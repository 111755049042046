import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MdKeyboardArrowLeft, MdKeyboardArrowDown } from "react-icons/md";
import { CgShapeCircle, CgRadioChecked } from "react-icons/cg";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  cursor: pointer;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  border-left: ${(props) => props.isSelected && `4px solid #3c8dbc`};
  width: 100%;
  min-height: 42px;
  height: 42px;
  background-color: ${(props) => props.isActive && "#0e181c"};
  color: ${(props) => (props.isActive ? "#FFF" : "#b8c7ce")};
  :hover {
    background-color: #0e181c;
    color: #fff;
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1px;
`;

const MenuTitle = styled.div`
  margin-left: 10px;
`;

const DropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: ${(props) => (props.isActive ? `${props.dropHeight}px` : "0px")};
  transition: height 0.2s;
`;

const DropInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${(props) => (props.isActive ? "#FFF" : "#8aa4af")};
  :hover {
    color: #fff;
  }
`;

const DropBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 34px;
  height: 34px;
  padding: 6px 5px 8px 20px;
  background-color: #2c3b41;
`;

const DropIcon = styled(CgShapeCircle)`
  border-width: 2px;
  margin-top: 1px;
  margin-right: 8px;
`;

const DropActiveIcon = styled(CgRadioChecked)`
  border-width: 2px;
  margin-top: 1px;
  margin-right: 8px;
`;

const DropTitle = styled.div``;

const LeftMenuBox = ({
  icon,
  title,
  linkTo,
  subLink,
  dropList,
  pathname,
  isShown,
  setIsShown,
  isDropdown = false,
}) => {
  const [menuIcon, setMenuIcon] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setMenuIcon(icon);
  }, []);

  const toggleDropdown = () => {
    if (isShown === isDropdown) {
      setIsShown(0);
      return;
    }
    setIsShown(isDropdown);
  };

  const onClickBox = (link) => {
    
    setIsShown(isDropdown);
    
    if (link === pathname) {
      history.push(link);
      history.go();
    } else {
      history.push(link);
    }

    if (link === "/logout") {
      sessionStorage.removeItem("token");
      history.push("/");
      window.location.reload();
    }

  };

  return (
    <Wrapper>
      <Inner
        isActive={
          isDropdown > 0
            ? isDropdown === isShown ||
              dropList.find((x) => pathname.startsWith(x.subLink))
            : pathname.startsWith(subLink)
        }
        onClick={() => {
          isDropdown > 0 ? toggleDropdown() : onClickBox(linkTo);
        }}
      >
        <TitleBox>
          {menuIcon}
          <MenuTitle>{title}</MenuTitle>
        </TitleBox>
        {isDropdown > 0 &&
          (isDropdown === isShown ||
          dropList.find((x) => pathname.startsWith(x.subLink)) ? (
            <MdKeyboardArrowDown size={16} />
          ) : (
            <MdKeyboardArrowLeft size={16} />
          ))}
      </Inner>
      <DropWrapper
        dropHeight={dropList.length * 34}
        isActive={
          isDropdown > 0 &&
          (isDropdown === isShown ||
            dropList.find((x) => pathname.startsWith(x.subLink)))
        }
      >
        {isDropdown > 0 &&
          dropList &&
          dropList.map(
            (drop, index) => {
              // if (drop.title !== "") {
              return (
                <DropInner
                  key={index}
                  isActive={pathname.startsWith(drop.subLink)}
                  onClick={() => onClickBox(drop.linkTo)}
                >
                  <DropBox>
                    {pathname.startsWith(drop.subLink) ? (
                      <DropActiveIcon size={18} />
                    ) : (
                      <DropIcon size={18} />
                    )}
                    <DropTitle>{drop.title}</DropTitle>
                  </DropBox>
                </DropInner>
              );
            }
            // return null;
            // }
          )}
      </DropWrapper>
    </Wrapper>
  );
};

export default LeftMenuBox;
