import React, { useState } from 'react'
import { Row, Col, Navbar, InputGroup, FormControl, Button, Dropdown, DropdownButton } from 'react-bootstrap'

const ArtistListTopBar = (props) => {
    const [search, setSearch] = useState(props.params.search ? props.params.search : "");

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setParams({...props.params, pageNumber:1, search:search});
    }
    const keyEvent = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    }
    const getGroupName = (type) => {
        if(props.artistGroupList && type) return props.artistGroupList.find(artistGroup => artistGroup.SEQ === type).NAME
        else return "전체"
    }
    
    return (
        <Navbar bg="light" expand="lg">
            <Row style={{width:"100%"}}>
                <Col md={2}>
                    <DropdownButton 
                        variant="outline-primary" id="commit-status-dropdown"
                        title={getGroupName(props.params.groupId)} >
                        {props.artistGroupList && props.artistGroupList.map(artistGroup => 
                            <Dropdown.Item key={artistGroup.SEQ} onClick={() => props.setParams({...props.params, pageNumber:1, groupId:artistGroup.SEQ})}>{artistGroup.NAME}</Dropdown.Item>
                        )}
                    </DropdownButton>
                </Col>
                <Col md={8}>
                    <Button className="btn btn-primary btn-sm" style={{ align : "right", display: "inline-block", marginLeft: "10px" }} onClick={() => props.setShowGroupAddPopup(true)}>작가 그룹 생성</Button>
                    <Button className="btn btn-primary btn-sm" style={{ align : "right", display: "inline-block", marginLeft: "10px" }} onClick={() => props.setShowGroupEditPopup(true)}>작가 그룹 정보 수정</Button>
                    <font style={{ verticalAlign: "middle", marginLeft: "10px" }}>선택한 작가들을</font>
                    <select id="artistGroupMove" style={{verticalAlign: "middle", marginLeft: "10px", fontSize : "14px"}} value={props.moveGroupId} onChange={(event) => {props.setMoveGroupId(event.target.value)}} >
                        {props.artistGroupList && props.artistGroupList.map(artistGroup => 
                            <option key={artistGroup.SEQ} value={artistGroup.SEQ} >{artistGroup.NAME}</option>
                        )}
                    </select>
                    <font style={{ verticalAlign: "middle", marginLeft: "10px" }}>으로</font>
                    <button className="btn btn-primary btn-sm" type="button" style={{ align : "right", display: "inline-block", marginLeft: "10px" }} onClick={() => props.moveArtist()}>작가 그룹 이동</button>

                </Col>
                <Col md={2} >
                    <InputGroup onSubmit={handleSubmit}> 
                        <FormControl type="text" value={search} placeholder="Search" onChange={(event)=>setSearch(event.target.value)} onKeyPress={keyEvent}/>
                        <Button variant="primary" onClick={handleSubmit}><i className="fa fa-search"/></Button>
                    </InputGroup>
                </Col>
            </Row>
        </Navbar>
    )
}

export default React.memo(ArtistListTopBar);