import React, { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../Components/Common/Loading/Loading";
import DefaultButton from "../../_Components/Common/ButtunUI/DefaultButton";
import PageTitle from "../../_Components/Common/PageTitle/PageTitle";
import BorderBox from "../../_Components/Common/BoxUI/BorderBox";
import MessageNoticeList from "../../_Components/MessageCenter/MessageNotice/MessageNoticeList";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
`;

const ControlBox = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-left: 15px;
`;

const MessageNoticePage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      setIsLoading(false);
    } else {
      history.push("/");
    }
  }, []);

  return (
    <Wrapper>
      <PageTitle
        mainTitle={"Notice"}
        v1Link={""}
        navNames={["Management", "Message Center", "Notice"]}
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <Fragment>
          <ControlBox>
            <DefaultButton
              btnText={"Add Notice"}
              theme={"blue"}
              onClick={() => {
                history.push("/Message/Notice/Add");
              }}
            />
          </ControlBox>
          <BorderBox
            boxTitle={"Notice List"}
            component={<MessageNoticeList />}
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default MessageNoticePage;
