import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SDPromptView from '../../Components/AI/SDPromptView'

const SDPromptPage = () => {
  const [promptList, setPromptList] = useState([])
  const [style, setStyle] = useState('')

  const getPrompt = () => {
    axios
      .get(`/new/webAdmin/v2/ai/sd/prompt`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        setPromptList(
          data.result
            .filter(item => item.style.includes(style))
            .sort((a, b) => a.order - b.order)
        )
      })
  }

  useEffect(() => {
    getPrompt()
  }, [style])

  return (
    <>
      <SDPromptView
        getPrompt={getPrompt}
        promptList={promptList}
        setPromptList={setPromptList}
        style={style}
        setStyle={setStyle}
      />
    </>
  )
}

export default SDPromptPage
