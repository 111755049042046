import React from 'react'
import styled from 'styled-components'
import { SpaceResponsive } from '../../Pages/NewApp/NewAppBannerPage'

const TopItem = props => {
  const { item } = props

  return (
    <TopItemWrapper>
      <Img src={item.packageImgWebp_700} />
      <TitleWrapper>
        <Title>{item.packageName}</Title>
        <SpaceResponsive height={3} />
        <SubTitle>{item.artistName}</SubTitle>
      </TitleWrapper>
    </TopItemWrapper>
  )
}

export default TopItem

const TopItemWrapper = styled.div`
  width: 100%;
  height: 122px;
  padding: 0 30px;
  font-family: Poppins;
  display: flex;
  align-items: center;
`

const TitleWrapper = styled.div`
  margin-left: 24px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  height: 19px;
`

const SubTitle = styled.div`
  font-size: 11px;
  height: 14px;
`

const Img = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 20px;
`
