import React from "react";
import { useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
`;

const MessageQnaArtistInfo = ({ selectedArtist }) => {
  useEffect(() => {
    // console.log(selectedArtist);
  }, [selectedArtist]);

  if (!selectedArtist || selectedArtist === "") {
    return <Wrapper>Selected artist information will be shown here.</Wrapper>;
  }

  return <Wrapper>Artist is existed.</Wrapper>;
};

export default MessageQnaArtistInfo;
