import React, { useState } from 'react'
import { Modal, Button, Container, Row, Col } from 'react-bootstrap'

const ArtistLicenseDeleteModal = (props) => {
    const [ isLicenseChecked, setIsLicenseChecked ] = useState(false);

    const handleClose = () => {
        setIsLicenseChecked(false);
        props.setShow(false);
    }

    const deleteLicense = () => {
        const params = {
            filepath : props.license.licenseUrl
        }
        fetch('/server/new/webAdmin/v2/license', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: 'DELETE'
            , body: JSON.stringify(params)
        })
        .then((response) => {
            console.log(response)
            return response.json()})
        .then((responseJson) => {
            console.log(responseJson);
            alert("삭제 완료");
            handleClose();
        })
        .catch((error) => { console.error("123=" + error) });
    }
    return (
        <Modal show={props.show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    신분증 삭제
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col md='auto'><font>신분증을 확인하세요</font></Col>
                        <Col md='auto'><a href={props.license.licenseUrl ? "https://api.stipop.io/admin/license?img=" + props.license.licenseUrl : null} target='_blank' rel='noopener noreferrer' onClick={() => setIsLicenseChecked(true)}>조회</a></Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" disabled={!isLicenseChecked} onClick={() => deleteLicense()}>Delete</Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(ArtistLicenseDeleteModal);