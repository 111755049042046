import React from 'react'
import CurationPreview from '../../Components/B2B/CurationPreview'
// import LeftMenu from "../../Components/LeftMenu";
import queryString from 'query-string'

const CurationPreviewPage = ({ match, location }) => {
  const query = queryString.parse(location.search)
  console.log(query)

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <CurationPreview query={query} />
    </React.Fragment>
  )
}

export default CurationPreviewPage
