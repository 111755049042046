import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Container, Row, Col } from 'react-bootstrap'
import Header from './CurationPreview/Header'
import KeywordList from './CurationPreview/KeywordList'
import TrendingList from './CurationPreview/TrendingList'
import BannerList from './CurationPreview/BannerList'
import Loading from '../Common/Loading/Loading'
import styled from 'styled-components'
import TrendingPreview from './CurationPreview/TrendingPreview'
import { BsSunFill, BsMoonFill } from 'react-icons/bs'
import CurationTable from './CurationPreview/CurationTable'
import CurationEdit from './CurationPreview/CurationEdit'

const CurationPreview = props => {
  const query = props.query
  const [params, setParams] = useState({
    pageNumber: query.pageNumber ? query.pageNumber : 1,
    status: query.status ? query.status : '',
    lang: query.lang ? query.lang : 'en',
    country: query.country ? query.country : 'us',
  })
  const [curationList, setCurationList] = useState([])
  const [trendingList, setTrendingList] = useState([])
  const [bannerList, setBannerList] = useState([])
  const [keywordList, setKeywordList] = useState([])
  const [modalInfo, setModalInfo] = useState({
    cardInfo: {},
    packageId: '',
    lightColor: '',
    darkColor: '',
    mainId: '',
    mainImg: '',
    stickerList: [],
  })
  const [type, setType] = useState('A')
  const [edit, setEdit] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      setIsLoading(true)
      getData()
      setEdit(0)
    } else {
      props.history.push('/')
    }
  }, [params])

  useEffect(() => {
    setEdit(0)
  }, [type])

  const getData = useCallback(async () => {
    await axios
      .get(`/new/webAdmin/v2/recommend?lang=${params.lang}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem("token"),
        },
      })
      .then(({ data }) => {
        setKeywordList(data.recommendList)
      })

    await axios
      .get('/new/webAdmin/v2/curationpreview', {
        params: params,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem("token"),
        },
      })
      .then(({ data }) => {
        if (data.status === 'success') {
          console.log(data.result.curationList)
          setCurationList(data.result.curationList)
          data.result.curationList.forEach(
            curationList =>
              curationList.status === 'Y' &&
              (curationList.type === 'A'
                ? setTrendingList(curationList)
                : setBannerList(curationList))
          )
          setIsLoading(false)
        } else {
          console.log(data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  })

  const setDefaultParams = (lang, country) => {
    setParams({ ...params, lang, country })
    setModalVisible(false)
  }

  const setCountry = country => {
    setParams({ ...params, country })
    setModalVisible(false)
  }

  const resetParams = () => {
    setParams({ ...params, lang: 'en', country: 'us' })
    setModalVisible(false)
  }

  const clickImg = useCallback(
    async (cardInfo, packageId, lightColor, darkColor, mainId, mainImg) => {
      await axios
        .get(`/new/webAdmin/v2/package/sticker/${packageId}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            token: sessionStorage.getItem("token"),
          },
        })
        .then(({ data }) => {
          // console.log(data)
          if (data.status === 'success') {
            setModalInfo({
              cardInfo: cardInfo,
              packageId: packageId,
              lightColor: lightColor,
              darkColor: darkColor,
              mainId: mainId,
              mainImg: mainImg,
              stickerList: data.body.package,
            })
            setModalVisible(true)
          } else {
            console.log(data)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  )

  const modalClose = () => {
    setModalVisible(false)
  }

  const [mode, SetMode] = useState(false)
  const switchColor = () => {
    SetMode(!mode)
  }

  return (
    <>
      <Container fluid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Header
            setDefaultParams={setDefaultParams}
            setCountry={setCountry}
            resetParams={resetParams}
          />
          <SwitchSection>
            <SwitchBox
              className={!mode ? 'light' : 'dark'}
              onClick={switchColor}
            >
              <div className={!mode ? 'light' : 'dark'}>
                {!mode ? (
                  <BsSunFill color={'white'} size={28} />
                ) : (
                  <BsMoonFill color={'#303030'} size={28} />
                )}
              </div>
            </SwitchBox>
          </SwitchSection>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <Section style={{ marginTop: '2%' }}>
            <MobileView className={`${!mode ? 'light' : 'dark'}`}>
              <div style={{ height: '21%' }}>
                <KeywordList keywordList={keywordList} mode={mode} />
              </div>
              <div style={{ height: '36%' }}>
                <TrendingList
                  trendingList={trendingList}
                  clickImg={clickImg}
                  mode={mode}
                />
              </div>
              <div style={{ height: '36%' }}>
                <BannerList bannerList={bannerList} mode={mode} />
              </div>
            </MobileView>
            {!edit ? (
              modalVisible ? (
                <TrendingPreview
                  modalInfo={modalInfo}
                  modalClose={modalClose}
                  mode={mode}
                />
              ) : (
                <CurationTable
                  curationList={curationList}
                  setDefaultParams={setDefaultParams}
                  setCountry={setCountry}
                  resetParams={resetParams}
                  type={type}
                  setEdit={setEdit}
                  getData={getData}
                  setType={setType}
                />
              )
            ) : (
              <CurationEdit
                curationList={curationList}
                edit={edit}
                setEdit={setEdit}
                mode={mode}
                type={type}
                getData={getData}
                language={params.lang}
                country={params.country}
              />
            )}
          </Section>
        )}
      </Container>
    </>
  )
}

export default CurationPreview

const Section = styled.section`
  margin: 30px 15px;
  display: flex;
`

const SwitchSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
`
const SwitchBox = styled.div`
  width: 95px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  box-shadow: 0 0 2px 0 #303030;
  &.light {
    background-color: white;
  }
  &.dark {
    background-color: #303030;
  }

  div {
    position: relative;
    width: 40px;
    height: 40px;
    left: 5%;
    border-radius: 100%;
    transition: all 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &.light {
      background-color: #ff4500;
    }
    &.dark {
      background-color: #ff855b;
      left: 50%;
    }
  }
`
const MobileView = styled.div`
  height: 76vh;
  width: 380px;
  padding: 0 20px;
  border-radius: 50px;
  margin-right: 20px;
  transition: all 0.5s ease-in-out;
  &.light {
    background-color: #fff;
    border: 5px solid #ff4500;
  }
  &.dark {
    background-color: #303030;
    border: 5px solid #ff855b;
  }
`
