import React from "react";
import BookmarkList from "../../Components/Contents/BookmarkList";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const BookmarkListPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <BookmarkList

      />
    </React.Fragment>
  );
};

export default BookmarkListPage;
