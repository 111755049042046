import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import DefaultButton from '../../Common/ButtunUI/DefaultButton'

const Wrapper = styled.div`
  font-size: 14px;
  padding: 10px;
`

const StickerListDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const StickerListRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const StickerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 8%;
  margin: 20px;
`

const StickerImage = styled.img`
  border: 1px solid #e5eefe;
`

const UntaggedStickerList = () => {
  const STICKER_ROW_COUNT = 3
  const STICKER_PER_ROW = 8
  const UNTAGGED_STICKER_LIMIT = STICKER_ROW_COUNT * STICKER_PER_ROW

  const [stickerList, setStickerList] = useState([])

  const history = useHistory()

  useEffect(() => {
    getUnttagedStickerList()
  }, [])

  const getUnttagedStickerList = async () => {
    try {
      await axios
        .get(
          `/new/webAdmin/v2/keyword/sticker/untagged/${UNTAGGED_STICKER_LIMIT}`,
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        .then(res => {
          const temp = res.data.sticker.untaggedResult
          const spliceList = []
          for (let index = 0; index < STICKER_ROW_COUNT; index++) {
            spliceList.push(temp.splice(0, STICKER_PER_ROW))
          }
          setStickerList(spliceList)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Wrapper>
      <StickerListDiv>
        {stickerList &&
          stickerList.map((spl, index) => {
            return (
              <StickerListRow key={index}>
                {spl.map((sticker, index) => {
                  return (
                    <StickerBox key={index}>
                      <StickerImage src={encodeURI(sticker.stickerImage)} />
                      <DefaultButton
                        style={{ marginTop: 20 }}
                        btnText={"Let's Go!!!"}
                        theme={'blue'}
                        onClick={() => {
                          history.push({
                            pathname: `/Keyword/Untagged/Add/${sticker.stickerId}`,
                          })
                        }}
                      />
                    </StickerBox>
                  )
                })}
              </StickerListRow>
            )
          })}
      </StickerListDiv>
    </Wrapper>
  )
}

export default UntaggedStickerList
