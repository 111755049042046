import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import List from "../Common/List";
import {EditList} from "../Common/EditList";
import SearchText from "../Common/SearchText";

const searchApi = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState([]); 
    const [dataList, setDataList] = useState([]);
    const [lang, setLang] = useState("en");
    const [pageMap, setPageMap] = useState({});
    const [pageNumber, setPageNumber] = useState(props.pageNumber ? props.pageNumber : 1);
    const [q, setQ] = useState(props.search ? props.search : '');

    
    const search = async (searchText) => { 

        setState(searchText);

        const response = await fetch(`/admin/v2/search?q=${searchText}&lang=${lang === 'other' ? 'en':lang}&pageNumber=${pageNumber}`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

        if (responseJson.body.stickerList) {

            if (filedList.length === 0) {
                
                let fileds = []
    
                for (const tmp in responseJson.body.stickerList[0]) {
                    if (tmp === 'seq' || tmp === 'stickerId' || tmp === 'packageId' || tmp === 'artistName' || tmp === 'keyword' || tmp === 'stickerImg' || tmp === 'packageName') {
                        fileds.push(tmp);
                    }
                }
                
                setFiledList(fileds);
            }
            
            setPageMap(responseJson.body.pageMap);
            setDataList(responseJson.body.stickerList);
            
            // setQ(searchText);

        } else {
            setDataList([]);
        }

    }; 

    const [keyword, SearchInput, setState] = SearchText(q, search, setLang, setQ);

    const callback = useCallback((value, key, index) => {
        dataList[index][key] = value;
        // setDataList([...dataList]);
    }, [dataList]);

    const editKeyword = async (data) => {

        const response = await fetch(`/new/webAdmin/v2/b2b/search`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            }
            , method:'PUT'
            , body: JSON.stringify(data)
        })

        setDataList([...dataList]);
        alert("수정완료");
    }

    const deleteKeyword = async (seq, index) => {
        // alert(seq);
        // alert(index);
        if (window.confirm('Are You Sure?')) {
            
            const response = await fetch(`/new/webAdmin/v2/b2b/search/keyword/${seq}`, {
                headers : {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: sessionStorage.getItem("token")
                }
                , method:'DELETE'
            });

            setDataList(dataList.filter(item => item.seq !== seq));
            
            alert("삭제완료");
        }
    };

    const customComponent = useCallback(() => {
        return (
            <EditList 
                fieldList={filedList} 
                dataList={dataList} 
                setDataList={callback} 
                editKeyword={(data) => editKeyword(data)} 
                deleteKeyword={(seq, index) => deleteKeyword(seq, index)} 
            />
            // <List fieldList={filedList} dataList={dataList} />
        )
    }, [dataList]);


    useEffect(() => { 
        // componentDidMount

        const fetchData = async () => {
            q && await search(q);
            setIsLoading(false);
        }

        fetchData();

        return async () => {
          //componentWillUnmount
        };
    }, []);

    if (!isLoading) {
        return (
            <>
                <Header title={"search Api"} />
                <Body HeaderComponent={SearchInput} headerWidth={"20%"} DataComponet={customComponent} pageMap={pageMap} search={keyword} />
            </>
        )
    } else {
        return <Loading />;
    }
}

export default withRouter(searchApi);