import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import OverviewModal from './OverviewModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
`
const Row = styled.div`
  display: flex;
  cursor: pointer;
  :hover{
    background-color: #eeeef1;
  }
`

const RowTitle = styled.div`
  display: flex;
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d2d6de;
  cursor: default;
  :hover{
    background-color: #fff;
  }
`

const RowTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  cursor: default;
  :hover{
    background-color: '#fff' ;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 35px;
  padding: 5px 2px;
  /* align-items: center; */
  /* justify-content: center; */
	word-break: break-all;
  word-wrap: break-word;
  white-space: pre-line;
`
export const getPricingPlan = async(appId, date, api_cnt) => {
  let plan = await axios.get(`/new/webAdmin/v2/pricing/${appId}/${date}/${api_cnt}`, {
    headers: {
      'token': window.sessionStorage.getItem("token")
    }
  })
  .then(({data})=>{
    console.log(data)
    if(data.status==='success'){
      return data.body.planName
    }
    else return ''
  })
  .catch(err=>{
    console.log(err)
  })
  return plan
}

const OverviewList = ({overviewList, currentPage=1, total, date}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedApp, setSelectedApp] = useState();

  const clickApp = async(appId, appName, email, payment, api_cnt, sdk_cnt) => {    
    let api_sdk = '';
    if(api_cnt === 0 && sdk_cnt===0){
      api_sdk = '둘 다 사용하지 않음'
    }
    else if(api_cnt === 0 && sdk_cnt>0){
      api_sdk = 'SDK'
    }
    else if(api_cnt>0 && sdk_cnt===0){
      api_sdk = 'API'
    }
    else {
      api_sdk = 'API + SDK'
    }

    // app's pricing plan get api
    // -> selectedApp set 추가
    let planName = await getPricingPlan(appId, date, api_cnt);

    setSelectedApp({
      id: appId, 
      name: appName,
      creatorName: email,
      card: payment===null?'카드 추가하지 않음':'카드 추가함',
      api_sdk: api_sdk,
      plan: planName,
      api_cnt:  api_cnt
    });

    setOpenModal(true);
  }

  return (
    <Wrapper>
      {selectedApp && 
        <OverviewModal 
          open={openModal} 
          setOpen={setOpenModal}
          info={selectedApp}
          setInfo={setSelectedApp}
          date={date}
          // id={selectedApp.id} 
          // name={selectedApp.name}
        />
      }
      <RowTitle>
        {/* <Col style={{width: '5%'}}>No.</Col> */}
        <Col style={{width: '10%'}}>앱 이름</Col>
        <Col style={{width: '10%'}}>MSU</Col>
        <Col style={{width: '10%'}}>월 스티커 전송수</Col>
        <Col style={{width: '10%'}}>월 스티커 다운로드 수</Col>
        <Col style={{width: '10%'}}>스티커 전송자 수</Col>
        <Col style={{width: '10%'}}>스티커 다운로드자 수</Col>
        <Col style={{width: '10%'}}>유저당 평균 전송 수</Col>
        <Col style={{width: '10%'}}>유저당 평균 다운로드 수</Col>
        <Col style={{width: '10%'}}>API call</Col>
        <Col style={{width: '10%'}}>SDK call</Col>
      </RowTitle>
      {overviewList && overviewList.map((data, index) => {
        return(
          <Row 
            key={`overview_${index}`} 
            onClick={()=>{clickApp(data.APP_ID, data.APP_NAME, data.EMAIL, data.PAYMENT_METHOD_ID, data.API_CNT, data.SDK_CNT)}}
          >
            {/* <Col style={{width: '5%'}}>{index+1+(currentPage-1)*20}</Col> */}
            <Col style={{width: '10%'}}>{data.APP_NAME}</Col>
            <Col style={{width: '10%'}}>{data.MSU}</Col>
            <Col style={{width: '10%'}}>{data.SEND_CNT}</Col>
            <Col style={{width: '10%'}}>{data.DOWN_CNT}</Col>
            <Col style={{width: '10%'}}>{data.SEND_USER_CNT}</Col>
            <Col style={{width: '10%'}}>{data.DOWN_USER_CNT}</Col>
            <Col style={{width: '10%'}}>{data.SEND_AVG}</Col>
            <Col style={{width: '10%'}}>{data.DOWN_AVG}</Col>
            <Col style={{width: '10%'}}>{data.API_CNT}</Col>
            <Col style={{width: '10%'}}>{data.SDK_CNT}</Col>
          </Row>
        )
      })}
      {total && 
        <RowTotal style={{borderTop: 'solid 1px lightgray', padding: '20px 0 0'}}>
          <Col style={{width: '25%', alignItems: 'center'}}>
            <RowTotal style={{fontSize: 20}}>Total MSU</RowTotal>
            <RowTotal>{parseInt(total.MSU).toLocaleString('ko-KR')}</RowTotal>
          </Col>
          <Col style={{width: '25%', alignItems: 'center'}}>
            <RowTotal style={{cursor: 'default', backgroundColor: '#fff', fontSize: 20}}>전체 월 스티커 전송수</RowTotal>
            <RowTotal>{parseInt(total.send).toLocaleString('ko-KR')}</RowTotal>
          </Col>
          <Col style={{width: '25%', alignItems: 'center'}}>
            <RowTotal style={{fontSize: 20}}>전체 월 스티커 다운수</RowTotal>
            <RowTotal>{parseInt(total.down).toLocaleString('ko-KR')}</RowTotal>
          </Col>
          <Col style={{width: '25%', alignItems: 'center'}}>
            <RowTotal style={{fontSize: 20}}>전체 월 API 호출수</RowTotal>
            <RowTotal>{parseInt(total.api).toLocaleString('ko-KR')}</RowTotal>
          </Col>
        </RowTotal>
      }
    </Wrapper>
  )
}

export default OverviewList;