import React, { useEffect, useState } from 'react';
import { languages, countryDefaultLanguage } from '../../Data/CountryAndLanguage';
export const CardTypeDropdown = ({type, setType}) => {

    return (
        <React.Fragment>
            <select value={type} onChange={(event) => setType(event.target.value)}>
                <option value="">타입을 선택하세요</option>
                <option value="A">Small Card (Type A)</option>
                <option value="B">Card banner (Type B)</option>
            </select>
        </React.Fragment>
    )
}

export const CardLangDropdown = ({lang, setLang}) => {

    return (
        <React.Fragment>
            <select value={lang} onChange={(event) => setLang(event.target.value)}>
                <option value="">언어를 선택하세요</option>
                {languages.map(lang=>{
                    return(
                        <option key={lang} value={lang}>{lang}</option>
                    )
                })}
            </select>
        </React.Fragment>
    )
}

export const CardCountryDropdown = ({country, setCountry}) => {
    const countryNameSet = new Set([]);
    const countrySet = countryDefaultLanguage.filter(con=>{
        if(!countryNameSet.has(con.country)) {
            countryNameSet.add(con.country);
            return con;
        }
    })

    return (
        <React.Fragment>
            <select value={country} onChange={(event) => setCountry(event.target.value)}>
                {countrySet.map(con=>{
                    return(
                        <option key={con.country} value={con.country}>{con.country_kr}</option>
                    )
                })}
            </select>
        </React.Fragment>
    )
}



