import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withRouter } from "react-router-dom";

import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import DateInputArtist from "../Common/DateInputArtist";
import List from "../Common/List";

const AllSend = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState([]); 
    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [range, setRange] = useState("month");


    const getRegCount = async () => {
        
        const response = await fetch(`/new/webAdmin/v2/track/allSend?range=${range}&startDate=${startDate}&endDate=${endDate}`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

        if (responseJson.status === 'success') {
            
            if (filedList.length === 0) {
                
                let fileds = []
                
                for (const tmp in responseJson.list[0]) {
                    fileds.push(tmp);
                }
                
                setFiledList(fileds);
            }
            
            setList(responseJson.list);

        } else {
            setList([]);
        }

    };


    // const [ Date, setState, setState1] = DateInputArtist(startDate, setStartDates, endDate, setEndDates, setRange, search);

    const dataComponent = useCallback(() => {
        return (
            <List 
                fieldList={filedList}
                dataList={list}
            />
        )
    }, [list])


    useEffect(() => { 

        const fetchData = async () => {
            await getRegCount();
            setIsLoading(false);
        }

        fetchData();

    return async () => {
          //componentWillUnmount
        };
    }, []);

    if (!isLoading) {
        return (
            <>
                <Header title={"전체 전송 수"} />
                <Body HeaderComponent={null} headerWidth={"50%"} DataComponet={dataComponent}  />
            </>
        )
    } else {
        return <Loading />;
    }
}

export default withRouter(AllSend);