import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { countryDefaultLanguage, languages } from '../../Data/CountryAndLanguage'

const CountryWrapper = styled.div`
    margin-lefg: 15px;
`

const Button = styled.button`
    border: none;
    border-radius: 15px;
    outline: none;
    margin: 5px;
    width: 40px;
`
const UnselectedButton = styled(Button)`
    color: #FFFFFF;
    background-color: #937067;
`

const SelectedButton = styled(Button)`
    background-color: #9C9EA9;
`

const Header = (props) => {
    const [country, setCountry] = useState({
        isSelected: false,
        type: "us"
    });
    const [language, setLanguage] = useState({
        isSelected: false,
        type: "en"
    });

    const selectCountry = (item) => {
        setCountry({...country, isSelected: true, type: item.country});
        setLanguage({...language, type: item.language})
    }

    const selectLanguage = (item) => {
        setLanguage({...language, isSelected: true, type: item})
    }

    const resetCountry = () => {
        setCountry({
            isSelected: false,
            type: "us"
        });
        setLanguage({
            isSelected: false,
            type: "en"
        })
    }

    const resetLanguage = () => {
        // language reset할 때
        // 지금 선택되어있는 country에 해당하는 lang이 default
        let [res] = countryDefaultLanguage.filter(function(item){
            if(item.country === country.type){
                return item.language
            }
        })
        setLanguage({
            isSelected: false,
            type: res.language 
        });
    }

    useEffect(()=>{
        props.setLanguage(language.type);
        props.setCountry(country.type);
    },[language])

    return(
        <div>
            <div style={{color:'#937067'}}>
                국가/언어
            </div>
            <CountryWrapper>
                {!country.isSelected && (
                    countryDefaultLanguage.map((item, index)=>{
                        return(
                            <UnselectedButton key={item+index} onClick={(e)=>{selectCountry(item, e)}}>
                                {item.country}
                            </UnselectedButton>
                        )
                    })
                )}
                {country.isSelected && !language.isSelected && (
                    <>
                        <SelectedButton onClick={resetCountry} >{country.type}</SelectedButton>
                        {languages.map((lang, index)=>{
                            return(
                                <UnselectedButton key={lang+index} onClick={(e)=>{selectLanguage(lang, e)}}>
                                    {lang}
                                </UnselectedButton>
                            )
                        })}
                    </>
                )}
                {country.isSelected && language.isSelected && (
                    <>
                        <SelectedButton onClick={resetCountry}>{country.type}</SelectedButton>
                        <SelectedButton onClick={resetLanguage}>{language.type}</SelectedButton>
                    </>
                )}
            </CountryWrapper>
        </div>
    );
};

export default Header;