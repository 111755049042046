import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { getBannerPropertyName, checkSuitability } from './MobileBannerUtils.js';

const Banner = (props) => {
    const [ packageId, setPackageId ] = useState(props.bannerContainerPackage.packageId ? props.bannerContainerPackage.packageId : '');
    const [ smallTitle, setSmallTitle ] = useState(props.bannerContainerPackage.sTitle ? props.bannerContainerPackage.sTitle : '');
    const [ bigTitle, setBigTitle ] = useState(props.bannerContainerPackage.bTitle ? props.bannerContainerPackage.bTitle : '');
    const [ tag, setTag ] = useState(props.bannerContainerPackage.tag ? props.bannerContainerPackage.tag : '');
    const [ stickerId, setStickerId ] = useState(props.bannerContainerPackage.stickerId ? props.bannerContainerPackage.stickerId : '');
    const [ image, setImage ] = useState(props.bannerContainerPackage.img ? props.bannerContainerPackage.img : '');

    useEffect(() => {
        props.setBannerContainerPackage(props.idx, {...props.bannerContainerPackage, packageId: packageId, sTitle: smallTitle, bTitle: bigTitle, tag: tag, stickerId: stickerId, img: [image]})
    }, [packageId, smallTitle, bigTitle, tag, stickerId, image]);
    
    return (
        <form encType="multipart/form-data">
            <Row>
                <Col md={2}>
                    <font size={2}>Sticker Pack ID<br/>(패키지 아이디)</font><br />
                    <input type="text" style={{width: '100%'}} value={packageId} onChange={(event) => setPackageId(event.target.value * 1)} disabled={props.type === ''}></input>
                </Col>
                <Col md={props.type === 'I' ? 3 : 1}>
                    <font size={2}>{getBannerPropertyName(props.type, "small title")}<br/>(스몰 타이틀)</font><br />
                    <input type="text" style={{width: '100%'}} value={smallTitle} onChange={(event) => setSmallTitle(event.target.value)} disabled={checkSuitability(props.type, 's_title')}></input>
                </Col>
                {props.type !== 'I' &&
                <Col md={2}>
                    <font size={2}>{getBannerPropertyName(props.type, "big title")}<br/>(빅 타이틀)</font><br />
                    <input type="text" style={{width: '100%'}} value={bigTitle} onChange={(event) => setBigTitle(event.target.value)} disabled={checkSuitability(props.type, 'b_title')}></input>
                </Col>}
                <Col md={1}>
                    <font size={2}>Tag<br/>(태그)</font><br />
                    <input type="text" style={{width: '100%'}} value={tag} onChange={(event) => setTag(event.target.value)} disabled={checkSuitability(props.type, 'tag')}></input>
                </Col>
                <Col md={1}>
                    <font size={2}>Sticker ID<br/>(스티커 아이디)</font><br />
                    <input type="text" style={{width: '100%'}} value={stickerId} onChange={(event) => setStickerId(event.target.value * 1)} disabled={checkSuitability(props.type, 's_id')}></input>
                </Col>
                {props.bannerContainerPackage.s_img && <Col md={1}><img src={props.bannerContainerPackage.s_img} style={{width:"100%", height:"100%"}} alt={props.bannerContainerPackage.s_img} /></Col> }
                <Col md={2}>
                    <font size={2}>Image<br/>(이미지)</font><br />
                    <input type="file" style={{width: '100%'}} onChange={(event) => setImage(event.target.files[0])} disabled={checkSuitability(props.type, 'image')}></input>
                    {image &&
                    <img src={typeof(image) === 'string' ? image : URL.createObjectURL(image)} style={{width: "100%"}} alt={image}/>}
                </Col>
                <Col md={1}>
                    <font size={2}>Delete<br />삭제</font><br />
                    <Button variant="danger" onClick={() => props.removeBannerContainerPackage(props.idx)}>Delete</Button>
                </Col>
                <Col md={1}>
                    <font size={2}>Order<br />순서</font><br />
                    <Button onClick={() => props.moveOrderUp(props.bannerContainerPackage.seq, props.bannerContainerPackage.order, props.idx)}>▲</Button>
                    <Button onClick={() => props.moveOrderDown(props.bannerContainerPackage.seq, props.bannerContainerPackage.order, props.idx)}>▼</Button>
                </Col>
            </Row>
        </form>
    )
}

export default Banner;