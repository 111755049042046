import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  z-index: 50;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 150px;
  height: 100px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  top: -100px;
  left: 24px;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  box-shadow: 0 2px 2px 0 rgba(231, 231, 231, 0.5);
  border-radius: 8px;
  padding: 10px;
`;

const HoverImage = styled.div`
  background-image: url(${(props) => encodeURI(props.url)});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

const AttachPreviewBox = ({ url, customStyles = {} }) => {
  return (
    <Wrapper style={customStyles}>
      <HoverImage url={url} />
    </Wrapper>
  );
};

export default AttachPreviewBox;
