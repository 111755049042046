import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ContentHeader = styled.section`
    position: relative;
    padding: 15px 15px 0 15px;
`;

const Title = styled.h1`
    margin:0;
    font-size:24px;
`;

const Crumb = styled.ol`
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px 5px;
    position: absolute;
    top: 15px;
    right: 10px;
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
`;

const Header = ({ title }) => {
    return (
        <ContentHeader>
            <Title>{title}</Title>
            <Crumb>
                <li>B2B</li>
            </Crumb>
        </ContentHeader>
    )
}

export default Header;