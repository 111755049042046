import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
`

const TextArea = styled.div`
  display: flex;
  margin-bottom: 60px;
  font-size: 40px;
`

const ButtonArea = styled.div`
  display: flex;
  `

const Button = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid gray;
  border-radius: 4px;
  background-color: lightgray;
  margin: 10px;
  color: #000;
  :hover{
    background-color: gray;
    color: #fff;
  }
`

const DonePostView = () => {
  return(
    <Wrapper>
      <TextArea>
        Post completed!
      </TextArea>
      <ButtonArea>
        <Link to="/Blog/post">
          <Button>
            Write Post page
          </Button>
        </Link>
        <Link to="/Blog/view">
          <Button>
            View Posts page
          </Button>
        </Link>
        <Button onClick={()=>{window.open("https://stipop.io/en/blog")}}>
          B2B blog 👉
        </Button>
      </ButtonArea>
    </Wrapper>
  );
};

export default DonePostView;