import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'

const Table = props => {
  const { menuList, lang, setLang, getMenu, getBanner } = props
  const langList = ['en', 'ko', 'es', 'pt']
  const [checked, setChecked] = useState([])
  const checkRef = useRef([])
  const [params, setParams] = useState({
    status: 'N',
    name: '',
    type: '',
    sub: '',
    detail: '',
    lang: lang,
  })
  const [edit, setEdit] = useState(0)

  const init = async () => {
    await setChecked([])
    await setEdit(0)
    await getMenu()
    await getBanner()

    if (checkRef.current) {
      for (let i = 0; i < checkRef.current.length; i++) {
        if (checkRef.current[i] && checkRef.current[i].checked) {
          checkRef.current[i].checked = false
        }
      }
    }
  }

  useEffect(() => {
    if (edit > 0) {
      setParams(menuList.filter(menu => menu.seq === edit)[0])
    } else if (edit < 0) {
      setParams({
        status: 'N',
        name: '',
        type: '',
        sub: '',
        detail: '',
        lang: lang,
      })
    } else {
      init()
    }
  }, [edit])

  useEffect(() => {
    init()
  }, [lang])

  const handleCheck = e => {
    if (e.target.checked) {
      setChecked(checked.concat(e.target.value))
    } else {
      setChecked(checked.filter(id => id != e.target.value))
    }
  }

  const updateStatus = async status => {
    axios
      .put(
        `/new/webAdmin/v2/home/menu/status`,
        { updateList: checked, status },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        init()
      })
  }

  const reorderPromise = (id, order) => {
    const params = {
      id,
      order,
    }

    return new Promise(async (resolve, reject) => {
      axios
        .put(`/new/webAdmin/v2/home/menu/order`, params, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const reorder = async list => {
    const result = await Promise.all(
      list.map((item, index) => reorderPromise(item.seq, index + 1))
    )

    if (result) {
      init()
    }
  }

  const moveOrder = async (idx, move) => {
    const updateData = [...menuList]
    const target = updateData.splice(move === 'up' ? idx - 1 : idx + 1, 1)[0]
    updateData.splice(idx, 0, target)

    await reorder(updateData)
  }

  const clickSave = async () => {
    if (edit > 0) {
      await axios.put(`/new/webAdmin/v2/home/menu/info/${edit}`, params, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
    } else if (edit < 0) {
      await axios.post(`/new/webAdmin/v2/home/menu`, params, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
    }

    await init()
  }

  const clickDelete = async () => {
    if (checked.length > 0) {
      await axios
        .delete(`/new/webAdmin/v2/home/menu`, {
          data: {
            deleteList: checked,
          },
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(() => {
          init()
        })
    }

    await setChecked([])
  }

  const renderEdit = () => {
    return (
      <table className="table no-margin">
        <thead>
          <tr>
            <th>NAME</th>
            <th>TYPE</th>
            <th>SUB TYPE</th>
            <th>DETAIL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                value={params.name}
                onChange={e => setParams({ ...params, name: e.target.value })}
              />
            </td>
            <td>
              <input
                type="text"
                value={params.type}
                onChange={e => setParams({ ...params, type: e.target.value })}
              />
            </td>
            <td>
              <input
                type="text"
                value={params.sub}
                onChange={e => setParams({ ...params, sub: e.target.value })}
              />
            </td>
            <td>
              <input
                type="text"
                value={params.detail}
                onChange={e => setParams({ ...params, detail: e.target.value })}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <Wrapper>
      <section
        className="content"
        style={{ paddingTop: '0', height: '100%', overflowY: 'auto' }}
      >
        <div className="row">
          <section className="col-lg-12 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  paddingBottom: '30px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                  <span>Lang</span>
                  <select
                    style={{ display: 'inline-block', margin: '0 20px' }}
                    value={lang}
                    onChange={e => {
                      setLang(e.target.value)
                    }}
                  >
                    {langList.map((l, i) => (
                      <option key={i} value={l}>
                        {l}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', gap: '0 10px' }}>
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      if (edit) {
                        clickSave()
                      } else {
                        setEdit(-1)
                      }
                    }}
                  >
                    {edit ? '저장' : '추가'}
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      if (edit) {
                        init()
                      } else {
                        clickDelete()
                      }
                    }}
                  >
                    {edit ? '취소' : '삭제'}
                  </button>
                  {!edit && (
                    <>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          updateStatus('Y')
                        }}
                      >
                        Activate
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          updateStatus('N')
                        }}
                      >
                        Deactivate
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {edit ? (
                    renderEdit()
                  ) : (
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th></th>
                          <th>STATUS</th>
                          <th>NAME</th>
                          <th>TYPE</th>
                          <th>SUB TYPE</th>
                          <th>DETAIL</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {menuList.length > 0 &&
                          menuList.map((item, index) => (
                            <tr key={index}>
                              <td>{item.order}</td>
                              <td>
                                <input
                                  ref={el => (checkRef.current[index] = el)}
                                  type="checkbox"
                                  onChange={handleCheck}
                                  value={item.seq}
                                />
                              </td>
                              <td>{item.status}</td>
                              <td>{item.name}</td>
                              <td>{item.type}</td>
                              <td>{item.sub}</td>
                              <td>{item.detail}</td>
                              <td>
                                {index !== 0 && (
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      moveOrder(index, 'up')
                                    }}
                                  >
                                    ▲
                                  </button>
                                )}
                                {index !== menuList.length - 1 && (
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      moveOrder(index, 'down')
                                    }}
                                  >
                                    ▼
                                  </button>
                                )}
                              </td>
                              <td>
                                <button
                                  className="btn btn-default"
                                  onClick={() => {
                                    setEdit(item.seq)
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Wrapper>
  )
}

export default Table

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
