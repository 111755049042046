import React, { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../Components/Common/Loading/Loading";
import PageTitle from "../../_Components/Common/PageTitle/PageTitle";
import MessageQnaSend from "../../_Components/MessageCenter/MessageQna/MessageQnaSend";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
`;

const Inner = styled.div`
  display: flex;
`;

const MessageQnaSendPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      setIsLoading(false);
    } else {
      history.push("/");
    }
  }, []);

  return (
    <Wrapper>
      <PageTitle
        mainTitle={"1:1 Message - Send"}
        v1Link={""}
        navNames={["Management", "Message Center", "1:1 Message", "Send"]}
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <Fragment>
          <Inner>
            <MessageQnaSend />
          </Inner>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default MessageQnaSendPage;
