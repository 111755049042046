import React, { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import BorderBox from "../../_Components/Common/BoxUI/BorderBox";
import Loading from "../../Components/Common/Loading/Loading";
import PageTitle from "../../_Components/Common/PageTitle/PageTitle";
import StudioBannerDetail from "../../_Components/StudioBanner/StudioBannerDetail";
import StudioBannerList from "../../_Components/StudioBanner/StudioBannerList";
import DefaultButton from "../../_Components/Common/ButtunUI/DefaultButton";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
`;

const Inner = styled.div`
  display: flex;
`;

const InnerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

const InnerRight = styled.div`
  width: 70%;
`;

const ControlBox = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-left: 15px;
`;

const StudioBannerPage = (props) => {
  const [allBannerList, setAllBannerList] = useState([]);
  const [activatedList, setActivatedList] = useState([]);
  const [deactivatedList, setDeactivatedList] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      if (reload) getStudioBannerList();
    } else {
      history.push("/");
    }
  }, [reload]);

  const getStudioBannerList = () => {
    fetch("/new/webAdmin/v2/studio-banners", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111" && responseJson.code === "1111") {
          props.history.push("/");
        } 
        const tempBannerList = responseJson.banners;
        setActivatedList(tempBannerList.filter((x) => x.status === 1));
        setDeactivatedList(tempBannerList.filter((x) => x.status === 0));
        setAllBannerList(tempBannerList);
        setReload(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickBannerBox = (bannerId) => {
    setSelectedBanner(allBannerList.find((x) => x.bannerId === bannerId));
  };

  const onClickAddBanner = () => {
    history.push("/Banner/Studio/Insert");
  };

  const onClickStudioHome = () => {
    window.open("https://studio.stipop.io/my-studio/my-home", "StudioHome");
  };

  return (
    <Wrapper>
      <PageTitle
        mainTitle={"Studio Banners"}
        v1Link={""}
        navNames={["Management", "Banners", "Studio Banners"]}
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <Fragment>
          <ControlBox>
            <DefaultButton
              btnText={"Add Banner"}
              theme={"blue"}
              onClick={onClickAddBanner}
            />
            <DefaultButton
              style={{ marginLeft: 10 }}
              btnText={"Studio Home"}
              onClick={onClickStudioHome}
            />
          </ControlBox>
          <Inner>
            <InnerLeft>
              <BorderBox
                boxTitle={"Activated Banners"}
                borderColor={"#00a65a"}
                component={
                  <StudioBannerList
                    bannerList={activatedList}
                    setBannerList={setActivatedList}
                    onClickBannerBox={onClickBannerBox}
                    isActive={true}
                    setReload={setReload}
                    activeLength={activatedList.length}
                  />
                }
              />
              <BorderBox
                boxTitle={"Deactivated Banners"}
                borderColor={"#8c8f94"}
                component={
                  <StudioBannerList
                    bannerList={deactivatedList}
                    onClickBannerBox={onClickBannerBox}
                    isActive={false}
                  />
                }
              />
            </InnerLeft>
            <InnerRight>
              <BorderBox
                boxTitle={"Banner Detail"}
                component={
                  <StudioBannerDetail
                    reload={reload}
                    setReload={setReload}
                    selectedBanner={selectedBanner}
                    setSelectedBanner={setSelectedBanner}
                    activatedList={activatedList}
                    activeLength={activatedList.length}
                  />
                }
              />
            </InnerRight>
          </Inner>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default StudioBannerPage;
