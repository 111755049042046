import React from "react";
import LoginForm from "../../Components/Login/LoginForm";

const Login = ({ match, location }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "100px",
        left: 0,
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <LoginForm />
    </div>
  );
};

export default Login;
