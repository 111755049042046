import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Input = styled.input`
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: 2;
    float: left;
    width: 25%;
    margin: 0;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    display: table-cell;
`;

const Dropdown = styled.div`
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
`;

const Span = styled.span`
    position: relative;
    float: left;
    height: 30px;
`;

const SearchButton = styled.button`
    z-index: 2;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    box-shadow: none;
    border-width: 1px;
    position: relative;
    background-color: #00c0ef;
    border-color: #00acd6;
    border: 1px solid transparent;
    color: #fff;
`;

const Componeent = ({startDate, setStartDate, endDate, setEndDate, setPlatform, setRange, search, categoryList, setCategory}) => {
// const SearchText = ({keyword, search}) => {
    return (
        <>
            <Dropdown>
                <select onChange={(e) => setPlatform(e.target.value)}>
                    <option key={"app"} value={"app"}>app</option>
                    <option key={"sdk"} value={"sdk"}>sdk</option>
                </select>
                <select style={{marginLeft:"10px"}} onChange={(e) => setRange(e.target.value)}>
                    <option key={"month"} value={"month"}>월간</option>
                    <option key={"week"} value={"week"}>주간</option>
                    <option key={"day"} value={"day"}>일간</option>
                </select>
                <select style={{marginLeft:"10px"}} onChange={(e) => setCategory(e.target.value)} >
                    <option key={999} value={''}>카테고리선택</option>
                    {
                        categoryList && categoryList.map((category, i) => <option key={i} value={category.category}>{category.category}</option> )
                    }
                </select>
            </Dropdown>
            <Input value={startDate} onChange={(event) => setStartDate(event.target.value)} />
            <Span>~</Span>
            <Input value={endDate} onChange={(event) => setEndDate(event.target.value)} />
            <Span>
                <SearchButton onClick={search}>search</SearchButton>
            </Span>
        </>
    )

};

const DateInput = (startDate, setStartDate, endDate, setEndDate, setPlatform, setRange, search, categoryList, setCategory) => {
    
    const [state, setState] = useState(startDate);
    const [state1, setState1] = useState(endDate);

    return [
        <Componeent startDate={state} endDate={state1} setStartDate={setStartDate} setEndDate={setEndDate} setPlatform={setPlatform} setRange={setRange} search={search} categoryList={categoryList} setCategory={setCategory} />,
        setState,
        setState1
    ]

};

export default DateInput;