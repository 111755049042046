
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import jwt_decode from 'jwt-decode';

const LoginForm = (props) => {

    const [gsiScriptLoaded, setGsiScriptLoaded] = React.useState(false);

    const handleCredentialResponse = async (response) => {        

        const params = {
            token : response.credential
        };
        
        const res = await fetch(`/v2/common/login/admin`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            method:'POST',
            body: JSON.stringify(params)
        });
        
        const responseJson = await res.json();
        
        if (responseJson.status === 'fail') {
            alert(responseJson.message);
            return;
        } else {
            sessionStorage.setItem('token', response.credential);
            props.history.push("/Home");
            window.location.reload();
        }

    };    

    useEffect(() => {

        document.body.classList.add("hold-transition");
        document.body.classList.add("login-page");

        document.body.classList.remove("hold-transition");
        document.body.classList.remove("skin-blue");
        document.body.classList.remove("sidebar-mini");

        document.getElementById("root").style.backgroundColor = "#ecf0f5";

        document.getElementById("root").style.backgroundColor = "";        

        const initializeGsi = () => {
            // Typescript will complain about window.google
            // Add types to your `react-app-env.d.ts` or //@ts-ignore it.
            if (!window.google || gsiScriptLoaded) return;
      
            setGsiScriptLoaded(true);
      
            window.google.accounts.id.initialize({
              client_id: '516796444310-1cavkjv08idnu2m94o7609rk0bslu5rg.apps.googleusercontent.com',
              callback: handleCredentialResponse,
            });
      
            window.google.accounts.id.renderButton(
              document.getElementById('buttonDiv'),
              {
                width: 330,
                theme: 'filled_blue',
                size: 'large',
              }, // customization attributes
            );
      
            // window.google.accounts.id.renderButton(document.getElementById('buttonDivMb'), {
            //   width: 300,
            //   theme: 'filled_blue',
            //   size: 'large',
            // });
        };

        const script = document.createElement('script');
        
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = initializeGsi;
        script.async = true;
        script.id = 'google-client-script';
        
        document.querySelector('body').appendChild(script);

        // const email = props.context ? localStorage.getItem(`remember-email-${props.context.user.accountInfo.email}`) : null;

        // if (email) {
        //     setEmail(email);
        // }

        return () => {
            
            window.google.accounts.id.cancel();
            
            if(document.getElementById('google-client-script')){
                document.getElementById('google-client-script').remove();
            }
        };

    }, []);

    return (
        <div className="login-box">
            <div className="login-logo">
                <a href="/">
                    <b>스티팝 관리자 로그인</b>
                </a>
            </div>
            <div className="login-box-body">
                <p className="login-box-msg">Sign in to start your session</p>
                <div id="buttonDiv"></div>
            </div>
        </div>
    );
};

export default withRouter(LoginForm);