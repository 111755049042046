import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

function OverviewDashboard(props) {
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
    } else {
      props.history.push("/");
    }
  }, []);

  return (
    <div
      // className="content-wrapper"
      style={{ minHeight: "100%" }}
    >
      <section className="content-header" style={{ marginBottom: "15px" }}>
        <h1>Marketing Dashboard</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/Dashboard/Overview">
              <i className="fa fa-dashboard"></i> Dashboard
            </a>
          </li>
          <li className="active">Marketing</li>
        </ol>
      </section>
      <section>
        <div>
          <font size={30}>추후 개발 예정</font>
        </div>
      </section>
    </div>
  );
}

export default withRouter(OverviewDashboard);
