import React from "react";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";

class GuideList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageNumber: this.props.pageNumber ? this.props.pageNumber : 1,
      type:
        this.props.location.state === undefined
          ? this.props.type
            ? this.props.type
            : "KOR"
          : this.props.location.state.type,
      idList: [],
      img: "",
      order: [],
      number: "",
      contents: "",
      show: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this._getGuideList(this.state.pageNumber, this.state.type);
      // this.setState({isLoading:false});
    } else {
      this.props.history.push("/");
    }
  }

  _getGuideList = (num, type) => {
    const params = "?pageNumber=" + num + "&type=" + this.state.type;
    fetch("/new/webAdmin/v2/guideline" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
      //    ,body: JSON.stringify(params)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          guideList: responseJson.result.guideList,
          // ,type : type
          isLoading: false,
          order: [],
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _checkBoxEvent = (event) => {
    const { idList } = this.state;
    const { value } = event.target;

    if (event.target.checked) {
      this.setState({ idList: idList.concat(value) });
    } else {
      this.setState({ idList: idList.filter((id) => id !== value) });
    }
  };

  // _delete = (c_id) => {

  //     if(this.state.idList.length === 0){
  //        alert('삭제할 아이템을 선택하세요');
  //        return;
  //     }

  //     const params = {
  //         idList: this.state.idList
  //     }

  //     fetch("/new/webAdmin/v2/guideline", {
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'Access-Control-Allow-Origin': '*',
  //             'token': sessionStorage.getItem("token")
  //         }
  //         ,method: 'delete'
  //         ,body: JSON.stringify(params)
  //      })
  //      .then((response) => response.json())
  //      .then((responseJson) => {
  //         this.state.idList = [];
  //         this._getContainerList(this.state.pageNumber, this.state.type);
  //      })
  //      .catch((error) => {
  //         console.error("123=" + error);
  //      });
  // }

  _guideSave = () => {
    const { number, contents, type } = this.state;
    const params = {
      number: number,
      contents: contents,
      lang: type,
    };

    fetch("/new/webAdmin/v2/guideline", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState(
          {
            number: "",
            contents: "",
          },
          () => this._getGuideList(this.state.pageNumber, this.state.type)
        );
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _guideListRender = () => {
    const { guideList } = this.state;

    let list = [];

    if (guideList && guideList.length !== 0) {
      for (const data of guideList) {
        list.push(
          <tr key={data.seq}>
            <td>{data.seq}</td>
            <td>
              <input
                type="checkbox"
                onChange={this._checkBoxEvent}
                value={data.seq}
              />
            </td>
            <td>{data.number}</td>
            <td>{data.contents}</td>
            <td>{data.regDate}</td>
          </tr>
        );
        this.state.order.push(data.tOrder);
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  handleClick = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { number, contents, type } = this.state;

    if (!this.state.isLoading) {
      return (
        <div
          //  className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header">
            <h1>컨테이너 관리</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/AThemeList">
                  <i className="fa fa-dashboard"></i>컨테이너
                </a>
              </li>
              <li className="active">컨테이너 관리</li>
            </ol>
          </section>
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div className="box-header with-border">
                    <select
                      style={{ display: "inline-block" }}
                      value={this.state.type}
                      onChange={(event) =>
                        this.setState({ type: event.target.value }, () =>
                          this._getGuideList(1)
                        )
                      }
                    >
                      <option value="KOR">한국어</option>
                      <option value="ENG">영어</option>
                    </select>
                    <div className="box-tools pull-right">
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="collapse"
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="remove"
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  {/* <!-- /.box-header --> */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th></th>
                            <th>번호</th>
                            <th>내용</th>
                            <th>등록일</th>
                          </tr>
                        </thead>
                        <tbody>{this._guideListRender()}</tbody>
                      </table>
                    </div>
                    <div className="box-footer clearfix">
                      <button
                        type="button"
                        className="btn btn-primary btn-block btn-flat"
                        style={{
                          width: "10%",
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                        onClick={this.handleClick}
                      >
                        등록
                      </button>
                      {/* <button type="button" onClick={this._delete} className="btn btn-primary btn-block btn-flat" style={{ width: '10%', display: "inline-block", marginRight: "10px" }}>
                                                삭제
                                            </button> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          {/* 팝업시작 */}
          <Modal
            show={this.state.show}
            onHide={this.handleClick}
            scrollable
            size="lg"
          >
            <Modal.Header closeButton>
              <h4 className="modal-title" style={{ textAlign: "center" }}>
                가이드 라인 입력
              </h4>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>번호</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="번호 입력"
                  value={number}
                  onChange={(event) =>
                    this.setState({ number: event.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>내용</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="내용 입력"
                  value={contents}
                  onChange={(event) =>
                    this.setState({ contents: event.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>언어</label>
                <select
                  className="form-control"
                  value={type}
                  onChange={(event) => {
                    this.setState({ type: event.target.value });
                  }}
                >
                  <option value="KOR">한국어</option>
                  <option value="ENG">영어</option>
                </select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-default pull-left"
                onClick={this.handleClick}
              >
                닫기
              </button>
              <button
                type="button"
                onClick={() => {
                  this._guideSave();
                  this.handleClick();
                }}
                className="btn btn-primary"
              >
                저장
              </button>
            </Modal.Footer>
          </Modal>
          {/* 팝업끝 */}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(GuideList);
