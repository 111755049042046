import React from 'react';
import {Container, Row, Col, Navbar, Button} from 'react-bootstrap'

const StudioBannerBottomBar = (props) => {
    return (
        <Navbar bg="light" expand="lg" style={{minWidth: "1000px", maxWidth: "1500px", margin: "10px"}} >
            <Container fluid>
                <Row>
                    <Col><a href="/Banner/Studio/Insert"><Button variant="success">추가</Button></a></Col>
                </Row>
            </Container>
        </Navbar>
    )
}

export default StudioBannerBottomBar;