import React from "react";
import OverviewDashboard from "../../Components/Dashboard/OverviewDashboard";
// import LeftMenu from "../../Components/LeftMenu";

const OverviewDashboardPage = ({ match, location }) => {
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <OverviewDashboard />
    </React.Fragment>
  );
};

export default OverviewDashboardPage;
