import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Table, Button } from 'react-bootstrap'
import { CardTypeDropdown } from '../CurationDropdown'

const CurationTable = props => {
  const { curationList, type, setEdit, getData, setType } = props
  const [checkedContainerIds, setCheckedContainerIds] = useState([])
  const checkRef = useRef([])

  useEffect(() => {
    console.log(curationList)
  }, [])

  const handleCheckBoxEvent = event => {
    if (event.target.checked) {
      setCheckedContainerIds(checkedContainerIds.concat(event.target.value))
    } else {
      setCheckedContainerIds(
        checkedContainerIds.filter(id => id !== event.target.value)
      )
    }
  }

  const clickDelete = () => {
    if (checkedContainerIds.length === 0) {
      alert('삭제할 아이템을 선택하세요')
      return
    }
    const params = {
      idList: checkedContainerIds,
    }
    fetch('/new/webAdmin/v2/curation', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'DELETE',
      body: JSON.stringify(params),
    })
      .then(() => {
        setCheckedContainerIds([])
        getData()
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const updateStatus = status => {
    const params = {
      idList: checkedContainerIds,
      status: status,
    }
    fetch('/new/webAdmin/v2/curation/status', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'put',
      body: JSON.stringify(params),
    })
      .then(() => {
        setCheckedContainerIds([])
        if (checkRef.current) {
          for (let i = 0; i < checkRef.current.length; i++) {
            if (checkRef.current[i] && checkRef.current[i].checked) {
              checkRef.current[i].checked = false
            }
          }
        }
        getData()
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const renderCuration = type => {
    return curationList
      .filter(item => item.type === type)
      .map((item, index) => (
        <tr key={item.cardId}>
          <td>{item.cardId}</td>
          <td>
            <input
              ref={el => (checkRef.current[index] = el)}
              type="checkbox"
              value={item.cardId}
              onChange={handleCheckBoxEvent}
            />
          </td>
          <td>{item.type}</td>
          <td>{item.title}</td>
          <td>{item.language}</td>
          <td>{item.country}</td>
          <td>{item.status}</td>
          <td>
            <Button
              onClick={() => {
                setEdit(item.cardId)
              }}
            >
              Edit
            </Button>
          </td>
        </tr>
      ))
  }

  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CardTypeDropdown type={type} setType={type => setType(type)} />
        <ButtonWrapper>
          <button
            style={{
              backgroundColor: '#007ffb',
            }}
            onClick={() => setEdit('new')}
          >
            New Banner
          </button>
          <button
            style={{ backgroundColor: '#dc143c' }}
            onClick={() => clickDelete()}
          >
            Delete
          </button>
          <button
            style={{ backgroundColor: '#ff4500' }}
            onClick={() => updateStatus('Y')}
          >
            Activate
          </button>
          <button
            style={{ backgroundColor: '#818181' }}
            onClick={() => updateStatus('N')}
          >
            Deactivate
          </button>
        </ButtonWrapper>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="10%">No.</th>
            <th width="5%"></th>
            <th width="15%">type</th>
            <th width="25%">Title</th>
            <th width="17%">Language</th>
            <th width="7%">Country</th>
            <th width="7%">상태값</th>
            <th width="7%">Edit</th>
          </tr>
        </thead>
        <tbody>{renderCuration(type)}</tbody>
      </Table>
    </Wrapper>
  )
}

export default CurationTable

const Wrapper = styled.div`
  width: 100%;
  height: 76vh;
  background-color: #fff;
  border-top: 5px solid skyblue;
  padding: 10px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;

  button {
    height: 35px;
    border: none;
    border-radius: 5px;
    padding: 0 10px;
    margin-left: 10px;
    color: #fff;

    &:hover {
      opacity: 0.8;
      font-weight: bold;
    }
  }
`
