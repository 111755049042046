import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BannerItem from './BannerItem'
import styled from 'styled-components'
import { AiOutlineEdit } from 'react-icons/ai'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const BannerListBox = styled.div`
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`
const Edit = styled.span`
  margin-left: 15px;
  font-size: 15px;
  &:hover {
    cursor: pointer;
    color: #ff4500;
    font-weight: bold;
  }
`

const BannerList = ({ bannerList, mode }) => {
  // useEffect(() => {
  //   console.log(bannerList)
  // }, [])

  const history = useHistory()

  const Scroll = e => {
    const list = document.getElementById('banner')
    list.scrollLeft += e.deltaY
  }

  const [dragging, setDragging] = useState(false)
  const [startX, setStartX] = useState()

  const Drag = e => {
    const list = document.getElementById('banner')
    e.preventDefault()
    setDragging(true)
    setStartX(e.pageX + list.scrollLeft)
  }
  const Move = e => {
    const list = document.getElementById('banner')
    if (dragging) {
      list.scrollLeft = startX - e.pageX
    }
  }
  const DragEnd = () => {
    setDragging(false)
  }

  return (
    <div>
      <section
        className="content-header"
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: !mode ? 'black' : 'white',
        }}
      >
        <h1>MD 추천 이모티콘</h1>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              Go to Curation page (Type B)
            </Tooltip>
          }
        >
          <Edit
            onClick={() =>
              history.push({
                pathname: '/Curation',
                state: {
                  pageNumber: 1,
                  type: 'B',
                  lang: bannerList ? bannerList.language : 'en',
                  country: bannerList ? bannerList.country : 'us',
                },
              })
            }
          >
            <AiOutlineEdit size={24} />
          </Edit>
        </OverlayTrigger>
      </section>
      <section
        onWheel={Scroll}
        onMouseDown={Drag}
        onMouseMove={Move}
        onMouseUp={DragEnd}
        onMouseLeave={DragEnd}
      >
        <BannerListBox id="banner">
          {bannerList ? (
            bannerList.card.map((item, index) => (
              <BannerItem
                key={index}
                lightColor={item.lightBackgroundCode}
                darkColor={item.darkBackgroundCode}
                cardImg={item.cardImgUrl}
                packageId={item.packageId}
                mode={mode}
              />
            ))
          ) : (
            <BannerListBox>데이터가 없습니다.</BannerListBox>
          )}
        </BannerListBox>
      </section>
    </div>
  )
}

export default BannerList
