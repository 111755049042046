// 태그 작업 보류됨 (2020.11~)

import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import {
  Row,
  Col,
  Navbar,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Collapse,
} from "react-bootstrap";
import { paging } from "../../Common/Page";
import BottomBar from "../../Components/Money/BottomBar.js";

const SdkInfo = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sdkInfo, setSdkInfo] = useState([]);
  const [params, setParams] = useState({
    date: props.date ? props.date : 30,
  });

  useEffect(() => {
    // componentDidMount
    const getData = async () => {
      await getSdkInfo();
      setIsLoading(false);
    };
    getData();
    return async () => {
      //componentWillUnmount
    };
  }, []);

  const getSdkInfo = async () => {
    const response = await fetch(
      `/new/webAdmin/v2/b2b/sdk?date=${params.date}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      }
    );

    const responseJson = await response.json();

    if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

    setSdkInfo(responseJson.body.sdkInfo);
  };

  if (!isLoading) {
    return (
      <div
        // className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header">
          <h1>sdk 현황</h1>
          <ol className="breadcrumb">
            <li>
              <a href="/SdkInfo">
                <i className="fa fa-dashboard"></i>B2B
              </a>
            </li>
            <li className="active">sdk 현황</li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <section className="col-lg-12 connectedSortable">
              <div className="box box-info">
                <div
                  className="box-header with-border"
                  style={{ paddingBottom: "30px", display: "inline" }}
                >
                  <Navbar bg="light" expand="lg">
                    <Col>
                      <Row>
                        <DropdownButton
                          variant="dark"
                          id="commit-status-dropdown"
                          title={params.date ? params.date + "일" : "기간"}
                          style={{ marginRight: 10 }}
                        >
                          <Dropdown.Item
                            key={1}
                            onClick={() => {
                              setParams((state) => ({ ...state, date: 1 }));
                            }}
                          >
                            1일
                          </Dropdown.Item>
                          <Dropdown.Item
                            key={7}
                            onClick={() => {
                              setParams((state) => ({ ...state, date: 7 }));
                            }}
                          >
                            7일
                          </Dropdown.Item>
                          <Dropdown.Item
                            key={30}
                            onClick={() => {
                              setParams((state) => ({ ...state, date: 30 }));
                            }}
                          >
                            30일
                          </Dropdown.Item>
                          <Dropdown.Item
                            key={100}
                            onClick={() => {
                              setParams((state) => ({ ...state, date: 100 }));
                            }}
                          >
                            100일
                          </Dropdown.Item>
                        </DropdownButton>
                        <Button onClick={getSdkInfo}>조회</Button>
                      </Row>
                    </Col>
                  </Navbar>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>회원가입</th>
                          <th>최초 SDK API 콜</th>
                          <th>Unique User ID 20 이상</th>
                          <th>Unique USer ID 1,000 이상</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sdkInfo && (
                          <>
                            <tr key={1}>
                              <td>{sdkInfo.totalSignupCount}</td>
                              <td>{sdkInfo.firstSdkApiCallCount}</td>
                              <td>{sdkInfo.uniqueUserCount20}</td>
                              <td>{sdkInfo.uniqueUserCount999}</td>
                            </tr>
                            <tr key={2}>
                              <td>
                                {sdkInfo.firstSdkApiCallCount !== 0 &&
                                sdkInfo.totalSignupCount !== 0
                                  ? (
                                      sdkInfo.firstSdkApiCallCount /
                                      sdkInfo.totalSignupCount
                                    ).toFixed(2) *
                                      100 +
                                    "%"
                                  : 0}
                              </td>
                              <td>
                                {sdkInfo.uniqueUserCount20 !== 0 &&
                                sdkInfo.firstSdkApiCallCount !== 0
                                  ? (
                                      sdkInfo.uniqueUserCount20 /
                                      sdkInfo.firstSdkApiCallCount
                                    ).toFixed(2) *
                                      100 +
                                    "%"
                                  : 0}
                              </td>
                              <td>
                                {sdkInfo.uniqueUserCount999 !== 0 &&
                                sdkInfo.uniqueUserCount20 !== 0
                                  ? (
                                      sdkInfo.uniqueUserCount999 /
                                      sdkInfo.uniqueUserCount20
                                    ).toFixed(2) *
                                      100 +
                                    "%"
                                  : 0}
                              </td>
                              <td></td>
                            </tr>
                            <tr key={3}>
                              <td>{sdkInfo.aTob + "분"}</td>
                              <td>{sdkInfo.bToC + "분"}</td>
                              <td>{sdkInfo.cToD + "분"}</td>
                              <td></td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default withRouter(SdkInfo);
