import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { deletePost } from './blogApi';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  .title{
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #d2d6de;
    cursor: default;
  }
  .items{
    height: 90px;
    margin: 10px 0;
    align-items: center;
    :hover{
      background-color: rgba(0,0,0,0.1);
    }
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  cursor: pointer;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({width})=> width?width:'20px'};
  /* justify-content: center; */
  align-items: center;
`

const Button = styled.div`
  cursor: pointer;
  background-color: ${({edit})=>edit?'#d2d6de':'#ff4500'};
  color: ${({edit})=>edit?'#000':'#fff'};
  margin: 0 2px;
  padding: 2px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  :hover{
    background-color: ${({edit})=>edit?'#A0A4AC':'#B90000'};;
  }
`

const PostItem = ({post, pageNumber, index, isAll, setRefresh}) => {
  // useEffect(()=>{
  //   console.log(post)
  // },[])
  const history = useHistory();
  const moveEdit = (e, post) => { 
    e.preventDefault();
    history.push({
      pathname: '/Blog/edit',
      state: {
        post: post
      }
    })
  }

  const onDeletePost = async(post_id) => {
    // have to image list cascade
    const deleteRes = await deletePost(post_id);
    console.log(deleteRes)
    if(deleteRes === 1){
      setRefresh(true)
    }
  }

  const moveDelete = (e, post) => {
    e.preventDefault();
    e.stopPropagation();
    if(window.confirm("정말 삭제하시겠습니까? 삭제 시 복구 불가합니다.")){
      // delete post (by post_id) api call
      onDeletePost(post.post_id)
    }
  }

  return(
    <Row className='items' onClick={(e)=>moveEdit(e, post)}>
      <Col width={'5%'}>{(pageNumber-1)*6+(index+1)+isAll}</Col>
      <Col width={'20%'}>
        <img src={post.main_image} height={90} />
      </Col>
      <Col width={'50%'}>{post.title}</Col>
      <Col width={'10%'}>{post.date}</Col>
      <Col width={'15%'}>
        <div style={{display:'flex'}}>
          <Button edit={true} onClick={(e)=>moveEdit(e, post)}>edit</Button>
          <Button edit={false} onClick={(e)=>moveDelete(e, post)}>delete</Button>
        </div>
      </Col>
    </Row>
  )
}

const PostList = ({ postList, pageNumber, isAll, setRefresh }) => {
  return(
    <Wrapper>
      <Row className='title'>
        <Col width={'5%'}>No.</Col>
        <Col width={'20%'}>main image</Col>
        <Col width={'50%'}>title</Col>
        <Col width={'10%'}>date</Col>
        <Col width={'15%'}>edit</Col>
      </Row>
      {postList && postList.map((post, index)=>{
        return (
          <PostItem 
            post={post} 
            pageNumber={pageNumber}
            index={index} 
            key={`post_list_${index}`}
            isAll={isAll?1:0}
            setRefresh={setRefresh}
            // onClick={moveEdit(post)}
          />
        )
      })}
    </Wrapper>
  )
}

export default PostList;