import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { AiOutlinePaperClip } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { useRef } from "react";
import AttachPreviewBox from "../../Common/BoxUI/AttachPreviewBox";
import Linkify from "react-linkify";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: 14px;
`;

const ChatCard = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;

const ChatCardMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const ChatCardTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
`;

const ChatCardBottom = styled.div`
  display: flex;
  margin-top: 6px;
`;

const ChatName = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const ChatTime = styled.div`
  font-size: 12px;
  color: #999;
`;

const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 12px;
  border: 1px solid #d2d6de;
  background-color: #d2d6de;
  color: #444;
  border-radius: 5px;
  line-height: 1.8;
`;

const ChatAttachBox = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  margin-left: 4px;
  align-items: center;
  cursor: pointer;
`;

const ChatAttachIcon = styled(AiOutlinePaperClip)`
  padding: 2px;
  background-color: #f7f9fc;
  color: #7b7b7b;
`;

const ChatAttach = styled.div`
  margin: 4px 0px 2px 4px;
  color: #7b7b7b;
`;

const AddMessageDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  border-top: 1px solid #e8e9ed;
`;

const AddMessageTop = styled.div`
  display: flex;
  align-items: center;
`;

const AddMessageBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  font-size: 14px;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #d2d6de;
`;

const InputMessage = styled.textarea`
  font-size: 14px;
  width: 100%;
  background-color: transparent;
  color: #0c0b24;
  padding: 10px;
  resize: none;
  border: 0px;
  /* ::-webkit-scrollbar {
    display: none;
  } */
  ::placeholder {
    color: #b3b3b3;
  }
`;

const IconClip = styled(AiOutlinePaperClip)`
  color: #7b7b7b;
  padding: 0px 4px;
  margin: 0px 4px;
  cursor: pointer;
  border-right: 1px solid #e6e6e6;
`;

const IconSend = styled(FiSend)`
  color: #7b7b7b;
  padding: 0px 4px;
  margin: 0px 4px;
  cursor: pointer;
`;

const MentionAttachBox = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  margin-top: 4px;
`;

const MentionAttachIcon = styled(AiOutlinePaperClip)`
  padding: 2px;
  background-color: #f7f9fc;
  color: #7b7b7b;
`;

const MentionAttachText = styled.div`
  color: #7b7b7b;
  margin-left: 6px;
`;

const InvisibleInput = styled.input`
  display: none;
`;

const CloseStatusText = styled.div`
  padding: 6px 12px;
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #f86368;
  color: #fff;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const CloseCheckForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  align-self: flex-start;
`;

const CloseCheckBox = styled.input`
  margin-left: 5px;
  margin-right: 5px;
`;

const MessageQnaChat = ({
  selectedRoom,
  setRefreshRoom,
  setIsClosed,
  isClosed,
  setLastSender,
}) => {
  const [qnaChat, setQnaChat] = useState();

  const [inputMessage, setInputMessage] = useState("");
  const [inputFileName1, setInputFileName1] = useState("");
  const [inputFileName2, setInputFileName2] = useState("");
  const [inputFileName3, setInputFileName3] = useState("");
  const [inputFile1, setInputFile1] = useState("");
  const [inputFile2, setInputFile2] = useState("");
  const [inputFile3, setInputFile3] = useState("");
  const filesRef = useRef(null);
  const [onAdding, setOnAdding] = useState(false);
  const [isAttachHover, setIsAttachHover] = useState(false);

  const [isCloseChecked, setIsCloseChecked] = useState(false);

  useEffect(() => {
    setIsClosed(selectedRoom.roomResult[0].roomStatus === 0);
    const tempRoomData = selectedRoom.qnaResult.concat(
      selectedRoom.replyResult
    );
    tempRoomData.sort((a, b) => {
      return new Date(a.regDate) - new Date(b.regDate);
    });
    if (tempRoomData.length > 0) {

      setLastSender(tempRoomData[tempRoomData.length - 1].type);
    }
    setQnaChat(tempRoomData);
  }, []);

  const addOneArtistQnaInRoom = async () => {
    if (inputMessage === "") {
      alert("Please type your message.");
      return;
    }

    setOnAdding(true);

    try {
      let formData = new FormData();
      formData.append("files", filesRef.current.files[0]);
      formData.append("files", filesRef.current.files[1]);
      formData.append("files", filesRef.current.files[2]);
      formData.append("comment", inputMessage);
      formData.append("roomStatus", isCloseChecked ? 0 : 1);

      const res = await axios.post(
        `/new/webAdmin/v2/message-center/room/${selectedRoom.roomResult[0].roomId}`,
        formData,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        setInputMessage("");
        setInputFileName1("");
        setInputFileName2("");
        setInputFileName3("");
        setInputFile1("");
        setInputFile2("");
        setInputFile3("");
        setOnAdding(false);
        if (isCloseChecked) {
          setIsClosed(true);
        }
        setRefreshRoom(true);
      }
    } catch (error) {
      console.log(error);
    }

    setOnAdding(false);
  };

  const onClickSend = () => {
    if (!onAdding) addOneArtistQnaInRoom();
  };

  const onClickAttach = (path) => {
    window.open(path, "_blank");
  };

  return (
    <Wrapper>
      {qnaChat &&
        qnaChat.map((chat, index) => {
          return chat.type === 0 ? (
            <ChatCard key={index}>
              <ChatCardMain>
                <ChatCardTop>
                  <ChatName>{selectedRoom.roomResult[0].userName}</ChatName>
                  <ChatTime>{chat.subDate}</ChatTime>
                </ChatCardTop>
                <ChatCardBottom>
                  <ChatContent>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      {chat.contents.split("\n").map((line, index) => {
                        return <span key={index}>{line}</span>;
                      })}
                    </Linkify>
                  </ChatContent>
                </ChatCardBottom>
                {chat.attach &&
                  chat.attach.map((file, attachIndex) => {
                    return (
                      <ChatAttachBox
                        onClick={() => {
                          onClickAttach(file.attachPath);
                        }}
                        key={attachIndex}
                      >
                        <ChatAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover((index + 1) * (attachIndex + 1));
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                          size={24}
                        />
                        <ChatAttach>{file.attachName}</ChatAttach>
                        {isAttachHover === (index + 1) * (attachIndex + 1) && (
                          <AttachPreviewBox url={file.attachPath} />
                        )}
                      </ChatAttachBox>
                    );
                  })}
              </ChatCardMain>
            </ChatCard>
          ) : (
            <ChatCard key={index} style={{ justifyContent: "flex-end" }}>
              <ChatCardMain>
                <ChatCardTop>
                  <ChatTime>{chat.subDate}</ChatTime>
                  <ChatName>Stipop</ChatName>
                </ChatCardTop>
                <ChatCardBottom>
                  <ChatContent
                    style={{
                      backgroundColor: "#f39c12",
                      color: "#fff",
                      border: "1px solid #f39c12",
                    }}
                  >
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      {chat.contents.split("\n").map((line, index) => {
                        return <span key={index}>{line}</span>;
                      })}
                    </Linkify>
                  </ChatContent>
                </ChatCardBottom>
                {chat.attach &&
                  chat.attach.map((file, attachIndex) => {
                    return (
                      <ChatAttachBox
                        onClick={() => {
                          onClickAttach(file.attachPath);
                        }}
                        key={attachIndex}
                      >
                        <ChatAttachIcon
                          size={24}
                          onMouseEnter={() => {
                            setIsAttachHover(
                              (index + 1) * (attachIndex + 1) * 2
                            );
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <ChatAttach>{file.attachName}</ChatAttach>
                        {isAttachHover ===
                          (index + 1) * (attachIndex + 1) * 2 && (
                          <AttachPreviewBox url={file.attachPath} />
                        )}
                      </ChatAttachBox>
                    );
                  })}
              </ChatCardMain>
            </ChatCard>
          );
        })}
      {isClosed && (
        <CloseStatusText>This 1:1 message is closed.</CloseStatusText>
      )}
      {!isClosed && (
        <AddMessageDiv>
          <CloseCheckForm>
            <CloseCheckBox
              onClick={() => {
                setIsCloseChecked(!isCloseChecked);
              }}
              type={"checkbox"}
            />
            Close after sending message.
          </CloseCheckForm>
          <AddMessageTop>
            <InputBox>
              <InputMessage
                placeholder={"Type your message..."}
                value={inputMessage}
                rows={8}
                onChange={(e) => {
                  setInputMessage(e.target.value);
                }}
              />
              <IconClip
                size={40}
                onClick={() => {
                  let [el] = window.document.getElementsByClassName(
                    "react-invisible-input"
                  );

                  el.click();
                }}
              />
              <IconSend size={34} onClick={onClickSend} />
            </InputBox>
          </AddMessageTop>
          <AddMessageBottom>
            {inputFile1 && inputFile1 !== "" && (
              <MentionAttachBox>
                <MentionAttachIcon size={24} />
                <MentionAttachText>{inputFileName1}</MentionAttachText>
              </MentionAttachBox>
            )}
            {inputFile2 && inputFile2 !== "" && (
              <MentionAttachBox>
                <MentionAttachIcon size={24} />
                <MentionAttachText>{inputFileName2}</MentionAttachText>
              </MentionAttachBox>
            )}
            {inputFile3 && inputFile3 !== "" && (
              <MentionAttachBox>
                <MentionAttachIcon size={24} />
                <MentionAttachText>{inputFileName3}</MentionAttachText>
              </MentionAttachBox>
            )}
            <InvisibleInput
              type={"file"}
              multiple
              className={"react-invisible-input"}
              accept={"image/jpg,image/jpeg,image/gif,image/png"}
              ref={filesRef}
              onChange={() => {
                if (filesRef.current.files.length !== 0) {
                  if (filesRef.current.files[3]) {
                    alert("Attach files are maximum 3.");
                    return;
                  }
                  if (filesRef.current.files[0]) {
                    setInputFileName1(filesRef.current.files[0].name);
                    setInputFile1(
                      URL.createObjectURL(filesRef.current.files[0])
                    );
                  } else {
                    setInputFileName1("");
                    setInputFile1("");
                  }
                  if (filesRef.current.files[1]) {
                    setInputFileName2(filesRef.current.files[1].name);
                    setInputFile2(
                      URL.createObjectURL(filesRef.current.files[1])
                    );
                  } else {
                    setInputFileName2("");
                    setInputFile2("");
                  }
                  if (filesRef.current.files[2]) {
                    setInputFileName3(filesRef.current.files[2].name);
                    setInputFile3(
                      URL.createObjectURL(filesRef.current.files[2])
                    );
                  } else {
                    setInputFileName3("");
                    setInputFile3("");
                  }
                }
              }}
            />
          </AddMessageBottom>
        </AddMessageDiv>
      )}
    </Wrapper>
  );
};

export default MessageQnaChat;
