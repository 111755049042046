import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CreateEdit from '../../Components/AI/CreateEdit'
import styled from 'styled-components'

const CreatePage = () => {
  const [lang, setLang] = useState('en')
  const [version, setVersion] = useState('')
  const [type, setType] = useState(0)
  const [typeList, setTypeList] = useState([])
  const [bannerList, setBannerList] = useState([])
  const [copy, setCopy] = useState(null)
  const [copyTarget, setCopyTarget] = useState(null)
  const versionList = ['', 'A', 'B']

  const getDetail = (lang, version) => {
    axios
      .get(
        `/new/webAdmin/v2/ai/create/${lang}?version=${version ? version : ''}`,
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(({ data }) => {
        setTypeList(data.result)
        if (data.result.length > 0) {
          // setType(type ? type : data.result[0].SEQ)
          setType(
            data.result.filter(res => res.SEQ === type).length > 0
              ? type
              : data.result[0].SEQ
          )
          if (type) {
            setBannerList(
              data.result.filter(item => item.SEQ === type).length > 0
                ? data.result
                    .filter(item => item.SEQ === type)[0]
                    .banner.sort((a, b) => a.ORDER - b.ORDER)
                : []
            )
          } else {
            setBannerList([])
          }
        } else {
          setType(0)
        }
      })
  }

  useEffect(() => {
    getDetail(lang, version)
    setCopy(null)
    setCopyTarget(null)
  }, [lang, version])

  useEffect(() => {
    if (type && typeList.filter(item => item.SEQ === type)) {
      setBannerList(
        typeList.filter(item => item.SEQ === type).length > 0
          ? typeList
              .filter(item => item.SEQ === type)[0]
              .banner.sort((a, b) => a.ORDER - b.ORDER)
          : []
      )
    } else {
      setBannerList([])
    }
  }, [type])

  const clickCopy = () => {
    axios
      .put(
        `/new/webAdmin/v2/ai/create/copy?v=${copy}&t=${copyTarget}`,
        {},
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        setVersion(copyTarget)
      })
  }

  return (
    <>
      {copy !== null && copyTarget !== null && (
        <ModalWrapper>
          <ModalBox>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <span>
                현재 버전({copy ? copy : '-'})을 {copyTarget ? copyTarget : '-'}
                에 덮어쓰시겠습니까?
              </span>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    clickCopy()
                  }}
                >
                  확인
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setCopy(null)
                    setCopyTarget(null)
                  }}
                >
                  취소
                </button>
              </div>
            </div>
          </ModalBox>
        </ModalWrapper>
      )}
      <CreateEdit
        lang={lang}
        setLang={setLang}
        version={version}
        setVersion={setVersion}
        type={type}
        setType={setType}
        typeList={typeList}
        bannerList={bannerList}
        versionList={versionList}
        getDetail={getDetail}
        copy={copy}
        setCopy={setCopy}
        setCopyTarget={setCopyTarget}
      />
    </>
  )
}

export default CreatePage

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`

const ModalBox = styled.div`
  width: 400px;
  height: 150px;
  background-color: #fff;
  border-top: 3px solid #00c0ef;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`
