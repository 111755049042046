import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ContestEdit from '../../Components/AI/ContestEdit'

const ContestPage = () => {
  const [year, setYear] = useState('')
  const [contest, setContest] = useState('')
  const [contestList, setContestList] = useState([])
  const [yearList, setYearList] = useState([])
  const [contestPackageList, setContestPackageList] = useState([])

  const getContest = async year => {
    await axios
      .get(`/new/webAdmin/v2/ai/contest?year=${year}`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then(({ data }) => {
        setContestList(data.result.contestList.sort((a, b) => a.year - b.year))
        setYearList([''].concat(data.result.yearList.map(item => item.year)))
        console.log(contest)
        if (contest) {
          const packageList = data.result.contestList.filter(
            item => item.contestId === contest
          )[0]
          if (packageList) {
            setContestPackageList(packageList.packages)
          }
        } else {
          setContestPackageList([])
        }
      })
  }

  useEffect(() => {
    if (contest) {
      setContestPackageList(
        contestList.filter(item => item.contestId === contest)[0].packages
      )
    } else {
      setContestPackageList([])
    }
  }, [contest])

  return (
    <>
      <ContestEdit
        getContest={getContest}
        year={year}
        setYear={setYear}
        contest={contest}
        setContest={setContest}
        contestList={contestList}
        yearList={yearList}
        contestPackageList={contestPackageList}
      />
    </>
  )
}

export default ContestPage
