import axios from "axios";
import React from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import MessageQnaListCard from "./MessageQnaListCard";
import { BiCaretLeftSquare, BiCaretRightSquare } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { MdArrowDropDown, MdClear } from "react-icons/md";
import DropdownBox from "../../Common/BoxUI/DropdownBox";
import DefaultButton from "../../Common/ButtunUI/DefaultButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const TableControlArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  user-select: none;
  font-size: 14px;
`;

const PrevNextBox = styled.div`
  display: flex;
`;

const PrevButton = styled.div`
  padding: 8px;
  margin-right: 4px;
  cursor: pointer;
`;

const NextButton = styled.div`
  padding: 8px;
  margin-left: 4px;
  cursor: pointer;
`;

const PageTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputBox = styled.div`
  display: flex;
`;

const CurrentPageInput = styled.input`
  display: flex;
  text-align: center;
  width: 40px;
  border: 1px solid #d2d6de;
  ::placeholder {
    padding: 6px 12px;
  }
`;

const InputTag = styled.div`
  margin-top: 1px;
  margin-left: 4px;
`;

const MaxPageText = styled.div`
  font-size: 12px;
  color: #666666;
`;

const FilterDiv = styled.div`
  display: flex;
`;

const StatusFilterDropDown = styled.div`
  margin: 0px 15px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const StatusFilterTag = styled.div`
  font-weight: bold;
  margin-right: 6px;
`;

const StatusFilterShowBox = styled.div`
  display: flex;
  background-color: #fff;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d2d6de;
  color: #333;
  width: 125px;
  min-height: 35px;
`;

const CategoryFilterDropdown = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const CategoryFilterTag = styled.div`
  font-weight: bold;
  margin-right: 6px;
`;

const CategoryFilterShowBox = styled.div`
  display: flex;
  background-color: #fff;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d2d6de;
  color: #333;
  width: 120px;
  min-height: 35px;
`;

const OpenedByFilterDropdown = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

const OpenedByFilterTag = styled.div`
  font-weight: bold;
  margin-right: 6px;
`;

const OpenedByFilterShowBox = styled.div`
  display: flex;
  background-color: #fff;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d2d6de;
  color: #333;
  width: 120px;
  min-height: 35px;
`;

const TableHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 10px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
`;

const TableColumnBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
  border: 1px solid #f4f4f4;
  border-bottom-width: 3px;
  :not(:first-child) {
    border-left: 0px;
  }
`;

const SearchDiv = styled.div`
  width: 470px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  input:focus {
    outline: none;
  }
`;

const SearchArtistDropdown = styled.div`
  position: relative;
  display: flex;
`;

const SearchArtistShowBox = styled.div`
  display: flex;
  width: 80px;
  height: 35px;
  background-color: transparent;
  color: #333;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d2d6de;
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const SelectArtistShowText = styled.div`
  margin: 0px 4px;
`;

const SearchArtistInput = styled.input`
  width: 100%;
  height: 35px;
  background-color: transparent;
  padding: 4px 8px;
  border: 1px solid #d2d6de;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const SelectedArtistTagBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 35px;
  background-color: transparent;
  padding: 3px 8px;
  border: 1px solid #d2d6de;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const SelectedArtistTag = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #e7e7e7;
  border: 1px solid #adadad;
  color: #333;
`;

const ClearIcon = styled(MdClear)`
  margin-left: 4px;
  padding: 1px;
  cursor: pointer;
`;

const AutoSuggestDiv = styled.div`
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 80px);
  left: 80px;
  top: 33px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px 0px;
  border: 1px solid #d2d6de;
  border-top: 0px;
  color: #333;
`;

const AutoSuggestBox = styled.div`
  padding: 5px 15px;
  cursor: pointer;
  :hover {
    background-color: #ddd;
  }
`;

const tablePosition = [
  { name: "#", colWidth: "5%", colValue: "roomId" },
  { name: "Artist (ID)", colWidth: "20%", colValue: "userName" },
  { name: "Title", colWidth: "39%", colValue: "roomTitle" },
  { name: "Category", colWidth: "8%", colValue: "roomType" },
  { name: "Status", colWidth: "10%", colValue: "roomStatus" },
  { name: "Artist Open", colWidth: "8%", colValue: "readStatus" },
  { name: "Last Updated", colWidth: "10%", colValue: "subDate" },
];

const categoryOptions = [
  { value: "All", label: "All" },
  { value: "gu", label: "Guidelines" },
  { value: "reg", label: "Upload" },
  { value: "revi", label: "Review" },
  { value: "la", label: "Launch" },
  { value: "reve", label: "Revenue" },
  { value: "oth", label: "Others" },
];

const statusOptions = [
  { value: "All", label: "All" },
  { value: "2", label: "Must respond" },
  { value: "1", label: "Responded" },
  { value: "0", label: "Closed" },
  { value: "3", label: "Sent" },
];

const regByOptions = [
  { value: "All", label: "All" },
  { value: "0", label: "Creator" },
  { value: "1", label: "Stipop" },
];

const searchTypeOptions = [
  { value: "Term", label: "Term" },
  { value: "ID", label: "ID" },
];

const MessageQnaList = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [qnaRoomList, setQnaRoomList] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [minPage, setMinPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [typeFilter, setTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [regByFilter, setRegByFilter] = useState("");

  const [isFilterLoaded, setIsFilterLoaded] = useState(false);
  // gu, reg, revi, la, reve, oth

  const [showCategoryDrop, setShowCategoryDrop] = useState(false);
  const [showStatusDrop, setShowStatusDrop] = useState(false);
  const [showOpendByDrop, setShowOpenedByDrop] = useState(false);

  const [inputArtist, setInputArtist] = useState("");
  const [selectedArtistId, setSelectedArtistId] = useState(-1);

  const [searchedArtist, setSearchedArtist] = useState("");
  const [suggestList, setSuggestList] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [showSearchTypeDrop, setShowSearchTypeDrop] = useState(false);
  const [searchType, setSearchType] = useState("Term");
  const [onSearch, setOnSearch] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      if (!isFilterLoaded) {
        if (
          localStorage.getItem("category") &&
          localStorage.getItem("status") &&
          localStorage.getItem("regBy")
        ) {
          setTypeFilter(localStorage.getItem("category"));
          setStatusFilter(localStorage.getItem("status"));
          setRegByFilter(localStorage.getItem("regBy"));
        } else {
          setTypeFilter("All");
          setStatusFilter("All");
          setRegByFilter("All");
        }
        // 타임존 체크 test 용
        console.log(new Date().getTimezoneOffset());
        setMinPage(1);
        const pathname = history.location.pathname.split("/");
        if (pathname[pathname.length - 1] !== "List") {
          setPageIndex(pathname[pathname.length - 1] - 0);
        }
        setIsFilterLoaded(true);
      }
    }
  }, []);

  useEffect(() => {
    if (isFilterLoaded) {
      getMessageQnaList();
    }
  }, [isFilterLoaded]);

  useEffect(() => {
    if (!isLoading) {
      getMessageQnaList();
    }
  }, [statusFilter, regByFilter, typeFilter, selectedArtistId]);

  useEffect(() => {
    if (!isLoading) {
      getMessageQnaList();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (!isLoading) {
      const pathname = history.location.pathname.split("/");
      if (pathname[pathname.length - 1] !== "List") {
        setPageIndex(pathname[pathname.length - 1] - 0);
      }
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (searchedArtist !== "" && searchedArtist.length >= 3) {
      getSomeUsersByTerm();
    } else {
      setSuggestList([]);
    }
  }, [searchedArtist]);

  useEffect(() => {
    if (selectedArtistId !== -1 && !onSearch) {
      searchArtistById();
    }
  }, [selectedArtistId]);

  const searchArtistById = async () => {
    setOnSearch(true);

    try {
      const res = await axios.get(
        `/new/webAdmin/v2/message-center/send/search/${selectedArtistId}`,
        {
          headers: { token: sessionStorage.getItem("token") },
        }
      );
      if (res.status === 200) {
        if (res.data.body.length !== 0) {
          setSelectedArtist(res.data.body[0]);
        } else {
          alert("Please check Artist ID.");
          setSelectedArtist(null);
          setSelectedArtistId(-1);
        }
        setOnSearch(false);
      }
    } catch (error) {
      console.error(error);
      alert("Please check Artist ID.");
      setSelectedArtist(null);
      setSelectedArtistId(-1);
      return;
    }

    setOnSearch(false);
  };

  const getMessageQnaList = async () => {
    setIsLoading(true);

    const req = {
      userId: selectedArtistId === -1 ? "" : selectedArtistId,
      status: statusFilter === "All" ? "" : statusFilter,
      regBy: regByFilter === "All" ? "" : regByFilter,
      type: typeFilter === "All" ? "" : typeFilter,
      // startIndex: pageIndex * 20,
      endIndex: 20,
    };
    await axios
      .get(`/new/webAdmin/v2/message-center/rooms/list/${pageIndex - 1}`, {
        params: req,
        headers: { token: sessionStorage.getItem("token") },
      })
      .then((res) => {
        if (res.status === 200) {
          setQnaRoomList(res.data.rooms.roomResult);
          setMaxPage(res.data.rooms.pageMap.pageCount);
        }
      });

    setIsLoading(false);
  };

  const getSomeUsersByTerm = async () => {
    try {
      const res = await axios.get(
        `/new/webAdmin/v2/message-center/send/search/users/${searchedArtist}`,
        {
          headers: { token: sessionStorage.getItem("token") },
        }
      );

      if (res.status === 200) {
        const tempList = res.data.body.suggestList;
        setSuggestList(tempList[0]);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const onClickPrev = () => {
    if (pageIndex > minPage) {
      setPageIndex(pageIndex - 1);
      history.push(`/Message/Qna/List/${pageIndex - 1}`);
    }
  };

  const onClickNext = () => {
    if (pageIndex <= maxPage - 1) {
      setPageIndex(pageIndex + 1);
      history.push(`/Message/Qna/List/${pageIndex + 1}`);
    }
  };

  const onClickCard = (room) => {
    history.push({ pathname: `/Message/Qna/Detail/${room.roomId}` });
  };

  return (
    <Wrapper>
      {!isLoading && (
        <Fragment>
          <TableControlArea>
            <SearchDiv>
              {selectedArtistId === -1 &&
                searchType !== "ID" &&
                suggestList &&
                inputArtist.length !== 0 && (
                  <AutoSuggestDiv>
                    {inputArtist.length > 2 ? (
                      suggestList.length !== 0 ? (
                        suggestList.map((item, index) => {
                          return (
                            <AutoSuggestBox
                              key={index}
                              onClick={() => {
                                setSelectedArtistId(item.userId);
                              }}
                            >
                              <strong>{item.userName}</strong>
                              <small>({item.userId})</small>
                              {" - "}
                              {item.userEmail}
                            </AutoSuggestBox>
                          );
                        })
                      ) : (
                        <AutoSuggestBox>No search result.</AutoSuggestBox>
                      )
                    ) : (
                      <AutoSuggestBox>
                        Please input at least 3 letters.
                      </AutoSuggestBox>
                    )}
                  </AutoSuggestDiv>
                )}
              <SearchArtistDropdown>
                <SearchArtistShowBox
                  onClick={() => {
                    setShowSearchTypeDrop(!showSearchTypeDrop);
                  }}
                >
                  <SelectArtistShowText>{searchType}</SelectArtistShowText>
                  <MdArrowDropDown size={18} />
                </SearchArtistShowBox>
                {showSearchTypeDrop && (
                  <DropdownBox
                    wrapperStyle={{ width: 80 }}
                    setSelectItem={setSearchType}
                    setShowItemDrop={setShowSearchTypeDrop}
                    itemList={searchTypeOptions}
                  />
                )}
              </SearchArtistDropdown>
              {selectedArtistId === -1 ? (
                <SearchArtistInput
                  value={inputArtist}
                  onChange={(e) => {
                    setInputArtist(e.target.value);
                    if (searchType !== "ID") setSearchedArtist(e.target.value);
                  }}
                />
              ) : (
                <SelectedArtistTagBox>
                  {selectedArtist !== null && (
                    <SelectedArtistTag>
                      <strong>{selectedArtist.u_name}</strong>
                      <small>({selectedArtist.u_id})</small>
                      <span style={{ marginLeft: 3 }}>
                        - {selectedArtist.u_email}
                      </span>
                      <ClearIcon
                        size={18}
                        onClick={() => {
                          setInputArtist("");
                          setSearchedArtist("");
                          setSelectedArtistId(-1);
                          setSelectedArtist(null);
                        }}
                      />
                    </SelectedArtistTag>
                  )}
                </SelectedArtistTagBox>
              )}
              {searchType === "ID" && selectedArtistId === -1 && (
                <DefaultButton
                  style={{ marginLeft: "10px", width: "100px" }}
                  btnText={"Search"}
                  theme={"green"}
                  onClick={() => {
                    setSelectedArtistId(inputArtist);
                  }}
                />
              )}
              {searchType !== "ID" && null}
            </SearchDiv>
            <PrevNextBox>
              <PrevButton onClick={onClickPrev}>
                <BiCaretLeftSquare size={24} />
              </PrevButton>
              <PageTextBox>
                <InputBox>
                  <CurrentPageInput
                    value={pageIndex}
                    onChange={(e) => {
                      if (
                        e.target.value >= minPage &&
                        e.target.value <= maxPage
                      ) {
                        setPageIndex(e.target.value);
                      } else {
                        alert(
                          `Please input between ${minPage} and ${maxPage}.`
                        );
                        setPageIndex(1);
                      }
                    }}
                  />
                  <InputTag>Page</InputTag>
                </InputBox>
                <MaxPageText>
                  ({minPage} to {maxPage})
                </MaxPageText>
              </PageTextBox>
              <NextButton onClick={onClickNext}>
                <BiCaretRightSquare size={24} />
              </NextButton>
            </PrevNextBox>
            <FilterDiv>
              <CategoryFilterDropdown>
                <CategoryFilterTag>Category</CategoryFilterTag>
                <CategoryFilterShowBox
                  onClick={() => {
                    setShowCategoryDrop(!showCategoryDrop);
                    setShowStatusDrop(false);
                    setShowOpenedByDrop(false);
                  }}
                >
                  {categoryOptions.find((x) => x.value === typeFilter).label}
                  <MdArrowDropDown size={18} />
                </CategoryFilterShowBox>
                {showCategoryDrop && (
                  <DropdownBox
                    setSelectItem={setTypeFilter}
                    setShowItemDrop={setShowCategoryDrop}
                    onClickHandler={() => {
                      setPageIndex(1);
                    }}
                    itemList={categoryOptions}
                  />
                )}
              </CategoryFilterDropdown>
              <StatusFilterDropDown>
                <StatusFilterTag>Status</StatusFilterTag>
                <StatusFilterShowBox
                  onClick={() => {
                    setShowStatusDrop(!showStatusDrop);
                    setShowOpenedByDrop(false);
                    setShowCategoryDrop(false);
                  }}
                >
                  {statusOptions.find((x) => x.value === statusFilter).label}
                  <MdArrowDropDown size={18} />
                </StatusFilterShowBox>
                {showStatusDrop && (
                  <DropdownBox
                    setSelectItem={setStatusFilter}
                    setShowItemDrop={setShowStatusDrop}
                    onClickHandler={() => {
                      setPageIndex(1);
                    }}
                    itemList={statusOptions}
                    wrapperStyle={{ width: 125 }}
                  />
                )}
              </StatusFilterDropDown>
              <OpenedByFilterDropdown
                onClick={() => {
                  setShowOpenedByDrop(!showOpendByDrop);
                  setShowStatusDrop(false);
                  setShowCategoryDrop(false);
                }}
              >
                <OpenedByFilterTag>Sender</OpenedByFilterTag>
                <OpenedByFilterShowBox>
                  {regByOptions.find((x) => x.value === regByFilter).label}
                  <MdArrowDropDown size={18} />
                </OpenedByFilterShowBox>
                {showOpendByDrop && (
                  <DropdownBox
                    setSelectItem={setRegByFilter}
                    setShowItemDrop={setShowOpenedByDrop}
                    onClickHandler={() => {
                      setPageIndex(1);
                    }}
                    itemList={regByOptions}
                  />
                )}
              </OpenedByFilterDropdown>
              <DefaultButton
                theme={"blue"}
                btnText={"Save Filters"}
                onClick={() => {
                  localStorage.setItem("category", typeFilter);
                  localStorage.setItem("status", statusFilter);
                  localStorage.setItem("regBy", regByFilter);
                  alert("1:1 message list filter is saved.");
                }}
              />
            </FilterDiv>
          </TableControlArea>
          <TableHeader>
            {tablePosition &&
              tablePosition.map((tp, index) => {
                return (
                  <TableColumnBox key={index} style={{ width: tp.colWidth }}>
                    {tp.name}
                  </TableColumnBox>
                );
              })}
          </TableHeader>
          {qnaRoomList &&
            qnaRoomList.length > 0 &&
            qnaRoomList.map((room, index) => {
              return (
                <MessageQnaListCard
                  key={index}
                  data={room}
                  layout={tablePosition}
                  onClick={() => {
                    onClickCard(room);
                  }}
                />
              );
            })}
        </Fragment>
      )}
    </Wrapper>
  );
};

export default MessageQnaList;
