import React, { useState, useEffect, useRef, Fragment } from 'react'
import axios from 'axios'
import _ from 'lodash'

const ContestEdit = props => {
  const {
    getContest,
    year,
    setYear,
    contest,
    setContest,
    contestList,
    yearList,
    contestPackageList,
  } = props

  const [checkedList, setCheckedList] = useState([])
  const [contestCheckList, setContestCheckList] = useState([])
  const [edit, setEdit] = useState(0)
  const [contestEdit, setContestEdit] = useState(0)
  const [cParams, setCParams] = useState({
    name: '',
    start: '',
    end: '',
    year: year,
    sYear: year,
    eYear: year,
  })
  const [pParams, setPParams] = useState([
    {
      n: 1,
      contestId: contest,
      packageId: '',
      score: '',
      rankNum: '',
    },
  ])

  const [newCheck, setNewCheck] = useState([])

  const checkRef = useRef([])
  const contestCheckRef = useRef([])
  const newCheckRef = useRef([])

  useEffect(() => {
    if (contestEdit > 0) {
      const selected = contestList.filter(
        item => item.contestId === contestEdit
      )[0]

      setCParams({
        id: selected.contestId,
        name: selected.contestName,
        start: dateFormat(selected.startDate).replace('.', '-'),
        end: dateFormat(selected.endDate).replace('.', '-'),
        year: selected.year,
        sYear: new Date(selected.startDate).getFullYear(),
        eYear: new Date(selected.endDate).getFullYear(),
      })
    } else {
      setCParams({
        name: '',
        start: '',
        end: '',
        year: year,
        sYear: year,
        eYear: year,
      })
    }
  }, [contestEdit])

  useEffect(() => {
    if (edit > 0) {
      setPParams(
        contestPackageList.map((item, index) => {
          return {
            id: item.seq,
            n: index + 1,
            contestId: item.contestId,
            packageId: item.packageId,
            score: item.score,
            rankNum: item.rankNum,
          }
        })
      )
    } else {
      setPParams([
        {
          n: 1,
          contestId: contest,
          packageId: '',
          score: '',
          rankNum: '',
        },
      ])
    }
  }, [edit])

  const init = async () => {
    await setEdit(0)
    await setContestEdit(0)
    await setCheckedList([])
    await setContestCheckList([])
    await setNewCheck([])
    await setCParams({
      name: '',
      start: '',
      end: '',
      year: year,
      sYear: year,
      eYear: year,
    })
    await setPParams([
      {
        n: 1,
        contestId: contest,
        packageId: '',
        score: '',
        rankNum: '',
      },
    ])
    await getContest(year)
    if (checkRef.current) {
      for (let i = 0; i < checkRef.current.length; i++) {
        if (checkRef.current[i] && checkRef.current[i].checked) {
          checkRef.current[i].checked = false
        }
      }
    }
    if (contestCheckRef.current) {
      for (let i = 0; i < contestCheckRef.current.length; i++) {
        if (contestCheckRef.current[i] && contestCheckRef.current[i].checked) {
          contestCheckRef.current[i].checked = false
        }
      }
    }
    if (newCheckRef.current) {
      for (let i = 0; i < newCheckRef.current.length; i++) {
        if (newCheckRef.current[i] && newCheckRef.current[i].checked) {
          newCheckRef.current[i].checked = false
        }
      }
    }
  }

  useEffect(() => {
    init()
  }, [year])

  useEffect(() => {
    if (!yearList.includes(Number(year))) {
      setYear('')
    }
  }, [yearList])

  useEffect(() => {
    setPParams(
      pParams.map(item => {
        return { ...item, contestId: contest }
      })
    )
  }, [contest])

  const dateFormat = date => {
    const leftPad = value => {
      if (value >= 10) {
        return value
      }

      return `0${value}`
    }
    const month = new Date(date).getMonth() + 1
    const day = new Date(date).getDate()

    return [leftPad(month), leftPad(day)].join('.')
  }

  const dateValid = (d, t) => {
    const NotNum = /[^0-9]/g
    const onlyNum = d.replace(NotNum, '')

    let format
    let validFormat

    if (onlyNum.length === 1) {
      if (Number(onlyNum) > 1) {
        return
      }
    }

    if (onlyNum.length === 2) {
      if (Number(onlyNum) > 12 || Number(onlyNum) < 1) {
        return
      }
    }

    if (onlyNum.length === 3) {
      if (Number(onlyNum[2]) > 3) {
        return
      }
    }

    if (onlyNum.length === 4) {
      if (Number(onlyNum.slice(2)) > 31 || Number(onlyNum.slice(2)) < 1) {
        return
      }
    }

    format = '$1-$2'
    validFormat = /([0-9]{2})([0-9]+)/

    const newDate = onlyNum.replace(validFormat, format)

    if (t === 's') {
      setCParams({ ...cParams, start: newDate })
    } else if (t === 'e') {
      setCParams({ ...cParams, end: newDate })
    }
  }

  const handleCheck = e => {
    if (e.target.checked) {
      setCheckedList(checkedList.concat(e.target.value))
    } else {
      setCheckedList(checkedList.filter(id => id !== e.target.value))
    }
  }

  const handleContestCheck = e => {
    if (e.target.checked) {
      setContestCheckList(contestCheckList.concat(e.target.value))
    } else {
      setContestCheckList(contestCheckList.filter(id => id !== e.target.value))
    }
  }

  const handleNewCheck = e => {
    if (e.target.checked) {
      setNewCheck(newCheck.concat(e.target.value))
    } else {
      setNewCheck(newCheck.filter(n => n !== e.target.value))
    }
  }

  const renderContestEdit = type => {
    return type === 'edit' ? (
      <tr onClick={() => {}}>
        <td style={{ width: '5%' }}></td>
        <td
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <input
              type="text"
              value={cParams.sYear}
              onChange={e => {
                setCParams({
                  ...cParams,
                  sYear: e.target.value,
                })
              }}
              placeholder="yyyy"
              maxLength={4}
              style={{
                width: '80%',
                height: '18px',
                fontSize: '14px',
              }}
            />
            <input
              type="text"
              value={cParams.start}
              onChange={e => dateValid(e.target.value, 's')}
              placeholder="mm-dd"
              maxLength={5}
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ padding: '0 5px' }}>~</span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <input
              type="text"
              value={cParams.eYear}
              onChange={e =>
                setCParams({
                  ...cParams,
                  eYear: e.target.value,
                })
              }
              placeholder="yyyy"
              maxLength={4}
              style={{
                width: '80%',
                height: '18px',
                fontSize: '14px',
              }}
            />
            <input
              type="text"
              value={cParams.end}
              onChange={e => dateValid(e.target.value, 'e')}
              placeholder="mm-dd"
              maxLength={5}
              style={{ width: '100%' }}
            />
          </div>
        </td>
        <td style={{ width: '65%' }}>
          <input
            type="text"
            value={cParams.name}
            onChange={e =>
              setCParams({
                ...cParams,
                name: e.target.value,
              })
            }
            style={{ width: '100%' }}
          />
        </td>
        <td style={{ width: '5%' }}>
          <button
            className="btn btn-default"
            onClick={() => {
              clickContestSave(cParams.id)
            }}
          >
            저장
          </button>
        </td>
        <td style={{ width: '5%' }}>
          <button
            className="btn btn-default"
            onClick={() => {
              init()
            }}
          >
            취소
          </button>
        </td>
      </tr>
    ) : (
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%', padding: '0 5px' }}>
            <div>Period</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                <input
                  type="text"
                  value={cParams.sYear}
                  onChange={e => {
                    setCParams({
                      ...cParams,
                      sYear: e.target.value,
                    })
                  }}
                  placeholder="yyyy"
                  maxLength={4}
                  style={{
                    width: '70%',
                    height: '18px',
                    fontSize: '14px',
                  }}
                />
                <input
                  type="text"
                  value={cParams.start}
                  onChange={e => dateValid(e.target.value, 's')}
                  placeholder="mm-dd"
                  maxLength={5}
                  style={{ width: '100%' }}
                />
              </div>
              <span style={{ padding: '0 5px' }}>~</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                <input
                  type="text"
                  value={cParams.eYear}
                  onChange={e =>
                    setCParams({
                      ...cParams,
                      eYear: e.target.value,
                    })
                  }
                  placeholder="yyyy"
                  maxLength={4}
                  style={{
                    width: '70%',
                    height: '18px',
                    fontSize: '14px',
                  }}
                />
                <input
                  type="text"
                  value={cParams.end}
                  onChange={e => dateValid(e.target.value, 'e')}
                  placeholder="mm-dd"
                  maxLength={5}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: '50%', padding: '0 5px' }}>
            <div>ContestName</div>
            <input
              type="text"
              value={cParams.name}
              onChange={e => setCParams({ ...cParams, name: e.target.value })}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderEdit = () => {
    return (
      <table className="table no-margin">
        <thead>
          <tr>
            <th></th>
            <th>No</th>
            <th>PACKAGE ID</th>
            <th>SCORE</th>
            <th>RANK</th>
          </tr>
        </thead>
        <tbody>
          {pParams &&
            pParams.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    ref={el => (newCheckRef.current[index] = el)}
                    value={item.n}
                    onChange={handleNewCheck}
                  />
                </td>
                <td>{index + 1}</td>
                <td>
                  <input
                    type="text"
                    value={item.packageId}
                    onChange={e => {
                      setPParams(
                        pParams.map(p =>
                          p.n === item.n
                            ? { ...p, packageId: e.target.value }
                            : p
                        )
                      )
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item.score}
                    onChange={e => {
                      setPParams(
                        pParams.map(p =>
                          p.n === item.n ? { ...p, score: e.target.value } : p
                        )
                      )
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={item.rankNum}
                    onChange={e => {
                      setPParams(
                        pParams.map(p =>
                          p.n === item.n ? { ...p, rankNum: e.target.value } : p
                        )
                      )
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    )
  }

  // useEffect(() => {
  //   console.log(pParams)
  // }, [pParams])

  const clickSave = async id => {
    if (id) {
      const updateList = [...pParams].filter(p => p.id)
      const insertList = [...pParams].filter(p => !p.id)

      if (updateList.length > 0) {
        await axios.put(
          `/new/webAdmin/v2/ai/contest/package/${id}`,
          { updateList: updateList },
          {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          }
        )
      }

      if (insertList.length > 0) {
        await axios.post(
          `/new/webAdmin/v2/ai/contest/package`,
          { packageList: insertList },
          {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          }
        )
      }

      if (checkedList.length > 0) {
        await axios.delete(`/new/webAdmin/v2/ai/contest/package`, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
          data: {
            deleteList: checkedList,
          },
        })
      }

      await init()
    } else {
      await axios
        .post(
          `/new/webAdmin/v2/ai/contest/package`,
          { packageList: pParams },
          {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          init()
        })
    }
  }

  const clickContestSave = async id => {
    const s = new Date(
      new Date(cParams.start.replace(/-/g, '/') + `/${cParams.sYear}`)
    )
    const ns = new Date(s.setDate(new Date(s.getDate() + 1)))
    const e = new Date(
      new Date(cParams.end.replace(/-/g, '/') + `/${cParams.eYear}`)
    )
    const ne = new Date(e.setDate(new Date(e.getDate() + 1)))

    if (id) {
      await axios
        .put(
          `/new/webAdmin/v2/ai/contest/${id}`,
          {
            ...cParams,
            year: cParams.sYear,
            start: ns,
            end: ne,
          },
          {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          init()
        })
    } else {
      await axios
        .post(
          `/new/webAdmin/v2/ai/contest`,
          {
            ...cParams,
            year: cParams.sYear,
            start: ns,
            end: ne,
          },
          {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          init()
        })
    }
  }

  const clickDelete = async type => {
    if (type === 'package') {
    } else if (type === 'contest') {
      if (contestCheckList.length > 0) {
        await axios
          .delete(`/new/webAdmin/v2/ai/contest`, {
            data: {
              deleteList: contestCheckList,
            },
            headers: {
              token: sessionStorage.getItem('token'),
            },
          })
          .then(() => {
            init()
          })
        setContestCheckList([])
      }
    }
  }

  return (
    <div style={{ minHeight: '100%' }}>
      <section className="content-header">
        <h1>AI Contest Winner Page 관리</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/ContentsList">
              <i className="fa fa-dashboard"></i>AI
            </a>
          </li>
          <li className="active">Contest Winner</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <section className="col-lg-6 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  paddingBottom: '30px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <span>Year</span>
                    <select
                      style={{ display: 'inline-block', margin: '0 20px' }}
                      value={year}
                      onChange={e => {
                        setContest('')
                        setYear(e.target.value)
                      }}
                    >
                      {yearList.map((item, i) => (
                        <option key={i} value={item}>
                          {item === '' ? 'all' : item}
                        </option>
                      ))}
                    </select>
                  </div>
                  {contestEdit < 0 ? (
                    <div style={{ display: 'flex', gap: '0 10px' }}>
                      <button
                        className="btn btn-default"
                        onClick={async () => {
                          await clickContestSave()
                        }}
                      >
                        저장
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={async () => {
                          await init()
                        }}
                      >
                        취소
                      </button>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', gap: '0 10px' }}>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setContestEdit(-1)
                        }}
                      >
                        추가
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          clickDelete('contest')
                        }}
                      >
                        삭제
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {contestEdit < 0 && renderContestEdit()}
                  <table className="table mo-margin">
                    {contestList &&
                      contestList.map((item, index) => (
                        <Fragment>
                          {(index === 0 ||
                            item.year !== contestList[index - 1].year) && (
                            <thead>
                              <div
                                style={{
                                  padding: '5px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {item.year}
                              </div>
                              <tr>
                                <th style={{ width: '5%' }}></th>
                                <th style={{ width: '25%' }}>Period</th>
                                <th style={{ width: '65%' }}>Contest Name</th>
                                <th style={{ width: '10%' }}></th>
                              </tr>
                            </thead>
                          )}
                          {contestEdit === item.contestId ? (
                            renderContestEdit('edit')
                          ) : (
                            <tr
                              key={index}
                              onClick={() => {
                                setContest(item.contestId)
                              }}
                              style={{
                                backgroundColor:
                                  item.contestId === contest
                                    ? '#ededed'
                                    : '#fff',
                                cursor: 'pointer',
                              }}
                            >
                              <td style={{ width: '5%' }}>
                                <input
                                  ref={el =>
                                    (contestCheckRef.current[index] = el)
                                  }
                                  type="checkbox"
                                  onChange={handleContestCheck}
                                  value={item.contestId}
                                />
                              </td>
                              <td style={{ width: '25%' }}>
                                {dateFormat(item.startDate) +
                                  ' ~ ' +
                                  dateFormat(item.endDate)}
                              </td>
                              <td style={{ width: '65%' }}>
                                {item.contestName}
                              </td>
                              <td style={{ width: '10%' }}>
                                <button
                                  className="btn btn-default"
                                  onClick={() => {
                                    setContestEdit(item.contestId)
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          </section>
          <section className="col-lg-6 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  paddingBottom: '30px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {edit === 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      gap: '0 10px',
                    }}
                  >
                    {contest && (
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          if (contest) {
                            setEdit(contest)
                          }
                        }}
                      >
                        수정
                      </button>
                    )}
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        if (contest) {
                          setEdit(-1)
                        }
                      }}
                    >
                      추가
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      gap: '0 10px',
                    }}
                  >
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        const temp = [...pParams]
                        temp.unshift({
                          n: temp.length + 1,
                          contestId: contest,
                          packageId: '',
                          score: '',
                          rankNum: '',
                        })

                        setPParams(temp)
                      }}
                    >
                      추가
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        const temp = [...pParams]
                        if (newCheck.length > 0) {
                          setNewCheck(
                            temp.filter(
                              item =>
                                newCheck.indexOf(String(item.n)) < 0 && !item.id
                            )
                          )
                          setCheckedList(
                            temp.filter(
                              item =>
                                newCheck.indexOf(String(item.n)) > -1 && item.id
                            )
                          )
                          setPParams(
                            temp.filter(
                              item => newCheck.indexOf(String(item.n)) < 0
                            )
                          )
                        }

                        if (newCheckRef.current) {
                          for (let i = 0; i < newCheckRef.current.length; i++) {
                            if (
                              newCheckRef.current[i] &&
                              newCheckRef.current[i].checked
                            ) {
                              newCheckRef.current[i].checked = false
                            }
                          }
                        }
                        setNewCheck([])
                      }}
                    >
                      삭제
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        if (edit > 0) {
                          clickSave(edit)
                        } else if (edit < 0) {
                          clickSave()
                        }
                      }}
                    >
                      저장
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        init()
                      }}
                    >
                      취소
                    </button>
                  </div>
                )}
              </div>
              <div className="box-body" style={{ width: '100%' }}>
                <div className="table-responsive">
                  {edit < 0 && renderEdit()}
                  {edit > 0
                    ? renderEdit()
                    : contestPackageList.length > 0 && (
                        <table className="table no-margin">
                          <thead>
                            <tr>
                              <th></th>
                              <th>No</th>
                              <th>PACKAGE ID</th>
                              <th>SCORE</th>
                              <th>RANK</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {contestPackageList.map((item, index) => (
                              <tr key={index}>
                                <td></td>
                                <td>{index + 1}</td>
                                <td>{item.packageId}</td>
                                <td>{item.score}</td>
                                <td>{item.rankNum}</td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default ContestEdit
