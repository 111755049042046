import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Input = styled.input`
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin: 0;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    display: table-cell;
`;

const Span = styled.span`
    position: relative;
    font-size: 0;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
`;


const Button = styled.button`
    z-index: 2;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    box-shadow: none;
    border-width: 1px;
    position: relative;
    background-color: #00c0ef;
    border-color: #00acd6;
    border: 1px solid transparent;
    color: #fff;
    margin-right: 5px;
`;

const Dropdown = styled.div`
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
`;

const DropButton = styled.button`
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.3333333;
    border-radius: 6px;
    position: relative;
    background-color: #f39c12;
    border-color: #e08e0b;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
`;

const DropdownMenu = styled.ul`
    box-shadow: none;
    border-color: #eee;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 12px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px
    solid #ccc;
    border: 1px
    solid rgba(0,0,0,.15);
    border-radius: 4px;
`;

const Componeent = ({lang, langList,  setLang, save, setInputLang, langDelete, addKeywordForm}) => {
    return (
        <>
            <Dropdown>
                <select onChange={(e) => setLang(e.target.value)}>
                    {
                        langList && langList.map((lang, i) => <option key={i} value={lang.lang}>{lang.lang}</option> )
                    }
                </select>
            </Dropdown>
            <Input value={lang} onChange={(event) => setInputLang(event.target.value)} />
            <Span>
                <Button onClick={() => save(lang)}>언어추가</Button>
                <Button onClick={langDelete}>언어삭제</Button>
                <Button onClick={addKeywordForm}>키워드추가</Button>
            </Span>
        </>
    )
};

const Lang = (lang, langList, setLang, save, setInputLang, langDelete, addKeywordForm) => {
    
    const [state, setState] = useState(lang);

    return [
        <Componeent lang={state} langList={langList} setLang={setLang} save={save} setInputLang={setInputLang} langDelete={langDelete} addKeywordForm={addKeywordForm} />
        ,setState
    ]

};

export default Lang;