import React from 'react'
import { Col } from 'react-bootstrap'
import { paging4 } from '../../Common/Page';

const BannerListBottomBar = (props) => {
    return (
        <Col>
            {paging4(props.pageNumber, props.pageMap, props.pageName, props.type, props.status)}
        </Col>
    )
}

export default BannerListBottomBar;