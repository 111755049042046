import React, { useState, useEffect } from "react";
import StudioBanner from "./StudioBanner";

const StudioBannerSection = (props) => {
  const [open, setOpen] = useState(new Array(props.banners.length).fill(false));

  useEffect(() => {
    setOpen(open.map((item, idx) => (idx === 0 ? true : false)));
  }, []);

  const handleOpen = (index) => {
    if (open[index]) {
      // 열려있으면 닫음
      setOpen(open.map((item) => false));
    } else {
      // 닫혀있으면 클릭한 것만 open
      setOpen(open.map((item, idx) => (idx === index ? true : false)));
    }
  };

  return (
    <div style={{ margin: "5px" }}>
      {props.banners &&
        props.banners.map((item, idx) => {
          console.log(item);
          return (
            <StudioBanner
              key={item.bannerId}
              banner={item}
              idx={idx}
              open={open[idx]}
              handleOpen={handleOpen}
              updateBannerStatus={props.updateBannerStatus}
              deleteBanner={props.deleteBanner}
            />
          );
        })}
    </div>
  );
};

export default StudioBannerSection;
