import React from 'react'

const Dropdown = ({ list, onChange, value, type }) => {
  return (
    <select value={value} onChange={e => onChange(e)}>
      {list.map((item, i) =>
        type === 'page' ? (
          <option key={i} value={item}>
            {item}
          </option>
        ) : (
          <option key={i} value={item.code}>
            {item.lang}
          </option>
        )
      )}
    </select>
  )
}

export default Dropdown
