import React from 'react';

const InsertTagGuideline = (props) => {
    return (
        <React.Fragment>
            <h3>스티커 태그 가이드라인</h3>
            <p>Sticker Tag Guideline:<br/>
                - What/who is the subject?<br/>
                - What is the subject doing - action?<br/>
                - What’s the subject’s emotion?<br/>
                - What’s the subject’s facial expression?<br/>
                - Any catchphrase/color/objects/themes?</p>
        </React.Fragment>
    )
}

export default InsertTagGuideline;