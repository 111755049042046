import React, { useState, useEffect } from 'react'
import AIHomeEdit from '../../Components/AI/AIHomeEdit'
import axios from 'axios'

const AIHomePage = () => {
  const [lang, setLang] = useState('en')
  const [bannerList, setBannerList] = useState([])

  const getBanner = lang => {
    axios
      .get(`/new/webAdmin/v2/ai/home/banner/${lang}`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        setBannerList(data.result.sort((a, b) => a.ORDER - b.ORDER))
      })
  }

  useEffect(() => {
    getBanner(lang)
  }, [lang])

  return (
    <>
      <AIHomeEdit
        getBanner={getBanner}
        lang={lang}
        setLang={setLang}
        bannerList={bannerList}
        setBannerList={setBannerList}
      />
    </>
  )
}

export default AIHomePage
