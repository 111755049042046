import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BannerList from './BannerList';
import Header from "./Header"
import KeywordList from './KeywordList';
import TrendingList from './TrendingList';
import TrendingModal from "./TrendingModal";

const FlexRow = styled.div`
    display: flex;
    padding: 10px;
`

const CurationPreview = () => {
    const [open, setOpen] = useState(false);
    const [previews, setPreviews] = useState({
        trendingList: [],
        bannerList: [],
        recommendList: []
    });
    // const [packageId, setPackageId] = useState(null);
    // const [stickerId, setStickerId] = useState(null);
    const [country, setCountry] = useState('us');
    const [language, setLanguage] = useState('en');

    const [selectedPackageInfo, setSelectedPackageInfo] = useState({
        p_id: '',
        s_id: '',
        lightBackgroundCode: '',
        darkBackgroundCode: ''
    })

    const openModal = (info) => {
        setOpen(true);
        // setPackageId(info.p_id);
        // setStickerId(info.s_id);
        setSelectedPackageInfo(info);
    }

    const closeModal = () => {
        setOpen(false);
        // setPackageId(null);
        // setStickerId(null);
        setSelectedPackageInfo(null);
    }

    useEffect(()=>{
        // get type A, type B list
        getTrendingAndBannerList();

        // get recommended tag list
        getRecommendedTagList();
    },[country, language])

    const getTrendingAndBannerList = async () => {
        const param = {
            pageNumber: 1,
            lang: 'en',
            country: 'us',
            status: 'Y'
        }

        try{
            await axios 
                .get(
                    `/new/webAdmin/v2/preview`,
                    {
                        headers: {
                            token: window.sessionStorage.getItem("token"),
                        },
                        params: param
                    }
                )
                .then(res => {
                    if(res.data.status==='success'){
                        const tmp = res.data.result.previewPackageList;
                        tmp.map((item)=>{
                            if(item.type == 'A'){
                                setPreviews(prev => {
                                    return{
                                        ...prev,
                                        // trendingList: [...prev.trendingList, item.previewPackage]
                                        trendingList: item.previewPackage
                                    }
                                })
                            }
                            else if(item.type == 'B'){
                                setPreviews(prev => {
                                    return{
                                        ...prev,
                                        bannerList: item.previewPackage
                                    }
                                })
                            }
                        })
                    }
                })
        } catch (err) {
            console.log("AXIOS ERROR");
            console.log(err);
        }
    }

    const getRecommendedTagList = async () => {
        try{
            await axios
                .get(
                    `/new/webAdmin/v2/recommend?lang=${language}`,
                    {
                        headers: {
                            token: window.sessionStorage.getItem("token"),
                        },
                    }
                )
                .then(res => {
                    if(res.data.status === 'success'){
                        setPreviews(prev => {
                            return{
                                ...prev,
                                recommendList: res.data.recommendList
                            }
                        })
                    }
                    else {
                        console.log("fail to get recommend tag list")
                    }
                })
        } catch(err) {
            console.log(err);
        }
    }

    return (
        <>
            <FlexRow>
                <Header setCountry={setCountry} setLanguage={setLanguage}/>
            </FlexRow>
            <hr />
            <FlexRow>
                <KeywordList recommendList={previews.recommendList}/>
            </FlexRow>
            <hr />
            <FlexRow>
                <TrendingList trendingList={previews.trendingList} openModal={openModal}/>
            </FlexRow>
            <hr />
            <FlexRow>
                <BannerList bannerList={previews.bannerList} />
            </FlexRow>

            <TrendingModal 
                open={open} 
                closeModal={closeModal} 
                // packageId={packageId}
                // stickerId={stickerId}
                info={selectedPackageInfo}
            />
        </>
    );
};

export default CurationPreview;