import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AiOutlineEdit } from 'react-icons/ai'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const KeywordListBox = styled.div`
  display: flex;
  overflow-x: auto;
  margin: 0 10px;
  padding-bottom: 15px;
  ::-webkit-scrollbar {
    display: none;
  }
`

const KeywordBox = styled.div`
  background-color: ${props =>
    props.mode === 'light' ? '#ff4500' : '#ff855b'};
  margin: 0 5px;
  white-space: nowrap;
  border-radius: 20px;
  padding: 5px 10px;
  color: white;
`
const Edit = styled.span`
  margin-left: 15px;
  font-size: 15px;
  &:hover {
    cursor: pointer;
    color: #ff4500;
    font-weight: bold;
  }
`
const KeywordList = ({ keywordList, mode }) => {
  // useEffect(() => {
  //   console.log(keywordList)
  // }, [])

  const history = useHistory()

  const Scroll = e => {
    const list = document.getElementById('recommend')
    list.scrollLeft += e.deltaY
  }

  const [dragging, setDragging] = useState(false)
  const [startX, setStartX] = useState()

  const Drag = e => {
    const list = document.getElementById('recommend')
    e.preventDefault()
    setDragging(true)
    setStartX(e.pageX + list.scrollLeft)
  }
  const Move = e => {
    const list = document.getElementById('recommend')
    if (dragging) {
      list.scrollLeft = startX - e.pageX
    }
  }
  const DragEnd = () => {
    setDragging(false)
  }

  return (
    <div style={{ paddingTop: '20px' }}>
      <section
        className="content-header"
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: !mode ? 'black' : 'white',
        }}
      >
        <h1>Recommend</h1>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              Go to Recommend page
            </Tooltip>
          }
        >
          <Edit onClick={() => history.push('/Recommend')}>
            <AiOutlineEdit size={24} />
          </Edit>
        </OverlayTrigger>
      </section>
      <section
        onWheel={Scroll}
        onMouseDown={Drag}
        onMouseMove={Move}
        onMouseUp={DragEnd}
        onMouseLeave={DragEnd}
      >
        <KeywordListBox id="recommend">
          {keywordList.map((keyword, index) => (
            <KeywordBox key={index} mode={!mode ? 'light' : 'dark'}>
              {keyword.keyword}
            </KeywordBox>
          ))}
        </KeywordListBox>
      </section>
    </div>
  )
}

export default KeywordList
