import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import {
  Row,
  Col,
  Navbar,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { paging5 } from "../../Common/Page";

const CollectionView = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState({
    collection: null,
    collectionList: null,
  });
  const [params, setParams] = useState({
    collectionName: "선택",
  });

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      props.history.push("/");
    } else {
      getCollection();
    }
  }, []);

  const getCollection = () => {
    // const param = queryString.stringify(params);
    fetch(`/new/webAdmin/v2/collection/list`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setList((state) => ({
          ...state,
          collection: responseJson.body.collection,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const getCollectionList = () => {
    fetch(`/new/webAdmin/v2/collection/list/${params.collectionName}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setList((state) => ({
          ...state,
          collectionList: responseJson.body.collectionList,
        }));
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  if (!isLoading) {
    return (
      <div
        //  className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header">
          <h1>컬렉션 조회</h1>
          <ol className="breadcrumb">
            <li>
              <a href="/ContentsList">
                <i className="fa fa-dashboard"></i>컬렉션
              </a>
            </li>
            <li className="active">컬렉션 조회</li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <section className="col-lg-12 connectedSortable">
              <div className="box box-info">
                <div
                  className="box-header with-border"
                  style={{ paddingBottom: "30px", display: "inline" }}
                >
                  <Navbar bg="light" expand="lg">
                    <Col>
                      <Row>
                        <DropdownButton
                          variant="dark"
                          id="commit-status-dropdown"
                          title={params.collectionName}
                        >
                          {list.collection &&
                            list.collection.map((data, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    setParams((state) => ({
                                      ...state,
                                      collectionName: data.name,
                                    }))
                                  }
                                >
                                  {data.name}
                                </Dropdown.Item>
                              );
                            })}
                        </DropdownButton>
                        <Button onClick={getCollectionList}>조회</Button>
                      </Row>
                    </Col>
                  </Navbar>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>순위</th>
                          <th>점수</th>
                          <th>이미지</th>
                          <th>스티커명</th>
                          <th>isNew</th>
                          <th>언어</th>
                          <th>작가이름</th>
                          <th>작가티어</th>
                          <th>작가 국적</th>
                          <th>다운 수</th>
                          <th>키워드</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.collectionList &&
                          list.collectionList.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.total}</td>
                                <td>
                                  <img
                                    src={data.p_img}
                                    width="250px"
                                    height="250px"
                                    alt=""
                                  />
                                </td>
                                <td>{data.p_name}</td>
                                <td>{data.isNew}</td>
                                <td>{data.langName}</td>
                                <td>{data.u_name}</td>
                                <td>
                                  {data.groupId === 1 ? "1tier" : "general"}
                                </td>
                                <td>{data.country}</td>
                                <td>{data.downCnt}</td>
                                <td>{data.p_tags}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default withRouter(CollectionView);
