import React, { useState, useEffect } from "react";
import styled from "styled-components";

const activeBorderRadius = '16px 16px 0 0'
const inactiveBorderRadius = '16px 16px 16px 16px'

const Input2 = styled.input`
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: 2;
    margin: 0;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    display: table-cell;
`;

const Span = styled.span`
    position: relative;
    font-size: 0;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
`;

const Box = styled.span`
    position: relative;
    white-space: nowrap;
    width: 30%;
    vertical-align: middle;
    display: flex;
`;

const Qbox = styled.span`
    position: relative;
    white-space: nowrap;
    width: 30%;
    vertical-align: middle;
`;


const SearchButton = styled.button`
    z-index: 2;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    box-shadow: none;
    border-width: 1px;
    position: relative;
    background-color: #00c0ef;
    border-color: #00acd6;
    border: 1px solid transparent;
    color: #fff;
`;

const Button = styled.button`
    z-index: 2;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    box-shadow: none;
    border-width: 1px;
    position: relative;
    background-color: #00c0ef;
    border-color: #00acd6;
    border: 1px solid transparent;
    color: #fff;
`;

const Dropdown = styled.div`
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
`;

const DropButton = styled.button`
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.3333333;
    border-radius: 6px;
    position: relative;
    background-color: #f39c12;
    border-color: #e08e0b;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
`;

const DropdownMenu = styled.ul`
    box-shadow: none;
    border-color: #eee;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 12px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px
    solid #ccc;
    border: 1px
    solid rgba(0,0,0,.15);
    border-radius: 4px;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 3;

  &:focus-within {
    box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  }
`

const Input = styled.input`
    flex: 1 0 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
`

const DeleteButton = styled.div`
  cursor: pointer;
`

const DropDownBox = styled.ul`
  display: block;
  margin: 0 auto;
  padding: 8px 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
`

const DropDownItem = styled.li`
  padding: 0 16px;

  &.selected {
    background-color: lightgray;
  }
`

const Componeent = ({keyword, search, collection, setCollection, queryBy, setQueryBy, setQ, autoList, sort, setSort, qBy, setQby, weight, setWeight, filter, setFilter}) => {

    return (
        <>
            <Span>
                <SearchButton>컬렉션</SearchButton>
            </Span>
            <Dropdown>
                <select onChange={(e) => setCollection(e.target.value)}>
                    <option key={"package"} value={"package"}>package</option>
                    <option key={"stickers"} value={"stickers"}>stickers</option>
                </select>
            </Dropdown>
            <Box>
                <SearchButton>검색대상</SearchButton>
                <Dropdown>
                    <select onChange={(e) => setQueryBy(e.target.value)}>
                        {
                            collection === "package" ?
                            <>
                                <option key={"packageName"} value={"packageName"}>packageName</option>
                                <option key={"artistName"} value={"artistName"}>artistName</option>
                                <option key={"packageCategory"} value={"packageCategory"}>packageCategory</option>
                                <option key={"packageCharacterName"} value={"packageCharacterName"}>packageCharacterName</option>
                                <option key={"packageDescription"} value={"packageDescription"}>packageDescription</option>
                                <option key={"packageKeyword"} value={"packageKeyword"}>packageKeyword</option>
                                <option key={"packageTag"} value={"packageTag"}>packageTag</option>
                                <option key={"stickerKeyword"} value={"stickerKeyword"}>stickerKeyword</option>
                            </>
                            :
                            <>
                                <option key={"packageName"} value={"packageName"}>packageName</option>
                                <option key={"artistName"} value={"artistName"}>artistName</option>
                                <option key={"packageCharacterName"} value={"packageCharacterName"}>packageCharacterName</option>
                                <option key={"stickerKeyword"} value={"stickerKeyword"}>stickerKeyword</option>
                            </>
                        }
                    </select>
                </Dropdown>
                <SearchButton 
                    onClick={() => {
                        setQby([...qBy, queryBy]);
                        setWeight([...weight, '']);
                    }}
                >추가</SearchButton>
            </Box>
            <Qbox>
                {
                    qBy.map((item, index) => {
                        return (
                            <>
                                <p 
                                    key={index} 
                                    onClick={() => {
                                        setQby(qBy.filter(val => item !== val));
                                        setWeight(weight.filter((val, idx) => idx !== index));
                                    }}
                                >{item}</p>
                                <Input2 
                                    value={weight[index]}  
                                    onChange={(event) => {
                                        setWeight(weight.map((val, idx) => idx === index ? event.target.value : val));
                                    }} 
                                />
                            </>
                        )
                    })
                }
            </Qbox>
            
            <Span>
                <SearchButton>정렬</SearchButton>
            </Span>
            <Input2 
                value={sort} 
                onChange={(event) => {
                    setSort(event.target.value);
                }} 
            />
            <Span>
                <SearchButton>필터</SearchButton>
            </Span>
            <Input2 
                value={filter} 
                onChange={(event) => {
                    setFilter(event.target.value);
                }} 
            />
            <Span>
                <SearchButton>검색어</SearchButton>
            </Span>
            <InputBox isHaveInputValue={keyword}>
                <Input 
                    value={keyword} 
                    onChange={(event) => {
                        setQ(event.target.value);
                        search(event.target.value);
                    }}
                />
            </InputBox>
            { 
                keyword.length > 0 && 

                (
                    <DropDownBox>
                    {
                        autoList.length === 0 && (
                            <DropDownItem>해당하는 단어가 없습니다</DropDownItem>
                        )
                    }
                    {
                        autoList.map((item, index) => {
                            if (item.highlights.length !== 0) {
                                return (
                                    <DropDownItem
                                        key={index}
                                        dangerouslySetInnerHTML={{__html: item.highlights[0].snippet}}
                                    />
                                )
                            }
                        })
                    }
                    </DropDownBox>
                )
            }
        </>
    )

};

const SearchText = (keyword, search, collection,  setCollection, queryBy, setQueryBy, setQ, autoList, sort, setSort, qBy, setQby, weight, setWeight, filter, setFilter) => {
    
    const [state, setState] = useState(keyword);

    return [
        state,
        <Componeent 
            keyword={state} 
            search={search} 
            collection={collection} 
            setCollection={setCollection} 
            queryBy={queryBy} 
            setQueryBy={setQueryBy} 
            setQ={setQ} 
            autoList={autoList} 
            sort={sort}
            setSort={setSort}
            qBy={qBy}
            setQby={setQby}
            weight={weight}
            setWeight={setWeight}
            filter={filter}
            setFilter={setFilter}
        />,
        setState
    ]

};

export default SearchText;