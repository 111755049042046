import React, {useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import { Button, ButtonGroup, Card, Row, Col, Dropdown, DropdownButton, Form } from 'react-bootstrap';

// TODO CSS Styling

const SearchKeyword = (props) => {
    const [packageId, setPackageId] = useState("");
    const getSearchTypeName = (type) => {
        switch(type) {
            case 1 : return "스티커팩 그룹 id";
            case 2 : return "컨테이너 id";
            default : return "스티커팩 id 목록";
        }
    }

    const keySubmitEvent = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            props.handleSubmit();
        }
    }

    const keyAddEvent = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            props.setSearchData({...props.searchData, packageIds: props.searchData.packageIds ? props.searchData.packageIds.concat(packageId) : [packageId]})
            setPackageId("");
        }
    }

    const handleSearchTypeChange = (type) => {
        props.setSearchType(type);
        setPackageId("");
        switch(type) {
            case 0 :
                props.setSearchData({
                    packageIds : []
                  , packageGroupId : ""
                  , containerId : ""
              })
                break;
            default :
                props.setSearchData({
                      packageIds : null
                    , packageGroupId : ""
                    , containerId : ""
                })
                break;
        }
    }

    return (
        <React.Fragment>
        <Card border="primary" style={{margin:"10px"}}>
            <Card.Header>검색 정보</Card.Header>
            <Card.Body>
                <Row style={{height:'50px', marginBottom:'10px', borderBottom:'solid', borderBottomWidth:'1px', borderBottomColor:'silver', alignContent : 'center'}}>
                    <Col md={3}>
                        <i className="fa fa-calendar"></i> 시작일 
                        <ReactDatePicker selected = {props.startDate} onChange={(date) => props.setStartDate(date)}/> 
                    </Col>
                    <Col md={3}>
                        <i className="fa fa-calendar"></i> 종료일 
                        <ReactDatePicker selected = {props.endDate} onChange={(date) => props.setEndDate(date)}/>
                    </Col>
                </Row>
                <Row style={{height:'100px', marginBottom:'10px', borderBottom:'solid', borderBottomWidth:'1px', borderBottomColor:'silver', alignContent : 'center'}}>
                    <Col md={2}>
                        <DropdownButton id="search-type" title={getSearchTypeName(props.searchType)}>
                            <Dropdown.Item eventKey={0} onClick={() => handleSearchTypeChange(0)}>스티커팩 id 목록</Dropdown.Item>
                            <Dropdown.Item eventKey={1} onClick={() => handleSearchTypeChange(1)}>스티커팩 그룹 id</Dropdown.Item>
                            <Dropdown.Item eventKey={2} onClick={() => handleSearchTypeChange(2)}>컨테이너 id</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                    <Col offset={1} md={10}>
                        {
                        props.searchType === 1 ?
                        
                        <React.Fragment>
                            <Form>
                                <Form.Group>
                                    <Form.Row>
                                        <Form.Label>스티커팩 그룹 id</Form.Label>
                                        <Col md={3} style={{marginLeft:"20px"}}><Form.Control id="packageGroupIdInput" value={props.searchData.packageGroupId ? props.searchData.packageGroupId : ""} onKeyPress={keySubmitEvent} onChange={(e) => {props.setSearchData({...props.searchData, packageGroupId: e.target.value})}}/></Col>
                                    </Form.Row>
                                </Form.Group>
                            </Form>
                        </React.Fragment>
                        : props.searchType === 2 ?
                        <React.Fragment>
                            <Form>
                                <Form.Group>
                                    <Form.Row>
                                        <Form.Label>컨테이너 id</Form.Label>
                                        <Col md={3} style={{marginLeft:"20px"}}><Form.Control id="containerIdInput" value={props.searchData.containerId ? props.searchData.containerId : ""} onKeyPress={keySubmitEvent} onChange={(e) => {props.setSearchData({...props.searchData, containerId: e.target.value})}}/></Col>
                                    </Form.Row>
                                </Form.Group>
                            </Form> 
                        </React.Fragment>
                        : 
                        <React.Fragment>
                            <Form>
                                <Form.Group>
                                    <Form.Row>
                                        <Form.Label>스티커팩 id</Form.Label>
                                        <Col md={3} style={{marginLeft:"20px"}}><Form.Control id="packageIdInput" value={packageId} onKeyPress={keyAddEvent} onChange={(e) => setPackageId(e.target.value)}/></Col>
                                        <Button size='sm' onClick={() => {props.setSearchData({...props.searchData, packageIds: props.searchData.packageIds ? props.searchData.packageIds.concat(packageId) : [packageId]}); setPackageId("")}}>추가</Button>
                                    </Form.Row>
                                </Form.Group>
                            </Form>
                            {
                                props.searchType === 0 &&
                                <Row style={{height:'50px', marginBottom:'10px', alignContent : 'center'}}>
                                    <Col md={1}><label>스티커팩 id 목록</label></Col>
                                    <Col>
                                        {props.searchData.packageIds && props.searchData.packageIds.map(id => (
                                            <ButtonGroup key={id}>
                                                <Button variant="outline-secondary" onClick={() => props.setSearchData({...props.searchData, packageIds: props.searchData.packageIds.filter(pid => pid !== id)})}>
                                                    {id}<i className="fa fa-times" />
                                                </Button>
                                            </ButtonGroup>
                                        ))}
                                    </Col>
                                </Row>
                            }
                        </React.Fragment>
                        }
                    </Col>
                </Row>
                <Row style={{height:'50px', marginBottom:'10px', borderBottom:'solid', borderBottomWidth:'1px', borderBottomColor:'silver', alignContent : 'center', justifyContent : 'flex-end'}}>
                    <Col md={1}>
                        <Button variant="primary" onClick={props.handleSubmit}>검색</Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </React.Fragment>
    )
}

export default SearchKeyword;