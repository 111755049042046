import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import InputCopy from './InputCopy';

const TrendingBox = styled.div`
    width: 100px;
    height: 150px;
    padding: 10px;
    margin: 5px;
`

const TrendingImage = styled.img`
    width: 100px;
    height: 100px;
    border: none;
    border-radius: 10px;
    &:hover {
        cursor: pointer;
    }

    ${(props)=>css`
        background-color: ${props.light};
        &:hover {
            background-color: ${props.dark}
        }
    `}
`

const TrendingInfo = styled.div`
    width: 100px;
    height: 40px;
`

const TrendingItem = (props) => {
    const clickImage = () => {
        const info = {
            p_id: props.item.packageId,
            s_id: props.item.stickerId,
            lightBackgroundCode: props.item.lightBackgroundCode,
            darkBackgroundCode: props.item.darkBackgroundCode
        }
        props.openModal(info);
    }

    return(
        <TrendingBox>
            <TrendingImage 
                src={props.item.stickerImg} 
                light={props.item.lightBackgroundCode}
                dark={props.item.darkBackgroundCode}
                onClick={clickImage}
            />
            <TrendingInfo>
                <InputCopy copyText={props.item.packageId} />
            </TrendingInfo>
        </TrendingBox>  
    )
}

export default TrendingItem;