import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import BorderBox from "../../Common/BoxUI/BorderBox";
import MessageQnaArtistInfo from "./MessageQnaArtistInfo";
import MessageQnaSendForm from "./MessageQnaSendForm";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const LeftInner = styled.div`
  display: flex;
  width: 50%;
`;

const RightInner = styled.div`
  display: flex;
  width: 50%;
  height: fit-content;
`;

const MessageQnaSend = () => {
  const [searchedArtist, setSearchedArtist] = useState("");
  const [suggestList, setSuggestList] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(null);

  useEffect(() => {
    if (searchedArtist !== "" && searchedArtist.length >= 3) {
      getSomeUsersByTerm();
    } else {
      setSuggestList([]);
    }
  }, [searchedArtist]);

  const getSomeUsersByTerm = async () => {
    try {
      const res = await axios.get(
        `/new/webAdmin/v2/message-center/send/search/users/${searchedArtist}`,
        {
          headers: { token: sessionStorage.getItem("token") },
        }
      );

      if (res.status === 200) {
        const tempList = res.data.body.suggestList;
        setSuggestList(tempList[0]);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  return (
    <Wrapper>
      <LeftInner>
        <BorderBox
          boxTitle={"1:1 Message Form"}
          component={
            <MessageQnaSendForm
              setSearchedArtist={setSearchedArtist}
              suggestList={suggestList}
              setSelectedArtist={setSelectedArtist}
              selectedArtist={selectedArtist}
            />
          }
        />
      </LeftInner>
      <RightInner>
        <BorderBox
          boxTitle={"Artist Infomation"}
          component={<MessageQnaArtistInfo selectedArtist={selectedArtist} />}
        />
      </RightInner>
    </Wrapper>
  );
};

export default MessageQnaSend;
