import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ArtistView from '../../Components/Subscription/ArtistView'

const ArtistPage = () => {
  const [user, setUser] = useState([])
  const [artist, setArtist] = useState([])
  const [query, setQuery] = useState({
    id: '',
    pageNumber: 1,
    aPageNumber: 1,
    type: 1,
    limit: 20,
  })
  const [uPageMap, setUPageMap] = useState({})
  const [aPageMap, setAPageMap] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const getUser = async () => {
    await setIsLoading(true)
    await axios
      .get(
        `/new/webAdmin/v2/sub/user?id=${query.id}&type=${query.type}&userPage=${query.pageNumber}&artistPage=${query.aPageNumber}&limit=${query.limit}`,
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(async ({ data }) => {
        await setArtist(data.result.artistList)
        await setAPageMap(data.result.artistPageMap)
        await setUser(data.result.userList)
        await setUPageMap(data.result.userPageMap)
        await setIsLoading(false)
      })
  }

  useEffect(() => {
    getUser()
  }, [query])

  return (
    <ArtistView
      getUser={getUser}
      user={user}
      uPageMap={uPageMap}
      artist={artist}
      aPageMap={aPageMap}
      query={query}
      setQuery={setQuery}
      isLoading={isLoading}
    />
  )
}

export default ArtistPage
