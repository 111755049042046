const countryDefaultLanguage = [
    {
        country: 'us',
        country_kr: '미국',
        language: 'en'
    },
    {
        country: 'kr',
        country_kr: '한국',
        language: 'ko'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'hi'
    },
    {
        country: 'es',
        country_kr: '스페인',
        language: 'es'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'bn'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'mr'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'ml'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'gu'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'kn'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'pa'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'te'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'ta'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'or'
    },
    {
        country: 'in',
        country_kr: '인도',
        language: 'bh'
    },
    {
        country: 'vn',
        country_kr: '베트남',
        language: 'vi'
    },
    {
        country: 'kz',
        country_kr: '카자흐스탄',
        language: 'kk'
    },
];

const languages = [
    'en', 
    'ko', 
    'hi', 
    'es',
    'bn',
    'mr',
    'ml',
    'gu',
    'kn',
    'pa', 
    'te',
    'ta',
    'or',
    'bh',
    'vi',
    'kk'
]

export {
    countryDefaultLanguage,
    languages
}