import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import { paging2 } from "../../Common/Page";
import { Container, Col, Row } from "react-bootstrap";

class Tax extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      taxInfoList: null,
      taxStatusCnt: null,
      pageMap: null,
      pageNumber: this.props.pageNumber ? this.props.pageNumber : 1,
      search: this.props.search ? this.props.search : "",
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this._getTax(this.state.pageNumber);
    } else {
      this.props.history.push("/");
    }
  }

  _getTax = (num) => {
    const params = "?pageNumber=" + num + "&search=" + this.state.search;

    fetch("/new/webAdmin/v2/tax" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          taxInfoList: responseJson.result.taxInfoList,
          taxStatusCnt: responseJson.result.taxStatusCnt,
          pageMap: responseJson.result.pageMap,
          isLoading: false,
          pageNumber: num,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _taxInfoRender = () => {
    const { taxInfoList } = this.state;

    let list = [];

    if (taxInfoList) {
      for (const data of taxInfoList) {
        list.push(
          <tr key={data.tax_id}>
            <td width="5%">{data.tax_id}</td>
            <td width="23%">
              {data.artistName} / {data.fullName}
              <br />
              {data.artistEmail}
            </td>
            <td width="15%">
              {data.usTaxYN === "Y" ? "US" : "Non-US"} / {data.type} <br />
              {data.formType === "W9"
                ? "Form 9 완료"
                : data.formType === "W8"
                ? "Form 8 완료"
                : "Form 필요"}
            </td>
            <td width="8%">{data.regDate}</td>
            <td width="8%">
              <a href={data.pdfLink} target="_blank" rel="noopener noreferrer">
                파일 조회
              </a>
            </td>
            <td width="3%">
              <a
                href={
                  data.licenseUrl
                    ? "https://api.stipop.io/admin/license?img=" +
                      data.licenseUrl
                    : null
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                조회
              </a>
            </td>
            <td width="7%">
              {data.status === "W"
                ? "승인대기"
                : data.status === "Y"
                ? "승인"
                : "거부"}
            </td>
            <td width="8%">{data.licenseYN}</td>
            <td width="10%">
              <button
                onClick={() =>
                  this._commit(data.licenseYN, data.tax_id, data.u_id, "Y", data.type)
                }
                className="btn btn-primary"
              >
                승인
              </button>
              <button
                onClick={() =>
                  this._commit(data.licenseYN, data.tax_id, data.u_id, "N", data.type)
                }
                className="btn btn-primary"
                style={{ marginLeft: "1%" }}
              >
                보류
              </button>
            </td>
            <td width="15%">{data.paypal_id ? data.paypal_id : "미입력"}</td>
          </tr>
        );
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _commit = (licenseYN, tax_id, u_id, status, type) => {
    
    if (licenseYN === "N" && type === 'individual') return;
    
    const params = {
      tax_id,
      u_id,
      status,
      type
    };

    fetch("/new/webAdmin/v2/tax/licenses/commit", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "put",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this._getTax(this.state.pageNumber);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _handleChangeInput = (e) => {
    this.setState({ searchKeyword: e.target.value.trim() });
  };

  _handleChangeSearchCri = (e) => {
    this.setState({ searchCri: e.target.value });
  };

  keyEvent = (event) => {
    if (event.key === "Enter") {
      this._getTax(1);
    }
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
          // className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header">
            <h1>세금 정보</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/ContentsList">
                  <i className="fa fa-dashboard"></i>세금
                </a>
              </li>
              <li className="active">세금 정보 관리</li>
            </ol>
          </section>
          <section>
            <Container fluid>
              <Row>
                <Col xs={6} lg={4}>
                  <div className="small-box bg-aqua">
                    <div className="inner" style={{ textAlign: "center" }}>
                      <h2>
                        검토가 필요한 세금 프로필:
                        <br />
                        {this.state.taxStatusCnt.waitCnt}명
                      </h2>
                    </div>
                  </div>
                </Col>
                <Col xs={6} lg={4}>
                  <div className="small-box bg-aqua">
                    <div className="inner" style={{ textAlign: "center" }}>
                      <h2>
                        최종 승인된 세금 프로필: <br />{" "}
                        {this.state.taxStatusCnt.confirmCnt}명{" "}
                      </h2>
                    </div>
                  </div>
                </Col>
                <Col xs={6} lg={4}>
                  <div className="small-box bg-aqua">
                    <div className="inner" style={{ textAlign: "center" }}>
                      <h2>
                        승인되지 않은 세금 프로필: <br />{" "}
                        {this.state.taxStatusCnt.rejectCnt}명{" "}
                      </h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div className="box-tools pull-right">
                    <InputGroup>
                      <FormControl
                        type="text"
                        value={this.state.search}
                        placeholder="Search"
                        onChange={(event) =>
                          this.setState({ search: event.target.value })
                        }
                        onKeyPress={this.keyEvent}
                      />
                      <Button variant="primary" onClick={() => this._getTax(1)}>
                        <i className="fa fa-search" />
                      </Button>
                    </InputGroup>
                  </div>
                  <div className="box-body">
                    <div className="table-responsive">
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th width="5%">No</th>
                            <th width="23%">작가명/이름/이메일</th>
                            <th width="15%">세금상황 요약</th>
                            <th width="8%">Update Date</th>
                            <th width="8%">W8/W9서류</th>
                            <th width="3%">License</th>
                            <th width="7%">상태</th>
                            <th width="8%">송금준비여부</th>
                            <th width="10%"></th>
                            <th width="15%">PAYPAL ID</th>
                          </tr>
                        </thead>
                        <tbody>{this._taxInfoRender()}</tbody>
                      </table>
                    </div>
                  </div>
                  <div className="box-footer clearfix">
                    {/* <ExportReactCSV csvData={this.state.csvlist} fileName='stickerInfo.csv' title='선택 스티커팩 정보'/> */}
                    <ul className="pagination pagination-sm no-margin pull-right">
                      {paging2(
                        this.state.pageNumber,
                        this.state.pageMap,
                        "Money/Tax",
                        this.state.search,
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default withRouter(Tax);
