import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PostingView from '../../_Components/Blog/PostingView';
import BorderBox from '../../_Components/Common/BoxUI/BorderBox';
import PageTitle from '../../_Components/Common/PageTitle/PageTitle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const EditPostsPage = (props) => {
  const [post, setPost] = useState(props.history.location.state.post)
  useEffect(()=>{
    if(!post)
      setPost(props.history.location.state.post)
  },[props])

  return(
    <Wrapper>
      <PageTitle 
        mainTitle={'Edit Blog Post'}
        subTitle={'move to home'}
        v2Link={''}
        navNames={["post"]}
      />
      <BorderBox 
        boxTitle='Blog Edit Page'
        component={<PostingView post={post}/>}
      />
    </Wrapper>
  )
}

export default EditPostsPage;