import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import _ from 'lodash'

const CreateEdit = props => {
  const {
    lang,
    setLang,
    version,
    setVersion,
    type,
    setType,
    typeList,
    bannerList,
    versionList,
    getDetail,
    copy,
    setCopy,
    setCopyTarget,
  } = props
  const langList = ['en', 'ko', 'es', 'pt']
  const [checkedList, setCheckedList] = useState([])
  const [typeCheckList, setTypeCheckList] = useState([])
  const [edit, setEdit] = useState(0)
  const [typeEdit, setTypeEdit] = useState(0)
  const [typeName, setTypeName] = useState('')
  const [params, setParams] = useState({
    title: '',
    subTitle: '',
    info: '',
    tags: '',
    bgColor: '',
    common: '',
    imgs: [],
  })
  const [deleteImg, setDeleteImg] = useState([])
  const checkRef = useRef([])
  const typeCheckRef = useRef([])
  const fileRef = useRef()

  useEffect(() => {
    console.log(params)
  }, [params])

  useEffect(() => {
    if (edit > 0) {
      const selected = bannerList.filter(item => item.SEQ === edit)[0]
      setParams({
        ...params,
        status: selected.STATUS,
        title: selected.TITLE,
        subTitle: selected.SUB_TITLE,
        info: selected.INFO,
        tags: selected.TAGS,
        bgColor: selected.BG_COLOR,
        common: selected.COMMON_TITLE,
        imgs: selected.imgs,
      })
    } else {
      setParams({
        title: '',
        subTitle: '',
        info: '',
        tags: '',
        bgColor: '',
        common: '',
        imgs: [],
      })
    }
  }, [edit])

  const init = async () => {
    await setEdit(0)
    await setTypeEdit(0)
    await setTypeName('')
    await setCheckedList([])
    await setDeleteImg([])
    await setParams({
      title: '',
      subTitle: '',
      info: '',
      tags: '',
      bgColor: '',
      common: '',
      imgs: [],
    })
    await setCopy(null)
    await setCopyTarget(null)
    await getDetail(lang, version)
    if (checkRef.current) {
      for (let i = 0; i < checkRef.current.length; i++) {
        if (checkRef.current[i] && checkRef.current[i].checked) {
          checkRef.current[i].checked = false
        }
      }
    }
    if (typeCheckRef.current) {
      for (let i = 0; i < typeCheckRef.current.length; i++) {
        if (typeCheckRef.current[i] && typeCheckRef.current[i].checked) {
          typeCheckRef.current[i].checked = false
        }
      }
    }
  }

  const handleCheck = e => {
    if (e.target.checked) {
      setCheckedList(checkedList.concat(e.target.value))
    } else {
      setCheckedList(checkedList.filter(id => id !== e.target.value))
    }
  }

  const handleTypeCheck = e => {
    if (e.target.checked) {
      setTypeCheckList(typeCheckList.concat(e.target.value))
    } else {
      setTypeCheckList(typeCheckList.filter(id => id !== e.target.value))
    }
  }

  const renderEdit = () => {
    return (
      <table className="table no-margin">
        <thead>
          <tr>
            <th>TITLE</th>
            <th>SUB TITLE</th>
            <th>INFO</th>
            <th>TAGS</th>
            <th>COMMON TITLE</th>
            <th>IMGS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                value={params.title}
                onChange={e => setParams({ ...params, title: e.target.value })}
              />
            </td>
            <td>
              <input
                type="text"
                value={params.subTitle}
                onChange={e =>
                  setParams({ ...params, subTitle: e.target.value })
                }
              />
            </td>
            <td>
              <textarea
                value={params.info}
                onChange={e => setParams({ ...params, info: e.target.value })}
              />
            </td>
            <td>
              <input
                type="text"
                value={params.tags}
                onChange={e => setParams({ ...params, tags: e.target.value })}
              />
            </td>
            <td>
              <input
                type="text"
                value={params.common}
                onChange={e => setParams({ ...params, common: e.target.value })}
              />
            </td>
            <td style={{ display: 'flex' }}>
              {params.imgs.filter(x => !deleteImg.includes(x.SEQ)).length > 0 &&
                params.imgs
                  .filter(x => !deleteImg.includes(x.SEQ))
                  .map((img, i) => (
                    <div
                      key={i}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          width: '110px',
                          height: '110px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: img.BG_COLOR,
                        }}
                      >
                        <img
                          key={i}
                          src={img.IMG}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          maxWidth: '150px',
                        }}
                      >
                        <span>Background Color</span>
                        <input
                          type="text"
                          value={img.BG_COLOR}
                          onChange={e => {
                            setParams({
                              ...params,
                              imgs: params.imgs.map(item =>
                                item.SEQ === img.SEQ
                                  ? {
                                      ...item,
                                      BG_COLOR: e.target.value,
                                    }
                                  : item
                              ),
                            })
                          }}
                        />
                      </div>

                      <button
                        onClick={() => setDeleteImg(deleteImg.concat(img.SEQ))}
                      >
                        삭제
                      </button>
                    </div>
                  ))}
              {params.upload &&
                params.upload.map((item, i) => (
                  <div
                    key={i}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      backgroundColor: '',
                    }}
                  >
                    <div
                      style={{
                        width: '110px',
                        height: '110px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: item.bgColor,
                      }}
                    >
                      <img
                        key={i}
                        src={URL.createObjectURL(item.file)}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '150px',
                      }}
                    >
                      <span>Background Color</span>
                      <input
                        type="text"
                        value={item.bgColor}
                        onChange={e => {
                          setParams({
                            ...params,
                            upload: params.upload.map((item, idx) =>
                              idx === i
                                ? { ...item, bgColor: e.target.value }
                                : item
                            ),
                          })
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        setParams({
                          ...params,
                          upload: params.upload.filter(
                            (x, index) => index !== i
                          ),
                        })

                        const dataTransfer = new DataTransfer()
                        params.upload
                          .filter((x, index) => index !== i)
                          .forEach(file => dataTransfer.items.add(file.file))

                        if (fileRef.current) {
                          fileRef.current.files = dataTransfer.files
                        }
                      }}
                    >
                      삭제
                    </button>
                  </div>
                ))}
              <input
                type="file"
                ref={fileRef}
                onChange={e => {
                  const upload = []
                  Array.from(e.target.files).map(file =>
                    upload.push({ file: file, bgColor: '' })
                  )
                  setParams({
                    ...params,
                    upload: upload,
                  })
                }}
                multiple={true}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  const updateStatus = status => {
    axios
      .put(
        `/new/webAdmin/v2/ai/create/status`,
        {
          updateList: checkedList,
          status,
        },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        init()
      })
  }

  const clickSave = async id => {
    let formData = new FormData()

    await formData.append('title', params.title)
    await formData.append('subTitle', params.subTitle)
    await formData.append('info', params.info)
    await formData.append('tags', params.tags)
    await formData.append('common', params.common)

    let bgColor = []
    if (params.upload) {
      await params.upload.map(async file => {
        await formData.append('imgs', file.file)
        await bgColor.push(file.bgColor)
      })
    }

    await formData.append('bgColor', JSON.stringify(bgColor))

    if (id) {
      await formData.append('status', params.status)
      await formData.append('updateImg', JSON.stringify(params.imgs))

      if (deleteImg.length > 0) {
        await formData.append('deleteImg', deleteImg)
      }
      await axios
        .put(`/new/webAdmin/v2/ai/create/banner/${id}`, formData, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(() => {
          init()
        })
    } else {
      await formData.append('type', type)
      await axios
        .post(`/new/webAdmin/v2/ai/create/banner`, formData, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(() => {
          init()
        })
    }
  }

  const clickTypeSave = async () => {
    await axios
      .post(
        `/new/webAdmin/v2/ai/create/type`,
        { type: typeName, lang: lang, version: version },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        init()
      })
  }

  const clickDelete = async type => {
    if (type === 'banner') {
      if (checkedList.length > 0) {
        await axios
          .delete(`/new/webAdmin/v2/ai/create/banner`, {
            data: {
              deleteList: checkedList,
            },
            headers: {
              token: sessionStorage.getItem('token'),
            },
          })
          .then(() => {
            init()
          })
        setCheckedList([])
      }
    } else if (type === 'type') {
      if (typeCheckList.length > 0) {
        await axios
          .delete(`/new/webAdmin/v2/ai/create/type`, {
            data: {
              deleteList: typeCheckList,
            },
            headers: {
              token: sessionStorage.getItem('token'),
            },
          })
          .then(() => {
            init()
          })
        setTypeCheckList([])
      }
    }
  }

  const reorderPromise = (id, order) => {
    const params = {
      id,
      order,
    }

    return new Promise(async (resolve, reject) => {
      axios
        .put(`/new/webAdmin/v2/ai/create/order`, params, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const reorder = async list => {
    const result = await Promise.all(
      list.map((item, index) => reorderPromise(item.SEQ, index + 1))
    )

    if (result) {
      init()
    }
  }

  const moveOrder = async (idx, move) => {
    const updateData = [...bannerList]
    const target = updateData.splice(move === 'up' ? idx - 1 : idx + 1, 1)[0]
    updateData.splice(idx, 0, target)

    await reorder(updateData)
  }

  return (
    <div style={{ minHeight: '100%' }}>
      <section className="content-header">
        <h1>AI Create Page 관리</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/ContentsList">
              <i className="fa fa-dashboard"></i>AI
            </a>
          </li>
          <li className="active">Create</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <section
            className="col-lg-3 connectedSortable"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <section>
              <div className="box box-info">
                <div
                  className="box-header with-border"
                  style={{
                    padding: '20px 10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                    Version
                  </span>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table mo-margin">
                      <tbody>
                        {versionList &&
                          versionList.map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <td
                                onClick={() => {
                                  setVersion(item)
                                }}
                                style={{
                                  backgroundColor:
                                    item === version ? '#ededed' : '#fff',
                                  cursor: 'pointer',
                                  fontWeight: item === version ? 'bold' : '',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                {item ? item : '-'}
                                {item === version && (
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      if (copy !== null) {
                                        setCopy(null)
                                      } else {
                                        setCopy(version)
                                      }
                                    }}
                                  >
                                    {copy === version ? '취소' : '복사'}
                                  </button>
                                )}
                              </td>
                              {item === version && copy === version && (
                                <td
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    gap: '10px',
                                  }}
                                >
                                  {versionList
                                    .filter(item => item !== version)
                                    .map((item, index) => (
                                      <button
                                        className="btn btn-default"
                                        key={index}
                                        onClick={() => {
                                          setCopyTarget(item)
                                        }}
                                        style={{ width: '100%' }}
                                      >
                                        {item ? item : '-'}
                                      </button>
                                    ))}
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="box box-info">
                <div
                  className="box-header with-border"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <span>Lang</span>
                      <select
                        style={{ display: 'inline-block', margin: '0 20px' }}
                        value={lang}
                        onChange={e => {
                          setLang(e.target.value)
                        }}
                      >
                        {langList.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    {typeEdit !== 0 ? (
                      <div style={{ display: 'flex', gap: '0 10px' }}>
                        <button
                          className="btn btn-default"
                          onClick={() => {
                            clickTypeSave()
                          }}
                        >
                          저장
                        </button>
                        <button
                          className="btn btn-default"
                          onClick={() => {
                            setTypeEdit(0)
                            init()
                          }}
                        >
                          취소
                        </button>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', gap: '0 10px' }}>
                        <button
                          className="btn btn-default"
                          onClick={() => {
                            setTypeEdit(-1)
                          }}
                        >
                          추가
                        </button>
                        <button
                          className="btn btn-default"
                          onClick={() => {
                            clickDelete('type')
                          }}
                        >
                          삭제
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table mo-margin">
                      <tbody>
                        {typeEdit < 0 && (
                          <tr>
                            <td>TYPE</td>
                            <td>
                              <input
                                type="text"
                                value={typeName}
                                onChange={e => setTypeName(e.target.value)}
                              />
                            </td>
                          </tr>
                        )}
                        {typeList &&
                          typeList.map((item, index) => (
                            <tr key={index}>
                              <td style={{ width: '15px' }}>
                                <input
                                  ref={el => (typeCheckRef.current[index] = el)}
                                  type="checkbox"
                                  onChange={handleTypeCheck}
                                  value={item.SEQ}
                                />
                              </td>
                              <td
                                onClick={() => {
                                  setType(item.SEQ)
                                }}
                                style={{
                                  backgroundColor:
                                    item.SEQ === type ? '#ededed' : '#fff',
                                  cursor: 'pointer',
                                }}
                              >
                                {item.TYPE}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <section className="col-lg-9 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  paddingBottom: '30px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {edit === 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      gap: '0 10px',
                    }}
                  >
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setEdit(-1)
                      }}
                    >
                      추가
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        clickDelete('banner')
                      }}
                    >
                      삭제
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        updateStatus('Y')
                      }}
                    >
                      Activate
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        updateStatus('N')
                      }}
                    >
                      Deactivate
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      gap: '0 10px',
                    }}
                  >
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        if (edit > 0) {
                          clickSave(edit)
                        } else if (edit < 0) {
                          clickSave()
                        }
                      }}
                    >
                      저장
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        init()
                      }}
                    >
                      취소
                    </button>
                  </div>
                )}
              </div>
              <div className="box-body" style={{ width: '100%' }}>
                <div className="table-responsive">
                  {edit !== 0 ? (
                    renderEdit()
                  ) : (
                    <div className="table no-margin">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th></th>
                          <th>STATUS</th>
                          <th>TITLE</th>
                          <th>SUB TITLE</th>
                          <th>INFO</th>
                          <th>TAGS</th>
                          <th>COMMON TITLE</th>
                          <th>IMGS</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {bannerList &&
                          bannerList.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <input
                                  ref={el => (checkRef.current[index] = el)}
                                  type="checkbox"
                                  onChange={handleCheck}
                                  value={item.SEQ}
                                />
                              </td>
                              <td>{item.STATUS}</td>
                              <td>{item.TITLE}</td>
                              <td>{item.SUB_TITLE}</td>
                              <td>{item.INFO}</td>
                              <td>{item.TAGS}</td>
                              <td>{item.COMMON_TITLE}</td>
                              <td style={{ display: 'flex' }}>
                                {item.imgs.length > 0 &&
                                  item.imgs.map((img, i) => (
                                    <div
                                      key={i}
                                      style={{
                                        display: 'flex',
                                        width: '110px',
                                        height: '110px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: img.BG_COLOR,
                                      }}
                                    >
                                      <img
                                        src={img.IMG}
                                        alt=""
                                        key={i}
                                        width={100}
                                        height={100}
                                      />
                                    </div>
                                  ))}
                              </td>
                              <td>
                                <button
                                  className="btn btn-default"
                                  onClick={() => {
                                    setEdit(item.SEQ)
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                              <td>
                                {index !== 0 && (
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      moveOrder(index, 'up')
                                    }}
                                  >
                                    ▲
                                  </button>
                                )}
                                {index !== bannerList.length - 1 && (
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      moveOrder(index, 'down')
                                    }}
                                  >
                                    ▼
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default CreateEdit
