import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

// import LeftMenu from "../../Components/LeftMenu";
import Loading from "../../Components/Common/Loading/Loading.js";
import Banner from "../../Components/MobileBanner/Banner";
import BannerContainer from "../../Components/MobileBanner/BannerContainer";

const MobileBannerInsertPage = (props) => {
  const isNewBanner = props.match.params.c_id === "Insert" ? true : false;
  const [isLoading, setIsLoading] = useState(true);
  const [bannerContainer, setBannerContainer] = useState({
    type: props.location.state ? props.location.state.type : "",
    title: props.location.state ? props.location.state.title : "",
    tag: props.location.state ? props.location.state.tag : "",
  });
  const [bannerContainerPackages, setBannerContainerPackages] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      if (!isNewBanner) {
        getBannerContainerPackages(props.match.params.c_id);
      } else {
        setIsLoading(false);
      }
    } else {
      props.history.push("/");
    }
  }, []);

  const getBannerContainerPackages = (c_id) => {
    setIsLoading(true);
    fetch("/new/webAdmin/v2/container/" + c_id, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setBannerContainerPackages(responseJson.result.containerPackageList);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const addBannerContainerBanner = () => {
    setBannerContainerPackages(
      bannerContainerPackages.concat({
        sTitle: "",
        bTitle: "",
        packageId: null,
        stickerId: null,
        img: null,
        tag: "",
      })
    );
  };

  const removeBannerContainerPackage = (idx) => {
    if (bannerContainerPackages[idx].seq) {
      const params = {
        seq: bannerContainerPackages[idx].seq,
      };

      fetch("/new/webAdmin/v2/container/package", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "delete",
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
          if (responseJson.status === "success") {
            setBannerContainerPackages(
              bannerContainerPackages.filter((item, index) => index === idx)
            );
          } else {
            alert("실패");
          }
        })
        .catch((error) => {
          console.error("123=" + error);
        });
    } else {
      setBannerContainerPackages(
        bannerContainerPackages.filter((item, index) => index !== idx)
      );
    }
  };

  const moveOrderUp = (seq, order, idx) => {
    if (idx === 0) {
      alert("처음입니다.");
      return;
    }

    const params = {
      c_id: props.match.params.c_id,
      seq: seq,
      order: order,
    };

    fetch("/new/webAdmin/v2/container/package/order/up", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "put",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        if (responseJson.status === "success") {
          getBannerContainerPackages(props.match.params.c_id);
        } else {
          alert("실패");
        }
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const moveOrderDown = (seq, order, idx) => {
    if (idx + 1 === bannerContainerPackages.length) {
      alert("마지막입니다.");
      return;
    }

    const params = {
      c_id: props.match.params.c_id,
      seq: seq,
      order: order,
    };

    fetch("/new/webAdmin/v2/container/package/order/down", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "put",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        if (responseJson.status === "success") {
          getBannerContainerPackages(props.match.params.c_id);
        } else {
          alert("실패");
        }
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let updateFormData = new FormData();
    let updateImgId = "";

    updateFormData.append("type", bannerContainer.type);
    updateFormData.append("tag", bannerContainer.tag);
    updateFormData.append("title", bannerContainer.title);

    if (!isNewBanner) {
      updateFormData.append("c_id", props.match.params.c_id);
    }

    updateFormData.append(
      "packageInput",
      JSON.stringify(bannerContainerPackages)
    );

    for (const tmp of bannerContainerPackages) {
      if (tmp.img && typeof tmp.img[0] !== "string") {
        updateFormData.append("imgs", tmp.img[0]);
        if (!isNewBanner) {
          updateImgId += tmp.seq + ",";
        }
      } else {
        if (!isNewBanner) {
          updateImgId += "null,";
        }
      }
    }

    updateFormData.append(
      "updateImgId",
      updateImgId.substring(0, updateImgId.length - 1)
    );

    fetch("/new/webAdmin/v2/container", {
      headers: {
        token: sessionStorage.getItem("token"),
      },
      method: isNewBanner ? "POST" : "PUT",
      body: updateFormData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        props.history.push({
          pathname: "/Banner/Mobile",
          search: "?type=" + bannerContainer.type,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
        alert("실패");
      });
  };

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      {isLoading ? (
        <Loading />
      ) : (
        <div
          // className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header" style={{ marginBottom: "15px" }}>
            <h1>Mobile App 배너 관리</h1>
            <ol className="breadcrumb">
              <li>
                <i className="fa fa-dashboard"></i> 배너 관리
              </li>
              <li className="active">Mobile App 배너 관리</li>
            </ol>
          </section>
          <section>
            <Container fluid>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <BannerContainer
                    isNewBanner={isNewBanner}
                    bannerContainer={bannerContainer}
                    setBannerContainer={setBannerContainer}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col>
                  <Button onClick={addBannerContainerBanner}>
                    패키지 추가
                  </Button>
                </Col>
              </Row>
              {bannerContainerPackages &&
                bannerContainerPackages.map((item, idx) => (
                  <Banner
                    key={idx}
                    idx={idx}
                    type={bannerContainer.type}
                    bannerContainerPackage={item}
                    setBannerContainerPackage={(index, data) =>
                      setBannerContainerPackages(
                        bannerContainerPackages.map((item, idx) =>
                          idx === index ? data : item
                        )
                      )
                    }
                    removeBannerContainerPackage={removeBannerContainerPackage}
                    moveOrderUp={moveOrderUp}
                    moveOrderDown={moveOrderDown}
                  />
                ))}
              <Row style={{ marginTop: "10px" }}>
                <Col md="auto">
                  <Button
                    variant="secondary"
                    onClick={() =>
                      props.history.push({
                        pathname: "/Banner/Mobile",
                        search: "?type=" + bannerContainer.type,
                      })
                    }
                  >
                    목록으로
                  </Button>
                </Col>
                <Col md="auto">
                  <Button variant="success" onClick={handleSubmit}>
                    등록하기
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      )}
    </React.Fragment>
  );
};

export default MobileBannerInsertPage;
