import React from "react";
import { withRouter } from "react-router-dom";
import { paging } from "../../Common/Page";
import "react-datepicker/dist/react-datepicker.css";
import { ExportReactCSV } from "../../Common/ExportReactCSV";
import { Modal, Row, Col } from "react-bootstrap";

import fileDownload from "file-saver";

class AThemeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageNumber: this.props.pageNumber ? this.props.pageNumber : 1,
      selectGroup: this.props.search ? this.props.search : undefined,
      type:
        this.props.location.state === undefined
          ? "CO"
          : this.props.location.state.t_type,
      idList: [],
      img: "",
      order: [],
      groupList: [],
      selfServingPackageStickers: [],
      show: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this._getAThemeDataList(
        this.state.pageNumber,
        this.state.type,
        this.state.selectGroup
      );
      this._getSelfServingPackageStickers();
    } else {
      this.props.history.push("/");
    }
  }

  _imgRender = () => {
    let list = [];
    let num = 0;
    if (this.state.stickers) {
      let row = [];
      for (const img of this.state.stickers) {
        if (num % 4 === 0) {
          num && list.push(<Row>{row}</Row>);
          row = [];
          row.push(
            <Col>
              <img
                key={img.s_id}
                src={img.s_img}
                style={{
                  width: "140px",
                  height: "140px",
                  position: "relative",
                  margin: "5px",
                  boxSizing: "border-box",
                  boxShadow:
                    "-6px 0 0 inset ,6px 0 0 inset,0 6px 0 inset ,0 -6px 0 inset",
                }}
                alt={img.s_img}
              />
              <p>{img.s_id}</p>
            </Col>
          );
        } else {
          row.push(
            <Col>
              <img
                key={img.s_id}
                src={img.s_img}
                style={{
                  width: "140px",
                  height: "140px",
                  position: "relative",
                  margin: "5px",
                  boxSizing: "border-box",
                  boxShadow:
                    "-6px 0 0 inset ,6px 0 0 inset,0 6px 0 inset ,0 -6px 0 inset",
                }}
                alt={img.s_img}
              />
              <p>{img.s_id}</p>
            </Col>
          );
        }
        num++;
      }
      for (let i = 0; i < 4 - row.length; i++) {
        row.push(<Col></Col>);
      }
      row && list.push(<Row>{row}</Row>);
      return list;
    } else {
      return <div></div>;
    }
  };

  _getAThemeDataList = (num, type, group) => {
    let params;
    if (group) {
      params = "?pageNumber=" + num + "&t_type=" + type + "&group=" + group;
    } else {
      params = "?pageNumber=" + num + "&t_type=" + type;
    }

    fetch("/new/webAdmin/v2/aThemeList" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
      //    ,body: JSON.stringify(params)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          aThemeList: responseJson.aThemeList,
          pageMap: responseJson.pageMap,
          groupList: responseJson.groupList,
          pageNumber: num,
          type: type,
          selectGroup: responseJson.selectedGroup[0].seq,
          isLoading: false,
          order: [],
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _aThemeListRender = () => {
    let list = [];

    let no = 1;
    this.state.order = [];
    if (this.state.aThemeList) {
      for (const data of this.state.aThemeList) {
        list.push(
          <tr key={data.itview_id}>
            <td>{no++}</td>
            <td>
              <input
                type="checkbox"
                onChange={this._checkBoxEvent}
                key={data.p_img}
                value={data.seq}
              />
            </td>
            <td>{data.p_id}</td>
            <td>
              <button
                type="button"
                onClick={() => this._testDownImageALl(data.p_id, data.p_name)}
                className="btn btn-primary"
              >
                다운
              </button>
            </td>
            <td>{data.p_name}</td>
            <td
              onClick={() => {
                this.setState({ img: data.p_img, stickers: data.stickers });
                this.handleClick();
              }}
            >
              <img src={data.p_img} width="100px" height="100px" alt=""></img>
            </td>
            <td>{data.regDate}</td>
            <td>
              <button
                type="button"
                className="btn btn-primary btn-block btn-flat"
                onClick={() => this._up(data.p_id, data.T_TYPE, data.T_ORDER)}
              >
                ▲
              </button>
            </td>
            <td>
              <button
                type="button"
                className="btn btn-primary btn-block btn-flat"
                onClick={() => this._down(data.p_id, data.T_TYPE, data.T_ORDER)}
              >
                ▼
              </button>
            </td>
          </tr>
        );
        this.state.order.push(data.T_ORDER);
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _getSelfServingPackageStickers = async () => {
    fetch("/new/webAdmin/v2/selfServingStickers", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          selfServingPackageStickers: responseJson.result,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _testDownImageALl = async (p_id, p_name) => {
    fetch("/new/webAdmin/v2/download/" + p_id, {
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
      //    ,body: JSON.stringify(params)
    })
      .then(async (response) => {
        fileDownload(
          await (await new Response(response.body)).blob(),
          p_name + ".zip"
        );
      })

      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _checkBoxEvent = (event) => {
    const { idList } = this.state;
    const { value } = event.target;

    if (event.target.checked) {
      this.setState({ idList: idList.concat(value) });
    } else {
      this.setState({ idList: idList.filter((id) => id !== value) });
    }
    // this.setState({idList:this.state.idList.concat(data.itview_id)}
  };

  _delete = (p_id, no) => {
    if (this.state.idList.length === 0) {
      alert("삭제할 아이템을 선택하세요");
      return;
    }

    const params = {
      idList: this.state.idList,
    };
    fetch("/new/webAdmin/v2/aThemeDelete", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())

      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({ idList: [] });
        this._getAThemeDataList(
          this.state.pageNumber,
          this.state.type,
          this.state.selectGroup
        );
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _up = (p_id, t_type, order) => {
    const result = Math.max.apply(null, this.state.order);

    if (result === order) {
      alert("처음입니다.");
      return;
    }
    const params = {
      p_id: p_id,
      t_type: t_type,
      t_order: order,
      g_id: this.state.selectGroup,
    };

    fetch("/new/webAdmin/v2/aTheme/order/up", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "PUT",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        if (responseJson.status === "success") {
          this._getAThemeDataList(
            this.state.pageNumber,
            this.state.type,
            this.state.selectGroup
          );
        } else {
          alert("실패");
        }
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _down = (p_id, t_type, order) => {
    const result = Math.min.apply(null, this.state.order);
    if (result === order) {
      alert("마지막입니다.");
      return;
    }
    const params = {
      p_id: p_id,
      t_type: t_type,
      t_order: order,
      g_id: this.state.selectGroup,
    };

    fetch("/new/webAdmin/v2/aTheme/order/down", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "PUT",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        if (responseJson.status === "success") {
          this._getAThemeDataList(
            this.state.pageNumber,
            this.state.type,
            this.state.selectGroup
          );
        } else {
          alert("실패");
        }
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _register = () => {
    this.props.history.push({
      pathname: "/AThemeInsert",
      state: {
        t_type: this.state.type,
        g_id: this.state.selectGroup,
      },
    });
  };

  _groupListRender = () => {
    let list = [];
    if (this.state.groupList) {
      for (const data of this.state.groupList) {
        if (data.title === "") continue;
        const name = data.subtitle
          ? data.title + " - " + data.subtitle
          : data.title;
        list.push(
          <option key={data.seq} value={data.seq}>
            {name}
          </option>
        );
      }
      return list;
    }
  };

  handleClick = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
          // className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header">
            <h1>채널사패키지 관리</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/AThemeList">
                  <i className="fa fa-dashboard"></i> A테마
                </a>
              </li>
              <li className="active">채널사패키지 관리</li>
            </ol>
          </section>
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div className="box-header with-border">
                    <select
                      style={{ display: "inline-block" }}
                      value={this.state.selectGroup}
                      onChange={(event) =>
                        this._getAThemeDataList(
                          1,
                          this.state.type,
                          event.target.value
                        )
                      }
                    >
                      {this._groupListRender()}
                    </select>
                    <select
                      style={{ display: "inline-block" }}
                      value={this.state.type}
                      onChange={(event) =>
                        this._getAThemeDataList(
                          1,
                          event.target.value,
                          this.state.selectGroup
                        )
                      }
                    >
                      <option value="CO">Common</option>
                      <option value="EN">English</option>
                      <option value="KO">Korea</option>
                      <option value="ES">Espanol</option>
                    </select>
                    <div className="box-tools pull-right">
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="collapse"
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="remove"
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  {/* <!-- /.box-header --> */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>체크</th>
                            <th>패키지 아이디</th>
                            <th>다운</th>
                            <th>패키지 이름</th>
                            <th>패키지 이미지</th>
                            <th>등록일</th>
                            <th>업</th>
                            <th>다운</th>
                          </tr>
                        </thead>
                        <tbody>{this._aThemeListRender()}</tbody>
                      </table>
                    </div>
                    <div className="box-footer clearfix">
                      <button
                        type="button"
                        onClick={() => this._register()}
                        className="btn btn-primary btn-block btn-flat"
                        style={{
                          width: "10%",
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                      >
                        등록
                      </button>
                      <button
                        type="button"
                        onClick={this._delete}
                        className="btn btn-primary btn-block btn-flat"
                        style={{
                          width: "10%",
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                      >
                        삭제
                      </button>
                      <ExportReactCSV
                        csvData={this.state.selfServingPackageStickers}
                        fileName="SelfServingPackageStickers.csv"
                        title="Self Serving 스티커팩 정보"
                      />
                      {/* <button type="button" onClick={this._excel} className="btn btn-primary btn-block btn-flat" style={{ width: '10%', display: "inline-block", margin: "0" }}>
                                                엑셀다운
                                            </button> */}
                      <ul className="pagination pagination-sm no-margin pull-right">
                        {paging(
                          this.state.pageNumber,
                          this.state.pageMap,
                          "AThemeList",
                          this.state.selectGroup
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          {/* 팝업시작 */}
          <Modal
            show={this.state.show}
            onHide={this.handleClick}
            scrollable
            size="lg"
          >
            <Modal.Body>
              <div>{this._imgRender()}</div>
            </Modal.Body>
          </Modal>
          {/* 팝업끝 */}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(AThemeList);
