import React from "react";
import ArtistGroups from "../../Components/Member/ArtistGroups";
// import LeftMenu from "../../Components/LeftMenu";

const ArtistGroupPage = ({ match, location }) => {
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <ArtistGroups />
    </React.Fragment>
  );
};

export default ArtistGroupPage;
