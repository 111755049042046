import React from 'react'
import styled from 'styled-components'
import InputCopy from '../../../_Components/Curation/InputCopy'

const TrendingItemBox = styled.div`
  background-color: ${props => props.color};
  width: 140px;
  height: 140px;
  border-radius: 20px;
  margin: 0 10px 5px 10px;
  display: flex;

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`

const TrendingItem = props => {
  const clickImg = (
    cardInfo,
    packageId,
    lightColor,
    darkColor,
    mainId,
    mainImg
  ) => {
    props.clickImg(cardInfo, packageId, lightColor, darkColor, mainId, mainImg)
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
    >
      <TrendingItemBox color={!props.mode ? props.lightColor : props.darkColor}>
        <img
          src={props.stickerImg}
          alt=""
          onClick={() =>
            clickImg(
              props.cardInfo,
              props.packageId,
              props.lightColor,
              props.darkColor,
              props.stickerId,
              props.stickerImg
            )
          }
        />
      </TrendingItemBox>
      <span style={{ color: !props.mode ? 'black' : 'white' }}>
        <InputCopy copyText={props.packageId} color={!props.mode ? 'black' : 'white'}/>
      </span>
    </div>
  )
}

export default TrendingItem
