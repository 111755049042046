import React from "react";
import SearchApiNew from "../../Components/B2B/searchApiNew";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const SearchApiNewPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <SearchApiNew pageNumber={query.pageNumber} search={query.search} />
    </React.Fragment>
  );
};

export default SearchApiNewPage;
