import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import TextInput from "./TextInput";


const Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
`;

const Field = styled.th`
    border-top: 1px solid #f4f4f4;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: left;
`;

const Data = styled.td`
    border-top: 1px solid #f4f4f4;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    display: table-cell;
`;

const Input = styled.input`
    width:80%;
    padding:8px;
`;

const List = ({fieldList, dataList}) => {
    // console.log(dataList)
    return (
        <Table>
            <tbody>
                <tr>
                    {
                        fieldList.map((filed, index) => {
                            return (
                                <Field key={index}>{filed}</Field>
                            )
                        })
                    }
                </tr>
                {
                    dataList.map((data, index) => {
                        
                        let list = [];
            
                        for (const key in data.document) {

                            if (key === 'packageImg') {
                                list.push(
                                    <td key={key+index}><img src={data.document[key]} style={{width:100, height:100}} /></td>
                                )
                            } else if (key === 'stickerImg') {
                                list.push(
                                    <td key={key+index}><img src={data.document[key]} style={{width:100, height:100}} /></td>
                                )
                            } else if (key === 'stickerKeyword') {
                                if (data.highlights.length !== 0 && key === data.highlights[0].field) {
                                    list.push(
                                        <Data style={{width:200}} key={key+index} >
                                            <span dangerouslySetInnerHTML={{__html: data.highlights[0].snippet}} />
                                        </Data>
                                    )
                                } else {
                                    list.push(
                                        <td key={key+index}><textarea readOnly style={{width:200, height:200}} type="text" value={data.document[key]} /></td>
                                    )
                                }
                            } else if (data.highlights.length !== 0 && key === data.highlights[0].field) {
                                list.push(
                                    <Data key={key+index} >
                                        <span dangerouslySetInnerHTML={{__html: data.highlights[0].snippet}} />
                                    </Data>
                                )
                            } else {
                                list.push(
                                    <Data key={key+index}>{data.document[key]}</Data>
                                );
                            }
                        }
            
                        return (
                            <tr key={index}>
                                {list}
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}

export default List;