import React from "react";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";

const defaultEndDate = new Date();
const defaultStartDate = new Date(
  defaultEndDate.getTime() - 6 * 24 * 60 * 60 * 1000
);

class ArtistSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      },
      isLoading: true,
      artistList: [],
    };
  }

  componentDidMount() {
    // check token
    if (sessionStorage.getItem("token")) {
      this.searchArtist(this.state.dateRange);
    } else {
      this.props.history.push("/");
    }
  }

  searchArtist = (dateRange) => {
    this.setState({ isLoading: true });
    fetch("/new/webAdmin/v2/artist-by-regDate", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "POST",
      body: JSON.stringify(dateRange),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") {
          if (responseJson.result !== undefined) {
            this.setState({ artistList: [], isLoading: false });
            alert(responseJson.result);
          } else {
            this.setState({ artistList: [], isLoading: false });
            alert("통신 오류");
          }
        } else {
          this.setState({ artistList: responseJson.result, isLoading: false });
        }
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  renderArtists = () => {
    let list = [];
    let i = 1;
    if (this.state.artistList) {
      for (const data of this.state.artistList) {
        list.push(
          <tr key={data.u_id}>
            <td>{i++}</td>
            <td>{data.u_id}</td>
            <td>{data.u_name}</td>
            <td>{data.u_email}</td>
            <td>{data.u_date}</td>
            <td>{data.c_name}</td>
          </tr>
        );
      }
    }
    return list;
  };

  onDateSubmit = () => {
    this.searchArtist(this.state.dateRange);
  };

  handleStartChange = (date) => {
    const { dateRange } = this.state;
    this.setState({ dateRange: { ...dateRange, startDate: date } });
  };
  handleEndChange = (date) => {
    const { dateRange } = this.state;
    this.setState({ dateRange: { ...dateRange, endDate: date } });
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    } else {
      return (
        <div
          // className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header">
            <h1>작가 검색</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/Search">
                  <i className="fa fa-dashboard"></i> 회원 관리
                </a>
              </li>
              <li className="active">작가 검색</li>
            </ol>
          </section>
          <section className="content">
            <section className="col-lg-12 connectedSortable">
              <div className="box box-info">
                <Row>
                  <Col md={3}>
                    <label>시작일</label>{" "}
                    <i
                      className="fa fa-calendar"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <ReactDatePicker
                      selected={this.state.dateRange.startDate}
                      onChange={this.handleStartChange}
                    />
                  </Col>
                  <Col md={3}>
                    <label>종료일</label>{" "}
                    <i
                      className="fa fa-calendar"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <ReactDatePicker
                      selected={this.state.dateRange.endDate}
                      onChange={this.handleEndChange}
                    />
                  </Col>
                  <Col>
                    <button onClick={this.onDateSubmit}>검색</button>
                  </Col>
                </Row>
                {/* <!-- /.box-header --> */}
                <div className="box-body">
                  <div
                    className="table-responsive"
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "750px",
                      overflow: "auto",
                    }}
                  >
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>UserID</th>
                          <th>이름</th>
                          <th>email</th>
                          <th>가입일</th>
                          <th>국적</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderArtists()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      );
    }
  }
}

export default withRouter(ArtistSearch);
