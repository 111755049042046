import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Input = styled.input`
    width:80%;
    padding:8px;
`;

const Component = ({value, setDataList, key}) => <Input key={key} defaultValue={value} onChange={(e) => setDataList(e.target.value)} />;

const TextInput = (value, setDataList, key) => {

    const [state, setState] = useState(value);

    return [
        state,
        <Component value={state} setDataList={setDataList} key={key ? key : Math.random(10)} />,
        setState
    ]

};

export default TextInput;