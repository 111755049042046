import React from "react";
import styled from "styled-components";
import { ResponsiveLine } from "@nivo/line";
import { useState } from "react";
import { useEffect } from "react";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const StudioBannerChart = ({ bannerImages }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const tempEnData = [];
    const tempKoData = [];
    for (let i = 0; i < bannerImages[0].dates.length; i++) {
      tempEnData.push({
        x: bannerImages[0].dates[i],
        y: bannerImages[0].dailyClick[i],
      });
      tempKoData.push({
        x: bannerImages[1].dates[i],
        y: bannerImages[1].dailyClick[i],
      });
    }
    setData([
      {
        id: "Banner - KO",
        data: tempKoData,
      },
      {
        id: "Banner - EN",
        data: tempEnData,
      },
    ]);
  }, [bannerImages]);

  return (
    <Wrapper>
      {data && data !== null && (
        <ResponsiveLine
          data={data}
          margin={{ top: 60, right: 60, bottom: 60, left: 80 }}
          animate={true}
          pointSize={12}
          pointBorderWidth={0}
          useMesh={true}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "date",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "count",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          legends={[
            {
              anchor: "top-right",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: -40,
              itemsSpacing: 40,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      )}
    </Wrapper>
  );
};

export default StudioBannerChart;
