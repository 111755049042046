import React, { useEffect, useState } from 'react';
import { MdContentCopy } from 'react-icons/md'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components';

const InputCopyWrapper = styled.span`
    text-align: center;
    &:hover {
       cursor: pointer;
    }
    ${({color})=>{
        return `color: ${color};`
    }}
`

const CopyButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin-left: 3px;
`

const InputCopy = ({copyText, color}) => {
    const [show, setShow] = useState(false);
    const copy = () => {
        // navigator.clipboard.writeText(copyText);

        const text = document.createElement('textarea')
        text.value = copyText
        document.body.appendChild(text)
        text.select()
        document.execCommand('copy')
        document.body.removeChild(text)

        setShow(true);
    }

    useEffect(()=>{
        if(show){
            setTimeout(()=>{ setShow(false) }, 1500);
        }
    },[show]);

    return(
        <OverlayTrigger
            show={show}
            placement="bottom"
            overlay={
                <Tooltip>
                    Copied!
                </Tooltip>
            }
        >
            <InputCopyWrapper onClick={copy} color={color?color:'black'}>
                {copyText}
                <CopyButton>
                    <MdContentCopy size={20} color={color?color:'black'}/>
                </CopyButton>
            </InputCopyWrapper>
        </OverlayTrigger>
    )
}

export default InputCopy;