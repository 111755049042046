import React from "react";
import AppInfo from "../../Components/B2B/AppInfo";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const AppInfoPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <AppInfo pageNumber={query.pageNumber} search={query.search} />
    </React.Fragment>
  );
};

export default AppInfoPage;
