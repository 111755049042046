import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  StickersSection,
  LibrariesSection,
  SelfServingGroupsSection,
  RegionSection,
  GuidelinesSection,
  ContainersSection,
  AdSection,
} from './ContentsInfoModalSections'
import { Button, Container, Row, Col } from 'react-bootstrap'

import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { FiChevronDown, FiCheck } from 'react-icons/fi'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import fileDownload from 'file-saver'
import axios from 'axios'
import LoadingSpinner from '../Common/Loading/LoadingSpinner'

const ContentsInfoModal = forwardRef((props, ref) => {
  const [packageName, setPackageName] = useState('')
  const [charName, setCharName] = useState('')
  const [desc, setDesc] = useState('')
  const [categories, setCategories] = useState([])
  const [packageTitles, setPackageTitles] = useState([])
  const [bulk, setBulk] = useState([])
  const [keyword, setKeyword] = useState([])
  const [stickers, setStickers] = useState([])
  const [packageStatus, setPackageStatus] = useState(props.package.commit)
  const [newTitle, setNewTitle] = useState('')
  const [newTitleLanguage, setNewTitleLanguage] = useState(0)
  const [telegramLink, setTelegramLink] = useState('')
  const [selectedLang, setSelectedLang] = useState(
    props.selectedLang ? props.selectedLang : 0
  )
  const [inputBulk, setInputBulk] = useState('')
  const [inputKeyword, setInputKeyword] = useState('')
  // const [tagLang, setTagLang] = useState(
  //   props.package.tag_lang ? props.package.tag_lang : null
  // )
  // const [tagLang, setTagLang] = useState(0)
  const [tagLangList, setTagLangList] = useState([
    128, 129, 130, 132, 133, 134, 135, 136, 137, 138, 148, 151, 154, 162, 167,
    168, 169, 171, 173, 175, 176, 177, 178, 179, 181, 182, 131,
  ])
  // Section 내 Collapse 의 in props 값, true : 열림, false : 닫힘
  const [openStickersSection, setOpenStickersSection] = useState(true)
  const [openLibrariesSection, setOpenLibrariesSection] = useState(false)
  const [openSelfServingGroupsSection, setOpenSelfServingGroupsSection] =
    useState(true)
  const [openRegionSection, setOpenRegionSection] = useState(true)
  const [openGuidelinesSection, setOpenGuidelinesSection] = useState(true)
  const [openContainersSection, setOpenContainersSection] = useState(false)
  const [bookmark, setBookmark] = useState(
    props.package.p_bookmark === 'Y' ? true : false
  )
  const [premium, setPremium] = useState(
    props.package.artiSubPackageYn === 'Y' ? true : false
  )
  const [isUS, setIsUS] = useState(props.package.isUS === 'Y' ? true : false)
  const [webpImg, setWebpImg] = useState(null)

  const packRef = useRef()
  const langRef = useRef()
  const charRef = useRef()
  const descRef = useRef()
  const categoryRef = useRef()
  const categoryBtnRef = useRef()
  const bulkRef = useRef()
  const bulkBoxRef = useRef()
  const keywordRef = useRef()
  const keywordBoxRef = useRef()
  const modalRef = useRef()

  const [packNameEdit, setPackNameEdit] = useState(false)
  const [langEdit, setLangEdit] = useState(false)
  const [charNameEdit, setCharNameEdit] = useState(false)
  const [descEdit, setDescEdit] = useState(false)
  const [categoryEdit, setCategoryEdit] = useState(false)
  const [bulkEdit, setBulkEdit] = useState(false)
  const [keywordEdit, setKeywordEdit] = useState(false)
  const [sLoading, setSLoading] = useState(0)
  const [tagLoading, setTagLoading] = useState(false)
  const [webpLoading, setWebpLoading] = useState(false)

  const [preview, setPreview] = useState(false)
  const [whatsApp, setWhatsApp] = useState(false)

  const handleClose = () => {
    setSelectedLang(0)
    props.setShow(false)
  }

  useEffect(() => {
    setBookmark(props.package.p_bookmark === 'Y' ? true : false)
    setPremium(props.package.artiSubPackageYn === 'Y' ? true : false)
    setIsUS(props.package.isUS === 'Y' ? true : false)
    setPackageName(props.package.p_name ? props.package.p_name : '')
    setCharName(props.package.p_charName ? props.package.p_charName : '')
    setDesc(props.package.p_description ? props.package.p_description : '')
    setCategories(
      props.package.p_category ? props.package.p_category.split(',') : []
    )
    setPackageTitles(props.package.titleList ? props.package.titleList : [])
    setBulk(props.package.p_bulk ? props.package.p_bulk.split(',') : [])
    setKeyword(props.package.p_tags ? props.package.p_tags.split(',') : [])
    setSelectedLang(
      props.selectedLang !== props.package.langSeq
        ? props.package.langSeq
        : props.selectedLang
    )
    // setTagLang(props.package.tag_lang ? props.package.tag_lang : 0)
    // setTagLangDisabled(
    //   props.package.tag_lang && props.package.commit === 1 ? true : false
    // )
    setStickers(props.package.stickers ? props.package.stickers : [])
    setPackageStatus(props.package.commit)
    setNewTitle('')
    setNewTitleLanguage(0)
    setTelegramLink(
      props.package.p_telegramLink ? props.package.p_telegramLink : ''
    )
    setOpenStickersSection(true)
    setOpenLibrariesSection(false)
    setOpenSelfServingGroupsSection(true)
    setOpenRegionSection(true)
    setOpenGuidelinesSection(true)
    setOpenContainersSection(false)
    console.log(props.package)
  }, [props.package])

  useEffect(() => {
    modalRef.current.scrollTo(0, 0)
  }, [props.package.p_id])

  useEffect(() => {
    props.updatePackageTitles(props.package.p_id, packageTitles)
    setNewTitle('')
    setNewTitleLanguage(0)
  }, [packageTitles])

  useEffect(() => {
    props.updatePackageBookmark(props.package.p_id, bookmark)
  }, [bookmark])

  useEffect(() => {
    props.updatePackagePremium(props.package.p_id, premium)
  }, [premium])

  useEffect(() => {
    props.updateIsUS(props.package.p_id, isUS)
  }, [isUS])

  useEffect(() => {
    if (!bulkEdit) {
      setInputBulk('')
    }
  }, [bulkEdit])

  useEffect(() => {
    if (!keywordEdit) {
      setInputKeyword('')
    }
  }, [keywordEdit])

  const renderLanguagesDropdownMenu = () => {
    const languages = props.languages ? props.languages : []
    return languages.map(language => (
      <option value={JSON.stringify(language)} key={language.seq}>
        {language.langName}
      </option>
    ))
  }

  const renderLanguagesDropdownMenu2 = () => {
    const languages = props.languages ? props.languages : []
    return languages.map(language => (
      <option value={language.seq} key={language.seq}>
        {language.langName}
      </option>
    ))
  }

  // const renderLanguagesDropdownMenu3 = () => {
  //   const languages = props.languages ? props.languages : []
  //   return languages.map(language => {
  //     if (tagLangList.indexOf(language.seq) >= 0) {
  //       return (
  //         <option value={language.seq} key={language.seq}>
  //           {language.langName}
  //         </option>
  //       )
  //     }
  //   })
  // }

  const renderCategoryList = () => {
    const categoryList = props.categories
      ? props.categories
          .filter(c => c.CATEGORY !== 'Etc')
          .concat(props.categories.filter(c => c.CATEGORY === 'Etc'))
      : []
    return (
      <CategoryList ref={categoryRef}>
        {categoryList.map((category, i) => (
          <CategoryBtn
            ref={categoryBtnRef}
            key={i}
            selected={categories.indexOf(category.CATEGORY) >= 0}
            onClick={() => {
              if (categories.indexOf(category.CATEGORY) >= 0) {
                setCategories(categories.filter(c => c !== category.CATEGORY))
              } else {
                setCategories(categories.concat(category.CATEGORY))
              }
            }}
          >
            {category.CATEGORY}
          </CategoryBtn>
        ))}
      </CategoryList>
    )
  }

  const renderPackageTitles = () => {
    return packageTitles.map(title => (
      <TranslationPackages key={title.seq}>
        <span>
          [{title.language_name}] {title.title}
        </span>
        <button
          style={{
            border: 'none',
            fontFamily: 'RawlineMedium',
            fontSize: '12px',
            color: '#ff3b94',
            backgroundColor: '#fff',
          }}
          onClick={() => removeTitle(title.seq)}
        >
          Delete
        </button>
      </TranslationPackages>
    ))
  }

  const downloadStickerPackage = async () => {
    fetch('/new/webAdmin/v2/download/' + props.package.p_id, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'get',
    })
      .then(async response => {
        fileDownload(
          await (await new Response(response.body)).blob(),
          props.package.p_name + '.zip'
        )
      })

      .catch(error => {
        console.error('123=' + error)
      })
  }

  const downloadWebp = async size => {
    fetch(`/new/webAdmin/v2/download/webp/${size}/${props.package.p_id}`, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'get',
    })
      .then(async response => {
        fileDownload(
          await (await new Response(response.body)).blob(),
          props.package.p_name + '_' + size + '.zip'
        )
      })

      .catch(error => {
        console.error('123=' + error)
      })
  }

  const addTitle = () => {
    if (!newTitle || newTitle.length < 1) {
      alert('스티커팩 이름은 1글자 이상이어야 합니다')
      return
    }

    const params = {
      p_id: props.package.p_id,
      title: newTitle,
      lang: JSON.parse(newTitleLanguage).seq,
    }

    fetch('/new/webAdmin/v2/contents/title', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'fail' && responseJson.code === '1111')
          props.history.push('/')
        setPackageTitles(
          packageTitles.concat({
            seq: responseJson.seq,
            title: newTitle,
            language: JSON.parse(newTitleLanguage).seq,
            language_name: JSON.parse(newTitleLanguage).langName,
          })
        )
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }
  const removeTitle = seq => {
    const params = {
      seq: seq,
    }

    fetch('/new/webAdmin/v2/contents/title', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'DELETE',
      body: JSON.stringify(params),
    })
      .then(() => {
        setPackageTitles(packageTitles.filter(info => info.seq !== seq))
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const editName = () => {
    if (!packageName || packageName.length < 1) {
      alert('스티커팩 이름은 1글자 이상이어야 합니다')
      return
    }

    if (props.package.p_name !== packageName) {
      const params = {
        pid: props.package.p_id,
        pname: packageName,
      }
      fetch('/new/webAdmin/v2/contents/name', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token'),
        },
        method: 'PUT',
        body: JSON.stringify(params),
      })
        .then(() => {
          alert(`패키지명이 변경되었습니다.`)
          props.updatePackageName(props.package.p_id, packageName)
          setPackNameEdit(false)
        })
        .catch(error => {
          console.error('123=' + error)
        })
    } else {
      setPackNameEdit(false)
    }
  }

  const editCharName = () => {
    if (!charName || charName.length < 1) {
      alert('캐릭터 또는 시리즈 이름은 1글자 이상이어야 합니다')
      return
    }

    if (props.package.p_charName !== charName) {
      const params = {
        pid: props.package.p_id,
        charName: charName,
      }
      fetch('/new/webAdmin/v2/contents/charName', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token'),
        },
        method: 'PUT',
        body: JSON.stringify(params),
      })
        .then(() => {
          alert(`캐릭터명이 변경되었습니다.`)
          props.updateCharacterName(props.package.p_id, charName)
          setCharNameEdit(false)
        })
        .catch(error => {
          console.error('123=' + error)
        })
    } else {
      setCharNameEdit(false)
    }
  }

  const editDescription = () => {
    if (!desc || desc.length < 1) {
      alert('패키지 설명은 1글자 이상이어야 합니다.')
      return
    }

    if (props.package.p_description !== desc) {
      const params = {
        pid: props.package.p_id,
        desc: desc,
      }
      fetch('/new/webAdmin/v2/contents/description', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token'),
        },
        method: 'PUT',
        body: JSON.stringify(params),
      })
        .then(() => {
          alert(`패키지 설명이 변경되었습니다.`)

          props.updateDescription(props.package.p_id, desc)
          setDescEdit(false)
        })
        .catch(error => {
          console.error('123=' + error)
        })
    } else {
      setDescEdit(false)
    }
  }

  const editCategory = () => {
    if (categories.length <= 0 || categories.length > 3) {
      alert('카테고리는 1개 이상 3개 이하여야 합니다.')
      return
    }

    if (props.package.p_category !== categories.join()) {
      const params = {
        pid: props.package.p_id,
        category: categories.join(),
      }
      fetch('/new/webAdmin/v2/contents/category', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token'),
        },
        method: 'PUT',
        body: JSON.stringify(params),
      })
        .then(() => {
          alert(`카테고리가 변경되었습니다.`)
          props.updateCategory(props.package.p_id, categories.join())
          setCategoryEdit(false)
        })
        .catch(error => {
          console.error('123=' + error)
        })
    } else {
      setCategoryEdit(false)
    }
  }

  const editLang = () => {
    if (selectedLang === 0) {
      alert('not change language')
      return
    } else {
      const lang =
        props.selectedLang !== props.package.langSeq
          ? props.package.langSeq
          : props.selectedLang

      if (lang !== selectedLang) {
        const params = {
          p_id: props.package.p_id,
          langSeq: selectedLang,
        }

        fetch('/new/webAdmin/v2/package/lang', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            token: sessionStorage.getItem('token'),
          },
          method: 'PUT',
          body: JSON.stringify(params),
        })
          .then(() => {
            alert(`패키지 언어가 변경되었습니다.`)
            props.updateLang(props.package.p_id, selectedLang)
            setLangEdit(false)
          })
          .catch(error => {
            console.error('123=' + error)
          })
      } else {
        setLangEdit(false)
      }
    }
  }

  // const editTagLang = tagLang => {
  //   const params = {
  //     pid: props.package.p_id,
  //     lang: tagLang,
  //   }

  //   axios
  //     .put(`/new/webAdmin/v2/contents/tagLang`, params, {
  //       headers: {
  //         token: sessionStorage.getItem("token"),
  //       },
  //     })
  //     .then(() => {
  //       props.updateTagLang(props.package.p_id, tagLang)
  //       setTagLang(tagLang)
  //       // setTagLangDisabled(true)
  //     })
  //     .catch(error => {
  //       console.error('123=' + error)
  //     })
  // }

  const premiumUpdate = async value => {
    const params = {
      premium: value ? 'Y' : 'N',
    }

    fetch(`/new/webAdmin/v2/package/premium/${props.package.p_id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(() => {
        props.package.artiSubPackageYn = value ? 'Y' : 'N'
        setPremium(value)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const bookmarkUpdate = async value => {
    const params = {
      bookmark: value ? 'Y' : 'N',
    }

    fetch(`/new/webAdmin/v2/package/bookmark/${props.package.p_id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(() => {
        props.package.p_bookmark = value ? 'Y' : 'N'
        setBookmark(value)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const isUSUpdate = async value => {
    const params = {
      isUS: value ? 'Y' : 'N',
    }

    fetch(`/new/webAdmin/v2/package/us/${props.package.p_id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(() => {
        props.package.isUS = value ? 'Y' : 'N'
        setIsUS(value)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const addBulk = async tag => {
    if (bulk.indexOf(tag) < 0) {
      await setBulk(bulk.concat(tag))
      await setStickers(
        stickers.map(sticker =>
          sticker.tags
            ? sticker.tags.split(',').indexOf(tag) < 0
              ? {
                  ...sticker,
                  tags: sticker.tags.split(',').concat(tag).join(),
                }
              : sticker
            : { ...sticker, tags: new Array().concat(tag).join() }
        )
      )
    } else {
      alert('This tag already exists.')
      return
    }
  }

  const deleteBulk = async tag => {
    await setBulk(bulk.filter(t => t !== tag))
    await setStickers(
      stickers.map(sticker =>
        sticker.tags
          ? {
              ...sticker,
              tags: sticker.tags
                .split(',')
                .filter(t => t !== tag)
                .join(),
            }
          : sticker
      )
    )
  }

  const addKeyword = async k => {
    if (keyword.indexOf(k) < 0) {
      await setKeyword(keyword.concat(k))
    } else {
      alert('This keyword already exists.')
      return
    }
  }

  const deleteKeyword = async tag => {
    await setKeyword(keyword.filter(t => t !== tag))
    await document.addEventListener('click', clickOutSide, false)
  }

  const editKeyword = async () => {
    const params = {
      packageId: props.package.p_id,
      tag: keyword.join(),
    }

    axios
      .put(`/new/webAdmin/v2/ptag`, params, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(() => {
        props.updateKeyword(props.package.p_id, keyword.join())
        setKeywordEdit(false)
        alert('Keyword is changed.')
      })
  }

  const updateStickerImage = async (pid, sid, img) => {
    await props.updateStickerImage(pid, sid, img)
    await setStickers(
      stickers.map(s => {
        if (s.s_id === sid) {
          s = { ...s, s_img: img }
        }
        return s
      })
    )
  }

  const updateStickerWebp = async (pid, webp) => {
    await props.updateStickerWebp(pid, webp)
  }

  const updatePackageMain = async (pid, img) => {
    await props.updatePackageMain(pid, img)
  }

  const updatePackageTray = async (pid, img) => {
    await props.updatePackageTray(pid, img)
  }

  const [savedStickers, setSavedStickers] = useState(props.package.stickers)
  const [addedTags, setAddedTags] = useState([])
  const [deletedTags, setDeletedTags] = useState([])

  const editBulk = async () => {
    setTagLoading(true)
    const deleted = []
    const added = []
    await stickers.map(sticker => {
      const savedTags =
        savedStickers &&
        savedStickers.filter(s => s.s_id === sticker.s_id)[0].tags
          ? savedStickers
              .filter(s => s.s_id === sticker.s_id)[0]
              .tags.split(',')
          : []

      deleted.push({
        s_id: sticker.s_id,
        tags: savedTags
          ? savedTags.filter(t =>
              sticker.tags ? !sticker.tags.split(',').includes(t) : t
            )
          : [],
      })

      added.push({
        s_id: sticker.s_id,
        tags: sticker.tags
          ? sticker.tags.split(',').filter(t => !savedTags.includes(t))
          : [],
      })
    })

    await setDeletedTags(deleted)
    await setAddedTags(added)

    const params = {
      pid: props.package.p_id,
      bulk: bulk.join(),
    }

    await axios
      .put(`/new/webAdmin/v2/contents/bulk`, params, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(async () => {
        const result = await Promise.all(
          stickers.map(sticker => tagPromise(sticker.s_id, sticker.tags))
        )
        if (result) {
          props.updateBulk(props.package.p_id, stickers, bulk.join())
          setSavedStickers(stickers)
          setBulkEdit(false)
          setTagLoading(false)
          alert('Bulk tag is changed.')
        }
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  // useEffect(() => {
  //   if (addedTags.length > 0 && packageStatus === 1) {
  //     addedTags.map(sticker => {
  //       axios.post(
  //         `/new/webAdmin/v2/keyword/sticker/tag/${tagLang}/${sticker.s_id}`,
  //         { insertKeywordList: sticker.tags },
  //         {
  //           headers: {
  //             token: sessionStorage.getItem("token"),
  //           },
  //         }
  //       )
  //     })
  //   }
  // }, [addedTags])
  useEffect(() => {
    if (addedTags.length > 0 && packageStatus === 1) {
      addedTags.map(sticker => {
        axios.post(
          `/new/webAdmin/v2/keyword/sticker/tag/${sticker.s_id}`,
          { insertKeywordList: sticker.tags },
          {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          }
        )
      })
    }
  }, [addedTags])

  // useEffect(() => {
  //   if (deletedTags.length > 0 && packageStatus === 1) {
  //     deletedTags.map(sticker => {
  //       axios.delete(
  //         `/new/webAdmin/v2/keyword/sticker/tag/${tagLang}/${sticker.s_id}`,
  //         {
  //           data: {
  //             deleteKeywordList: sticker.tags,
  //           },
  //           headers: {
  //             token: sessionStorage.getItem("token"),
  //           },
  //         }
  //       )
  //     })
  //   }
  // }, [deletedTags])
  useEffect(() => {
    if (deletedTags.length > 0 && packageStatus === 1) {
      deletedTags.map(sticker => {
        axios.delete(`/new/webAdmin/v2/keyword/sticker/tag/${sticker.s_id}`, {
          data: {
            deleteKeywordList: sticker.tags,
          },
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
      })
    }
  }, [deletedTags])

  const tagPromise = (id, tags) => {
    const params = {
      sid: id,
      tags: tags,
      pid: props.package.p_id,
    }

    return new Promise(async (resolve, reject) => {
      axios
        .put(`/new/webAdmin/v2/contents/tags`, params, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const updateTag = async (id, tags) => {
    await tagPromise(id, tags).then(async res => {
      if (res.status === 200) {
        await setStickers(
          stickers.map(sticker =>
            sticker.s_id === id ? { ...sticker, tags: tags } : sticker
          )
        )
        await props.updateStickers(props.package.p_id, id, tags)
      }
    })
  }

  const clickOutSide = e => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      props.setShow(!props.show)
    }
  }

  const clickEsc = e => {
    if (modalRef.current && e.key === 'Escape') {
      props.setShow(!props.show)
    }
  }

  useEffect(() => {
    document.addEventListener('click', clickOutSide, false)
    document.addEventListener('keydown', clickEsc, false)
    return () => {
      document.removeEventListener('click', clickOutSide, false)
      document.removeEventListener('keydown', clickEsc, false)
    }
  })

  const insertTagPromise = sticker => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `/new/webAdmin/v2/keyword/sticker/tag/${sticker.s_id}`,
          { insertKeywordList: sticker.tags.split(',') },
          {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          }
        )
        .then(res => {
          resolve(res)
        })
    })
  }

  const insertStickerTag = async () => {
    await Promise.all(
      stickers.map(sticker => {
        if (sticker.tags) {
          insertTagPromise(sticker)
        }
      })
    )
  }

  useImperativeHandle(ref, () => ({
    insertStickerTag,
  }))

  const insertStickerOneTag = (sid, tag) => {
    axios.post(
      `/new/webAdmin/v2/keyword/sticker/tag/one/${sid}`,
      { tag },
      {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      }
    )
  }

  const deleteStickerOneTag = async (sid, tag) => {
    await axios
      .delete(`/new/webAdmin/v2/keyword/sticker/tag/one/${sid}`, {
        data: { tag },
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(res => {
        console.log(res)
      })
  }

  const convertURLtoFile = async url => {
    const response = await fetch(url)
    const data = await response.blob()
    const ext = url.split('.').pop()
    const filename = url.split('/').pop()
    const metadata = { type: `image/${ext}` }
    const file = new File([data], filename, metadata)

    return file
  }

  const selectWebp = async pid => {
    // await axios
    //   .get(`/new/webAdmin/v2/webp/${pid}`, {
    //     headers: {
    //       token: sessionStorage.getItem("token"),
    //     },
    //   })
    //   .then(async ({ data }) => {
    //     if (data.result.length > 0) {
    //       const newWebp = await Promise.all(
    //         data.result.map(async item => {
    //           return { ...item, file: await convertURLtoFile(item.webp) }
    //         })
    //       )

    //       setWebpImg(newWebp)
    //     }
    //   })
    const newWebp = await Promise.all(
      props.package.stickers.map(async item => {
        return {
          ...item,
          file: item.webp ? await convertURLtoFile(item.webp) : null,
        }
      })
    )
    setWebpImg(newWebp)
    // setWebpImg(props.package.stickers)
    setPreview(false)
    setWhatsApp(!whatsApp)
  }

  const convertWebp = async () => {
    await setWebpLoading(true)
    await axios
      .post(
        `/new/webAdmin/v2/webp`,
        { p_id: props.package.p_id },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(async () => {
        await axios
          .get(`/new/webAdmin/v2/webp/${props.package.p_id}`, {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          })
          .then(async ({ data }) => {
            if (data.result) {
              await updateStickerWebp(props.package.p_id, data.result)

              const newWebp = await Promise.all(
                data.result.map(async item => {
                  return {
                    ...item,
                    file: item.webp ? await convertURLtoFile(item.webp) : null,
                  }
                })
              )
              await setWebpImg(newWebp)
            }
          })
        await setWebpLoading(false)
      })
  }

  return (
    <ModalContainer
      ref={modalRef}
      onClick={e => {
        if (e.target.textContent !== 'Save') {
          if (packNameEdit) {
            if (packRef.current && !packRef.current.contains(e.target)) {
              setPackNameEdit(false)
              setPackageName(props.package.p_name)
            }
          }

          if (langEdit) {
            if (langRef.current && !langRef.current.contains(e.target)) {
              setLangEdit(false)
              setSelectedLang(
                props.selectedLang !== props.package.langSeq
                  ? props.package.langSeq
                  : props.selectedLang
              )
            }
          }

          if (charNameEdit) {
            if (charRef.current && !charRef.current.contains(e.target)) {
              setCharNameEdit(false)
              setCharName(props.package.p_charName)
            }
          }

          if (descEdit) {
            if (descRef.current && !descRef.current.contains(e.target)) {
              setDescEdit(false)
              setDesc(props.package.p_description)
            }
          }

          if (categoryEdit) {
            if (
              categoryRef.current &&
              categoryBtnRef.current &&
              !categoryRef.current.contains(e.target) &&
              !categoryBtnRef.current.contains(e.target)
            ) {
              setCategoryEdit(false)
              setCategories(
                props.package.p_category
                  ? props.package.p_category.split(',')
                  : []
              )
            }
          }

          if (bulkEdit) {
            if (bulkBoxRef.current && !bulkBoxRef.current.contains(e.target)) {
              if (e.target.textContent !== 'Add') {
                setBulkEdit(false)
                setBulk(
                  props.package.p_bulk ? props.package.p_bulk.split(',') : []
                )
                setStickers(
                  props.package.stickers ? props.package.stickers : []
                )
              }
            }
          }

          if (keywordEdit) {
            if (
              keywordBoxRef.current &&
              !keywordBoxRef.current.contains(e.target)
            ) {
              if (e.target.textContent !== 'Add') {
                setKeywordEdit(false)
                setKeyword(
                  props.package.p_tags ? props.package.p_tags.split(',') : []
                )
              }
            }
          }
        }
      }}
    >
      {(tagLoading || webpLoading) && (
        <div
          style={{
            width: '1210px',
            height: 'calc(95vh - 70px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            zIndex: 99,
          }}
        >
          <LoadingSpinner />
        </div>
      )}
      <Left onClick={async () => await props.previousPackage()}>
        <FaChevronLeft size={50} />
      </Left>
      <Right onClick={async () => await props.nextPackage()}>
        <FaChevronRight size={50} />
      </Right>
      <ModalWrapper>
        <ModalHeader>
          <Title style={{ fontSize: '18px' }}>
            Creator: <span>{props.package.u_name}</span>
          </Title>
          <FaTimes
            size={20}
            color={'silver'}
            onClick={() => handleClose()}
            style={{ cursor: 'pointer' }}
          />
        </ModalHeader>
        <ModalBody>
          <PackWrapper>
            <PackName>
              <Title style={{ display: 'flex', gap: '10px' }}>
                <div>
                  Name - <span>PackID {props.package.p_id}</span>
                </div>
                <div>
                  <InputWrapper style={{ height: '100%' }}>
                    (<Title style={{ marginRight: '7px' }}>Premium</Title>
                    <BookmarkInput>
                      <input
                        id="premium"
                        type="checkbox"
                        checked={premium}
                        onChange={e => premiumUpdate(e.target.checked)}
                      />
                      <label htmlFor="premium">
                        <FiCheck size={16} />
                      </label>
                    </BookmarkInput>
                    )
                  </InputWrapper>
                </div>
              </Title>
              <Space height={8} />
              <InputWrapper>
                <Input
                  ref={packRef}
                  type={'text'}
                  value={packageName}
                  onChange={e => setPackageName(e.target.value)}
                  disabled={!packNameEdit}
                  width={367}
                  style={{ border: packNameEdit && '1px solid #ff4a9c' }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      editName()
                    }
                  }}
                />
                {packNameEdit ? (
                  <CustomBtn
                    save={true}
                    onClick={() => {
                      editName()
                    }}
                  >
                    Save
                  </CustomBtn>
                ) : (
                  <CustomBtn
                    onClick={() => {
                      setPackNameEdit(true)
                      packRef.current.disabled = false
                      packRef.current.focus()
                    }}
                  >
                    Edit
                  </CustomBtn>
                )}
              </InputWrapper>
            </PackName>
            <LangWrapper>
              <Title>Sticker language</Title>
              <Space height={8} />
              <InputWrapper>
                <LangSelect>
                  <Lang
                    ref={langRef}
                    value={
                      selectedLang !== 0 ? selectedLang : props.package.langSeq
                    }
                    onChange={e => setSelectedLang(e.target.value)}
                    disabled={!langEdit}
                    style={{ border: langEdit && '1px solid #ff4a9c' }}
                  >
                    {renderLanguagesDropdownMenu2()}
                  </Lang>
                  <span className={'dropDown'}>
                    <FiChevronDown size={18} />
                  </span>
                </LangSelect>
                {langEdit ? (
                  <CustomBtn save={true} onClick={() => editLang()}>
                    Save
                  </CustomBtn>
                ) : (
                  <CustomBtn
                    onClick={() => {
                      setLangEdit(true)
                      langRef.current.disabled = false
                      langRef.current.focus()
                    }}
                  >
                    Edit
                  </CustomBtn>
                )}
              </InputWrapper>
            </LangWrapper>
            <div>
              <Space height={32} />
              <InputWrapper>
                <Title style={{ marginRight: '7px' }}>Bookmark</Title>
                <BookmarkInput>
                  <input
                    id="bookmark"
                    type="checkbox"
                    checked={bookmark}
                    onChange={e => bookmarkUpdate(e.target.checked)}
                  />
                  <label htmlFor="bookmark">
                    <FiCheck size={16} />
                  </label>
                </BookmarkInput>
              </InputWrapper>
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Space height={32} />
              <InputWrapper>
                <Title style={{ marginRight: '7px' }}>US</Title>
                <BookmarkInput>
                  <input
                    id="isUS"
                    type="checkbox"
                    checked={isUS}
                    onChange={e => isUSUpdate(e.target.checked)}
                  />
                  <label htmlFor="isUS">
                    <FiCheck size={16} />
                  </label>
                </BookmarkInput>
              </InputWrapper>
            </div>
          </PackWrapper>
          <Space height={25} />
          <div>
            <Title>Title translation</Title>
            <Space height={8} />
            <InputWrapper style={{ width: '100%' }}>
              <LangSelect style={{ marginRight: '5px' }}>
                <Lang
                  value={newTitleLanguage}
                  onChange={e => setNewTitleLanguage(e.target.value)}
                >
                  <option value={0}>언어를 선택하세요</option>
                  {renderLanguagesDropdownMenu()}
                </Lang>
                <span className={'dropDown'}>
                  <FiChevronDown size={18} />
                </span>
              </LangSelect>
              <Input
                width={445}
                type="text"
                value={newTitle}
                placeholder="스티커팩명(번역)"
                onChange={event => setNewTitle(event.target.value)}
                onFocus={e =>
                  (e.currentTarget.style.border = '1px solid #ff4a9c')
                }
                onBlur={e =>
                  (e.currentTarget.style.border = '1px solid #dbdbdb')
                }
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    if (newTitleLanguage) {
                      addTitle()
                    } else {
                      alert('언어를 선택하세요.')
                    }
                  }
                }}
              />
              <CustomBtn
                onClick={() => {
                  if (newTitleLanguage) {
                    addTitle()
                  } else {
                    alert('언어를 선택하세요.')
                  }
                }}
                add={true}
              >
                Add
              </CustomBtn>
              <TranslationPackageWrapper>
                {renderPackageTitles()}
              </TranslationPackageWrapper>
            </InputWrapper>
          </div>
          <Space height={25} />
          <div>
            <Title>Character or series name</Title>
            <Space height={8} />
            <InputWrapper>
              <Input
                ref={charRef}
                width={636}
                type="text"
                value={charName}
                onChange={e => setCharName(e.target.value)}
                disabled={!charNameEdit}
                style={{ border: charNameEdit && '1px solid #ff4a9c' }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    editCharName()
                  }
                }}
              />
              {charNameEdit ? (
                <CustomBtn save={true} onClick={() => editCharName()}>
                  Save
                </CustomBtn>
              ) : (
                <CustomBtn
                  onClick={() => {
                    setCharNameEdit(true)
                    charRef.current.disabled = false
                    charRef.current.focus()
                  }}
                >
                  Edit
                </CustomBtn>
              )}
            </InputWrapper>
          </div>
          <Space height={34} />
          <div>
            <InputWrapper2>
              <Title>Description</Title>
              {descEdit ? (
                <CustomBtn save={true} onClick={() => editDescription()}>
                  Save
                </CustomBtn>
              ) : (
                <CustomBtn
                  onClick={() => {
                    setDescEdit(true)
                    descRef.current.disabled = false
                    descRef.current.focus()
                  }}
                >
                  Edit
                </CustomBtn>
              )}
            </InputWrapper2>
            <Space height={8} />
            <Textarea
              ref={descRef}
              style={{
                height: '115px',
                border: descEdit && '1px solid #ff4a9c',
              }}
              value={desc}
              onChange={e => setDesc(e.target.value)}
              disabled={!descEdit}
            />
          </div>
          <Space height={34} />
          <div>
            <InputWrapper2>
              <Title>Pack Category</Title>
              {categoryEdit ? (
                <CustomBtn save={true} onClick={() => editCategory()}>
                  Save
                </CustomBtn>
              ) : (
                <CustomBtn onClick={() => setCategoryEdit(true)}>
                  Edit
                </CustomBtn>
              )}
              {categoryEdit && renderCategoryList()}
            </InputWrapper2>
            <Space height={8} />
            <CategoryWrapper>
              {categories.sort().map((category, index) => (
                <Categories key={index}>{category}</Categories>
              ))}
            </CategoryWrapper>
          </div>
          <Space height={45} />
          <StickerWrapper>
            <BulkWrapper>
              <InputWrapper2>
                <div style={{ display: 'flex' }}>
                  <Title>Sticker Tags</Title>
                  <DownloadPack onClick={() => downloadStickerPackage()}>
                    Download Pack
                  </DownloadPack>
                </div>
                {bulkEdit ? (
                  <CustomBtn
                    save={true}
                    onClick={() => {
                      if (props.package.p_bulk !== bulk.join()) {
                        editBulk()
                      } else {
                        setBulkEdit(false)
                      }
                    }}
                  >
                    Save
                  </CustomBtn>
                ) : (
                  <CustomBtn
                    onClick={() => {
                      setBulkEdit(true)
                      bulkRef.current.disabled = false
                      bulkRef.current.focus()
                    }}
                  >
                    Edit
                  </CustomBtn>
                )}
              </InputWrapper2>
              <Space height={8} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <BulkInputWrapper focus={bulkEdit} ref={bulkBoxRef}>
                  <BulkInputBox>
                    {bulk &&
                      bulk.map((tag, index) => (
                        <BulkTag
                          focus={bulkEdit}
                          key={index}
                          onClick={e => {
                            if (bulkEdit) {
                              console.log(modalRef.current.contains(e.target))
                              deleteBulk(tag)
                            }
                          }}
                        >
                          <span>{tag}</span>
                          <IoMdClose size={10} />
                        </BulkTag>
                      ))}
                    <BulkInput
                      ref={bulkRef}
                      type={'text'}
                      disabled={!bulkEdit}
                      value={inputBulk}
                      onChange={e => setInputBulk(e.target.value)}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          if (inputBulk.length > 0) {
                            addBulk(inputBulk)
                          }
                          setInputBulk('')
                        }
                      }}
                    />
                  </BulkInputBox>
                  <AddTagBtn
                    focus={bulkEdit}
                    onClick={() => {
                      if (bulkEdit && inputBulk.length > 0) {
                        addBulk(inputBulk)
                      }
                      setInputBulk('')
                      bulkRef.current.focus()
                    }}
                  >
                    Add
                  </AddTagBtn>
                </BulkInputWrapper>
                {/* <LangWrapper
                  style={{
                    position: 'absolute',
                    left: '880px',
                  }}
                >
                  <Title>Tag Language</Title>
                  <Space height={8} />
                  <InputWrapper style={{ width: '100%' }}>
                    <LangSelect>
                      <Lang
                        value={tagLang}
                        onChange={async e => {
                          if (tagLangEdit) {
                            await setTagLang(e.target.value)
                          } else {
                            await editTagLang(e.target.value)
                          }
                        }}
                        disabled={tagLangDisabled}
                      >
                        <option value={0}>언어를 선택하세요</option>
                        {renderLanguagesDropdownMenu3()}
                      </Lang>
                      <span className={'dropDown'}>
                        <FiChevronDown size={18} />
                      </span>
                    </LangSelect>
                    {props.package.commit === 1 ? (
                      <CustomBtn
                        style={{
                          backgroundColor: tagLangDisabled ? '#fff' : '#ff4a9c',
                          color: tagLangDisabled ? '#ff4a9c' : '#fff',
                        }}
                        onClick={async () => {
                          if (!tagLangDisabled) {
                            await clickTagLangSave()
                          } else {
                            await setTagLangDisabled(false)
                            await setTagLangEdit(true)
                          }
                        }}
                      >
                        {tagLangDisabled ? 'Edit' : 'Save'}
                      </CustomBtn>
                    ) : (
                      <div></div>
                    )}
                  </InputWrapper>
                </LangWrapper> */}
              </div>
            </BulkWrapper>
            <Space height={45} />
            <BulkWrapper>
              <InputWrapper2>
                <Title>Package Keyword</Title>
                {keywordEdit ? (
                  <CustomBtn
                    save={true}
                    onClick={() => {
                      if (props.package.p_tags !== keyword.join()) {
                        editKeyword()
                      } else {
                        setKeywordEdit(false)
                      }
                    }}
                  >
                    Save
                  </CustomBtn>
                ) : (
                  <CustomBtn
                    onClick={() => {
                      setKeywordEdit(true)
                      keywordRef.current.disabled = false
                      keywordRef.current.focus()
                    }}
                  >
                    Edit
                  </CustomBtn>
                )}
              </InputWrapper2>
              <Space height={8} />
              <BulkInputWrapper ref={keywordBoxRef} focus={keywordEdit}>
                <BulkInputBox>
                  {keyword &&
                    keyword.map((tag, index) => (
                      <BulkTag
                        focus={keywordEdit}
                        key={index}
                        onClick={e => {
                          document.removeEventListener(
                            'click',
                            clickOutSide,
                            false
                          )
                          if (keywordEdit) {
                            deleteKeyword(tag)
                          }
                        }}
                      >
                        <span>{tag}</span>
                        <IoMdClose size={10} />
                      </BulkTag>
                    ))}
                  <BulkInput
                    ref={keywordRef}
                    type={'text'}
                    disabled={!keywordEdit}
                    value={inputKeyword}
                    onChange={e => setInputKeyword(e.target.value)}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        if (inputKeyword.length > 0) {
                          addKeyword(inputKeyword)
                        }
                        setInputKeyword('')
                      }
                    }}
                  />
                </BulkInputBox>
                <AddTagBtn
                  focus={keywordEdit}
                  onClick={() => {
                    if (keywordEdit && inputKeyword.length > 0) {
                      addKeyword(inputKeyword)
                    }
                    setInputKeyword('')
                    keywordRef.current.focus()
                  }}
                >
                  Add
                </AddTagBtn>
              </BulkInputWrapper>
            </BulkWrapper>
            <div
              style={{
                width: '100%',
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <CustomBtn
                style={{
                  width: '103px',
                  backgroundColor: whatsApp ? '#ff4a9c' : '#fff',
                  color: whatsApp ? '#fff' : '#ff4a9c',
                  marginRight: '10px',
                }}
                onClick={() => {
                  selectWebp(props.package.p_id)
                }}
              >
                WhatsApp
              </CustomBtn>
              <CustomBtn
                style={{
                  width: '103px',
                  backgroundColor: preview ? '#ff4a9c' : '#fff',
                  color: preview ? '#fff' : '#ff4a9c',
                }}
                onClick={() => {
                  setWhatsApp(false)
                  setPreview(!preview)
                }}
              >
                Preview
              </CustomBtn>
            </div>
            <a
              href={`https://stipop.com/en/sticker-pack/${btoa(
                props.package.p_id
              )}`}
              target="_blank"
            >
              {`https://stipop.com/en/sticker-pack/${btoa(props.package.p_id)}`}
            </a>
            <StickersSection
              package={props.package}
              stickers={stickers}
              open={openStickersSection}
              setOpen={setOpenStickersSection}
              updateTag={updateTag}
              whatsApp={whatsApp}
              preview={preview}
              webpImg={webpImg}
              setWebpImg={setWebpImg}
              convertURLtoFile={convertURLtoFile}
              convertWebp={convertWebp}
              downloadWebp={downloadWebp}
              packageName={packageName}
              updateStickers={props.updateStickers2}
              updateStickerImage={updateStickerImage}
              updatePackageMain={updatePackageMain}
              updatePackageTray={updatePackageTray}
              clickOutSide={clickOutSide}
              insertStickerOneTag={insertStickerOneTag}
              deleteStickerOneTag={deleteStickerOneTag}
              bulkEdit={bulkEdit}
              sLoading={sLoading}
              setSLoading={setSLoading}
            />
          </StickerWrapper>
        </ModalBody>
        <Space height={48} />
      </ModalWrapper>
      <Space height={20} />

      <ModalBody2
        style={{
          backgroundColor: '#eeeeee',
          paddingBottom: '10px',
          paddingTop: '10px',
          borderTop: '2px solid silver',
        }}
      >
        <Row>
          <Col md={3} style={{ fontWeight: 'bold', borderRight: '2px solid' }}>
            승인 여부
          </Col>
          <Col md={6}>
            <form>
              <label style={{ marginRight: '25px' }}>
                <input
                  type="radio"
                  checked={packageStatus === 1}
                  onChange={() => setPackageStatus(1)}
                ></input>
                승인(Approved)
              </label>
              <label style={{ marginRight: '25px' }}>
                <input
                  type="radio"
                  checked={packageStatus === 2}
                  onChange={() => setPackageStatus(2)}
                ></input>
                수정 요청(Re-Submit)
              </label>
              <label style={{ marginRight: '25px' }}>
                <input
                  type="radio"
                  checked={packageStatus === 3}
                  onChange={() => setPackageStatus(3)}
                ></input>
                거절(Reject)
              </label>
            </form>
          </Col>
          <Col md={3}>
            <button
              onClick={async () => {
                await props.updatePackageStatus(
                  props.package.p_id,
                  packageStatus
                )
                // if (packageStatus === 1 && props.package.commit === 1) {
                //   await insertStickerTag()
                // }
                // if (packageStatus === 1) {
                //   if (Number(tagLang)) {
                //     if (Number(tagLang) !== Number(selectedLang)) {
                //       await alert('Please check pack and tag languages.')
                //     }
                //     await insertStickerTag()
                //     await props.updatePackageStatus(
                //       props.package.p_id,
                //       packageStatus,
                //       tagLang
                //     )
                //   } else {
                //     alert('Tag language is required.')
                //   }
                // } else {
                //   await props.updatePackageStatus(
                //     props.package.p_id,
                //     packageStatus
                //   )
                // }
              }}
            >
              저장
            </button>
          </Col>
        </Row>
        {packageStatus === 2 && (
          <GuidelinesSection
            package={props.package}
            guidelines={props.guidelines}
            updatePackageStatus={props.updatePackageStatus}
            guidelinesOfThePackage={props.guidelinesOfThePackage}
            getGuidelinesOfThePackage={props.getGuidelinesOfThePackage}
            open={openGuidelinesSection}
            setOpen={setOpenGuidelinesSection}
          />
        )}
      </ModalBody2>

      <AdSection
        package={props.package}
        updateIsAd={props.updateIsAd}
        updateIsShow={props.updateIsShow}
        updateIsFree={props.updateIsFree}
      />

      <LibrariesSection
        package={props.package}
        libraries={props.libraries}
        librariesOfThePackage={props.librariesOfThePackage}
        open={openLibrariesSection}
        setOpen={setOpenLibrariesSection}
      />

      <SelfServingGroupsSection
        package={props.package}
        appCategory={props.appCategory}
        selectedCategory={props.selectedCategory}
        open={openSelfServingGroupsSection}
        setOpen={setOpenSelfServingGroupsSection}
      />

      <RegionSection
        package={props.package}
        region={props.region}
        selectedRegion={props.selectedRegion}
        open={openRegionSection}
        setOpen={setOpenRegionSection}
      />
      <ContainersSection
        package={props.package}
        containersOfThePackage={props.package.containerList}
        open={openContainersSection}
        setOpen={setOpenContainersSection}
      />
      <Space height={48} />
      <ModalFooter>
        <Button
          variant="danger"
          onClick={() => props.deletePackage(props.package.p_id)}
          style={{ marginRight: '10px' }}
        >
          Delete
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
      <Space height={60} />
    </ModalContainer>
  )
})

export default React.memo(ContentsInfoModal)

const Space = styled.div`
  width: 100%;
  height: ${props => `${props.height}px`};
`

const ModalContainer = styled.div`
  padding: 70px 90px 0 95px;
  width: 1395px;
  height: 95%;
  background-color: #fff;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccfd2;
  }
`

const ModalWrapper = styled.div`
  width: 1210px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  padding: 0 60px;
`

const ModalHeader = styled.div`
  width: 100%;
  height: 84px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
`

const ModalBody = styled.div`
  width: 100%;
`

const ModalBody2 = styled.div`
  width: 100%;
  padding: 0 20px;
`

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Left = styled.div`
  position: fixed;
  top: 50%;
  left: calc(50% - 675px);
  cursor: pointer;

  &:hover {
    color: #ff4a9c;
  }
`

const Right = styled.div`
  position: fixed;
  top: 50%;
  right: calc(50% - 675px);
  cursor: pointer;

  &:hover {
    color: #ff4a9c;
  }
`

const PackWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const Title = styled.div`
  height: 24px;
  font-family: RawlineSemiBold;
  font-size: 16px;
  color: #000;
  font-weight: bold;

  span {
    font-family: RawlineExtraBold;
    font-weight: bolder;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 46px;
`

const InputWrapper2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 701.7px;
  height: 24px;
  position: relative;
`

const PackName = styled.div`
  margin-right: 17px;
`

const Input = styled.input`
  height: 46px;
  width: ${props => `${props.width}px`};
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 16px;
  margin-right: 7px;
  font-size: 16px;
  font-family: AppleSDGothicNeo;

  &:focus {
    outline: none;
  }
`

const LangWrapper = styled.div`
  margin-right: 27px;
`

const LangSelect = styled.div`
  height: 46px;
  width: 186px;
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  background-color: #fff;
  margin-right: 7px;

  & > span {
    position: absolute;
    left: 150px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`

const Lang = styled.select`
  width: inherit;
  height: inherit;
  background-color: transparent;
  padding-left: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  font-size: 16px;
  font-family: RawlineMedium;
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
  border-radius: 6px;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    border: 1px solid #ff4a9c;
    outline: none;
  }
`

const BookmarkInput = styled.div`
  width: 20px;
  height: 20px;
  margin-bottom: 5px;

  & > input {
    display: none;
  }

  & > input + label {
    width: 100%;
    height: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    background-color: #fff;
    color: #dbdbdb;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & > input:checked + label {
    background-color: #ff4a9c;
    color: #fff;
    border: 1px solid #ff4a9c;
  }
`

const TranslationPackageWrapper = styled.div`
  position: absolute;
  top: 170px;
  right: 40px;
  width: 300px;
  height: 328px;
  transform: translateY(-50%);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccfd2;
  }
`

const TranslationPackages = styled.div`
  width: 290px;
  height: 32px;
  font-size: 12px;
  font-family: RawlineMedium;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Textarea = styled.textarea`
  width: 701.7px;
  height: 115px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  background-color: #fff;
  padding: 12px 16px;
  font-size: 16px;
  font-family: AppleSDGothicNeo;

  &:focus {
    outline: none;
  }
`

const CustomBtn = styled.button`
  width: 59px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #ff4a9c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: RawlineSemiBold;
  color: ${props => (props.save ? '#fff' : '#ff4a9c')};
  background-color: ${props => (props.save ? '#ff4a9c' : '#fff')};
  padding-top: 5px;

  &:hover {
    background-color: ${props => props.add && '#ff4a9c'};
    color: ${props => props.add && '#fff'};
  }

  &:focus {
    outline: none;
  }
`

const CategoryList = styled.div`
  width: 701.7px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #ff4a9c;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px 15px 6px 15px;
  position: absolute;
  top: calc(100% + 54px);
  z-index: 1;
`

const CategoryBtn = styled.button`
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  font-size: 14px;
  font-family: RawlineSemiBold;
  margin-right: 14px;
  background-color: ${props => (props.selected ? '#ff4a9c' : '#fff')};
  color: ${props => (props.selected ? '#fff' : '#000')};
  margin-bottom: 14px;
  padding-top: 5px;

  &:hover {
    border: ${props => !props.selected && '1px solid #ff4a9c'};
    color: ${props => !props.selected && '#ff4a9c'};
  }

  &:focus {
    outline: none;
  }
`

const CategoryWrapper = styled.div`
  width: 701.7px;
  height: 50px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccfd2;
  }
`

const Categories = styled.div`
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  font-size: 14px;
  font-family: RawlineSemiBold;
  margin-right: 14px;
  background-color: #fff;
  padding-top: 5px;
`

const StickerWrapper = styled.div`
  width: 100%;
`

const BulkWrapper = styled.div`
  width: 100%;
`
const DownloadPack = styled.div`
  font-family: Rawline;
  font-size: 16px;
  font-style: italic;
  color: #6989ff;
  margin-left: 20px;
  cursor: pointer;
`

const BulkInputWrapper = styled.div`
  width: 701.7px;
  height: 46px;
  position: relative;
  border: ${props => (props.focus ? '1px solid #ff4a9c' : '1px solid #dbdbdb')};
  border-radius: 6px;
`

const BulkInputBox = styled.div`
  width: calc(100% - 120px);
  height: 100%;
  display: flex;
  padding-left: 12px;
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccfd2;
    border-radius: 6px;

    &:hover {
      background-color: #ff4298;
    }
  }
`

const BulkTag = styled.button`
  width: auto;
  height: 28px;
  font-size: 12px;
  border-radius: 8px;
  border: ${props => (props.focus ? '1px solid #ff4a9c' : '1px solid #dbdbdb')};
  color: ${props => (props.focus ? '#ff4a9c' : '#dbdbdb')};
  padding: 0 9px;
  font-family: RawlineSemiBold;
  margin-right: 6px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;

  span {
    margin-right: 10px;
    white-space: nowrap;
    padding-top: 3px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: ${props => (props.focus ? 'pointer' : 'initial')};
  }
`

const BulkInput = styled.input`
  width: 100%;
  min-width: 100px;
  height: 100%;
  font-size: 16px;
  border: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #fff;
  }
`

const AddTagBtn = styled.button`
  width: 46px;
  height: 24px;
  border: ${props => (props.focus ? '1px solid #ff4a9c' : '1px solid #dbdbdb')};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 12px;
  color: ${props => (props.focus ? '#ff4a9c' : '#dbdbdb')};
  font-family: RawlineSemiBold;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  padding-top: 5px;

  &:hover {
    background-color: ${props => props.focus && '#ff4a9c'};
    color: ${props => props.focus && '#fff'};
  }
`
