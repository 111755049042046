import React from 'react';
import styled from 'styled-components';
import PostingView from '../../_Components/Blog/PostingView';
import BorderBox from '../../_Components/Common/BoxUI/BorderBox';
import PageTitle from '../../_Components/Common/PageTitle/PageTitle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const WritePostPage = () => {
  return(
    <Wrapper>
      <PageTitle 
        mainTitle={'Write Blog Post'}
        subTitle={'move to home'}
        v2Link={''}
        navNames={["post"]}
      />
      <BorderBox 
        boxTitle='Blog Post Page'
        component={<PostingView />}
      />
    </Wrapper>
  );
};

export default WritePostPage;