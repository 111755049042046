import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Editor from './Editor';
import Preview from './Preview';
import { useHistory } from 'react-router-dom';
import { checkTitleDuplication, createPost, deletePost, getPostImages, updatePost } from './blogApi';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
`

const MainPostCheckSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  span{
    margin-left: 5px;
  }
`

const PostButton = styled.div`
  display: flex;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 2px 10px;
  background-color: lightgray;
  margin-right: 2px;
`

const PostingSection = styled.div`
  display: flex;
  padding: 0 10px 20px;
  justify-content: space-around;
`

const PostingView = ({post}) => {
  const [title, setTitle] = useState({
    title: '',
    date: '',
    category: 0
  });
  const [mainImage, setMainImage] = useState()
  const [content, setContent] = useState()
  const [files, setFiles] = useState()
  const [isMain, setIsMain] = useState(false)
  const [urlFiles, setUrlFiles] = useState()
  const [editMainImage, setEditMainImage] = useState(false)

  const getPostImageUrls = async(id) => {
    let images = await getPostImages(id);
    // console.log(images)
    setUrlFiles(images);
  }

  useEffect(()=>{
    if(post){
      setTitle({
        title: post.title,
        date: post.date,
        category: post.category
      })
      setContent(post.content.replace(/\$\/\{/g, '\$\{'))
      setIsMain(post.is_main===1?true:false)
      setMainImage(post.main_image)

      // get image list
      getPostImageUrls(post.post_id)
    }
  },[])

  const findFileList = () => {
    var imageCount = (content.match(/<I/g) || []).length;
    // 포스트에 이미지 없을 때
    if(imageCount === 0){ 
      return null;
    }
    // console.log(imageCount)
    // 포스트에는 <I> 있는데, 업로드된 file이 없을 때
    if(!files || files === undefined ){ 
      return -1;
    }
    let fileNameList = [];
    let pos = 0;
    for(let i = 0; i<imageCount; i++){
      let s_fileName = content.slice(pos).indexOf("<I name=") +pos+ 8;
      // 예전 <I name="" /> 처리 필요
      let isPrev = false;
      let prevI = content.slice(s_fileName).indexOf(">")+s_fileName;
      let e_fileName = 0; 
      if(content.charAt(prevI-1)==="/"){
        // prev I
        isPrev = true;
        e_fileName = prevI-1;
      }
      else{
        e_fileName = content.slice(pos).indexOf('</I>')+pos-1;
      }
      let fileName = content.substring(s_fileName, e_fileName);
      fileNameList.push(fileName)
      // pos += e_fileName+4; // error <- pos > content.length(overflow)
      if(isPrev) pos = e_fileName+1;
      else pos = e_fileName+4
    }
    
    let fileList = []; // aws에 업로드 할 File 타입만 넣기
    fileNameList.map((name, index) => {
      let file = null;
      // edit mode 일 때 urlFiles 에서 먼저 fileName찾기 
      // -> url에 있으면 다시 업로드하지 않아도 됨
      // -> fileList에 넣지 않음
      if(urlFiles){
        for(let i = 0; i<urlFiles.length; i++){
          if(urlFiles[i].name === name){
            file = urlFiles[i];
            break;
          }
        }
      }
      // onPost일 때는 모두 여기로
      if(file===null){
        for(let i = 0; i<files.length; i++){
          if(files[i].name === name){
            file = files[i];
            fileList.push(file)
            break;
          }
        }
      }
    })

    return fileList;
  }
  
  const validation = () => {
    if(!title.title || title.title==='') return false;
    if(!title.category || title.category===0) return false;
    if(!title.date || title.date==='') return false;
    if(!content || content==='') return false;
    if(!mainImage) return false;

    return true;
  }

  const history = useHistory();

  const write = async(e, action) => {
    e.preventDefault();
    // title validation
    if(action==="post"){
      if(title.title.length<5){
        window.alert('제목은 5자 이상이어야 합니다.')
        return;
      }
      if(title.title.indexOf('/')!==-1) {
        window.alert('제목에 \'\/\'가 들어갈 수 없습니다.')
        return;
      }
      if(title.title.indexOf('-')!==-1) {
        window.alert('제목에 \'\-\'가 들어갈 수 없습니다.')
        return;
      }
      const isDuplTitle = await checkTitleDuplication(title.title);
      if(isDuplTitle===0) {
        window.alert('제목이 중복입니다. 사용 불가!')
        return;
      }
    }

    // validation check 필요
    if(!validation()){
      window.alert('값(제목, 카테고리, 날짜, 메인이미지, 컨텐츠)을 모두 작성하세요')
      return;
    }

    if(action==="post"){
      onPost();
    }
    else if(action==="edit"){
      onEdit();
    }
  }

  const onPost = async() => {
    const uploadFiles = findFileList();
    // console.log(uploadFiles)
    
    if(uploadFiles===-1){
      window.alert("이미지 파일오류(<I>가 존재하는데, 이미지 파일을 업로드 하지 않음)")
      return;
    }

    let formData = new FormData();
    formData.append("title", title.title);
    formData.append("category", title.category);
    formData.append("date", title.date);
    formData.append("content", content.replace(/\$\{/g, '\$\/\{'));
    formData.append("mainImage", mainImage.name);
    formData.append("isMain", isMain);
    formData.append("postImgs", mainImage);
    if(uploadFiles){
      uploadFiles.map((file, index)=>{
        if(file){
          formData.append("postImgs", file);
        }
      })
    }

    await createPost(formData, history);
  }

  const onEdit = async() => {
    // editMainImage === true이면 메인 이미지 변경됨 -> formData[마지막] 업로드    
    const uploadFiles = findFileList();
    if(uploadFiles===-1){
      // 새로 올린 이미지 없음
      // <I>태그 갯수 > urlFiles.length 인지 확인해야함
      // 이미지 삭제할수도 있으니까 >
      let imageCount = (content.match((/<I/g)) || []).length;
      if(imageCount > urlFiles.length){
        window.alert("이미지 파일 부족")
        return;
      }
    }

    let formData = new FormData();
    formData.append("postId", post.post_id);
    formData.append("category", title.category);
    formData.append("date", title.date);
    formData.append("content", content.replace(/\$\{/g, '\$\/\{'));
    formData.append("isMain", isMain);
    if(editMainImage){
      formData.append("urlMainImage", -1);
    }
    else{
      formData.append("urlMainImage", post.main_image);
    }

    if(uploadFiles && uploadFiles.length>0){
      // console.log(uploadFiles)
      uploadFiles.map((file, index)=>{
        if(file){
          formData.append("postImgs", file);
        }
      })
    }
    // main image 변경됐으면 메인이미지 파일 넣기
    if(editMainImage)
      formData.append("postImgs", mainImage);
    
    await updatePost(formData, history);
  }

  const onDelete = async(e) => {
    if(window.confirm("정말 삭제하시겠습니까? 삭제 시 복구 불가합니다.")){
      const deleteRes = await deletePost(post.post_id, history);
      if(deleteRes===1){
        history.push('/Blog/view')
      }
    }
  }

  return (
    <Wrapper>
      <ButtonSection>
        <MainPostCheckSection>
          <input type='checkbox' value={isMain} checked={isMain} onChange={(e)=>{setIsMain(e.currentTarget.checked)}}/>
          <span>main post</span>
        </MainPostCheckSection>
        {post && (
          <>
            <PostButton onClick={(e)=>write(e, "edit")}>EDIT</PostButton>
            <PostButton onClick={(e)=>onDelete(e)} style={{backgroundColor: '#ff4500', color: '#fff'}}>DELETE</PostButton>
          </>
        )}
        {!post && (
          <PostButton onClick={(e)=>write(e,"post")}>POST</PostButton>
        )}
      </ButtonSection>
      <PostingSection>
        <div style={{display:'flex', width: '45%'}}>
          <Editor edit={post?true:false} setEditMainImage={setEditMainImage} setMainImage={setMainImage} title={title} setTitle={setTitle} content={content} setContent={setContent} files={files} setFiles={setFiles}/>
        </div>
        <div style={{zoom: 0.5, display: 'flex', flexDirection:'column', width: '45%'}}>
          <Preview edit={post?true:false} editMainImage={editMainImage} mainImage={mainImage} content={content} files={files} urlFiles={urlFiles} title={title}/>
        </div>
      </PostingSection>
    </Wrapper>
  );
};

export default PostingView;