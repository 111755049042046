const Typesense = require('typesense');

module.exports = {
    client: new Typesense.Client({
        'nodes': [{
          'host': 'search.stipop.com', // For Typesense Cloud use xxx.a1.typesense.net     // For Typesense Cloud use 443
          'protocol': 'https'   // For Typesense Cloud use https
        }]
        , 'apiKey': 'uFFVccKWYkFXJ59HRGAqUflZpkCODaTOaNJZvPRwCjKRlsKf'
        , 'connectionTimeoutSeconds': 2
    }),
}