// 태그 작업 보류됨 (2020.11~)

import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import {
  Row,
  Col,
  Navbar,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Collapse,
} from "react-bootstrap";
import { paging } from "../../Common/Page";
import BottomBar from "../../Components/Money/BottomBar.js";

const AppInfo = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageMap, setPageMap] = useState({});
  const [appList, setAppList] = useState([]);
  const [params, setParams] = useState({
    pageNumber: props.pageNumber ? props.pageNumber * 1 : 1,
  });

  useEffect(() => {
    // componentDidMount
    const getData = async () => {
      await getAppList();
      setIsLoading(false);
    };
    getData();
    return async () => {
      //componentWillUnmount
    };
  }, []);

  const getAppList = async () => {
    const response = await fetch(
      `/new/webAdmin/v2/b2b/app?pageNumber=${params.pageNumber}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      }
    );

    const responseJson = await response.json();

    if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

    setAppList(responseJson.body.appList);
    setPageMap(responseJson.body.pageMap);
  };

  if (!isLoading) {
    return (
      <div
        //  className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header">
          <h1>앱 현황</h1>
          <ol className="breadcrumb">
            <li>
              <a href="/ContentsList">
                <i className="fa fa-dashboard"></i>B2B
              </a>
            </li>
            <li className="active">앱 현황</li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <section className="col-lg-12 connectedSortable">
              <div className="box box-info">
                <div
                  className="box-header with-border"
                  style={{ paddingBottom: "30px", display: "inline" }}
                >
                  {/* <Navbar bg="light" expand="lg">
                                    <Col>
                                        <Row>
                                            <DropdownButton 
                                                variant="dark" id="commit-status-dropdown"
                                                title={category.regionName ? category.regionName:'지역'} 
                                                style={{marginRight:10}}
                                            >
                                                {
                                                    region && region.map((category, index) => {
                                                        return (
                                                            <Dropdown.Item 
                                                                key={index} 
                                                                onClick={() => {
                                                                    setCategory(state => ({...state, regionId:category.regionId, regionName:category.region}));
                                                                }}
                                                            >
                                                                {category.region}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </DropdownButton>
                                            <Button onClick={getPackageList}>조회</Button>
                                        </Row>
                                    </Col>
                                </Navbar> */}
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>App name</th>
                          <th>MAU(this month)</th>
                          <th>MAU(last 7 days)</th>
                          <th>API Call(this month)</th>
                          <th>API Call (last 7 days)</th>
                          <th>Sticker sent(this month)</th>
                          <th>Sticker sent(last 7 days)</th>
                          <th>Sticker view(this month)</th>
                          <th>Sticker view(last 7 days)</th>
                          <th>Invoicing amount(this month)</th>
                          <th>Company name</th>
                          <th>Billing info registered date</th>
                          <th>Website</th>
                          <th>name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {appList &&
                          appList.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {(params.pageNumber - 1) * 20 + (index + 1)}
                                </td>
                                <td>{data.APP_NAME}</td>
                                <td>{data.mauMonth}</td>
                                <td>{data.mauWeek}</td>
                                <td>{data.callMonth}</td>
                                <td>{data.callWeek}</td>
                                <td>{data.sendMonth}</td>
                                <td>{data.sendWeek}</td>
                                <td>{data.viewMonth}</td>
                                <td>{data.viewWeek}</td>
                                <td>{""}</td>
                                <td>{data.COMPANY}</td>
                                <td>{data.regDate}</td>
                                <td>{data.website}</td>
                                <td>{data.name}</td>
                                <td>{data.EMAIL}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Row>
          <Col>
            <BottomBar
              pageNumber={params.pageNumber}
              pageMap={pageMap}
              pageName={"AppInfo"}
              search={""}
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default withRouter(AppInfo);
