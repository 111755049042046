import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import List from "../Common/List";
import {EditList} from "../Common/EditList";
import SearchTextLimit from "../Common/SearchTextLimit";

const searchGraphionica = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState([]); 
    const [dataList, setDataList] = useState([]);
    const [limit, setLimit] = useState(20);
    const [q, setQ] = useState("");
    const [animated, setAnimated] = useState("");
    const [group, setGroup] = useState("");
    
    const search = useCallback(async () => { 

        // setState(searchText);

        const response = await fetch(`https://messenger.stipop.io/v1/search/graphionica?q=${q}&userId=9937}&limit=${limit}&lang=en&animated=${animated === 'all' ? '':animated}&group=${group}`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                // apikey: 'a3e68b6c54d8132f6879d5bc2c49708d',
                apikey: 'f1487146b30e92fc07ae42f44de3c5d7',
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
// console.log(responseJson)
        if (responseJson.body.stickerList) {

            if (filedList.length === 0) {
                
                let fileds = []
    
                for (const tmp in responseJson.body.stickerList[0]) {
                    fileds.push(tmp);
                }
                
                setFiledList(fileds);
            }
            
            setDataList(responseJson.body.stickerList);

        } else {
            setDataList([]);
        }

    }); 

    const [SearchInput] = SearchTextLimit(q, setQ, limit, setLimit, search, setAnimated, setGroup);
    // const [keyword, SearchInput, setState] = SearchTextLimit(q, setQ, limit, setLimit, search);

    const callback = useCallback((value, key, index) => {
        dataList[index][key] = value;
        // setDataList([...dataList]);
    }, [dataList]);

    const editKeyword = async (data) => {

        const response = await fetch(`/new/webAdmin/v2/b2b/search`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            }
            , method:'PUT'
            , body: JSON.stringify(data)
        })

        setDataList([...dataList]);
        alert("수정완료");
    }

    const deleteKeyword = async (seq, index) => {
        // alert(seq);
        // alert(index);
        if (window.confirm('Are You Sure?')) {
            
            const response = await fetch(`/new/webAdmin/v2/b2b/search/keyword/${seq}`, {
                headers : {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: sessionStorage.getItem("token")
                }
                , method:'DELETE'
            });

            setDataList(dataList.filter(item => item.seq !== seq));
            
            alert("삭제완료");
        }
    };

    const customComponent = useCallback(() => {
        return (
            <EditList 
                fieldList={filedList} 
                dataList={dataList} 
                setDataList={callback} 
                editKeyword={(data) => editKeyword(data)} 
                deleteKeyword={(seq, index) => deleteKeyword(seq, index)} 
            />
            // <List fieldList={filedList} dataList={dataList} />
        )
    }, [dataList]);


    useEffect(() => { 
        // componentDidMount
        setIsLoading(false);

        return async () => {
          //componentWillUnmount
        };
    }, [dataList]);

    if (!isLoading) {
        return (
            <>
                <Header title={"search Graphionica"} />
                <Body HeaderComponent={SearchInput} headerWidth={"50%"} DataComponet={customComponent}  />
            </>
        )
    } else {
        return <Loading />;
    }
}

export default withRouter(searchGraphionica);