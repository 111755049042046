import React from 'react'
import { CSVLink } from 'react-csv'
import Button from 'react-bootstrap/Button';

/**
 * 주어진 정보를 CSV 로 추출하는 Button Component 생성
 * @param {Array[Object]} csvData 추출할 정보를 담은 객체 배열
 * @param {String} fileName 추출한 CSV 파일 이름
 * @param {String} title 버튼에 표시되는 이름
 */
export const ExportReactCSV = ({csvData, fileName, title}) => {
    return (
        <Button style={{margin: '10px'}} variant="secondary">
            <CSVLink style={{color: "white"}}data={csvData} filename={fileName}>{title}</CSVLink>
        </Button>
    )
}