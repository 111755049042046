import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { paging2 } from '../../Common/Page'
import Loading from '../Common/Loading/Loading'
import axios from 'axios'
import UserInfoPopup from './UserInfoPopup'

const UserList = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [pageNumber, setPageNumber] = useState(
    props.pageNumber ? props.pageNumber : 1
  )
  const [pageMap, setPageMap] = useState('')
  const [search, setSearch] = useState(props.search ? props.search : '')
  const [users, setUsers] = useState([])
  const [type, setType] = useState(0)
  const [open, setOpen] = useState(false)
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getUserList(pageNumber)
    } else {
      props.history.push('/')
    }
  }, [])

  const getUserList = num => {
    setIsLoading(true)
    const params = '?pageNumber=' + num + '&search=' + search + '&type=' + type

    axios
      .get(`/new/webAdmin/v2/member` + params, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem("token"),
        },
      })
      .then(({ data }) => {
        console.log(data)
        setUsers(data.memberList)
        setPageNumber(num)
        setPageMap(data.pageMap)
        setIsLoading(false)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const userListRender = () => {
    let list = []
    if (users.length > 0) {
      for (const data of users) {
        list.push(
          <tr
            key={data.u_id}
            onClick={() => {
              setOpen(true)
              setUserData(data)
            }}
          >
            <td>{data.u_id}</td>
            <td>{data.u_name}</td>
            <td>{data.u_email}</td>

            <td>{data.u_date}</td>
            <td>{data.u_loginType === 0 ? 'Web' : 'App'}</td>
            <td>{data.u_phModel ? data.u_phModel : 'web'}</td>

            <td>{data.c_name}</td>
            <td>
              {data.u_certMail === 0 ? (
                <i className="fa fa-times"></i>
              ) : (
                <i className="fa fa-check"></i>
              )}
            </td>
          </tr>
        )
      }
      return list
    } else {
      return <tr></tr>
    }
  }

  const keyEvent = event => {
    if (event.key === 'Enter') {
      getUserList(1)
    }
  }

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div
        // className="content-wrapper"
        style={{ minHeight: '100%' }}
      >
        <section className="content-header">
          <h1>유저 관리</h1>
          <ol className="breadcrumb">
            <li>
              <i className="fa fa-dashboard"></i>회원 관리
            </li>
            <li className="active">유저 관리</li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            {/* <!-- Left col --> */}
            <section className="col-lg-12 connectedSortable">
              {/* <!-- Custom tabs (Charts with tabs)--> */}
              <div className="box box-info">
                <div
                  className="box-header with-border"
                  style={{ paddingBottom: '30px' }}
                >
                  <div className="box-tools pull-left">
                    <div
                      className="input-group input-group-sm"
                      style={{ width: '150px' }}
                    >
                      <input
                        type="text"
                        name="table_search"
                        value={search}
                        className="form-control pull-right"
                        placeholder="Search"
                        onKeyPress={keyEvent}
                        onChange={e => setSearch(e.target.value)}
                      />
                      <div className="input-group-btn">
                        <button
                          type="submit"
                          className="btn btn-default"
                          onClick={() => getUserList(1)}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /.box-header --> */}
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>이름</th>
                          <th>email</th>

                          <th>가입일</th>
                          <th>가입경로</th>
                          <th>os</th>

                          <th>국적</th>
                          <th>메일인증</th>
                        </tr>
                      </thead>
                      <tbody>{userListRender()}</tbody>
                    </table>
                  </div>
                  <div className="box-footer clearfix">
                    <ul className="pagination pagination-sm no-margin pull-right">
                      {paging2(
                        pageNumber,
                        pageMap,
                        'Member/Users',
                        search,
                        type
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
      {open && userData && (
        <UserInfoPopup
          data={userData}
          setData={setUserData}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  )
}

export default withRouter(UserList)
