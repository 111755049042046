import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { paging } from "../../Common/Page";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../Common/Loading/Loading";

class ArtistQnaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageNumber: this.props.pageNumber ? this.props.pageNumber : 1,
      search: this.props.search ? this.props.search : "",

      ArtistQnaList: [],
      pageMap: {},
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this._getStickerQnaList(this.state.pageNumber);
    } else {
      this.props.history.push("/");
    }
  }

  _getStickerQnaList = (num) => {
    const params = "?pageNumber=" + num + "&search=" + this.state.search;

    fetch("/new/webAdmin/v2/ArtistQnaList" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          ArtistQnaList: responseJson.ArtistQnaList,
          pageMap: responseJson.pageMap,
          pageNumber: num,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  getTypeName = (type) => {
    switch (type) {
      case "gu":
        return "Service Guide";
      case "reg":
        return "Registration";
      case "reve":
        return "Revenue";
      case "la":
        return "Launch";
      case "oth":
        return "Others";
      case "revi":
        return "Review";
      default:
        return "None";
    }
  };

  _artistQnaListRender = () => {
    const result = this.state.ArtistQnaList.map((item) => (
      <tr key={item.seq}>
        <td>{item.seq}</td>
        <td>
          {item.u_name} / {item.u_id} /<br />
          {item.u_email}
        </td>
        <td>{this.getTypeName(item.type)}</td>
        <td onClick={() => this._qnaDetail(item.seq)}>{item.contents}</td>
        <td>{item.regdate}</td>
        <td>{item.status === 0 ? "답변 대기중" : " 답변완료"}</td>
        <td>{item.replyDate ? item.replyDate : "-"}</td>
      </tr>
    ));
    return result;
  };

  _qnaDetail = (seq) => {
    this.props.history.push(
      "/v1/ArtistQnaDetail?id=" +
        seq +
        "&pageNumber=" +
        this.state.pageNumber +
        "&search=" +
        this.state.search
    );
  };

  keyEvent = (event) => {
    if (event.key === "Enter") {
      this._getStickerQnaList(1);
    }
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
          // className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header">
            <h1>1:1 문의사항 관리</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/StickerQnaList">
                  <i className="fa fa-dashboard"></i> 문의사항
                </a>
              </li>
              <li className="active">1:1 문의사항 관리</li>
            </ol>
          </section>
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div className="box-tools pull-right">
                    <InputGroup>
                      <FormControl
                        type="text"
                        value={this.state.search}
                        placeholder="Search"
                        onChange={(event) =>
                          this.setState({ search: event.target.value })
                        }
                        onKeyPress={this.keyEvent}
                      />
                      <Button
                        variant="primary"
                        onClick={() => this._getStickerQnaList(1)}
                      >
                        <i className="fa fa-search" />
                      </Button>
                    </InputGroup>
                  </div>
                  {/* <!-- /.box-header --> */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th width="3%">No.</th>
                            <th width="15%">작가명/ID/작가 이메일</th>
                            <th width="5%">타입</th>
                            <th width="49%">내용</th>
                            <th width="10%">등록일</th>
                            <th width="8%">답변여부</th>
                            <th width="10%">답변일</th>
                          </tr>
                        </thead>
                        <tbody>{this._artistQnaListRender()}</tbody>
                      </table>
                    </div>
                    <div className="box-footer clearfix">
                      <ul className="pagination pagination-sm no-margin pull-right">
                        {paging(
                          this.state.pageNumber,
                          this.state.pageMap,
                          "v1/ArtistQnaList",
                          this.state.search
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default withRouter(ArtistQnaList);
