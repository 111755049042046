import React from "react";
import CategoryAdd from "../../Components/Category/CategoryAdd";
// import LeftMenu from "../../Components/LeftMenu";

const CategoryAddPage = ({ match, location }) => {
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <CategoryAdd />
    </React.Fragment>
  );
};

export default CategoryAddPage;
