import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Header from "../Common/Header";
import Body from "../Common/Body";
import Loading from "../Common/Loading/Loading";
import SelectBox from "../Common/SelectBox";
import OverviewList from "./OverviewList";
import Paging from "../../_Components/Common/Page/Paging";
import axios from "axios";

const OverviewDashboard =(props)=> {
  const [isLoading, setIsLoading] = useState(true);
  const [overviewList, setOverviewList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [date, setDate] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const onChange = (date) => {
    setDate(date);
    setPageNumber(1);
    getOverviewList(date, 1);
  }
  const [uadateTime, setUpdateTime] = useState("now")
  const [Select, setState] = SelectBox(date, dateList, onChange, uadateTime);
  const [total, setTotal] = useState();
  
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getDateList();
    } else {
      props.history.push("/");
    }
  }, []);

  useEffect(()=>{
    if(date){
      getOverviewList(date, pageNumber)
    }
  }, [date, pageNumber])
  
  const getDateList = async() => {
    await axios.get(`/new/webAdmin/v2/overview/date`, {
      headers: {
        'token': window.sessionStorage.getItem("token"),
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(res=>{
        // console.log(res)
        if(res.status===200 && res.data.status==='success'){
          setDateList(res.data.date)
          setDate(res.data.date[0].month)

          // set update time
          const updates = res.data.updateTime.split('T')
          setUpdateTime(`${updates[0]} ${updates[1].substring(0,5)} 최신 업데이트`)
        }
      })
      .catch(err=>{
        setDateList([])
        setDate(null)
        console.log(err)
      })
  }

  const getOverviewList = async(date, pageNumber) => {
    // get api
    await axios.get(`/new/webAdmin/v2/overview/${date}/${pageNumber}`, {
      headers: {
        'token': window.sessionStorage.getItem("token"),
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(res => {
        console.log(res)

        if(res.status===200 && res.data.status==='success'){
          // total sum - MSU, send, down
          // console.log(res.data.data.total)
          setTotal(res.data.data.total)
          // setTotal({
          //   msu: res.data.data.total.MSU,
          //   send: res.data.data.total.send,
          //   down: res.data.data.total.down
          // })

          setIsLoading(false)
          setEndPage(res.data.data.endPage)
          setOverviewList(res.data.data.list)

        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const dataComponent = useCallback(()=>{
    if(isLoading){
      return <div style={{height: 200}}><Loading /></div>
    }
    else{
      return (
        <>
          <OverviewList overviewList={overviewList} currentPage={pageNumber} total={total} date={date}/>
          {/* paging component */}
          <Paging currentPage={pageNumber} lastPage={endPage} style={{marginTop: 20}} setPageNumber={setPageNumber}/>
        </>
      )
    }
  }, [overviewList])

  return (
    <>
      <Header title={"Overview"}/>
      <Body
        HeaderComponent={Select}
        headerWidth={"20%"}
        DataComponet={dataComponent}
      />
    </>
  );
}

export default withRouter(OverviewDashboard);
