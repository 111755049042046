import React from 'react';
import Pagination from 'react-bootstrap/Pagination'
import queryString from 'query-string';

let _renderPaging = (pageNumber, pageMap, pageName, search) => {

    let list = [];
    let j = 0;
    let i = 0;

    const first = "/" + pageName + "?search=" + search + "&pageNumber=";
    let test = pageNumber !== 1 ? pageNumber - 1 : 1;
    let last = pageNumber !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount;

    list.push(<Pagination.Item key='first' href={first + test}>&laquo;</Pagination.Item>)

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
        let num = pageMap.startPage * 1 + j

        if ((num) === pageMap.pageNumber * 1) {
            list.push(<Pagination.Item key={i} className="active">{num}</Pagination.Item>)
        } else {
            list.push(<Pagination.Item key={i} href={first + num}>{num}</Pagination.Item>)
        }
        j++;
    }

    list.push(<Pagination.Item key='last' href={first + last}>&raquo;</Pagination.Item>)
    return <Pagination >{list}</Pagination>;
}

export const paging = (pageNumber, pageMap, pageName, search) => {
    return _renderPaging(pageNumber, pageMap, pageName, search);
}
            
let _renderPaging2 = (pageNumber, pageMap, pageName, search, type, order, language) => {

    let list = [];
    let j = 0;
    let i = 0;

    const first = "/" + pageName + "?search=" + search +"&type=" + type + "&order=" + order + "&language=" + language  + "&pageNumber=";
    let test = pageNumber !== 1 ? pageNumber - 1 : 1;
    let last = pageNumber !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount;

    list.push(<Pagination.Item  key='first' href={first + test}>&laquo;</Pagination.Item >)

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
        let num = pageMap.startPage * 1 + j

        if ((num) === pageMap.pageNumber * 1) {
            list.push(<Pagination.Item  key={i} className="active">{num}</Pagination.Item >)
        } else {
            list.push(<Pagination.Item  key={i} href={first + num}>{num}</Pagination.Item >)
        }
        j++;
    }

    list.push(<Pagination.Item  key='last' href={first + last}>&raquo;</Pagination.Item >)
    return <Pagination >{list}</Pagination>;
}

export const paging2 = (pageNumber, pageMap, pageName, search, type, order, language) => {
    return _renderPaging2(pageNumber, pageMap, pageName, search, type, order, language);
}

let _renderPaging3 = (pageNumber, pageMap, pageName, search, type, groupId, countryCode) => {
    let list = [];
    let j = 0;
    let i = 0;

    const first = "/" + pageName + "?search=" + search +"&type=" + type + "&groupId=" + groupId + "&country=" + countryCode + "&pageNumber=";
    let test = pageNumber !== 1 ? pageNumber - 1 : 1;
    let last = pageNumber !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount;

    list.push(<Pagination.Item  key='first' href={first + test}>&laquo;</Pagination.Item >)

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
        let num = pageMap.startPage * 1 + j

        if ((num) === pageMap.pageNumber * 1) {
            list.push(<Pagination.Item  key={i} className="active">{num}</Pagination.Item >)
        } else {
            list.push(<Pagination.Item  key={i} href={first + num}>{num}</Pagination.Item >)
        }
        j++;
    }

    list.push(<Pagination.Item key='last' href={first + last}>&raquo;</Pagination.Item>)
    return <Pagination >{list}</Pagination>;
}

export const paging3 = (pageNumber, pageMap, pageName, search, type, groupId, countryCode) => {
    return _renderPaging3(pageNumber, pageMap, pageName, search, type, groupId, countryCode);
}

let _renderPaging4 = (pageNumber, pageMap, pageName, type, status) => {
    let list = [];
    let j = 0;
    let i = 0;

    const first = "/" + pageName + "?status=" + status +"&type=" + type  + "&pageNumber=";
    let test = pageNumber !== 1 ? pageNumber - 1 : 1;
    let last = pageNumber !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount;

    list.push(<Pagination.Item  key='first' href={first + test}>&laquo;</Pagination.Item >)

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
        let num = pageMap.startPage * 1 + j

        if ((num) === pageMap.pageNumber * 1) {
            list.push(<Pagination.Item  key={i} className="active">{num}</Pagination.Item >)
        } else {
            list.push(<Pagination.Item  key={i} href={first + num}>{num}</Pagination.Item >)
        }
        j++;
    }

    list.push(<Pagination.Item  key='last' href={first + last}>&raquo;</Pagination.Item >)
    return <Pagination >{list}</Pagination>;
}

export const paging4 = (pageNumber, pageMap, pageName, type, status) => {
    return _renderPaging4(pageNumber, pageMap, pageName, type, status);
}

let _renderPaging5 = (pageNumber, params, pageMap, pageName) => {

    const param = queryString.stringify(params);

    let list = [];
    let j = 0;
    let i = 0;

    const first = `/${pageName}?${param}`
    let test = pageNumber*1 !== 1 ? pageNumber*1 - 1 : 1;
    let last = pageNumber*1 !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount;

    list.push(<Pagination.Item  key='first' href={first + `&pageNumber=${test}`}>&laquo;</Pagination.Item >)

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
        let num = pageMap.startPage * 1 + j

        if ((num) === pageMap.pageNumber * 1) {
            list.push(<Pagination.Item  key={i} className="active">{num}</Pagination.Item >)
        } else {
            list.push(<Pagination.Item  key={i} href={first + `&pageNumber=${num}`}>{num}</Pagination.Item >)
        }
        j++;
    }

    list.push(<Pagination.Item  key='last' href={first+ `&pageNumber=${last}`}>&raquo;</Pagination.Item >)
    return <Pagination >{list}</Pagination>;
}

export const paging5 = (pageNumber, params, pageMap, pageName) => {
    return _renderPaging5(pageNumber, params, pageMap, pageName);
}