import axios from 'axios'
import React, { useState } from 'react'
import {
  Row,
  Col,
  Navbar,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap'

const getTypeName = type => {
  switch (type) {
    case 'bookmark':
      return 'Bookmark'
    case 'us':
      return 'US'
    default:
      return 'Bookmark'
  }
}

const BookmarkListTopBar = props => {
  const { search, setSearch, setSearchData } = props
  const [type, setType] = useState(props.filter ? props.filter : 'bookmark')

  const keyEvent = event => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (search) {
      axios
        .get(`/new/webAdmin/v2/bookmark/sticker/keyword/${search}`, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(({ data }) => {
          console.log(data.body)
          setSearchData(data.body)
        })
    } else {
      setSearchData([])
    }
  }

  return (
    <Navbar bg="light" expand="lg" style={{ marginTop: '15px' }}>
      <Col>
        <DropdownButton
          variant="dark"
          id="commit-status-dropdown"
          title={getTypeName(type)}
        >
          <Dropdown.Item
            onClick={() => {
              props.setFilter('bookmark')
              setType('bookmark')
            }}
          >
            Bookmark
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.setFilter('us')
              setType('us')
            }}
          >
            US
          </Dropdown.Item>
        </DropdownButton>
      </Col>
      <Row style={{ marginRight: '5px' }}>
        <InputGroup onSubmit={handleSubmit}>
          <FormControl
            type="text"
            value={search}
            placeholder="Search"
            onChange={event => setSearch(event.target.value)}
            onKeyPress={keyEvent}
          />
          <Button variant="primary" onClick={handleSubmit}>
            <i className="fa fa-search" />
          </Button>
        </InputGroup>
      </Row>
    </Navbar>
  )
}

export default React.memo(BookmarkListTopBar)
