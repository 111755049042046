import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const TopStickers = props => {
  const { title, topStickers } = props

  return (
    <>
      <Title>{title}</Title>
      <SpaceResponsive height={16} />
      <Wrapper>
        {topStickers &&
          topStickers.map((sticker, index) => {
            if (index < 4) {
              return (
                <StickerWrapper key={index} rank={index + 1}>
                  <Sticker src={sticker.packageImg} />
                  <StickerInfo>
                    <PackageName>{sticker.packageName}</PackageName>
                    <SpaceResponsive height={2} />
                    <ArtistName>
                      {sticker.artistName ? sticker.artistName : 'artist'}
                    </ArtistName>
                    <Rank>#{index + 1}</Rank>
                  </StickerInfo>
                </StickerWrapper>
              )
            }
          })}
      </Wrapper>
      <SpaceResponsive height={34} />
    </>
  )
}

export default TopStickers

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 343px;
  height: 336px;
  border-radius: 24px;
  background-color: #fff;
  padding: 8px 13.5px;
`

const StickerWrapper = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: ${props => (props.rank < 4 ? '1px solid #cfcfcf' : 'none')};
  display: flex;
  align-items: center;
  position: relative;
`

const Sticker = styled.img`
  width: 72px;
  height: 72px;
  margin-right: 8.5px;
`

const StickerInfo = styled.div`
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const PackageName = styled.div`
  font-size: 14px;
  line-height: 18.8px;
  letter-spacing: 0.16px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
`

const ArtistName = styled.div`
  font-size: 11px;
  line-height: 14.3px;
  letter-spacing: 0.13px;
  color: #a0a7af;
`

const Rank = styled.div`
  font-size: 14px;
  font-family: Roboto;
  line-height: 18.8px;
  letter-spacing: 0.16px;
  color: #a0a7af;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 278.1px;
  transform: translateY(-50%);
`
