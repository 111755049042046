import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  
  /* margin: 40px 300px; */
`;

const PageButton = styled.div`
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 12px;
`;

const MoveButton = styled(PageButton)`
  color: ${({ disable }) => (disable ? 'gray !important' : '#000')};
  cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};
  margin: 0 20px;
`;

const NumberButton = styled(PageButton)`
  color: ${({ active }) => (active ? '#000' : 'gray')};
  font-weight: ${({ active }) => (active ? 'bold' : '500')};;
  margin: 0 8px;
`;

const Paging = ({ currentPage, lastPage, style, setPageNumber=null }) => {
  let pageArray = [];
  for (let i = 0; i < lastPage; i++) {
    pageArray[i] = i + 1;
  }

  useEffect(()=>{
    currentPage *= 1;
    lastPage *= 1;
  }, [currentPage, lastPage])

  const history = useHistory();

  const movePage = (pageNum) => {
    if(setPageNumber===null){
      // blog - move page
      let path = history.location.pathname;
      let pathCnt = (path.match(/\//g) || []).length;
      if(pathCnt<3){
        path += `/all/${pageNum}`;
      }
      else{
        path = path.substring(0,path.lastIndexOf("/")) + `/${pageNum}`;
      }
      // console.log(path)
      history.push(path)
    }
    else{
      // pageNumber만 --
      setPageNumber(pageNum)
    }
  }

  return (
    <Wrapper style={style}>
      <MoveButton
        disable={currentPage == 1}
        onClick={() => {
          if (currentPage - 1 > 0) {
            movePage(currentPage - 1);
          }
        }}
      >
        <span>
          <svg
            width="7px"
            height="11px"
            viewBox="0 0 7 11"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g stroke={currentPage == 1 ? 'none' : 'black'} strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-420.000000, -203.000000)" fill="#BBBBBB" className={'path'}>
                <polygon points="421.710617 208.266011 426.12132 212.676714 425.266011 213.532023 420 208.266011 425.266011 203 426.12132 203.855309" />
              </g>
            </g>
          </svg>
        </span>
      </MoveButton>
      {pageArray.length>0 && pageArray.map((num, index) => {
        return (
          <NumberButton
            key={`paging_${index}`}
            active={currentPage == num}
            onClick={() => {
              movePage(num);
            }}
          >
            {num}
          </NumberButton>
        );
      })}
      <MoveButton
        disable={currentPage == lastPage}
        onClick={() => {
          if (currentPage + 1 <= lastPage) {
            movePage(currentPage + 1);
          }
        }}
      >
        <span>
          <svg
            width="7px"
            height="11px"
            viewBox="0 0 7 11"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g stroke={currentPage == lastPage ? 'none' : 'black'} strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-472.000000, -236.000000)" fill="#BBBBBB" className={'path'}>
                <polygon points="476.410703 241.266011 472 236.855309 472.855309 236 478.12132 241.266011 472.855309 246.532023 472 245.676714" />
              </g>
            </g>
          </svg>
        </span>
      </MoveButton>
    </Wrapper>
  );
};

export default Paging;
