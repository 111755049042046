import React from "react";
import ArtistSearch from "../../Components/Member/ArtistSearch";
// import LeftMenu from "../../Components/LeftMenu";

const ArtistSearchPage = ({ match, location }) => {
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <ArtistSearch />
    </React.Fragment>
  );
};

export default ArtistSearchPage;
