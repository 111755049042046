import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  z-index: 10;
  position: fixed;
  display: flex;
  width: 100%;
  left: 230px;
  min-height: 50px;
  height: 50px;
  color: white;
  background-color: #3c8dbc;
`;

const HeaderBarContainer = () => {
  return <Wrapper></Wrapper>;
};

export default HeaderBarContainer;
