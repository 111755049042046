import React from "react";
import { withRouter } from "react-router-dom";
import { paging2 } from "../../Common/Page";
import "react-datepicker/dist/react-datepicker.css";

class CommentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageNumber: this.props.pageNumber ? this.props.pageNumber : 1,
      type:
        this.props.location.state === undefined
          ? this.props.type
            ? this.props.type
            : "A"
          : this.props.location.state.type,
      idList: [],
      img: "",
      order: [],
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this._getCommentList(this.state.pageNumber, this.state.type);
      // this.setState({isLoading:false});
    } else {
      this.props.history.push("/");
    }
  }

  _getCommentList = (num, type) => {
    const params = "?pageNumber=" + num + "&type=" + this.state.type;
    fetch("/new/webAdmin/v2/comment" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
      //    ,body: JSON.stringify(params)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          commentList: responseJson.result.commentList,
          pageMap: responseJson.result.pageMap,
          pageNumber: num,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _delete = (c_id) => {
    if (this.state.idList.length === 0) {
      alert("삭제할 아이템을 선택하세요");
      return;
    }

    const params = {
      idList: this.state.idList,
    };

    fetch("/new/webAdmin/v2/comment", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "delete",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({ idList: [] });
        this._getCommentList(this.state.pageNumber, this.state.type);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _checkBoxEvent = (event) => {
    const { idList } = this.state;
    const { value } = event.target;

    if (event.target.checked) {
      this.setState({ idList: idList.concat(value) });
    } else {
      this.setState({ idList: idList.filter((id) => id !== value) });
    }
    // this.setState({idList:this.state.idList.concat(data.itview_id)}
  };

  _commentListRender = () => {
    const { commentList } = this.state;

    let list = [];

    if (commentList) {
      for (const data of commentList) {
        list.push(
          <tr key={data.seq}>
            <td>{data.seq}</td>
            <td>
              <input
                type="checkbox"
                onChange={this._checkBoxEvent}
                value={data.seq}
              />
            </td>
            <td>{data.u_name}</td>
            <td>{data.p_name}</td>
            <td>{data.contents}</td>
            <td>{data.regDate}</td>
          </tr>
        );
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
          //  className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header">
            <h1>댓글 관리</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/AThemeList">
                  <i className="fa fa-dashboard"></i>댓글
                </a>
              </li>
              <li className="active">댓글 관리</li>
            </ol>
          </section>
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div className="box-header with-border">
                    <div className="box-tools pull-right">
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="collapse"
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="remove"
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  {/* <!-- /.box-header --> */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th></th>
                            <th>작성자명</th>
                            <th>패키지명</th>
                            <th>내용</th>
                            <th>등록일</th>
                          </tr>
                        </thead>
                        <tbody>{this._commentListRender()}</tbody>
                      </table>
                    </div>
                    <div className="box-footer clearfix">
                      <button
                        type="button"
                        onClick={this._delete}
                        className="btn btn-primary btn-block btn-flat"
                        style={{
                          width: "10%",
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                      >
                        삭제
                      </button>
                      <ul className="pagination pagination-sm no-margin pull-right">
                        {paging2(
                          this.state.pageNumber,
                          this.state.pageMap,
                          "CommentList",
                          "",
                          this.state.type
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          {/* 팝업시작 */}

          {/* 팝업끝 */}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(CommentList);
