import React from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const Monthly = props => {
  const { title, bannerList } = props

  return (
    <>
      <Title>{title}</Title>
      <SpaceResponsive height={16} />
      <StickerWrapper
        lightColor={bannerList[0].lightBackgroundCode}
        darkColor={bannerList[0].darkBackgroundCode}
      >
        <img src={bannerList[0].cardImgUrl} alt="" />
      </StickerWrapper>
      <SpaceResponsive height={34} />
    </>
  )
}

export default Monthly

const StickerWrapper = styled.div`
  width: 343px;
  height: 241px;
  background-color: ${props => props.lightColor};
  border-radius: 42px;

  img {
    width: 100%;
    height: 100%;
  }
`
