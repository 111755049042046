import React, { useState } from 'react'
import {
  Row,
  Col,
  Navbar,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap'

const getTypeName = type => {
  switch (type) {
    case 0:
      return '검토 중'
    case 1:
      return '승인'
    case 2:
      return '수정 요청'
    case 3:
      return '거절'
    default:
      return '전체'
  }
}

const ContentsListTopBar = props => {
  const [search, setSearch] = useState(
    props.params.search ? props.params.search : ''
  )

  const handleSubmit = event => {
    event.preventDefault()
    props.setParams({
      ...props.params,
      pageNumber: 1,
      search: search,
    })
  }

  const keyEvent = event => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  return (
    <Navbar bg="light" expand="lg">
      <Col>
        <DropdownButton
          variant="dark"
          id="commit-status-dropdown"
          title={getTypeName(props.params.type)}
        >
          <Dropdown.Item
            onClick={() =>
              props.setParams({
                pageNumber: 1,
                search: search,
                type: '',
                order: props.params.order,
                language: props.params.language,
              })
            }
          >
            전체
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.setParams({
                pageNumber: 1,
                search: search,
                type: 0,
                order: props.params.order,
                language: props.params.language,
              })
            }
          >
            검토 중
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.setParams({
                pageNumber: 1,
                search: search,
                type: 1,
                order: props.params.order,
                language: props.params.language,
              })
            }
          >
            승인
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.setParams({
                pageNumber: 1,
                search: search,
                type: 2,
                order: props.params.order,
                language: props.params.language,
              })
            }
          >
            수정 요청
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.setParams({
                pageNumber: 1,
                search: search,
                type: 3,
                order: props.params.order,
                language: props.params.language,
              })
            }
          >
            거절
          </Dropdown.Item>
        </DropdownButton>
      </Col>
      <Row style={{ marginRight: '5px' }}>
        <InputGroup onSubmit={handleSubmit}>
          <FormControl
            type="text"
            value={search}
            placeholder="Search"
            onChange={event => setSearch(event.target.value)}
            onKeyPress={keyEvent}
          />
          <Button variant="primary" onClick={handleSubmit}>
            <i className="fa fa-search" />
          </Button>
        </InputGroup>
      </Row>
    </Navbar>
  )
}

export default React.memo(ContentsListTopBar)
