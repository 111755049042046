import React from 'react'
import {Row, Col, Navbar} from 'react-bootstrap'
import { paging3 } from '../../Common/Page';
import {ExportReactCSV} from '../../Common/ExportReactCSV';

const ArtistListBottomBar = (props) => {
    return (
        <Navbar bg="light" expand="lg" >
            <Col>
                <ExportReactCSV csvData={props.csvData} fileName='emaillist.csv' title='아티스트 이메일' />
            </Col>
            <Row >
                <Col>
                    {paging3(props.params.pageNumber, props.pageMap, props.pageName, props.params.search, props.memberType, props.params.groupId, props.params.countryCode)}
                </Col>
            </Row>
        </Navbar>
    )
}

export default ArtistListBottomBar;