import React, { Fragment, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import styled from "styled-components";
import DefaultButton from "../../Common/ButtunUI/DefaultButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  input:focus {
    outline: none;
  }
  textarea:focus {
    outline: none;
  }
`;

const InputTitleEn = styled.input`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
`;

const InputTitleKo = styled.input`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  padding: 10px;
`;

const InputContentsHelp = styled.div`
  padding: 10px 15px;
  margin-bottom: 10px;
  background-color: #00a65a;
  border-radius: 4px;
  color: white;
`;

const InputContentsEn = styled.textarea`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  padding: 10px;
  resize: none;
  margin-bottom: 10px;
`;

const InputContentsKo = styled.textarea`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  padding: 10px;
  resize: none;
`;

const InputTag = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
`;

const InputCurrentFileBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const InputCurrentFileText = styled.a`
  width: fit-content;
`;

const InputCurrentFileRemove = styled(MdDeleteForever)`
  padding: 2px;
  border: 1px solid #d2d6de;
  width: 22px;
  height: 22px;
  margin-left: 10px;
  margin-top: 1px;
  border-radius: 4px;
  cursor: pointer;
`;

const InputImage = styled.input`
  width: fit-content;
`;

const InputFile = styled.input`
  width: fit-content;
  margin-bottom: 10px;
`;

const CloseCheckForm = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CloseCheckBox = styled.input`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  margin-right: 5px;
`;

const MessageNoticeEditForm = ({ noticeData, setNoticeData, goTop }) => {
  const [inputTitleEn, setInputTitleEn] = useState("");
  const [inputTitleKo, setInputTitleKo] = useState("");
  const [inputContentsEn, setInputContentsEn] = useState("");
  const [inputContentsKo, setInputContentsKo] = useState("");

  const imageEnRef = useRef(null);
  const imageKoRef = useRef(null);
  const attachEnRef1 = useRef(null);
  const attachEnRef2 = useRef(null);
  const attachEnRef3 = useRef(null);
  const attachKoRef1 = useRef(null);
  const attachKoRef2 = useRef(null);
  const attachKoRef3 = useRef(null);

  const [isContentsBottom, setIsContentsBottom] = useState(false);
  const [isFixChecked, setIsFixChecked] = useState(false);

  const [currentData, setCurrentData] = useState();

  useEffect(() => {
    if (noticeData) {
      setCurrentData(noticeData);
      const {
        noticeTitleEn,
        noticeTitleKo,
        noticeContentsEn,
        noticeContentsKo,
        fixStatus,
        noticeContentsPlace,
      } = noticeData;
      setInputTitleEn(noticeTitleEn);
      setInputTitleKo(noticeTitleKo);
      if (noticeContentsEn !== null) {
        setInputContentsEn(noticeContentsEn);
      }
      if (noticeContentsKo !== null) {
        setInputContentsKo(noticeContentsKo);
      }
      setIsFixChecked(fixStatus === 1);
      setIsContentsBottom(noticeContentsPlace === 1);
    }
  }, []);

  const setPreview = async () => {
    const {
      noticeImageEn,
      noticeImageKo,
      attachNameEn1,
      attachNameEn2,
      attachNameEn3,
      attachPathEn1,
      attachPathEn2,
      attachPathEn3,
      attachNameKo1,
      attachNameKo2,
      attachNameKo3,
      attachPathKo1,
      attachPathKo2,
      attachPathKo3,
      noticeId,
    } = currentData;
    let formData = {};
    if (imageEnRef.current.files.length !== 0) {
      formData = { ...formData, imageEn: imageEnRef.current.files[0] };
    } else {
      formData = { ...formData, noticeImageEn };
    }
    if (imageKoRef.current.files.length !== 0) {
      formData = { ...formData, imageKo: imageKoRef.current.files[0] };
    } else {
      formData = { ...formData, noticeImageKo };
    }
    if (attachEnRef1.current.files.length !== 0) {
      formData = { ...formData, attachEn1: attachEnRef1.current.files[0] };
    } else {
      formData = { ...formData, attachNameEn1, attachPathEn1 };
    }
    if (attachEnRef2.current.files.length !== 0) {
      formData = { ...formData, attachEn2: attachEnRef2.current.files[0] };
    } else {
      formData = { ...formData, attachNameEn2, attachPathEn2 };
    }
    if (attachEnRef3.current.files.length !== 0) {
      formData = { ...formData, attachEn3: attachEnRef3.current.files[0] };
    } else {
      formData = { ...formData, attachNameEn3, attachPathEn3 };
    }
    if (attachKoRef1.current.files.length !== 0) {
      formData = { ...formData, attachKo1: attachKoRef1.current.files[0] };
    } else {
      formData = { ...formData, attachNameKo1, attachPathKo1 };
    }
    if (attachKoRef2.current.files.length !== 0) {
      formData = { ...formData, attachKo2: attachKoRef2.current.files[0] };
    } else {
      formData = { ...formData, attachNameKo2, attachPathKo2 };
    }
    if (attachKoRef3.current.files.length !== 0) {
      formData = { ...formData, attachKo3: attachKoRef3.current.files[0] };
    } else {
      formData = { ...formData, attachNameKo3, attachPathKo3 };
    }
    formData = {
      ...formData,
      noticeTitleEn: inputTitleEn,
      noticeTitleKo: inputTitleKo,
      noticeContentsEn: inputContentsEn,
      noticeContentsKo: inputContentsKo,
      noticeContentsPlace: isContentsBottom ? 1 : 0,
      fixStatus: isFixChecked ? 1 : 0,
      noticeId,
    };

    setNoticeData(formData);
  };

  const onClickPreviewButton = () => {
    const { noticeImageEn, noticeImageKo } = currentData;

    if (inputTitleEn === "" || inputTitleKo === "") {
      alert("Please input both EN and KO title.");
      return;
    }
    if (inputContentsEn === "" && inputContentsKo !== "") {
      alert("Please input both EN and KO contents.");
      return;
    }
    if (inputContentsKo === "" && inputContentsEn !== "") {
      alert("Please input both EN and KO contents.");
      return;
    }
    if (
      imageEnRef.current.files[0] === undefined &&
      noticeImageEn === null &&
      (imageKoRef.current.files[0] !== undefined || noticeImageKo !== null)
    ) {
      alert("Please input both EN and KO image.");
      return;
    }
    if (
      imageKoRef.current.files[0] === undefined &&
      noticeImageKo === null &&
      (imageEnRef.current.files[0] !== undefined || noticeImageEn !== null)
    ) {
      alert("Please input both EN and KO image.");
      return;
    }
    if (
      inputContentsEn === "" &&
      imageEnRef.current.files.length === 0 &&
      noticeImageEn === null
    ) {
      alert("Please input contents or image.");
      return;
    }

    goTop();
    setPreview();
  };

  return (
    <Wrapper>
      {currentData && currentData !== null && (
        <Fragment>
          <InputTag>Is this notice alaways on the top?</InputTag>
          <CloseCheckForm>
            <CloseCheckBox
              checked={isFixChecked}
              onClick={() => {
                setIsFixChecked(!isFixChecked);
              }}
              type={"checkbox"}
            />
            Check this box if you want this notice is always on the top of
            notice list.
          </CloseCheckForm>
          <InputTag>Title (EN/KO)</InputTag>
          <InputTitleEn
            placeholder={"Input title (EN)"}
            value={inputTitleEn}
            onChange={(event) => {
              setInputTitleEn(event.target.value);
            }}
          />
          <InputTitleKo
            placeholder={"Input title (KO)"}
            type={"text"}
            value={inputTitleKo}
            onChange={(event) => {
              setInputTitleKo(event.target.value);
            }}
          />
          <InputTag>Are contents placed below image?</InputTag>
          <CloseCheckForm>
            <CloseCheckBox
              checked={isContentsBottom}
              onClick={() => {
                setIsContentsBottom(!isContentsBottom);
              }}
              type={"checkbox"}
            />
            Check this box if you want to contents are placed below image.
          </CloseCheckForm>
          <InputTag>Contents (EN/KO)</InputTag>
          <InputContentsHelp>
            To create link with custom name, write as{" "}
            <strong>![name](link)</strong>
            .
            <br />
            Link must start with "<strong>http://</strong>" or "
            <strong>https://</strong>".
            <br />
            For example, <strong>![Stipop](https://stipop.io)</strong>
          </InputContentsHelp>
          <InputContentsEn
            placeholder={"Input contents (EN)"}
            value={inputContentsEn}
            onChange={(event) => {
              setInputContentsEn(event.target.value);
            }}
            rows={10}
          />
          <InputContentsKo
            placeholder={"Input contents (KO)"}
            value={inputContentsKo}
            onChange={(event) => {
              setInputContentsKo(event.target.value);
            }}
            rows={10}
          />
          <InputTag>Image Contents (EN)</InputTag>
          <InputCurrentFileBox>
            <InputCurrentFileText
              href={currentData.noticeImageEn}
              target={"_blank"}
            >
              Current :{" "}
              {currentData.noticeImageEn ? currentData.noticeImageEn : "Empty"}
            </InputCurrentFileText>
            {currentData.noticeImageEn && (
              <InputCurrentFileRemove
                onClick={() => {
                  setCurrentData({ ...currentData, noticeImageEn: null });
                }}
              />
            )}
          </InputCurrentFileBox>
          <InputImage
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={imageEnRef}
          />
          <InputTag>Image Contents (KO)</InputTag>
          <InputCurrentFileBox>
            <InputCurrentFileText
              href={currentData.noticeImageKo}
              target={"_blank"}
            >
              Current :{" "}
              {currentData.noticeImageKo ? currentData.noticeImageKo : "Empty"}
            </InputCurrentFileText>
            {currentData.noticeImageKo && (
              <InputCurrentFileRemove
                onClick={() => {
                  setCurrentData({ ...currentData, noticeImageKo: null });
                }}
              />
            )}
          </InputCurrentFileBox>
          <InputImage
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={imageKoRef}
          />
          <InputTag>Attach File (EN)</InputTag>
          <InputCurrentFileBox>
            <InputCurrentFileText
              href={currentData.attachPathEn1}
              target={"_blank"}
            >
              Current :{" "}
              {currentData.attachPathEn1 ? currentData.attachPathEn1 : "Empty"}
            </InputCurrentFileText>
            {currentData.attachPathEn1 && (
              <InputCurrentFileRemove
                onClick={() => {
                  setCurrentData({ ...currentData, attachPathEn1: null });
                }}
              />
            )}
          </InputCurrentFileBox>
          <InputCurrentFileBox>
            <InputCurrentFileText
              href={currentData.attachPathEn2}
              target={"_blank"}
            >
              Current :{" "}
              {currentData.attachPathEn2 ? currentData.attachPathEn2 : "Empty"}
            </InputCurrentFileText>
            {currentData.attachPathEn2 && (
              <InputCurrentFileRemove
                onClick={() => {
                  setCurrentData({ ...currentData, attachPathEn2: null });
                }}
              />
            )}
          </InputCurrentFileBox>
          <InputCurrentFileBox>
            <InputCurrentFileText
              href={currentData.attachPathEn3}
              target={"_blank"}
            >
              Current :{" "}
              {currentData.attachPathEn3 ? currentData.attachPathEn3 : "Empty"}
            </InputCurrentFileText>
            {currentData.attachPathEn3 && (
              <InputCurrentFileRemove
                onClick={() => {
                  setCurrentData({ ...currentData, attachPathEn3: null });
                }}
              />
            )}
          </InputCurrentFileBox>
          <InputFile
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={attachEnRef1}
          />
          <InputFile
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={attachEnRef2}
          />
          <InputFile
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={attachEnRef3}
          />
          <InputTag>Attach File (KO)</InputTag>
          <InputCurrentFileBox>
            <InputCurrentFileText
              href={currentData.attachPathKo1}
              target={"_blank"}
            >
              Current :{" "}
              {currentData.attachPathKo1 ? currentData.attachPathKo1 : "Empty"}
            </InputCurrentFileText>
            {currentData.attachPathKo1 && (
              <InputCurrentFileRemove
                onClick={() => {
                  setCurrentData({ ...currentData, attachPathKo1: null });
                }}
              />
            )}
          </InputCurrentFileBox>
          <InputCurrentFileBox>
            <InputCurrentFileText
              href={currentData.attachPathKo2}
              target={"_blank"}
            >
              Current :{" "}
              {currentData.attachPathKo2 ? currentData.attachPathKo2 : "Empty"}
            </InputCurrentFileText>
            {currentData.attachPathKo2 && (
              <InputCurrentFileRemove
                onClick={() => {
                  setCurrentData({ ...currentData, attachPathKo2: null });
                }}
              />
            )}
          </InputCurrentFileBox>
          <InputCurrentFileBox>
            <InputCurrentFileText
              href={currentData.attachPathKo3}
              target={"_blank"}
            >
              Current :{" "}
              {currentData.attachPathKo3 ? currentData.attachPathKo3 : "Empty"}
            </InputCurrentFileText>
            {currentData.attachPathKo3 && (
              <InputCurrentFileRemove
                onClick={() => {
                  setCurrentData({ ...currentData, attachPathKo3: null });
                }}
              />
            )}
          </InputCurrentFileBox>
          <InputFile
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={attachKoRef1}
          />
          <InputFile
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={attachKoRef2}
          />
          <InputFile
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={attachKoRef3}
          />
          <DefaultButton
            style={{ width: 120, marginTop: 20 }}
            theme={"blue"}
            btnText={"Show Preview"}
            onClick={() => {
              onClickPreviewButton();
            }}
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default MessageNoticeEditForm;
