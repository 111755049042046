import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import List from "../Common/List";
import SearchText from "../Common/SearchText";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import moment from "moment";

const defaultEndDate = new Date();
const defaultStartDate = new Date(
    defaultEndDate.getTime() - 7 * 24 * 60 * 60 * 1000
);


const SearchButton = styled.button`
    z-index: 2;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    box-shadow: none;
    border-width: 1px;
    position: relative;
    background-color: #00c0ef;
    border-color: #00acd6;
    border: 1px solid transparent;
    color: #fff;
`;

const SearchHistory = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState(["검색어", "검색횟수", "검색결과수"]); 
    const [dataList, setDataList] = useState([]);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [count, setCount] = useState(15);
    // const [keyword, SearchInput, setState] = SearchText("", search, setLang);

    const customComponent = () => {
        return (
            <List fieldList={filedList} dataList={dataList} />
        )
    }
    
    const header = () => {
        return (
            <>
                start date<ReactDatePicker dateFormat="yyyy/MM/dd" selected = {startDate} onChange={(date) => setStartDate(date)}/>
                end date<ReactDatePicker dateFormat="yyyy/MM/dd" selected = {endDate} onChange={(date) => setEndDate(date)}/>
                기준 검색결과수(default:15)<input type="text" value={count} onChange={(e) => setCount(e.target.value) }/>
                <SearchButton onClick={search}>검색</SearchButton>
            </>
        )
    }

    const search = async () => {
        console.log(moment(startDate).format('YYYY-MM-DD'));
        console.log(moment(endDate).format('YYYY-MM-DD'));
        console.log(count);

        const params = `?count=${count}&startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`;

        const response = await fetch(`/new/webAdmin/v2/b2b/search/history${params}`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'get'
        });

        const json = await response.json();

        if (json.status === "fail") props.history.push("/");

        if (json.body.searchHistory) {
            setDataList(json.body.searchHistory);
        } else {
            setDataList([]);
        }
        
        

    }

    useEffect(() => {
        // componentDidMount
        setIsLoading(false);
        // const getData = async () => {
        //   await getSdkInfo();
        //   setIsLoading(false);
        // };
        // getData();
        return async () => {
          //componentWillUnmount
        };
    }, []);

    if (!isLoading) {
        return (
            <>
                <Header title={"Search History"} />
                <Body HeaderComponent={header()} DataComponet={customComponent} />
            </>
        )
    } else {
        return <Loading />;
    }

}

export default withRouter(SearchHistory);