import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import List from "../Common/SearchList";
import { EditList } from "../Common/EditList";
import SearchText from "../Common/SearchTextNew";
import { client } from "../../Typesense/client";
import { pageMapSetting } from "../../Common/Utils";

const searchApiNew = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState([]); 
    const [dataList, setDataList] = useState([]);
    const [collection, setCollection] = useState("package");
    const [queryBy, setQueryBy] = useState("packageName");
    const [pageMap, setPageMap] = useState({});
    const [pageNumber, setPageNumber] = useState(props.pageNumber ? props.pageNumber : 1);
    const [q, setQ] = useState(props.search ? props.search : '');
    const [autoList, setAutoList] = useState([]);
    const [sort, setSort] = useState('');
    const [qBy, setQby] = useState([]);
    const [weight, setWeight] = useState([]);
    const [filter, setFilter] = useState('');

    
    const search = async (searchText) => { 
console.log(qBy)
        setState(searchText);

        const searchParameters = {
             'q'                     : `${searchText}`
            ,'query_by'              : `${qBy.map((item, index) => item)}`
            ,'query_by_weights'      : `${weight.map((item, index) => item)}`
            ,'filter_by'             : `${filter}`
            ,'sort_by'               : `${sort}`
            ,'page'                  : `${pageNumber}`
            ,'per_page'              : '100'
            ,'highlight_start_tag'   : '<b>'
            ,'highlight_end_tag'     : '</b>'
        };

        const searchParametersAuto = {
             'q'                     : `${searchText}`
            ,'query_by'              : `${qBy.map((item, index) => item)}`
            ,'query_by_weights'      : `${weight.map((item, index) => item)}`
            ,'filter_by'             : `${filter}`
            ,'sort_by'               : `${sort}`
            ,'page'                  : `${pageNumber}`
            ,'per_page'              : '5'
            ,'highlight_start_tag'   : '<b>'
            ,'highlight_end_tag'     : '</b>'
        };

        console.log(searchParameters)

        const result = await client.collections(collection).documents().search(searchParameters);
        const auto = await client.collections(collection).documents().search(searchParametersAuto);

        if (result.hits) {

            if (filedList.length === 0) {
                
                let fileds = []
                
                if (result.hits.length !== 0) {
                    for (const tmp in result.hits[0].document) {
                        fileds.push(tmp);
                    }
                    setFiledList(fileds);
                }
            }
            
            setPageMap(pageMapSetting(pageNumber, result.found, result.request_params.per_page, 'searchApiNew'));
            setDataList(result.hits);
            setAutoList(auto.hits);
            setQ(searchText);

        } else {
            setDataList([]);
        }

    }; 

    const [keyword, SearchInput, setState] = SearchText(q, search, collection, setCollection, queryBy, setQueryBy, setQ, autoList, sort, setSort, qBy, setQby, weight, setWeight, filter, setFilter);

    const customComponent = useCallback(() => {
        return (
            <List fieldList={filedList} dataList={dataList} />
        )
    }, [dataList]);


    useEffect(() => { 
        // componentDidMount

        const fetchData = async () => {
            q && await search(q);
            setIsLoading(false);
        }

        fetchData();

        return async () => {
          //componentWillUnmount
        };
    }, []);

    

    if (!isLoading) {
        return (
            <>
                <Header title={"search Api (디폴트 정렬 정확도순)"} />
                <Body HeaderComponent={SearchInput} headerWidth={"100%"} DataComponet={customComponent} pageMap={pageMap} search={keyword} />
            </>
        )
    } else {
        return <Loading />;
    }
}

export default withRouter(searchApiNew);