import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { BsFillArrowUpLeftCircleFill } from "react-icons/bs";

const KeywordListWrapper = styled.div`
    padding: 10px;
    display: flex;
    width: 80vw;
    white-space: nowrap;
    overflow: auto;
`;

const Tag = styled.div`
    background-color: #D7492A;
    color: #FFFFFF;
    border-radius: 15px;
    padding: 10px;
    margin-right: 5px;
`;

const EditLink = styled.button`
    border: none;
    outline: none;
    color: #EF7456;
    background-color: #FFFFFF;

    &:hover {
        color: #DB6B50;
    }
`

const KeywordList = ({recommendList}) => {
    const history = useHistory();

    const sendRcommendPage = () => {
        history.push('/Recommend');
    }
    return(
        <div>
            <EditLink onClick={sendRcommendPage}>
                <BsFillArrowUpLeftCircleFill fa-3x="true" style={{marginRight:'5px'}}/>
                <span>recommend keyword edit page </span>
            </EditLink>
            <KeywordListWrapper style={{marginLeft:'15px'}}>
                {recommendList && recommendList.map((tag, index)=>{
                    return(
                        <Tag key={tag+index}>{tag.keyword}</Tag>
                    );
                })}
            </KeywordListWrapper>
        </div>
    );
};

export default KeywordList;