import React from "react";
import MarketingDashboard from "../../Components/Dashboard/MarketingDashboard";
// import LeftMenu from "../../Components/LeftMenu";

const MarketingDashboardPage = ({ match, location }) => {
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <MarketingDashboard />
    </React.Fragment>
  );
};

export default MarketingDashboardPage;
