import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'
import BannerList from './BannerList'
import StickerList from './StickerList'
import TrioCard from './TrioCard'
import Artists from './Artists'
import MdCard from './MdCard'
import Monthly from './Monthly'
import MiniCard from './MiniCard'
import TopStickers from './TopStickers'
import Search from './Search'
import LoadingSpinner from '../Common/Loading/LoadingSpinner'

const AppPreview = props => {
  const {
    homeStickers,
    newStickers,
    searchStickers,
    topStickers,
    setIsNewBanner,
    page,
    isLoading,
  } = props

  return (
    <>
      <Wrapper>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <SpaceResponsive height={16} />
            {page === 'home' ? (
              <>
                <Logo src="https://img.stipop.io/image/b2b/stipop-iphone-logo.png" />
                <SpaceResponsive height={18} />
                {homeStickers &&
                  homeStickers.map((item, index) =>
                    item.collectionName === 'main' ? (
                      <BannerList
                        key={index}
                        page={page}
                        collection={item.collectionName}
                        bannerList={item.collection}
                      />
                    ) : item.collectionName === 'card' ||
                      item.collectionName === 'center card' ||
                      item.collectionName === 'free' ? (
                      <StickerList
                        key={index}
                        title={item.title}
                        stickerList={item.collection}
                      />
                    ) : item.collectionName === 'trio card' ? (
                      <TrioCard
                        key={index}
                        title={item.title}
                        stickerList={item.collection}
                      />
                    ) : item.collectionName === 'artist' ? (
                      <Artists
                        key={index}
                        title={item.title}
                        artistList={item.collection}
                      />
                    ) : item.collectionName === 'md card' ? (
                      <MdCard
                        key={index}
                        title={item.title}
                        stickerList={item.collection}
                      />
                    ) : item.collectionName === 'spotlight' ? (
                      <BannerList
                        key={index}
                        page={page}
                        collection={item.collectionName}
                        title={item.title}
                        bannerList={item.collection}
                      />
                    ) : item.collectionName === 'mini cards' ? (
                      <MiniCard
                        key={index}
                        page={page}
                        title={item.title}
                        stickerList={
                          item.collection.length > 0
                            ? item.collection
                            : newStickers.newList.newPackageList
                        }
                      />
                    ) : (
                      item.collectionName === 'top table' && (
                        <TopStickers
                          key={index}
                          title={'Top stickers'}
                          topStickers={topStickers}
                        />
                      )
                    )
                  )}
              </>
            ) : page === 'new' ? (
              <>
                <SpaceResponsive height={10} />
                <div
                  style={{
                    fontSize: '24px',
                    fontFamily: 'Poppins',
                    fontWeight: '800',
                    lineHeight: '22px',
                    letterSpacing: '-0.17px',
                  }}
                >
                  New
                </div>
                <SpaceResponsive height={12.5} />
                {newStickers && (
                  <>
                    {newStickers.collectionList.map((item, index) => (
                      <BannerList
                        key={index}
                        page={page}
                        bannerList={item.collection}
                        setIsNewBanner={setIsNewBanner}
                      />
                    ))}
                    <MiniCard
                      page={page}
                      title={'Newly released stickers'}
                      stickerList={newStickers.newList}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <SpaceResponsive height={10} />
                <div
                  style={{
                    fontSize: '24px',
                    fontFamily: 'Poppins',
                    fontWeight: '800',
                    lineHeight: '22px',
                    letterSpacing: '-0.17px',
                  }}
                >
                  Search
                </div>
                <SpaceResponsive height={12.5} />
                <Search searchStickers={searchStickers} />
              </>
            )}
            <SpaceResponsive height={44} />
          </>
        )}
      </Wrapper>
    </>
  )
}

export default AppPreview

const Wrapper = styled.div`
  width: 375px;
  height: 100%;
  border: 1px solid black;
  border-radius: 10px;
  padding-left: 16px;
  box-sizing: border-box;
  background-color: #f8f8f8;
  overflow-y: auto;
  scrollbar-color: transparent transparent;
  scrollbar-width: 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }
`

const Logo = styled.img`
  width: 144px;
  height: 25px;
  margin-left: 4px;
`
