import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'

/* ArtistList 에서 그룹 수정 버튼 클릭 시 열리는 Modal */

const ArtistGroupEditPopup = (props) => {
    const [didChange, setDidChange] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const handleSubmit = (SEQ, NAME) => {
        if(NAME.length){
            props.editGroup({SEQ: SEQ, NAME: NAME});
            setDidChange(true);
        } else {
            setIsEmpty(true);
        }
    }
    
    const handleClose = () => {
        resetModal();
        props.setShow(false);
    }

    const resetModal = () => {
        setDidChange(false);
        setIsEmpty(false);
    }

    const artistGroupInfo = () => {
        return (
            <React.Fragment>
                { props.artistGroupList &&
                <table style={{width: '100%', border: '1px solid #444444'}}>
                    <thead style={{border: '1px solid #444444'}}>
                        <tr>
                            <th style={{padding : '5px', border: '1px solid #444444'}}>No.</th>
                            <th style={{padding : '5px', border: '1px solid #444444'}}>Group Name</th>
                            <th style={{padding : '5px', border: '1px solid #444444'}}>New Name</th>
                            <th style={{padding : '5px', border: '1px solid #444444'}}></th>
                            <th style={{padding : '5px', border: '1px solid #444444'}}></th>
                        </tr>
                    </thead>
                    <tbody >
                        {props.artistGroupList.map(({SEQ, NAME}) => (
                            <tr key={SEQ}>
                                <td style={{padding : '5px', border: '1px solid #444444'}}>{SEQ}</td>
                                <td style={{padding : '5px', border: '1px solid #444444'}}>{NAME}</td>
                                <td style={{padding : '5px', border: '1px solid #444444'}}><input id={"newGroupName"+SEQ} type="text" defaultValue={NAME} style={{ width : '100%' }} onChange={resetModal}/></td>
                                <td style={{padding : '5px', border: '1px solid #444444'}}><button onClick={()=>handleSubmit(SEQ, document.getElementById('newGroupName'+SEQ).value)} style={{ width : "100%" }}>변경</button></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>}
            </React.Fragment>
        );
    }
    
    return (
        <Modal show={props.show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>작가 그룹 정보 수정</Modal.Title>
            </Modal.Header>
            <Modal.Body>        
                <font size={3}>{artistGroupInfo()}</font>
                    {isEmpty ? <p style={{color:"red", fontSize:'small'}}>ERROR: 그룹 이름은 1글자 이상이어야 합니다.</p> : ""}
                    {didChange ? <p style={{color:"forestgreen", fontSize:'small'}}>NOTICE: 그룹 이름이 변경되었습니다.</p> : ""}
                </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-default pull-right" onClick={handleClose}>닫기</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ArtistGroupEditPopup;