import React, { useEffect } from 'react';
import styled from 'styled-components';
import BannerItem from './BannerItem';

const BannerWrapper = styled.div`
    display: flex;
    align-item: left;
    width: 100vw;
    white-space: nowrap;
    overflow: auto;
    color: #33486F;
`

const BannerTitle = styled.div`
    color: #33486F;
`

const BannerList = ({bannerList}) => {
    return(
        <div>
            <BannerTitle>Banner Image List</BannerTitle>
            <BannerWrapper>
                {bannerList && bannerList.map((banner, index)=>{
                    return(
                        <BannerItem key={index} banner={banner}/>
                    );
                })}
            </BannerWrapper>
        </div>
    );
};

export default BannerList;