import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getPricingPlan } from './OverviewList';

const ModalWrapper = styled.div`
  display: ${({open})=>open?'flex':'none'};
  flex-direction: column;
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #d2d6de;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  padding: 30px;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 25px;

  span {
    font-size: 30px;
    font-weight: bold;
  }
`

const ModalButton = styled.div`
  display: flex;
  border-radius: 5px;
  border: 1px solid #d2d6de;
  padding: 5px 10px;
  height: fit-content;

  :hover{
    background-color: #d2d6de;
  }
`

const ModalRow = styled.div`
  display: flex;
  /* margin: 5px 0; */
`

const ModalCol = styled.div`
  display: flex;
  flex-direction: column;
	word-break: break-all;
  word-wrap: break-word;
  white-space: pre-line;

  span{
    margin: 10px 0;
  }

  ${({title})=>{
    if(title===true){
      return `
        width: 30%;
        border-right: 1px solid #d2d6de;
        margin-right: 10px; 
      `
    }
    else {
      return `
        width: 70%;
        margin-left: 10px;
      `
    }
  }}

  button{
    position: absolute;
    bottom: 0;
    right: 0;
    border: none;
    border-radius: 5px;
    background-color: #ff4500;
    color: #fff;
    padding: 5px 10px;
    font-weight: bold;
    :hover{
      opacity: 0.8;
    }
  }
  input{
    margin-right: 20px;
  }
`

const OverviewModal = ({ open, setOpen, info, setInfo, date}) => {
  const [plan, setPlan] = useState(String(info.plan).toLowerCase())

  useEffect(()=>{
    setPlan(String(info.plan).toLowerCase())
  }, [info])
  const clickClose = () => {
    setOpen(false);
  }
  
  const updatePlan = async() => {
    if(plan!==''){
      // update plan api
      // -> setInfo
      // setInto(prev=> {...prev, plan: plan});
      await axios.put(`/new/webAdmin/v2/pricing`, 
      {
        appId: info.id,
        plan: plan
      },
      {
        headers: {
          'token': window.sessionStorage.getItem("token")
        }
      })
        .then(async({data})=>{
          if(data.status==='success'){
            let planName = await getPricingPlan(info.id, date, info.api_cnt);
            setInfo({...info, plan: planName})
            alert(`Changed to plan ${plan}`)
          }
          else{
            alert(`error: 관리자에게 문의바람`)
          }
        })
        .catch((err)=>{
          console.log(err)
        })
    }
  }

  return(
    <ModalWrapper open={open}>
      <HeaderRow onClick={clickClose}>
        <span>{info.name} App Info</span>
        <ModalButton>close</ModalButton>
      </HeaderRow>
      <ModalRow>
        <ModalCol title={true}><span>앱 이름</span></ModalCol>
        <ModalCol title={false}><span>{info.name}</span></ModalCol>
      </ModalRow>
      <ModalRow>
        <ModalCol title={true}><span>앱 아이디</span></ModalCol>
        <ModalCol title={false}><span>{info.id}</span></ModalCol>
      </ModalRow>
      <ModalRow>
        <ModalCol title={true}><span>앱 생성자 이름</span></ModalCol>
        <ModalCol title={false}><span>{info.creatorName}</span></ModalCol>
      </ModalRow>
      <ModalRow>
        <ModalCol title={true}><span>카드 추가 유무</span></ModalCol>
        <ModalCol title={false}><span>{info.card}</span></ModalCol>
      </ModalRow>
      <ModalRow>
        <ModalCol title={true}><span>API / SDK</span></ModalCol>
        <ModalCol title={false}><span>{info.api_sdk}</span></ModalCol>
      </ModalRow>
      <ModalRow>
        <ModalCol title={true}><span>Plan</span></ModalCol>
        <ModalCol title={false}><span>{info.plan}</span></ModalCol>
      </ModalRow>
      <ModalRow>
        <ModalCol title={true}></ModalCol>
        <ModalCol title={false}>
          <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
            <div>
              <input onChange={()=>setPlan('starter')} type='radio' id='starter' name='plan-type' value='starter' checked={plan.includes('starter')}/>
              <lable>Starter</lable>
            </div>
            <div>
              <input onChange={()=>setPlan('pro')} type='radio' id='pro' name='plan-type' value='pro' checked={plan.includes('pro')}/>
              <label>Pro</label>
            </div>
            <button onClick={()=>{updatePlan()}}>update</button>
          </div>
        </ModalCol>
      </ModalRow>
    </ModalWrapper>
  )
}

export default OverviewModal;