import React from 'react'
import AIUploadView from '../../Components/AI/AIUploadView'
import queryString from 'query-string'

const AIUploadPage = ({ match, location }) => {
  const query = queryString.parse(location.search)

  return (
    <AIUploadView
      pageNumber={query.pageNumber}
      limit={query.limit}
      d={query.d}
      l={query.l}
      cnt={query.cnt}
    />
  )
}

export default AIUploadPage
