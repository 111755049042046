import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Loading from '../../Components/Common/Loading/Loading'
import PageTitle from '../../_Components/Common/PageTitle/PageTitle'
import BorderBox from '../../_Components/Common/BoxUI/BorderBox'
import KeywordAddForm from '../../_Components/Keyword/UntaggedList/KeywordAddForm'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
`

const KeywordUntaggedAddPage = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      setIsLoading(false)
    } else {
      history.push('/')
    }
  }, [])

  return (
    <Wrapper>
      <PageTitle
        mainTitle={'Untagged List - Add'}
        v1Link={''}
        navNames={['Management', 'Keywords', 'Untagged List', 'Add']}
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <Fragment>
          <BorderBox
            boxTitle={'Keyword Add Form'}
            component={<KeywordAddForm />}
          />
        </Fragment>
      )}
    </Wrapper>
  )
}

export default KeywordUntaggedAddPage
