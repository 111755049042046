import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Dropdown = styled.div`
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
`;


const Componeent = ({date, dateList, setDate, text}) => {
    return (
        <>
            <Dropdown>
                <select onChange={(e) => setDate(e.target.value)} >
                    {date==="" && <option key={999} value={''}>월선택</option>}
                    {
                        dateList && dateList.map((date, i) => <option key={i} value={date.month}>{date.month}</option> )
                    }
                </select>
            </Dropdown>
            <div style={{marginLeft: 15}}>
                {text}
            </div>
        </>
    )
};

const Lang = (date, dateList, setDate, text="") => {
    
    const [state, setState] = useState(date);

    return [
        <Componeent date={state} dateList={dateList} setDate={setDate} text={text} />
        ,setState
    ]

};

export default Lang;