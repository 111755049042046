import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import styled from 'styled-components'

const AIHomeEdit = props => {
  const { getBanner, lang, setLang, bannerList, setBannerList } = props
  const [checked, setChecked] = useState([])
  const [edit, setEdit] = useState(0)
  const [preview, setPreview] = useState('')
  const [params, setParams] = useState({
    link: '',
    img: null,
  })

  const checkRef = useRef([])

  const init = async () => {
    await setEdit(0)
    await setChecked([])
    await setPreview('')
    await setParams({
      link: '',
      img: null,
    })
    await getBanner(lang)

    if (checkRef.current) {
      for (let i = 0; i < checkRef.current.length; i++) {
        if (checkRef.current[i] && checkRef.current[i].checked) {
          checkRef.current[i].checked = false
        }
      }
    }
  }

  useEffect(() => {
    init()
  }, [lang])

  useEffect(() => {
    if (edit > 0) {
      const selected = bannerList.filter(item => item.SEQ === edit)[0]
      setParams({
        ...params,
        status: selected.STATUS,
        link: selected.LINK,
        img: selected.IMG,
      })
    } else {
      setParams({
        link: '',
        img: null,
      })
    }
  }, [edit])

  const handleCheck = e => {
    if (e.target.checked) {
      setChecked(checked.concat(e.target.value))
    } else {
      setChecked(checked.filter(id => id !== e.target.value))
    }
  }

  const updateStatus = status => {
    axios
      .put(
        `/new/webAdmin/v2/ai/home/banner/status`,
        {
          updateList: checked,
          status,
        },
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        init()
      })
  }

  const clickSave = async id => {
    let formData = new FormData()
    await formData.append('lang', lang)
    await formData.append('link', params.link)

    if (preview) {
      await formData.append('img', params.img)
    }

    if (id > 0) {
      // 수정
      await formData.append('status', params.status)
      await axios
        .put(`/new/webAdmin/v2/ai/home/banner/${id}`, formData, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(() => {
          init()
        })
    } else {
      // 추가
      await axios
        .post(`/new/webAdmin/v2/ai/home/banner`, formData, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(() => {
          init()
        })
    }
  }

  const clickDelete = async () => {
    if (checked.length > 0) {
      await axios
        .delete(`/new/webAdmin/v2/ai/home/banner`, {
          data: {
            deleteList: checked,
          },
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(() => {
          init()
        })
      setChecked([])
    }
  }

  const reorderPromise = (id, order) => {
    const params ={
      id,
      order
    }

    return new Promise(async (resolve, reject) => {
      axios.put(`/new/webAdmin/v2/ai/home/banner/order`, params, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
    })
  }

  const reorder = async list => {
    const result = await Promise.all(
      list.map((item , index) => reorderPromise(item.SEQ, index + 1))
    )

    if (result) {
      init()
    }
  }

  const moveOrder = async (idx, move) => {
    const updateData = [...bannerList]
    const target = updateData.splice(move === 'up' ? idx -1 : idx + 1, 1)[0]
    updateData.splice(idx, 0, target)

    await setBannerList(updateData)
    await reorder(updateData)
  }

  const renderEdit = () => {
    return (
      <tbody>
        <tr>
          <td>
            <input
              type="text"
              value={params.link}
              onChange={e => setParams({ ...params, link: e.target.value })}
            />
          </td>
          <td>
            {(preview || params.img) && (
              <img
                src={preview ? preview : params.img}
                width="375px"
                height="84px"
                alt=""
              />
            )}
            <input
              type={'file'}
              onChange={e => {
                if (e.target.files && e.target.files[0])
                  setParams({
                    ...params,
                    img: e.target.files[0],
                  })
                setPreview(URL.createObjectURL(e.target.files[0]))
              }}
            />
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <div style={{ minHeight: '100%' }}>
      <section className="content-header">
        <h1>AI Home</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/ContentsList">
              <i className="fa fa-dashboard"></i>AI
            </a>
          </li>
          <li className="active">Home</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <section className="col-lg-12 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  paddingBottom: '30px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%', display: 'flex' }}>
                  <div style={{ width: '100%' }}>
                    <span>Lang</span>
                    <select
                      style={{ display: 'inline-block', margin: '0 20px' }}
                      value={lang}
                      onChange={e => {
                        setLang(e.target.value)
                      }}
                    >
                      {new Array('en', 'ko', 'es', 'pt').map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  {edit !== 0 ? (
                    <div style={{ display: 'flex', gap: '0 10px' }}>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          clickSave(edit)
                        }}
                      >
                        저장
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          init()
                        }}
                      >
                        취소
                      </button>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', gap: '0 10px' }}>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          updateStatus('Y')
                        }}
                      >
                        Activate
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          updateStatus('N')
                        }}
                      >
                        Deactivate
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setEdit(-1)
                        }}
                      >
                        추가
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          clickDelete()
                        }}
                      >
                        삭제
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <Table className="table no-margin">
                    <thead>
                      <tr>
                        {edit === 0 && (
                          <>
                            <th>No</th>
                            <th></th>
                            <th>SEQ</th>
                            <th>STATUS</th>
                          </>
                        )}
                        <th>LINK</th>
                        <th>IMG</th>
                      </tr>
                    </thead>
                    {edit !== 0 ? (
                      renderEdit()
                    ) : (
                      <tbody>
                        {bannerList &&
                          bannerList
                            .map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <input
                                    ref={el => (checkRef.current[index] = el)}
                                    type="checkbox"
                                    onChange={handleCheck}
                                    value={item.SEQ}
                                  />
                                </td>
                                <td>{item.SEQ}</td>
                                <td>{item.STATUS}</td>
                                <td>{item.LINK}</td>
                                <td>
                                  <img
                                    src={item.IMG}
                                    width="375px"
                                    height="84px"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      setEdit(item.SEQ)
                                    }}
                                  >
                                    Edit
                                  </button>
                                </td>
                                <td>
                                  {index !== 0 && (
                                    <button
                                      className="btn btn-default"
                                      onClick={() => {
                                        moveOrder(index, 'up')
                                      }}
                                    >
                                      ▲
                                    </button>
                                  )}
                                  {index !== bannerList.length - 1 && (
                                    <button
                                      className="btn btn-default"
                                      onClick={() => {
                                        moveOrder(index, 'down')
                                      }}
                                    >
                                      ▼
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    )}
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default AIHomeEdit

const Table = styled.table`
  td {
    /* max-width: 300px; */
    white-space: break-spaces;
  }
`
