import React from "react";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Loading from "../Common/Loading/Loading";

class AThemeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      home: props.location.state.t_type,
      g_id: props.location.state.g_id,
      selectedOption: null,
      options: null,
      detail: null,
      visible: "",
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      fetch("/new/webAdmin/v2/packageList", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
          this.setState({
            options: responseJson.options,
            isLoading: false,
          });
        })
        .catch((error) => {
          console.error("123=" + error);
        });
    } else {
      this.props.history.push("/");
    }
  }
  _read = (packageid) => {
    fetch(`/new/webAdmin/v2/packageDetail/${packageid}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({ detail: responseJson.detail[0], isLoading: false });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _save = () => {
    if (this.state.home === null || this.state.detail === null) {
      alert("테마를 결정하세요");
      return;
    }

    const params = {
      p_id: this.state.detail.id,
      t_type: this.state.home,
      g_id: this.state.g_id,
    };
    fetch(`/new/webAdmin/v2/aThemeInsert`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        // this.props.history.push('/AThemeList');
        this.props.history.push({
          pathname: "/AThemeList",
          state: {
            t_type: this.state.home,
          },
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _setType = (value) => {
    this.setState({ home: value });
    const queryParams = "?lang=" + this.state.home;
    if (sessionStorage.getItem("token")) {
      fetch("/new/webAdmin/v2/packageList" + queryParams, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
          this.setState({
            options: responseJson.options,
            isLoading: false,
          });
        })
        .catch((error) => {
          console.error("123=" + error);
        });
    } else {
      this.props.history.push("/");
    }
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this._read(selectedOption.id);
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
        //  className="content-wrapper"
        >
          <section className="content-header">
            <h1>전송패키지 추가</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/AThemeList">
                  <i className="fa fa-dashboard"></i> 테마
                </a>
              </li>
              <li className="active">전송패키지 추가</li>
            </ol>
          </section>
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div className="box-header with-border"></div>
                  <div className="box-body">
                    {/* <!-- text input --> */}
                    <div className="form-group">
                      <label>타입</label>
                      <select
                        disabled="true"
                        className="form-control"
                        value={this.state.home}
                        onChange={(event) => this._setType(event.target.value)}
                      >
                        <option value="CO">Common</option>
                        <option value="EN">English</option>
                        <option value="KO">Korea</option>
                        <option value="ES">Espanol</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label>패키지 이름 검색</label>
                      <Select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                        options={this.state.options}
                      />
                    </div>

                    <div
                      className="form-group"
                      style={{ display: this.state.visible }}
                    >
                      <label>패키지 카테고리</label>
                      <input
                        value={
                          this.state.detail === null
                            ? ""
                            : this.state.detail.category
                        }
                        className="form-control"
                        disabled="true"
                      ></input>

                      <label>패키지 승인날짜</label>
                      <input
                        value={
                          this.state.detail === null
                            ? ""
                            : this.state.detail.commitdate
                        }
                        className="form-control"
                        disabled="true"
                      ></input>

                      <label>패키지 태그</label>
                      <input
                        value={
                          this.state.detail === null
                            ? ""
                            : this.state.detail.tags
                        }
                        className="form-control"
                        disabled="true"
                      ></input>

                      <label>패키지 등록언어</label>
                      <input
                        value={
                          this.state.detail === null
                            ? ""
                            : this.state.detail.lang
                        }
                        className="form-control"
                        disabled="true"
                      ></input>
                    </div>
                    <div className="box-footer">
                      <button
                        type="submit"
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/AThemeList",
                            state: { t_type: this.state.home },
                          })
                        }
                        className="btn btn-secondary"
                      >
                        목록으로
                      </button>
                      <button
                        type="submit"
                        onClick={this._save}
                        className="btn btn-primary"
                      >
                        등록하기
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- /.box-header --> */}
              </section>
            </div>
          </section>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default withRouter(AThemeList);
