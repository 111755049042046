import axios from "axios";

export const getPosts = async(params, headers) => {
  let result = {
    postList: null,
    endPage: null,
    mainPost: null
  }
  await axios.get(`https://api.stipop.io/v2/common/blog/post`,{
  // await axios.get(`http://13.125.75.42:3103/v2/common/blog/post`, {
  // await axios.get(`http://localhost:3003/v2/common/blog/post`,{
    params: params,
    headers: headers
  })
    .then(res => {
      if(res.data.status!=='fail'){
        result.mainPost = res.data.data.mainPost[0];
        result.postList = res.data.data.postList;
        result.endPage = res.data.data.pageMap.endPage;      
      }
    })
    .catch(err => {
      console.log(err)
    })

  return result;
}

export const getPostImages = async(id) => {
  let result = null;
  await axios.get(`https://api.stipop.io/v2/common/blog/post/images/${id}`)
  // await axios.get(`http://13.125.75.42:3103/v2/common/blog/post/images/${id}`)
  // await axios.get(`http://localhost:3003/v2/common/blog/post/images/${id}`)
    .then(res=>{
      // console.log(res)
      // setUrlFiles(res.data.data)
      result = res.data.data;
    })
    .catch(err=>{
      console.log(err)
    })
  
  return result;
}

export const checkTitleDuplication = async(title) => {
  let result = null;
  await axios.get(`/new/webAdmin/v2/blog/title-check/${title}`,{
    headers: {
      'token': window.sessionStorage.getItem("token"),
    },
  })
    .then(res=>{
      if(res.status===200 && res.data.status==='success'){
        // console.log(res.data.result.count)
        if(res.data.result.count > 0){
          // title duplicate
          result = 0;
        }
        else result = 1;
      }
    })
    .catch(err=>{
      console.log(err)
      result = 0;
    })

  return result;
}

export const createPost = async(formData, history) => {
  await axios.post('/new/webAdmin/v2/blog/post', formData, {
    headers: {
      'token': window.sessionStorage.getItem("token"),
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(res => {
      // console.log(res)
      if(res.data.status==="faile"){
        alert("post api 실패")
        // result = -1;
        return -1;
      }
      history.push('/Blog/done')
    })
    .catch(err => {
      console.log(err)
      window.alert('post api error')
      return -1;
    })
}

export const updatePost = async(formData, history) => {
  await axios.put('/new/webAdmin/v2/blog/post', formData, {
    headers: {
      'token': window.sessionStorage.getItem("token"),
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(res => {
      // console.log(res)
      if(res.data.status==="fail"){
        alert("edit api 실패")
        // console.log(res)
        return;
      }
      history.push('/Blog/done')
    })
    .catch(err => {
      console.log(err)
      window.alert('post api error')
    })
}

export const deletePost = async(id, history) => {
  let result = null;
  await axios.delete(`/new/webAdmin/v2/blog/post/${id}`, {
    headers: {
      'token': window.sessionStorage.getItem("token"),
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(res => {
      // console.log(res)
      if(res.data.status==="faile"){
        alert("delete api 실패")
        result = -1;
      }
      // history.push('/Blog/view')
      result = 1;
    })
    .catch(err => {
      console.log(err)
      window.alert('post api error')
      result = -1;
    })
  return result;
}