import React, { useEffect } from 'react'
import styled from 'styled-components'
import InputCopy from '../../../_Components/Curation/InputCopy'

const BannerItemBox = styled.div`
  width: 300px;
  height: 180px;
  background-color: ${props => props.color};
  border-radius: 20px;
  margin: 0 10px 5px 10px;
  img {
    width: 100%;
    height: 100%;
  }
`

const BannerItem = props => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
    >
      <BannerItemBox color={!props.mode ? props.lightColor : props.darkColor}>
        <img src={props.cardImg} alt="" />
      </BannerItemBox>
      <span style={{ color: !props.mode ? 'black' : 'white' }}>
        <InputCopy copyText={props.packageId} color={!props.mode ? 'black' : 'white'}/>
      </span>
    </div>
  )
}

export default BannerItem
