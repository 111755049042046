import React from "react";
import AThemeInsert from "../../Components/Container/AThemeInsert";
// import LeftMenu from "../../Components/LeftMenu";

const AThemeInsertPage = ({ match, location }) => {
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <AThemeInsert />
    </React.Fragment>
  );
};

export default AThemeInsertPage;
