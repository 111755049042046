import React from "react";
import SendCount from "../../Components/Tracking/SendCount";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const SendCountPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <SendCount date={query.date} />
    </React.Fragment>
  );
};

export default SendCountPage;
