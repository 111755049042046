import axios from "axios";
import React, { useState, Fragment } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { IoArrowBack } from "react-icons/io5";
import { AiOutlinePaperClip } from "react-icons/ai";
import AttachPreview from "../../Common/BoxUI/AttachPreviewBox";
import DefaultButton from "../../Common/ButtunUI/DefaultButton";
import Linkify from "react-linkify";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const HeaderTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid #e8e9ed;
`;

const HeaderTitleText = styled.div`
  width: 716px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  overflow-wrap: break-word;
`;

const HeaderBottomDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 30px;
`;

const HeaderTimeText = styled.div`
  font-family: RawlineMedium, sans-serif;
  font-size: 12px;
  color: #8f8f8f;
`;

const DetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const TextArea = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const NoticeText = styled.div`
  color: #0c0b24;
  line-height: 1.86;
`;

const MentionBottom = styled.div`
  display: flex;
  position: relative;
`;

const NoticeAttachArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const NoticeAttachBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
`;

const NoticeAttachIcon = styled(AiOutlinePaperClip)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 2px;
  background-color: #f7f9fc;
  color: #7b7b7b;
`;

const MentionAttachText = styled.div`
  color: #b3b3b3;
  margin-left: 4px;
`;

const ControlDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  letter-spacing: 2.4px;
  font-size: 12px;
`;

const BackListBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BackArrowIcon = styled(IoArrowBack)`
  color: #9fa3a7;
  margin-bottom: 1px;
`;

const BackListText = styled.div`
  color: #9fa3a7;
  margin-left: 8px;
`;

const MessageNoticeAddPreview = ({ previewData, goTop }) => {
  const [previewLang, setPreviewLang] = useState("en");

  const [isAttachHover, setIsAttachHover] = useState(-1);

  const history = useHistory();

  const tempDate = new Date().toLocaleString().split(". ");

  const subDate = `${tempDate[0]}.${
    tempDate[1].length === 1 ? `0${tempDate[1]}` : `${tempDate[1]}`
  }.${tempDate[2].length === 1 ? `0${tempDate[2]}` : `${tempDate[2]}`}`;

  const addOneNotice = async () => {
    const {
      imageEn,
      imageKo,
      attachEn1,
      attachEn2,
      attachEn3,
      attachKo1,
      attachKo2,
      attachKo3,
      titleEn,
      titleKo,
      contentsEn,
      contentsKo,
      contentsPlace,
      fixStatus,
    } = previewData;
    let formData = new FormData();
    if (imageEn !== undefined) formData.append("imageEn", imageEn);
    if (imageKo !== undefined) formData.append("imageKo", imageKo);
    if (attachEn1 !== undefined) formData.append("attachEn1", attachEn1);
    if (attachEn2 !== undefined) formData.append("attachEn2", attachEn2);
    if (attachEn3 !== undefined) formData.append("attachEn3", attachEn3);
    if (attachKo1 !== undefined) formData.append("attachKo1", attachKo1);
    if (attachKo2 !== undefined) formData.append("attachKo2", attachKo2);
    if (attachKo3 !== undefined) formData.append("attachKo3", attachKo3);
    formData.append("titleEn", titleEn);
    formData.append("titleKo", titleKo);
    formData.append("contentsEn", contentsEn);
    formData.append("contentsKo", contentsKo);
    formData.append("contentsPlace", contentsPlace ? 1 : 0);
    formData.append("fixStatus", fixStatus ? 1 : 0);

    const res = await axios.post(
      `/new/webAdmin/v2/message-center/notice`,
      formData,
      {
        headers: { token: sessionStorage.getItem("token") },
      }
    );
    if (res.status === 200) {
      history.push("/Message/Notice");
    }
  };

  const onClickSubmitButton = () => {
    const { imageEn, imageKo, titleEn, titleKo, contentsEn, contentsKo } =
      previewData;
    if (titleEn === "" || titleKo === "") {
      alert("Please input both EN and KO title.");
      return;
    }
    if (contentsEn === "" && contentsKo !== "") {
      alert("Please input both EN and KO contents.");
      return;
    }
    if (contentsKo === "" && contentsEn !== "") {
      alert("Please input both EN and KO contents.");
      return;
    }
    if (imageEn === undefined && imageKo !== undefined) {
      alert("Please input both EN and KO image.");
      return;
    }
    if (imageKo === undefined && imageEn !== undefined) {
      alert("Please input both EN and KO image.");
      return;
    }
    if (contentsEn === "" && imageEn === undefined) {
      alert("Please input contents or image.");
      return;
    }

    addOneNotice();
  };

  return (
    <Wrapper>
      {!previewData &&
        'Please input form left side, then click "Show Preview" button.'}
      {previewData && (
        <Fragment>
          <HeaderTitleBox>
            <HeaderTitleText>
              {previewLang === "en"
                ? previewData["titleEn"]
                : previewData["titleKo"]}
            </HeaderTitleText>
            <HeaderBottomDiv>
              <HeaderTimeText>{subDate}</HeaderTimeText>
            </HeaderBottomDiv>
          </HeaderTitleBox>
          {previewLang === "en" ? (
            <DetailDiv>
              {previewData.contentsEn !== undefined &&
                previewData.contentsEn !== null &&
                previewData.contentsPlace === 0 && (
                  <TextArea>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <NoticeText>
                        {previewData.contentsEn
                          .split("\n")
                          .map((line, index) => {
                            let resultLine = "";
                            const splitLine1 = line.split("![");
                            if (splitLine1.length > 1) {
                              const splitLink1 = splitLine1[1].split("]");
                              const splitLinkName = splitLink1[0];
                              const splitLink2 = splitLink1[1]
                                .split("(")[1]
                                .split(")");
                              const splitLinkPath = splitLink2[0];
                              const splitLine2 = splitLink2[1];
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    href={splitLinkPath}
                                    target={"_blank"}
                                  >
                                    {splitLinkName}
                                  </a>
                                  {splitLine2}
                                  <br />
                                </span>
                              );
                            } else {
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <br />
                                </span>
                              );
                            }
                            return resultLine;
                          })}
                      </NoticeText>
                    </Linkify>
                  </TextArea>
                )}
              {previewData.imageEn !== undefined &&
                previewData.imageEn !== null && (
                  <img
                    style={{ marginTop: 15, marginBottom: 15 }}
                    src={URL.createObjectURL(previewData.imageEn)}
                    alt={""}
                  />
                )}
              {previewData.contentsEn !== undefined &&
                previewData.contentsEn !== null &&
                previewData.contentsPlace === 1 && (
                  <TextArea>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <NoticeText>
                        {previewData.contentsEn
                          .split("\n")
                          .map((line, index) => {
                            let resultLine = "";
                            const splitLine1 = line.split("![");
                            if (splitLine1.length > 1) {
                              const splitLink1 = splitLine1[1].split("]");
                              const splitLinkName = splitLink1[0];
                              const splitLink2 = splitLink1[1]
                                .split("(")[1]
                                .split(")");
                              const splitLinkPath = splitLink2[0];
                              const splitLine2 = splitLink2[1];
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    href={splitLinkPath}
                                    target={"_blank"}
                                  >
                                    {splitLinkName}
                                  </a>
                                  {splitLine2}
                                  <br />
                                </span>
                              );
                            } else {
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <br />
                                </span>
                              );
                            }
                            return resultLine;
                          })}
                      </NoticeText>
                    </Linkify>
                  </TextArea>
                )}
              <MentionBottom>
                <NoticeAttachArea>
                  {previewData.attachEn1 !== undefined &&
                    previewData.attachEn1 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(previewData.attachEn1),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(0);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {previewData.attachEn1.name}
                        </MentionAttachText>
                        {isAttachHover === 0 && (
                          <AttachPreview
                            url={URL.createObjectURL(previewData.attachEn1)}
                          />
                        )}
                      </NoticeAttachBox>
                    )}
                  {previewData.attachEn2 !== undefined &&
                    previewData.attachEn2 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(previewData.attachEn2),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(1);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {previewData.attachEn2.name}
                        </MentionAttachText>
                        {isAttachHover === 1 && (
                          <AttachPreview
                            url={URL.createObjectURL(previewData.attachEn2)}
                          />
                        )}
                      </NoticeAttachBox>
                    )}
                  {previewData.attachEn3 !== undefined &&
                    previewData.attachEn3 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(previewData.attachEn3),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(2);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {previewData.attachEn3.name}
                        </MentionAttachText>
                        {isAttachHover === 2 && (
                          <AttachPreview
                            url={URL.createObjectURL(previewData.attachEn3)}
                          />
                        )}
                      </NoticeAttachBox>
                    )}
                </NoticeAttachArea>
              </MentionBottom>
            </DetailDiv>
          ) : (
            <DetailDiv>
              {previewData.contentsKo !== undefined &&
                previewData.contentsKo !== null &&
                previewData.contentsPlace === 0 && (
                  <TextArea>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <NoticeText>
                        {previewData.contentsKo
                          .split("\n")
                          .map((line, index) => {
                            let resultLine = "";
                            const splitLine1 = line.split("![");
                            if (splitLine1.length > 1) {
                              const splitLink1 = splitLine1[1].split("]");
                              const splitLinkName = splitLink1[0];
                              const splitLink2 = splitLink1[1]
                                .split("(")[1]
                                .split(")");
                              const splitLinkPath = splitLink2[0];
                              const splitLine2 = splitLink2[1];
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    href={splitLinkPath}
                                    target={"_blank"}
                                  >
                                    {splitLinkName}
                                  </a>
                                  {splitLine2}
                                  <br />
                                </span>
                              );
                            } else {
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <br />
                                </span>
                              );
                            }
                            return resultLine;
                          })}
                      </NoticeText>
                    </Linkify>
                  </TextArea>
                )}
              {previewData.imageKo !== undefined &&
                previewData.imageKo !== null && (
                  <img
                    style={{ marginTop: 15, marginBottom: 15 }}
                    src={URL.createObjectURL(previewData.imageKo)}
                    alt={""}
                  />
                )}
              {previewData.contentsKo !== undefined &&
                previewData.contentsKo !== null &&
                previewData.contentsPlace === 1 && (
                  <TextArea>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <NoticeText>
                        {previewData.contentsKo
                          .split("\n")
                          .map((line, index) => {
                            let resultLine = "";
                            const splitLine1 = line.split("![");
                            if (splitLine1.length > 1) {
                              const splitLink1 = splitLine1[1].split("]");
                              const splitLinkName = splitLink1[0];
                              const splitLink2 = splitLink1[1]
                                .split("(")[1]
                                .split(")");
                              const splitLinkPath = splitLink2[0];
                              const splitLine2 = splitLink2[1];
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    href={splitLinkPath}
                                    target={"_blank"}
                                  >
                                    {splitLinkName}
                                  </a>
                                  {splitLine2}
                                  <br />
                                </span>
                              );
                            } else {
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <br />
                                </span>
                              );
                            }
                            return resultLine;
                          })}
                      </NoticeText>
                    </Linkify>
                  </TextArea>
                )}
              <MentionBottom>
                <NoticeAttachArea>
                  {previewData &&
                    previewData.attachKo1 !== undefined &&
                    previewData.attachKo1 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(previewData.attachKo1),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(0);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {previewData.attachKo1.name}
                        </MentionAttachText>
                        {isAttachHover === 0 && (
                          <AttachPreview
                            url={URL.createObjectURL(previewData.attachKo1)}
                          />
                        )}
                      </NoticeAttachBox>
                    )}
                  {previewData &&
                    previewData.attachKo2 !== undefined &&
                    previewData.attachKo2 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(previewData.attachKo2),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(1);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {previewData.attachKo2.name}
                        </MentionAttachText>
                        {isAttachHover === 1 && (
                          <AttachPreview
                            url={URL.createObjectURL(previewData.attachKo2)}
                          />
                        )}
                      </NoticeAttachBox>
                    )}
                  {previewData &&
                    previewData.attachKo3 !== undefined &&
                    previewData.attachKo3 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(previewData.attachKo3),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(2);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {previewData.attachKo3.name}
                        </MentionAttachText>
                        {isAttachHover === 2 && (
                          <AttachPreview
                            url={URL.createObjectURL(previewData.attachKo3)}
                          />
                        )}
                      </NoticeAttachBox>
                    )}
                </NoticeAttachArea>
              </MentionBottom>
            </DetailDiv>
          )}
          <ControlDiv>
            <BackListBox onClick={() => {}}>
              <BackArrowIcon size={16} />
              <BackListText>
                {previewLang === "en" ? "LIST" : "목록으로 돌아가기"}
              </BackListText>
            </BackListBox>
          </ControlDiv>
          <ControlDiv>
            <DefaultButton
              style={{ width: 100, marginTop: 20 }}
              theme={"blue"}
              btnText={"Submit"}
              onClick={() => {
                onClickSubmitButton();
              }}
            />
            <DefaultButton
              style={{ width: 150, marginTop: 20 }}
              theme={"default"}
              btnText={previewLang === "en" ? "Language(EN)" : "Language(KO)"}
              onClick={() => {
                setPreviewLang(previewLang === "en" ? "ko" : "en");
                goTop();
              }}
            />
          </ControlDiv>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default MessageNoticeAddPreview;
