import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'

const CurationEdit = props => {
  const {
    curationList,
    edit,
    setEdit,
    mode,
    type,
    getData,
    language,
    country,
  } = props
  const [data, setData] = useState(null)
  const [title, setTitle] = useState('')
  const checkRef = useRef([])
  const [checked, setChecked] = useState([])
  const [drag, setDrag] = useState(false)
  const [image, setImage] = useState([])

  useEffect(() => {
    if (edit === 'new') {
      setData([
        {
          seq: uuidv4(),
          packageId: '',
          stickerId: '',
          lightBackgroundCode: '',
          darkBackgroundCode: '',
          packageImg: '',
          stickerImg: '',
          cardImgUrl: '',
        },
      ])
    } else {
      setData(curationList.filter(item => item.cardId === edit)[0].card)
      setTitle(curationList.filter(item => item.cardId === edit)[0].title)
    }
  }, [])

  useEffect(() => {
    console.log(data)
  }, [data])

  const handleCheck = e => {
    if (e.target.checked) {
      setChecked(checked.concat(e.target.value.split(',')))
    } else {
      // console.log(e.target.value.split(','))
      setChecked(
        checked.filter(seq => e.target.value.split(',').indexOf(seq) < 0)
      )
    }
  }

  const clickDelete = () => {
    if (checked.length > 0) {
      setData(data.filter(item => !checked.includes(String(item.seq))))
    }
    if (checkRef.current) {
      for (let i = 0; i < checkRef.current.length; i++) {
        if (checkRef.current[i] && checkRef.current[i].checked) {
          checkRef.current[i].checked = false
        }
      }
    }
    setChecked([])
  }

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }

  const clickAdd = () => {
    const temp = [...data]
    temp.unshift({
      seq: uuidv4(),
      packageId: '',
      stickerId: '',
      lightBackgroundCode: '',
      darkBackgroundCode: '',
      packageImg: '',
      stickerImg: '',
      cardImgUrl: '',
    })
    setData(temp)
  }

  const stickerPreview = item => {
    axios
      .get(`/new/webAdmin/v2/collection/sticker/${item.stickerId}`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then(res => {
        const result = res.data.result
        if (result) {
          setData(
            data.map(d =>
              d.seq === item.seq ? { ...d, stickerImg: result.s_img } : d
            )
          )
        }
      })
  }

  const clickSave = async () => {
    let formData = new FormData()
    let imgId = ''

    await formData.append('type', type)
    await formData.append('title', title)
    await formData.append('language', language)
    await formData.append('country', country)
    await formData.append('cardId', edit)
    await formData.append('packageInput', JSON.stringify(data))

    console.log(language, country)

    for (const tmp of data) {
      if (image.filter(item => item.seq === tmp.seq).length > 0) {
        await formData.append(
          'imgs',
          image.filter(item => item.seq === tmp.seq)[0].file[0]
        )
        if (edit === 'new') {
          imgId += 'new,'
        } else {
          if (typeof tmp.seq !== 'string') {
            imgId += `${tmp.seq},`
          } else {
            imgId += 'new,'
          }
        }
      } else {
        imgId += 'null,'
      }
    }

    await formData.append('updateImgId', imgId.substring(0, imgId.length - 1))
    await fetch('/new/webAdmin/v2/curation', {
      headers: {
        token: sessionStorage.getItem("token"),
      },
      method: edit === 'new' ? 'POST' : 'PUT',
      body: formData,
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        getData()
        setEdit(0)
      })
      .catch(error => {
        console.error('123=' + error)
        alert('실패')
      })
  }

  const dragDataInit = {
    target: null,
    index: -1,
    move_up: [],
    move_down: [],
    updateList: [],
  }

  const [dragData, setDragData] = useState(dragDataInit)

  const _dragStart = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    setDragData({
      ...dragData,
      target: e.currentTarget,
      index: Number(e.currentTarget.dataset.index),
      updateList: [...data],
    })

    e.dataTransfer.setData('text/html', '')
    e.dataTransfer.effectAllowed = 'move'
  }

  const _dragEnter = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const _dragged = Number(dragData.target.dataset.index)
    const _index = Number(dragData.index)
    const _target = Number(e.currentTarget.dataset.index)
    let move_down = [...dragData.move_down]
    let move_up = [...dragData.move_up]

    let updateData = [...dragData.updateList]

    updateData[_index] = updateData.splice(_target, 1, updateData[_index])[0]

    if (_dragged > _target) {
      move_down.includes(_target) ? move_down.pop() : move_down.push(_target)
    } else if (_dragged < _target) {
      move_up.includes(_target) ? move_up.pop() : move_up.push(_target)
    } else {
      move_down = []
      move_up = []
    }

    setDragData({
      ...dragData,
      updateList: updateData,
      index: _target,
      move_up,
      move_down,
    })
  }

  const _dragLeave = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (e.currentTarget === dragData.target) {
      e.currentTarget.style.visibility = 'hidden'
    }
  }

  const _dragEnd = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    setDrag(false)
    setData([...dragData.updateList])
    setDragData({
      ...dragData,
      move_down: [],
      move_up: [],
      updateList: [],
    })

    e.currentTarget.style.visibility = 'visible'
    e.dataTransfer.dropEffect = 'move'
  }

  const _dragCapture = async e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
  }

  const renderA = (item, index) => {
    return (
      <>
        <CheckBox>
          <input
            ref={el => (checkRef.current[index] = el)}
            type="checkbox"
            value={item.seq}
            onChange={handleCheck}
          />
        </CheckBox>
        <Index mode={mode}>#{index + 1}</Index>
        <TextInput mode={mode}>
          <span>Package ID</span>
          <input
            type="text"
            value={item.packageId ? item.packageId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, packageId: e.target.value } : d
                )
              )
            }}
          />
        </TextInput>
        <TextInput mode={mode}>
          <span>Sticker ID</span>
          <input
            type="text"
            value={item.stickerId ? item.stickerId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, stickerId: e.target.value } : d
                )
              )
            }}
            onBlur={e => {
              if (e.target.value) {
                stickerPreview(item)
              }
            }}
          />
        </TextInput>
        <TextInput mode={mode}>
          <span>라이트 배경코드</span>
          <input
            type="text"
            value={item.lightBackgroundCode ? item.lightBackgroundCode : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq
                    ? { ...d, lightBackgroundCode: e.target.value }
                    : d
                )
              )
            }}
          />
        </TextInput>
        <TextInput mode={mode}>
          <span>다크 배경코드</span>
          <input
            type="text"
            value={item.darkBackgroundCode ? item.darkBackgroundCode : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq
                    ? { ...d, darkBackgroundCode: e.target.value }
                    : d
                )
              )
            }}
          />
        </TextInput>
        <Preview
          bgColor={mode ? item.darkBackgroundCode : item.lightBackgroundCode}
          style={{
            width: 'calc(20% - 10px)',
            minWidth: '124.5px',
          }}
        >
          <img
            id="preview"
            src={item.stickerImg ? item.stickerImg : ''}
            alt=""
          />
        </Preview>
        <Order>
          <img
            id="drag"
            src="https://img.stipop.io/image/account/hamburger_menu.png"
            alt=""
            draggable={false}
            onMouseDown={() => setDrag(true)}
          />
        </Order>
      </>
    )
  }

  const renderB = (item, index) => {
    return (
      <>
        <CheckBox>
          <input
            ref={el => (checkRef.current[index] = el)}
            type="checkbox"
            value={item.seq}
            onChange={handleCheck}
          />
        </CheckBox>
        <Index>#{index + 1}</Index>
        <TextInput mode={mode}>
          <span>Package ID</span>
          <input
            type="text"
            value={item.packageId ? item.packageId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, packageId: e.target.value } : d
                )
              )
            }}
          />
        </TextInput>
        <TextInput mode={mode}>
          <span>라이트 배경코드</span>
          <input
            type="text"
            value={item.lightBackgroundCode ? item.lightBackgroundCode : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq
                    ? { ...d, lightBackgroundCode: e.target.value }
                    : d
                )
              )
            }}
          />
        </TextInput>
        <TextInput mode={mode}>
          <span>다크 배경코드</span>
          <input
            type="text"
            value={item.darkBackgroundCode ? item.darkBackgroundCode : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq
                    ? { ...d, darkBackgroundCode: e.target.value }
                    : d
                )
              )
            }}
          />
        </TextInput>
        <FileInput>
          <Label>
            <label className="button" htmlFor={item.seq}>
              파일 선택
            </label>
            {image.filter(i => i.seq === item.seq).length > 0 && (
              <div className="file-name">
                {image.filter(i => i.seq === item.seq)[0].file[0].name}
              </div>
            )}
          </Label>
          <input
            id={item.seq}
            type="file"
            onChange={e => {
              if (e.target.files && e.target.files[0]) {
                if (image.length > 0) {
                  if (image.filter(i => i.seq === item.seq).length > 0) {
                    setImage(
                      image.map(i =>
                        i.seq === item.seq
                          ? {
                              ...i,
                              img: URL.createObjectURL(e.target.files[0]),
                              file: e.target.files,
                            }
                          : i
                      )
                    )
                  } else {
                    setImage(
                      image.concat({
                        seq: item.seq,
                        img: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files,
                      })
                    )
                  }
                } else {
                  setImage(
                    image.concat({
                      seq: item.seq,
                      img: URL.createObjectURL(e.target.files[0]),
                      file: e.target.files,
                    })
                  )
                }
              }
            }}
            multiple={false}
          />
          <Preview>
            <img
              src={
                image.length > 0
                  ? image.filter(i => i.seq === item.seq).length > 0
                    ? image.filter(i => i.seq === item.seq)[0].img
                    : item.cardImgUrl
                    ? item.cardImgUrl
                    : ''
                  : item.cardImgUrl
                  ? item.cardImgUrl
                  : ''
              }
              alt=""
            />
          </Preview>
        </FileInput>
        <Order>
          <img
            id="drag"
            src="https://img.stipop.io/image/account/hamburger_menu.png"
            alt=""
            draggable={false}
            onMouseDown={() => setDrag(true)}
          />
        </Order>
      </>
    )
  }

  return (
    <Wrapper>
      <ButtonWrapper>
        <button
          onClick={() => {
            getData()
            setEdit(0)
          }}
          style={{
            backgroundColor: '#818181',
          }}
        >
          목록으로
        </button>
        <button
          onClick={() => clickDelete()}
          style={{ backgroundColor: '#dc143c' }}
        >
          선택 삭제
        </button>
        <button
          onClick={() => clickAdd()}
          style={{ backgroundColor: '#ff4500' }}
        >
          새 구간 추가
        </button>
        <button
          onClick={() => clickSave()}
          style={{ backgroundColor: '#007ffb' }}
        >
          배너 저장
        </button>
      </ButtonWrapper>
      {data && (
        <>
          <TitleWrapper>
            <span>Title</span>
            <TitleInput
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </TitleWrapper>
          {data.map((item, index) => {
            let default_class = ''
            dragData.move_down.includes(index) && (default_class = 'move_down')
            dragData.move_up.includes(index) && (default_class = 'move_up')

            return (
              <CurationItem
                key={index}
                data={item}
                data-index={index}
                draggable={drag}
                onDragStart={_dragStart}
                onDragEnter={_dragEnter}
                onDragLeave={_dragLeave}
                onDragEnd={_dragEnd}
                onDragCapture={_dragCapture}
                className={default_class}
                mode={mode}
              >
                {type === 'A' ? renderA(item, index) : renderB(item, index)}
              </CurationItem>
            )
          })}
        </>
      )}
    </Wrapper>
  )
}

export default CurationEdit

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;

  button {
    height: 35px;
    border: none;
    border-radius: 5px;
    padding: 0 10px;
    margin-left: 10px;
    color: #fff;

    &:hover {
      opacity: 0.8;
      font-weight: bold;
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* min-width: 700px; */

  span {
    font-weight: bold;
    font-size: 20px;
  }
`

const TitleInput = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
`

const CurationItem = styled.div`
  width: 100%;
  min-width: 700px;
  height: 90px;
  background-color: ${props => (props.mode ? '#303030' : '#fff')};
  border-radius: 10px;
  border: 1px solid ${props => (props.mode ? '#fff' : '#000')};
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 15px;
  position: relative;
  opacity: 0.999;

  #drag {
    width: 25px;
    height: 20px;
    cursor: pointer;
  }

  &.move_up {
    transform: translateY(-90px);
    z-index: 1;
  }
  &.move_down {
    transform: translateY(90px);
    z-index: 1;
  }
`

const CheckBox = styled.div`
  width: 5%;
  min-width: 33.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
`

const Index = styled.div`
  width: 5%;
  min-width: 33.5px;
  height: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: ${props => (props.mode ? '#fff' : '#000')};
`

const TextInput = styled.div`
  width: 15%;
  min-width: 100.5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  span {
    width: 90%;
    font-size: 12px;
    font-weight: bold;
    color: ${props => (props.mode ? '#fff' : '#000')};
  }

  input[type='text'] {
    width: 90%;
    height: 30px;
  }

  textarea {
    width: 95%;
    height: 50%;
  }
`

const FileInput = styled.div`
  width: calc(50% - 10px);
  min-width: 325px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    display: none;
  }
`

const Label = styled.div`
  width: 30%;
  min-width: 97.5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0;

  & .button {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #efefef;
    border: 1px solid #000;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .file-name {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }
`

const Preview = styled.div`
  width: 70%;
  min-width: 227.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    max-width: 100%;
    background-color: ${props => props.bgColor};
  }

  #artist {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
`

const Order = styled.div`
  width: 10%;
  min-width: 67px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`
