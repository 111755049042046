import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import queryString from "query-string";

// import LeftMenu from "../../Components/LeftMenu";
import Loading from "../../Components/Common/Loading/Loading.js";
import { CardTypeDropdown, CardLangDropdown, CardCountryDropdown } from "./CurationDropdown";
import BannerContainerTable from "../../Components/MobileBanner/BannerContainerTable.js";
import CurationTable from "./CurationTable";

const Curation = (props) => {
  
    const query = props.query;
    const pageName = "Curation";
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageMap, setPageMap] = useState({});
    const [params, setParams] = useState(!location.state ? {
        pageNumber: query.pageNumber ? query.pageNumber : 1,
        type: query.type ? query.type : "A",
        status: query.status ? query.status : "N",
        lang: query.lang ? query.lang : "en",
        country: query.country ? query.country : "us",
    } : {
        pageNumber: location.state.pageNumber ? location.state.pageNumber : 1,
        type: location.state.type ? location.state.type : "A",
        status: location.state.status ? location.state.status : "N",
        lang: location.state.lang ? location.state.lang : "en",
        country: location.state.country ? location.state.country : "us",
    });

    useEffect(() => {
        if (sessionStorage.getItem("token")) {
            getBannerContainers();  
        } else {
            props.history.push("/");
        }
    }, [params]);

    const getBannerContainers = () => {
        setIsLoading(true);
        
        const queryParameter = queryString.stringify(params);
        // console.log(queryParameter);       
        fetch(`/new/webAdmin/v2/curation?${queryParameter}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token"),
            },
            method: "GET",
        })
        .then((response) => response.json())
        .then((json) => {
            if (json.status === "fail") props.history.push("/");
            setData(json.result.curationList)
            // setBannerContainers(responseJson.result.containerList);
            // setPageMap(responseJson.result.pageMap);
            setIsLoading(false);
        })
        .catch((error) => {
            console.error("123=" + error);
        });
    };

    const handleCheckBoxEvent = (event) => {
        if (event.target.checked) {
            setParams({ ...params, status: "Y" });
        } else {
            setParams({ ...params, status: "" });
        }
    };

    return (
        <>
            <div style={{ minHeight: "100%" }}>
                <section className="content-header" style={{ marginBottom: "15px" }}>
                    <h1>Curation</h1>
                </section>
                <section>
                {
                    isLoading ? 
                    (
                        <Loading />
                    )
                    : 
                    (
                        <Container fluid>
                        <Row>
                            <Col md="auto">
                                <CardTypeDropdown type={params.type} setType={(type) => setParams({ ...params, pageNumber: 1, type })} />
                                <CardLangDropdown lang={params.lang} setLang={(lang) => setParams({ ...params, pageNumber: 1, lang })} />
                                <CardCountryDropdown country={params.country} setCountry={(country) => setParams({ ...params, pageNumber: 1, country })} />
                            </Col>
                            <Col md="auto">
                                <input type="checkbox" onChange={handleCheckBoxEvent} checked={params.status === "Y"} />
                                {" "}Active
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CurationTable data={data} getBannerContainers={getBannerContainers} />
                            </Col>
                        </Row>
                        {/* <Row className="pull-right">
                            <BannerListBottomBar pageNumber={params.pageNumber} type={params.type} status={params.status} pageMap={pageMap} pageName={pageName} />
                        </Row> */}
                        </Container>
                    )
                }
                </section>
            </div>
        </>
    );
};

export default Curation;
