import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import ArtistGroup from "./ArtistGroup";

function ArtistGroups(props) {
  const [detailsOfArtistGroups, setDetailsOfArtistGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // check token
    if (sessionStorage.getItem("token")) {
      getDetailsOfArtistGroups();
    } else {
      props.history.push("/");
    }
  }, []);

  const getDetailsOfArtistGroups = () => {
    fetch("/new/webAdmin/v2/artistGroup/detail", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setDetailsOfArtistGroups(responseJson.detailsOfArtistGroups);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  if (isLoading) {
    return <Loading></Loading>;
  } else {
    return (
      <div
        // className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header">
          <h1>작가 그룹 정보</h1>
          <ol className="breadcrumb">
            <li>
              <i className="fa fa-dashboard"></i>회원 관리
            </li>
            <li className="active">작가 그룹 정보</li>
          </ol>
        </section>
        <section className="content">
          {detailsOfArtistGroups &&
            detailsOfArtistGroups.map((detail) => {
              return (
                <ArtistGroup
                  key={detail.artistGroupId}
                  data={detail}
                ></ArtistGroup>
              );
            })}
        </section>
      </div>
    );
  }
}

export default withRouter(ArtistGroups);
