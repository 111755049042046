import React from 'react';
import { getContainerTypeName } from './MobileBannerUtils';

const BannerTypeDropdown = (props) => {

    return (
        <React.Fragment>
            <select value={props.type} onChange={(event) => props.setType(event.target.value)}>
                <option value="">타입을 선택하세요</option>
                <option value="I">1. {getContainerTypeName("I")}</option>
                <option value="B">2. {getContainerTypeName("B")}</option>
                <option value="E">3. {getContainerTypeName("E")}</option>
                <option value="A">4. {getContainerTypeName("A")}</option>
                <option value="G">5. {getContainerTypeName("G")}</option>
                <option value="H">6. {getContainerTypeName("H")}</option>
                <option value="D">7. {getContainerTypeName("D")}</option>
                <option value="C">8. {getContainerTypeName("C")}</option>
            </select>
        </React.Fragment>
    )
}

export default BannerTypeDropdown;