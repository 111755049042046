import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import FormatBoldRoundedIcon from '@material-ui/icons/FormatBoldRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import CropOriginalRoundedIcon from '@material-ui/icons/CropOriginalRounded';
import TitleRoundedIcon from '@material-ui/icons/TitleRounded';
import FormatSizeRoundedIcon from '@material-ui/icons/FormatSizeRounded';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import FormatListNumberedRoundedIcon from '@material-ui/icons/FormatListNumberedRounded';

const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  width: 100%;
`

const EditorHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  div{
    display: flex;
    justify-content: flex-start;
  }
`

const MenuButton = styled.div`
  display: flex;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 2px 10px;
  background-color: #fff;
  margin-right: 2px;
  position: relative;
`

const HyperLinkInput = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
`


const EditorBody = styled.textarea`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Editor = ({ edit, setEditMainImage, setMainImage, title, setTitle, content, setContent, files, setFiles }) => {
  const inputRef = useRef();
  const [hyperlinkOpen, setHyperLinkOpen] = useState(false);
  const [codeLang, setCodeLang] = useState('Javascript');
  const [openCodeLang, setOpenCodeLang] = useState(false);
  // const [isFocus, setIsFocus] = useState(false);

  useEffect(()=>{
    const tabKeyEvent = (e) => {
      if(e.key==='Tab'){
        e.preventDefault();

        const start = inputRef.current.selectionStart;
        const end = inputRef.current.selectionEnd;
        let res = 
          inputRef.current.value.substring(0, start)
          +"\t"
          +inputRef.current.value.substring(end);

        inputRef.current.value = res;
        setContent(inputRef.current.value);

        inputRef.current.selectionStart = inputRef.current.selectionEnd = start + 1;
      }
    }
    document.getElementById('editor').addEventListener('keydown', (e)=>{tabKeyEvent(e)})

    return document.getElementById('editor').removeEventListener('keydown', tabKeyEvent);
  }, [])

  const changeInput = (e) => {
    // console.log(inputRef.current.value)
    setContent(inputRef.current.value)
  }

  const addCodeBlock = (lang) => {
    const dragStart = inputRef.current.selectionStart;
    const dragEnd = inputRef.current.selectionEnd;

    const beforeText = inputRef.current.value.substring(0, dragStart);
    const dragText = inputRef.current.value.substring(dragStart, dragEnd);
    const afterText = inputRef.current.value.substring(dragEnd);

    let res = `${beforeText}\`\`\`${lang}\n${dragText}\n\`\`\`${afterText}`;
    inputRef.current.value = res;
    setContent(inputRef.current.value);
  }

  const addMark = (mark, option) => {
    const dragStart = inputRef.current.selectionStart;
    const dragEnd = inputRef.current.selectionEnd;

    const beforeText = inputRef.current.value.substring(0, dragStart);
    const dragText = inputRef.current.value.substring(dragStart, dragEnd);
    const afterText = inputRef.current.value.substring(dragEnd);
    
    let res = '';
    if(mark.charAt(0)==='I')
      res = beforeText + '<' + mark + option + '><\/' + mark + '>' + afterText;
    else if(mark.charAt(0)==='h')
      res = beforeText + `<${mark} link="${option}">${dragText}</${mark}>${afterText}`;
    else
      res = beforeText + '<' + mark + '>' + dragText + '<\/' + mark + '>' + afterText;
    // console.log(res);

    inputRef.current.value = res;
    setContent(inputRef.current.value)
  }

  const imageUpload = (input) => {
    if(!files){
      setFiles([input[0]])
    }
    else{
      setFiles(prev=>[...prev, input[0]]);
    }
    addMark('I', ` name=${input[0].name}`);
  }

  const addBulletPoint = () => {
    const start = inputRef.current.selectionStart;
    const end = inputRef.current.selectionEnd;

    const beforeText = inputRef.current.value.substring(0, start);
    const dragText = inputRef.current.value.substring(start, end);
    const afterText = inputRef.current.value.substring(end);
    
    let res = beforeText;
    dragText.split('\n').map((line, index)=>{
      res += `\t- ${line} \n`
    })
    res+=afterText;

    inputRef.current.value = res;
    setContent(inputRef.current.value);
  }

  const addNmberList = () => {
    const start = inputRef.current.selectionStart;
    const end = inputRef.current.selectionEnd;

    const beforeText = inputRef.current.value.substring(0, start);
    const dragText = inputRef.current.value.substring(start, end);
    const afterText = inputRef.current.value.substring(end);
    
    let index = 1;
    let res = beforeText;
    dragText.split('\n').map((line, i)=>{
      res += `\t-${index}. ${line} \n`;
      index++;
    })
    res+=afterText;

    inputRef.current.value = res;
    setContent(inputRef.current.value)
  }

  return (
    <EditorWrapper>
      <EditorHeader>
        <div style={{padding: '10px 10px 0'}}>  
          <input 
            type="text" 
            placeholder={"title(\'\/\', \'\-\'사용 불가)"} 
            value={title.title}
            style={{width: '100%', marginBottom: 3}}
            disabled={edit}
            onChange={(e)=>{
              e.persist();
              setTitle((prevState) => ({
                ...prevState,
                title: e.target.value
              }))
            }}
          />
        </div>
        <div style={{padding: '0 10px'}}>
          <select 
            id="category_selector"
            style={{width: '50%', height: 30, marginBottom: 3}}
            // value={title.category}
            onChange={(e)=>{
              e.persist();
              setTitle((prevState) => ({
                ...prevState,
                category: e.target.value
              }))
            }}
          >
            <option selected={title.category===0} value={0} disabled>category</option>
            <option selected={title.category===1} value={1}>Engineering</option>
            <option selected={title.category===2} value={2}>Product</option>
            <option selected={title.category===3} value={3}>Insights</option>
            <option selected={title.category===4} value={4}>Stipop</option>
            <option selected={title.category===5} value={5}>Behind the Scenes</option>
          </select>
          <input 
            type="text" 
            placeholder={'date'} 
            value={title.date}
            onChange={(e)=>{
              e.persist();
              setTitle((prevState) => ({
                ...prevState,
                date: e.target.value
              }))
            }}
            style={{width: '50%', marginBottom: 3}}
          />
        </div>
        <div style={{padding: '0 10px'}}>
          <input 
            type="file"
            name="postImgs"
            accept="image/*"
            onChange={(e)=>{
              e.persist();
              setMainImage(e.currentTarget.files[0])
              if(edit){
                setEditMainImage(true)
              }
            }} 
          />
        </div>

        <div style={{display:'block', marginTop: 10, borderTop: '1px solid gray', padding: 10, position: 'relative'}}>
          <div style={{marginBottom: 10}}>
            <MenuButton onClick={()=>addMark('T')} title="title text"><FormatSizeRoundedIcon/></MenuButton>
            <MenuButton onClick={()=>addMark('t')} title="sub title text"><TitleRoundedIcon /></MenuButton>
            <MenuButton onClick={()=>addMark('b')} title="blod text"><FormatBoldRoundedIcon/></MenuButton>
            <MenuButton onClick={()=>{setOpenCodeLang(prev=>!prev);}} title="code block"><CodeRoundedIcon /></MenuButton>
            <select 
              id="category_selector"
              style={{
                display: openCodeLang?'block':'none',
                width: '30%', 
                height: 30, 
                marginBottom: 3,
                position: 'absolute',
                top: 40,
                left: 154
              }}
              // value={title.category}
              onChange={(e)=>{
                e.persist();
                setCodeLang(e.target.value);
                setOpenCodeLang(false);
                addCodeBlock(e.target.value);
                e.target.value = 0
              }}
            >
              <option selected value={0} disabled>language</option>
              <option value={"Javascript"}>Javascript</option>
              <option value={"python"}>Python</option>
              <option value={"java"}>Android/JAVA</option>
              <option value={"swift"}>Swift/iOS</option>
              <option value={"objectivec"}>Objective-C</option>
            </select>
            <MenuButton title="hyperlink text">
              <LinkRoundedIcon 
                onClick={()=>{
                  setHyperLinkOpen(prev=>!prev);
                }} 
              />
              <HyperLinkInput style={{display:hyperlinkOpen?'block':'none'}}>
                <div>
                  <input type="text" id="hyperlink-input" onClick={(e)=>e.preventDefault()}/>
                  <MenuButton 
                    style={{width: 50}}
                    onClick={(e)=>{
                      e.preventDefault();
                      const hyperlink = document.getElementById("hyperlink-input").value
                      addMark('h', hyperlink)
                      setHyperLinkOpen(false)
                      document.getElementById("hyperlink-input").value = ''
                    }}
                  >
                    link
                  </MenuButton>  
                </div>
              </HyperLinkInput>
            </MenuButton>
            <MenuButton onClick={()=>addMark('i')} title="image description text"><CropOriginalRoundedIcon /></MenuButton>
            
            <MenuButton onClick={()=>{addBulletPoint()}} title="bullet point"><FormatListBulletedRoundedIcon/></MenuButton>
            <MenuButton onClick={()=>{addNmberList()}} title="number list"><FormatListNumberedRoundedIcon/></MenuButton>
          </div>
          <div >
            <input 
              id="input_images"
              type="file" 
              accept='image/*'
              multiple
              // disabled={!isFocus}
              onChange={(e)=>{imageUpload(e.currentTarget.files)}}
            />
          </div>
        </div>
      </EditorHeader>
      <EditorBody 
        id="editor"
        ref={inputRef} 
        value={content}
        // type={'text'} 
        onChange={(e)=>changeInput(e)}
        // onFocus={()=>{setIsFocus(true)}}
        // onBlur={()=>{setTimeout(()=>{setIsFocus(false)}, 2000)}}
      />
    </EditorWrapper>
  )
}
export default Editor;