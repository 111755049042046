import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import queryString from "query-string";

// import LeftMenu from "../../Components/LeftMenu.js";
import Loading from "../../Components/Common/Loading/Loading.js";
import TopBar from "../../Components/Money/TopBar.js";
import ArtistPaypalTable from "../../Components/Money/ArtistPaypalTable.js";
import BottomBar from "../../Components/Money/BottomBar.js";

const ArtistPaypalPage = (props) => {
  const query = queryString.parse(props.location.search);
  const pageName = "Money/ArtistPaypal";

  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState({
    pageNumber: query.pageNumber ? query.pageNumber * 1 : 1,
    search: query.search ? query.search : "",
  });
  const [pageMap, setPageMap] = useState({});
  const [artistPaypals, setArtistPaypals] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getArtistPaypals();
    } else {
      props.history.push("/");
    }
  }, [params]);

  const getArtistPaypals = () => {
    setIsLoading(true);
    const queryParameter =
      "?pageNumber=" + params.pageNumber + "&search=" + params.search;
    fetch("/new/webAdmin/v2/tax/paypals" + queryParameter, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setArtistPaypals(responseJson.paypals);
        setPageMap(responseJson.pageMap);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <div
        //  className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header" style={{ marginBottom: "15px" }}>
          <h1>작가 Paypal 관리</h1>
          <ol className="breadcrumb">
            <li>
              <i className="fa fa-dashboard"></i> 세금정보관리
            </li>
            <li className="active">작가 Paypal 관리</li>
          </ol>
        </section>
        <section>
          {isLoading ? (
            <Loading />
          ) : (
            <Container fluid>
              <Row>
                <Col>
                  <TopBar params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ArtistPaypalTable contents={artistPaypals} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BottomBar
                    pageNumber={params.pageNumber}
                    pageMap={pageMap}
                    pageName={pageName}
                    search={params.search}
                  />
                </Col>
              </Row>
            </Container>
          )}
        </section>
      </div>
    </React.Fragment>
  );
};

export default ArtistPaypalPage;
