import React from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import DefaultButton from "../../Common/ButtunUI/DefaultButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  input:focus {
    outline: none;
  }
  textarea:focus {
    outline: none;
  }
`;

const InputTitleEn = styled.input`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
`;

const InputTitleKo = styled.input`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  padding: 10px;
`;

const InputContentsHelp = styled.div`
  padding: 10px 15px;
  margin-bottom: 10px;
  background-color: #00a65a;
  border-radius: 4px;
  color: white;
`;

const InputContentsEn = styled.textarea`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  padding: 10px;
  resize: none;
  margin-bottom: 10px;
`;

const InputContentsKo = styled.textarea`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  padding: 10px;
  resize: none;
`;

const InputTag = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
`;

const InputImage = styled.input`
  width: fit-content;
`;

const InputFile = styled.input`
  width: fit-content;
  margin-bottom: 10px;
`;

const CloseCheckForm = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CloseCheckBox = styled.input`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  margin-right: 5px;
`;

const MessageNoticeAddForm = ({ setPreviewData, goTop }) => {
  const [inputTitleEn, setInputTitleEn] = useState("");
  const [inputTitleKo, setInputTitleKo] = useState("");
  const [inputContentsEn, setInputContentsEn] = useState("");
  const [inputContentsKo, setInputContentsKo] = useState("");

  const imageEnRef = useRef(null);
  const imageKoRef = useRef(null);
  const attachEnRef1 = useRef(null);
  const attachEnRef2 = useRef(null);
  const attachEnRef3 = useRef(null);
  const attachKoRef1 = useRef(null);
  const attachKoRef2 = useRef(null);
  const attachKoRef3 = useRef(null);

  const [isContentsBottom, setIsContentsBottom] = useState(false);
  const [isFixChecked, setIsFixChecked] = useState(false);

  const setPreview = async () => {
    let formData = {};
    if (imageEnRef !== null)
      formData = { ...formData, imageEn: imageEnRef.current.files[0] };
    if (imageKoRef !== null)
      formData = { ...formData, imageKo: imageKoRef.current.files[0] };
    if (attachEnRef1 !== null)
      formData = { ...formData, attachEn1: attachEnRef1.current.files[0] };
    if (attachEnRef2 !== null)
      formData = { ...formData, attachEn2: attachEnRef2.current.files[0] };
    if (attachEnRef3 !== null)
      formData = { ...formData, attachEn3: attachEnRef3.current.files[0] };
    if (attachKoRef1 !== null)
      formData = { ...formData, attachKo1: attachKoRef1.current.files[0] };
    if (attachKoRef2 !== null)
      formData = { ...formData, attachKo2: attachKoRef2.current.files[0] };
    if (attachKoRef3 !== null)
      formData = { ...formData, attachKo3: attachKoRef3.current.files[0] };
    formData = {
      ...formData,
      titleEn: inputTitleEn,
      titleKo: inputTitleKo,
      contentsEn: inputContentsEn,
      contentsKo: inputContentsKo,
      contentsPlace: isContentsBottom ? 1 : 0,
      fixStatus: isFixChecked ? 1 : 0,
    };

    setPreviewData(formData);
  };

  const onClickPreviewButton = () => {
    if (inputTitleEn === "" || inputTitleKo === "") {
      alert("Please input both EN and KO title.");
      return;
    }
    if (inputContentsEn === "" && inputContentsKo !== "") {
      alert("Please input both EN and KO contents.");
      return;
    }
    if (inputContentsKo === "" && inputContentsEn !== "") {
      alert("Please input both EN and KO contents.");
      return;
    }
    if (
      imageEnRef.current.files[0] === undefined &&
      imageKoRef.current.files[0] !== undefined
    ) {
      alert("Please input both EN and KO image.");
      return;
    }
    if (
      imageKoRef.current.files[0] === undefined &&
      imageEnRef.current.files[0] !== undefined
    ) {
      alert("Please input both EN and KO image.");
      return;
    }
    if (inputContentsEn === "" && imageEnRef.current.files[0] === undefined) {
      alert("Please input contents or image.");
      return;
    }

    goTop();
    setPreview();
  };

  return (
    <Wrapper>
      <InputTag>Is this notice alaways on the top?</InputTag>
      <CloseCheckForm>
        <CloseCheckBox
          onClick={() => {
            setIsFixChecked(!isFixChecked);
          }}
          type={"checkbox"}
        />
        Check this box if you want this notice is always on the top of notice
        list.
      </CloseCheckForm>
      <InputTag>Title (EN/KO)</InputTag>
      <InputTitleEn
        placeholder={"Input title (EN)"}
        value={inputTitleEn}
        onChange={(event) => {
          setInputTitleEn(event.target.value);
        }}
      />
      <InputTitleKo
        placeholder={"Input title (KO)"}
        value={inputTitleKo}
        onChange={(event) => {
          setInputTitleKo(event.target.value);
        }}
      />
      <InputTag>Are contents placed below image?</InputTag>
      <CloseCheckForm>
        <CloseCheckBox
          onClick={() => {
            setIsContentsBottom(!isContentsBottom);
          }}
          type={"checkbox"}
        />
        Check this box if you want to contents are placed below image.
      </CloseCheckForm>
      <InputTag>Contents (EN/KO)</InputTag>
      <InputContentsHelp>
        To create link with custom name, write as <strong>![name](link)</strong>
        .
        <br />
        Link must start with "<strong>http://</strong>" or "
        <strong>https://</strong>".
        <br />
        For example, <strong>![Stipop](https://stipop.io)</strong>
      </InputContentsHelp>
      <InputContentsEn
        placeholder={"Input contents (EN)"}
        value={inputContentsEn}
        onChange={(event) => {
          setInputContentsEn(event.target.value);
        }}
        rows={10}
      />
      <InputContentsKo
        placeholder={"Input contents (KO)"}
        value={inputContentsKo}
        onChange={(event) => {
          setInputContentsKo(event.target.value);
        }}
        rows={10}
      />
      <InputTag>Image Contents (EN)</InputTag>
      <InputImage
        accept={"image/jpg,image/jpeg,image/gif,image/png"}
        type={"file"}
        ref={imageEnRef}
      />
      <InputTag>Image Contents (KO)</InputTag>
      <InputImage
        accept={"image/jpg,image/jpeg,image/gif,image/png"}
        type={"file"}
        ref={imageKoRef}
      />
      <InputTag>Attach File (EN)</InputTag>
      <InputFile
        accept={"image/jpg,image/jpeg,image/gif,image/png"}
        type={"file"}
        ref={attachEnRef1}
      />
      <InputFile
        accept={"image/jpg,image/jpeg,image/gif,image/png"}
        type={"file"}
        ref={attachEnRef2}
      />
      <InputFile
        accept={"image/jpg,image/jpeg,image/gif,image/png"}
        type={"file"}
        ref={attachEnRef3}
      />
      <InputTag>Attach File (KO)</InputTag>
      <InputFile
        accept={"image/jpg,image/jpeg,image/gif,image/png"}
        type={"file"}
        ref={attachKoRef1}
      />
      <InputFile
        accept={"image/jpg,image/jpeg,image/gif,image/png"}
        type={"file"}
        ref={attachKoRef2}
      />
      <InputFile
        accept={"image/jpg,image/jpeg,image/gif,image/png"}
        type={"file"}
        ref={attachKoRef3}
      />
      <DefaultButton
        style={{ width: 120, marginTop: 20 }}
        theme={"blue"}
        btnText={"Show Preview"}
        onClick={() => {
          onClickPreviewButton();
        }}
      />
    </Wrapper>
  );
};

export default MessageNoticeAddForm;
