import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const MiniCard = props => {
  const { page, title, stickerList } = props

  return page === 'home' ? (
    <>
      <Title>{title}</Title>
      <SpaceResponsive height={16} />
      <Wrapper>
        <StickerWrapper
          page={page}
          column={stickerList && Math.round(stickerList.length / 2)}
        >
          {stickerList &&
            stickerList.map((sticker, index) => (
              <StickerItem
                key={index}
                page={page}
                backgroundColor={sticker.bgColor}
              >
                {sticker.stickerImg ? (
                  <img src={sticker.stickerImg} alt={''} />
                ) : (
                  <img src={sticker.packageImg} alt={''} />
                )}
              </StickerItem>
            ))}
        </StickerWrapper>
      </Wrapper>
      <SpaceResponsive height={34} />
    </>
  ) : (
    <>
      <Wrapper>
        <StickerWrapper page={page}></StickerWrapper>
        {stickerList &&
          stickerList.map((sticker, index) => (
            <StickerItem
              key={index}
              page={page}
              backgroundColor={sticker.bgColor}
            >
              <img src={sticker.packageImg} alt="" />
              <PackageInfo>
                <span>{sticker.packageName}</span>
                <span
                  style={{
                    fontSize: '11px',
                    fontWeight: 'normal',
                    color: '#a0a7af',
                    height: '14.3px',
                  }}
                >
                  {sticker.artistName}
                </span>
              </PackageInfo>
            </StickerItem>
          ))}
      </Wrapper>
    </>
  )
}

export default MiniCard

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  position: relative;

  /* scrollbar-color: transparent transparent;
  scrollbar-width: 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  } */
`

const StickerWrapper = styled.div`
  display: ${props => (props.page === 'home' ? 'grid' : 'display')};
  grid-template-rows: ${props => props.page === 'home' && 'repeat(2, 1fr)'};
  grid-template-columns: ${props => `repeat(${props.column}, 83.4px)`};
  gap: 5px;
`

const StickerItem = styled.div`
  width: ${props => (props.page === 'home' ? '83.4px' : '343px')};
  height: ${props => (props.page === 'home' ? '82px' : '118px')};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  border-radius: 26px;
  padding: 12px;
  display: ${props => props.page === 'new' && 'flex'};
  align-items: ${props => props.page === 'new' && 'center'};
  margin-bottom: ${props => props.page === 'new' && '7.3px'};

  &:nth-child(2n) {
    grid-row: ${props => props.page === 'home' && '-1 / -2'};
  }

  img {
    width: ${props => (props.page === 'home' ? '100%' : '94px')};
    height: ${props => (props.page === 'home' ? '100%' : '94px')};
  }
`

const PackageInfo = styled.div`
  font-size: 14px;
  font-family: Poppins;
  font-weight: bold;
  letter-spacing: -0.14px;
  height: 18.8px;
  margin-left: 14.6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
