import React from "react";
import CurationInsert from "../../Components/B2B/CurationInsert";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const CurationInsertPage = ({ match, location, history }) => { console.log(location)
  const query = queryString.parse(location.search);
  const state = location.state;

  return (
    <React.Fragment>
      <CurationInsert query={query} match={match} state={state} history={history} />
    </React.Fragment>
  );
};

export default CurationInsertPage;
