import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const Search = props => {
  const { searchStickers } = props

  useEffect(() => {
    console.log(searchStickers)
  }, [])
  return (
    <>
      <Title>Browse categories</Title>
      <SpaceResponsive height={16} />
      <Wrapper>
        {searchStickers &&
          searchStickers.map((item, index) => (
            <Category key={index} bgColor={item.collection[0].bgColor}>
              <img src={item.collection[0].stickerImg} alt="" />
              <span>{item.title}</span>
            </Category>
          ))}
      </Wrapper>
    </>
  )
}

export default Search

const Wrapper = styled.div`
  width: 343px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 11px;
`

const Category = styled.div`
  width: 166px;
  height: 145px;
  border-radius: 30px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#a0a7af')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 90px;
    height: 90px;
    margin-bottom: 4px;
  }

  span {
    width: 131px;
    height: 29px;
    font-size: 14px;
    font-family: Poppins;
    font-weight: bold;
    color: #fff;
    line-height: 22px;
    letter-spacing: 0.14px;
    text-align: center;
  }
`
