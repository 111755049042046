import React from "react";
import WalletQnaList from "../../Components/WalletQna/WalletQnaList";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const WalletQnaListPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <WalletQnaList pageNumber={query.pageNumber} type={query.type} />
    </React.Fragment>
  );
};

export default WalletQnaListPage;
