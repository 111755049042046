import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import TextInput from "../Common/TextInput";

const Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
`;

const Field = styled.th`
    border-top: 1px solid #f4f4f4;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: left;
`;

const Data = styled.td`
    border-top: 1px solid #f4f4f4;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    display: table-cell;
`;

const Img = styled.img`
    width:100px;
    height:100px;
`;

const Input = styled.input`
    width:80%;
    padding:8px;
`;

// const Component = ({value, index, setDataList}) => {
//     return (
//         <Input value={value} onChange={(e) => {
//             // value = e.target.value;
//             setDataList(e.target.value);
//             // setState(value);
//             // setDataList((state) => [...state, {...state[index], [key]:value}])
//         }}  />
//     )
// }

export const EditList = ({fieldList, dataList, setDataList, editKeyword, deleteKeyword}) => {

    return (
        <Table>
            <tbody>
                <tr>
                {
                    fieldList.map((filed, index) => {
                        return (
                            <Field key={index}>{filed}</Field>
                        )
                    })
                }
                </tr>
                {
                    dataList.map((data, index) => { 
                        let list = [];

                        for (const key in data) {
                            if (key === 'stickerImg') {
                                list.push(
                                    <Data key={data.stickerId+"_"+key}><Img src={data[key]} /></Data>
                                )
                            } else if (!key.startsWith("keyword")) {

                                if (key === 'seq' || key === 'stickerId' || key === 'packageId' || key === 'artistName' || key === 'keyword' || key === 'stickerImg' || key === 'packageName') {
                                    list.push(
                                        <Data key={data.stickerId+"_"+key}>{data[key]}</Data>
                                    )
                                }

                            } else if (key === 'keyword') {

                                const set = (value) => {
                                    setData(value);
                                    setDataList(value, key, index);
                                }

                                const [value, Tinput, setData] = TextInput(data[key], set, data.stickerId+"_"+key);
                                
                                list.push(
                                    <Data key={data.stickerId+"_"+key}>
                                        {Tinput}
                                    </Data>
                                )
                            }
                        }

                        list.push(<Data><button onClick={() => editKeyword(data)}>수정</button></Data>)
                        list.push(<Data><button onClick={() => deleteKeyword(data.seq, index)}>삭제</button></Data>)

                        return (
                            <tr key={index}>
                                {list}
                            </tr>
                        )
                    })
                }
                
            </tbody>
        </Table>
    )
}

export const EditList2 = ({fieldList, dataList, setDataList, editKeyword, deleteKeyword, updateStatus, updateOrder}) => {
// console.log(dataList)
    return (
        <Table>
            <tbody>
                <tr>
                    {/* <Field>seq</Field> */}
                    <Field>keyword</Field>
                    <Field>status</Field>
                </tr>
                {
                    dataList.map((data, index) => { 
                        
                        const set = (value) => {
                            setData(value);
                            setDataList(value, 'keyword', index);
                        }

                        const [value, Tinput, setData] = TextInput(data.keyword, set, data.seq+"_keyword");

                        return (
                            <tr key={index}>
                                {/* <Data key={data.seq}>{data.seq}</Data> */}
                                <Data key={data.seq+"_keyword"}>
                                    {Tinput}
                                </Data>
                                <Data>{data.status}</Data>
                                <Data><button onClick={() => editKeyword(data)}>저장</button></Data>
                                <Data><button onClick={() => deleteKeyword(data.seq)}>삭제</button></Data>
                                <Data><button onClick={() => updateStatus(data.seq, 'Y')}>Y</button></Data>
                                <Data><button onClick={() => updateStatus(data.seq, 'N')}>N</button></Data>
                                <Data><button onClick={() => updateOrder(data, index, 'UP')}>▲</button></Data>
                                <Data><button onClick={() => updateOrder(data, index, 'DOWN')}>▼</button></Data>
                            </tr>
                        )
                    })
                }
                
            </tbody>
        </Table>
    )
}

export const EditList3 = ({fieldList, dataList, setDataList}) => {

    return (
        <Table>
            <tbody>
                <tr>
                {
                    fieldList.map((filed, index) => {
                        return (
                            <Field key={index}>{filed}</Field>
                        )
                    })
                }
                </tr>
                {
                    dataList.map((data, index) => { 
                        let list = [];

                        for (const key in data) {
                            if (key === 'stickerImg') {
                                list.push(
                                    <Data key={data.stickerId+"_"+key}><Img src={data[key]} /></Data>
                                )
                            } else if (!key.startsWith("keyword")) {
                                list.push(
                                    <Data key={data.stickerId+"_"+key}>{data[key]}</Data>
                                )
                            }
                        }

                        return (
                            <tr key={index}>
                                {list}
                            </tr>
                        )
                    })
                }
                
            </tbody>
        </Table>
    )
}