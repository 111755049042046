import React from "react";
import CollectionView from "../../Components/Collection/CollectionView";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const CollectionPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <CollectionView />
    </React.Fragment>
  );
};

export default CollectionPage;
