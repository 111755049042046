import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withRouter } from "react-router-dom";

import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import SelectBox from "../Common/SelectBox";
import List from "../Common/List2";

const subscription = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState([]); 
    const [userList, setUserList] = useState([]);
    const [isClick, setIsClick] = useState([]);
    const [pageMap, setPageMap] = useState({});
    const [pageNumber, setPageNumber] = useState(props.pageNumber ? props.pageNumber : 1);

    const getUserList = async () => { 

        const response = await fetch(`/admin/v2/subscription?pageNumber=${pageNumber}`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

        if (responseJson.status === 'success') {
            
            if (filedList.length === 0) {
                
                let fileds = []
                
                for (const tmp in responseJson.body.userList[0]) {
                    if (tmp !== 'userInfo') {
                        fileds.push(tmp);
                    }
                }
                
                setFiledList(fileds);
            }
// console.log(responseJson.body.userList)            
            setUserList(responseJson.body.userList);
            setPageMap(responseJson.body.pageMap);
            setIsClick(new Array(responseJson.body.userList.length).fill(false));

        } else {
            setUserList([]);
        }

    };

    useEffect(() => {
        if (sessionStorage.getItem("token")) {
            getUserList()
        } else {
          props.history.push('/')
        }
      }, [])

    

    const dataComponent = useCallback(() => {
        return (
            <List 
                fieldList={filedList}
                dataList={userList}
                isClick={isClick}
                clickE={clickE}
            />
        )
    }, [isClick]);

    const clickE = async (userId, index) => {
        let tmp = isClick;
        tmp[index] = !tmp[index];
        setIsClick(tmp);
        setUserList({...userList});
    }

    useEffect(() => { 
        // componentDidMount
        const fetchData = async () => {
            await getUserList();
            setIsLoading(false);
        }

        fetchData();

        return async () => {
          //componentWillUnmount
        };

    }, []);

    if (!isLoading) {
        return (
            <>
                <Header title={"subscription"} />
                <Body HeaderComponent={null} headerWidth={"20%"} DataComponet={dataComponent} pageMap={pageMap} />
            </>
        )
    } else {
        return <Loading />;
    }

}

export default withRouter(subscription);