import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Loading from "../Common/Loading/Loading";
import LineGraph from "./LineGraph";
import SearchKeyword from "./SearchKeyword";

/* Development Database 에서 유효한 기간, month 값은 실제 - 1
const defaultStartDate = new Date(2019,5,1);
const defaultEndDate = new Date(2019,5,10);
*/

const defaultEndDate = new Date();
const defaultStartDate = new Date(
  defaultEndDate.getTime() - 7 * 24 * 60 * 60 * 1000
);

function ContentsDashboard(props) {
  const [isLoading, setIsLoading] = useState(true);

  // 검색 기간
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  // 검색 정보
  const [searchType, setSearchType] = useState(0);
  const [searchData, setSearchData] = useState({
    packageIds: "",
    packageGroupId: "",
    containerId: "",
  });

  // Chart Data
  const [labels, setLabels] = useState([]);
  const [views, setViews] = useState([]);
  const [downloads, setDownloads] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getViewsAndDownloads();
    } else {
      props.history.push("/");
    }
  }, []);

  const getViewsAndDownloads = async () => {
    setIsLoading(true);
    const params = {
      startDate: startDate,
      endDate: endDate,
      searchData: {
        packageIds:
          searchData.packageIds && searchData.packageIds.length
            ? searchData.packageIds
            : null,
        packageGroupId: searchData.packageGroupId
          ? searchData.packageGroupId
          : null,
        containerId: searchData.containerId ? searchData.containerId : null,
      },
    };
    const responseViews = await getViews(params);
    const responseDownloads = await getDownloads(params);
    setLabels(responseViews.dates);
    setViews(responseViews.views);
    setDownloads(responseDownloads.downloads);
    setIsLoading(false);
  };
  const getViews = (params) => {
    return fetch("/new/webAdmin/v2/dashboard/views", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const getDownloads = (params) => {
    return fetch("/new/webAdmin/v2/dashboard/downloads", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };
  return (
    <div
      //   className="content-wrapper"
      style={{ minHeight: "100%" }}
    >
      <section className="content-header" style={{ marginBottom: "15px" }}>
        <h1>Contents Dashboard</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/ContentsList">
              <i className="fa fa-dashboard"></i> Dashboard
            </a>
          </li>
          <li className="active">Contents</li>
        </ol>
      </section>
      <SearchKeyword
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        searchType={searchType}
        setSearchType={setSearchType}
        searchData={searchData}
        setSearchData={setSearchData}
        handleSubmit={getViewsAndDownloads}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <Card style={{ margin: "10px" }}>
          <Card.Header>스티커팩 View/Download 현황</Card.Header>
          <Card.Body>
            <LineGraph labels={labels} datasets={[views, downloads]} />
          </Card.Body>
        </Card>
      )}
    </div>
  );
}

export default withRouter(ContentsDashboard);
