import axios from "axios";
import React, { useEffect, Fragment } from "react";
import { useState } from "react";
import styled from "styled-components";
import BorderBox from "../../Common/BoxUI/BorderBox";
import MessageQnaArtistInfo from "./MessageQnaArtistInfo";
import MessageQnaChat from "./MessageQnaChat";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const LeftInner = styled.div`
  display: flex;
  width: 60%;
`;

const RightInner = styled.div`
  display: flex;
  width: 40%;
`;

const MessageQnaDetail = ({ roomId, isClosed, setIsClosed, setLastSender }) => {
  const [selectedArtist, setSelectedArtist] = useState({});
  const [selectedRoom, setSelectedRoom] = useState();
  const [refreshRoom, setRefreshRoom] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getOneRoomDetail();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      getOneRoomDetail();
    }
  }, [refreshRoom]);

  const getOneRoomDetail = async () => {
    setIsLoading(true);

    try {
      await axios
        .get(`/new/webAdmin/v2/message-center/room/${roomId}`, {
          headers: { token: sessionStorage.getItem("token") },
        })
        .then((res) => {
          if (res.status === 200) {
            setSelectedRoom(res.data.room);
          }
        });
    } catch (error) {
      console.error(error);
      return;
    }
    setRefreshRoom(false);
    setIsLoading(false);
  };

  return (
    <Wrapper>
      {!isLoading && selectedRoom && (
        <Fragment>
          <LeftInner>
            <BorderBox
              boxTitle={`1:1 Message : ${selectedRoom.roomResult[0].roomTitle}`}
              component={
                <MessageQnaChat
                  selectedRoom={selectedRoom}
                  setSelectedArtist={setSelectedArtist}
                  setRefreshRoom={setRefreshRoom}
                  setIsClosed={setIsClosed}
                  isClosed={isClosed}
                  setLastSender={setLastSender}
                />
              }
            />
          </LeftInner>
          <RightInner>
            <BorderBox
              boxTitle={"Artist Infomation"}
              component={
                <MessageQnaArtistInfo selectedArtist={selectedArtist} />
              }
            />
          </RightInner>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default MessageQnaDetail;
