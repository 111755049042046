import React from 'react';
import styled from 'styled-components';
import DonePostView from '../../_Components/Blog/DonePostView';
import BorderBox from '../../_Components/Common/BoxUI/BorderBox';
import PageTitle from '../../_Components/Common/PageTitle/PageTitle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const DonePostPage = () => {
  return(
    <Wrapper>
      <PageTitle 
        mainTitle={'Blog Post'}
        subTitle={'move to home'}
        v2Link={''}
        navNames={["Blog"]}
      />
      <BorderBox 
        boxTitle='Blog Post Page'
        component={<DonePostView />}
      />
    </Wrapper>
  );
};

export default DonePostPage;