import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Div = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 10px;
    box-sizing: border-box;
`;

const Input = styled.input`
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin: 0;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    display: table-cell;
`;

const Span = styled.span`
    position: relative;
    font-size: 0;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
`;


const SearchButton = styled.button`
    z-index: 2;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    box-shadow: none;
    border-width: 1px;
    position: relative;
    background-color: #00c0ef;
    border-color: #00acd6;
    border: 1px solid transparent;
    color: #fff;
`;

const Dropdown = styled.div`
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
`;


const Componeent = ({q, setQ, limit, setLimit, search, setAnimated, setGroup}) => {

    return (
        <Div>
            animated:
            <Dropdown>
                <select onChange={(e) => setAnimated(e.target.value)}>
                    <option key={"ALL"} value={"ALL"}>ALL</option>
                    <option key={"N"} value={"N"}>N</option>
                    <option key={"Y"} value={"Y"}>Y</option>
                </select>
            </Dropdown>
            패키지내에 한개만 출력:
            <Dropdown>
                <select onChange={(e) => setGroup(e.target.value)}>
                    <option key={"N"} value={"N"}>N</option>
                    <option key={"Y"} value={"Y"}>Y</option>
                </select>
            </Dropdown>
            LIMIT: <Input value={limit} onChange={(event) => setLimit(event.target.value)} />
            search text: <Input value={q} onChange={(event) => setQ(event.target.value)} />
            <Span>
                <SearchButton onClick={search}>search</SearchButton>
            </Span>
        </Div>
    )

};

const SearchText = (q, setQ, limit, setLimit, search, setAnimated, setGroup) => {
    
    // const [state, setState] = useState(keyword);

    return [
        // state,
        <Componeent q={q} setQ={setQ} limit={limit} setLimit={setLimit} search={search} setAnimated={setAnimated} setGroup={setGroup} />,
        
    ]

};

export default SearchText;