export function getContainerTypeName(type) {
    switch(type) {
        case "A" : return "Card Banner(Type A)";
        case "B" : return "Topic Banner(Type B)";
        case "C" : return "Search Banner(Type C)";
        case "D" : return "Must Banner(Type D)";
        case "E" : return "Mood Banner(Type E)";
        case "F" : return "Type F, Deprecated";
        case "G" : return "Tag Banner(Type G)";
        case "H" : return "Hot Banner(Type H)";
        case "I" : return "Main Banner(Type I)";
        default : return "None";
    }
}

export function getBannerPropertyName(type, property) {
    switch (property) {
        case 'big title' :
            if(type === 'A') return "Big Title";
            if(type === 'E') return "Tag Title";
            break;
        case 'small title' :
            if(type === 'A') return "Small Title";
            if(type === 'E') return "Hex Color Code";
            if(type === 'H') return "Hex Color Code";
            if(type === 'I') return "URL";
            break;
        default : return "";
    }
}

export function checkSuitability(type, property) {
    if(type === '') return true;
    if(type === 'C'){
        if(property === 'tag') return false;
        return true;
    }
    switch (property) {
        case 's_id' :
            if(type === 'I' || type === 'A') return true;
            return false;
        case 'image' :
            if(type === 'I' || type === 'A') return false;
            return true;
        case 's_title' :
            if(type === 'B' || type === 'G') return true;
            return false;
        case 'b_title' :
            if(type === 'A' || type === 'E') return false;
            return true;
        case 'tag' :
            if(type === 'G') return false;
            return true;
        default :
            return true;
    }
}