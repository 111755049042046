import React from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const TrioCard = props => {
  const { title, stickerList } = props

  const division = (arr, n) => {
    const len = stickerList.length
    const divide = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0)
    const newArr = []

    for (let i = 0; i < divide; i++) {
      newArr.push(arr.slice(i * 3, i * 3 + 3))
    }

    return newArr
  }

  return (
    <>
      <Title>{title}</Title>
      <SpaceResponsive height={16} />
      <Wrapper>
        {division(stickerList, 3).map((trio, i) => (
          <StickerWrapper
            key={i}
            bgColor={
              trio.filter((s, i) => s.bgColor !== null)[0]
                ? trio.filter((s, i) => s.bgColor !== null)[0].bgColor
                : '#a1e4bf'
            }
          >
            {trio &&
              trio.map((sticker, index) =>
                index % 3 === 0 ? (
                  <StickerMain key={index}>
                    <img src={sticker.stickerImg} alt="" />
                  </StickerMain>
                ) : index % 3 === 1 ? (
                  <StickerItem key={index} n={index % 3}>
                    <img src={sticker.stickerImg} alt="" />
                  </StickerItem>
                ) : (
                  <StickerItem key={index} n={index % 3}>
                    <img src={sticker.stickerImg} alt="" />
                  </StickerItem>
                )
              )}
          </StickerWrapper>
        ))}
      </Wrapper>
      <SpaceResponsive height={34} />
    </>
  )
}

export default TrioCard

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  position: relative;
  display: flex;

  /* scrollbar-color: transparent transparent;
  scrollbar-width: 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  } */
`

const StickerWrapper = styled.div`
  min-width: 246px;
  height: 180px;
  background-color: ${props => props.bgColor};
  border-radius: 36px;
  margin-right: 9.7px;
  padding: 25px 19px;
  position: relative;
`

const StickerMain = styled.div`
  width: 130px;
  height: 130px;
  position: absolute;
  top: 25px;
  left: 19px;

  img {
    width: 100%;
    height: 100%;
  }
`

const StickerItem = styled.div`
  width: 65px;
  height: 65px;
  position: absolute;
  right: 19px;
  bottom: ${props => props.n === 2 && '25px'};

  img {
    width: 90%;
    height: 90%;
  }
`
