import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
/* ArtistList 에서 그룹 추가 버튼 클릭 시 열리는 Modal */

const ArtistGroupAddPopup = (props) => {
    const [groupName, setGroupName] = useState("");
    const [isComplete, setIsComplete] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const handleSubmit = () => {
        if(groupName.length){
            setIsComplete(true);
            props.addGroup({NAME : groupName})
        } else {
            setIsEmpty(true);
        }
    }
    const handleClose = () => props.setShow(false);
    
    return (
        <Modal show={props.show} onHide={handleClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>작가 그룹 생성</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <font size={4}>{isComplete ? <p>그룹을 성공적으로 생성했습니다.</p> : <p>그룹 이름 : <input type="text" name="add_group" placeholder="Group Name" onChange={(e) => {setGroupName(e.target.value); setIsEmpty(false)}}/></p>}</font>
                {isEmpty ? <p style={{color:"red", fontSize: 'small'}}>ERROR: 그룹 이름은 1글자 이상이어야 합니다.</p> : ""}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-default pull-right" onClick={handleClose} data-dismiss="modal">닫기</Button>
                {isComplete ? "" : <button className="btn btn-primary pull-right" style={{marginRight:"5px"}} onClick={()=>handleSubmit()}>생성</button>}
            </Modal.Footer>
        </Modal>
    )
}

export default ArtistGroupAddPopup;