import React from "react";
import ArtistQnaList from "../../Components/ArtistQna/ArtistQnaList";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const ArtistQnaListPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <ArtistQnaList
        pageNumber={query.pageNumber}
        search={query.search ? query.search : ""}
      />
    </React.Fragment>
  );
};

export default ArtistQnaListPage;
