import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MdOpenInNew } from "react-icons/md";
import { Fragment } from "react";
import DefaultButton from "../../_Components/Common/ButtunUI/DefaultButton";
import StudioBannerChart from "./StudioBannerChart";
import { useRef } from "react";
import axios from "axios";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 14px;
`;

const BannerTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BannerTopBox = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  width: 49%;
`;

const BannerTagText = styled.div`
  font-weight: bold;
  margin-right: 20px;
  width: 80px;
  min-width: 80px;
`;

const BannerTitleText = styled.div`
  word-break: break-all;
`;

const BannerTitleInput = styled.input`
  word-break: break-all;
`;

const BannerStatusText = styled.div`
  padding: 2px 4px;
  background-color: ${(props) => (props.status === 1 ? "#f0c33c" : "#8c8f94")};
  color: white;
  border-radius: 4px;
`;

const BannerCenter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f4f4f4;
`;

const BannerCenterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 49%;
`;

const BannerLinkBox = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 10px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f4f4f4;
`;

const BannerLinkOpen = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BannerLinkUrl = styled.div`
  word-break: break-all;
`;

const BannerLinkInput = styled.input`
  width: 100%;
  word-break: break-all;
`;

const BannerLinkIcon = styled(MdOpenInNew)`
  margin-left: 4px;
  margin-top: 2px;
`;

const BannerImageInputBox = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const BannerImageBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const BannerImageInput = styled.input``;

const BannerImage = styled.div`
  width: 100%;
  min-width: 250px;
  height: 200px;
  min-height: 100px;
  background-image: url(${(props) => encodeURI(props.bgImage)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const BannerChartTagBox = styled.div`
  display: flex;
  align-items: flex-start;
`;

const BannerTotalClicks = styled.div`
  margin-right: 20px;
`;

const BannerChartBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
  height: 400px;
`;

const BannerUnderBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding-top: 10px;
`;

const BannerUnderBarBox = styled.div`
  display: flex;
`;

const EmptyBanner = styled.div``;

const StudioBannerDetail = ({
  reload,
  setReload,
  selectedBanner,
  activatedList,
  activeLength,
}) => {
  const [banner, setBanner] = useState(null);
  const [bannerImageEn, setBannerImageEn] = useState(null);
  const [bannerImageKo, setBannerImageKo] = useState(null);
  const [isOnEdit, setIsOnEdit] = useState(false);

  const [inputTitle, setInputTitle] = useState("");
  const [inputLinkEn, setInputLinkEn] = useState("");
  const [inputLinkKo, setInputLinkKo] = useState("");
  const imageEnRef = useRef(null);
  const imageKoRef = useRef(null);
  const [inputImageEn, setInputImageEn] = useState(null);
  const [inputImageKo, setInputImageKo] = useState(null);

  useEffect(() => {
    if (selectedBanner) {
      setIsOnEdit(false);
      getStudioBannerImages();
    }
  }, [selectedBanner]);

  const initInputs = () => {
    setInputTitle(banner.title);
    setInputLinkEn(banner.images[0].linkUrl);
    setInputLinkKo(banner.images[1].linkUrl);
    setInputImageEn(banner.images[0].imageUrl);
    setInputImageKo(banner.images[1].imageUrl);
  };

  const getStudioBannerImages = () => {
    fetch("/new/webAdmin/v2/studio-banners/" + selectedBanner.bannerId, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const tempBanner = responseJson.banner;
        setBanner(tempBanner);
        const tempImages = tempBanner.images;
        tempImages.map((image) =>
          image.language === "en"
            ? setBannerImageEn(image)
            : setBannerImageKo(image)
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateStudioBannerStatus = (req) => {
    axios
      .put("/new/webAdmin/v2/studio-banners/status/place", req, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setBanner({ ...banner, status: 1 - banner.status, place: req.place });
          setReload(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteStudioBanner = () => {
    fetch("/new/webAdmin/v2/studio-banners/" + selectedBanner.bannerId, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "DELETE",
    })
      .then(() => {
        setReload(true);
        setBanner(null);
        alert("Selected banner is deleted.");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickLink = (url) => {
    window.open(url);
  };

  const onClickToggleEdit = () => {
    if (!isOnEdit) initInputs();
    setIsOnEdit(!isOnEdit);
  };

  const onClickSave = () => {
    if (!inputTitle) {
      alert("Please input Banner Title.");
      return;
    }
    if (!inputLinkEn) {
      alert("Please input Banner Link (EN).");
      return;
    }
    if (!inputLinkKo) {
      alert("Please input Banner Link (EN).");
      return;
    }

    let editedBanner = new FormData();
    editedBanner.append("title", inputTitle);
    editedBanner.append("bannerImages", imageEnRef.current.files[0]);
    editedBanner.append("bannerImages", imageKoRef.current.files[0]);
    const banners = [
      {
        linkUrl: inputLinkEn,
        language: "en",
        bannerImageId: banner.images[0].bannerImageId,
        isBannerChanged: inputImageEn !== banner.images[0].imageUrl,
      },
      {
        linkUrl: inputLinkKo,
        language: "ko",
        bannerImageId: banner.images[1].bannerImageId,
        isBannerChanged: inputImageKo !== banner.images[1].imageUrl,
      },
    ];
    editedBanner.append("banners", JSON.stringify(banners));

    fetch(`/new/webAdmin/v2/studio-banners/${banner.bannerId}/update`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "PUT",
      body: editedBanner,
    })
      .then(() => {
        setReload(true);
        getStudioBannerImages();
        alert("Banner update success.");
        setIsOnEdit(false);
      })
      .catch((error) => {
        console.error(error);
        alert("Banner update fail.");
      });
  };

  const onClickToggleActivate = () => {
    if (!reload) {
      let statusText = banner.status ? "**Deactivated**" : "**Activated**";
      const result = window.confirm(
        "Selected Banner will be " + statusText + ". want to continue?"
      );
      if (result) {
        const req = {
          bannerId: selectedBanner.bannerId,
          place:
            selectedBanner.status === 0
              ? activeLength === 0
                ? 1
                : activatedList[activeLength - 1].place + 1
              : 127,
        };
        updateStudioBannerStatus(req);
      } else {
      }
    }
  };

  const onClickDeleteBanner = () => {
    const result = window.confirm(
      "Selected Banner will be **Deleted**. want to continue?"
    );
    if (result) {
      deleteStudioBanner();
    } else {
    }
  };

  return (
    <Wrapper>
      {banner && bannerImageEn && bannerImageKo ? (
        isOnEdit ? (
          <Fragment>
            <BannerTop>
              <BannerTopBox>
                <BannerTagText>Title</BannerTagText>
                <BannerTitleInput
                  type={"text"}
                  value={inputTitle}
                  onChange={(e) => {
                    setInputTitle(e.target.value);
                  }}
                />
              </BannerTopBox>
            </BannerTop>
            <BannerCenter>
              <BannerCenterBox>
                <BannerLinkBox>
                  <BannerTagText>Link (EN)</BannerTagText>
                  <BannerLinkInput
                    type={"text"}
                    value={inputLinkEn}
                    onChange={(e) => {
                      setInputLinkEn(e.target.value);
                    }}
                  />
                </BannerLinkBox>
                <BannerImageBox>
                  <BannerImageInputBox>
                    <BannerTagText>Image (EN)</BannerTagText>
                    <BannerImageInput
                      type={"file"}
                      accept={"image/jpg,image/jpeg,image/gif,image/png"}
                      ref={imageEnRef}
                      onChange={() => {
                        if (imageEnRef.current.files.length !== 0)
                          setInputImageEn(
                            URL.createObjectURL(imageEnRef.current.files[0])
                          );
                      }}
                    />
                  </BannerImageInputBox>
                  <BannerImage bgImage={inputImageEn} />
                </BannerImageBox>
              </BannerCenterBox>
              <BannerCenterBox>
                <BannerLinkBox>
                  <BannerTagText>Link (KO)</BannerTagText>
                  <BannerLinkInput
                    type={"text"}
                    value={inputLinkKo}
                    onChange={(e) => {
                      setInputLinkKo(e.target.value);
                    }}
                  />
                </BannerLinkBox>
                <BannerImageBox>
                  <BannerImageInputBox>
                    <BannerTagText>Image (KO)</BannerTagText>
                    <BannerImageInput
                      type={"file"}
                      accept={"image/jpg,image/jpeg,image/gif,image/png"}
                      ref={imageKoRef}
                      onChange={() => {
                        if (imageKoRef.current.files.length !== 0)
                          setInputImageKo(
                            URL.createObjectURL(imageKoRef.current.files[0])
                          );
                      }}
                    />
                  </BannerImageInputBox>
                  <BannerImage bgImage={inputImageKo} />
                </BannerImageBox>
              </BannerCenterBox>
            </BannerCenter>
            <BannerUnderBar>
              <BannerUnderBarBox>
                <DefaultButton btnText={"CANCEL"} onClick={onClickToggleEdit} />
                <DefaultButton
                  btnText={"SAVE"}
                  theme={"yellow"}
                  onClick={onClickSave}
                  style={{ marginLeft: "10px" }}
                />
              </BannerUnderBarBox>
            </BannerUnderBar>
          </Fragment>
        ) : (
          <Fragment>
            <BannerTop>
              <BannerTopBox>
                <BannerTagText>Title</BannerTagText>
                <BannerTitleText>{banner.title}</BannerTitleText>
              </BannerTopBox>
              <BannerTopBox>
                <BannerTagText>Status</BannerTagText>
                <BannerStatusText status={banner.status}>
                  {banner.status === 1 ? "Activated" : "Deactivated"}
                </BannerStatusText>
              </BannerTopBox>
            </BannerTop>
            <BannerCenter>
              <BannerCenterBox>
                <BannerLinkBox>
                  <BannerTagText>Link (EN)</BannerTagText>
                  <BannerLinkOpen
                    onClick={() => onClickLink(bannerImageEn.linkUrl)}
                  >
                    <BannerLinkUrl>{bannerImageEn.linkUrl}</BannerLinkUrl>
                    <BannerLinkIcon size={16} />
                  </BannerLinkOpen>
                </BannerLinkBox>
                <BannerImage bgImage={bannerImageEn.imageUrl} />
              </BannerCenterBox>
              <BannerCenterBox>
                <BannerLinkBox>
                  <BannerTagText>Link (KO)</BannerTagText>
                  <BannerLinkOpen
                    onClick={() => onClickLink(bannerImageKo.linkUrl)}
                  >
                    <BannerLinkUrl>{bannerImageKo.linkUrl}</BannerLinkUrl>
                    <BannerLinkIcon size={16} />
                  </BannerLinkOpen>
                </BannerLinkBox>
                <BannerImage bgImage={bannerImageKo.imageUrl} />
              </BannerCenterBox>
            </BannerCenter>
            <BannerChartBox>
              <BannerChartTagBox>
                <BannerTagText>Total Clicks</BannerTagText>
                <BannerTotalClicks>
                  {selectedBanner.images[0].numOfClicks} (EN)
                </BannerTotalClicks>
                <BannerTotalClicks>
                  {selectedBanner.images[1].numOfClicks} (KO)
                </BannerTotalClicks>
              </BannerChartTagBox>
              <StudioBannerChart bannerImages={selectedBanner.images} />
            </BannerChartBox>
            <BannerUnderBar>
              <DefaultButton btnText={"EDIT"} onClick={onClickToggleEdit} />
              <BannerUnderBarBox>
                <DefaultButton
                  btnText={banner.status === 1 ? "DEACTIVATE" : "ACTIVATE"}
                  theme={banner.status === 1 ? "default" : "green"}
                  onClick={onClickToggleActivate}
                />
                <DefaultButton
                  style={{ marginLeft: 10 }}
                  btnText={"DELETE"}
                  theme={"red"}
                  onClick={onClickDeleteBanner}
                />
              </BannerUnderBarBox>
            </BannerUnderBar>
          </Fragment>
        )
      ) : (
        <EmptyBanner>Please select banner.</EmptyBanner>
      )}
    </Wrapper>
  );
};

export default StudioBannerDetail;
