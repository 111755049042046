import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { IoMdArrowDropupCircle, IoMdArrowDropdownCircle } from "react-icons/io";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  font-size: 15px;
`;

const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.isActive
      ? props.isClicked
        ? Colors.active.click
        : Colors.active.default
      : props.isClicked
      ? Colors.deactive.click
      : Colors.deactive.default};
  border-radius: 4px;
  color: white;
  cursor: pointer;
  box-shadow: 0 1px 1px #00000020;
  :hover {
    background-color: ${(props) =>
      props.isActive
        ? props.isClicked
          ? Colors.active.click
          : Colors.active.hover
        : props.isClicked
        ? Colors.deactive.click
        : Colors.deactive.hover};
  }
`;

const LeftBox = styled.div`
  display: flex;
  width: 40px;
  min-width: 40px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${(props) =>
    props.isActive ? Colors.active.click : Colors.deactive.click};
`;

const OrderText = styled.div``;

const RightBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const OrderControlBox = styled.div`
  display: flex;
  width: 30px;
  min-width: 30px;
  flex-direction: column;
  justify-content: ${(props) =>
    props.index === props.start
      ? "flex-end"
      : props.index === props.end
      ? "flex-start"
      : "space-between"};
  color: #1ed14b;
`;

const OrderUp = styled(IoMdArrowDropupCircle)`
  padding: 1px;
  cursor: pointer;
`;

const OrderDown = styled(IoMdArrowDropdownCircle)`
  padding: 1px;
  cursor: pointer;
`;

const Title = styled.div`
  padding: 10px;
`;

const Colors = {
  active: {
    default: "#1ed14b",
    hover: "#00ba37",
    click: "#00a32a",
    left: "#00a32a",
  },
  deactive: {
    default: "#c3c4c7",
    hover: "#a7aaad",
    click: "#8c8f94",
    left: "#8c8f94",
  },
};

const StudioBannerBox = ({
  index,
  banner,
  isActive,
  onClickBannerBox,
  onEditOrders,
  bannerList,
  setBannerList,
  setRefreshIndex,
  activeLength,
  refresh,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const onClickHandler = () => {
    onClickBannerBox(banner.bannerId);
  };

  const onClickOrderUp = async () => {
    if (index < 1) return;

    const tempPlace = bannerList[index].place;
    bannerList[index].place = bannerList[index - 1].place;
    bannerList[index - 1].place = tempPlace;
    bannerList.sort((a, b) => {
      if (a.place < b.place) return -1;
      if (a.place > b.place) return 1;
      return 0;
    });
    setBannerList(bannerList);
    setRefreshIndex(index);
  };

  const onClickOrderDown = () => {
    if (index > activeLength) return;

    const tempPlace = bannerList[index].place;
    bannerList[index].place = bannerList[index + 1].place;
    bannerList[index + 1].place = tempPlace;
    bannerList.sort((a, b) => {
      if (a.place < b.place) return -1;
      if (a.place > b.place) return 1;
      return 0;
    });
    setBannerList(bannerList);
    setRefreshIndex(index + 1);
  };

  return (
    <Wrapper>
      {onEditOrders && (
        <OrderControlBox index={index} start={0} end={activeLength}>
          {index !== 0 && <OrderUp onClick={onClickOrderUp} size={24} />}
          {index !== activeLength - 1 && (
            <OrderDown onClick={onClickOrderDown} size={24} />
          )}
        </OrderControlBox>
      )}
      <BannerWrapper
        onMouseDown={() => {
          setIsClicked(true);
        }}
        onMouseUp={() => {
          setIsClicked(false);
        }}
        onClick={onClickHandler}
        isActive={isActive}
        isClicked={isClicked}
      >
        {isActive && banner.place !== 127 ? (
          <LeftBox isActive={isActive}>
            <OrderText>{index + 1}</OrderText>
          </LeftBox>
        ) : (
          <LeftBox isActive={isActive}>
            <OrderText>-</OrderText>
          </LeftBox>
        )}
        <RightBox>
          <Title>{!refresh ? banner.title : ""}</Title>
        </RightBox>
      </BannerWrapper>
    </Wrapper>
  );
};

export default StudioBannerBox;
