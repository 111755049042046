import React from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const StickerList = props => {
  const { title, stickerList } = props
  return (
    <>
      <Title>{title}</Title>
      <SpaceResponsive height={16} />
      <Wrapper>
        <StickerWrapper>
          {stickerList &&
            stickerList.map((sticker, index) => (
              <StickerItem key={index} onClick={() => {}}>
                <img src={sticker.stickerImg} alt="" />
              </StickerItem>
            ))}
        </StickerWrapper>
      </Wrapper>
      <SpaceResponsive height={34} />
    </>
  )
}

export default StickerList

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  position: relative;

  /* scrollbar-color: transparent transparent;
  scrollbar-width: 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  } */
`

const StickerWrapper = styled.div`
  display: flex;
`

const StickerItem = styled.div`
  min-width: 120px;
  height: 118px;
  background-color: #fff;
  border-radius: 36px;
  margin-right: 6.4px;
  padding: 12px 13px;

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`
