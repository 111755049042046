import React, { Fragment } from "react";
import styled from "styled-components";
import { MdMore, MdChevronRight } from "react-icons/md";
import { useHistory } from "react-router-dom";

/**
 *
 * Page Title Component
 *
 * - 각 페이지 상단에 현재 페이지 표시
 * - Main, Sub Title(좌측) & Page Navigation Route(우측) 제공
 *
 */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  padding-bottom: 0px;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
`;

const MainTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const SubTitle = styled.div`
  font-size: 14px;
  color: #777777;
  margin-bottom: 2px;
  margin-left: 8px;
  cursor: pointer;
`;

const PageNavBox = styled.div`
  display: flex;
  align-items: center;
`;

const PageNavIcon = styled(MdMore)`
  margin-right: 4px;
`;

const PageNavName = styled.div`
  font-size: 12px;
  margin-left: 4px;
  margin-right: 4px;
`;

const PageTitle = ({
  mainTitle = "메인타이틀",
  subTitle = "≫ click - move back to admin version 1. ≪",
  v1Link = "/",
  navNames = [],
}) => {
  const history = useHistory();

  return (
    <Wrapper>
      <TitleBox>
        <MainTitle>{mainTitle}</MainTitle>
        {v1Link !== "" && (
          <SubTitle
            onClick={() => {
              history.push(v1Link);
            }}
          >
            {subTitle}
          </SubTitle>
        )}
      </TitleBox>
      <PageNavBox>
        <PageNavIcon size={14} />
        {navNames &&
          navNames.map((name, index) => {
            return (
              <Fragment key={index}>
                <PageNavName>{name}</PageNavName>
                {index !== navNames.length - 1 && <MdChevronRight size={14} />}
              </Fragment>
            );
          })}
      </PageNavBox>
    </Wrapper>
  );
};

export default PageTitle;
