import React from "react";
import SearchApi from "../../Components/B2B/searchApi";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const SearchApiPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <SearchApi pageNumber={query.pageNumber} search={query.search} />
    </React.Fragment>
  );
};

export default SearchApiPage;
