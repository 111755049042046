import React from 'react';
import { Container, Navbar } from 'react-bootstrap'

const StudioBannerTopBar = (props) => {
    return (
        <Navbar bg="light" expand="lg" style={{minWidth: "1000px", maxWidth: "1500px", margin: "10px"}} >
            <Container fluid>
            </Container>
        </Navbar>
    )
}

export default StudioBannerTopBar;