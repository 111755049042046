import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  Collapse,
  Form,
  Button,
} from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import { FiTag } from 'react-icons/fi'
import readAsDataURL from '../../utils/readAsDataURL'
import _ from 'lodash'
import axios from 'axios'
import LoadingSpinner from '../Common/Loading/LoadingSpinner'

/*  ContentsInfoModal을 구성하는 주요 Section
    1) render 함수 : 화면에 Section에 들어갈 내용을 rendering 하는 함수
    2) add 함수 : 목록에 값을 추가, 서버와 통신
    3) remove 함수 : 목록에서 값을 제거, 서버와 통신 */

export const StickersSection = props => {
  const [stickers, setStickers] = useState([])
  const [main, setMain] = useState('')
  const [showGuideline, setShowGuideline] = useState(false)
  const [tray, setTray] = useState('')
  const [currentTray, setCurrentTray] = useState(1)
  const previewImg = [1, 2, 3, 4, 5, 6, 7, 8]
  const trayList = [-1, 0, 1, 2, 3, 4, 5, 6]
  const [inputTag, setInputTag] = useState({ id: '', tag: '' })
  const [prioritized, setPrioritized] = useState(
    trayList.map(n => {
      const trayItem = {
        tray:
          n === -1
            ? 'https://img.stipop.io/image/studio/upload/star_color.png'
            : n === 0
            ? tray
            : `https://img.stipop.io/image/studio/upload/tray/${n}.png`,
        img:
          n === -1
            ? previewImg.map(
                n =>
                  `https://img.stipop.io/image/studio/upload/preview/${n}.png`
              )
            : n === 0
            ? stickers
              ? stickers.map(sticker =>
                  sticker.webp
                    ? sticker.webp
                    : sticker.s200
                    ? sticker.s200
                    : sticker.s_img
                )
              : props.stickers.map(sticker =>
                  sticker.webp
                    ? sticker.webp
                    : sticker.s200
                    ? sticker.s200
                    : sticker.s_img
                )
            : [],
        num: n + 1,
      }
      return trayItem
    })
  )

  const [trayInput, setTrayInput] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(-1)
  const imgSize = ['', '300', '408', '618', '200', '96', '512']
  const [imgType, setImgType] = useState('')
  const [imgWidth, setImgWidth] = useState('')
  const [imgHeight, setImgHeight] = useState('')
  const [inputW, setInputW] = useState('')
  const [inputH, setInputH] = useState('')
  const [webpLoading, setWebpLoading] = useState(false)

  const history = useHistory()

  const trayRef = useRef()

  useEffect(() => {
    if (deleteOpen === -1) {
      document.addEventListener('click', props.clickOutSide, false)
    }

    if (trayInput === false) {
      document.addEventListener('click', props.clickOutSide, false)
    }
  }, [deleteOpen, trayInput])

  useEffect(() => {
    setStickers(props.stickers)
  }, [props.stickers])

  useEffect(() => {
    setTray(props.package.tray)
    setMain(props.package.p_img)
  }, [props.package])

  useEffect(() => {
    if (trayRef.current) {
      trayRef.current.scrollTo(0, 0)
    }
  }, [props.package.p_id])

  useEffect(() => {
    setPrioritized(
      trayList.map(n => {
        const trayItem = {
          tray:
            n === -1
              ? 'https://img.stipop.io/image/studio/upload/star_color.png'
              : n === 0
              ? tray
              : `https://img.stipop.io/image/studio/upload/tray/${n}.png`,
          img:
            n === -1
              ? previewImg.map(
                  n =>
                    `https://img.stipop.io/image/studio/upload/preview/${n}.png`
                )
              : n === 0
              ? stickers
                ? stickers.map(sticker =>
                    sticker.webp
                      ? sticker.webp
                      : sticker.s200
                      ? sticker.s200
                      : sticker.s_img
                  )
                : props.stickers.map(sticker =>
                    sticker.webp
                      ? sticker.webp
                      : sticker.s200
                      ? sticker.s200
                      : sticker.s_img
                  )
              : [],
          num: n + 1,
        }
        return trayItem
      })
    )
  }, [stickers, tray])

  useEffect(() => {
    if (!trayInput) {
      document.removeEventListener('click', props.clickOutSide, false)
    } else {
      document.addEventListener('click', props.clickOutSide, false)
    }
  }, [trayInput])

  useEffect(() => {
    setImgType('')
    setInputW('')
    setInputH('')
    setImgWidth('')
    setImgHeight('')
  }, [props.preview])

  const handleToggle = () => {
    setShowGuideline(!showGuideline)
  }

  const deleteSticker = sid => {
    const params = {
      s_id: sid,
    }
    fetch('/new/webAdmin/v2/contents/sticker', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'DELETE',
      body: JSON.stringify(params),
    })
      .then(() => {
        setStickers(props.stickers.filter(s => s.s_id !== sid))
        props.updateStickers(props.package.p_id, sid)
        setDeleteOpen(-1)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const getFormDataSize = formData =>
    [...formData].reduce(
      (size, [name, value]) =>
        size + (typeof value === 'string' ? value.length : value.size),
      0
    )

  // const convertWebp = () => {
  //   setWebpLoading(true)
  //   axios.post(`/new/webAdmin/v2/webp`, { p_id: props.package.p_id }, {
  //     headers: {
  //       token: sessionStorage.getItem("token")
  //     }
  //   }).then(() => {
  //     setWebpLoading(false)
  //   })
  // }

  const renderStickers = () => {
    return (
      <StickerWrapper>
        {stickers.map(sticker => (
          <StickerBox
            key={sticker.s_id}
            // onClick={() => {
            //   const form = new FormData()
            //   form.append('image_url', sticker.s_img)
            //   axios
            //     .post(`https://api.picsart.io/tools/1.0/removebg`, form, {
            //       headers: {
            //         accept: 'application/json',
            //         'X-Picsart-API-Key': 'aca0vK95TN75qfYyqMoPFrT3RU2dIZgW',
            //       },
            //     })
            //     .then(({ data }) => {
            //       axios.post(
            //         `http://localhost:3000/v4.0.1/sticky/remove/background/${sticker.s_id}`,
            //         data.data,
            //         {
            //           headers: {
            //             apikey: 'a3e68b6c54d8132f6879d5bc2c49708d',
            //           },
            //         }
            //       )
            //     })
            // }}
          >
            <StickerTop>
              {sticker.s_id}
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Edit>
                  <input
                    type={'file'}
                    onClick={e => (e.target.value = null)}
                    onChange={e => {
                      e.persist()
                      Promise.all(
                        _.map(e.target.files, file =>
                          readAsDataURL(file, 512000, '500KB', 700)
                        )
                      )
                        .then(() => {
                          const formData = new FormData()
                          _.map(e.target.files, file => {
                            if (file.name.split('#').length > 1) {
                              const newFile = new File(
                                [file],
                                file.name.split('#').join(''),
                                {
                                  type: file.type,
                                }
                              )
                              formData.append('sticker', newFile)
                            } else {
                              formData.append('sticker', file)
                            }
                          })
                          if (getFormDataSize(formData) > 0) {
                            formData.append(
                              'path',
                              sticker.s_img.split('/').pop().split('.')[0]
                            )
                            formData.append('tags', [].join())
                            axios
                              .put(
                                `/new/webAdmin/v2/contents/sticker/${sticker.s_id}`,
                                formData,
                                {
                                  headers: {
                                    token: sessionStorage.getItem('token'),
                                  },
                                }
                              )
                              .then(({ data }) => {
                                props.updateStickerImage(
                                  props.package.p_id,
                                  sticker.s_id,
                                  data.body
                                )
                              })
                              .catch(error => {
                                console.warn(error)
                              })
                          }
                        })
                        .catch(error => {
                          alert(error)
                          console.warn(error)
                        })
                    }}
                    accept={props.package.aniPic === 0 ? '.png' : '.gif'}
                  />
                  <StickerTopBtn
                    src="https://img.stipop.io/image/studio/upload/stickerEdit.png"
                    style={{ marginRight: '6px' }}
                  />
                </Edit>
                <StickerTopBtn
                  id="delete"
                  src="https://img.stipop.io/image/studio/upload/stickerDelete.png"
                  onClick={() => {
                    if (deleteOpen === sticker.s_id) {
                      setDeleteOpen(-1)
                    } else {
                      setDeleteOpen(sticker.s_id)
                    }
                  }}
                />
              </div>
            </StickerTop>
            <StickerMid>
              <Sticker
                src={
                  sticker.webp
                    ? sticker.webp
                    : sticker.s200
                    ? sticker.s200
                    : sticker.s_img
                }
                alt=""
              />
              <StickerTagWrapper>
                {props.sLoading === sticker.s_id && (
                  <div
                    style={{
                      width: '100%',
                      height: 'calc(100% - 12px)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      zIndex: 99,
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                )}
                <Link
                  to={`/Keyword/Untagged/Add/${sticker.s_id}`}
                  target="_blank"
                >
                  <EditTag>
                    <FiTag size={12} />
                    <span>Tags</span>
                  </EditTag>
                </Link>
                <TagWrapper>
                  {sticker.tags &&
                    sticker.tags.split(',').map((tag, idx) => (
                      <StickerTag
                        key={idx}
                        onClick={async () => {
                          await props.setSLoading(sticker.s_id)
                          if (props.package.commit === 1) {
                            await props.deleteStickerOneTag(sticker.s_id, tag)
                          }
                          await props.updateTag(
                            sticker.s_id,
                            sticker.tags
                              .split(',')
                              .filter(t => t !== tag)
                              .join()
                          )
                          await props.setSLoading(0)
                        }}
                      >
                        <span>{tag}</span>
                      </StickerTag>
                    ))}
                </TagWrapper>
              </StickerTagWrapper>
            </StickerMid>
            <StickerBottom>
              {sticker.s_id === deleteOpen ? (
                <DeleteCheck>
                  <div>스티커를 삭제하시겠습니까?</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DeleteBtn
                      delete={true}
                      onClick={() => deleteSticker(deleteOpen)}
                      style={{ marginRight: '5px' }}
                    >
                      삭제
                    </DeleteBtn>
                    <DeleteBtn
                      onClick={() => {
                        document.removeEventListener(
                          'click',
                          props.clickOutSide,
                          false
                        )
                        setDeleteOpen(-1)
                      }}
                    >
                      취소
                    </DeleteBtn>
                  </div>
                </DeleteCheck>
              ) : (
                <>
                  <StickerTagInput
                    deactive={props.sLoading === sticker.s_id}
                    type="text"
                    placeholder={'Add tags'}
                    value={inputTag.id === sticker.s_id ? inputTag.tag : ''}
                    onChange={e => {
                      setInputTag({ id: sticker.s_id, tag: e.target.value })
                    }}
                    onKeyPress={async e => {
                      if (e.key === 'Enter') {
                        if (inputTag.tag.length > 0) {
                          if (sticker.tags) {
                            if (sticker.tags.split(',').length < 10) {
                              if (
                                sticker.tags.split(',').indexOf(inputTag.tag) <
                                0
                              ) {
                                await props.setSLoading(sticker.s_id)
                                if (props.package.commit === 1) {
                                  await props.insertStickerOneTag(
                                    sticker.s_id,
                                    inputTag.tag
                                  )
                                }
                                await props.updateTag(
                                  sticker.s_id,
                                  sticker.tags
                                    .split(',')
                                    .concat(inputTag.tag)
                                    .join()
                                )
                                await props.setSLoading(0)
                              } else {
                                alert('This tag already exists.')
                                setInputTag({ id: '', tag: '' })
                                return
                              }
                            } else {
                              alert(
                                'The maximum number of tags per sticker is 10.'
                              )
                              setInputTag({ id: '', tag: '' })
                              return
                            }
                          } else {
                            await props.setSLoading(sticker.s_id)

                            if (props.package.commit === 1) {
                              await props.insertStickerOneTag(
                                sticker.s_id,
                                inputTag.tag
                              )
                            }
                            await props.updateTag(
                              sticker.s_id,
                              new Array().concat(inputTag.tag).join()
                            )
                            await props.setSLoading(0)
                          }
                        }
                        setInputTag({ id: '', tag: '' })
                      }
                    }}
                    disabled={props.bulkEdit}
                  />
                  <TagCnt
                    warn={sticker.tags && sticker.tags.split(',').length > 10}
                  >
                    {sticker.tags ? sticker.tags.split(',').length : 0}/10
                  </TagCnt>
                </>
              )}
            </StickerBottom>
            {sticker.prompt && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Prompt>
                  <div className="title">Prompt</div>
                  <div className="text">{sticker.prompt}</div>
                </Prompt>
              </div>
            )}
          </StickerBox>
        ))}
      </StickerWrapper>
    )
  }

  const addFile = async size => {
    setImgType(size)
    const newWebp = await Promise.all(
      props.package.stickers.map(async item => {
        if (size === '') {
          return {
            ...item,
            file: item.webp ? await props.convertURLtoFile(item.webp) : null,
          }
        } else if (size === '300') {
          return {
            ...item,
            file: item.webp_300
              ? await props.convertURLtoFile(item.webp_300)
              : null,
          }
        } else if (size === '408') {
          return {
            ...item,
            file: item.webp_408
              ? await props.convertURLtoFile(item.webp_408)
              : null,
          }
        } else if (size === '618') {
          return {
            ...item,
            file: item.webp_618
              ? await props.convertURLtoFile(item.webp_618)
              : null,
          }
        } else if (size === '200') {
          return {
            ...item,
            file: item.webp_200
              ? await props.convertURLtoFile(item.webp_200)
              : null,
          }
        } else if (size === '96') {
          return {
            ...item,
            file: item.webp_96
              ? await props.convertURLtoFile(item.webp_96)
              : null,
          }
        } else if (size === '512') {
          return {
            ...item,
            file: item.webp_512
              ? await props.convertURLtoFile(item.webp_512)
              : null,
          }
        }
      })
    )
    props.setWebpImg(newWebp)
  }

  const renderPreview = type => {
    return (
      <PreviewWrapper>
        <PreviewBox>
          <PreviewMain>
            <img src={props.package.p_img} alt="" />
          </PreviewMain>
          <PreviewTitle>{props.packageName}</PreviewTitle>
          <PreviewStickerWrapper>
            {type === 'preview'
              ? stickers.map(sticker => (
                  <PreviewSticker
                    key={sticker.s_id}
                    width={imgWidth}
                    height={imgHeight}
                  >
                    <img
                      src={
                        imgType === '300'
                          ? sticker.s300
                          : imgType === '408'
                          ? sticker.s408
                          : imgType === '618'
                          ? sticker.s618
                          : imgType === '200'
                          ? sticker.s200
                          : imgType === '96'
                          ? sticker.s96
                          : sticker.s_img
                      }
                      alt=""
                    />
                  </PreviewSticker>
                ))
              : props.webpImg.map((webp, i) => (
                  <PreviewSticker key={i} style={{ flexDirection: 'column' }}>
                    <img
                      style={{ width: '80%', height: '80%' }}
                      src={
                        imgType === '300'
                          ? webp.webp_300
                          : imgType === '408'
                          ? webp.webp_408
                          : imgType === '618'
                          ? webp.webp_618
                          : imgType === '200'
                          ? webp.webp_200
                          : imgType === '96'
                          ? webp.webp_96
                          : imgType === '512'
                          ? webp.webp_512
                          : webp.webp
                      }
                      alt=""
                    />
                    {webp.file && (
                      <span
                        style={{
                          color:
                            props.package.aniPic === 2
                              ? Math.round(webp.file.size / 1000) > 500
                                ? 'red'
                                : '#000'
                              : Math.round(webp.file.size / 1000) > 100
                              ? 'red'
                              : '#000',
                        }}
                      >
                        {Math.round(webp.file.size / 1000)} KB
                      </span>
                    )}
                  </PreviewSticker>
                ))}
          </PreviewStickerWrapper>
        </PreviewBox>
      </PreviewWrapper>
    )
  }

  const renderTray = () => {
    return (
      <>
        <TrayTitle>Main image and app tray icon</TrayTitle>
        <div style={{ height: '30px' }}></div>
        <TrayWrapper>
          <MainImgWrapper>
            <MainImg src={main} />
            <Main src="https://img.stipop.io/image/studio/upload/main.png" />
            <input
              type={'file'}
              onClick={e => (e.target.value = null)}
              onChange={e => {
                e.persist()
                Promise.all(
                  _.map(e.target.files, file =>
                    readAsDataURL(file, 512000, '500KB', 700)
                  )
                )
                  .then(() => {
                    const formData = new FormData()
                    _.map(e.target.files, file => {
                      if (file.name.split('#').length > 1) {
                        const newFile = new File(
                          [file],
                          file.name.split('#').join(''),
                          {
                            type: file.type,
                          }
                        )
                        formData.append('main', newFile)
                      } else {
                        formData.append('main', file)
                      }
                    })
                    axios
                      .put(
                        `/new/webAdmin/v2/contents/main/${props.package.p_id}`,
                        formData,
                        {
                          headers: {
                            token: sessionStorage.getItem('token'),
                          },
                        }
                      )
                      .then(({ data }) => {
                        props.updatePackageMain(props.package.p_id, data.body)
                        setMain(data.body)
                      })
                      .catch(err => {
                        console.warn(err)
                      })
                  })
                  .catch(error => {
                    alert(error)
                    console.warn(error)
                  })
              }}
              accept={props.package.aniPic === 0 ? '.png' : '.gif'}
            />
          </MainImgWrapper>
          <TrayBox>
            <TrayIconWrapper>
              {prioritized.map((item, idx) => (
                <TrayIcon
                  key={idx}
                  onClick={() => {
                    setCurrentTray(item.num)
                    if (item.num === 1) {
                      if (currentTray === 1) {
                        // document.removeEventListener(
                        //   'click',
                        //   props.clickOutSide,
                        //   false
                        // )
                        setTrayInput(true)
                      }
                    } else {
                      setTrayInput(false)
                    }
                  }}
                  current={currentTray === item.num}
                  tray={tray ? true : false}
                  style={{
                    filter: item.num === 1 && !tray && 'saturate(100%)',
                    borderTopRightRadius: item.num === 7 && '8px',
                    borderTopLeftRadius: item.num === 0 && '8px',
                  }}
                >
                  <input
                    type={'file'}
                    onClick={e => (e.target.value = null)}
                    disabled={currentTray !== 1 || !trayInput}
                    onChange={e => {
                      e.persist()
                      Promise.all(
                        _.map(e.target.files, file =>
                          readAsDataURL(file, 512000, '500KB', 100)
                        )
                      )
                        .then(() => {
                          const formData = new FormData()
                          _.map(e.target.files, file => {
                            if (file.name.split('#').length > 1) {
                              const newFile = new File(
                                [file],
                                file.name.split('#').join(''),
                                {
                                  type: file.type,
                                }
                              )
                              formData.append('tray', newFile)
                            } else {
                              formData.append('tray', file)
                            }
                          })
                          axios
                            .put(
                              `/new/webAdmin/v2/contents/tray/${props.package.p_id}`,
                              formData,
                              {
                                headers: {
                                  token: sessionStorage.getItem('token'),
                                },
                              }
                            )
                            .then(({ data }) => {
                              props.updatePackageTray(
                                props.package.p_id,
                                data.body
                              )
                              setTray(data.body)
                            })
                            .catch(err => {
                              console.warn(err)
                            })
                        })
                        .catch(error => {
                          alert(error)
                          console.warn(error)
                        })
                    }}
                    accept={'.png'}
                  />
                  <img
                    src={item.tray}
                    style={{
                      width: (item.num === 0 || item.num === 1) && '26px',
                    }}
                  />
                </TrayIcon>
              ))}
            </TrayIconWrapper>
            <TrayImageWrapper ref={trayRef}>
              {prioritized[currentTray].img.map((img, index) => (
                <TrayImage key={index}>
                  <img
                    src={img}
                    style={{
                      width: currentTray === 0 && '60px',
                      height: currentTray === 0 && '60px',
                    }}
                  />
                </TrayImage>
              ))}
            </TrayImageWrapper>
          </TrayBox>
        </TrayWrapper>
      </>
    )
  }

  return (
    <Container
      style={{
        width: '100%',
        padding: '0',
        marginTop: '20px',
      }}
    >
      {props.preview && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '10px',
            }}
          >
            {imgSize.map(
              (size, index) =>
                size !== '512' && (
                  <CustomBtn
                    key={index}
                    style={{
                      backgroundColor: size === imgType ? '#ff4a9c' : '#fff',
                      color: size === imgType ? '#fff' : '#ff4a9c',
                      marginLeft: '5px',
                    }}
                    onClick={() => setImgType(size)}
                  >
                    {size ? size : '원본'}
                  </CustomBtn>
                )
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <input
              type="text"
              value={inputW}
              onChange={e => setInputW(e.target.value)}
              style={{
                width: '100px',
                height: '24px',
                fontSize: '16px',
                margin: '0 5px',
              }}
            />
            <span>X</span>
            <input
              type="text"
              value={inputH}
              onChange={e => setInputH(e.target.value)}
              style={{
                width: '100px',
                height: '24px',
                fontSize: '16px',
                margin: '0 5px',
              }}
            />
            <CustomBtn
              onClick={() => {
                setImgWidth(inputW)
                setImgHeight(inputH)
              }}
            >
              적용
            </CustomBtn>
          </div>
        </div>
      )}
      {props.whatsApp && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '10px',
            }}
          >
            {imgSize.map((size, index) => (
              <CustomBtn
                key={index}
                style={{
                  backgroundColor: size === imgType ? '#ff4a9c' : '#fff',
                  color: size === imgType ? '#fff' : '#ff4a9c',
                  marginLeft: '5px',
                }}
                onClick={async () => {
                  addFile(size)
                }}
              >
                {size ? size : '원본'}
              </CustomBtn>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '10px',
            }}
          >
            <CustomBtn
              style={{ height: '30px', marginRight: '10px' }}
              onClick={() => {
                setImgType('')
                props.convertWebp()
              }}
            >
              Webp 다시 변환
            </CustomBtn>
            <CustomBtn
              style={{ height: '30px' }}
              onClick={() => {
                props.downloadWebp(imgType ? imgType : '700')
              }}
            >
              Webp 다운로드
            </CustomBtn>
          </div>
        </div>
      )}
      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Col
          style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}
          onClick={() => props.setOpen(!props.open)}
        >
          스티커 목록
          {props.open ? (
            <i className="fa fa-angle-up"></i>
          ) : (
            <i className="fa fa-angle-down"></i>
          )}
        </Col>
      </Row>
      <Collapse in={props.open}>
        <Container style={{ width: '100%', padding: '0' }}>
          {stickers.length ? (
            props.preview ? (
              <>
                {renderPreview('preview')}
                <div style={{ height: '50px' }} />
                {renderTray()}
              </>
            ) : props.whatsApp ? (
              webpLoading ? (
                <LoadingSpinner />
              ) : props.webpImg ? (
                <>
                  {renderPreview('whatsApp')}
                  <div style={{ height: '50px' }} />
                  {renderTray()}
                </>
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    height: '100px',
                  }}
                >
                  Webp 이미지가 존재하지 않습니다.
                </div>
              )
            ) : (
              <>
                {renderStickers()}
                <div style={{ height: '50px' }} />
                {renderTray()}
              </>
            )
          ) : (
            <font color="red">등록된 스티커가 없습니다</font>
          )}
        </Container>
      </Collapse>
    </Container>
  )
}

export function LibrariesSection(props) {
  const [selectedGroup, setSelectedGroup] = useState(0)
  const [libraries, setLibraries] = useState([])
  const [librariesOfThePackage, setLibrariesOfThePackage] = useState([])

  useEffect(() => {
    setSelectedGroup(0)
    setLibraries(props.libraries ? props.libraries : [])
    setLibrariesOfThePackage(
      props.librariesOfThePackage ? props.librariesOfThePackage : []
    )
  }, [props.librariesOfThePackage])

  const renderLibrariesDropdownMenu = () => {
    return libraries.map(library =>
      library.subtitle ? (
        <option value={JSON.stringify(library)} key={library.seq}>
          {library.title} - {library.subtitle}
        </option>
      ) : (
        <option value={JSON.stringify(library)} key={library.seq}>
          {library.title}
        </option>
      )
    )
  }

  const renderLibrariesOfThePackage = () => {
    return librariesOfThePackage.map(library => (
      <tr key={library.seq}>
        <td>
          {library.subtitle
            ? library.title + ' - ' + library.subtitle
            : library.title}
        </td>
        <td>
          <button onClick={() => removeGroup(library.seq)}>삭제</button>
        </td>
      </tr>
    ))
  }

  const addGroup = () => {
    if (selectedGroup === 0) {
      alert('항목을 선택하세요')
      return
    }

    const params = {
      p_id: props.package.p_id,
      seq: JSON.parse(selectedGroup).seq,
    }

    fetch('/new/webAdmin/v2/package/group', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(() =>
        setLibrariesOfThePackage(
          librariesOfThePackage.concat({
            seq: JSON.parse(selectedGroup).seq,
            title: JSON.parse(selectedGroup).title,
            subtitle: JSON.parse(selectedGroup).subtitle,
            glanguage: 'CO',
          })
        )
      )
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const removeGroup = seq => {
    const params = {
      p_id: props.package.p_id,
      seq: seq,
    }
    fetch('/new/webAdmin/v2/package/group', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'DELETE',
      body: JSON.stringify(params),
    })
      .then(() => {
        setLibrariesOfThePackage(
          librariesOfThePackage.filter(info => info.seq !== seq)
        )
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  return (
    <div
      style={{
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: '2px solid silver',
      }}
    >
      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Col
          style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}
          onClick={() => props.setOpen(!props.open)}
        >
          채널사 관리<i className="fa fa-angle-down"></i>
        </Col>
      </Row>
      <Collapse in={props.open}>
        <div>
          <Table striped bordered>
            <thead>
              <tr>
                <th width="90%">채널사 명(Library Name)</th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              {renderLibrariesOfThePackage()}
              <tr>
                <td>
                  <select
                    style={{ width: '100%' }}
                    value={selectedGroup}
                    onChange={event => setSelectedGroup(event.target.value)}
                  >
                    <option value={0}>항목을 선택하세요</option>
                    {renderLibrariesDropdownMenu()}
                  </select>
                </td>
                <td>
                  <button onClick={addGroup}>추가</button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Collapse>
    </div>
  )
}

export function SelfServingGroupsSection(props) {
  const [category, setCategory] = useState({
    categoryId: 0,
    subCategoryId: 0,
  })
  const [appCategory, setAppCategory] = useState([])
  // const selfServingGroupsOfThePackage = props.selfServingGroupsOfThePackage;
  const [selectedCategory, setSelectedCategory] = useState(
    props.selectedCategory
  )

  useEffect(() => {
    setCategory({
      categoryId: 0,
      subCategoryId: 0,
    })
    async function fetchData() {
      const response = await fetch(
        '/new/webAdmin/v2/package/category?p_id=' + props.package.p_id,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            token: sessionStorage.getItem('token'),
          },
          method: 'get',
        }
      )

      const responseJson = await response.json()
      if (responseJson.status === 'fail' && responseJson.code === '1111')
        props.history.push('/')

      setSelectedCategory(responseJson.body.category)
      setAppCategory(responseJson.body.appCategory)
    }
    fetchData()
  }, [props.package])

  const renderAppCategory = () => {
    return appCategory.map(tmp => (
      <option value={tmp.categoryId} key={tmp.categoryId}>
        {tmp.category}
      </option>
    ))
  }

  const renderAppSubCategory = () => {
    // if (category.categoryId*1 === 6) {
    //     return <>
    // } else {
    const [subCategory] = appCategory.filter(
      tmp => tmp.categoryId === category.categoryId * 1
    )

    return (
      subCategory &&
      subCategory.subCategoryList.map(tmp => (
        <option value={tmp.subCategoryId} key={tmp.subCategoryId}>
          {tmp.subCategory}
        </option>
      ))
    )
    // }
  }

  const renderSelfServingGroupsOfThePackage = () => {
    return selectedCategory.map(category => (
      <tr key={category.appCategoryId}>
        <td>{category.categoryName}</td>
        <td>{category.subCategoryName}</td>
        <td>
          <button onClick={() => removeGroup(category.appCategoryId)}>
            삭제
          </button>
        </td>
      </tr>
    ))
  }

  const addGroup = () => {
    if (category.categoryId === 0) {
      alert('항목을 선택하세요')
      return
    }

    const params = {
      p_id: props.package.p_id,
      categoryId: category.categoryId,
      subCategoryId: category.subCategoryId,
    }

    fetch('/new/webAdmin/v2/package/category', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'fail' && responseJson.code === '1111')
          props.history.push('/')
        setSelectedCategory(
          selectedCategory.concat({
            appCategoryId: responseJson.body.appCategoryId,
            categoryId: category.categoryId,
            subCategoryId: category.subCategoryId,
            categoryName: category.categoryName,
            subCategoryName: category.subCategoryName,
          })
        )
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const removeGroup = appCategoryId => {
    fetch('/new/webAdmin/v2/package/category/' + appCategoryId, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'DELETE',
    })
      .then(() => {
        setSelectedCategory(
          selectedCategory.filter(info => info.appCategoryId !== appCategoryId)
        )
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#eeeeee',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: '2px solid silver',
      }}
    >
      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Col
          style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}
          onClick={() => props.setOpen(!props.open)}
        >
          App category 관리 <i className="fa fa-angle-down"></i>
        </Col>
      </Row>
      <Collapse in={props.open}>
        <div>
          <Table striped bordered>
            <thead>
              <tr>
                <th width="45%">App category</th>
                <th width="45%"></th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              {selectedCategory && renderSelfServingGroupsOfThePackage()}
              <tr>
                <td>
                  {/* <select style={{width:"100%"}} onChange={(event) => setCategory({categoryId:event.target.value})}> */}
                  <select
                    style={{ width: '100%' }}
                    id="categoryId"
                    value={category.categoryId}
                    onChange={event => {
                      const tmp = event.target.value
                      const name = event.target.selectedOptions[0].text
                      setCategory(state => ({
                        ...state,
                        categoryId: tmp,
                        categoryName: name,
                      }))
                    }}
                  >
                    <option value={0}>app category 선택</option>
                    {renderAppCategory()}
                  </select>
                </td>
                <td>
                  {/* <select style={{width:"100%"}} onChange={(event) => setCategory({subCategoryId:event.target.value})}> */}
                  <select
                    style={{ width: '100%' }}
                    id="subCategoryId"
                    value={category.subCategoryId}
                    onChange={event => {
                      const tmp = event.target.value
                      const name = event.target.selectedOptions[0].text
                      setCategory(state => ({
                        ...state,
                        subCategoryId: tmp,
                        subCategoryName: name,
                      }))
                    }}
                  >
                    <option value={0}>sub category 선택</option>
                    {renderAppSubCategory()}
                  </select>
                </td>
                <td>
                  <button onClick={addGroup}>추가</button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Collapse>
    </div>
  )
}

export function RegionSection(props) {
  const [region, setRegion] = useState({})
  const regionList = props.region
  const [selectedRegion, setSelectedRegion] = useState([])

  useEffect(() => {
    setRegion({})
    setSelectedRegion(props.selectedRegion ? props.selectedRegion : [])
  }, [props.selectedRegion])

  const renderRegion = () => {
    return regionList.map(tmp => (
      <option value={tmp.regionId} key={tmp.regionId}>
        {tmp.region}
      </option>
    ))
  }

  const renderSelectedRegion = () => {
    return selectedRegion.map(region => (
      <tr key={region.id}>
        <td>{region.regionName}</td>
        <td>
          <button onClick={() => removeGroup(region.id)}>삭제</button>
        </td>
      </tr>
    ))
  }

  const addGroup = () => {
    if (region.regionId === 0) {
      alert('항목을 선택하세요')
      return
    }

    const params = {
      p_id: props.package.p_id,
      regionId: region.regionId,
    }

    fetch('/new/webAdmin/v2/package/region', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'fail' && responseJson.code === '1111')
          props.history.push('/')
        setSelectedRegion(
          selectedRegion.concat({
            id: responseJson.body.id,
            regionId: region.regionId,
            regionName: region.regionName,
          })
        )
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const removeGroup = id => {
    fetch('/new/webAdmin/v2/package/region/' + id, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'DELETE',
    })
      .then(() => {
        setSelectedRegion(selectedRegion.filter(info => info.id !== id))
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#eeeeee',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: '2px solid silver',
      }}
    >
      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Col
          style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}
          onClick={() => props.setOpen(!props.open)}
        >
          지역 관리 <i className="fa fa-angle-down"></i>
        </Col>
      </Row>
      <Collapse in={props.open}>
        <div>
          <Table striped bordered>
            <thead>
              <tr>
                <th width="95%">지역</th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              {renderSelectedRegion()}
              <tr>
                <td>
                  <select
                    style={{ width: '100%' }}
                    id="categoryId"
                    value={region.regionId}
                    onChange={event => {
                      const tmp = event.target.value
                      const name = event.target.selectedOptions[0].text
                      setRegion(state => ({
                        ...state,
                        regionId: tmp,
                        regionName: name,
                      }))
                    }}
                  >
                    <option value={0}>지역 선택</option>
                    {renderRegion()}
                  </select>
                </td>
                <td>
                  <button onClick={addGroup}>추가</button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Collapse>
    </div>
  )
}

export function GuidelinesSection(props) {
  const [params, setParams] = useState({
    p_id: props.package.p_id,
    g_id: '',
    guide: '',
  })
  const [guidelines, setGuidelines] = useState([])
  const [guidelinesOfThePackage, setGuidelinesOfThePackage] = useState([])
  const [mailLanguage, setMailLanguage] = useState('KOR')

  useEffect(() => {
    setGuidelinesOfThePackage(
      props.guidelinesOfThePackage ? props.guidelinesOfThePackage : []
    )
  }, [props.guidelinesOfThePackage])

  useEffect(() => {
    setParams({ p_id: props.package.p_id, g_id: '', guide: '' })
    setGuidelines(props.guidelines ? props.guidelines : [])
  }, [props.package])

  const renderGuidelinesDropdownMenu = () => {
    return guidelines.map(guideline => (
      <option value={guideline.seq} key={guideline.seq}>
        {guideline.number} : {guideline.contents}
      </option>
    ))
  }

  const renderGuidelinesOfThePackage = () => {
    return guidelinesOfThePackage.map(guideline => (
      <tr key={guideline.seq} value={guideline}>
        <td>{guideline.contents}</td>
        <td>
          <button onClick={() => removeGuideline(guideline.seq)}>삭제</button>
        </td>
      </tr>
    ))
  }

  const addGuideline = () => {
    fetch('/new/webAdmin/v2/reason', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'post',
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'fail' && responseJson.code === '1111')
          props.history.push('/')
        setGuidelinesOfThePackage(
          guidelinesOfThePackage.concat({
            seq: responseJson.seq,
            contents: params.guide,
          })
        )
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const removeGuideline = guideline_id => {
    fetch('/new/webAdmin/v2/reason', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'DELETE',
      body: JSON.stringify({ id: guideline_id }),
    })
      .then(response => {
        setGuidelinesOfThePackage(
          guidelinesOfThePackage.filter(info => info.seq !== guideline_id)
        )
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const sendEmail = () => {
    const params = {
      p_id: props.package.p_id,
      lang: mailLanguage,
    }

    fetch('/new/webAdmin/v2/reason/mail', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'post',
      body: JSON.stringify(params),
    })
      .then(response => {
        if (response.ok) props.updatePackageStatus(props.package.p_id, 2)
        else alert('메일 전송에 실패했습니다.')
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  return (
    <div style={{ width: '100%', marginTop: '10px' }}>
      <Row style={{ marginBottom: '10px' }}>
        <Col
          style={{ fontWeight: 'bold', textAlign: 'left', cursor: 'pointer' }}
          onClick={() => {
            props.setOpen(!props.open)
          }}
        >
          수정 요청 사유 관리<i className="fa fa-angle-down"></i>
        </Col>
      </Row>

      <TableWrapper open={props.open}>
        <Table striped bordered>
          <thead>
            <tr>
              <th width="90%">수정 요청 사유</th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody>
            {renderGuidelinesOfThePackage()}
            <tr>
              <td>
                <select
                  style={{ width: '100%' }}
                  value={params.g_id}
                  onChange={e =>
                    setParams({
                      ...params,
                      g_id: e.target.value,
                      guide: e.target.selectedOptions[0].text,
                    })
                  }
                >
                  {renderGuidelinesDropdownMenu()}
                </select>
              </td>
              <td>
                <button onClick={addGuideline}>추가</button>
              </td>
            </tr>
          </tbody>
        </Table>
        <div style={{ marginBottom: '10px', float: 'right' }}>
          <select
            style={{ marginRight: '5px' }}
            onChange={event => setMailLanguage(event.target.value)}
          >
            <option value="KOR">한국어</option>
            <option value="ENG">영어</option>
          </select>
          <button onClick={sendEmail}>수정 요청 메일 발송</button>
        </div>
      </TableWrapper>
    </div>
  )
}

export function AdSection(props) {
  const [isAd, setIsAd] = useState('')
  const [isShow, setIsShow] = useState('')
  const [isFree, setIsFree] = useState('')

  useEffect(() => {
    setIsAd(props.package.p_isAd)
    setIsShow(props.package.p_isShow)
    setIsFree(props.package.p_free)
  }, [props.package])

  const editIsAd = () => {
    const params = {
      pid: props.package.p_id,
      ad: isAd,
    }
    fetch('/new/webAdmin/v2/contents/ad', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(() => {
        props.updateIsAd(props.package.p_id, isAd)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const editIsShow = () => {
    const params = {
      pid: props.package.p_id,
      show: isShow,
    }
    fetch('/new/webAdmin/v2/contents/show', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(() => {
        props.updateIsShow(props.package.p_id, isShow)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const editIsFree = () => {
    const params = {
      pid: props.package.p_id,
      free: isFree,
    }
    fetch('/new/webAdmin/v2/contents/free', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(() => {
        props.updateIsFree(props.package.p_id, isFree)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#eeeeee',
        padding: '0 20px 10px 20px',
        borderBottom: '2px solid silver',
      }}
    >
      <Row style={{ paddingBottom: '10px' }}>
        <Col md={3} style={{ fontWeight: 'bold', borderRight: '2px solid' }}>
          앱 광고 시청 여부
        </Col>
        <Col md={6}>
          <form>
            <label style={{ marginRight: '25px' }}>
              <input
                type="radio"
                checked={isAd === 'Y'}
                onChange={() => setIsAd('Y')}
              ></input>
              광고 시청
            </label>
            <label style={{ marginRight: '25px' }}>
              <input
                type="radio"
                checked={isAd === 'N'}
                onChange={() => setIsAd('N')}
              ></input>
              유료 구매
            </label>
          </form>
        </Col>
        <Col md={3}>
          <button
            onClick={() => {
              editIsAd()
            }}
          >
            저장
          </button>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '10px' }}>
        <Col md={3} style={{ fontWeight: 'bold', borderRight: '2px solid' }}>
          대시보드 스티커 승인
        </Col>
        <Col md={6}>
          <form>
            <label style={{ marginRight: '25px' }}>
              <input
                type="radio"
                checked={isShow === 'Y'}
                onChange={() => setIsShow('Y')}
              ></input>
              전체 공개
            </label>
            <label style={{ marginRight: '25px' }}>
              <input
                type="radio"
                checked={isShow === 'N'}
                onChange={() => setIsShow('N')}
              ></input>
              비공개
            </label>
          </form>
        </Col>
        <Col md={3}>
          <button
            onClick={() => {
              editIsShow()
            }}
          >
            저장
          </button>
        </Col>
      </Row>
      <Row>
        <Col md={3} style={{ fontWeight: 'bold', borderRight: '2px solid' }}>
          무료스티커 여부
        </Col>
        <Col md={6}>
          <form>
            <label style={{ marginRight: '25px' }}>
              <input
                type="radio"
                checked={isFree === 1}
                onChange={() => setIsFree(1)}
              ></input>
              Y(무료)
            </label>
            <label style={{ marginRight: '25px' }}>
              <input
                type="radio"
                checked={isFree === 0}
                onChange={() => setIsFree(0)}
              ></input>
              N(유료)
            </label>
          </form>
        </Col>
        <Col md={3}>
          <button
            onClick={() => {
              editIsFree()
            }}
          >
            저장
          </button>
        </Col>
      </Row>
    </div>
  )
}

export function ContainersSection(props) {
  const [containerId, setContainerId] = useState('')
  const [containersOfThePackage, setContainersOfThePackage] = useState([])

  useEffect(() => {
    setContainerId('')
    setContainersOfThePackage(
      props.containersOfThePackage ? props.containersOfThePackage : []
    )
  }, [props.package])

  const renderContainersOfThePackage = () => {
    return containersOfThePackage.map((container, idx) => (
      <tr key={idx}>
        <td>{container.c_id}</td>
      </tr>
    ))
  }
  const addContainer = () => {
    const params = {
      p_id: props.package.p_id,
      c_id: containerId,
    }

    fetch('/new/webAdmin/v2/container/package', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(() => {
        setContainersOfThePackage(
          containersOfThePackage.concat({ c_id: containerId })
        )
        setContainerId('')
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  return (
    <div
      style={{
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: '2px solid silver',
      }}
    >
      <Row style={{ marginBottom: '10px' }}>
        <Col
          style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}
          onClick={() => props.setOpen(!props.open)}
        >
          컨테이너 관리<i className="fa fa-angle-down"></i>
        </Col>
      </Row>
      <Collapse in={props.open}>
        <div>
          <Table striped bordered>
            <thead>
              <tr>
                <th width="90%">컨테이너 ID</th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              {renderContainersOfThePackage()}
              <tr>
                <td>
                  <input
                    id="input-container-id"
                    type="text"
                    value={containerId}
                    onChange={event => setContainerId(event.target.value)}
                  ></input>
                </td>
                <td>
                  <button onClick={addContainer}>추가</button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Collapse>
    </div>
  )
}

const StickerWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 13px 10px;
`

const StickerBox = styled.div`
  width: 100%;
  min-height: 205px;
  position: relative;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
`

const StickerTop = styled.div`
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-family: RawlineSemiBold;
  padding: 0 10px 0 9px;
`

const Edit = styled.label`
  margin-bottom: 0;
  input {
    display: none;
  }
`

const StickerTopBtn = styled.img`
  width: 11px;
  height: 11px;
  cursor: pointer;
`

const StickerMid = styled.div`
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: space-between;
`

const Sticker = styled.img`
  width: 112px;
  height: 100%;
  padding-left: 9px;
`

const StickerTagWrapper = styled.div`
  width: 136px;
  height: 100%;
  background-color: #f8f8f9;
  padding: 6px 5px;
  margin-right: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccfd2;
  }
`

const EditTag = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  color: #778699;

  span {
    font-size: 8px;
    margin-bottom: 2px;
    margin-left: 2px;
  }
  &:hover {
    color: #ff4a9c;
    font-weight: bold;

    svg {
      color: #ff4a9c;
    }
  }
`

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StickerTag = styled.button`
  min-width: 28px;
  max-width: 126px;
  min-height: 24px;
  font-size: 12px;
  border-radius: 8px;
  border: 1px solid #ff4298;
  background-color: #fff;
  padding: 3px 9px;
  margin-right: 3px;
  margin-bottom: 3px;
  font-family: RawlineSemiBold;
  text-align: center;
  word-break: break-all;
  padding-top: 5px;
  position: relative;

  span {
    color: #ff3b94;
    word-break: break-all;
    white-space: normal;
  }

  &:hover {
    background-color: #ff4298;
    color: #fff;
    margin-right: 3px;
    margin-bottom: 3px;
    span {
      opacity: 0;
    }
  }

  &:hover::before {
    content: 'X';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    outline: none;
  }
`

const StickerBottom = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const DeleteCheck = styled.div`
  width: 241px;
  height: 37px;
  font-size: 14px;
  line-height: 2.29;
  font-family: RawlineMedium;
  font-weight: bold;
  color: #dd4b39;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StickerTagInput = styled.input`
  width: 241px;
  height: 37px;
  padding: 0 54px 0 16px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  font-size: 14px;
  line-height: 2.29;
  font-family: RawlineMedium;

  &:focus {
    outline: none;
    border: ${props =>
      props.deactive ? '1px solid #dbdbdb' : '1px solid #ff4a9c'};
    background-color: ${props => props.deactive && '#efefef4d'};
  }

  &::placeholder {
    color: #d2d2d2;
  }
`

const TagCnt = styled.div`
  position: absolute;
  right: 17px;
  font-size: 12px;
  color: #d2d2d2;
  line-height: 2.67;
  font-family: RawlineMedium;
`

const TrayTitle = styled.div`
  height: 24px;
  font-family: RawlineSemiBold;
  font-weight: bold;
  font-size: 16px;
`
const TrayWrapper = styled.div`
  width: 640px;
  display: flex;
  justify-content: space-between;
`

const MainImgWrapper = styled.label`
  width: 128px;
  height: 128px;
  border: 1px solid #b7bbc0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  input {
    display: none;
  }

  &:hover {
    border: 1px solid #ff4a9c;
    cursor: pointer;
  }
`

const MainImg = styled.img`
  width: 90%;
  height: 90%;
`

const Main = styled.img`
  width: 21px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-50%, -50%);
`

const TrayBox = styled.div`
  width: 460px;
  height: 264px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  position: relative;
`

const TrayIconWrapper = styled.div`
  width: 100%;
  height: 57.5px;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
`

const TrayIcon = styled.label`
  width: 57.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-color: ${props => props.current && '#f9f9f9'};
  filter: ${props => (props.current ? '' : 'saturate(0%)')};

  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }

  img {
    width: 90%;
  }

  input {
    display: none;
  }
`

const TrayImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  gap: 12px 0;
  justify-content: center;
  justify-items: center;
  align-items: center;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccfd2;
    border-radius: 8px;
  }
`

const TrayImage = styled.div`
  width: 83px;
  height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 75px;
    height: 75px;
  }
`

const PreviewWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const PreviewBox = styled.div`
  min-width: 660px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  background-color: #fff;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
`

const PreviewMain = styled.div`
  width: 172px;
  height: 172px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 90%;
    height: 90%;
  }
`

const PreviewTitle = styled.div`
  height: 25px;
  font-size: 16px;
  font-family: Rawline;
  font-weight: bold;
  margin-bottom: 30px;
`

const PreviewStickerWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 17px 33px;
`

const PreviewSticker = styled.div`
  width: ${props => (props.width ? `${props.width}px` : '120px')};
  height: ${props => (props.height ? `${props.height}px` : '120px')};
  display: flex;
  justify-content: cetner;
  align-items: center;

  img {
    width: 90%;
    height: 90%;
  }
`

const TableWrapper = styled.div`
  width: 100%;
  min-height: ${props => (props.open ? '160px' : 0)};
  display: inline-block;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
`

const DeleteBtn = styled.button`
  width: 35px;
  height: 25px;
  color: #fff;
  background-color: ${props => (props.delete ? '#dd4b39' : '#6c757d')};
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;

  &:focus {
    outline: none;
  }
`

const CustomBtn = styled.button`
  height: 24px;
  border-radius: 4px;
  border: 1px solid #ff4a9c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: RawlineSemiBold;
  color: ${props => (props.save ? '#fff' : '#ff4a9c')};
  background-color: ${props => (props.save ? '#ff4a9c' : '#fff')};
  padding-top: 5px;
  padding: 0 10px;

  &:hover {
    background-color: ${props => props.add && '#ff4a9c'};
    color: ${props => props.add && '#fff'};
  }

  &:focus {
    outline: none;
  }
`

const Prompt = styled.div`
  width: 241px;

  .title {
    width: 100%;
    font-size: 12px;
  }
  .text {
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    font-size: 12px;
    color: #778699;
    padding: 5px;
    margin-bottom: 10px;
    white-space: break-spaces;
  }
`
