import React, { useEffect } from 'react'
import styled from 'styled-components'
import TrendingItem from './TrendingItem'

const TrendingListWrapper = styled.div`
  display: flex;
  align-items: left;
  width: 100vw;
  white-space: nowrap;
  overflow: auto;
  color: #35336f;
`

const TrendingTitle = styled.div`
  color: #35336f;
`

const TrendingList = props => {
  return (
    <div>
      <TrendingTitle>Trending Package List</TrendingTitle>
      <TrendingListWrapper>
        {props.trendingList &&
          props.trendingList.map((trend, index) => {
            return (
              <TrendingItem
                key={trend.seq}
                item={trend}
                openModal={props.openModal}
              />
            )
          })}
      </TrendingListWrapper>
    </div>
  )
}

export default TrendingList
