import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 120px;
  top: 37px;
  right: 0px;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 0px;
  border: 1px solid #d2d6de;
  color: #333;
`;

const ItemWrapper = styled.div`
  padding: 5px 15px;
  cursor: pointer;
  :hover {
    background-color: #ddd;
  }
`;

const DropdownBox = ({
  setSelectItem,
  setShowItemDrop,
  onClickHandler = () => {},
  itemList,
  wrapperStyle,
  itemBoxStyle,
}) => {
  return (
    <Wrapper style={wrapperStyle}>
      {itemList &&
        itemList.map((item, index) => {
          return (
            <ItemWrapper
              key={index}
              style={itemBoxStyle}
              onClick={() => {
                setSelectItem(item["value"]);
                setShowItemDrop(false);
                onClickHandler();
              }}
            >
              {item["label"]}
            </ItemWrapper>
          );
        })}
    </Wrapper>
  );
};

export default DropdownBox;
