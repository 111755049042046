import React from "react";
import Tax from "../../Components/Money/Tax";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const TaxPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <Tax
        pageNumber={query.pageNumber}
        search={query.search ? query.search : ""}
        type={query.type}
      />
    </React.Fragment>
  );
};

export default TaxPage;
