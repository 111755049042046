import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import styled from 'styled-components'

const PromptEdit = props => {
  const { type, setType, typeList, promptList, setPromptList, getPrompt } = props
  const [checked, setChecked] = useState([])
  const [edit, setEdit] = useState('')
  const [params, setParams] = useState({
    status: '',
    service: '',
    style: '',
    prompt: '',
    negative: '',
    model: '',
    upscale: '',
    img: null,
  })

  const [preview, setPreview] = useState('')
  const [isNew, setIsNew] = useState(false)
  const [addType, setAddType] = useState('')
  const [newType, setNewType] = useState('')
  const checkRef = useRef([])

  const init = async () => {
    await setPreview('')
    await setChecked([])
    await setParams({
      status: '',
      service: '',
      style: '',
      prompt: '',
      negative: '',
      model: '',
      upscale: '',
      img: null,
    })
    await setIsNew(false)
    await setEdit('')
    await setAddType('')
    await setNewType('')
    await getPrompt()

    if (checkRef.current) {
      for (let i = 0; i < checkRef.current.length; i++) {
        if (checkRef.current[i] && checkRef.current[i].checked) {
          checkRef.current[i].checked = false
        }
      }
    }
  }

  useEffect(() => {
    init()
  }, [type])

  useEffect(() => {
    if (edit) {
      setPreview('')
      setIsNew(false)
      const selected = promptList.filter(item => item.SEQ === edit)[0]
      setParams({
        ...params,
        status: selected.STATUS,
        service: selected.SERVICE,
        style: selected.STYLE,
        prompt: selected.PROMPT,
        negative: selected.NEGATIVE,
        model: selected.MODEL,
        upscale: selected.UPSCALE,
        img: selected.IMG,
      })
    } else {
      setParams({
        ...params,
        status: '',
        service: '',
        style: '',
        prompt: '',
        negative: '',
        model: '',
        upscale: '',
        img: '',
      })
    }
  }, [edit])

  const insertType = () => {
    if (newType) {
      axios
        .post(
          `/new/webAdmin/v2/prompt/type`,
          { type: newType },
          {
            headers: {
              token: sessionStorage.getItem("token"),
            },
          }
        )
        .then(({ data }) => {
          setAddType('')
          setNewType('')
          init()
          setType(data.result)
        })
    }
  }

  const updateType = () => {
    axios
      .put(
        `/new/webAdmin/v2/prompt/type/${type}`,
        {
          type: newType,
        },
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        init()
      })
  }

  const deleteType = () => {
    axios
      .delete(`/new/webAdmin/v2/prompt/type/${type}`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then(() => {
        init()
      })
  }

  const handleCheck = e => {
    if (e.target.checked) {
      setChecked(checked.concat(e.target.value))
    } else {
      setChecked(checked.filter(id => id !== e.target.value))
    }
  }

  const updateStatus = status => {
    axios
      .put(
        `/new/webAdmin/v2/prompt/status`,
        { updateList: checked, status },
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        init()
      })
  }

  const fileToUrl = file => {
    const reader = new FileReader()

    reader.onload = e => {
      setPreview(e.target.result)
    }

    reader.readAsDataURL(file)
  }

  const clickSave = async id => {
    let formData = new FormData()

    await formData.append('service', params.service)
    await formData.append('style', params.style)
    await formData.append('prompt', params.prompt)
    await formData.append('negative', params.negative)
    await formData.append('model', params.model)
    await formData.append('upscale', params.upscale)
    if (preview) {
      await formData.append('img', params.img)
    }

    if (id) {
      await formData.append('status', params.status)
      await axios
        .put(`/new/webAdmin/v2/prompt/${id}`, formData, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(() => {
          init()
        })
    } else {
      await formData.append('type', type)
      await axios
        .post(`/new/webAdmin/v2/prompt`, formData, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(() => {
          init()
        })
    }
  }

  const clickDelete = async () => {
    if (checked.length > 0) {
      await axios
        .delete(`/new/webAdmin/v2/prompt`, {
          data: {
            deleteList: checked,
          },
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(() => {
          init()
        })
      setChecked([])
    }
  }
  
  const reorderPromise = (id, order) => {
    const params ={
      id,
      order
    }

    return new Promise(async (resolve, reject) => {
      axios.put(`/new/webAdmin/v2/prompt/order`, params, {
        headers: {
          token: sessionStorage.getItem('token'),
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
    })
  }

  const reorder = async list => {
    const result = await Promise.all(
      list.map((item , index) => reorderPromise(item.SEQ, index + 1))
    )

    if (result) {
      init()
    }
  }

  const moveOrder = async (idx, move) => {
    const updateData = [...promptList]
    const target = updateData.splice(move === 'up' ? idx -1 : idx + 1, 1)[0]
    updateData.splice(idx, 0, target)

    await setPromptList(updateData)
    await reorder(updateData)
  }

  const renderNew = () => {
    return (
      <Table className="table no-margin">
        <thead>
          <tr>
            <th>서비스</th>
            <th>스타일</th>
            <th>Prompt</th>
            <th>Negative Prompt</th>
            <th>Model</th>
            <th>Upscale</th>
            <th>이미지</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type={'text'}
                value={params.service}
                onChange={e =>
                  setParams({
                    ...params,
                    service: e.target.value,
                  })
                }
              />
            </td>
            <td>
              <input
                type={'text'}
                value={params.style}
                onChange={e =>
                  setParams({
                    ...params,
                    style: e.target.value,
                  })
                }
              />
            </td>
            <td>
              <textarea
                value={params.prompt}
                onChange={e =>
                  setParams({
                    ...params,
                    prompt: e.target.value,
                  })
                }
              />
            </td>
            <td>
              <textarea
                value={params.negative}
                onChange={e =>
                  setParams({
                    ...params,
                    negative: e.target.value,
                  })
                }
              />
            </td>
            <td>
              <input
                type={'text'}
                value={params.model}
                onChange={e =>
                  setParams({
                    ...params,
                    model: e.target.value,
                  })
                }
              />
            </td>
            <td>
              <input
                type={'text'}
                value={params.upscale}
                onChange={e =>
                  setParams({
                    ...params,
                    upscale: e.target.value,
                  })
                }
              />
            </td>
            <td>
              <img
                src={preview ? preview : params.img}
                width="100px"
                height="100px"
                alt=""
              />
              <input
                type={'file'}
                onChange={e => {
                  if (e.target.files && e.target.files[0])
                    setParams({
                      ...params,
                      img: e.target.files[0],
                    })
                  fileToUrl(e.target.files[0])
                }}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    <div
      //  className="content-wrapper"
      style={{ minHeight: '100%' }}
    >
      <section className="content-header">
        <h1>AI Prompt 관리</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/ContentsList">
              <i className="fa fa-dashboard"></i>AI
            </a>
          </li>
          <li className="active">Prompt</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <section className="col-lg-12 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  paddingBottom: '30px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                  <span>Prompt Type</span>
                  <select
                    style={{ display: 'inline-block', margin: '0 20px' }}
                    value={type}
                    onChange={e => {
                      setType(e.target.value)
                    }}
                  >
                    {typeList.map((item, i) => (
                      <option key={i} value={item.SEQ}>
                        {item.TYPE}
                      </option>
                    ))}
                  </select>
                  {(addType === 'new' || addType === 'update') && (
                    <input
                      type="text"
                      value={newType}
                      onChange={e => setNewType(e.target.value)}
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      if (addType === 'new') {
                        insertType()
                      } else if (addType === 'update') {
                        updateType()
                      } else if (addType === 'delete') {
                        deleteType()
                      } else {
                        setAddType('new')
                      }
                    }}
                  >
                    {!addType
                      ? '타입 추가'
                      : addType === 'new'
                      ? '추가'
                      : addType === 'update'
                      ? '수정'
                      : '삭제'}
                  </button>
                  {addType && (
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setAddType('')
                        setNewType('')
                      }}
                      style={{ marginLeft: '10px' }}
                    >
                      취소
                    </button>
                  )}
                  {!addType && (
                    <>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setAddType('update')
                          setNewType(
                            typeList.filter(
                              item => item.SEQ === Number(type)
                            )[0].TYPE
                          )
                        }}
                        style={{ marginLeft: '10px' }}
                      >
                        타입 수정
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setAddType('delete')
                          // deleteType()
                        }}
                        style={{ marginLeft: '10px' }}
                      >
                        타입 삭제
                      </button>
                    </>
                  )}
                </div>
                {isNew || edit ? (
                  <div style={{ display: 'flex', gap: '0 10px' }}>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        if (edit) {
                          clickSave(edit)
                        } else {
                          clickSave()
                        }
                        init()
                      }}
                    >
                      저장
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        init()
                      }}
                    >
                      취소
                    </button>
                  </div>
                ) : (
                  <div style={{ display: 'flex', gap: '0 10px' }}>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setParams({
                          ...params,
                          status: '',
                          service: '',
                          style: '',
                          prompt: '',
                          negative: '',
                          model: '',
                          upscale: '',
                          img: '',
                        })
                        setIsNew(true)
                      }}
                    >
                      추가
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        clickDelete()
                      }}
                    >
                      삭제
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        updateStatus('Y')
                      }}
                    >
                      Activate
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        updateStatus('N')
                      }}
                    >
                      Deactivate
                    </button>
                  </div>
                )}
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  {isNew || edit ? (
                    renderNew()
                  ) : (
                    <Table className="table no-margin">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th></th>
                          <th>상태</th>
                          <th>서비스</th>
                          <th>스타일</th>
                          <th>Prompt</th>
                          <th>Negative Prompt</th>
                          <th>Model</th>
                          <th>Upscale</th>
                          <th>이미지</th>
                          {/* <th>수정</th> */}
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {promptList &&
                          promptList.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <input
                                  ref={el => (checkRef.current[index] = el)}
                                  type="checkbox"
                                  onChange={handleCheck}
                                  value={item.SEQ}
                                />
                              </td>
                              <td>{item.STATUS}</td>
                              <td>
                                {edit === item.SEQ ? (
                                  <input
                                    type={'text'}
                                    value={params.service}
                                    onChange={e =>
                                      setParams({
                                        ...params,
                                        service: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  item.SERVICE
                                )}
                              </td>
                              <td>
                                {edit === item.SEQ ? (
                                  <input
                                    type={'text'}
                                    value={params.style}
                                    onChange={e =>
                                      setParams({
                                        ...params,
                                        style: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  item.STYLE
                                )}
                              </td>
                              <td>
                                {edit === item.SEQ ? (
                                  <textarea
                                    value={params.prompt}
                                    onChange={e =>
                                      setParams({
                                        ...params,
                                        prompt: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  item.PROMPT
                                )}
                              </td>
                              <td>
                                {edit === item.SEQ ? (
                                  <textarea
                                    value={params.negative}
                                    onChange={e =>
                                      setParams({
                                        ...params,
                                        negative: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  item.NEGATIVE
                                )}
                              </td>
                              <td>
                                {edit === item.SEQ ? (
                                  <input
                                    type={'text'}
                                    value={params.model}
                                    onChange={e =>
                                      setParams({
                                        ...params,
                                        model: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  item.MODEL
                                )}
                              </td>
                              <td>
                                {edit === item.SEQ ? (
                                  <input
                                    type={'text'}
                                    value={params.upscale}
                                    onChange={e =>
                                      setParams({
                                        ...params,
                                        upscale: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  item.UPSCALE
                                )}
                              </td>
                              <td>
                                <img
                                  src={
                                    item.SEQ === edit
                                      ? preview
                                        ? preview
                                        : item.IMG
                                      : item.IMG
                                  }
                                  width="100px"
                                  height="100px"
                                  alt=""
                                />
                                {edit === item.SEQ && (
                                  <input
                                    type={'file'}
                                    onChange={e => {
                                      if (e.target.files && e.target.files[0])
                                        setParams({
                                          ...params,
                                          img: e.target.files[0],
                                        })
                                      fileToUrl(e.target.files[0])
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                <button
                                  className="btn btn-default"
                                  onClick={() => {
                                    setEdit(item.SEQ)
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                              <td>
                                  {index !== 0 && (
                                    <button
                                      className="btn btn-default"
                                      onClick={() => {
                                        moveOrder(index, 'up')
                                      }}
                                    >
                                      ▲
                                    </button>
                                  )}
                                  {index !== promptList.length - 1 && (
                                    <button
                                      className="btn btn-default"
                                      onClick={() => {
                                        moveOrder(index, 'down')
                                      }}
                                    >
                                      ▼
                                    </button>
                                  )}
                                </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default PromptEdit

const Table = styled.table`
  td {
    /* max-width: 300px; */
    white-space: break-spaces;
  }
`
