import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { paging } from '../../Common/Page';

const BottomBar = (props) => {
    return (
        <Container fluid style={{ width: '100%' }}>
            <Row className="pull-right" style={{ marginRight: '5px' }}>
                <Col md='auto'>
                    {paging(props.pageNumber, props.pageMap, props.pageName, props.search)}
                </Col>
            </Row>
        </Container>
    )
}

export default React.memo(BottomBar);