import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import {
    Row,
    Col,
    Navbar,
    InputGroup,
    FormControl,
    Button,
    Dropdown,
    DropdownButton, Modal, Collapse, Table
} from "react-bootstrap";
import { paging5 } from "../../Common/Page";
import { TrendingUpRounded } from "@material-ui/icons";

const Collection = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    const [isShow, setIsShow] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const [newTitleLanguage, setNewTitleLanguage] = useState(0);
    const [packageTitles, setPackageTitles] = useState([]);
    const [selectedLang, setSelectedLang] = useState({});
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [region, setRegion] = useState({});
    const [regionList, setRegionList] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});

    const [list, setList] = useState({
        stickerList: null,
        langList: null,
        countryList: null,
        pageMap: null,
    });
    // const [langList, setLangList] = useState([]);
    const [params, setParams] = useState({
        lang: props.lang ? props.lang : 0,
        langScore: props.langScore ? props.langScore : 1000000,
        langName: props.langName ? props.langName : "언어",
        tier: props.tier ? props.tier : 1,
        tierName: props.tierName ? props.tierName : "Tier",
        tierScore: props.tierScore ? props.tierScore : 500000,
        country: props.country ? props.country : 0,
        countryName: props.countryName ? props.countryName : "작가국적",
        countryScore: props.countryScore ? props.countryScore : 10000,
        downName: props.downName ? props.downName : "다운 1당 점수",
        downScore: props.downScore ? props.downScore : 1,
        newStickerName: props.newStickerName ? props.newStickerName : "신규 스티커 점수",
        newStickerScore: props.newStickerScore ? props.newStickerScore : 510000,
        keyword: props.keyword ? props.keyword : "",
        keywordScore: props.keywordScore ? props.keywordScore : 250000,
        collectionName: "",
        appId: "",
        userId: "",
        userLang: "",
        userCountry: "",
        titleLang: "",
        packageRegion: "",
        appDown: "",
        totalDown: ""

    });

    const [pageNumber, setPageNumber] = useState(
        props.pageNumber ? props.pageNumber : 1
    );

    useEffect(() => {

        if (!sessionStorage.getItem("token")) {
            props.history.push("/");
        } else {
            getPackage();
        }

    }, []);

    const getPackage = () => {
        const param = queryString.stringify(params);
        // console.log(`/new/webAdmin/v2/collection?${param}&pageNumber=${pageNumber}`);
        fetch(`/admin/v2/collection?${param}&pageNumber=${pageNumber}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token"),
            },
            method: "get",
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
                setList((state) => ({
                    ...state,
                    stickerList: responseJson.body.collection,
                    langList: responseJson.body.lang,
                    countryList: responseJson.body.country,
                    pageMap: responseJson.body.pageMap,
                }));


                setIsLoading(false);
            })
            .catch((error) => {
                console.error("123=" + error);
            });
    };

    const save = () => {
        if (params.collectionName.trim().length === 0) {
            alert("제목을 입력하세요.");
        } else {
            fetch(`/new/webAdmin/v2/collection`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: sessionStorage.getItem("token"),
                },
                method: "post",
                body: JSON.stringify(params),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
                    if (responseJson.status === "success") {
                        alert("저장 성공");
                    } else {
                        alert(responseJson.message);
                    }
                })
                .catch((error) => {
                    console.error("123=" + error);
                });
        }
    };

    const updateInputValue = (index, name) => (event) => {

        event.persist();

        setList((state) => ({
            ...state,
            stickerList: state.stickerList.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        [name]: event.target.value,
                    };
                } else {
                    return item;
                }
            }),
        }));
    }

    const handleClose = () => setIsShow(false);

    const renderLanguagesDropdownMenu = () => {
        const languages = list.langList ? list.langList : [];
        return languages.map(language => <option value={JSON.stringify(language)} key={language.seq}>{language.langName}</option>);
    }

    const renderCountryDropdownMenu = () => {
        const country = list.countryList ? list.countryList : [];
        return country.map(country => <option value={JSON.stringify(country)} key={country.code}>{country.c_name}</option>);
    }

    const renderLanguagesDropdownMenu2 = () => {
        const languages = list.langList ? list.langList : [];
        return languages.map(language => <option value={JSON.stringify(language)} key={language.seq}>{language.langName}</option>);
    }

    const addTitle = () => {

        if (!newTitle || newTitle.length < 1) {
            alert("스티커팩 이름은 1글자 이상이어야 합니다")
            return;
        }

        const params = {
            p_id: selectedPackageId,
            title: newTitle,
            lang: JSON.parse(newTitleLanguage).seq
        }

        fetch("/new/webAdmin/v2/contents/title", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: 'POST'
            , body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then((responseJson => {
                if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
                setPackageTitles(packageTitles.concat({
                    seq: responseJson.seq,
                    title: newTitle,
                    language: JSON.parse(newTitleLanguage).seq,
                    language_name: JSON.parse(newTitleLanguage).langName
                }))
            }))
            .catch((error) => { console.error("123=" + error) })
    }

    const removeTitle = (seq) => {
        const params = {
            seq: seq
        }

        fetch("/new/webAdmin/v2/contents/title", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: 'DELETE'
            , body: JSON.stringify(params)
        })
            .then(() => {
                setPackageTitles(packageTitles.filter(info => info.seq !== seq));
            })
            .catch((error) => {
                console.error("123=" + error);
            });
    }

    const editLang = () => {
        // alert(selectedLang);
        // alert(props.package.p_id);
        // console.log("==", selectedLang);
        if (selectedLang === 0) {
            alert("not change language");
        } else {
            console.log(selectedLang)
            const params = {
                p_id: selectedPackageId
                , langSeq: JSON.parse(selectedLang).seq
            }

            fetch("/new/webAdmin/v2/package/lang", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': sessionStorage.getItem("token")
                }
                , method: 'PUT'
                , body: JSON.stringify(params)
            })
                .then(() => {
                    alert("complete");
                    setList((state) => ({
                        ...state,
                        stickerList: state.stickerList.map((item, i) => {
                            if (item.p_id === selectedPackageId) {
                                return {
                                    ...item,
                                    "langName": JSON.parse(selectedLang).langName,
                                    "langSeq": JSON.parse(selectedLang).seq,
                                    "langCode": JSON.parse(selectedLang).langCode
                                };
                            } else {
                                return item;
                            }
                        }),
                    }));
                })
                .catch((error) => {
                    console.error("123=" + error);
                });
        }
    }

    const editCountry = () => {
        // alert(selectedLang);
        // alert(props.package.p_id);
        console.log("==", selectedCountry);

        const params = {
            countryCode: JSON.parse(selectedCountry).code
        }

        fetch(`/admin/v2/country/${selectedUserId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: 'PUT'
            , body: JSON.stringify(params)
        })
        .then(() => {
            alert("complete");
            setList((state) => ({
                ...state,
                stickerList: state.stickerList.map((item, i) => {
                    if (item.u_id === selectedUserId) {
                        return {
                            ...item,
                            "country": JSON.parse(selectedCountry).c_name
                        };
                    } else {
                        return item;
                    }
                }),
            }));
        })
        .catch((error) => {
            console.error("123=" + error);
        });
    }

    const updateTitle = (p_id, p_name) => {


        const params = {
            p_name
        }

        fetch(`/admin/v2/package/${p_id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: 'PUT'
            , body: JSON.stringify(params)
        })
        .then(() => {
            alert("complete");
        })
        .catch((error) => {
            console.error("123=" + error);
        });
    }

    const showModal = async (data) => {

        const response = await fetch(`/admin/v2/collection/${data.p_id}?p_id=${data.p_id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token"),
            },
            method: "get",
        }).catch((error) => {
            console.error("123=" + error);
        });

        const responseJson = await response.json();

        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        // console.log(responseJson.body.packageTitles);
        setSelectedCountry(JSON.stringify({c_id:data.c_id, code:data.countryCode, c_name:data.country}));
        setSelectedRegion(responseJson.body.selectedRegion);
        setRegionList(responseJson.body.region);
        setPackageTitles(responseJson.body.packageTitles);
        setSelectedPackageId(data.p_id);
        setSelectedLang(JSON.stringify({ seq: data.langSeq, langName: data.langName, langCode: data.langCode }));
        setSelectedUserId(data.u_id);
        setIsShow(true);

    };

    const renderPackageTitles = () => {
        return packageTitles.map(title =>
            <React.Fragment key={title.seq}>
                <Col md={3} style={{ fontWeight: 'bold', borderRight: "2px solid" }}>스티커팩명({title.language_name})</Col>
                <Col md={3} >{title.title}</Col>
                <Col md={6} ><button onClick={() => removeTitle(title.seq)}>삭제</button></Col>
            </React.Fragment>
        )
    }

    const renderRegion = () => {
        return regionList.map((tmp) => (
          <option value={tmp.regionId} key={tmp.regionId}>
            {tmp.region}
          </option>
        ));
    };

    const addGroup = () => {
        if (region.regionId === 0) {
          alert("항목을 선택하세요");
          return;
        }
    
        const params = {
          p_id: selectedPackageId,
          regionId: region.regionId,
        };
    
        fetch("/new/webAdmin/v2/package/region", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: sessionStorage.getItem("token"),
          },
          method: "POST",
          body: JSON.stringify(params),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
            setSelectedRegion(
              selectedRegion.concat({
                id: responseJson.body.id,
                regionId: region.regionId,
                regionName: region.regionName,
              })
            );
          })
          .catch((error) => {
            console.error("123=" + error);
          });
    };

    const removeGroup = (id) => {
        fetch("/new/webAdmin/v2/package/region/" + id, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: sessionStorage.getItem("token"),
          },
          method: "DELETE",
        })
          .then(() => {
            setSelectedRegion(selectedRegion.filter((info) => info.id !== id));
          })
          .catch((error) => {
            console.error("123=" + error);
          });
    };

    const renderSelectedRegion = () => {
        return selectedRegion.map((region) => (
          <tr key={region.id}>
            <td>{region.regionName}</td>
            <td>
              <button onClick={() => removeGroup(region.id)}>삭제</button>
            </td>
          </tr>
        ));
    };

    if (!isLoading) {
        return (
            <div style={{ minHeight: "100%" }}>
                <section className="content-header">
                    <h1>컬렉션 추가</h1>
                    <ol className="breadcrumb">
                        <li>
                            <a href="/ContentsList">
                                <i className="fa fa-dashboard"></i>컬렉션
                            </a>
                        </li>
                        <li className="active">컬렉션 추가</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <section className="col-lg-12 connectedSortable">
                            <div className="box box-info">
                                <div className="box-header with-border" style={{ paddingBottom: "30px", display: "inline" }}>
                                    <Navbar bg="light" expand="lg">
                                        <Col>
                                            <Row>
                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    appId
                                                </span>
                                                <input style={{ display: "inline-block", marginLeft: "10px", }} id="appId" type="text" value={params.appId}
                                                    onChange={(event) => {
                                                        const { value } = event.target;
                                                        setParams((state) => ({
                                                            ...state,
                                                            appId: value,
                                                        }));
                                                    }}
                                                />
                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    userId
                                                </span>
                                                <input style={{ display: "inline-block", marginLeft: "10px", }} id="userId" type="text" value={params.userId}
                                                    onChange={(event) => {
                                                        const { value } = event.target;
                                                        setParams((state) => ({
                                                            ...state,
                                                            userId: value,
                                                        }));
                                                    }}
                                                />
                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    userLang
                                                </span>
                                                <input style={{ display: "inline-block", marginLeft: "10px", }} id="userLang" type="text" value={params.userLang}
                                                    onChange={(event) => {
                                                        const { value } = event.target;
                                                        setParams((state) => ({
                                                            ...state,
                                                            userLang: value,
                                                        }));
                                                    }}
                                                />
                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    userCountry
                                                </span>
                                                <input style={{ display: "inline-block", marginLeft: "10px", }} id="userCountry" type="text" value={params.userCountry}
                                                    onChange={(event) => {
                                                        const { value } = event.target;
                                                        setParams((state) => ({
                                                            ...state,
                                                            userCountry: value,
                                                        }));
                                                    }}
                                                />
                                            </Row>

                                            <Row>
                                                <DropdownButton variant="dark" id="commit-status-dropdown" title={params.langName}>
                                                    {
                                                        list.langList && list.langList.map((data, index) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                    onClick={() =>
                                                                        setParams((state) => ({
                                                                            ...state,
                                                                            lang: data.seq,
                                                                            langName: data.langName,
                                                                        }))
                                                                    }
                                                                >
                                                                    {data.langName}
                                                                </Dropdown.Item>
                                                            );
                                                        })
                                                    }
                                                </DropdownButton>
                                                <input style={{ display: "inline-block", marginLeft: "10px", }} id="langScore" type="text" value={params.langScore}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        setParams((state) => ({
                                                            ...state,
                                                            langScore: value,
                                                        }));
                                                    }}
                                                />

                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    title lang
                                                </span>
                                                <input style={{ display: "inline-block", marginLeft: "10px", }} id="titleLang" type="text" value={params.titleLang}
                                                    onChange={(event) => {
                                                        const { value } = event.target;
                                                        setParams((state) => ({
                                                            ...state,
                                                            titleLang: value,
                                                        }));
                                                    }}
                                                />
                                            </Row>

                                            <Row style={{ marginTop: "10px" }}>
                                                <DropdownButton
                                                    variant="dark"
                                                    id="commit-status-dropdown"
                                                    title={params.tierName}
                                                >
                                                    <Dropdown.Item
                                                        key={1}
                                                        onClick={() =>
                                                            setParams((state) => ({
                                                                ...state,
                                                                tier: 2,
                                                                tierName: "Tier 1",
                                                            }))
                                                        }
                                                    >
                                                        Tier 1
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        key={2}
                                                        onClick={() =>
                                                            setParams((state) => ({
                                                                ...state,
                                                                tier: 1,
                                                                tierName: "Common",
                                                            }))
                                                        }
                                                    >
                                                        Common
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                                <input
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "10px",
                                                    }}
                                                    id="tierScore"
                                                    type="text"
                                                    value={params.tierScore}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        setParams((state) => ({
                                                            ...state,
                                                            tierScore: value,
                                                        }));
                                                    }}
                                                />
                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    pack region
                                                </span>
                                                <input style={{ display: "inline-block", marginLeft: "10px", }} id="packageRegion" type="text" value={params.packageRegion}
                                                    onChange={(event) => {
                                                        const { value } = event.target;
                                                        setParams((state) => ({
                                                            ...state,
                                                            packageRegion: value,
                                                        }));
                                                    }}
                                                />
                                            </Row>
                                            <Row style={{ marginTop: "10px" }}>
                                                <DropdownButton
                                                    variant="dark"
                                                    id="commit-status-dropdown"
                                                    title={params.countryName}
                                                >
                                                    {list.countryList &&
                                                        list.countryList.map((data, index) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                    onClick={() =>
                                                                        setParams((state) => ({
                                                                            ...state,
                                                                            country: data.code,
                                                                            countryName: data.c_name,
                                                                        }))
                                                                    }
                                                                >
                                                                    {data.c_name}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                </DropdownButton>
                                                <input
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "10px",
                                                    }}
                                                    id="countryScore"
                                                    type="text"
                                                    value={params.countryScore}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        setParams((state) => ({
                                                            ...state,
                                                            countryScore: value,
                                                        }));
                                                    }}
                                                />
                                            </Row>
                                            <Row style={{ marginTop: "10px" }}>
                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    in app down
                                                </span>
                                                <input style={{ display: "inline-block", marginLeft: "10px", }} id="appDown" type="text" value={params.appDown}
                                                    onChange={(event) => {
                                                        const { value } = event.target;
                                                        setParams((state) => ({
                                                            ...state,
                                                            appDown: value,
                                                        }));
                                                    }}
                                                />
                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    total down
                                                </span>
                                                <input style={{ display: "inline-block", marginLeft: "10px", }} id="totalDown" type="text" value={params.totalDown}
                                                    onChange={(event) => {
                                                        const { value } = event.target;
                                                        setParams((state) => ({
                                                            ...state,
                                                            totalDown: value,
                                                        }));
                                                    }}
                                                />
                                            </Row>
                                            <Row style={{ marginTop: "10px" }}>
                                                <span>{params.newStickerName}</span>
                                                <input
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "10px",
                                                    }}
                                                    id="downScore"
                                                    type="text"
                                                    value={params.newStickerScore}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        setParams((state) => ({
                                                            ...state,
                                                            newStickerScore: value,
                                                        }));
                                                    }}
                                                />
                                            </Row>
                                            <Row style={{ marginTop: "10px" }}>
                                                <span>키워드 입력</span>
                                                <input
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "10px",
                                                    }}
                                                    id="keyword"
                                                    type="text"
                                                    value={params.keyword}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        setParams((state) => ({
                                                            ...state,
                                                            keyword: value,
                                                        }));
                                                    }}
                                                />
                                                <input
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "10px",
                                                    }}
                                                    id="keyword"
                                                    type="text"
                                                    value={params.keywordScore}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        setParams((state) => ({
                                                            ...state,
                                                            keywordScore: value,
                                                        }));
                                                    }}
                                                />
                                            </Row>
                                            <Row style={{ marginTop: "10px" }}>
                                                <Button onClick={getPackage}> 검색 </Button>
                                            </Row>
                                            <Row style={{ marginTop: "10px" }}>
                                                <span
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    컬렉션 이름 입력
                                                </span>
                                                <input
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "10px",
                                                    }}
                                                    id="collectionName"
                                                    type="text"
                                                    value={params.collectionName}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        setParams((state) => ({
                                                            ...state,
                                                            collectionName: value,
                                                        }));
                                                    }}
                                                />
                                                <Button style={{ marginLeft: "10px" }} onClick={save}>
                                                    {" "}
                                                    저장{" "}
                                                </Button>
                                            </Row>
                                        </Col>
                                    </Navbar>
                                </div>
                                <div className="box-body">
                                    <div className="table-responsive">
                                        <table className="table no-margin">
                                            <thead>
                                                <tr>
                                                    <th>순위</th>
                                                    <th>점수</th>
                                                    <th>이미지</th>
                                                    <th>스티커명</th>
                                                    <th>isNew</th>
                                                    <th>언어</th>
                                                    <th>작가이름</th>
                                                    <th>작가티어</th>
                                                    <th>작가 국적</th>
                                                    <th>앱다운</th>
                                                    <th>전체다운</th>
                                                    {/* <th>키워드</th> */}
                                                    <th>버튼</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.stickerList &&
                                                    list.stickerList.map((data, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1 + (pageNumber - 1) * 30}</td>
                                                                <td>{data.total}</td>
                                                                <td onClick={() => showModal(data)}><img src={data.p_img} width="150px" height="150px" alt="" /></td>
                                                                <td><input type="text" value={data.p_name} onChange={updateInputValue(index, 'p_name')} /></td>
                                                                <td>{data.isNew}</td>
                                                                <td>{data.langName}</td>
                                                                <td>{data.u_name}</td>
                                                                <td>{data.groupId === 1 ? "1tier" : "general"}</td>
                                                                <td>{data.country}</td>
                                                                <td>{data.appDownCnt}</td>
                                                                <td>{data.totalDownCnt}</td>
                                                                {/* <td>{data.p_tags}</td> */}
                                                                <td><button onClick={() => updateTitle(data.p_id, data.p_name)}>저장</button></td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <Modal show={isShow} onHide={handleClose} centered size='xl' scrollable>
                    <Modal.Body>
                        <Container style={{ backgroundColor: "#eeeeee", paddingTop: "10px", paddingBottom: "10px", borderBottom: "2px solid silver" }}>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col md={3} style={{ fontWeight: 'bold', borderRight: "2px solid" }}>스티커팩 제목 번역</Col>
                                <Col md={3} >
                                    <select style={{ width: "100%" }} value={newTitleLanguage} onChange={(event) => setNewTitleLanguage(event.target.value)}>
                                        <option value={0}>언어를 선택하세요</option>
                                        {renderLanguagesDropdownMenu()}
                                    </select>
                                </Col>
                                <Col md={3} >
                                    <input style={{ width: "100%" }} type="text" value={newTitle} placeholder="스티커팩명(번역)" onChange={(event) => setNewTitle(event.target.value)}></input>
                                </Col>
                                <Col md={3}>
                                    <button onClick={addTitle}>추가</button>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col md={3} style={{ fontWeight: 'bold', borderRight: "2px solid" }}>Language</Col>
                                <Col md={3} >
                                    <select style={{ width: "100%" }} value={selectedLang} onChange={(event) => { setSelectedLang(event.target.value) }}>
                                        {renderLanguagesDropdownMenu2()}
                                    </select>
                                </Col>
                                <Col md={3} ><button onClick={editLang}>edit</button></Col>
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                {renderPackageTitles()}
                            </Row>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col md={3} style={{ fontWeight: 'bold', borderRight: "2px solid" }}>작가 국가</Col>
                                <Col md={3} >
                                    <select style={{ width: "100%" }} value={selectedCountry} onChange={(event) => { setSelectedCountry(event.target.value) }}>
                                        {renderCountryDropdownMenu()}
                                    </select>
                                </Col>
                                <Col md={3} ><button onClick={editCountry}>edit</button></Col>
                            </Row>
                        </Container>
                        <Container
                            style={{
                                backgroundColor: "#eeeeee",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                borderBottom: "2px solid silver",
                            }}
                        >
                            <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Col style={{ fontWeight: "bold", textAlign: "center" }} >
                                    지역 관리 <i className="fa fa-angle-down"></i>
                                </Col>
                            </Row>
                            <Collapse in={true}>
                                <div>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th width="95%">지역</th>
                                                <th width="10%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderSelectedRegion()}
                                            <tr>
                                                <td>
                                                    <select style={{ width: "100%" }} id="categoryId" value={region.regionId} 
                                                        onChange={(event) => {
                                                            const tmp = event.target.value;
                                                            const name = event.target.selectedOptions[0].text;
                                                            setRegion((state) => ({
                                                                ...state,
                                                                regionId: tmp,
                                                                regionName: name,
                                                            }));
                                                        }}
                                                    >
                                                        <option value={0}>지역 선택</option>
                                                        { regionList && renderRegion() }
                                                    </select>
                                                </td>
                                                <td>
                                                    <button onClick={addGroup}>추가</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Collapse>
                        </Container>
                    </Modal.Body>
                </Modal>
            </div>
        );
    } else {
        return <Loading />;
    }
};

export default withRouter(Collection);
