import React from "react";
import { withRouter } from "react-router-dom";
import { paging } from "../../Common/Page";
import Loading from "../Common/Loading/Loading";
import "react-datepicker/dist/react-datepicker.css";

class WalletQnaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pageNumber: this.props.pageNumber ? this.props.pageNumber : 1,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this._getWalletQnaList(this.state.pageNumber);
      // this.setState({isLoading:false});
    } else {
      this.props.history.push("/");
    }
  }

  _getWalletQnaList = (num) => {
    const params = "?pageNumber=" + num;

    fetch("/new/webAdmin/v2/WalletQnaList" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          walletQnaList: responseJson.walletQnaList,
          pageMap: responseJson.pageMap,
          pageNumber: num,
          // ,isLoading : false
        });
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1000);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _walletQnaListRender = () => {
    let list = [];
    if (this.state.walletQnaList) {
      for (const data of this.state.walletQnaList) {
        list.push(
          <tr key={data.seq} onClick={() => this._qnaDetail(data.u_id)}>
            <td>{data.seq}</td>
            <td>{data.u_name}</td>
            <td>{data.contents}</td>
            <td>{data.regDate}</td>
          </tr>
        );
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _qnaDetail = (u_id) => {
    this.props.history.push("/WalletQnaDetail?u_id=" + u_id);
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
          //  className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header">
            <h1>지갑 QNA 관리</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/WalletQnaList">
                  <i className="fa fa-dashboard"></i> 지갑 QNA
                </a>
              </li>
              <li className="active">지갑 QNA 관리</li>
            </ol>
          </section>
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div className="box-header with-border">
                    {/* <select style={{ display: "inline-block" }} value={this.state.type} onChange={(event) => this._getAThemeDataList(1, event.target.value)}>
                                            <option>홈타입 선택</option>
                                            <option value="N">N</option>
                                            <option value="S">S</option>
                                            <option value="T">T</option>
                                        </select> */}
                    <div className="box-tools pull-right">
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="collapse"
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="remove"
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  {/* <!-- /.box-header --> */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>작가명</th>
                            <th>내용</th>
                            <th>등록일</th>
                          </tr>
                        </thead>
                        <tbody>{this._walletQnaListRender()}</tbody>
                      </table>
                    </div>
                    <div className="box-footer clearfix">
                      <ul className="pagination pagination-sm no-margin pull-right">
                        {paging(
                          this.state.pageNumber,
                          this.state.pageMap,
                          "WalletQnaList"
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default withRouter(WalletQnaList);
