import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import DefaultButton from "../../Common/ButtunUI/DefaultButton";
import { MdArrowDropDown, MdClear } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  input:focus {
    outline: none;
  }
`;

const SearchArtistDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchArtistTop = styled.div``;

const SearchArtistBottom = styled.div`
  display: flex;
  position: relative;
  border-radius: 6px;
`;

const TitleTag = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const SearchArtistDropdown = styled.div`
  position: relative;
  display: flex;
`;

const SearchArtistShowBox = styled.div`
  display: flex;
  width: 80px;
  background-color: transparent;
  color: #333;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d2d6de;
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 80px;
`;

const SelectArtistShowText = styled.div`
  margin: 0px 4px;
`;

const SearchArtistDropDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 80px;
  bottom: -76px;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 0px;
  border: 1px solid #d2d6de;
  color: #333;
`;

const SearchArtistDropBox = styled.div`
  padding: 5px 15px;
  cursor: pointer;
  :hover {
    background-color: #ddd;
  }
`;

const SearchArtistInput = styled.input`
  width: 100%;
  min-height: 35px;
  background-color: transparent;
  padding: 4px 8px;
  border: 1px solid #d2d6de;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const SelectedArtistTagBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 35px;
  background-color: transparent;
  padding: 3px 8px;
  border: 1px solid #d2d6de;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const SelectedArtistTag = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #e7e7e7;
  border: 1px solid #adadad;
  color: #333;
`;

const ClearIcon = styled(MdClear)`
  margin-left: 4px;
  padding: 1px;
  cursor: pointer;
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const TitleInput = styled.input`
  border: 1px solid #d2d6de;
  background-color: transparent;
  padding: 4px 8px;
  border-radius: 4px;
  min-height: 35px;
`;

const SelectCategoryDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const SelectCategoryDropdown = styled.div`
  position: relative;
  display: flex;
`;

const SelectCategoryShowBox = styled.div`
  display: flex;
  background-color: #fff;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d2d6de;
  color: #333;
  width: 120px;
  min-height: 35px;
`;

const SelectCategoryShowText = styled.div`
  margin: 0px 4px;
`;

const SelectCategoryDropDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 120px;
  bottom: -200px;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 0px;
  border: 1px solid #d2d6de;
  color: #333;
`;

const SelectCategoryDropBox = styled.div`
  padding: 5px 15px;
  cursor: pointer;
  :hover {
    background-color: #ddd;
  }
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const ContentInput = styled.textarea`
  border: 1px solid #d2d6de;
  background-color: transparent;
  padding: 4px 8px;
  border-radius: 4px;
  resize: none;
  line-height: 1.8;
`;

const AttachDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const AttachBox = styled.div`
  :not(:first-child) {
    margin-top: 10px;
  }
`;

const AttachInput = styled.input``;

const BottomDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f4f4f4;
`;

const AutoSuggestDiv = styled.div`
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 80px);
  left: 80px;
  top: 33px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px 0px;
  border: 1px solid #d2d6de;
  border-top: 0px;
  color: #333;
`;

const AutoSuggestBox = styled.div`
  padding: 5px 15px;
  cursor: pointer;
  :hover {
    background-color: #ddd;
  }
`;

const CloseCheckForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseCheckBox = styled.input`
  margin-left: 20px;
  margin-right: 5px;
`;

const categoryOptions = [
  { value: "gu", label: "Guidelines" },
  { value: "reg", label: "Upload" },
  { value: "revi", label: "Review" },
  { value: "la", label: "Launch" },
  { value: "reve", label: "Revenue" },
  { value: "oth", label: "Others" },
];

const MessageQnaSendForm = ({
  setSearchedArtist,
  suggestList,
  setSelectedArtist,
  selectedArtist,
}) => {
  const [searchType, setSearchType] = useState("Term");
  const [showTypeDrop, setShowTypeDrop] = useState(false);
  const [selectCategory, setSelectCategory] = useState("Select...");
  const [showCategoryDrop, setShowCategoryDrop] = useState(false);
  const [inputArtist, setInputArtist] = useState("");
  const [inputContents, setInputContents] = useState("");
  const [inputTitle, setInputTitle] = useState("");

  const [onSearch, setOnSearch] = useState(false);
  const [selectedArtistId, setSelectedArtistId] = useState(-1);

  const [onSend, setOnSend] = useState(false);

  const fileRef1 = useRef(null);
  const fileRef2 = useRef(null);
  const fileRef3 = useRef(null);

  const [isCloseChecked, setIsCloseChecked] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (selectedArtistId !== -1 && !onSearch) {
      searchArtistById();
    }
  }, [selectedArtistId]);

  const searchArtistById = async () => {
    setOnSearch(true);

    try {
      const res = await axios.get(
        `/new/webAdmin/v2/message-center/send/search/${selectedArtistId}`,
        {
          headers: { token: sessionStorage.getItem("token") },
        }
      );
      if (res.status === 200) {
        if (res.data.body.length !== 0) {
          setSelectedArtist(res.data.body[0]);
        } else {
          alert("Please check Artist ID.");
          setSelectedArtist(null);
          setSelectedArtistId(-1);
        }
        setOnSearch(false);
      }
    } catch (error) {
      console.error(error);
      alert("Please check Artist ID.");
      setSelectedArtist(null);
      setSelectedArtistId(-1);
      return;
    }

    setOnSearch(false);
  };

  const insertArtistQnaRoom = async () => {
    setOnSend(true);

    let formData = new FormData();
    if (fileRef1 !== null) formData.append("files", fileRef1.current.files[0]);
    if (fileRef2 !== null) formData.append("files", fileRef2.current.files[0]);
    if (fileRef3 !== null) formData.append("files", fileRef3.current.files[0]);

    formData.append("userId", selectedArtistId);
    formData.append(
      "type",
      categoryOptions.find((x) => x.label === selectCategory).value
    );
    formData.append("title", inputTitle);
    formData.append("contents", inputContents);
    formData.append("status", isCloseChecked ? 0 : 3);
    formData.append("readStatus", 1);
    formData.append("regBy", 1);

    try {
      const res = await axios.post(
        `/new/webAdmin/v2/message-center/room`,
        formData,
        {
          headers: { token: sessionStorage.getItem("token") },
        }
      );
      if (res.status === 200) {
        history.push("/Message/Qna/List/1");
        setOnSend(false);
      }
    } catch (error) {
      console.error(error);
      setOnSend(false);
      return;
    }

    setOnSend(false);
  };

  const onClickSend = () => {
    if (inputArtist === "") {
      alert("Please select Artist.");
      return;
    }
    if (inputTitle === "") {
      alert("Please input title.");
      return;
    }
    if (selectCategory === "Select...") {
      alert("Please select category.");
      return;
    }
    if (inputContents === "") {
      alert("Please input contents.");
      return;
    }
    if (!onSend) {
      setOnSend(true);
      insertArtistQnaRoom();
    }
  };

  return (
    <Wrapper>
      <SearchArtistDiv>
        <SearchArtistTop>
          <TitleTag>Artist</TitleTag>
        </SearchArtistTop>
        <SearchArtistBottom>
          {selectedArtistId === -1 &&
            searchType !== "ID" &&
            suggestList &&
            inputArtist.length !== 0 && (
              <AutoSuggestDiv>
                {inputArtist.length > 2 ? (
                  suggestList.length !== 0 ? (
                    suggestList.map((item, index) => {
                      return (
                        <AutoSuggestBox
                          key={index}
                          onClick={() => {
                            setSelectedArtistId(item.userId);
                          }}
                        >
                          <strong>{item.userName}</strong>
                          <small>({item.userId})</small>
                          {" - "}
                          {item.userEmail}
                        </AutoSuggestBox>
                      );
                    })
                  ) : (
                    <AutoSuggestBox>No search result.</AutoSuggestBox>
                  )
                ) : (
                  <AutoSuggestBox>
                    Please input at least 3 letters.
                  </AutoSuggestBox>
                )}
              </AutoSuggestDiv>
            )}
          <SearchArtistDropdown>
            <SearchArtistShowBox
              onClick={() => {
                setShowTypeDrop(!showTypeDrop);
                setShowCategoryDrop(false);
              }}
            >
              <SelectArtistShowText>{searchType}</SelectArtistShowText>
              <MdArrowDropDown size={18} />
            </SearchArtistShowBox>
            {showTypeDrop && (
              <SearchArtistDropDiv>
                <SearchArtistDropBox
                  onClick={() => {
                    setInputArtist("");
                    setSearchedArtist("");
                    setSelectedArtistId(-1);
                    setSelectedArtist(null);
                    setSearchType("ID");
                    setShowTypeDrop(false);
                  }}
                >
                  ID
                </SearchArtistDropBox>
                <SearchArtistDropBox
                  onClick={() => {
                    setInputArtist("");
                    setSearchedArtist("");
                    setSelectedArtistId(-1);
                    setSelectedArtist(null);
                    setSearchType("Term");
                    setShowTypeDrop(false);
                  }}
                >
                  Term
                </SearchArtistDropBox>
              </SearchArtistDropDiv>
            )}
          </SearchArtistDropdown>
          {selectedArtistId === -1 ? (
            <SearchArtistInput
              value={inputArtist}
              onChange={(e) => {
                setInputArtist(e.target.value);
                if (searchType !== "ID") setSearchedArtist(e.target.value);
              }}
            />
          ) : (
            <SelectedArtistTagBox>
              {selectedArtist !== null && (
                <SelectedArtistTag>
                  <strong>{selectedArtist.u_name}</strong>
                  <small>({selectedArtist.u_id})</small>
                  <span style={{ marginLeft: 3 }}>
                    - {selectedArtist.u_email}
                  </span>
                  <ClearIcon
                    size={18}
                    onClick={() => {
                      setInputArtist("");
                      setSearchedArtist("");
                      setSelectedArtistId(-1);
                      setSelectedArtist(null);
                    }}
                  />
                </SelectedArtistTag>
              )}
            </SelectedArtistTagBox>
          )}
          {searchType === "ID" && selectedArtistId === -1 && (
            <DefaultButton
              style={{ marginLeft: "10px", width: "100px" }}
              btnText={"Search"}
              theme={"green"}
              onClick={() => {
                setSelectedArtistId(inputArtist);
              }}
            />
          )}
          {searchType !== "ID" && null}
        </SearchArtistBottom>
      </SearchArtistDiv>
      <TitleDiv>
        <TitleTag>Title</TitleTag>
        <TitleInput
          value={inputTitle}
          onChange={(e) => {
            setInputTitle(e.target.value);
          }}
        />
      </TitleDiv>
      <SelectCategoryDiv>
        <TitleTag>Category</TitleTag>
        <SelectCategoryDropdown>
          <SelectCategoryShowBox
            onClick={() => {
              setShowCategoryDrop(!showCategoryDrop);
              setShowTypeDrop(false);
            }}
          >
            <SelectCategoryShowText>{selectCategory}</SelectCategoryShowText>
            <MdArrowDropDown size={18} />
          </SelectCategoryShowBox>
          {showCategoryDrop && (
            <SelectCategoryDropDiv>
              <SelectCategoryDropBox
                onClick={() => {
                  setSelectCategory("Guidelines");
                  setShowCategoryDrop(false);
                }}
              >
                Guidelines
              </SelectCategoryDropBox>
              <SelectCategoryDropBox
                onClick={() => {
                  setSelectCategory("Upload");
                  setShowCategoryDrop(false);
                }}
              >
                Upload
              </SelectCategoryDropBox>
              <SelectCategoryDropBox
                onClick={() => {
                  setSelectCategory("Review");
                  setShowCategoryDrop(false);
                }}
              >
                Review
              </SelectCategoryDropBox>
              <SelectCategoryDropBox
                onClick={() => {
                  setSelectCategory("Launch");
                  setShowCategoryDrop(false);
                }}
              >
                Launch
              </SelectCategoryDropBox>
              <SelectCategoryDropBox
                onClick={() => {
                  setSelectCategory("Revenue");
                  setShowCategoryDrop(false);
                }}
              >
                Revenue
              </SelectCategoryDropBox>
              <SelectCategoryDropBox
                onClick={() => {
                  setSelectCategory("Others");
                  setShowCategoryDrop(false);
                }}
              >
                Others
              </SelectCategoryDropBox>
            </SelectCategoryDropDiv>
          )}
        </SelectCategoryDropdown>
      </SelectCategoryDiv>
      <ContentDiv>
        <TitleTag>Contents</TitleTag>
        <ContentInput
          rows={10}
          value={inputContents}
          onChange={(e) => {
            setInputContents(e.target.value);
          }}
        />
      </ContentDiv>
      <AttachDiv>
        <AttachBox>
          <TitleTag>File 1</TitleTag>
          <AttachInput
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={fileRef1}
          />
        </AttachBox>
        <AttachBox>
          <TitleTag>File 2</TitleTag>
          <AttachInput
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={fileRef2}
          />
        </AttachBox>
        <AttachBox>
          <TitleTag>File 3</TitleTag>
          <AttachInput
            accept={"image/jpg,image/jpeg,image/gif,image/png"}
            type={"file"}
            ref={fileRef3}
          />
        </AttachBox>
      </AttachDiv>
      <BottomDiv>
        <DefaultButton
          onClick={() => {
            onClickSend();
          }}
          btnText={"Send"}
          theme={"blue"}
        />
        <CloseCheckForm>
          <CloseCheckBox
            onClick={() => {
              setIsCloseChecked(!isCloseChecked);
            }}
            type={"checkbox"}
          />
          Close after sending message.
        </CloseCheckForm>
      </BottomDiv>
    </Wrapper>
  );
};

export default MessageQnaSendForm;
