import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

const Wrapper = styled.div`
  width: 100%; 
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
`
const MenuWrapper = styled.div`
  display: flex;
  width: 200px;
  height: 32px;
  padding: 0 10px;
  border-radius: 6px;
  border: solid 1px #9c9d9d;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  /* justify-content: center; */
  position: relative;
`
const ActiveItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const MenuItem = styled.div`
  cursor: pointer;
  font-weight: ${({active})=>active?'bold':'500'};
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 6px;
  :hover {
    background-color: #f7f9fb;
  }
`

const MenuModal = styled.div`
  display: ${({open})=>open?'block':'none'};
  position: absolute;
  top: 35px;
  left: 0;
  width: 200px;
  z-index: 2;
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px #9c9d9d;
  padding: 10px;
`

const PostListHeader = ({ active }) => {
  const menu = ['All', 'Engineering', 'Product', 'Insights', 'Stipop', 'Behind the Scenes', 'Main Post'];
  const [open, setOpen] = useState(false);
  const url = ['all', 'engineering', 'product', 'insights', 'stipop', 'behind', 'mainpost'];
  const history = useHistory();

  return(
    <Wrapper>
      <MenuWrapper onClick={()=>{setOpen(prev=>!prev)}}>
        <ActiveItem>
          <div>{menu[active]}</div>
          {!open && <MdExpandMore />}
          {open && <MdExpandLess />}
        </ActiveItem>
        <MenuModal open={open}>
          {menu.map((menuItem, index)=>{
            return (
              <MenuItem 
                active={index===active}
                onClick={()=>{
                  // setSelectedMenu(index)
                  history.push(`/Blog/view/${url[index]}/1`)
                }}
              >
                {menuItem}
              </MenuItem>
            )
          })}
        </MenuModal>
      </MenuWrapper>
    </Wrapper>
  )
}

export default PostListHeader;