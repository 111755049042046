import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const BannerList = props => {
  const { page, collection, title, bannerList } = props
  const [curX, setCurX] = useState(0)
  const carouselRef = useRef()

  return (
    <>
      {collection === 'spotlight' && (
        <>
          <Title>{title}</Title>
          <SpaceResponsive height={16} />
        </>
      )}
      <BannerListWrapper>
        <CustomCarousel
          ref={carouselRef}
          onScroll={e => setCurX(e.target.scrollLeft)}
        >
          <CarouselCard>
            {bannerList &&
              bannerList.map((banner, index) => {
                return (
                  <CarouselCardItem
                    key={index}
                    src={banner.uploadImg}
                    onClick={() => {}}
                  />
                )
              })}
          </CarouselCard>
        </CustomCarousel>
        {bannerList && bannerList.length > 1 && (
          <Navigation>
            {bannerList.map((banner, index) => {
              return (
                <NavBtn
                  key={index}
                  bgColor={banner.bgColor}
                  onClick={() => {
                    carouselRef.current.scrollTo({
                      left: index * 359,
                      behavior: 'smooth',
                    })
                  }}
                  active={
                    curX >= index * 359 - 200 && curX < (index + 1) * 359 - 200
                  }
                />
              )
            })}
          </Navigation>
        )}
      </BannerListWrapper>
      <SpaceResponsive height={page === 'home' ? 46.5 : 7.3} />
    </>
  )
}

export default BannerList

const BannerListWrapper = styled.div`
  position: relative;

  /* * {
    scrollbar-color: transparent transparent;
    scrollbar-width: 0;
    -ms-overflow-style: none;
  }

  *::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  } */
`
const CustomCarousel = styled.div`
  width: 343px;
  position: relative;
  overflow-x: scroll;
`

const CarouselCard = styled.div`
  display: flex;
`

const CarouselCardItem = styled.img`
  min-width: 343px;
  height: 231px;
  margin-right: 16px;
  border-radius: 42px;

  &:hover {
    cursor: pointer;
  }
`

const Navigation = styled.div`
  position: absolute;
  bottom: -17px;
  left: calc(50% - 16px);
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
`
const NavBtn = styled.div`
  width: ${props => (props.active ? '21px' : '7px')};
  height: 7px;
  border-radius: ${props => (props.active ? '3.5px' : '50%')};
  background-color: ${props =>
    props.active ? (props.bgColor ? props.bgColor : '#ff4500') : 'gray'};
  margin: 0 5px;
  cursor: pointer;
`
