import React from "react";
import { Fragment } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
`;

const Inner = styled.div`
  display: flex;
  width: 100%;
  color: #333333;
  font-size: 14px;
  margin: 0px 10px;
  :hover {
    background-color: #f4f4f4;
  }
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  border: 1px solid #f4f4f4;
  border-top: 0px;
  :not(:first-child) {
    border-left: 0px;
  }
`;

const CenterBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DefaultBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const ColumnTop = styled.div``;

const ColumnBottom = styled.div`
  margin-top: 4px;
`;

const FromToBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  min-width: 50px;
  width: 50px;
  font-size: 11px;
  font-weight: bold;
  color: white;
  background-color: ${(props) => (props.isFrom ? "#f39c12" : "#3c8dbc")};
  border-radius: 10px;
  margin-top: 4px;
`;

const StatusBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  width: 120px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
`;

const OpenedBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleBox = styled.div`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 여러 줄 자르기 추가 스타일 */
  white-space: normal;
  height: 50px;
  line-height: 1.8;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const MessageQnaListCard = ({ data, layout, onClick }) => {
  return (
    <Wrapper>
      <Inner>
        {layout &&
          layout.map((la, index) => {
            let text = "";

            switch (la.name) {
              case "#":
                const temp = data["roomRegBy"];
                text = (
                  <Fragment>
                    <CenterBox>
                      <ColumnTop>{data[la.colValue]}</ColumnTop>
                      <FromToBox isFrom={!temp}>
                        {temp ? "TO" : "FROM"}
                      </FromToBox>
                    </CenterBox>
                  </Fragment>
                );
                break;
              case "Artist (ID)":
                text = (
                  <Fragment>
                    <DefaultBox>
                      <ColumnTop>
                        <strong>{data[la.colValue]}</strong>
                        <small>({data["userId"]})</small>
                      </ColumnTop>
                      <ColumnBottom style={{ color: "#666666" }}>
                        {data["userEmail"]}
                      </ColumnBottom>
                    </DefaultBox>
                  </Fragment>
                );
                break;
              case "Category":
                let categoryStr = data[la.colValue];
                switch (data[la.colValue]) {
                  case "gu":
                    categoryStr = "Guidelines";
                    break;
                  case "reve":
                    categoryStr = "Revenue";
                    break;
                  case "upl":
                    categoryStr = "Upload";
                    break;
                  case "revi":
                    categoryStr = "Review";
                    break;
                  case "la":
                    categoryStr = "Launch";
                    break;
                  case "reg":
                    categoryStr = "Upload";
                    break;
                  case "oth":
                    categoryStr = "Others";
                    break;
                  default:
                    break;
                }
                text = <CenterBox>{categoryStr}</CenterBox>;
                break;
              case "Status":
                let statusStr = "-";
                let statusBgColor = "#000";
                if (data[la.colValue] === 0) {
                  statusStr = "Closed";
                  statusBgColor = "#a7aaad";
                } else if (data[la.colValue] === 1) {
                  statusStr = "Responded";
                  statusBgColor = "#1ed14b";
                } else if (data[la.colValue] === 2) {
                  statusStr = "Must respond";
                  statusBgColor = "#d63638";
                } else if (data[la.colValue] === 3) {
                  statusStr = "Sent";
                  statusBgColor = "#72aee6";
                } else {
                  statusStr = "Error";
                  statusBgColor = "#000000";
                }
                text = (
                  <CenterBox>
                    <StatusBox
                      style={{ backgroundColor: statusBgColor }}
                      status={data[la.colValue]}
                    >
                      {statusStr}
                    </StatusBox>
                  </CenterBox>
                );
                break;
              case "Artist Open":
                let openedStr = "";
                if (data[la.colValue] === 0) {
                  openedStr = "Opened";
                } else {
                  openedStr = "Not opened";
                }
                text = (
                  <CenterBox>
                    <OpenedBox>{openedStr}</OpenedBox>
                  </CenterBox>
                );
                break;
              case "Last Updated":
                const tempUpdateDate = data[la.colValue].split(" ");
                text = (
                  <Fragment>
                    <CenterBox>
                      <ColumnTop>{tempUpdateDate[0]}</ColumnTop>
                      <ColumnBottom>{tempUpdateDate[1]}</ColumnBottom>
                    </CenterBox>
                  </Fragment>
                );
                break;
              case "Title":
                text = <TitleBox>{data[la.colValue]}</TitleBox>;
                break;
              default:
                text = data[la.colValue];
                break;
            }

            return (
              <ColumnBox
                key={index}
                style={
                  la.name !== "Artist (ID)"
                    ? { width: la.colWidth, cursor: "pointer" }
                    : { width: la.colWidth }
                }
                onClick={() => {
                  if (la.name !== "Artist (ID)") onClick();
                }}
              >
                {text}
              </ColumnBox>
            );
          })}
      </Inner>
    </Wrapper>
  );
};

export default MessageQnaListCard;
