import React, {useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

const StudioBannerImageSection = (props) => {
    const [ bannerTitle, setBannerTitle ] = useState("");
    const [ bannerImageEn, setBannerImageEn ] = useState(null);
    const [ bannerImageKo, setBannerImageKo ] = useState(null);
    const [ bannerImageLinkEn, setBannerImageLinkEn ] = useState("");
    const [ bannerImageLinkKo, setBannerImageLinkKo ] = useState("");
    
    const saveBanner = () => {
        if(!bannerTitle) {
            alert("배너 제목은 1글자 이상이어야 합니다.");
            return;
        }
        if(!bannerImageEn || !bannerImageKo){
            alert("배너 이미지를 첨부하기 바랍니다.");
            return;
        }
        if(!bannerImageLinkEn || !bannerImageLinkKo){
            alert("배너 이미지 연결 URL")
        }        

        let banner = new FormData();
        banner.append('title', bannerTitle);
        banner.append('bannerImages', bannerImageEn);
        banner.append('bannerImages', bannerImageKo);
        const banners = [
            { linkUrl : bannerImageLinkEn, language : 'en' },
            { linkUrl : bannerImageLinkKo, language : 'ko' }
        ]
        banner.append('banners', JSON.stringify(banners));

        fetch('/new/webAdmin/v2/studio-banners', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: "POST"
            , body: banner
        })
        .then(() => {
            alert("저장완료")
            props.redirect();
        })
        .catch((error) => {console.error("123=" + error); alert("저장실패")})
    }
    return (
        <React.Fragment>
            <div style={{ minWidth: "1000px", maxWidth: "1500px", backgroundColor: "white", border: "solid", borderWidth: "1px", borderColor: "black", borderRadius: "30px", padding: "10px", margin: "10px"}}>
                <form name='bannerForm' encType='multipart/form-data'>
                    <Container fluid>
                        <Row style={{marginBottom: "30px"}}>
                            <Col md={1}><label>배너 제목 : </label></Col>
                            <Col md={5}><input type='text' name='title' style={{width: "100%"}} onChange={(event) => setBannerTitle(event.target.value)}></input></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row><Col><h4>영문(English) 배너</h4></Col></Row>
                                <Row>
                                    <Col md={2}><label>연결 URL : </label></Col>
                                    <Col><input type='text' name='link-en' style={{width: "100%"}} onChange={(event) => setBannerImageLinkEn(event.target.value)}></input></Col>
                                </Row>
                                <Row>
                                    <Col md={2}><label>배너 이미지 : </label></Col>
                                    <Col><input type='file' accept='image/jpg,image/jpeg,image/gif,image/png' name='image-en' onChange={(event) => setBannerImageEn(event.target.files[0])}/></Col>   
                                </Row>
                                <Row>
                                    <Col md={{ offset: 2 }}>{bannerImageEn ? <img src={URL.createObjectURL(bannerImageEn)} style={{ width: "500px", height: "168px" }} alt=''></img> : ""}</Col>
                                </Row>
                                </Col>
                                <Col>
                                <Row><Col><h4>국문(Korean) 배너</h4></Col></Row>
                                <Row>
                                    <Col md={2}><label>연결 URL : </label></Col>
                                    <Col><input type='text' name='link-ko' style={{width: "100%"}} onChange={(event) => setBannerImageLinkKo(event.target.value)}></input></Col>
                                </Row>
                                <Row>
                                    <Col md={2}><label>배너 이미지 : </label></Col>
                                    <Col><input type='file' accept='image/jpg,image/jpeg,image/gif,image/png' name='image-ko' onChange={(event) => setBannerImageKo(event.target.files[0])}/></Col>   
                                </Row>
                                <Row>
                                    <Col md={{ offset: 2 }}>{bannerImageKo ? <img src={URL.createObjectURL(bannerImageKo)} style={{ width: "500px", height: "168px" }} alt=''></img> : ""}</Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-end">
                            <Col className="justify-content-md-end" md={1}>
                                <Button variant="success" onClick={saveBanner}>저장</Button>
                            </Col>
                        </Row>
                    </Container>
                </form>
            </div>
        </React.Fragment>
    )
}

export default withRouter(StudioBannerImageSection);