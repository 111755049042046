import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import DefaultButton from "../Common/ButtunUI/DefaultButton";
import StudioBannerBox from "./StudioBannerBox";
import axios from "axios";

const Wrapper = styled.div`
  width: 100%;
  padding: 15px 15px 0px 15px;
`;

const UnderBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding: 10px 0px;
`;

const EmptyBox = styled.div`
  margin-bottom: 15px;
`;

const StudioBannerList = ({
  bannerList = [],
  setBannerList,
  isActive = true,
  onClickBannerBox,
  setReload,
  activeLength,
}) => {
  const [onEditOrders, setOnEditOrders] = useState(false);
  const [refreshIndex, setRefreshIndex] = useState(-1);

  useEffect(() => {
    if (refreshIndex) {
      setRefreshIndex(-1);
    }
  }, [refreshIndex]);

  const updateStudioBannerPlace = (req) => {
    axios
      .put("/new/webAdmin/v2/studio-banners/place", req, {
        headers: { token: sessionStorage.getItem("token") },
      })
      .then((res) => {
        if (res.status === 200) {
          setOnEditOrders(false);
          setReload(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClickEditOrders = () => {
    if (onEditOrders) {
      setReload(true);
    }
    setOnEditOrders(!onEditOrders);
  };

  const onClickSaveOrders = () => {
    const bannerPlaces = [];

    for (let i = 0; i < bannerList.length; i++) {
      bannerPlaces.push({ bannerId: bannerList[i].bannerId, place: i + 1 });
    }

    updateStudioBannerPlace(bannerPlaces);
  };

  return (
    <Wrapper>
      {bannerList && bannerList.length === 0 && (
        <EmptyBox>no banners.</EmptyBox>
      )}
      {bannerList &&
        bannerList.map((banner, index) => {
          return (
            <StudioBannerBox
              key={index}
              index={index}
              banner={banner}
              isActive={isActive}
              onClickBannerBox={onClickBannerBox}
              onEditOrders={onEditOrders}
              bannerList={bannerList}
              setBannerList={setBannerList}
              setRefreshIndex={setRefreshIndex}
              activeLength={activeLength}
              refresh={
                refreshIndex !== -1 &&
                (refreshIndex === index || refreshIndex === index - 1)
              }
            />
          );
        })}
      {isActive && (
        <UnderBar>
          <DefaultButton
            btnText={!onEditOrders ? "Edit Orders" : "CANCEL"}
            theme={"default"}
            onClick={onClickEditOrders}
          />
          {onEditOrders && (
            <DefaultButton
              style={{ marginLeft: "10px" }}
              btnText={"SAVE"}
              theme={"red"}
              onClick={onClickSaveOrders}
            />
          )}
        </UnderBar>
      )}
    </Wrapper>
  );
};

export default StudioBannerList;
