import React, { useState } from 'react';
import { Table } from 'react-bootstrap';

const ArtistPaypalTable = (props) => {
    const [ paypals, setPaypals ] = useState(props.contents);

    const renderContents = () => {
        let list = paypals && paypals.map(data => (
            <tr key={data.userId} >
                <td>{data.userId}</td>
                <td>{data.artistName}</td>
                <td>{data.userEmail}</td>
                <td>{data.paypalId}</td>
                <td>{data.regDate}</td>
                <td>
                    <textarea value={data.comment ? data.comment : ''} onChange={(event) => setPaypals(paypals.map(item => item.userId === data.userId ? {...data, comment: event.target.value} : item))}/>
                    <button onClick={() => saveComment(data.userId, data.comment)}><i className="fa fa-save"></i></button>
                </td>
            </tr>
        ))
        return list;
    }

    const saveComment = (userId, comment) => {
        const params = {
            comment
        };

        fetch("/new/webAdmin/v2/tax/paypals/"+userId+"/comment", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: 'put'
            , body: JSON.stringify(params)
        })
        .then(() => alert('Comment 수정 완료'))
        .catch((error) => { console.error("123=" + error) });
    }
    
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>User ID</th>
                    <th>작가명</th>
                    <th>작가 이메일</th>
                    <th>Paypal ID</th>
                    <th>등록일</th>
                    <th>Comment</th>
                </tr>
            </thead>
            <tbody>
                {renderContents()}
            </tbody>
        </Table>
    )
}

export default React.memo(ArtistPaypalTable);