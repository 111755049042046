import React, { useState, useEffect } from "react";
import { Collapse, Container, Row, Col, Button } from "react-bootstrap";
import LineGraph from "./LineGraph";

const StudioBanner = (props) => {
  const [banner, setBanner] = useState(props.banner);
  const [onEdit, setOnEdit] = useState(false);
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerImageLinkEn, setBannerImageLinkEn] = useState("");
  const [bannerImageLinkKo, setBannerImageLinkKo] = useState("");
  const [bannerImageEn, setBannerImageEn] = useState(null);
  const [bannerImageKo, setBannerImageKo] = useState(null);

  const imageEn = props.banner.images.filter(
    (item) => item.language === "en"
  )[0];
  const imageKo = props.banner.images.filter(
    (item) => item.language === "ko"
  )[0];

  const updateBannerStatus = () => {
    setBanner({ ...banner, status: !banner.status });
    props.updateBannerStatus(banner.bannerId);
  };

  const toggleOnEditBanner = () => {
    if (!onEdit) {
      setBannerTitle(props.banner.title);
      setBannerImageLinkEn(imageEn.linkUrl);
      setBannerImageLinkKo(imageKo.linkUrl);
    } else {
      setBannerImageEn(null);
      setBannerImageKo(null);
    }
    setOnEdit(!onEdit);
  };

  const saveEditedBanner = () => {
    if (!bannerTitle) {
      alert("배너 제목은 1글자 이상이어야 합니다.");
      return;
    }
    if (!bannerImageLinkEn || !bannerImageLinkKo) {
      alert("배너 이미지 연결 URL");
      return;
    }

    let editedBanner = new FormData();
    editedBanner.append("title", bannerTitle);
    editedBanner.append("bannerImages", bannerImageEn);
    editedBanner.append("bannerImages", bannerImageKo);
    const banners = [
      {
        linkUrl: bannerImageLinkEn,
        language: "en",
        bannerImageId: imageEn.bannerImageId,
        isBannerChanged: bannerImageEn !== null,
      },
      {
        linkUrl: bannerImageLinkKo,
        language: "ko",
        bannerImageId: imageKo.bannerImageId,
        isBannerChanged: bannerImageKo !== null,
      },
    ];
    editedBanner.append("banners", JSON.stringify(banners));

    fetch(`/new/webAdmin/v2/studio-banners/${banner.bannerId}/update`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "PUT",
      body: editedBanner,
    })
      .then(() => {
        alert("수정완료");
        window.location.reload();
      })
      .catch((error) => {
        console.error("123=" + error);
        alert("수정실패");
      });
  };

  useEffect(() => {
    if (onEdit) {
      toggleOnEditBanner();
    }
  }, [props.open]);

  return (
    <Container
      style={{
        minWidth: "1200px",
        maxWidth: "1500px",
        backgroundColor: "white",
        border: "solid",
        borderWidth: "1px",
        borderColor: "black",
        borderRadius: "30px",
        padding: "10px",
        margin: "10px",
      }}
    >
      <Row
        className="justify-content-md-start"
        style={{
          marginBottom: "5px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Col
          className="justify-content-md-start"
          md={"auto"}
          onClick={() => !onEdit && props.handleOpen(props.idx)}
        >
          {!onEdit ? (
            <h3>
              {props.banner.title} <i className="fa fa-angle-down" />
            </h3>
          ) : (
            <>
              <strong>배너 제목</strong>
              <input
                type="text"
                name="title"
                style={{ width: "100%" }}
                value={bannerTitle}
                onChange={(event) => setBannerTitle(event.target.value)}
              />
            </>
          )}
        </Col>
        <Col></Col>
        <Col className="justify-content-md-end" md={"auto"}>
          {banner.status ? (
            <i className="fa fa-circle" style={{ color: "green" }} />
          ) : (
            <i className="fa fa-circle" style={{ color: "red" }} />
          )}
        </Col>
      </Row>
      <Collapse in={props.open}>
        <Container fluid>
          <Row>
            <Col md={4}>
              <Row style={{ marginBottom: "5px" }}>
                <Col md={3}>
                  <h4>영문(English)</h4>
                </Col>
                <Col md={9}>
                  <strong>연결 주소 : </strong>
                  {!onEdit ? (
                    <a href={imageEn.linkUrl} target={"_blank"}>
                      {imageEn.linkUrl}
                    </a>
                  ) : (
                    <input
                      type={"text"}
                      value={bannerImageLinkEn}
                      onChange={(event) => {
                        setBannerImageLinkEn(event.target.value);
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col>
                  {!onEdit ? (
                    <img
                      src={imageEn.imageUrl}
                      style={{ width: "500px", height: "168px" }}
                      alt={imageEn.imageUrl}
                    />
                  ) : (
                    <>
                      {bannerImageEn ? (
                        <img
                          src={URL.createObjectURL(bannerImageEn)}
                          style={{ width: "500px", height: "168px" }}
                          alt={imageEn.imageUrl}
                        />
                      ) : (
                        <img
                          src={imageEn.imageUrl}
                          style={{ width: "500px", height: "168px" }}
                          alt={imageEn.imageUrl}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/jpg,image/jpeg,image/gif,image/png"
                        name="image-en"
                        onChange={(event) =>
                          setBannerImageEn(event.target.files[0])
                        }
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={{ offset: 1, span: 4 }}>
              <Row style={{ marginBottom: "5px" }}>
                <Col md={3}>
                  <h4>국문(Korean)</h4>
                </Col>
                <Col md={9}>
                  <strong>연결 주소 : </strong>
                  {!onEdit ? (
                    <a href={imageKo.linkUrl} target={"_blank"}>
                      {imageKo.linkUrl}
                    </a>
                  ) : (
                    <input
                      type={"text"}
                      value={bannerImageLinkKo}
                      onChange={(event) => {
                        setBannerImageLinkKo(event.target.value);
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col>
                  {!onEdit ? (
                    <img
                      src={imageKo.imageUrl}
                      style={{ width: "500px", height: "168px" }}
                      alt={imageKo.imageUrl}
                    />
                  ) : (
                    <>
                      {bannerImageKo ? (
                        <img
                          src={URL.createObjectURL(bannerImageKo)}
                          style={{ width: "500px", height: "168px" }}
                          alt={imageKo.imageUrl}
                        />
                      ) : (
                        <img
                          src={imageKo.imageUrl}
                          style={{ width: "500px", height: "168px" }}
                          alt={imageKo.imageUrl}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/jpg,image/jpeg,image/gif,image/png"
                        name="image-ko"
                        onChange={(event) =>
                          setBannerImageKo(event.target.files[0])
                        }
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={3} style={{ paddingLeft: "30px" }}>
              <Container>
                <Row>
                  <Col>
                    <strong>누적 클릭 수 : </strong>English(
                    {imageEn.numOfClicks}) / Korean({imageKo.numOfClicks})
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <LineGraph
                      labels={imageEn.dates}
                      datasets={[imageEn.dailyClick, imageKo.dailyClick]}
                      maxClick={Math.max.apply(null, [
                        Math.max.apply(null, imageEn.dailyClick),
                        Math.max.apply(null, imageKo.dailyClick),
                      ])}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row className="justify-content-md-end">
            <Col className="justify-content-md-end" md={3}>
              <Button variant="secondary" onClick={toggleOnEditBanner}>
                {onEdit ? "취소" : "편집"}
              </Button>
              {onEdit && (
                <Button
                  variant="success"
                  onClick={saveEditedBanner}
                  style={{ marginLeft: "5px" }}
                >
                  저장
                </Button>
              )}
              {!onEdit && (
                <>
                  <Button
                    variant="success"
                    onClick={updateBannerStatus}
                    style={{ marginLeft: "5px" }}
                  >
                    {banner.status ? "비활성" : "활성"}
                  </Button>
                  <Button
                    variant="warning"
                    onClick={() => props.deleteBanner(banner.bannerId)}
                    style={{ marginLeft: "5px" }}
                  >
                    삭제
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Collapse>
    </Container>
  );
};

export default StudioBanner;
