import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";

const TagDel = (props) => {
  const [searchText, setSearchText] = useState(
    props.searchText ? props.searchText : ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState(null);

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      props.history.push("/");
    }
    setIsLoading(false);
  });

  const _search = () => {
    const params = "?searchText=" + searchText;

    fetch("/new/webAdmin/v2/tag" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setList(responseJson.search);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const _delete = (tagId) => {
    fetch("/new/webAdmin/v2/tag/" + tagId, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "delete",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        _search();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  if (!isLoading) {
    return (
      <div
        // className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header">
          <h1>태그삭제</h1>
          <ol className="breadcrumb">
            <li>
              <a href="/ContentsList">
                <i className="fa fa-dashboard"></i>태그
              </a>
            </li>
            <li className="active">태그삭제</li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <section className="col-lg-12 connectedSortable">
              <div className="box box-info">
                <div
                  className="box-header with-border"
                  style={{ paddingBottom: "30px" }}
                >
                  <input
                    style={{ display: "inline-block", marginLeft: "10px" }}
                    id="searchText"
                    type="text"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    onKeyDown={(key) => key.keyCode === 13 && _search()}
                  />
                  <button type="button" onClick={_search}>
                    검색
                  </button>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>삭제</th>
                          <th>패키지아이디</th>
                          <th>패키지명</th>
                          <th>스티커아이디</th>
                          <th>키워드</th>
                          <th>이미지</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list &&
                          list.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.tagId}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-block btn-flat"
                                    style={{
                                      width: "50%",
                                      display: "inline-block",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => _delete(data.tagId)}
                                  >
                                    삭제
                                  </button>
                                </td>
                                <td>{data.packageId}</td>
                                <td>{data.packageName}</td>
                                <td>{data.stickerId}</td>
                                <td>{data.keyword}</td>
                                <td>
                                  <img
                                    src={data.stickerImg}
                                    width="500px"
                                    height="500px"
                                    alt=""
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default withRouter(TagDel);
