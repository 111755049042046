import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ProductView from '../../Components/Subscription/ProductView'

const ProductPage = () => {
  const [product, setProduct] = useState([])

  const getProduct = () => {
    axios
      .get(`/new/webAdmin/v2/sub/product`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        setProduct(data.result)
      })
  }

  useEffect(() => {
    getProduct()
  }, [])

  return <ProductView getProduct={getProduct} product={product} />
}

export default ProductPage
