import React from "react";
import SearchColorApi from "../../Components/B2B/searchColorApi";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const SearchColorApiPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <SearchColorApi date={query.date} />
    </React.Fragment>
  );
};

export default SearchColorApiPage;
