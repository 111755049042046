import React, { useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';


const CurationTable = (props) => {
    
    const [ checkedContainerIds, setCheckedContainerIds ] = useState([]);

    const handleCheckBoxEvent = (event) => {
        if (event.target.checked) {
            setCheckedContainerIds(checkedContainerIds.concat(event.target.value));
        } else {
            setCheckedContainerIds(checkedContainerIds.filter(id => id !== event.target.value));
        }
    }

    const renderCuration = () => {
        return props.data && props.data.map(data => (
            <tr key={data.cardId}>
                <td>{data.cardId}</td>
                <td><input type="checkbox" onChange={handleCheckBoxEvent} value={data.cardId}/></td>
                <td>{data.type}</td>
                <td>{data.title}</td>
                {/* <td>{data.imgUrl}</td> */}
                <td>{data.language}</td>
                <td>{data.country}</td>
                <td>{data.status}</td>
                <td>
                    <Button onClick={() => { props.history.push({ pathname : `/Curation/${data.cardId}`, state : { type : data.type, title: data.title, language:data.language, country:data.country, curationCardPackages:data.card } }); }} >
                        Edit
                    </Button>
                </td>
            </tr>
        ))
    }

    const insertNew = () => {
        props.history.push({
            pathname : '/Curation/Insert'
        });
    }

    const deleteBannerContainers = () => {
        if(checkedContainerIds.length === 0){
            alert('삭제할 아이템을 선택하세요');
            return; 
        }
        const params = {
            idList: checkedContainerIds
        }
        fetch("/new/webAdmin/v2/curation", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            ,method: 'DELETE'
            ,body: JSON.stringify(params)
        })
        .then(() => {
            setCheckedContainerIds([]);
            props.getBannerContainers();
        })
        .catch((error) => { console.error("123=" + error) });
    }

    const updateStatus = (status) => {
        const params = {
            idList: checkedContainerIds,
            status: status
        }
        fetch("/new/webAdmin/v2/curation/status", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            ,method: 'put'
            ,body: JSON.stringify(params)
        })
        .then(() => { 
            setCheckedContainerIds([]);
            props.getBannerContainers() 
        })
        .catch((error) => { console.error("123=" + error) });

        /*if(status === 'Y'){
            fetch("/new/webAdmin/v2/container/active/mail", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': sessionStorage.getItem("token")
                }
                ,method: 'POST'
                ,body: JSON.stringify(params)
            })
            .catch((error) => { console.error("123=" + error) });
        }*/
    }

    return (
        <React.Fragment>
            <div style={{backgroundColor: "#fff", borderTop: "solid 5px skyblue", marginTop: "10px"}}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th width="10%">No.</th>
                            <th width="5%"></th>
                            <th width="15%">type</th>
                            <th width="25%">Title</th>
                            <th width="17%">Language</th>
                            <th width="7%">Country</th>
                            <th width="7%">상태값</th>
                            <th width="7%">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderCuration()}
                    </tbody>
                    
                </Table>
            </div>
            <Row>
                <Col md="auto"><Button onClick={insertNew}>New Banner</Button></Col>
                <Col md="auto"><Button onClick={deleteBannerContainers}>Delete</Button></Col>
                <Col md="auto"><Button onClick={() => updateStatus('Y')}>Activate</Button></Col>
                <Col md="auto"><Button onClick={() => updateStatus('N')}>Deactivate</Button></Col>
            </Row>
        </React.Fragment>
    )
}

export default withRouter(CurationTable);