import React from "react";
import ReactExport from "react-export-excel";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import { Modal } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class After extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pay: "",
      u_id: 0,
      date: this.props.date ? this.props.date : "",
      a_paypal: "",
      bank_name: "",
      bank_u_name: "",
      account_number: "",
      password: "",
      license: "",
      sort: "",
      searchKeyword: "",
      searchCri: "",
      idList: [],
      show: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this._getAfter("", "");
      // this.setState({isLoading:false});
    } else {
      this.props.history.push("/");
    }
  }

  _getAfter = (date, sort) => {
    const params =
      "?date=" +
      date +
      "&sort=" +
      sort +
      "&keyword=" +
      this.state.searchKeyword +
      "&cri=" +
      this.state.searchCri;
    fetch("/new/webAdmin/v2/after" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          after: responseJson.after,
          category: responseJson.category,
          isLoading: false,
          date: date,
          sort: sort,
          idList: [],
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _getSearch = (date, sort, keyword, cri) => {
    if (cri === "") {
      alert("검색조건을 입력하세요");
      return;
    }
    const params =
      "?date=" + date + "&sort=" + sort + "&keyword=" + keyword + "&cri=" + cri;
    fetch("/new/webAdmin/v2/after/search" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          after: responseJson.after,
          category: responseJson.category,
          isLoading: false,
          date: date,
          sort: sort,
          idList: [],
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _afterRender = () => {
    let list = [];
    if (this.state.after) {
      for (const data of this.state.after) {
        list.push(
          <tr key={data.u_id}>
            <td>{data.u_id}</td>
            <td>
              <input
                type="checkbox"
                onChange={this._checkBoxEvent}
                value={data.u_id}
              />
            </td>
            <td>{data.u_name}</td>
            <td
              onClick={() => {
                this.setState({
                  u_id: data.u_id,
                  license: data.license,
                  password: "",
                  pay: "",
                  a_paypal: data.a_paypal ? data.a_paypal : "",
                  bank_name: data.bank_name ? data.bank_name : "",
                  bank_u_name: data.bank_u_name ? data.bank_u_name : "",
                  account_number: data.account_number
                    ? data.account_number
                    : "",
                });
                this.handleClick();
              }}
            >
              {data.u_email}
            </td>
            <td>${data.total}</td>
            <td>${data.coin}</td>
            <td>${data.b2b}</td>
            <td>${data.ad}</td>
            {/* <td>${(data.pay + data.pay1 + data.pay2 + data.pay3).toFixed(2)}</td>
                        <td>${(data.total + data.pay + data.coin + data.b2b + data.ad + data.pay1 + data.pay2 + data.pay3).toFixed(2)}</td> */}
            <td>{data.a_country === 410 ? "내국인" : "외국인"}</td>
            <td>
              {data.a_country === 410
                ? data.bankInfo === 1
                  ? "국내계좌있음"
                  : "국내계좌정보없음"
                : data.a_paypal}
            </td>
            <td>
              {data.a_country === 410
                ? data.license
                  ? "라이센스업로드"
                  : "라이센스업로드하지않음"
                : "외국인"}
            </td>
            <td>{data.status}</td>
            <td>{data.payDate}</td>
            <td>{data.sort}</td>
          </tr>
        );
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _checkBoxEvent = (event) => {
    const { idList } = this.state;
    const { value, checked } = event.target;

    if (checked) {
      this.setState({ idList: idList.concat(value) });
    } else {
      this.setState({ idList: idList.filter((id) => id !== value) });
    }
  };

  _updateSort = (sort) => {
    if (this.state.idList.length !== 0) {
      const params = {
        idList: this.state.idList,
        sort: sort,
        date: this.state.date,
      };
      fetch("/new/webAdmin/v2/after/sort", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "PUT",
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
          if (responseJson.status === "success") {
            // this._getAfter(this.state.date, "");
            window.location.reload();
          } else {
            alert("실패");
          }
        })
        .catch((error) => {
          console.error("123=" + error);
        });
    }
  };

  _dateRender = () => {
    let list = [];
    if (this.state.category) {
      for (const data of this.state.category) {
        list.push(
          <option key={data.date} value={data.date}>
            {data.date}
          </option>
        );
      }
      return list;
    }
  };

  _updatePay = (u_id, pay, date) => {
    const params = {
      u_id: u_id,
      pay: pay,
      date: date,
    };
    fetch("/new/webAdmin/v2/after", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "PUT",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        if (responseJson.status === "success") {
          this._getAfter(date, "");
        } else {
          alert("실패");
        }
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _passwordCheck = (passwd) => {
    const params = {
      passwd: passwd,
    };
    fetch("/new/webAdmin/v2/license", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        if (responseJson.status === "success") {
          window.open(
            "https://api.stipop.io/admin/license?img=" + this.state.license,
            "_blank"
          );
        } else {
          alert("실패");
        }
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _handleChangeInput = (e) => {
    this.setState({ searchKeyword: e.target.value.trim() });
  };

  _handleChangeSearchCri = (e) => {
    this.setState({ searchCri: e.target.value });
  };

  handleClick = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
          //  className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header">
            <h1>개편 이후</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/ContentsList">
                  <i className="fa fa-dashboard"></i> 정산
                </a>
              </li>
              <li className="active">개편 이후 정산</li>
            </ol>
          </section>
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div
                    className="box-header with-border"
                    style={{ paddingBottom: "30px" }}
                  >
                    <select
                      style={{ display: "inline-block" }}
                      value={this.state.date}
                      onChange={(event) => {
                        this._getAfter(event.target.value, this.state.sort);
                      }}
                    >
                      <option>날짜 선택</option>
                      {this._dateRender()}
                    </select>
                    <select
                      style={{ display: "inline-block", marginLeft: "10px" }}
                      value={this.state.sort}
                      onChange={(event) =>
                        this._getAfter(this.state.date, event.target.value)
                      }
                    >
                      <option value="">정렬 선택</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                    </select>

                    <select
                      style={{ display: "inline-block", marginLeft: "20px" }}
                      onChange={this._handleChangeSearchCri}
                    >
                      <option value="">검색조건</option>
                      <option value="name">작가이름</option>
                      <option value="email">작가이메일</option>
                    </select>

                    <input
                      value={this.state.searchKeyword}
                      style={{ display: "inline-block", marginLeft: "10px" }}
                      id="searchIndex"
                      type="text"
                      onChange={this._handleChangeInput}
                    ></input>
                    <button
                      type="button"
                      onClick={() => {
                        this._getSearch(
                          this.state.date,
                          "",
                          this.state.searchKeyword,
                          this.state.searchCri
                        );
                      }}
                    >
                      검색
                    </button>
                  </div>
                  {/* <!-- /.box-header --> */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th></th>
                            <th>작가</th>
                            <th>작가 이메일</th>
                            <th>판매수익</th>
                            <th>코인 수익</th>
                            <th>B2B 수익</th>
                            <th>광고 수익</th>
                            {/* <th>저번달 미지급수익</th>
                                                        <th>이번달 지급총액</th> */}
                            <th>국가정보</th>
                            <th>계좌정보</th>
                            <th>라이센스</th>
                            <th>지급여부</th>
                            <th>지급일</th>
                            <th>정렬</th>
                          </tr>
                        </thead>
                        <tbody>{this._afterRender()}</tbody>
                      </table>
                    </div>
                    <div className="box-footer clearfix">
                      <ExcelFile>
                        <ExcelSheet data={this.state.after} name="list">
                          <ExcelColumn label="u_id" value="u_id" />
                          <ExcelColumn label="작가명" value="u_name" />
                          <ExcelColumn label="이메일" value="u_email" />
                          <ExcelColumn label="작가 인센티브" value="total" />
                          <ExcelColumn label="코인수익" value="coin" />
                          <ExcelColumn label="B2B수익" value="b2b" />
                          <ExcelColumn label="광고수익" value="ad" />
                          {/* <ExcelColumn label="저번달 미지급수익" value={(data) => (data.pay + data.pay1 + data.pay2 + data.pay3).toFixed(2)} />
                                                    <ExcelColumn label="이번달 지급총액" value={(data) => (data.total + data.pay + data.pay1 + data.pay2 + data.pay3 + data.coin + data.b2b + data.ad).toFixed(2)} /> */}
                          <ExcelColumn
                            label="국가정보"
                            value={(data) =>
                              data.a_country === 410 ? "내국인" : "외국인"
                            }
                          />
                          <ExcelColumn label="은행이름" value="bank_name" />
                          <ExcelColumn label="예금주" value="bank_u_name" />
                          <ExcelColumn
                            label="계좌번호"
                            value="account_number"
                          />
                          <ExcelColumn label="paypal" value="a_paypal" />
                          <ExcelColumn label="지급여부" value="status" />
                          <ExcelColumn label="지급일" value="payDate" />
                          <ExcelColumn
                            label="라이센스"
                            value={(data) =>
                              data.a_country === 410
                                ? data.license
                                  ? "라이센스업로드"
                                  : "라이센스업로드하지않음"
                                : "외국인"
                            }
                          />
                        </ExcelSheet>
                      </ExcelFile>
                      <button
                        type="button"
                        onClick={() => {
                          this._updateSort("A");
                        }}
                        className="btn btn-primary btn-block btn-flat"
                        style={{
                          width: "10%",
                          display: "inline-block",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          this._updateSort("B");
                        }}
                        className="btn btn-primary btn-block btn-flat"
                        style={{
                          width: "10%",
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                      >
                        B
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <Modal
            show={this.state.show}
            onHide={this.handleClick}
            scrollable
            size="lg"
          >
            <Modal.Header closeButton>
              <h4 className="modal-title" style={{ textAlign: "center" }}>
                상세정보
              </h4>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>은행명</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.bank_name}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label>계좌번호</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.account_number}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>예금주</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.bank_u_name}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label>paypal</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.a_paypal}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>지급금액</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="지급 금액"
                    value={this.state.pay}
                    onChange={(event) =>
                      this.setState({ pay: event.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>라이센스열람패스워드</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="비밀번호입력"
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                  />
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-default pull-left"
                onClick={this.handleClick}
              >
                닫기
              </button>
              <button
                type="button"
                onClick={() => this._passwordCheck(this.state.password)}
                className="btn btn-danger"
              >
                라이센스보기
              </button>
              <button
                type="button"
                onClick={() => {
                  this._updatePay(
                    this.state.u_id,
                    this.state.pay,
                    this.state.date
                  );
                  this.handleClick();
                }}
                className="btn btn-primary"
              >
                지급
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default withRouter(After);
