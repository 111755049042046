import React from "react";
import Collection from "../../Components/Collection/Collection";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const CollectionPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <Collection
        pageNumber={query.pageNumber}
        lang={query.lang}
        langScore={query.langScore}
        langName={query.langName}
        tier={query.tier}
        tierName={query.tierName}
        tierScore={query.tierScore}
        country={query.country}
        countryName={query.countryName}
        countryScore={query.countryScore}
        downName={query.downName}
        downScore={query.downScore}
        newStickerName={query.newStickerName}
        newStickerScore={query.newStickerScore}
        keyword={query.keyword}
      />
    </React.Fragment>
  );
};

export default CollectionPage;
