import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const CardPackage = ({idx, bannerContainerPackage, setBannerContainerPackage, removeBannerContainerPackage, moveOrderUp, moveOrderDown}) => {
    
    const [ packageId, setPackageId ] = useState(bannerContainerPackage.packageId ? bannerContainerPackage.packageId : '');
    const [ stickerId, setStickerId ] = useState(bannerContainerPackage.stickerId ? bannerContainerPackage.stickerId : '');
    const [ lightBackgroundCode, setLightBackgroundCode ] = useState(bannerContainerPackage.lightBackgroundCode ? bannerContainerPackage.lightBackgroundCode : '');
    const [ darkBackgroundCode, setDarkBackgroundCode ] = useState(bannerContainerPackage.darkBackgroundCode ? bannerContainerPackage.darkBackgroundCode : '');
    const [ image, setImage ] = useState(bannerContainerPackage.cardImgUrl ? bannerContainerPackage.cardImgUrl : '');

    useEffect(() => {
        setBannerContainerPackage(idx, {...bannerContainerPackage, packageId, stickerId, lightBackgroundCode, darkBackgroundCode, img: [image]})
    }, [packageId, stickerId, lightBackgroundCode, darkBackgroundCode, image]);
    
    return (
        <form encType="multipart/form-data">
            <Row>
                <Col md={1}>
                    <font size={2}>Sticker Pack ID</font><br />
                    <input type="text" style={{width: '100%'}} value={packageId} onChange={(event) => setPackageId(event.target.value * 1)} ></input>
                </Col>
                <Col md={1}>
                    <font size={2}>Sticker ID</font><br />
                    <input type="text" style={{width: '100%'}} value={stickerId} onChange={(event) => setStickerId(event.target.value * 1)} ></input>
                </Col>
                {   
                    bannerContainerPackage.stickerImg && 
                    <Col md={1}>
                        <img src={bannerContainerPackage.stickerImg} style={{width:150, height:150}} alt={bannerContainerPackage.stickerImg} />
                    </Col> 
                }
                <Col md={2}>
                    <font size={2}>라이트 배경코드</font><br />
                    <input type="text" style={{width: '100%'}} value={lightBackgroundCode} onChange={(event) => setLightBackgroundCode(event.target.value)} ></input>
                </Col>
                <Col md={2}>
                    <font size={2}>다크 배경코드</font><br />
                    <input type="text" style={{width: '100%'}} value={darkBackgroundCode} onChange={(event) => setDarkBackgroundCode(event.target.value)} ></input>
                </Col>
                <Col md={2}>
                    <font size={2}>Image</font><br />
                    <input type="file" style={{width: '100%'}} onChange={(event) => setImage(event.target.files[0])} ></input>
                    {image &&
                    <img src={typeof(image) === 'string' ? image : URL.createObjectURL(image)} style={{width: "100%"}} alt={image}/>}
                </Col>
                <Col md={1}>
                    <font size={2}>Delete</font><br />
                    <Button variant="danger" onClick={() => removeBannerContainerPackage(idx)}>Delete</Button>
                </Col>
                <Col md={1}>
                    <font size={2}>Order</font><br />
                    <Button onClick={() => moveOrderUp(bannerContainerPackage.seq, bannerContainerPackage.order, idx)}>▲</Button>
                    <Button onClick={() => moveOrderDown(bannerContainerPackage.seq, bannerContainerPackage.order, idx)}>▼</Button>
                </Col>
            </Row>
        </form>
    )
}

export default CardPackage;