import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import TextInput from "./TextInput";

const Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
`;

const Field = styled.th`
    border-top: 1px solid #f4f4f4;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: left;
`;

const Data = styled.td`
    border-top: 1px solid #f4f4f4;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    display: table-cell;
`;

const Input = styled.input`
    width:80%;
    padding:8px;
`;

const List = ({fieldList, dataList, clickE, isClick}) => {

    return (
        <Table>
            <tbody>
                <tr>
                    {
                        fieldList.map((filed, index) => {
                            return (
                                <Field key={index}>{filed}</Field>
                            )
                        })
                    }
                </tr>
                {
                    dataList.map((data, index) => {
                        
                        let list = [];
            
                        for (const key in data) {
                            if (key === 'stickerImg') {
                                list.push(
                                    <td key={key+index}><img src={data[key]} style={{width:100, height:100}} /></td>
                                )
                            } else if (key !== 'userInfo') {
                                list.push(
                                    <Data key={key+index}>{data[key]}</Data>
                                );
                            }
                        }
            
                        return (
            
                            clickE ?
                            <>
                                <tr key={index} onClick={() => clickE(data.userId, index)}>
                                    {list}
                                </tr>
                                {
                                    isClick[index] &&  
                                    <tr key={index+'_first'}>
                                        <Field>SEQ</Field>
                                        <Field>NOTIFICATION_TYPE</Field>
                                        <Field>NOTIFICATION_SUBTYPE</Field>
                                        <Field>TRANSACTION_ID</Field>
                                        <Field>SUBSCRIPTION_ID</Field>
                                        <Field>BUNDLE_ID</Field>
                                        <Field>PRODUCT_ID</Field>
                                        <Field>SUBSCRIPTION_GROUP_IDENTIFIER</Field>
                                        <Field>PURCHASE_DATE</Field>
                                        <Field>ORIGINAL_PURCHASE_DATE</Field>
                                        <Field>EXPIRES_DATE</Field>
                                        <Field>QUANTITY</Field>
                                        <Field>TYPE</Field>
                                        <Field>IN_APP_OWERSHIP_TYPE</Field>
                                        <Field>SIGNED_DATE</Field>
                                        <Field>ENVIRONMENT</Field>
                                        <Field>REG_DATE</Field>
                                    </tr>
                                }
                                {
                                    isClick[index] &&  

                                    data.userInfo.map((user, index) => {

                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{user.SEQ}</td>
                                                    <td>{user.NOTIFICATION_TYPE}</td>
                                                    <td>{user.NOTIFICATION_SUBTYPE}</td>
                                                    <td>{user.TRANSACTION_ID}</td>
                                                    <td>{user.SUBSCRIPTION_ID}</td>
                                                    <td>{user.BUNDLE_ID}</td>
                                                    <td>{user.PRODUCT_ID}</td>
                                                    <td>{user.SUBSCRIPTION_GROUP_IDENTIFIER}</td>
                                                    <td>{user.PURCHASE_DATE}</td>
                                                    <td>{user.ORIGINAL_PURCHASE_DATE}</td>
                                                    <td>{user.EXPIRES_DATE}</td>
                                                    <td>{user.QUANTITY}</td>
                                                    <td>{user.TYPE}</td>
                                                    <td>{user.IN_APP_OWERSHIP_TYPE}</td>
                                                    <td>{user.SIGNED_DATE}</td>
                                                    <td>{user.ENVIRONMENT}</td>
                                                    <td>{user.REG_DATE}</td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </>
                            :
                            <tr key={index}>
                                {list}
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}

export default List;