import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import BannerTypeDropdown from './BannerTypeDropdown.js';
import { getContainerTypeName } from './MobileBannerUtils';
const BannerContainer = (props) => {
    const [ title, setTitle ] = useState(props.bannerContainer.title ? props.bannerContainer.title : '');
    const [ tag, setTag ] = useState(props.bannerContainer.tag ? props.bannerContainer.tag : '');

    useEffect(() => {
        props.setBannerContainer({...props.bannerContainer, title: title, tag: tag});
    }, [title, tag]);
    return (
        <React.Fragment>
            <Container fluid style={{backgroundColor: '#fff', borderTop: 'solid 5px skyblue', marginTop: '10px'}}>
                <Row>
                    <Col md={2}><font size={5}>Banner Type: </font></Col>
                    <Col>
                        {props.isNewBanner 
                        ? <BannerTypeDropdown type={props.bannerContainer.type} setType={(type) => props.setBannerContainer({...props.bannerContainer, type: type})} />
                        : <font size={5}>{getContainerTypeName(props.bannerContainer.type)}</font>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={2}><font size={5}>{props.bannerContainer.type === 'C' ? 'Hex Color Code : ' : 'Container Title: '}</font></Col>
                    <Col><input type='text' style={{width: '100%'}} value={title} onChange={(event) => setTitle(event.target.value)}/></Col>
                </Row>
                <Row>
                    <Col md={2}><font size={5}>Container Tag: </font></Col>
                    <Col><input type='text' style={{width: '100%'}} value={tag} onChange={(event) => setTag(event.target.value)}/></Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default BannerContainer;