import React from "react";
import CategoryList from "../../Components/Category/CategoryList";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const CategoryListPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <CategoryList pageNumber={query.pageNumber} search={query.search} />
    </React.Fragment>
  );
};

export default CategoryListPage;
