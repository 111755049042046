import React from "react";
import Subscription from "../../Components/Home/Subscription";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const SubscriptionPage = ({ match, location }) => {
    
    const query = queryString.parse(location.search);

    return (
        <React.Fragment>
            <Subscription pageNumber={query.pageNumber} />
        </React.Fragment>
    );
};

export default SubscriptionPage;
