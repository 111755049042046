import React from 'react'
import LineGraph from './LineGraph'

function ArtistGroup (props) {
    const labels = props.data.dates;
    const datasets = [props.data.numberOfViews, props.data.numberOfDownloads];
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">그룹 고유 번호 : {props.data.artistGroupId} / 그룹명 : {props.data.artistGroupName}</h3>
                        <div className="box-tools pull-right">
                            <button type="button" className="btn btn-box-tool" data-widget="collapse">
                                <i className="fa fa-minus"></i>
                            </button>
                            <button type="button" className="btn btn-box-tool" data-widget="remove">
                                <i className="fa fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="box border-primary" style={{margin:'10px'}}>
                                    <div className="box-body">
                                        <font size={3}><strong> 그룹명 : </strong>{props.data.artistGroupName} (작가 수 : {props.data.numberOfArtists})</font>
                                        <div className="box-tools pull-right"><a href={"/Member/Artists?groupId="+props.data.artistGroupId}><i className="fa fa-list"></i></a></div>
                                    </div>
                                </div>
                                <div className="box border-primary" style={{margin:'10px'}}>
                                    <div className="box-body">
                                        <font size={3}><strong> 스티커팩 수 : </strong>{props.data.numberOfApprovedPackages} / {props.data.numberOfStickerPackages} (승인/등록)</font>
                                    </div>
                                </div>
                                <div className="box border-primary" style={{margin:'10px'}}>
                                    <div className="box-body">
                                        <font size={3}><strong> 문의사항 수 : </strong>{props.data.numberOfMessages} (답변대기중 : {props.data.numberOfNotAnsweredMessages})</font>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <LineGraph labels={labels} datasets={datasets}/>
                            </div>
                        </div>
                    </div>
                    <div className="box-footer">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArtistGroup;