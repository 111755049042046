import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SyntaxHighlighter from "react-syntax-highlighter";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid #c4c4c4;
  border-radius: 4px;
  height: calc(740px * 2);
  overflow-y: auto;
  padding: 60px calc((100% - 1024px) / 2);
`

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 84px;
`

const ContentSection = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`

const ShareArea = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  width: 100px;
`

const TextArea = styled.div`
  display: flex;
  /* display: inline-block; */
  width: 700px;
  /* width: 300px; */
  flex-direction: column;
  padding-bottom: 20px;
  word-break: break-all !important;
`

const InlineArea = styled.div`
  display: inline;
  margin-bottom: 34px;
`

const CategoryText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ff4500;
  margin-bottom: 26px;
`

const TitleText = styled.div`
  font-size: 45px;
  font-weight: 800;
  line-height: 1.33;
  letter-spacing: -0.78px;
  color: #2b292d;
  margin-bottom: 26px;
`

const DateText = styled.div`
  font-size: 16px;
  color: #364557;
  margin-bottom: 29px;
  margin-bottom: 46px;
`

// large title
const Type0 = styled.div`
  display: flex;
  font-size: 44px;
  font-weight: 800;
  line-height: 1.18;
  letter-spacing: -0.49px;
  color: #2b292d;
  margin-top: 24px;
  margin-bottom: 6px;
  width: 100%;
`

// small title
const Type1 = styled.div`
  display: flex;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.63;
  letter-spacing: -0.36px;
  color: #2b292d;
  margin-top: 24px;
  margin-bottom: 4px;
  width: 100%;
`

// sub text
const Type2 = styled.span`
  /* display: flex; */
  display: inline;
  /* float: left; */
  font-size: 24px;
  line-height: 1.67;
  letter-spacing: 0.28px;
  color: #000;
  /* margin-bottom: 34px; */
  /* width: 100%; */
`

// image
const Type3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #364557;

  div {
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 24px;
  }
`

// hyper text
const Type4 = styled(Type2)`
  color: #ff4500;
  cursor: pointer;
`

// blod text
const Type5 = styled(Type2)`
  /* margin: 0 5px; */
  font-weight: bold;
`

const CodeBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #f5f7fa;
  font-size: 16px;
  padding: 10px 30px;
  overflow-x: auto;
  overflow-y: hidden;
  /* color: #fff; */
  pre {
    overflow: unset;
  }
`

const BulletPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 21px;
  /* line-height: 1.67; */
  letter-spacing: 0.28px;
  color: #000;
  height: 40px;
  margin-bottom: 8.5px;
  ${({tab})=>{
    return `
      margin-left: ${(tab+1)*30}px;
    `
  }}

  .bullet{
    display: flex;
    border-radius: 50%;
    background-color: #000;
    width: 10px;
    height: 10px;
    margin-right: 20px;
  }
`
const NumberList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 21px;
  /* line-height: 1.67; */
  letter-spacing: 0.28px;
  color: #000;
  height: 40px;
  margin-bottom: 8.5px;
  ${({tab})=>{
    return `
      margin-left: ${(tab+1)*30}px;
    `
  }}
  div {
    margin-right: 15px;
  }
`

const Preview = ({ 
  edit, 
  editMainImage, 
  mainImage, 
  title, 
  content, 
  files, 
  urlFiles 
}) => {  
  const codeContentIndex = useRef(0);

  const textToHtml = (origin_text) => {
    codeContentIndex.current = 0;
    // real code block
    let realCodes = []
    let codeCnt = ((origin_text.match(/```/g) || []).length)/2;
    let pos = 0;
    for(let i = 0; i<codeCnt; i++){
      let startCode = origin_text.slice(pos).search(/```/g)+3+pos;
      let langEnd = origin_text.slice(startCode).search(/\n/g)+startCode;
      const lang = origin_text.slice(startCode, langEnd);
      if(lang===''){
        lang = 'Javascript'; // default
      } 
      let endCode = origin_text.slice(startCode).search(/```/g)+startCode;
      // let tmpText = origin_text.slice(startCode, endCode).replace(/\n/g, '&nbsp;')
      let tmpText = origin_text.slice(langEnd, endCode).replace(/\n/g, '&nbsp;')
        
      realCodes.push({code: tmpText.toString(), lang: lang})
      origin_text = origin_text.slice(0, startCode-3) + '<c></c>' + origin_text.slice(endCode+3);
      // console.log(origin_text)
      pos = 0;
    }

    const blocks = origin_text.split('\n');
    return blocks.map((block, index)=>{
      // bullet point
      let flagBulletPoint = false;
      let numberList = false;
      let totalTabCnt = (block.match(/\t/g) || []).length;
      let tabCnt = 0;
      for(let i = 0; i<totalTabCnt; i++){
        block = block.slice(1);
        if(block.charAt(0)==='\t'){
          tabCnt++;
          continue;
        }
        else if(block.search(/-[0-9]+./)===0) {
          numberList = true;
          break;
        }
        else if(block.charAt(0) === '-' && block.charAt(1) === ' '){
          flagBulletPoint = true;
          break;
        }
      }

      if(flagBulletPoint){
        return(
          <BulletPoint tab={tabCnt}>
            <div className='bullet'></div>
            {block.slice(2)}
          </BulletPoint>
        )
      }
      if(numberList){
        return (
          <NumberList tab={tabCnt}>
            <div>{block.slice(1,3)}</div>
            {block.slice(3)}
          </NumberList>
        )
      }

      // find tag
      let tagCnt = (block.match(/<[tTiIbhc]/g)||[]).length;
      let tagStartIndex = [];
      let tagEndIndex = [];
      let pos = 0;

      for(let j = 0; j<tagCnt; j++){
        let s_index = block.slice(pos).search(/<[tTiIbhc]/g);
        if(s_index == -1) break;
        tagStartIndex.push(s_index+pos);
        pos += s_index + 1;

        // 현재 <I name=""></I>
        // 예전 <I name="" /> 처리 필요
        if(block.charAt(pos)==="I"){
          let prevI = block.slice(pos).search(/>/g)+pos;
          if(block.charAt(prevI-1)==="/"){
            // console.log("prev I!");
            tagEndIndex.push(prevI);
            continue;
          }
        }

        let e_index;
        e_index = block.slice(pos).search(/<\/[tTiIbhc]>/g);
        
        if(e_index == -1) break;
        tagEndIndex.push(e_index+pos);
      }

      let n = tagStartIndex.length;
      let resBlock = [];
      for(let j = 0; j<n+1; j++) resBlock.push(j)
      
      return(
        <InlineArea key={`block_${index}`}>
          {resBlock.map((inner, k)=>{
            // <tag>tagText</tag>text
            let text = '';
            let tagText = '';
            let tag = '';

            // plain text
            if(k===0){
              text = block.substring(0, tagStartIndex[0])
            }
            else if(k===n){
              text = block.substring(tagEndIndex[k-1]+4, block.length);
            }
            else{
              text = block.substring(tagEndIndex[k-1]+4, tagStartIndex[k]);
            }

            // tag text
            if(k!==0){ // k===0은 무조건 plain text or ''(tag없음)
              if(block.charAt(tagStartIndex[k-1]+1) == 'I') {
                tag = 'I';
                tagText = '';
              }
              else if(block.charAt(tagStartIndex[k-1]+1) === 'h'){
                tag = 'h';
                let tagEnd = block.slice(tagStartIndex[k-1]).indexOf(">");
                tagEnd += tagStartIndex[k-1];
                tagText = block.substring(tagEnd+1, tagEndIndex[k-1]);
              }
              else{
                tag = block.charAt(tagStartIndex[k-1]+1);
                tagText = block.substring(tagStartIndex[k-1]+3, tagEndIndex[k-1]);
              }
            }
            
            // test
            // text1 <T>title</T> text2 <t>subtitle</t> image <I name=intro_01_ko.png></I>text3 <i>imageDesc</i> text4 <b>bold</b> text5 <h link="link">link</h> last
            // console.log(`${k}-tag(${tag}): ${tagText}`)
            // console.log(k+"-text: "+text)

            switch(tag){
              case "T":
                return (
                  <>
                    <Type0>{tagText}</Type0>
                    <Type2>{text}</Type2>
                  </>
                )
              case "t":
                return (
                  <>
                    <Type1>{tagText}</Type1>
                    <Type2>{text}</Type2>
                  </>
                )
              case "I":
                // fine file name
                let s_fileName = block.slice(tagStartIndex[k-1]).indexOf("name=") + tagStartIndex[k-1] + 5;
                let e_fileName = block.slice(tagStartIndex[k-1]).indexOf(">") + tagStartIndex[k-1];
                let fileName = block.substring(s_fileName, e_fileName);
                // 예전 <I name="" />처리
                if(fileName.charAt(fileName.length-1)==="/")
                  fileName = fileName.substring(0, fileName.length-1);

                // update post
                if(edit && urlFiles && urlFiles.length>0){
                  // console.log("edit page -file")
                  let file = urlFiles.filter(img=>img.name === fileName);
                  // console.log(file)
                  // for(let i = 0; i<urlFiles.length; i++){
                  //   if(urlFiles[i].name===fileName){
                  //     file = urlFiles[i];
                  //     break;
                  //   }
                  // }
                  if(file[0]){
                    return(
                      <>
                        <img src={file[0].url} width='100%' />
                        <Type2>{text}</Type2>
                      </>
                    )
                  }
                }
                // create post
                if(files && files.length>0){
                  let file = null;
                  for(let i = 0; i<files.length; i++){
                    if(files[i].name===fileName){
                      file = files[i];
                      break;
                    }
                  }
                  if(file){
                    return(
                      <>
                        <img src={URL.createObjectURL(file)} width='100%' />
                        <Type2>{text}</Type2>
                      </>
                    )
                  }
                }
                break;
              case "i":
                return (
                  <>
                    <Type3>{tagText}</Type3>
                    <Type2>{text}</Type2>
                  </>
                )
              case "b":
                return (
                  <>
                    <Type5>{tagText}</Type5>
                    <Type2>{text}</Type2>
                  </>
                )
              case "h":
                let s_linkName = block.slice(tagStartIndex[k-1]).indexOf("link=") + tagStartIndex[k-1] + 6;
                let e_linkName = block.slice(tagStartIndex[k-1]).indexOf(">") + tagStartIndex[k-1] - 1;
                let linkName = block.substring(s_linkName, e_linkName);
                // console.log(linkName)
                return (
                  <>
                    <Type4 onClick={()=>{window.open(linkName, "_blank")}}>{tagText}</Type4>
                    <Type2>{text}</Type2>
                   </>
                )
              case "c":
                if(codeContentIndex.current>=realCodes.length) return;
                let lines = realCodes[codeContentIndex.current].code.split('&nbsp;')
                let currentLang = realCodes[codeContentIndex.current++].lang;
                // console.log(currentLang)
                return(
                  <CodeBlock>
                    {lines && lines.map((line, index)=>{
                      return (
                        <SyntaxHighlighter
                          key={`codeblock_${codeContentIndex.current-1}_${index}_line`}
                          language={currentLang}
                          customStyle={{
                            margin: 0,
                            padding: 10,
                            backgroundColor: "transparent",
                            overflowX: "unset",
                          }}
                        >
                          {line}
                        </SyntaxHighlighter>
                      )
                    })}
                  </CodeBlock>
                )
              default:
                return (
                  <Type2>{text}</Type2>
                )
            }
          })    
        }
        </InlineArea>
      )    
    })
  }

  useEffect(()=>{
    updateScroll()
  },[content, files])

  const updateScroll = () => {
    var element = document.getElementById("previewText");
    element.scrollTop = element.scrollHeight;
  }

  const categoryText = ['', 'Engineering', 'Product', 'Insights', 'Stipop', 'Behind the Scenes']

  return(
    <Wrapper id='previewText'>
      <TitleSection>
        <CategoryText>
          {title && 
            categoryText[title.category]
          }
        </CategoryText>
        <TitleText>{title && title.title}</TitleText>
        <DateText>{title && title.date}</DateText>
        {mainImage && edit && !editMainImage && <img id="main_img" src={mainImage} style={{width: '100%'}}/>}
        {mainImage && edit && editMainImage && <img id="main_img" src={URL.createObjectURL(mainImage)} style={{width: '100%'}} />}
        {mainImage && !edit && <img id="main_img" src={URL.createObjectURL(mainImage)} style={{width: '100%'}}/>}
      </TitleSection>
      <ContentSection>
        <ShareArea>
          <div style={{marginBottom: 26.5}}>SHARE</div>
          <img
            src="https://img.stipop.io/image/b2b/blog/share/facebook.png"
            width={43}
            style={{
              marginBottom: 6,
              width: 43,
            }}
          />
          <img
            src="https://img.stipop.io/image/b2b/blog/share/twitter.png"
            width={43}
            style={{
              marginBottom: 6,
              width: 43,
            }}
          />
          <img
            src="https://img.stipop.io/image/b2b/blog/share/linkedin.png"
            width={43}
            style={{
              marginBottom: 6,
              width: 43,
            }}
          />
        </ShareArea>
        <TextArea id="text_area">
          {content && textToHtml(content)}
        </TextArea>
      </ContentSection>
    </Wrapper>
  )
}

export default Preview;