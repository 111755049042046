import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import ReactJson from 'react-json-view'

const ProductView = props => {
  const { getProduct, product } = props
  const [edit, setEdit] = useState(0)
  const [params, setParams] = useState({
    paltform: '',
    subscriptionProduct: {},
  })
  const [inputJson, setInputJson] = useState('')
  const [pType, setPType] = useState(true) //true: json, false: string

  const init = async () => {
    await getProduct()
    await setEdit(0)
    await setParams({ paltform: '', subscriptionProduct: {} })
    await setInputJson('')
    await setPType(true)
  }

  useEffect(() => {
    if (edit) {
      const editItem = product.filter(item => item.seq === edit)[0]
      setParams({
        ...params,
        platform: editItem.platform,
        subscriptionProduct: editItem.data,
      })
    }
  }, [edit])

  const validJSON = str => {
    try {
      const josn = JSON.parse(str)
      return typeof josn === 'object'
    } catch (err) {
      return false
    }
  }

  const clickSave = async str => {
    await axios.put(
      `/new/webAdmin/v2/sub/product/${edit}`,
      {
        ...params,
        subscriptionProduct: JSON.stringify(
          str ? str : params.subscriptionProduct
        ),
      },
      {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      }
    )

    await init()
  }

  const renderEdit = () => {
    return (
      <Table className="table no-margin">
        <thead>
          <tr>
            <th>Platform</th>
            <th style={{ minWidth: '800px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                }}
              >
                <div style={{ marginRight: '20px' }}>Parameters</div>
                <div
                  onClick={async () => {
                    await setInputJson(
                      JSON.stringify(params.subscriptionProduct, null, 4)
                    )
                    await setPType(false)
                  }}
                  style={{
                    fontSize: '14px',
                    color: pType ? '#0000004d' : '#268bd2',
                    cursor: 'pointer',
                    marginRight: '10px',
                  }}
                >
                  String
                </div>
                <div
                  onClick={async () => {
                    if (validJSON(inputJson)) {
                      await setParams({
                        ...params,
                        params: JSON.parse(inputJson),
                      })
                      await setPType(true)
                    } else {
                      alert('This text is not in valid json format.')
                    }
                  }}
                  style={{
                    fontSize: '14px',
                    color: !pType ? '#0000004d' : '#268bd2',
                    cursor: 'pointer',
                  }}
                >
                  JSON
                </div>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                value={params.platform}
                onChange={e => {
                  setParams({
                    ...params,
                    platform: e.target.value,
                  })
                }}
              />
            </td>
            <td style={{ minWdith: '800px' }}>
              {pType ? (
                <ReactJson
                  src={params.subscriptionProduct}
                  theme={'summerfruit:inverted'}
                  collapsed={false}
                  onAdd={e => {
                    setParams({ ...params, subscriptionProduct: e.updated_src })
                  }}
                  onEdit={e => {
                    setParams({ ...params, subscriptionProduct: e.updated_src })
                  }}
                  onDelete={e => {
                    setParams({ ...params, subscriptionProduct: e.updated_src })
                  }}
                  enableClipboard={edit && false}
                  name={null}
                />
              ) : (
                <>
                  <textarea
                    value={inputJson}
                    onChange={e => {
                      setInputJson(e.target.value)
                    }}
                    style={{ minWidth: '800px', minHeight: '800px' }}
                  />
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#dc3545',
                    }}
                  >
                    {!validJSON(inputJson) &&
                      'This text is not in valid json format.'}
                  </div>
                </>
              )}
            </td>
            <td>
              <button
                className="btn btn-default"
                onClick={async () => {
                  if (pType) {
                    clickSave()
                  } else {
                    if (!validJSON(inputJson)) {
                      alert('This text is not in valid json format.')
                    } else {
                      clickSave(JSON.parse(inputJson))
                    }
                  }
                }}
              >
                저장
              </button>
              <button
                className="btn btn-default"
                onClick={() => {
                  init()
                }}
              >
                취소
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    <div style={{ minHeight: '100%' }}>
      <section className="content-header">
        <h1>Subscription Product 관리</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/ContentsList">
              <i className="fa fa-dashboard"></i>Subscription
            </a>
          </li>
          <li className="active">Product</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <section className="col-lg-12 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  width: '100%',
                  paddingBottom: '30px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              ></div>
              {product.length > 0 && (
                <div className="box-body">
                  <div className="table-responsive">
                    {edit ? (
                      renderEdit()
                    ) : (
                      <Table className="table no-margin">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Platform</th>
                            <th>Parameters</th>
                            <th>Last Update</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {product &&
                            product.map((item, index) => (
                              <tr key={index}>
                                <td>{item.seq}</td>
                                <td>{item.platform}</td>
                                <td style={{ minWidth: '500px' }}>
                                  <ReactJson
                                    src={item.data}
                                    collapsed={false}
                                    name={null}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    quotesOnKeys={false}
                                    displayArrayKey={false}
                                  />
                                </td>
                                <td>
                                  {new Date(item.updateDate).toLocaleString()}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      setEdit(item.seq)
                                    }}
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default ProductView

const Table = styled.table`
  td {
    /* max-width: 300px; */
    white-space: break-spaces;
  }
`
