// 태그 작업 보류됨 (2020.11~)

import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import {
  Row,
  Col,
  Navbar,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Collapse,
} from "react-bootstrap";
import { paging } from "../../Common/Page";
import {
  SelfServingGroupsSection,
  RegionSection,
} from "../Contents/ContentsInfoModalSections";

const CategoryList = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [region, setRegion] = useState([]);
  const [category, setCategory] = useState({
    regionId: 0,
    categoryId: 0,
    subCategoryId: 0,
  });
  const [appCategory, setAppCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [sticker, setSticker] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [packageData, setPackageData] = useState({});

  useEffect(() => {
    // componentDidMount
    const getData = async () => {
      await getCategory();
      await getRegion();
      setIsLoading(false);
    };
    getData();
    return async () => {
      //componentWillUnmount
    };
  }, []);

  const getCategory = async () => {
    const response = await fetch(`/new/webAdmin/v2/package/category2`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    });

    const responseJson = await response.json();

    if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

    setAppCategory(responseJson.body.appCategory);
  };

  const getRegion = async () => {
    const response = await fetch(`/new/webAdmin/v2/package/region2`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    });

    const responseJson = await response.json();

    if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

    setRegion(responseJson.body.region);
  };

  const getPackageList = async () => {
    if (category.regionId !== 0 || category.categoryId !== 0) {
      const params = queryString.stringify(category);

      const response = await fetch(`/new/webAdmin/v2/package?${params}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      });

      const responseJson = await response.json();

      if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

      setPackageList(responseJson.body.packageList);
    }
  };

  const getPackage = async (data) => {
    const response = await fetch(
      `/new/webAdmin/v2/package/sticker/${data.p_id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      }
    );

    const responseJson = await response.json();

    if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

    setPackageData(data);
    setSticker(responseJson.body.package);
    setModalShow(true);
  };

  const getRegion2 = async (p_id) => {
    const response = await fetch(
      `/new/webAdmin/v2/package/region?p_id=${p_id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      }
    );

    const responseJson = await response.json();

    if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

    setSelectedRegion(responseJson.body.selectedRegion);
    setRegionList(responseJson.body.region);
  };

  const renderStickers = () => {
    return (
      <Row style={{ backgroundColor: "#f0f0f0" }}>
        {sticker.map((img) => {
          console.log(img);
          return (
            <Col key={img.s_id} xs={6} sm={4} md={4} lg={3}>
              <img
                src={img.s_img}
                style={{ width: "140px", height: "140px" }}
                alt={img.s_img}
              />
              <p>{img.s_id}</p>
            </Col>
          );
        })}
      </Row>
    );
  };

  if (!isLoading) {
    return (
      <div
        //  className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header">
          <h1>Sticker Categories</h1>
          <ol className="breadcrumb">
            <li>
              <a href="/ContentsList">
                <i className="fa fa-dashboard"></i>카테고리
              </a>
            </li>
            <li className="active">앱 카데고리</li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <section className="col-lg-12 connectedSortable">
              <div className="box box-info">
                <div
                  className="box-header with-border"
                  style={{ paddingBottom: "30px", display: "inline" }}
                >
                  <Navbar bg="light" expand="lg">
                    <Col>
                      <Row>
                        <DropdownButton
                          variant="dark"
                          id="commit-status-dropdown"
                          title={
                            category.regionName ? category.regionName : "지역"
                          }
                          style={{ marginRight: 10 }}
                        >
                          {region &&
                            region.map((category, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => {
                                    setCategory((state) => ({
                                      ...state,
                                      regionId: category.regionId,
                                      regionName: category.region,
                                    }));
                                  }}
                                >
                                  {category.region}
                                </Dropdown.Item>
                              );
                            })}
                        </DropdownButton>
                        <DropdownButton
                          variant="dark"
                          id="commit-status-dropdown"
                          title={
                            category.categoryName
                              ? category.categoryName
                              : "App category"
                          }
                          style={{ marginRight: 10 }}
                        >
                          {appCategory &&
                            appCategory.map((category, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => {
                                    setCategory((state) => ({
                                      ...state,
                                      categoryId: category.categoryId,
                                      categoryName: category.category,
                                      subCategoryName: "",
                                    }));
                                    setSubCategory(category.subCategoryList);
                                  }}
                                >
                                  {category.category}
                                </Dropdown.Item>
                              );
                            })}
                        </DropdownButton>
                        <DropdownButton
                          variant="dark"
                          id="commit-status-dropdown"
                          title={
                            category.subCategoryName
                              ? category.subCategoryName
                              : "Sub category"
                          }
                          style={{ marginRight: 10 }}
                        >
                          {subCategory &&
                            subCategory.map((category, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    setCategory((state) => ({
                                      ...state,
                                      subCategoryId: category.subCategoryId,
                                      subCategoryName: category.subCategory,
                                    }))
                                  }
                                >
                                  {category.subCategory}
                                </Dropdown.Item>
                              );
                            })}
                        </DropdownButton>
                        <Button onClick={getPackageList}>조회</Button>
                      </Row>
                    </Col>
                  </Navbar>
                </div>
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>작가명</th>
                          <th>작가출신국가</th>
                          <th>패키지명</th>
                          <th>대표이미지</th>
                          <th>다운수</th>
                          <th>승인여부</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packageList &&
                          packageList.map((data, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  getPackage(data);
                                  getRegion2(data.p_id);
                                }}
                              >
                                <td>{index + 1}</td>
                                <td>{data.u_name}</td>
                                <td>{data.country}</td>
                                <td>{data.p_name}</td>
                                <td>
                                  <img
                                    src={data.p_img}
                                    width="100px"
                                    height="100px"
                                    alt=""
                                  />
                                </td>
                                <td>{data.downCnt}</td>
                                <td>
                                  {data.p_commit === 1
                                    ? "승인"
                                    : data.p_commit === 2
                                    ? "수정요청"
                                    : data.p_commit === 3
                                    ? "거절"
                                    : "검토중"}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          centered
          size="xl"
          scrollable
        >
          <Modal.Body>
            <Container
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                borderBottom: "2px solid silver",
              }}
            >
              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col style={{ fontWeight: "bold", textAlign: "center" }}>
                  스티커 목록
                </Col>
              </Row>
              {/* <Collapse> */}
              <Container>{sticker && renderStickers()}</Container>
              {/* </Collapse> */}
            </Container>

            <SelfServingGroupsSection
              package={packageData}
              appCategory={null}
              selectedCategory={null}
              open={true}
              setOpen={true}
            />

            <RegionSection
              package={packageData}
              region={regionList}
              selectedRegion={selectedRegion}
              open={true}
              setOpen={true}
            />
            <Row></Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default withRouter(CategoryList);
