import React, { useState } from 'react'
import { Container, Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap'

const TopBar = (props) => {
    const [search, setSearch] = useState(props.params.search ? props.params.search : "");

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setParams({...props.params, pageNumber:1, search:search});
    }

    const keyEvent = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    }

    return (
        <Container fluid>
            <Row className='pull-right' style={{ marginRight:"5px" }}>
                <Col md="auto">
                    <InputGroup onSubmit={handleSubmit}> 
                        <FormControl type="text" value={search} placeholder="Search" onChange={(event)=>setSearch(event.target.value)} onKeyPress={keyEvent}/>
                        <Button variant="primary" onClick={handleSubmit}><i className="fa fa-search"/></Button>
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default React.memo(TopBar);