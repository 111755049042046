import React from "react";
import SearchHistory from "../../Components/B2B/SearchHistory";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const SearchHistoryPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <SearchHistory date={query.date} />
    </React.Fragment>
  );
};

export default SearchHistoryPage;
