import React from "react";
import ContentsList from "../../Components/Contents/ContentsList";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const ContentsListPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <ContentsList
        pageNumber={query.pageNumber}
        search={query.search}
        type={query.type}
        order={query.order}
        language={query.language}
        q={'studio'}
      />
    </React.Fragment>
  );
};

export default ContentsListPage;
