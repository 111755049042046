import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.default};
  border: 1px solid ${(props) => props.theme.outline};
  color: ${(props) => props.theme.inline};
  cursor: pointer;
  border-radius: 4px;
  :hover {
    background-color: ${(props) =>
      props.isPressed ? props.theme.click : props.theme.hover};
  }
  user-select: none;
  font-size: 13px;
`;

const InnerBorder = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid
    ${(props) => (props.isPressed ? props.theme.inline : "transparent")};
  border-radius: 4px;
`;

const Inner = styled.div`
  padding: 6px 12px;
  opacity: 1;
`;

const Colors = (theme) => {
  let themeColor;

  switch (theme) {
    case "red":
      themeColor = {
        default: "#f86368",
        hover: "#e65054",
        click: "#d63638",
        inline: "#ffffff",
        outline: "#d63638",
      };
      break;
    case "blue":
      themeColor = {
        default: "#4f94d4",
        hover: "#3582c4",
        click: "#2271b1",
        inline: "#ffffff",
        outline: "#2271b1",
      };
      break;
    case "green":
      themeColor = {
        default: "#1ed14b",
        hover: "#00ba37",
        click: "#00a32a",
        inline: "#ffffff",
        outline: "#00a32a",
      };
      break;
    case "yellow":
      themeColor = {
        default: "#f0c33c",
        hover: "#dba617",
        click: "#bd8600",
        inline: "#ffffff",
        outline: "#bd8600",
      };
      break;
    default:
      themeColor = {
        default: "#f0f0f1",
        hover: "#dcdcde",
        click: "#c3c4c7",
        inline: "#000000",
        outline: "#c3c4c7",
      };
      break;
  }

  return themeColor;
};

const DefaultButton = ({
  style,
  theme = "default",
  btnText = "버튼이름",
  onClick = () => {},
}) => {
  const [themeColor, setThemeColor] = useState();
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    setThemeColor(Colors(theme));
  }, [theme]);

  return (
    <Wrapper
      style={style}
      onMouseDown={() => {
        setIsPressed(true);
      }}
      onMouseUp={() => {
        setIsPressed(false);
      }}
      onClick={onClick}
      theme={themeColor}
      isPressed={isPressed}
    >
      <InnerBorder theme={themeColor} isPressed={isPressed}>
        <Inner>{btnText}</Inner>
      </InnerBorder>
    </Wrapper>
  );
};

export default DefaultButton;
