import React from "react";
import { withRouter, Link } from "react-router-dom";

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    document.body.classList.add("hold-transition");
    document.body.classList.add("sidebar-mini");
  }

  render() {
    const userType = localStorage.getItem("userType");

    return (
      // {/* <!-- Left side column. contains the logo and sidebar --> */}
      <aside className="main-sidebar">
        {/* <!-- sidebar: style can be found in sidebar.less --> */}
        <section className="sidebar">
          {/* <!-- sidebar menu: : style can be found in sidebar.less --> */}
          {
            // Tag 다는 외부 인력용 계정인 경우
            userType === "99" ? (
              <ul className="sidebar-menu" data-widget="tree">
                <li className="header">Menu</li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("Category") >
                    -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/CategoryList">
                    <i className="fa fa-th"></i>
                    <span>스티커 카테고리</span>
                  </a>
                </li>

                <li className="header">LogOut</li>
                <li className="treeview">
                  <Link
                    to="/"
                    onClick={() => {
                      localStorage.removeItem("token");
                    }}
                  >
                    <span>LogOut</span>
                  </Link>
                </li>
              </ul>
            ) : (
              // 관리자 계정인 경우
              <ul className="sidebar-menu" data-widget="tree">
                {/* 데이터(Data) Section*/}
                <li className="header">데이터(Data)</li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("Home") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Home">
                    <i className="fa fa-th"></i>
                    <span>Home</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.location.pathname.indexOf("Dashboard") > -1
                      ? "active treeview"
                      : ""
                  }
                >
                  <a href="/Dashboard/Contents">
                    <i className="fa fa-th"></i>
                    <span>Dashboard</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li
                      className={
                        this.props.location.pathname.indexOf(
                          "/Dashboard/Overview"
                        ) > -1
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/Dashboard/Overview">
                        <i className="fa fa-circle-o"></i> Overview Dashboard
                      </a>
                    </li>
                    <li
                      className={
                        this.props.location.pathname.indexOf(
                          "/Dashboard/Marketing"
                        ) > -1
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/Dashboard/Marketing">
                        <i className="fa fa-circle-o"></i> Marketing Dashboard
                      </a>
                    </li>
                    <li
                      className={
                        this.props.location.pathname.indexOf(
                          "/Dashboard/Contents"
                        ) > -1
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/Dashboard/Contents">
                        <i className="fa fa-circle-o"></i> Contents Dashboard
                      </a>
                    </li>
                  </ul>
                </li>

                {/* 관리(Management) Section*/}
                <li className="header">관리(Management)</li>
                <li
                  className={
                    this.props.location.pathname.indexOf("Container") > -1 ||
                    this.props.location.pathname.indexOf("/Banner") > -1
                      ? "active treeview"
                      : ""
                  }
                >
                  <a href="/Banner/Mobile">
                    <i className="fa fa-th"></i>
                    <span>배너 관리</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li
                      className={
                        this.props.location.pathname.indexOf("/Banner/Mobile") >
                        -1
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/Banner/Mobile">
                        <i className="fa fa-circle-o"></i> App 배너 관리
                      </a>
                    </li>
                    <li
                      className={
                        this.props.location.pathname.indexOf("/Banner/Studio") >
                        -1
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/Banner/Studio">
                        <i className="fa fa-circle-o"></i> Studio 배너 관리
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf(
                      "ContentsList"
                    ) > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/ContentsList">
                    <i className="fa fa-th"></i>
                    <span>컨텐츠 관리</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.location.pathname.indexOf("/Artists") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Member/Artists">
                    <i className="fa fa-th"></i>
                    <span>작가 관리</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.location.pathname.indexOf("Member") > -1 &&
                    this.props.location.pathname.indexOf("/Artists") === -1
                      ? "active treeview"
                      : ""
                  }
                >
                  <a href="/Member/ArtistGroupPage">
                    <i className="fa fa-th"></i>
                    <span>회원 관리</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li
                      className={
                        this.props.location.pathname.indexOf(
                          "/ArtistGroupPage"
                        ) > -1
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/Member/ArtistGroupPage">
                        <i className="fa fa-circle-o"></i> 작가 그룹 정보
                      </a>
                    </li>
                    <li
                      className={
                        this.props.location.pathname.indexOf("/Users") > -1
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/Member/Users">
                        <i className="fa fa-circle-o"></i> 유저 관리
                      </a>
                    </li>
                    <li
                      className={
                        this.props.location.pathname.indexOf(
                          "/CorporateUsers"
                        ) > -1
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/Member/CorporateUsers">
                        <i className="fa fa-circle-o"></i> 기업 유저 관리
                      </a>
                    </li>
                    <li
                      className={
                        this.props.location.pathname.indexOf("/ArtistSearch") >
                        -1
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/Member/ArtistSearch">
                        <i className="fa fa-circle-o"></i> 작가 검색
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("Category") >
                    -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/CategoryList">
                    <i className="fa fa-th"></i>
                    <span>스티커 카테고리</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("ArtistQna") >
                    -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/ArtistQnaList">
                    <i className="fa fa-th"></i>
                    <span>작가 1:1 문의사항</span>
                  </a>
                </li>

                <li
                  className={
                    this.props.history.location.pathname.indexOf("Comment") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/CommentList">
                    <i className="fa fa-th"></i>
                    <span>댓글관리</span>
                  </a>
                </li>

                <li
                  className={
                    this.props.history.location.pathname.indexOf("Guide") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/GuideList">
                    <i className="fa fa-th"></i>
                    <span>가이드라인 관리</span>
                  </a>
                </li>

                <li
                  className={
                    this.props.history.location.pathname.indexOf("Taggers") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Taggers/Home">
                    <i className="fa fa-th"></i>
                    <span>태그 현황</span>
                  </a>
                </li>

                <li
                  className={
                    this.props.history.location.pathname.indexOf("TagDel") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/TagDel">
                    <i className="fa fa-th"></i>
                    <span>태그 삭제</span>
                  </a>
                </li>

                <li
                  className={
                    this.props.history.location.pathname.indexOf("Collection") >
                    -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Collection">
                    <i className="fa fa-th"></i>
                    <span>컬렉션 생성</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf(
                      "Collection/View"
                    ) > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Collection/View">
                    <i className="fa fa-th"></i>
                    <span>컬렉션 조회</span>
                  </a>
                </li>

                {/* 정산 Section*/}
                <li className="header">정산</li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("After") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Money/After">
                    <i className="fa  fa-money"></i>
                    <span>월별 정산 내역</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("Payment") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Money/Payment">
                    <i className="fa  fa-money"></i>
                    <span>전체수익집계</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("Tax") > -1 &&
                    this.props.history.location.pathname.indexOf("TaxForm") ===
                      -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Money/Tax">
                    <i className="fa  fa-money"></i>
                    <span>세금정보관리</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf(
                      "ArtistLicense"
                    ) > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Money/ArtistLicense">
                    <i className="fa  fa-money"></i>
                    <span>작가 License 관리</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf(
                      "ArtistPaypal"
                    ) > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Money/ArtistPaypal">
                    <i className="fa  fa-money"></i>
                    <span>작가 Paypal 관리</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("TaxForm") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/Money/TaxForm">
                    <i className="fa  fa-money"></i>
                    <span>Tax Form 관리</span>
                  </a>
                </li>

                {/* B2B Section */}
                <li className="header">B2B</li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("Theme") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/AThemeList">
                    <i className="fa fa-th"></i>
                    <span>채널사 관리</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("AppInfo") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/AppInfo">
                    <i className="fa fa-th"></i>
                    <span>앱 현황</span>
                  </a>
                </li>
                <li
                  className={
                    this.props.history.location.pathname.indexOf("SdkInfo") > -1
                      ? "active treeview"
                      : "treeview"
                  }
                >
                  <a href="/SdkInfo">
                    <i className="fa fa-th"></i>
                    <span>sdk현황</span>
                  </a>
                </li>

                {/* LogOut Section */}
                <li className="header">LogOut</li>
                <li className="treeview">
                  <Link
                    to="/"
                    onClick={() => {
                      localStorage.removeItem("token");
                    }}
                  >
                    {/* <i className="fa fa-dashboard"></i> */}
                    <span>LogOut</span>
                  </Link>
                </li>

                {/* 현재 사용하지 않음 Section, Hazel 요청으로 LeftMenu 하단에 보임*/}
                {/* <li className="header">현재 사용하지 않음</li>
                            <li className={this.props.history.location.pathname.indexOf('WalletQna') > -1 ? "active treeview":"treeview"}>
                                <a href="/WalletQnaList">
                                    <i className="fa fa-th"></i>
                                    <span>지갑 문의사항</span>
                                </a>
                            </li>
                            <li className={this.props.history.location.pathname.indexOf('StickerQna') > -1 ? "active treeview":"treeview"}>
                                <a href="/StickerQnaList">
                                    <i className="fa fa-th"></i>
                                    <span>스티커 문의사항</span>
                                </a>
                            </li> */}
              </ul>
            )
          }
        </section>
        {/* <!-- /.sidebar --> */}
      </aside>
    );
  }
}
// window.location.reload();
export default withRouter(LeftMenu);
