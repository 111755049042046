import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Loading from '../Common/Loading/Loading'

import ArtistListTopBar from './ArtistListTopBar'
import ArtistListBottomBar from './ArtistListBottomBar'
import ArtistListTable from './ArtistListTable'

import ArtistInfoPopup from './ArtistInfoPopup'
import ArtistGroupAddPopup from './ArtistGroupAddPopup'
import ArtistGroupEditPopup from './ArtistGroupEditPopup'

function ArtistList(props) {
  const pageName = 'Member/Artists'
  const memberType = 1
  const [isLoading, setIsLoading] = useState(true) // 화면 로딩 여부
  const [showInfoPopup, setShowInfoPopup] = useState(false) // ArtistInfoPopup 활성 여부
  const [showGroupAddPopup, setShowGroupAddPopup] = useState(false) // ArtistGroupAddPopup 활성 여부
  const [showGroupEditPopup, setShowGroupEditPopup] = useState(false) // ArtistGroupEditPopup 활성 여부

  // 조회하는 목록 조건 초기화
  const [params, setParams] = useState({
    pageNumber: props.pageNumber ? props.pageNumber : 1,
    search: props.search ? props.search : '',
    groupId: props.groupId ? props.groupId : 0,
    countryCode: props.countryCode ? props.countryCode : -1,
  })

  const [pageMap, setPageMap] = useState('')
  const [artistList, setArtistList] = useState([])
  const [artistGroupList, setArtistGroupList] = useState([])
  const [artistCountryList, setArtistCountryList] = useState([])
  const [artistEmails, setArtistEmails] = useState([])
  const [modalArtist, setModalArtist] = useState('')

  const [moveGroupId, setMoveGroupId] = useState(
    props.groupId ? props.groupId : 0
  )
  const [moveArtistUserIdList, setMoveArtistUserIdList] = useState([])

  useEffect(() => {
    // check token
    if (sessionStorage.getItem("token")) {
      getArtistList()
      getArtistGroupList()
      getArtistCountryList()
      getArtistEmails()
    } else {
      props.history.push('/')
    }
  }, [])
  useEffect(() => {
    getArtistList()
  }, [params])

  const getArtistList = () => {
    setIsLoading(true)

    const queryString =
      '?pageNumber=' +
      params.pageNumber +
      '&search=' +
      params.search +
      '&type=' +
      memberType +
      '&groupId=' +
      params.groupId +
      '&country=' +
      params.countryCode

    fetch('/new/webAdmin/v2/member' + queryString, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'get',
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setPageMap(responseJson.pageMap)
        setArtistList(responseJson.memberList)
        setIsLoading(false)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }
  const getArtistGroupList = () => {
    fetch('/new/webAdmin/v2/artistGroup', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'get',
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setArtistGroupList(responseJson.groupList)
      })
  }
  const getArtistCountryList = () => {
    fetch('/new/webAdmin/v2/country', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'get',
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setArtistCountryList(responseJson.artistCountryList)
      })
      .catch(error => {
        console.error(error)
      })
  }
  const getArtistEmails = () => {
    fetch('/new/webAdmin/v2/email', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'get',
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setArtistEmails(responseJson.list)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleShow = data => {
    setModalArtist(data)
    setShowInfoPopup(true)
  }

  const handleClick = event => {
    const data = event.target.value

    if (event.target.checked) {
      setMoveArtistUserIdList(moveArtistUserIdList.concat(data))
    } else {
      setMoveArtistUserIdList(
        moveArtistUserIdList.filter(item => item !== data)
      )
    }
  }

  const addGroup = param => {
    fetch('/new/webAdmin/v2/artistGroup', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'POST',
      body: JSON.stringify(param),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setArtistGroupList(
          artistGroupList.concat({ SEQ: responseJson.insertId, ...param })
        )
      })
  }
  const editGroup = param => {
    fetch('/new/webAdmin/v2/artistGroup', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },

      method: 'PUT',
      body: JSON.stringify(param),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setArtistGroupList(
          artistGroupList.map(data =>
            param.SEQ === data.SEQ ? { ...data, NAME: param.NAME } : data
          )
        )
      })
  }
  const moveArtist = () => {
    const params = {
      artistUserIdList: moveArtistUserIdList,
      groupId: moveGroupId,
    }
    fetch('/new/webAdmin/v2/artist/group', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },

      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        getArtistList()
        setMoveArtistUserIdList([])
      })
  }
  if (isLoading) {
    return <Loading />
  } else {
    return (
      <div
        // className="content-wrapper"
        style={{ minHeight: '100%' }}
      >
        <section className="content-header">
          <h1>작가 관리</h1>
          <ol className="breadcrumb">
            <li>
              <i className="fa fa-dashboard"></i>회원 관리
            </li>
            <li className="active">작가 관리</li>
          </ol>
        </section>
        <Container fluid>
          <ArtistListTopBar
            params={params}
            setParams={setParams}
            setShowGroupAddPopup={setShowGroupAddPopup}
            setShowGroupEditPopup={setShowGroupEditPopup}
            moveGroupId={moveGroupId}
            setMoveGroupId={setMoveGroupId}
            moveArtist={moveArtist}
            artistGroupList={artistGroupList}
          />
          <ArtistListTable
            params={params}
            setParams={setParams}
            contents={artistList}
            handleShow={handleShow}
            handleClick={handleClick}
            artistCountryList={artistCountryList}
          />
          <ArtistListBottomBar
            csvData={artistEmails}
            params={params}
            pageMap={pageMap}
            pageName={pageName}
            memberType={memberType}
          />
        </Container>

        <ArtistInfoPopup
          show={showInfoPopup}
          setShow={setShowInfoPopup}
          data={modalArtist}
          setData={setModalArtist}
          artistList={artistList}
          setArtistList={setArtistList}
        />
        <ArtistGroupAddPopup
          show={showGroupAddPopup}
          setShow={setShowGroupAddPopup}
          addGroup={addGroup}
        />
        <ArtistGroupEditPopup
          show={showGroupEditPopup}
          setShow={setShowGroupEditPopup}
          artistGroupList={artistGroupList}
          editGroup={editGroup}
        />
      </div>
    )
  }
}

export default withRouter(ArtistList)
