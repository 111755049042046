import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import InputCopy from './InputCopy';

const BannerBox = styled.div`
    height: 200px;
    padding: 10px;
    margin: 5px;
`

const BannerImage = styled.img`
    height: 150px;
    border: none;
    border-radius: 10px;

    ${(props)=>css`
        background-color: ${props.light};
        &:hover {
            background-color: ${props.dark}
        }
    `}
`

const BannerInfo = styled.div`
    height: 40px;
`

const BannerItem = ({banner}) => {
    return(
        <BannerBox>
            <BannerImage 
                src={banner.cardImgUrl} 
                light={banner.lightBackgroundCode}
                dark={banner.darkBackgroundCode}
            />
            <BannerInfo>
                <InputCopy copyText={banner.packageId} />
            </BannerInfo>
        </BannerBox>
    );
}

export default BannerItem;