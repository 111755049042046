import React from "react";
import SdkInfo from "../../Components/B2B/SdkInfo";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const SdkInfoPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <SdkInfo date={query.date} />
    </React.Fragment>
  );
};

export default SdkInfoPage;
