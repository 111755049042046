import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = (props) => {
  const { component, path, exact } = props
  const token = sessionStorage.getItem('token')

  if (token) {
    return <Route component={component} path={path} exact={exact} />
  } else {
    return <Redirect to="/" />
  }
}

export default PrivateRoute
