import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import NewHome from '../../Components/NewHome/NewHome'
import {
  STIPOP_API_HOST,
  STIPOP_API_KEY,
} from '../../_Components/Common/common'

const index = () => {
  const [lang, setLang] = useState('en')
  const [menuList, setMenuList] = useState([])
  const [bannerList, setBannerList] = useState([])
  const [menu, setMenu] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [params, setParams] = useState({
    pageNumber: 1,
    limit: 20,
    mature: 'N',
  })

  const [itemList, setItemList] = useState([])
  const [newItemList, setNewItemList] = useState(null)
  const [artistItemList, setArtistItemList] = useState([])

  const getMenu = async () => {
    axios
      .get(`/new/webAdmin/v2/home/menu?lang=${lang}`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        setMenuList(data.result)
        if (data.result.length > 0) {
          setMenu(data.result[0])
        }
      })
  }

  const getBanner = () => {
    axios
      .get(`${STIPOP_API_HOST}/v4/ai/home/banner?lang=${lang}`, {
        headers: {
          apikey: STIPOP_API_KEY,
        },
      })
      .then(({ data }) => {
        setBannerList(data.body)
      })
  }

  const getItem = async menu => {
    setIsLoading(true)
    const query = `?id=${menu.seq}&lang=${menu.lang}&pageNumber=${
      params.pageNumber
    }&limit=${params.limit}&mature=${params.mature}${
      menu.sub === 'top' ? `&date=${menu.detail}` : ''
    }`

    await axios
      .get(`${STIPOP_API_HOST}/v4.0.1/home/main/new` + query, {
        headers: {
          apikey: STIPOP_API_KEY,
        },
      })
      .then(({ data }) => {
        if (data.body) {
          if (menu.sub === 'new') {
            console.log(data.body)
            setNewItemList(data.body)
          } else if (menu.sub === 'foryou') {
            setItemList(data.body.packageList)
            if (params.pageNumber === 1) {
              setArtistItemList(data.body.artistList)
            }
          } else {
            if (menu.type === 'package') {
              setItemList(data.body.packageList)
            } else if (menu.type === 'artist') {
              setItemList(data.body.artistList)
            }
          }
        }
      })

    await setIsLoading(false)
  }

  useEffect(() => {
    getMenu()
    getBanner()
    setItemList([])
  }, [lang])

  useEffect(() => {
    if (menu) {
      getItem(menu)
    }
  }, [menu])

  return (
    <Wrapper>
      <NewHome
        menuList={menuList}
        bannerList={bannerList}
        menu={menu}
        setMenu={setMenu}
        lang={lang}
        setLang={setLang}
        getMenu={getMenu}
        getBanner={getBanner}
        isLoading={isLoading}
        getItem={getItem}
        itemList={itemList}
        newItemList={newItemList}
        artistItemList={artistItemList}
      />
    </Wrapper>
  )
}

export default index

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
`
