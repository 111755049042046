import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withRouter } from "react-router-dom";

import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import SelectBox from "../Common/SelectBox";
import List from "../Common/List";

const billing = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState([]); 
    const [billingList, setBillingList] = useState([]);
    const [dateList, setDateList] = useState([]);
    const [date, setDate] = useState("");

    const getDate = async () => { 

        const response = await fetch(`/new/webAdmin/v2/billing/date`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === 'success') {
            setDateList(responseJson.dateList);
        } else if (responseJson.status === "fail" && responseJson.code === "1111") {
            props.history.push("/");
        } else {
            setDateList([]);
        }

    }; 

    const getBillingList = useCallback(async (date) => { 

        const response = await fetch(`/new/webAdmin/v2/billing?date=${date}`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === 'success') {
            
            if (filedList.length === 0) {
                
                let fileds = []
                
                for (const tmp in responseJson.billingList[0]) {
                    fileds.push(tmp);
                }
                
                setFiledList(fileds);
            }
            
            setBillingList(responseJson.billingList);

        } else if (responseJson.status === "fail" && responseJson.code === "1111") {
            props.history.push("/"); 
        } else {
            setBillingList([]);
        }

    }, [date]);

    const onChange = (date) => {
        setDate(date);
        getBillingList(date);
    }

    const [Select, setState] = SelectBox(date, dateList, onChange);

    const dataComponent = useCallback(() => {
        return (
            <List 
                fieldList={filedList}
                dataList={billingList}
            />
        )
    }, [billingList])


    useEffect(() => { 
        // componentDidMount
        const fetchData = async () => {
            await getDate();
            setIsLoading(false);
        }

        fetchData();

        return async () => {
          //componentWillUnmount
        };
    }, []);

    if (!isLoading) {
        return (
            <>
                <Header title={"billing"} />
                <Body HeaderComponent={Select} headerWidth={"20%"} DataComponet={dataComponent}  />
            </>
        )
    } else {
        return <Loading />;
    }
}

export default withRouter(billing);