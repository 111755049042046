import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { SpaceResponsive } from '../../Pages/NewApp/NewAppBannerPage'
import PackageItem from './PackageItem'
import ArtistItem from './ArtistItem'
import BannerList from '../NewApp/BannerList'
import MiniCard from '../NewApp/MiniCard'
import TopItem from './TopItem'

const Preview = props => {
  const {
    menuList,
    bannerList,
    menu,
    setMenu,
    lang,
    isLoading,
    getItem,
    itemList,
    newItemList,
    artistItemList,
  } = props

  return (
    menu && (
      <Wrapper>
        <SpaceResponsive height={40} />
        <Title>Home</Title>
        <MenuWrapper>
          {menuList.length > 0 &&
            menuList.map((item, index) => (
              <MenuItem
                key={index}
                onClick={async () => {
                  if (!isLoading) {
                    await setMenu(item)
                  }
                }}
                active={menu.seq === item.seq}
              >
                {item.name}
              </MenuItem>
            ))}
        </MenuWrapper>
        <BannerWrapper>
          {bannerList.length > 0 &&
            bannerList.map((item, index) => (
              <BannerItem key={index} src={item.img} />
            ))}
        </BannerWrapper>
        <SpaceResponsive height={16} />
        {isLoading ? (
          <ItemWrapper
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Loading...
          </ItemWrapper>
        ) : (
          <ItemWrapper>
            {menu.sub === 'new'
              ? newItemList && (
                  <>
                    {newItemList.collectionList.map((item, index) => (
                      <BannerList
                        key={index}
                        page={'new'}
                        bannerList={item.collection}
                      />
                    ))}
                    <MiniCard
                      page={'new'}
                      title={'Newly released stickers'}
                      stickerList={newItemList.packageList}
                    />
                  </>
                )
              : menu.sub === 'top'
              ? itemList &&
                itemList.map((item, index) => (
                  <TopItem key={index} item={item} />
                ))
              : menu.type === 'package'
              ? itemList &&
                itemList.map((item, index) => (
                  <PackageItem key={index} item={item} />
                ))
              : itemList &&
                itemList.map((item, index) => (
                  <ArtistItem key={index} item={item} />
                ))}
          </ItemWrapper>
        )}
      </Wrapper>
    )
  )
}

export default Preview

const Wrapper = styled.div`
  min-width: 375px;
  max-width: 375px;
  height: 100%;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  height: 40px;
  padding: 0 20px;
`

const MenuWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  display: flex;
`

const MenuItem = styled.div`
  display: inline-block;
  height: 32px;
  padding: 5px 15px;
  cursor: pointer;

  ${props => css`
    font-weight: ${props.active && 'bold'};
    border-bottom: ${props.active && '2px solid #000'};
  `}
`

const BannerWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
`

const BannerItem = styled.img`
  width: 100%;
  min-width: 375px;
  height: 84px;
`

const ItemWrapper = styled.div`
  width: 100%;
  height: 690px;
  margin-right: 20px;
  overflow-y: auto;
  overflow-x: hidden;
`
