import React from "react";
import StickerQnaDetail from "../../Components/StickerQna/StickerQnaDetail";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const StickerQnaDetailPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <StickerQnaDetail p_id={query.p_id} />
    </React.Fragment>
  );
};

export default StickerQnaDetailPage;
