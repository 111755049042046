import React from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const MdCard = props => {
  const { title, stickerList } = props

  const division = (arr, n) => {
    const len = stickerList.length
    const divide = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0)
    const newArr = []

    for (let i = 0; i < divide; i++) {
      newArr.push(arr.slice(i * 3, i * 3 + 3))
    }

    return newArr
  }

  return (
    <>
      <Title>{title}</Title>
      <SpaceResponsive height={16} />
      <Wrapper>
        {division(stickerList, 3).map((trio, i) => (
          <StickerWrapper
            key={i}
            bgColor={
              trio.filter((s, i) => s.bgColor !== null)[0]
                ? trio.filter((s, i) => s.bgColor !== null)[0].bgColor
                : '#fff'
            }
            contents={
              trio.filter((s, i) => s.contents !== null)[0]
                ? trio.filter((s, i) => s.contents !== null)[0].contents
                : ''
            }
          >
            {trio &&
              trio.map((sticker, index) =>
                index % 3 === 0 ? (
                  <StickerMain key={index}>
                    <img src={sticker.stickerImg} alt="" />
                  </StickerMain>
                ) : index % 3 === 1 ? (
                  <StickerItem key={index} n={index % 3}>
                    <img src={sticker.stickerImg} alt="" />
                  </StickerItem>
                ) : (
                  <StickerItem key={index} n={index % 3}>
                    <img src={sticker.stickerImg} alt="" />
                  </StickerItem>
                )
              )}
            <Contents>
              <img
                src="https://img.stipop.io/image/b2b/description.png"
                alt=""
              />
              <span>
                {trio.filter((s, i) => s.contents !== null)[0]
                  ? trio.filter((s, i) => s.contents !== null)[0].contents
                  : ''}
              </span>
            </Contents>
          </StickerWrapper>
        ))}
      </Wrapper>
      <SpaceResponsive height={34} />
    </>
  )
}

export default MdCard

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  position: relative;
  display: flex;

  /* scrollbar-color: transparent transparent;
  scrollbar-width: 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  } */
`

const StickerWrapper = styled.div`
  min-width: 216px;
  height: 171px;
  background-color: ${props => props.bgColor};
  border-radius: 36px;
  margin-right: 9.7px;
  padding: 12px 13px 0 13px;
  position: relative;
`

const StickerMain = styled.div`
  width: 120px;
  height: 120px;
  position: absolute;
  top: 12px;
  left: 13px;

  img {
    width: 100%;
    height: 100%;
  }
`

const StickerItem = styled.div`
  width: 58px;
  height: 58px;
  position: absolute;
  right: 13px;
  bottom: ${props => props.n === 2 && '39px'};

  img {
    width: 90%;
    height: 90%;
  }
`

const Contents = styled.div`
  width: 170px;
  height: 35px;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 10px;
  font-family: Poppins;
  position: absolute;
  left: 23px;
  bottom: 2px;

  img {
    height: 22px;
    width: 15px;
    margin-right: 8.5px;
    margin-bottom: 5px;
  }

  span {
    width: 146.5px;
    white-space: normal;
    overflow: hidden;
    line-height: 12px;
    letter-spacing: 0.12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
  }
`
