import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import getGoalOfTheDay from './GoalOfTheDay.js';
import FrequentTagsTable from './FrequentTagsTable.js';
import styled from 'styled-components';

const DesktopOnlyTable = styled.div`
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const InsertTags = (props) => {
    const [ tag, setTag ] = useState("");

    const handleAdd = () => {
        if(tag === "") {
            alert("Tag shouldn't be empty");
            return;
        }
        if(props.tags.length === 20) {
            alert("You can register at most 20 tags. \n태그는 최대 20개까지 등록할 수 있습니다.");
            return;
        }
        if(props.tags.filter(item => item.keyword === tag).length || props.existingTags.filter(item => item.keyword === tag).length){
            alert("Existing Tag. \n이미 존재하는 태그입니다.");
            return;
        }
        props.setTags([{keyword: tag}, ...props.tags]);
        setTag("");
    }

    const handleRemove = (item) => {
        props.setTags(props.tags.filter(tag => tag !== item))
    }

    const keyEvent = (event) => {
        if (event.key === "Enter") {
            handleAdd();
        }
    }
    
    return (
        <React.Fragment>
            <Container fluid>
                <Row style={{marginTop: "10px", marginBottom: "10px"}}>
                    <Col><font size={4}>ACCOUNT : {props.user.NAME} ( {props.user.TOTAL_COUNT} / {getGoalOfTheDay()} )</font></Col>
                </Row>
                <Row><Col><font size={4}>STICKER : {props.sticker.s_id} (PACKAGE : {props.sticker.p_id}. {props.sticker.packageName})</font></Col></Row>
                <Row>
                    <Col md={4}>
                        <Container>
                            <Row>
                                <Col><img src={props.sticker.s_img} style={{ width: "90%", height: "90%", marginTop: "10px", boxSizing:"border-box", boxShadow:"-18px 0 0 inset ,18px 0 0 inset,0 18px 0 inset ,0 -18px 0 inset"}} alt={props.sticker.s_img}></img></Col>
                            </Row>
                            <Row style={{marginTop: "10px", marginBottom: "10px"}}>
                                <Col xs={7} md={8}><input type="text" placeholder="Enter Tag..." style={{width : "100%"}} value={tag} onChange={(event) => setTag(event.target.value.toLowerCase())} onKeyPress={keyEvent} ></input></Col>
                                <Col xs={3} md={2}><Button variant="success" onClick={handleAdd}>Enter</Button></Col>
                                <Col xs={2} md={2}>{props.tags.length}/20 Tags</Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col md={3}>
                        <Container style={{backgroundColor:"#aeefea", width: "100%", height: "100%"}}>
                            <Row style={{marginLeft: "5px"}}><h3>Tags</h3></Row>
                            <Row>
                                {props.tags && props.tags.map((tag, idx) => (
                                    <Col key={idx} md={"auto"}>
                                        <Button variant="outline-secondary" onClick={() => handleRemove(tag)}>
                                            {tag.keyword} <i className="fa fa-times" />
                                        </Button>
                                    </Col>
                                ))}
                                {props.existingTags && props.existingTags.map((tag, idx) => (
                                    <Col key={idx} md={"auto"}>
                                        <Button variant="outline-secondary" disabled>
                                            {tag.keyword} <i className="fa fa-times" />
                                        </Button>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Col>
                    <Col md={5}><DesktopOnlyTable><FrequentTagsTable tags={props.frequentTags}/></DesktopOnlyTable></Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default InsertTags;