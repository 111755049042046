import React from 'react'
import Table from 'react-bootstrap/Table'

/*  props 로 넘어온 contents 는
    aniPic, commit, commitDate, containerList, country, downCnt, 
    g_id, langName, langSeq, 
    p_category, p_count, p_free, p_id, p_img, p_keyword, 
    p_name, p_price, p_tags, packageGroupList, 
    stickers, titleList, u_email, u_id, u_name
    을 담는 각 스티커팩 객체의 배열이다.
*/

const ContentsListTable = props => {
  const renderContents = () => {
    let list =
      props.contents &&
      props.contents.map(data => (
        <tr key={data.p_id}>
          <td>
            <input
              type="checkbox"
              onChange={props.handleClick}
              value={JSON.stringify(data)}
            />
          </td>
          <td>{data.p_id}</td>
          <td>
            {data.commit === 1 ? (
              <p style={{ color: 'green' }}>승인</p>
            ) : data.commit === 2 ? (
              <p style={{ color: 'orange' }}>수정 요청</p>
            ) : data.commit === 3 ? (
              <p style={{ color: 'red' }}>거절</p>
            ) : (
              <p>
                <strong>검토 중</strong>
              </p>
            )}
          </td>
          <td>{data.artiSubPackageYn}</td>
          <td>{data.u_name}</td>
          <td>{data.u_email}</td>
          <td>{data.country}</td>
          <td>{data.p_name}</td>
          <td onClick={() => props.handleShow(data)}>
            <img
              src={data.webp ? data.webp : data.p_img}
              width="100px"
              height="100px"
              alt=""
            ></img>
          </td>
          <td>
            <font size={5}>
              {data.aniPic === 0 ? (
                <i className="fa fa-times"></i>
              ) : (
                <i className="fa fa-check"></i>
              )}
            </font>
          </td>
          <td>{data.langName}</td>
          <td>{data.downCnt}</td>
          <td>{data.regDate}</td>
          <td>{data.commitDate === null ? '미승인' : data.commitDate}</td>
        </tr>
      ))
    return list
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>
            <input type="checkbox" />
          </th>
          <th>No.</th>
          <th>상태</th>
          <th>프리미엄</th>
          <th>작가</th>
          <th>작가 이메일</th>
          <th>출신국가</th>
          <th>이모티콘 이름</th>
          <th>img</th>
          <th>애니메이션</th>
          <th>
            <select
              id="language"
              style={{ verticalAlign: 'middle', fontSize: '12px' }}
              value={props.params ? props.params.language : -1}
              onChange={event =>
                props.setParams({
                  ...props.params,
                  language: event.target.value,
                })
              }
            >
              <option value={-1}>언어</option>
              {props.languages.map(data => (
                <option value={data.seq} key={data.seq}>
                  {data.langName}
                </option>
              ))}
            </select>
          </th>
          <th onClick={props.orderDown}>다운수</th>
          <th>등록일</th>
          <th>승인일</th>
        </tr>
      </thead>
      <tbody>{renderContents()}</tbody>
    </Table>
  )
}

export default React.memo(ContentsListTable)
