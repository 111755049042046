import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getPosts } from '../../_Components/Blog/blogApi';
import PostListView from '../../_Components/Blog/PostListView';
import BorderBox from '../../_Components/Common/BoxUI/BorderBox';
import PageTitle from '../../_Components/Common/PageTitle/PageTitle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ViewPostsPage = () => {
  const history = useHistory();
  const [category, setCategory] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [lastPage, setLastPage] = useState(10);
  const [postList, setPostList] = useState(['','','','','','']);
  const [refresh, setRefresh] = useState(false); // delete => refresh true로 postlist 새로 get

  useEffect(()=>{
    const arr = history.location.pathname.split("/");
    if(arr.length<4){
      setCategory(0)
      setCurrentPage(1)
    }
    else{
      setCategory(categoryTextToCode(arr[arr.length-2]));
      setCurrentPage(parseInt(arr[arr.length-1]));
    }
  }, [history.location.pathname]);

  useEffect(()=>{
    if(category!==undefined && currentPage){   
      getPostList(category, currentPage)
    }
  }, [category, currentPage])

  useEffect(()=>{
    if(refresh===true){
      setRefresh(false)
      getPostList(category, currentPage)
    }
  }, [refresh])

  const categoryTextToCode = (category) => {
    switch(category){
      case 'all': return 0;
      case 'engineering': return 1;
      case 'product': return 2;
      case 'insights': return 3;
      case 'stipop': return 4;
      case 'behind': return 5;
      case 'mainpost': return 6;
      default: return 0;
    }
  }

  const getPostList = async(category, page) => {
    // category===6일때 예외(main post만)
    const params = {
      category: category===6?0:category,
      pageNumber: page
    }
    const headers = {
      'Access-Control-Allow-Origin' : '*',
    }
    const result = await getPosts(params, headers);
    if(category === 6){
      setPostList([result.mainPost]);
      setLastPage(1);
    }
    else{
      setPostList(result.postList);
      if(category === 0 && currentPage === 1){
        setPostList(prev=>[result.mainPost, ...prev])
      }
      setLastPage(result.endPage);
    }
  }

  return(
    <Wrapper>
      <PageTitle
        mainTitle={'View Blog Post'}
        subTitle={'move to home'}
        v2Link={''}
        navNames={["view"]} 
      />
      <BorderBox 
        // boxTitle='Post List'
        component={
          <PostListView 
            category={category} 
            currentPage={currentPage} 
            lastPage={lastPage}
            postList={postList}
            setRefresh={setRefresh}
          />
        }
      />
    </Wrapper>
  )
}

export default ViewPostsPage;