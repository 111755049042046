import React from "react";
import StickerQnaList from "../../Components/StickerQna/StickerQnaList";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const StickerQnaListPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <StickerQnaList pageNumber={query.pageNumber} />
    </React.Fragment>
  );
};

export default StickerQnaListPage;
