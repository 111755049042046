// 태그 작업 보류됨 (2020.11~)

import React from "react";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../Common/Loading/Loading";
import Select from "react-select";
import StarRatings from "react-star-ratings";

class CategoryAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      category: [],
      spcategory: "",
      scategory: "",
      skeyword: "",
      slanguage: "",
      index: 0,
      gcategory: "",
      pcategory: [],
      pid: props.location.state.pid,
      partist: props.location.state.uname,
      pname: props.location.state.pname,
      subcategorys: [],
      selectedCategoryOption: "",
      keywords: [],
      selectedKeywordOption: "",
      languages: [],
      selectedLanguageOption: "",
      mCategory: "",
      metaCategory: [],
      metaSelectCategory: "",
      nCategory: "",
      subMetaCategory: [],
      nSubMetaCategory: "",
      mKeyword: "",
      rating: 2,
      subCategoryList2: [],
      inputCategory: "",
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this._stickerListData();
      // this._categoryData();
      this._packageCategoryListData();
      //   this._categoryMetaData();
      this._getPackageRate();
    } else {
      this.props.history.push("/");
    }
  }

  //카테고리 메타데이터
  _categoryData = () => {
    let subCategoryList = [];
    fetch("/new/webAdmin/v2/category", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        for (const tmp of responseJson.list) {
          for (const tmp2 of tmp.subCategoryList) {
            subCategoryList.push(tmp2);
          }
        }
        this.setState({
          category: responseJson.list,
          subCategoryList2: subCategoryList,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  /**초기화면 스티커 리스트 데이터 */
  _stickerListData = () => {
    fetch("/new/webAdmin/v2/sticker?pid=" + this.state.pid, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
      //    ,body: JSON.stringify(params)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          stickerList: responseJson.stickerList,
          subCategoryList: responseJson.categoryList,
          keywordList: responseJson.keywordList,
          languageList: responseJson.languageList,
        });
        // setTimeout(()=>{this.setState({isLoading:false})}, 1000)
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _handleCategoryChange = (selectedOption) => {
    this.setState({
      selectedCategoryOption: selectedOption.category,
      selectedCategoryOptionId: selectedOption.id,
      selectedCategoryOptioncId: selectedOption.c_id,
    });
  };

  _handleKeywordChange = (selectedOption) => {
    this.setState({ selectedKeywordOption: selectedOption.label });
  };

  _handleLanguageChange = (selectedOption) => {
    this.setState({ selectedLanguageOption: selectedOption.label });
  };

  _autoSearch = (key, s_id, selectedOption) => {
    if (key.keyCode === 13) {
      // this._addSubCategoryEnter(s_id)
    }
  };

  _inputChage = (value) => {
    // if ( value !== '') {

    fetch("/new/webAdmin/v2/category/auto?search=" + value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          inputCategory: value,
          subCategoryList2: responseJson.list,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
    // }
  };

  /**초기화면 스티커 리스트 렌더링 */
  _stickerListRender = () => {
    let list = [];
    if (this.state.stickerList) {
      for (const img of this.state.stickerList) {
        list.push(
          <React.Fragment>
            <table className="table no-margin">
              <tr>
                <th>
                  <img
                    style={{ height: "50%", width: "50%" }}
                    key={img.s_id}
                    src={img.s_img}
                    alt={img.s_img}
                  />
                </th>

                <th
                  style={{
                    textAlign: "inherit",
                    verticalAlign: "top",
                    paddingTop: "5%",
                  }}
                >
                  <tr>
                    Sub-category
                    <button
                      type="button"
                      onClick={() => {
                        this._addSubCategory(img.s_id);
                      }}
                      className="btn"
                      style={{ width: "50px", marginLeft: "9px" }}
                    >
                      Add
                    </button>
                  </tr>
                  <br />

                  <tr>
                    <div style={{ width: "400px" }}>
                      {/* {_select(this.state.subCategoryList2)} */}
                      <Select
                        id="customSelector"
                        onChange={this._handleCategoryChange}
                        onInputChange={this._inputChage}
                        onKeyDown={(key) =>
                          key.keyCode === 13 &&
                          this._addSubCategoryEnter(img.s_id)
                        }
                        options={this.state.subCategoryList2}
                      />
                    </div>
                  </tr>
                  <br />

                  <tr>{this._subCategoryRender(img.subcategory, img.s_id)}</tr>
                </th>
                <th>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>

                <th
                  style={{
                    textAlign: "inherit",
                    verticalAlign: "top",
                    paddingTop: "5%",
                  }}
                >
                  keyword
                  <button
                    type="button"
                    onClick={() => {
                      this._addKeyword(img.s_id);
                    }}
                    className="btn"
                    style={{ width: "50px", marginLeft: "9px" }}
                  >
                    add
                  </button>
                  <tr>
                    <input
                      id="testinput"
                      onChange={(e) =>
                        this.setState({ mKeyword: e.target.value })
                      }
                      type="text"
                      placeholder="typing keywords"
                    ></input>
                  </tr>
                  <select
                    style={{ display: "none" }}
                    className="form-control"
                    onChange={(event) => {
                      this.setState({ skeyword: event.target.value });
                    }}
                  >
                    <option value="">adding keyword</option>
                    {this._keywordSelectRender()}
                  </select>
                  <Select
                    onChange={this._handleKeywordChange}
                    style={this.state.styles}
                    options={this.state.keywords}
                  />
                  {this._keywordRender(img.s_id, img.keyword)}
                </th>
                <th>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                <th
                  style={{
                    textAlign: "inherit",
                    verticalAlign: "top",
                    paddingTop: "5%",
                  }}
                >
                  language
                  <button
                    type="button"
                    onClick={() => {
                      this._addLanguage(img.s_id);
                    }}
                    className="btn"
                    style={{ width: "50px", marginLeft: "9px" }}
                  >
                    add
                  </button>
                  <Select
                    onChange={this._handleLanguageChange}
                    style={this.state.styles}
                    options={this.state.languages}
                  />
                  {this._languageRender(img.s_id, img.language)}
                </th>
              </tr>
            </table>
          </React.Fragment>
        );
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  /**패키지 카테고리 리스트 데이터 */
  _packageCategoryListData = () => {
    fetch("/new/webAdmin/v2/packagecategory?pid=" + this.state.pid, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          pcategory: responseJson.data,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  /**패키지 카테고리 리스트 데이터 */
  _packageCategoryListRender = () => {
    let list = [];

    if (this.state.pcategory) {
      for (const data of this.state.pcategory) {
        list.push(
          <button className="btn btn-default">{data.p_category}</button>
        );
        list.push(
          <label>
            <button
              className="btn btn-primary btn-sm"
              style={{ marginLeft: "10%" }}
              onClick={() => {
                this._deletePackageCategory(this.state.pid, data.c_id);
              }}
            >
              delete
            </button>
          </label>
        );
      }
      return list;
    }
  };

  _deletePackageCategory = (pid, category) => {
    const params = {
      pid: pid,
      cid: category,
    };
    fetch("/new/webAdmin/v2/packagecategory", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "delete",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({ scategory: "" });
        this._stickerListData();
        this._categoryData();
        this._packageCategoryListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _deleteStickerSubCategory = (subcategory, s_cid, c_id, stickerId) => {
    const params = {
      sid: stickerId,
      subcategory: subcategory,
      subcategory_id: s_cid,
      category_id: c_id,
    };
    fetch("/new/webAdmin/v2/stickercategory", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "delete",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())

      .then((responseJson) => {

        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");

        this._stickerListData();
        this._categoryData();
        this._packageCategoryListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _deleteKeyword = (sid, keyword) => {
    const params = {
      sid: sid,
      keyword: keyword,
    };

    fetch("/new/webAdmin/v2/stickerkeyword", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "delete",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this._stickerListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _deleteLanguage = (sid, language) => {
    const params = {
      sid: sid,
      language: language,
    };

    fetch("/new/webAdmin/v2/stickerlanguage", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "delete",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this._stickerListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _subCategoryRender = (subcategoryList, sid) => {
    let list = [];
    if (subcategoryList) {
      for (const data of subcategoryList) {
        list.push(
          <button type="submit" className="btn btn-default">
            {data.subcategory} - {data.category}{" "}
          </button>
        );

        list.push(
          <button
            type="submit"
            onClick={() => {
              this._deleteStickerSubCategory(
                data.subcategory,
                data.s_cid,
                data.c_id,
                sid
              );
            }}
            className="btn btn-primary btn-sm"
            style={{ marginLeft: "10%" }}
          >
            delete
          </button>
        );
        list.push(<br></br>);
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  //카테고리데이터 화면출력
  _categoryRender = () => {
    let list = [];
    if (this.state.category) {
      for (const data of this.state.category) {
        list.push(<option value={data.SEQ}>{data.CATEGORY}</option>);
      }
      return list;
    }
  };

  _keywordRender = (sid, keywordList) => {
    let list = [];
    if (keywordList) {
      for (const data of keywordList) {
        list.push(
          <button type="submit" className="btn btn-default">
            {data.keyword}{" "}
          </button>
        );
        list.push(
          <button
            type="submit"
            onClick={() => {
              this._deleteKeyword(sid, data.keyword);
            }}
            className="btn btn-primary btn-sm"
          >
            delete
          </button>
        );
        list.push(<br></br>);
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _languageRender = (sid, languageList) => {
    let list = [];
    if (languageList) {
      for (const data of languageList) {
        list.push(
          <button type="submit" className="btn btn-default">
            {data.language}{" "}
          </button>
        );
        list.push(
          <button
            type="submit"
            onClick={() => {
              this._deleteLanguage(sid, data.language);
            }}
            className="btn btn-primary btn-sm"
          >
            delete
          </button>
        );

        list.push(<br></br>);
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _categorySelectRender = () => {
    let list = [];
    if (this.state.subCategoryList) {
      this.setState({ subcategorys: [] });
      for (const data of this.state.subCategoryList) {
        this.state.subcategorys.push({
          label: data.subcategory + " - " + data.category,
          name: data.subcategory,
          sc_id: data.subcategory_id,
          c_id: data.category_id,
        });
        list.push(
          <option value={data.subcategory_id + "@" + data.category_id}>
            {data.subcategory} - {data.category}
          </option>
        );
      }
      return list;
    }
  };

  _keywordSelectRender = () => {
    let list = [];
    if (this.state.keywordList) {
      this.setState({ keywords: [] });
      for (const data of this.state.keywordList) {
        this.state.keywords.push({ label: data.keyword });

        list.push(<option>{data.keyword}</option>);
      }
      return list;
    }
  };

  _languageSelectRender = () => {
    let list = [];
    if (this.state.languageList) {
      this.setState({ languages: [] });

      for (const data of this.state.languageList) {
        this.state.languages.push({ label: data.langName });

        list.push(<option>{data.langName}</option>);
      }
      return list;
    }
  };

  _addCategory = () => {
    if (this.state.spcategory === "") {
      alert("checking the category");
      return;
    }
    const params = {
      pid: this.state.pid,
      category: this.state.spcategory,
    };

    for (const item of this.state.pcategory) {
      if (params.category === item.c_id) {
        alert("duplicate category");
        return;
      }
    }

    fetch("/new/webAdmin/v2/packagecategory", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        //패키지 카테고리 갱신,
        //스티커 리스트 갱신
        this._packageCategoryListData();
        this._stickerListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _addSubCategoryAll = () => {
    // this.state.selectedCategoryOption = selectedOption.category;
    // this.state.selectedCategoryOptionId = selectedOption.id;
    // this.state.selectedCategoryOptioncId = selectedOption.c_id;

    if (this.state.selectedCategoryOption === "") {
      alert("Adding subcategory at once");
      return;
    }

    const subcategoryId = this.state.selectedCategoryOptionId;
    const categoryId = this.state.selectedCategoryOptioncId;

    const params = {
      subcategoryId,
      categoryId,
      pid: this.state.pid,
      stickerList: this.state.stickerList,
    };

    fetch("/new/webAdmin/v2/stickercategory/group", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");

        this._stickerListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _addSubCategoryEnterAll = () => {
    const { inputCategory } = this.state;

    if (inputCategory === "") {
      alert("Adding subcategory at once");
      return;
    }

    const params = {
      categoryId: 11,
      pid: this.state.pid,
      category: inputCategory,
      stickerList: this.state.stickerList,
    };

    fetch("/new/webAdmin/v2/stickercategory/group/new", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");

        this._stickerListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _addSubCategoryEnter = (s_id) => {
    const { inputCategory } = this.state;

    if (inputCategory.trim() === "" && inputCategory.trim().length === 0) {
      alert("add category");
    } else {
      const params = {
        s_id: s_id,
        category: inputCategory,
      };

      fetch("/new/webAdmin/v2/sticker/category/new", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "post",
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");

          this.setState({
            selectedCategoryOption: "",
            mCategory: "",
          });

          this._stickerListData();
          this._categoryData();
          this._packageCategoryListData();
        })
        .catch((error) => {
          console.error("123=" + error);
        });
    }
  };

  _addSubCategory = (sid) => {
    if (
      this.state.selectedCategoryOption === "" &&
      this.state.mCategory === ""
    ) {
      alert("add subcategory");
      return;
    }
    let params;
    if (
      this.state.selectedCategoryOption !== "" &&
      this.state.mCategory === ""
    ) {
      params = {
        sid: sid,
        subcategory: this.state.selectedCategoryOption,
        subcategoryId: this.state.selectedCategoryOptionId,
        categoryId: this.state.selectedCategoryOptioncId,
        pid: this.state.pid,
      };
    }
    if (
      this.state.selectedCategoryOption === "" &&
      this.state.mCategory !== ""
    ) {
      params = {
        sid: sid,
        subcategory: this.state.mCategory,
        pid: this.state.pid,
      };
    }
    if (
      this.state.selectedCategoryOption !== "" &&
      this.state.mCategory !== ""
    ) {
      params = {
        sid: sid,
        subcategory: this.state.mCategory,
        pid: this.state.pid,
      };

      alert("just one");
      return;
    }
    this.setState({
      mCategory: "",
      selectedCategoryOption: "",
    });
    fetch("/new/webAdmin/v2/stickercategory", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // const wme = document.getElementById('category-input');
        // wme.value = '';
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");

        // this._stickerListData();
        this.setState({
          selectedCategoryOption: "",
          mCategory: "",
        });

        this._stickerListData();
        this._categoryData();
        this._packageCategoryListData();
        //   this._categoryMetaData();

        //window.location.reload();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _addKeyword = (sid) => {
    let params;

    if (this.state.selectedKeywordOption === "" && this.state.mKeyword === "") {
      alert("add keyword");
      return;
    }

    if (this.state.selectedKeywordOption !== "" && this.state.mKeyword === "") {
      params = {
        sid: sid,
        keyword: this.state.selectedKeywordOption,
      };
    }

    if (this.state.selectedKeywordOption === "" && this.state.mKeyword !== "") {
      params = {
        sid: sid,
        keyword: this.state.mKeyword,
      };
    }
    if (this.state.selectedKeywordOption !== "" && this.state.mKeyword !== "") {
      params = {
        sid: sid,
        keyword: this.state.mKeyword,
      };
    }
    fetch("/new/webAdmin/v2/stickerkeyword", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this._stickerListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _addLanguage = (sid) => {
    if (this.state.selectedLanguageOption === "") {
      alert("add language");
      return;
    }

    const params = {
      sid: sid,
      language: this.state.selectedLanguageOption,
    };

    fetch("/new/webAdmin/v2/stickerlanguage", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");

        this._stickerListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _categoryMetaRender = () => {
    let list = [];

    if (this.state.metaCategory) {
      for (const data of this.state.metaCategory) {
        list.push(<option value={data.SEQ}>{data.CATEGORY}</option>);
      }
      return list;
    }
  };

  _setMetaCategory = (value) => {
    this.setState({ metaSelectCategory: value });
    this._subMetaCategoryData();
  };

  _addNewCategory = () => {
    const params = {
      category: this.state.nCategory,
    };

    fetch("/new/webAdmin/v2/category", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({ nCategory: "" });
        window.location.reload();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _subMetaCategoryData = () => {
    if (this.state.metaSelectCategory === "") return;
    const params = "sub=" + this.state.metaSelectCategory;
    fetch("/new/webAdmin/v2/subCategory?" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          subMetaCategory: responseJson.list,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _subCategoryMetaRender = () => {
    let list = [];
    if (this.state.subMetaCategory) {
      for (const data of this.state.subMetaCategory) {
        list.push(<option>{data.category}</option>);
      }
      return list;
    }
  };

  _addNewMetaSubCategory = () => {
    if (
      this.state.metaSelectCategory === "" ||
      this.state.nSubMetaCategory === ""
    ) {
      alert("add category");
      return;
    }

    const params = {
      category: this.state.metaSelectCategory,
      subcategory: this.state.nSubMetaCategory,
    };

    fetch("/new/webAdmin/v2/subCategory", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this._subMetaCategoryData();
        window.location.reload();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  changeRating = (newRating, name) => {
    this.setState({
      rating: newRating,
    });

    const params = {
      pid: this.state.pid,
      rating: newRating,
    };

    fetch("/new/webAdmin/v2/categoryrating", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _getPackageRate = () => {
    fetch("/new/webAdmin/v2/categoryrating?pid=" + this.state.pid, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          rating: responseJson.data[0].rate,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _addlanguageAll = () => {
    if (this.state.slanguage === "") {
      alert("언어를 선택하세요");
      return;
    }

    const params = {
      stickerList: this.state.stickerList,
      langName: this.state.slanguage,
    };

    fetch("/new/webAdmin/v2/stickerlanguage/group", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");

        this._stickerListData();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
          // className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          {/* 패키지정보*/}
          <div className="box-body">
            <div className="table-responsive">
              {/**메타 카테고리추기 */}
              <button
                type="button"
                onClick={() => {
                  this.props.history.goBack();
                }}
                data-toggle="modal"
                className="btn btn-primary"
                style={{ display: "inline-block", marginLeft: "10px" }}
              >
                back
              </button>

              <label>Category</label>
              <button
                type="button"
                data-toggle="modal"
                data-target="#add-new-category"
                className="btn btn-primary"
                style={{
                  width: "10%",
                  display: "inline-block",
                  marginLeft: "5px",
                }}
              >
                add
              </button>

              <select
                style={{ display: "inline-block" }}
                value={this.state.sort}
                onChange={(event) => {
                  this._setMetaCategory(event.target.value);
                }}
              >
                <option value="">checking the category</option>
                {this._categoryRender()}
              </select>

              <label>Sub-category</label>
              <button
                type="button"
                data-toggle="modal"
                data-target="#add-new-subcategory"
                className="btn btn-primary"
                style={{
                  width: "10%",
                  display: "inline-block",
                  marginLeft: "5px",
                }}
              >
                add
              </button>

              <select
                style={{ display: "inline-block" }}
                value={this.state.sort}
              >
                <option value="">Sub-category</option>
                {this._subCategoryMetaRender()}
              </select>
              <StarRatings
                rating={this.state.rating}
                starRatedColor="blue"
                changeRating={this.changeRating}
                numberOfStars={5}
                name="rating"
              />

              {/**메타 서브카테고리추기 */}
            </div>

            <div className="table-responsive">
              <label>Name : </label>
              <label>{this.state.pname}</label>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <label>Artist : </label>
              <label>{this.state.partist}</label>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <label>selecting the category</label>
              <label>
                <button
                  type="button"
                  onClick={() => {
                    this._addCategory();
                  }}
                  className="btn btn-primary"
                  style={{ width: "50px", marginLeft: "9px" }}
                >
                  Add
                </button>
              </label>
              <label>
                <select
                  className="form-control"
                  onChange={(event) => {
                    this.setState({ spcategory: event.target.value });
                  }}
                >
                  <option value="">checking the category</option>
                  {this._categoryRender()}
                </select>
              </label>
              <label>{this._packageCategoryListRender()}</label>
            </div>

            <div className="table-responsive" style={{ height: "250px" }}>
              <label> Adding subcategory at once </label>

              <label>
                <button
                  type="button"
                  onClick={() => {
                    this._addSubCategoryAll();
                  }}
                  className="btn btn-primary"
                  style={{ width: "50px", marginLeft: "9px" }}
                >
                  Add
                </button>
              </label>

              <label>
                <div style={{ width: "200px" }}>
                  <Select
                    id="customSelector"
                    onChange={this._handleCategoryChange}
                    onInputChange={this._inputChage}
                    onKeyDown={(key) =>
                      key.keyCode === 13 && this._addSubCategoryEnterAll()
                    }
                    options={this.state.subCategoryList2}
                  />
                </div>
                {/* <select className="btn" style={{ width: '100%', marginLeft: "9px" }} className="form-control" onChange={(event) => { this.setState({ gcategory: event.target.value }) }}>
                                    <option value="">sub-category</option>
                                    {this._categorySelectRender(this.state.pid)}
                                </select> */}
              </label>

              <label style={{ marginLeft: "5%" }}>
                Adding language at once
              </label>

              <label>
                <button
                  type="button"
                  onClick={() => {
                    this._addlanguageAll();
                  }}
                  className="btn btn-primary"
                  style={{ width: "50px", marginLeft: "9px" }}
                >
                  add
                </button>
              </label>
              <label>
                <select
                  className="form-control"
                  onChange={(event) => {
                    this.setState({ slanguage: event.target.value });
                  }}
                >
                  <option value="">language</option>
                  {this._languageSelectRender()}
                </select>
              </label>
            </div>

            <div className="box-footer clearfix">
              <ul className="pagination pagination-sm no-margin pull-left">
                {this._stickerListRender()}
              </ul>
            </div>
          </div>

          <div className="modal fade" id="add-new-category">
            <div
              className="modal-dialog"
              style={{ width: "40%", height: "80%", overflow: "auto" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" style={{ textAlign: "center" }}>
                    Category Add
                  </h4>
                </div>

                <div className="modal-body">
                  <table className="table no-margin">
                    <thead>
                      <tr>
                        <th>category name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>
                          <input
                            type="text"
                            style={{ width: "30%" }}
                            onChange={(event) =>
                              this.setState({ nCategory: event.target.value })
                            }
                          ></input>
                        </th>
                      </tr>
                    </tbody>
                  </table>

                  <button
                    type="button"
                    onClick={this._addNewCategory}
                    data-dismiss="modal"
                    className="btn btn-primary"
                    style={{
                      width: "10%",
                      display: "inline-block",
                      marginLeft: "9px",
                    }}
                  >
                    add
                  </button>

                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-primary"
                    style={{
                      width: "10%",
                      display: "inline-block",
                      marginLeft: "9px",
                    }}
                  >
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/**서브카테고리 추가 팝업창*/}
          <div className="modal fade" id="add-new-subcategory">
            <div
              className="modal-dialog"
              style={{ width: "40%", height: "80%", overflow: "auto" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" style={{ textAlign: "center" }}>
                    Sub-category add
                  </h4>
                </div>

                <div className="modal-body">
                  <table className="table no-margin">
                    <thead>
                      <tr>
                        <th>Sub-category</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>
                          <input
                            type="text"
                            style={{ width: "30%" }}
                            onChange={(event) =>
                              this.setState({
                                nSubMetaCategory: event.target.value,
                              })
                            }
                          ></input>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    type="button"
                    onClick={this._addNewMetaSubCategory}
                    data-dismiss="modal"
                    className="btn btn-primary"
                    style={{
                      width: "10%",
                      display: "inline-block",
                      marginLeft: "9px",
                    }}
                  >
                    Add
                  </button>

                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-primary"
                    style={{
                      width: "10%",
                      display: "inline-block",
                      marginLeft: "9px",
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default withRouter(CategoryAdd);
