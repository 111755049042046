import React, { useEffect } from 'react'
import styled from 'styled-components'
import AppPreview from './AppPreview'

const NewApp = props => {
  const {
    homeStickers,
    newStickers,
    searchStickers,
    topStickers,
    setIsNewBanner,
    page,
    isLoading,
  } = props

  return (
    <div style={{ height: 'calc(100% - 60px)' }}>
      <AppPreview
        homeStickers={homeStickers}
        newStickers={newStickers}
        searchStickers={searchStickers}
        topStickers={topStickers}
        setIsNewBanner={setIsNewBanner}
        page={page}
        isLoading={isLoading}
      />
    </div>
  )
}

export default NewApp
