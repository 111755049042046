import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { paging } from "../../Common/Page";
import Loading from "../Common/Loading/Loading";
import ReactExport from "react-export-excel";

const CorporateUsers = (props) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(
    props.pageNumber ? props.pageNumber : 1
  );
  const [search, setSearch] = useState(props.search ? props.search : "");
  const [option, setOption] = useState("all");
  const [order, setOrder] = useState({ orderName: "", orderBy: "" });
  const [corporateUsers, setCorporateUsers] = useState([]);
  const [pageMap, setPageMap] = useState({});
  const [excel, setExcel] = useState(null);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getCorporateUsers(pageNumber);
      excelDown(pageNumber);
    } else {
      this.props.history.push("/");
    }
  }, [order]);

  const getCorporateUsers = async (pageNumber) => {
    setIsLoading(true);
    const response = await fetchData(pageNumber);
    setCorporateUsers(response.corporateUsers);
    setPageMap(response.pageMap);
    setIsLoading(false);
  };

  const fetchData = async (pageNumber) => {
    const params =
      "?pageNumber=" +
      pageNumber +
      "&search=" +
      search +
      "&option=" +
      option +
      "&orderName=" +
      order.orderName +
      "&orderBy=" +
      order.orderBy;
    // console.log(params)
    // console.log(search);
    // console.log(option)
    return fetch("/new/webAdmin/v2/corporate-user" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const renderCorporateUsers = () => {
    const list = corporateUsers.map((user) => (
      <tr key={user.UC_ID}>
        <td>{user.UC_ID}</td>
        <td>{user.APP_NAME}</td>
        <td>{user.COMPANY}</td>
        <td>{user.LINK}</td>
        <td>{user.TEAM_SIZE}</td>
        <td>{user.DOMAIN}</td>
        <td>{user.DEV_YN}</td>
        <td>{user.REGION}</td>
        <td>{user.UPDATE_DATE}</td>
        <td>{user.REG_DATE}</td>
        <td>{user.NAME}</td>
        <td>{user.EMAIL}</td>
      </tr>
    ));

    return list;
  };

  const keyEvent = (event) => {
    if (event.key === "Enter") {
      getCorporateUsers(1, search);
    }
  };

  const excelDown = async () => {
    console.log("?");

    const params =
      "?pageNumber=" +
      pageNumber +
      "&search=" +
      search +
      "&option=" +
      option +
      "&orderName=" +
      order.orderName +
      "&orderBy=" +
      order.orderBy;

    const response = await fetch(
      "/new/webAdmin/v2/corporate-user/excel" + params,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      }
    );

    const responseJson = await response.json();
    if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
    // console.log(userData)
    setExcel(responseJson.corporateUsers);
    // return userData.corporateUsers;
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div
        // className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header">
          <h1>기업 유저 관리</h1>
          <ol className="breadcrumb">
            <li>
              <i className="fa fa-dashboard"></i>회원 관리
            </li>
            <li className="active">기업 유저 관리</li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            {/* <!-- Left col --> */}
            <section className="col-lg-12 connectedSortable">
              {/* <!-- Custom tabs (Charts with tabs)--> */}
              <div className="box box-info">
                <div
                  className="box-header with-border"
                  style={{ paddingBottom: "30px" }}
                >
                  <div className="box-tools pull-left">
                    <div
                      className="input-group input-group-sm"
                      style={{ width: "500px" }}
                    >
                      <select
                        style={{ display: "inline-block", marginLeft: "20px" }}
                        onChange={(event) => {
                          setOption(event.target.value);
                        }}
                      >
                        <option value="all">검색조건(전체)</option>
                        <option value="mau">app mau</option>
                        <option value="category">category</option>
                        <option value="subCategory">sub category</option>
                        <option value="region">region</option>
                      </select>
                      <input
                        type="text"
                        name="table_search"
                        value={search}
                        className="form-control pull-right"
                        placeholder="Search"
                        onKeyPress={keyEvent}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <div className="input-group-btn">
                        <button
                          type="submit"
                          className="btn btn-default"
                          onClick={() => getCorporateUsers(1)}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                      {excel && (
                        <ExcelFile>
                          <ExcelSheet data={excel} name="list">
                            <ExcelColumn label="UserId" value="UC_ID" />
                            <ExcelColumn label="App name" value="APP_NAME" />
                            <ExcelColumn label="Company" value="COMPANY" />
                            <ExcelColumn label="Website" value="LINK" />
                            <ExcelColumn label="App MAU" value="TEAM_SIZE" />
                            <ExcelColumn label="Category" value="DOMAIN" />
                            <ExcelColumn
                              label="DEV_YN"
                              value="DEV_YN"
                            />
                            <ExcelColumn label="Region" value="REGION" />
                            <ExcelColumn
                              label="Last Login"
                              value="UPDATE_DATE"
                            />
                            <ExcelColumn label="Signup date" value="REG_DATE" />
                            <ExcelColumn label="name" value="NAME" />
                            <ExcelColumn label="email" value="EMAIL" />
                          </ExcelSheet>
                        </ExcelFile>
                      )}
                    </div>
                  </div>
                </div>
                {/* <!-- /.box-header --> */}
                <div className="box-body">
                  <div className="table-responsive">
                    <table className="table no-margin">
                      <thead>
                        <tr>
                          <th>UserId</th>
                          <th>App name</th>
                          <th>Company</th>
                          <th>Website</th>
                          <th>App MAU</th>
                          <th>Category</th>
                          <th>DevYn</th>
                          <th>Region</th>
                          <th
                            onClick={() => {
                              if (order.orderBy === "ASC") {
                                setOrder({
                                  orderName: "login",
                                  orderBy: "DESC",
                                });
                              } else if (order.orderBy === "DESC") {
                                setOrder({
                                  orderName: "login",
                                  orderBy: "ASC",
                                });
                              } else {
                                setOrder({
                                  orderName: "login",
                                  orderBy: "DESC",
                                });
                              }
                            }}
                          >
                            Last Login
                          </th>
                          <th
                            onClick={() => {
                              if (order.orderBy === "ASC") {
                                setOrder({
                                  orderName: "sign",
                                  orderBy: "DESC",
                                });
                              } else if (order.orderBy === "DESC") {
                                setOrder({ orderName: "sign", orderBy: "ASC" });
                              } else {
                                setOrder({
                                  orderName: "sign",
                                  orderBy: "DESC",
                                });
                              }
                            }}
                          >
                            Signup date
                          </th>
                          <th>name</th>
                          <th>email</th>
                        </tr>
                      </thead>
                      <tbody>{renderCorporateUsers()}</tbody>
                    </table>
                  </div>
                  <div className="box-footer clearfix">
                    <ul className="pagination pagination-sm no-margin pull-right">
                      {paging(
                        pageNumber,
                        pageMap,
                        "Member/CorporateUsers",
                        search
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    );
  }
};

export default withRouter(CorporateUsers);

