import React from 'react'
import styled from 'styled-components'
// import { MdLocalOffer } from "react-icons/md";

/**
 *
 * Border Box Component
 *
 * - 상단 Border 적용 : borderColor => #HEXCODE
 * - Box 이름 : 미입력 시 자리 차지 X
 * - border & padding 제거 필요 => layout css는 최소화한 상태로 UI 만들 예정
 *
 */

const Wrapper = styled.div`
  width: 100%;
  height: 812px;
  margin-top: 15px;
  padding: 0 15px;
  overflow-x: scroll;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const Inner = styled.div`
  width: 100%;
  background-color: white;
  border-top: 3px solid ${props => props.borderColor};
  box-shadow: 0 1px 1px #00000020;
  border-radius: 4px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-line;
  overflow-x: scroll;
  overflow-y: hidden;
`

const HeaderBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-bottom: 1px solid #f4f4f4;
`

const Title = styled.div`
  font-size: 16px;
`

const BorderBox = ({ borderColor = '#d2d6de', boxTitle = '', component }) => {
  return (
    <Wrapper>
      <Inner borderColor={borderColor}>
        {boxTitle !== '' && (
          <HeaderBox>
            {/* <MdLocalOffer size={18} /> */}
            <Title>{boxTitle}</Title>
          </HeaderBox>
        )}
        {component}
      </Inner>
    </Wrapper>
  )
}

export default BorderBox
