import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

// import LeftMenu from "../../Components/LeftMenu";
import Loading from '../../Components/Common/Loading/Loading.js'
import CardPackage from './CardPackage'
import {
  CardTypeDropdown,
  CardLangDropdown,
  CardCountryDropdown,
} from './CurationDropdown'

const CurationInsert = props => {
  const isNewBanner = props.match.params.cardId === 'Insert' ? true : false

  const [isLoading, setIsLoading] = useState(true)
  const [curationCard, setCurationCard] = useState({
    type: props.state ? props.state.type : '',
    title: props.state ? props.state.title : '',
    tag: props.state ? props.state.tag : '',
    imgUrl: props.state ? props.state.imgUrl : '',
    language: props.state ? props.state.language : '',
    country: props.state ? props.state.country : '',
  })
  const [curationCardPackages, setCurationCardPackage] = useState(
    props.state ? props.state.curationCardPackages : []
  )

  useEffect(() => {
    console.log(curationCardPackages)
    if (sessionStorage.getItem("token")) {
      setIsLoading(false)
    } else {
      props.history.push('/')
    }
  }, [])

  const getBannerContainerPackages = cardId => {
    setIsLoading(true)
    fetch('/new/webAdmin/v2/curation/' + cardId, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'get',
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setCurationCardPackage(responseJson.result.cardPackageList)
        setIsLoading(false)
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const addBannerContainerBanner = () => {
    setCurationCardPackage(
      curationCardPackages.concat({
        packageId: '',
        stickerId: '',
        lightBackgroundCode: '',
        darkBackgroundCode: '',
        order: '',
      })
    )
  }

  const removeBannerContainerPackage = idx => {
    if (curationCardPackages[idx].seq) {
      const params = {
        seq: curationCardPackages[idx].seq,
      }

      fetch('/new/webAdmin/v2/curation/package', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem("token"),
        },
        method: 'delete',
        body: JSON.stringify(params),
      })
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.status === 'success') {
            setCurationCardPackage(
              curationCardPackages.filter((item, index) => index === idx)
            )
          } else if (responseJson.status === "fail" && responseJson.code === "1111") {
            props.history.push("/")  
          } else {
            alert('실패')
          }
        })
        .catch(error => {
          console.error('123=' + error)
        })
    } else {
      setCurationCardPackage(
        curationCardPackages.filter((item, index) => index !== idx)
      )
    }
  }

  const moveOrderUp = (seq, order, idx) => {
    if (idx === 0) {
      alert('처음입니다.')
      return
    }

    const params = {
      cardId: props.match.params.cardId,
      seq,
      order,
    }

    fetch('/new/webAdmin/v2/curation/package/order/up', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'put',
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'success') {
          getBannerContainerPackages(props.match.params.cardId)
        } else if (responseJson.status === "fail" && responseJson.code === "1111") {
          props.history.push("/");
        } else {
          alert('실패')
        }
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const moveOrderDown = (seq, order, idx) => {
    if (idx + 1 === curationCardPackages.length) {
      alert('마지막입니다.')
      return
    }

    const params = {
      cardId: props.match.params.cardId,
      seq,
      order,
    }

    fetch('/new/webAdmin/v2/curation/package/order/down', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'put',
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'success') {
          getBannerContainerPackages(props.match.params.cardId)
        } else if (responseJson.status === "fail" && responseJson.code === "1111") {
          props.history.push("/");
        } else {
          alert('실패')
        }
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const handleSubmit = () => {
    // setIsLoading(true);
    // console.log(isNewBanner);
    // console.log(curationCard);
    // console.log(curationCardPackages);
    let updateFormData = new FormData()
    let updateImgId = ''

    updateFormData.append('type', curationCard.type)
    updateFormData.append('title', curationCard.title)
    updateFormData.append('language', curationCard.language)
    updateFormData.append('country', curationCard.country)

    if (!isNewBanner) {
      updateFormData.append('cardId', props.match.params.cardId)
    }

    updateFormData.append('packageInput', JSON.stringify(curationCardPackages))

    for (const tmp of curationCardPackages) {
      console.log(tmp)
      if (tmp.img && typeof tmp.img[0] !== 'string') {
        updateFormData.append('imgs', tmp.img[0])
        if (!isNewBanner) {
          updateImgId += tmp.seq + ','
        }
      } else {
        if (!isNewBanner) {
          updateImgId += 'null,'
        }
      }
    }

    updateFormData.append(
      'updateImgId',
      updateImgId.substring(0, updateImgId.length - 1)
    )

    fetch('/new/webAdmin/v2/curation', {
      headers: {
        token: sessionStorage.getItem("token"),
      },
      method: isNewBanner ? 'POST' : 'PUT',
      body: updateFormData,
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        props.history.push({
          pathname: '/Curation',
          search: '?type=' + curationCard.type,
        })
      })
      .catch(error => {
        console.error('123=' + error)
        alert('실패')
      })
  }

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      {isLoading ? (
        <Loading />
      ) : (
        <div
          // className="content-wrapper"
          style={{ minHeight: '100%' }}
        >
          <section className="content-header" style={{ marginBottom: '15px' }}>
            <h1>Curation 추가</h1>
          </section>
          <section>
            <Container fluid>
              <Row style={{ marginBottom: '10px' }}>
                <Col>
                  <Container
                    fluid
                    style={{
                      backgroundColor: '#fff',
                      borderTop: 'solid 5px skyblue',
                      marginTop: '10px',
                    }}
                  >
                    <Row>
                      <Col md={2}>
                        <font size={5}>Card Type: </font>
                      </Col>
                      <Col>
                        {isNewBanner ? (
                          <CardTypeDropdown
                            type={curationCard.type}
                            setType={type =>
                              setCurationCard({
                                ...props.bannerContainer,
                                type: type,
                              })
                            }
                          />
                        ) : (
                          <font size={5}>{curationCard.type}</font>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <font size={5}>title</font>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          style={{ width: '100%' }}
                          value={curationCard.title}
                          onChange={event =>
                            setCurationCard({
                              ...curationCard,
                              title: event.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <font size={5}>language</font>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          style={{ width: '100%' }}
                          value={curationCard.language}
                          onChange={event =>
                            setCurationCard({
                              ...curationCard,
                              language: event.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <font size={5}>country</font>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          style={{ width: '100%' }}
                          value={curationCard.country}
                          onChange={event =>
                            setCurationCard({
                              ...curationCard,
                              country: event.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row style={{ marginBottom: '10px' }}>
                <Col>
                  <Button onClick={addBannerContainerBanner}>
                    패키지 추가
                  </Button>
                </Col>
              </Row>

              {curationCardPackages &&
                curationCardPackages.map((item, idx) => (
                  <CardPackage
                    key={idx}
                    idx={idx}
                    type={curationCardPackages.type}
                    bannerContainerPackage={item}
                    setBannerContainerPackage={(index, data) =>
                      setCurationCardPackage(
                        curationCardPackages.map((item, idx) =>
                          idx === index ? data : item
                        )
                      )
                    }
                    removeBannerContainerPackage={removeBannerContainerPackage}
                    moveOrderUp={moveOrderUp}
                    moveOrderDown={moveOrderDown}
                  />
                ))}
              <Row style={{ marginTop: '10px' }}>
                <Col md="auto">
                  <Button
                    variant="secondary"
                    onClick={() =>
                      props.history.push({
                        pathname: '/Curation',
                        search: '?type=' + curationCard.type,
                      })
                    }
                  >
                    목록으로
                  </Button>
                </Col>
                <Col md="auto">
                  <Button variant="success" onClick={handleSubmit}>
                    등록하기
                  </Button>
                </Col>
                <br />
                <br />
                <br />
              </Row>
            </Container>
          </section>
        </div>
      )}
    </React.Fragment>
  )
}

export default CurationInsert
