import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import TextInput from "./TextInput";

const Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
`;

const Field = styled.th`
    border-top: 1px solid #f4f4f4;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: left;
`;

const Data = styled.td`
    border-top: 1px solid #f4f4f4;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    display: table-cell;
`;

const Input = styled.input`
    width:80%;
    padding:8px;
`;

const List = ({fieldList, dataList, clickE, isClick, setDatas, setPtag}) => {

    // const [isClick, setClick] = useState(new Array(dataList.length).fill(false));

    // const test = (index) => {
    //     let tmp = isClick;
    //     tmp[index] = !tmp[index];
    //     setClick(tmp);
    // }

    // console.log(isClick);

    const savePtag = async (packageId, tag) => {

        console.log(packageId, tag);
        const params = {
             packageId
            ,tag
        }

        await fetch(`/new/webAdmin/v2/bookmark/ptag`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'put',
            body: JSON.stringify(params)
        });

        setPtag(packageId, tag, 'update');
    };

    const deletePtag = async (packageId, p_tags, tag) => {

        console.log(packageId, tag);
        const tags = p_tags ? p_tags.indexOf(`,${tag}`) > -1 ? p_tags.replace(`,${tag}`, '') : p_tags.indexOf(`${tag}`) > -1 ? p_tags.replace(`${tag}`, '') : p_tags.indexOf(`${tag},`) > -1 ? p_tags.replace(`${tag},`, '') : p_tags : p_tags;
        const params = {
             packageId
            ,tag : tags
        }

        await fetch(`/new/webAdmin/v2/bookmark/ptag`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'delete',
            body: JSON.stringify(params)
        });

        setPtag(packageId, tags, 'delete');
    };

    const save = async (packageId, stickerId, keyword) => {

        const params = {
             stickerId
            ,keyword
        }

        await fetch(`/new/webAdmin/v2/bookmark/keyword`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'post',
            body: JSON.stringify(params)
        });

        setDatas(packageId, stickerId, keyword, 'update')
    };

    const deleteKeyword = async (packageId, stickerId, keyword) => {

        const params = {
             stickerId
            ,keyword
        }

        await fetch(`/new/webAdmin/v2/bookmark/keyword`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'delete',
            body: JSON.stringify(params)
        });

        setDatas(packageId, stickerId, keyword, 'delete')
    };

    const pTagInput = (data) => {

        const set = (value) => {
            setData(value);
        }

        const [value, Tinput, setData] = TextInput('', set, data.p_id+"_ptags");

        return (
            <tr>
                <td>{Tinput}</td>
                <td>
                    <button onClick={() => savePtag(data.p_id, value)}>저장</button>
                    <button onClick={() => deletePtag(data.p_id, data.p_tags, value)}>삭제</button>
                </td>
            </tr>
        )
    }

    return (
        <Table>
            <tbody>
                <tr>
                    {
                        fieldList.map((filed, index) => {
                            return (
                                <Field key={index}>{filed}</Field>
                            )
                        })
                    }
                </tr>
                {
                    dataList.map((data, index) => {
                        
                        let list = [];
            
                        for (const key in data) {
                            if (key === 'stickerImg') {
                                list.push(
                                    <td key={key+index}><img src={data[key]} style={{width:100, height:100}} /></td>
                                )
                            } else if (key !== 'bookmarkStickerList') {
                                list.push(
                                    <Data key={key+index}>{data[key]}</Data>
                                );
                            }
                        }
            
                        return (
            
                            clickE ?
                            // <tr key={index} onClick={ () => test(index)}>
                            <>
                                <tr key={index} onClick={ () => clickE(data.p_id, index)}>
                                    {list}
                                </tr>
                                {
                                    isClick[index] && pTagInput(data)
                                }
                                {
                                    isClick[index] &&     
                                    
                                    data.bookmarkStickerList.map((sticker, index) => {

                                        const set = (value) => {
                                            setData(value);
                                        }
                
                                        const [value, Tinput, setData] = TextInput('', set, sticker.s_id+"_keyword");

                                        return (
                                            <tr key={index}>
                                                <td>{sticker.s_id}</td>
                                                <td>
                                                    <img src={sticker.stickerImg} style={{width:200, height:200}} />
                                                </td>
                                                <td>{sticker.keyword}</td>
                                                <td>{Tinput}</td>
                                                <td>
                                                    <button onClick={() => save(data.p_id, sticker.s_id, value)}>저장</button>
                                                    <button onClick={() => deleteKeyword(data.p_id, sticker.s_id, value)}>삭제</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    
                                }
                            </>
                            :
                            <tr key={index}>
                                {list}
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}

export default List;