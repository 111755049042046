import React from 'react';
import { Table, Button } from 'react-bootstrap';

const ArtistLicenseTable = (props) => {

    const getStatusColor = (status) => {
        switch(status) {
            case 'Approved' : return 'green';
            case 'Rejected' : return 'red';
            default : return 'black'
        }
    }
    
    const renderContents = () => {
        let list = props.contents && props.contents.map(data => (
            <tr key={data.userId} >
                <td>{data.userId}</td>
                <td>{data.artistName}</td>
                <td>{data.userEmail}</td>
                <td><font color={getStatusColor(data.licenseStatus)}>{data.licenseStatus}</font></td>
                <td>
                    <Button onClick={() => updateStatus(data.userId, 'Y')}>승인</Button>
                    <Button onClick={() => updateStatus(data.userId, 'N')}>보류</Button>
                </td>
                <td><a href={data.licenseUrl ? "https://api.stipop.io/admin/license?img=" + data.licenseUrl : null} target='_blank' rel='noopener noreferrer'>조회</a></td>
                <td>{data.regDate}</td>
                <td>{data.updateDate}</td>
                <td><Button onClick={() => handleDelete(data)}>삭제</Button></td>
            </tr>
        ))
        return list;
    }

    const updateStatus = (u_id, status) => {
        const params = {
             u_id
           , status
       };

       fetch("/new/webAdmin/v2/tax/licenses/commit", {
           headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Access-Control-Allow-Origin': '*',
               'token': sessionStorage.getItem("token")
           }
           , method: 'put'
           , body: JSON.stringify(params)
       })
       .then(() => props.getContents())
       .catch((error) => { console.error("123=" + error) });
    }

    const handleDelete = (license) => {
        props.setLicense(license);
        props.setShow(true);
    }
    
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>User ID</th>
                    <th>작가명</th>
                    <th>작가 이메일</th>
                    <th>Status</th>
                    <th>승인/보류</th>
                    <th>Link</th>
                    <th>등록일</th>
                    <th>수정일</th>
                    <th>삭제</th>
                </tr>
            </thead>
            <tbody>
                {renderContents()}
            </tbody>
        </Table>
    )
}

export default React.memo(ArtistLicenseTable);