import React from "react";
import TagDel from "../../Components/StickerTag/TagDel";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const TagDelPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <TagDel searchText={query.searchText} />
    </React.Fragment>
  );
};

export default TagDelPage;
