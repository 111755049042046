import React from "react";
import ArtistList from "../../Components/Member/ArtistList";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const ArtistListPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <ArtistList
        pageNumber={query.pageNumber * 1}
        search={query.search}
        type={query.type * 1}
        groupId={query.groupId * 1}
        countryCode={query.country * 1}
      />
    </React.Fragment>
  );
};

export default ArtistListPage;
