import React, { useEffect } from 'react'
import styled from 'styled-components'
import Preview from './Preview'
import PageTitle from '../../_Components/Common/PageTitle/PageTitle'
import Table from './Table'

const NewHome = props => {
  const {
    menuList,
    bannerList,
    menu,
    setMenu,
    lang,
    setLang,
    getMenu,
    getBanner,
    isLoading,
    getItem,
    itemList,
    newItemList,
    artistItemList,
  } = props

  return (
    <>
      <PageTitle
        mainTitle="New Home"
        v1Link=""
        navNames={['Banners', 'New Home']}
      />
      <Wrapper>
        <Preview
          menuList={menuList}
          bannerList={bannerList}
          menu={menu}
          setMenu={setMenu}
          lang={lang}
          isLoading={isLoading}
          getItem={getItem}
          itemList={itemList}
          newItemList={newItemList}
          artistItemList={artistItemList}
        />
        <Table
          menuList={menuList}
          lang={lang}
          setLang={setLang}
          getMenu={getMenu}
          getBanner={getBanner}
        />
      </Wrapper>
    </>
  )
}

export default NewHome

const Wrapper = styled.div`
  display: flex;
  height: calc(100% - 70px);
  padding: 20px;
  overflow-y: hidden;
`
