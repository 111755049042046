import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { Pagination } from 'react-bootstrap'

const ArtistView = props => {
  const { user, uPageMap, artist, aPageMap, query, setQuery, isLoading } = props

  useEffect(() => {
    setQuery({ ...query, type: 1 })
  }, [query.id])

  const renderPaging = (pageNumber, pageMap) => {
    let list = []
    let j = 0
    let i = 0

    let test = pageNumber !== 1 ? pageNumber - 1 : 1
    let last =
      pageNumber !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount

    list.push(
      <Pagination.Item
        key="first"
        onClick={() => {
          setQuery({ ...query, pageNumber: test })
        }}
      >
        &laquo;
      </Pagination.Item>
    )

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
      let num = pageMap.startPage * 1 + j

      if (num === pageMap.pageNumber * 1) {
        list.push(
          <Pagination.Item key={i} className="active">
            {num}
          </Pagination.Item>
        )
      } else {
        list.push(
          <Pagination.Item
            key={i}
            onClick={() => {
              setQuery({ ...query, pageNumber: num })
            }}
          >
            {num}
          </Pagination.Item>
        )
      }
      j++
    }

    list.push(
      <Pagination.Item
        key="last"
        onClick={() => {
          setQuery({ ...query, pageNumber: last })
        }}
      >
        &raquo;
      </Pagination.Item>
    )
    return <Pagination>{list}</Pagination>
  }

  return (
    <div
      // className="content-wrapper"
      style={{ minHeight: '100%' }}
    >
      <section className="content-header">
        <h1>Subscription Artist 관리</h1>
        <ol className="breadcrumb">
          <li>
            <i className="fa fa-dashboard"></i>Subscription
          </li>
          <li className="active">Artist</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          {/* <!-- Left col --> */}
          <section className="col-lg-12 connectedSortable">
            {/* <!-- Custom tabs (Charts with tabs)--> */}
            <div className="box box-info">
              {/* <!-- /.box-header --> */}
              <div className="box-body">
                <div className="table-responsive">
                  <Table className="table no-margin">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>이름</th>
                        <th>email</th>
                        <th>가입일</th>
                        <th>현재 구독중인 유저</th>
                        <th>전체 구독 유저</th>
                      </tr>
                    </thead>
                    <tbody>
                      {artist.length > 0 &&
                        artist.map((a, index) => {
                          return (
                            <Fragment key={index}>
                              <tr
                                onClick={() => {
                                  if (query.id === a.artistId) {
                                    setQuery({ ...query, id: '' })
                                  } else {
                                    setQuery({ ...query, id: a.artistId })
                                  }
                                }}
                                style={{
                                  backgroundColor:
                                    query.id === a.artistId
                                      ? '#f0f8ff'
                                      : 'transparent',
                                  cursor: 'pointer',
                                  borderTop:
                                    query.id === a.artistId &&
                                    '2px solid #00c0ef',
                                  borderBottom:
                                    query.id === a.artistId &&
                                    '2px solid #00c0ef',
                                }}
                              >
                                <td>{a.artistId}</td>
                                <td>{a.u_name}</td>
                                <td>{a.u_email}</td>
                                <td>{a.u_date}</td>
                                <td>{a.current_sub}</td>
                                <td>{a.total_sub}</td>
                              </tr>
                              {!isLoading &&
                                query.id === a.artistId &&
                                user.length > 0 && (
                                  <Fragment>
                                    <div
                                      style={{
                                        width: '100%',
                                        height: '50px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <UserButton
                                        onClick={() => {
                                          setQuery({ ...query, type: 1 })
                                        }}
                                        active={query.type === 1}
                                      >
                                        현재 구독자
                                      </UserButton>
                                      <UserButton
                                        onClick={() => {
                                          setQuery({ ...query, type: 0 })
                                        }}
                                        active={!query.type}
                                      >
                                        전체 구독자
                                      </UserButton>
                                    </div>
                                    <tr
                                      style={{
                                        borderTop: '2px solid #00c0ef',
                                        backgroundColor: '#f0f8ff',
                                      }}
                                    >
                                      <th>No.</th>
                                      <th>이름</th>
                                      <th>email</th>
                                      <th>가입일</th>
                                      <th>구독 시작일</th>
                                      <th>구독 만료일</th>
                                    </tr>
                                    {user.map((u, i) => (
                                      <tr
                                        key={i}
                                        style={{
                                          borderBottom:
                                            i === user.length - 1 &&
                                            '2px solid #00c0ef',
                                          backgroundColor:
                                            new Date(u.endDate) > new Date()
                                              ? '#f0f8ff'
                                              : '#ededed',
                                        }}
                                      >
                                        <td>{u.userId}</td>
                                        <td>{u.u_name}</td>
                                        <td>{u.u_email}</td>
                                        <td>{u.u_date}</td>
                                        <td>
                                          {new Date(
                                            u.startDate
                                          ).toLocaleString()}
                                        </td>
                                        <td>
                                          {new Date(u.endDate).toLocaleString()}
                                        </td>
                                      </tr>
                                    ))}
                                    <ul
                                      className="pagination pagination-sm pull-left"
                                      style={{ margin: '10px 0' }}
                                    >
                                      {renderPaging(query.pageNumber, uPageMap)}
                                    </ul>
                                  </Fragment>
                                )}
                            </Fragment>
                          )
                        })}
                    </tbody>
                  </Table>
                </div>
                <div className="box-footer clearfix">
                  <ul className="pagination pagination-sm no-margin pull-right">
                    {renderPaging(query.pageNumber, aPageMap)}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default ArtistView

const Table = styled.table`
  td {
    /* max-width: 300px; */
    white-space: break-spaces;
  }
`

const UserButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.active ? '#00c0ef' : '#000')};
  font-weight: ${props => props.active && 'bold'};
  cursor: pointer;
`
