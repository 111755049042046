import React, { useState, useEffect } from "react";
// import LeftMenu from "../../Components/LeftMenu";
import Loading from "../../Components/Common/Loading/Loading";
import StudioBannerTopBar from "../../Components/StudioBanner/StudioBannerTopBar";
import StudioBannerSection from "../../Components/StudioBanner/StudioBannerSection";
import StudioBannerBottomBar from "../../Components/StudioBanner/StudioBannerBottomBar";

const StudioBannerListPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getBanners();
    } else {
      props.history.push("/");
    }
  }, []);

  const getBanners = () => {
    fetch("/new/webAdmin/v2/studio-banners", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setBanners(responseJson.banners);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const updateBannerStatus = (bannerId) => {
    fetch("/new/webAdmin/v2/studio-banners/" + bannerId + "/status", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "PUT",
    })
      .then(() => {
        setBanners(
          banners.map((item) =>
            item.bannerId === bannerId
              ? { ...item, status: !item.status }
              : item
          )
        );
        alert("변경되었습니다");
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const deleteBanner = (bannerId) => {
    fetch("/new/webAdmin/v2/studio-banners/" + bannerId, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "DELETE",
    })
      .then(() => {
        setBanners(banners.filter((item) => item.bannerId !== bannerId));
        alert("삭제했습니다");
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <div
        // className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header" style={{ marginBottom: "15px" }}>
          <h1>Studio 배너 관리</h1>
          <ol className="breadcrumb">
            <li>
              <a href="/Banner/Studio">
                <i className="fa fa-dashboard"></i> 배너 관리
              </a>
            </li>
            <li className="active">Studio 배너 관리</li>
          </ol>
        </section>
        <section>
          <StudioBannerTopBar />
          {isLoading ? (
            <Loading />
          ) : (
            <StudioBannerSection
              banners={banners}
              updateBannerStatus={updateBannerStatus}
              deleteBanner={deleteBanner}
            />
          )}
          <StudioBannerBottomBar />
        </section>
      </div>
    </React.Fragment>
  );
};

export default StudioBannerListPage;
