import axios from "axios";
import React, { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../Components/Common/Loading/Loading";
import DefaultButton from "../../_Components/Common/ButtunUI/DefaultButton";
import PageTitle from "../../_Components/Common/PageTitle/PageTitle";
import MessageQnaDetail from "../../_Components/MessageCenter/MessageQna/MessageQnaDetail";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
`;

const Inner = styled.div`
  display: flex;
`;

const ControlBox = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-left: 15px;
`;

const MessageQnaDetailPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [onUpdate, setOnUpdate] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  // last chat sender => 0:artist, 1:admin
  const [lastSender, setLastSender] = useState(-1);
  const [roomId, setRoomId] = useState();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      const splitPath = location.pathname.split("/");
      setRoomId(splitPath[splitPath.length - 1]);
      setIsLoading(false);
    } else {
      history.push("/");
    }
  }, []);

  const updateRoomCloseStatus = async () => {
    setOnUpdate(true);

    await axios
      .put(
        `/new/webAdmin/v2/message-center/room/status/${roomId}`,
        { roomStatus: !isClosed ? 0 : lastSender === 1 ? 1 : 2 },
        {
          headers: { token: sessionStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setIsClosed(!isClosed);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setOnUpdate(false);
      });
  };

  return (
    <Wrapper>
      <PageTitle
        mainTitle={"1:1 Message - Detail"}
        v1Link={""}
        navNames={["Management", "Message Center", "1:1 Message", "Detail"]}
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <Fragment>
          <ControlBox>
            <DefaultButton
              btnText={isClosed ? "Open Message" : "Close Message"}
              theme={isClosed ? "blue" : "red"}
              onClick={() => {
                if (!onUpdate) updateRoomCloseStatus();
              }}
            />
          </ControlBox>
          <Inner>
            <MessageQnaDetail
              roomId={roomId}
              setIsClosed={setIsClosed}
              isClosed={isClosed}
              setLastSender={setLastSender}
            />
          </Inner>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default MessageQnaDetailPage;
