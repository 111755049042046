import React from "react";
import AThemeList from "../../Components/Container/AThemeList";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const AThemeListPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <AThemeList
        pageNumber={query.pageNumber}
        type={query.type}
        search={query.search}
      />
    </React.Fragment>
  );
};

export default AThemeListPage;
