import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import LineGraph from "./LineGraph";

function Dashboard(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [viewLabel, setViewLabel] = useState([]);
  const [downloadLabel, setDownloadLabel] = useState([]);
  const [memberLabel, setMemberLabel] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [memberData, setMemberData] = useState([]);

  useEffect(() => {

    if (sessionStorage.getItem("token")) {
        document.body.classList.add("skin-blue");
        getDashboardData();
    } else {
      props.history.push("/");
    }

  }, []);

  const getDashboardData = () => {
    fetch("/new/webAdmin/v2/dashboard", {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => { 

        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        
        let labels = [];
        let val = [];
        let labels1 = [];
        let val1 = [];
        let labels2 = [];
        let val2 = [];

        for (const data of responseJson.views) {
          labels.push(data.date);
          val.push(data.cnt);
        }

        for (const data of responseJson.downs) {
          labels1.push(data.date);
          val1.push(data.cnt);
        }

        for (const data of responseJson.member) {
          labels2.push(data.date);
          val2.push(data.cnt);
        }

        setViewLabel(labels);
        setDownloadLabel(labels1);
        setMemberLabel(labels2);

        setViewData(val);
        setDownloadData(val1);
        setMemberData(val2);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div
        // className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header">
          <h1>
            Dashboard
            <small>Version 2.0</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <i className="fa fa-dashboard"></i> Home
            </li>
            <li className="active">Dashboard</li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header with-border">
                  <h3 className="box-title">VIEWS</h3>
                  <div className="box-tools pull-right">
                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="collapse"
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="remove"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-8">
                      <p className="text-center">
                        <strong>데일리 뷰수</strong>
                      </p>
                      <LineGraph
                        labels={viewLabel}
                        datasets={viewData}
                      ></LineGraph>
                    </div>
                    <div className="col-md-4">
                      <p className="text-center">
                        <strong>Yesterday Top 5</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-footer"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header with-border">
                  <h3 className="box-title">DOWNLOADS</h3>
                  <div className="box-tools pull-right">
                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="collapse"
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="remove"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-8">
                      <p className="text-center">
                        <strong>데일리 다운수</strong>
                      </p>
                      <LineGraph
                        labels={downloadLabel}
                        datasets={downloadData}
                      ></LineGraph>
                    </div>
                    <div className="col-md-4">
                      <p className="text-center">
                        <strong>Yesterday Top 5</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header with-border">
                  <h3 className="box-title">MEMBER</h3>
                  <div className="box-tools pull-right">
                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="collapse"
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="remove"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-8">
                      <p className="text-center">
                        <strong>일간 가입자</strong>
                      </p>
                      <LineGraph
                        labels={memberLabel}
                        datasets={memberData}
                      ></LineGraph>
                    </div>
                    <div className="col-md-4">
                      <p className="text-center">
                        <strong>Yesterday Top 5</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-footer"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header with-border">
                  <h3 className="box-title">구독자 통계</h3>
                  <div className="box-tools pull-right">
                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="collapse"
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-box-tool"
                      data-widget="remove"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="box-body">
                  {/* <img 
                                        src="https://www.paypalobjects.com/webstatic/en_US/developer/docs/login/connectwithpaypalbutton.png"
                                        onClick={() => {
                                            window.location.href = "https://www.paypal.com/connect?flowEntry=static&client_id=AVvtiUZsDLKUB7vZMCmm36_zw9p78aEJeeWpsIwooV3DEPExxL8GmkWjeKEuVLZVBVHlXluyN7FUrfDF&scope=openid&redirect_uri=https%3A%2F%2Fstudio.stipop.io/my-studio/wallet/payment?state=sucess"
                                        }}
                                    /> */}
                  {/* {this._subscribeRender()} */}
                </div>
                <div className="box-footer"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Dashboard);
