import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { MdContentCopy } from 'react-icons/md'
import { FaTimes } from 'react-icons/fa'
import InputCopy from '../../../_Components/Curation/InputCopy'

const TrendingPreview = props => {
  useEffect(() => {
    console.log(props.modalInfo)
  }, [])

  const clickCopy = id => {
    const text = document.createElement('textarea')
    text.value = id
    document.body.appendChild(text)
    text.select()
    document.execCommand('copy')
    document.body.removeChild(text)
  }

  const history = useHistory()
  const clickEdit = cardInfo => {
    history.push({
      pathname: `/Curation/${cardInfo.cardId}`,
      state: {
        type: cardInfo.type,
        title: cardInfo.title,
        language: cardInfo.language,
        country: cardInfo.country,
        curationCardPackages: cardInfo.card,
      },
    })
  }

  return (
    <>
      <ModalBox className={`${!props.mode ? 'light' : 'dark'} `}>
        <MainSection>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              id="mainBackground"
              style={{
                backgroundColor: !props.mode
                  ? props.modalInfo.lightColor
                  : props.modalInfo.darkColor,
                marginRight: '15px',
              }}
            >
              <img src={props.modalInfo.mainImg} alt="" />
            </div>
          </div>
        </MainSection>
        <div
          style={{
            height: '5%',
            width: '90%',
            padding: '0 20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span style={{ color: !props.mode ? 'black' : 'white' }}>
            Package ID :{' '}
            <StickerId>
              <InputCopy
                copyText={props.modalInfo.packageId}
                color={!props.mode ? 'black' : 'white'}
              />
            </StickerId>
          </span>
          <span
            style={{
              color: !props.mode ? 'black' : 'white',
              marginLeft: '10px',
            }}
          >
            Sticker ID :{' '}
            <StickerId>
              <InputCopy
                copyText={props.modalInfo.mainId}
                color={!props.mode ? 'black' : 'white'}
              />
            </StickerId>
          </span>
        </div>
        <StickerSection mode={!props.mode ? 'light' : 'dark'}>
          {props.modalInfo.stickerList.map((sticker, index) => (
            <Col
              md={!props.wide ? 2 : 4}
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImgBox
                style={{
                  backgroundColor: !props.mode
                    ? props.modalInfo.lightColor
                    : props.modalInfo.darkColor,
                }}
              >
                <img src={sticker.s_img} alt="" />
              </ImgBox>
              <InputCopy
                copyText={sticker.s_id}
                color={!props.mode ? 'black' : 'white'}
              />
            </Col>
          ))}
        </StickerSection>
        <Row
          style={{
            height: '10%',
            width: '90%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {/* <button
            style={{
              backgroundColor: 'lightgreen',
              border: 'none',
              borderRadius: '5px',
              marginRight: '10px',
              padding: '5px 15px',
              fontWeight: 'bold',
            }}
            onClick={() => clickEdit(props.modalInfo.cardInfo)}
          >
            EDIT
          </button> */}
          <button
            style={{
              backgroundColor: 'crimson',
              border: 'none',
              borderRadius: '5px',
              color: 'white',
              padding: '5px 15px',
              fontWeight: 'bold',
            }}
            onClick={() => props.modalClose()}
          >
            CLOSE
          </button>
        </Row>
      </ModalBox>
    </>
  )
}

export default TrendingPreview

const ModalBox = styled(Container)`
  height: 76vh;
  padding: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;

  &.light {
    background-color: #fff;
    border: 5px solid #ff4500;
    border-radius: 30px;
  }
  &.dark {
    background-color: #303030;
    border: 5px solid #ff855b;
    border-radius: 30px;
  }
`

const MainSection = styled(Row)`
  height: 30%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  #mainBackground {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    img {
      width: 110%;
      height: 110%;
    }
  }
  span {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
  }
`
const StickerId = styled.span`
  font-weight: bold;
  // &:hover {
  //   cursor: pointer;
`

const StickerSection = styled(Row)`
  height: 60%;
  width: 90%;
  padding: 20px 20px;
  overflow-y: auto;
  border-top: ${props =>
    props.mode === 'light' ? '1px solid #303030' : '1px solid #ffefb0'};
  border-bottom: ${props =>
    props.mode === 'light' ? '1px solid #303030' : '1px solid #ffefb0'};
`

const ImgBox = styled.div`
  width: 120px;
  height: 120px;
  margin: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
    height: 80%;
  }
`
