import React from 'react'
import SearchApiNewFixed from '../../Components/B2B/searchApiNewFixed'
// import LeftMenu from "../../Components/LeftMenu";
import queryString from 'query-string'

const SearchApiNewFixedPage = ({ match, location }) => {
  const query = queryString.parse(location.search)

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <SearchApiNewFixed
        pageNumber={query.pageNumber}
        search={query.search}
        lang={query.lang}
      />
    </React.Fragment>
  )
}

export default SearchApiNewFixedPage
