import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Loading from '../../Components/Common/Loading/Loading';
import BorderBox from '../../_Components/Common/BoxUI/BorderBox';
import PageTitle from '../../_Components/Common/PageTitle/PageTitle';
import CurationPreview from '../../_Components/Curation/CurationPreview';

const CurationPreviewPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (window.sessionStorage.getItem("token")) {
          setIsLoading(false);
        } else {
          history.push("/");
        }
      }, []);

    return(
        <>
            <PageTitle
                mainTitle={"Curation Preview"}
                v2Link={""}
                navNames={["B2B", "Curation Preview"]}
            />
            {isLoading && <Loading />}
            {!isLoading && (
                <BorderBox
                  boxTitle={"Curation Preview Page"}
                  component={<CurationPreview />}
                />
            )}
        </>
    );
}

export default CurationPreviewPage;