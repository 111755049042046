import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withRouter } from "react-router-dom";

import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import DateInputCategory from "../Common/DateInputCategory";
import List from "../Common/List";

const DownloadPackage = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState([]); 
    const [downloadList, setDownloadList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [platform, setPlatform] = useState("app");
    const [range, setRange] = useState("month");
    const [category, setCategory] = useState("");
    const [categoryList, setCategoryList] = useState("");

    const getCategory = async () => { 

        const response = await fetch(`/new/webAdmin/v2/track/category`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

        if (responseJson.status === 'success') {

            setCategoryList(responseJson.categoryList);

        } else {
            setCategoryList([]);
        }

    }; 

    const setStartDates = (date) => {
        setStartDate(date);
        setState(date);
    }

    const setEndDates = (date) => {
        setEndDate(date);
        setState1(date);
    }

    const search = useCallback(async () => {
        
        const response = await fetch(`/new/webAdmin/v2/track/download?&range=${range}&startDate=${startDate}&endDate=${endDate}&platform=${platform}&category=${category}`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === 'success') {
            
            if (filedList.length === 0) {
                
                let fileds = []
                
                for (const tmp in responseJson.downloadList[0]) {
                    fileds.push(tmp);
                }
                
                setFiledList(fileds);
            }
            
            setDownloadList(responseJson.downloadList);

        } else {
            setDownloadList([]);
        }

    }, [startDate, endDate, platform, range, category]);


    const [ Date, setState, setState1] = DateInputCategory(startDate, setStartDates, endDate, setEndDates, setPlatform, setRange, search, categoryList, setCategory);

    const dataComponent = useCallback(() => {
        return (
            <List 
                fieldList={filedList}
                dataList={downloadList}
            />
        )
    }, [downloadList])


    useEffect(() => { 

        const fetchData = async () => {
            await getCategory();
            setIsLoading(false);
        }

        fetchData();

        return async () => {
          //componentWillUnmount
        };
    }, []);

    if (!isLoading) {
        return (
            <>
                <Header title={"패키지 다운로드"} />
                <Body HeaderComponent={Date} headerWidth={"50%"} DataComponet={dataComponent}  />
            </>
        )
    } else {
        return <Loading />;
    }
}

export default withRouter(DownloadPackage);