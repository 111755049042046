import React from 'react';

export const regExp = (text) => {
    var pattern = /[\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

    if (pattern.test(text)) {
        return text.replace(pattern, "");
    } else {
        return text;
    }
}

export const pageMapSetting = (pageNumber, totalCount, rowPerPage, pageName) => {

    // let pageNumber          = pageNumber;
    // let totalCount          = totalCount;
    let onePageCountRow = rowPerPage;
    let pageCount = 1;
    let groupCount = 1;
    let groupNumber = 0;
    let pageGroupCount = 10;
    let startPage = 1;
    let endPage = 10;
    let startRow = 0;
    let endRow = onePageCountRow;
    let modNum = 0;
    let listStartNumber = 1;

    let pageMap = null;

    if (pageNumber < 1) {
        pageNumber = 1;
    }

    if (totalCount === 0) {
        endPage = 1;
    } else {
        // 총 페이지 수 재설정
        if ((totalCount % onePageCountRow) * 1 == 0) {
            pageCount = parseInt((totalCount / onePageCountRow) * 1);
        } else {
            pageCount = parseInt((totalCount / onePageCountRow) * 1) + 1;
        }

        // 요청 페이지와 최종 페이지 번호 비교
        // if (pageNumber > pageCount) {
        //     pageNumber = pageCount;
        // }

        // 총 그룹 수 재설정 - set to request
        if ((pageCount % endPage) * 1 == 0) {
            groupCount = parseInt((pageCount / endPage) * 1);

        } else {
            groupCount = parseInt((pageCount / endPage) * 1) + 1;
        }

        // 현재 그룹 번호 재설정
        if (pageNumber % endPage == 0) {
            groupNumber = parseInt(pageNumber / endPage) - 1;
        } else {
            groupNumber = parseInt(pageNumber / endPage);
        }

        // 표시될 페이지 시작값 설정 - set to request
        startPage = (groupNumber) * endPage + 1;

        // 표시될 페이지 끝값 설정 - set to request
        endPage = (groupNumber) * endPage + endPage;
        if (pageCount < endPage) {
            endPage = pageCount;
        }
        // 표시될 결과 시작열 설정
        startRow = (pageNumber - 1) * onePageCountRow;

        if (totalCount % onePageCountRow > 0) {
            modNum = totalCount % onePageCountRow;
        } else {
            modNum = onePageCountRow;
        }

        listStartNumber = (pageCount - pageNumber) * onePageCountRow + modNum;
    }

    pageMap = {
          "pageNumber": pageNumber
        , "onePageCountRow": onePageCountRow
        , "totalCount": totalCount
        , "pageCount": pageCount
        , "groupCount": groupCount
        , "groupNumber": groupNumber
        , "pageGroupCount": pageGroupCount
        , "startPage": startPage
        , "endPage": endPage
        , "startRow": startRow
        , "endRow": endRow
        , "modNum": modNum
        , "listStartNumber": listStartNumber
        , "pageName" : pageName
    }

    return pageMap
}