import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';

const TaxFormTable = (props) => {
    const [ taxForms, setTaxForms ] = useState(props.contents);

    const renderContents = () => {
        let list = taxForms && taxForms.map(data => (
            <tr key={data.seq} >
                <td>{data.seq}</td>
                <td>{data.userId}</td>
                <td>{data.artistName}</td>
                <td>{data.userEmail}</td>
                <td>{data.taxStatus === 'Y' ? <font color='green'>Approved</font> : data.taxStatus === 'N' ? <font color='red'>Rejected</font> : 'Waiting'}</td>
                <td><a href={data.pdfLink} target='_blank' rel='noopener noreferrer'>조회</a></td>
                <td>
                    <Button onClick={() => updateStatus(data.seq, 'Y')}>승인</Button>
                    <Button onClick={() => updateStatus(data.seq, 'N')}>보류</Button>
                </td>
                <td>{data.regDate}</td>
                <td>
                    <textarea value={data.comment ? data.comment : ''} onChange={(event) => setTaxForms(taxForms.map(item => item.seq === data.seq ? {...data, comment: event.target.value} : item))}/>
                    <button onClick={() => saveComment(data.seq, data.comment)}><i className="fa fa-save"></i></button>
                </td>
            </tr>
        ))
        return list;
    }

    const updateStatus = (tax_id, status) => {
        const params = {
              tax_id
            , status
       };

       fetch("/new/webAdmin/v2/tax/tax-forms/commit", {
           headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Access-Control-Allow-Origin': '*',
               'token': sessionStorage.getItem("token")
           }
           , method: 'put'
           , body: JSON.stringify(params)
       })
       .then(() => props.getContents())
       .catch((error) => { console.error("123=" + error) });
    }
    
    const saveComment = (tax_id, comment) => {
        const params = {
            comment
        };

        fetch("/new/webAdmin/v2/tax/tax-forms/"+tax_id+"/comment", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: 'put'
            , body: JSON.stringify(params)
        })
        .then(() => alert('Comment 수정 완료'))
        .catch((error) => { console.error("123=" + error) });
    }
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>No.</th>
                    <th>User ID</th>
                    <th>작가명</th>
                    <th>작가 이메일</th>
                    <th>Status</th>
                    <th>PDF link</th>
                    <th>승인/보류</th>
                    <th>수정일</th>
                    <th>Comment</th>
                </tr>
            </thead>
            <tbody>
                {renderContents()}
            </tbody>
        </Table>
    )
}

export default React.memo(TaxFormTable);