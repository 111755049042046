import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import queryString from "query-string";

// import LeftMenu from "../../Components/LeftMenu";
import Loading from "../../Components/Common/Loading/Loading.js";
import BannerTypeDropdown from "../../Components/MobileBanner/BannerTypeDropdown.js";
import BannerContainerTable from "../../Components/MobileBanner/BannerContainerTable.js";
import BannerListBottomBar from "../../Components/MobileBanner/BannerListBottomBar.js";

const MobileBannerListPage = (props) => {
  const query = queryString.parse(props.location.search);
  const pageName = "Banner/Mobile";

  const [isLoading, setIsLoading] = useState(true);
  const [bannerContainers, setBannerContainers] = useState([]);
  const [pageMap, setPageMap] = useState({});
  const [params, setParams] = useState({
    pageNumber: query.pageNumber ? query.pageNumber : 1,
    type: query.type ? query.type : "A",
    status: query.status ? query.status : "",
  });

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getBannerContainers();
    } else {
      props.history.push("/");
    }
  }, [params]);

  const getBannerContainers = () => {
    setIsLoading(true);
    const queryParameter =
      "?pageNumber=" +
      params.pageNumber +
      "&type=" +
      params.type +
      "&status=" +
      params.status;
    fetch("/new/webAdmin/v2/container" + queryParameter, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setBannerContainers(responseJson.result.containerList);
        setPageMap(responseJson.result.pageMap);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  const handleCheckBoxEvent = (event) => {
    if (event.target.checked) {
      setParams({ ...params, status: "Y" });
    } else {
      setParams({ ...params, status: "" });
    }
  };

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <div
        // className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header" style={{ marginBottom: "15px" }}>
          <h1>Mobile App 배너 관리</h1>
          <ol className="breadcrumb">
            <li>
              <i className="fa fa-dashboard"></i> 배너 관리
            </li>
            <li className="active">Mobile App 배너 관리</li>
          </ol>
        </section>
        <section>
          {isLoading ? (
            <Loading />
          ) : (
            <Container fluid>
              <Row>
                <Col md="auto">
                  <BannerTypeDropdown
                    type={params.type}
                    setType={(type) =>
                      setParams({ ...params, pageNumber: 1, type: type })
                    }
                  />
                </Col>
                <Col md="auto">
                  <input
                    type="checkbox"
                    onChange={handleCheckBoxEvent}
                    checked={params.status === "Y"}
                  />{" "}
                  Active
                </Col>
              </Row>
              <Row>
                <Col>
                  <BannerContainerTable
                    bannerContainers={bannerContainers}
                    getBannerContainers={getBannerContainers}
                  />
                </Col>
              </Row>
              <Row className="pull-right">
                <BannerListBottomBar
                  pageNumber={params.pageNumber}
                  type={params.type}
                  status={params.status}
                  pageMap={pageMap}
                  pageName={pageName}
                />
              </Row>
            </Container>
          )}
        </section>
      </div>
    </React.Fragment>
  );
};

export default MobileBannerListPage;
