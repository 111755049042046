import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { getContainerTypeName } from './MobileBannerUtils';

const BannerContainerTable = (props) => {
    const [ checkedContainerIds, setCheckedContainerIds ] = useState([]);

    const handleCheckBoxEvent = (event) => {
        if (event.target.checked) {
            setCheckedContainerIds(checkedContainerIds.concat(event.target.value));
        } else {
            setCheckedContainerIds(checkedContainerIds.filter(id => id !== event.target.value));
        }
    }

    useEffect(() => {
        console.log(props.bannerContainers)
    }, [])

    const renderBannerContainers = () => {
        return props.bannerContainers && props.bannerContainers.map(data => (
            <tr key={data.c_id}>
                <td>{data.c_id}</td>
                <td><input type="checkbox" onChange={handleCheckBoxEvent} value={data.c_id}/></td>
                <td>{getContainerTypeName(data.cType)}</td>
                <td>{data.title}</td>
                <td>{data.status}</td>
                <td>{data.regDate}</td>
                <td>
                    <Button onClick={() => { props.history.push({ pathname : '/Banner/Mobile/' + data.c_id, state : { type : data.cType, tag : data.tag, title: data.title } }); }} >
                        Edit
                    </Button>
                </td>
                <td><Button onClick={() => moveOrderUp(data.c_id, data.cType, data.tOrder)} >▲</Button></td>
                <td><Button onClick={() => moveOrderDown(data.c_id, data.cType, data.tOrder)}>▼</Button></td>
            </tr>
        ))
    }

    const insertNewBannerContainer = () => {
        props.history.push({
            pathname : '/Banner/Mobile/Insert'
        });
    }

    const deleteBannerContainers = () => {
        if(checkedContainerIds.length === 0){
            alert('삭제할 아이템을 선택하세요');
            return; 
        }
        const params = {
            idList: checkedContainerIds
        }
        fetch("/new/webAdmin/v2/container", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            ,method: 'DELETE'
            ,body: JSON.stringify(params)
        })
        .then(() => {
            setCheckedContainerIds([]);
            props.getBannerContainers();
        })
        .catch((error) => { console.error("123=" + error) });
    }

    const updateStatus = (status) => {
        const params = {
            idList: checkedContainerIds,
            status: status
        }
        fetch("/new/webAdmin/v2/container/status", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            ,method: 'put'
            ,body: JSON.stringify(params)
        })
        .then(() => { 
            setCheckedContainerIds([]);
            props.getBannerContainers() 
        })
        .catch((error) => { console.error("123=" + error) });

        /*if(status === 'Y'){
            fetch("/new/webAdmin/v2/container/active/mail", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': sessionStorage.getItem("token")
                }
                ,method: 'POST'
                ,body: JSON.stringify(params)
            })
            .catch((error) => { console.error("123=" + error) });
        }*/
    }

    const moveOrderUp = (c_id, type, order) => {
        const params = {
              c_id: c_id
            , type: type
            , order: order
        };

        fetch("/new/webAdmin/v2/container/order/up", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            ,method: 'put'
            ,body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
            if (responseJson.status === 'success') {
                props.getBannerContainers();
            } else {
                alert("실패");
            }
        })
        .catch((error) => { console.error("123=" + error) });
    }

    const moveOrderDown = (c_id, type, order) => {
        const params = {
             c_id: c_id
           , type: type
           , order: order
        };

        fetch("/new/webAdmin/v2/container/order/down", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            ,method: 'put'
            ,body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
            if (responseJson.status === 'success') {
                props.getBannerContainers();
            } else {
                alert("실패");
            }
        })
        .catch((error) => { console.error("123=" + error) });
    }

    return (
        <React.Fragment>
            <div style={{backgroundColor: "#fff", borderTop: "solid 5px skyblue", marginTop: "10px"}}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th width="10%">No.</th>
                            <th width="5%"></th>
                            <th width="15%">Container Name</th>
                            <th width="25%">Title</th>
                            <th width="7%">Status</th>
                            <th width="17%">Release Date</th>
                            <th width="7%">Edit</th>
                            <th width="7%">Up</th>
                            <th width="7%">Down</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderBannerContainers()}
                    </tbody>
                    
                </Table>
            </div>
            <Row>
                <Col md="auto"><Button onClick={insertNewBannerContainer}>New Banner</Button></Col>
                <Col md="auto"><Button onClick={deleteBannerContainers}>Delete</Button></Col>
                <Col md="auto"><Button onClick={() => updateStatus('Y')}>Activate</Button></Col>
                <Col md="auto"><Button onClick={() => updateStatus('N')}>Deactivate</Button></Col>
            </Row>
        </React.Fragment>
    )
}

export default withRouter(BannerContainerTable);