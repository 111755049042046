import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Paging from '../Common/Page/Paging';
import PostList from './PostList';
import PostListHeader from './PostListHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 880px; */
  min-height: 350px;
  height: fit-content;
`
const PostListView = ({category, currentPage, lastPage, postList, setRefresh}) => {
  const [active, setActive] = useState(0);

  useEffect(()=>{
    setActive(category)
  },[category])
  
  return (
    <Wrapper>
      <PostListHeader active={active}/>
      <PostList postList={postList} pageNumber={currentPage} isAll={category===0&&currentPage>1} setRefresh={setRefresh} />
      {lastPage!==0 &&(
        <Paging 
          currentPage={currentPage} 
          lastPage={lastPage} 
          style={{
            margin: '30px 20%',
          }}
        />
      )}
    </Wrapper>
  )
}

export default PostListView;