import React, { useState, useEffect } from 'react'
import PromptEdit from '../../Components/AI/PromptEdit'
import axios from 'axios'

const PromptPage = () => {
  const [type, setType] = useState(1)
  const [typeList, setTypeList] = useState([])
  const [promptList, setPromptList] = useState([])

  const getPrompt = () => {
    axios
      .get(`/new/webAdmin/v2/prompt`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then(({ data }) => {
        setTypeList(data.result)
        if (data.result.filter(item => item.SEQ === Number(type)).length > 0) {
          setPromptList(data.result.filter(item => item.SEQ === Number(type))[0].detail.sort((a, b) => a.ORDER - b.ORDER))
        } else {
          console.log(data.result[0].SEQ)
          setPromptList(data.result[0].detail.sort((a, b) => a.ORDER - b.ORDER))
        }
      })
  }

  useEffect(() => {
    getPrompt()
  }, [type])

  return (
    <>
      <PromptEdit
        type={type}
        setType={setType}
        typeList={typeList}
        promptList={promptList}
        setPromptList={setPromptList}
        getPrompt={getPrompt}
      />
    </>
  )
}

export default PromptPage
