import React from "react";
import ArtistQnaDetail from "../../Components/ArtistQna/ArtistQnaDetail";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const ArtistQnaDetailPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <ArtistQnaDetail
        id={query.id}
        pageNumber={query.pageNumber}
        search={query.search ? query.search : ""}
      />
    </React.Fragment>
  );
};

export default ArtistQnaDetailPage;
