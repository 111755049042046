import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import DefaultButton from "../../Common/ButtunUI/DefaultButton";
import { IoArrowBack } from "react-icons/io5";
import { AiOutlinePaperClip } from "react-icons/ai";
import Linkify from "react-linkify";
import AttachPreview from "../../Common/BoxUI/AttachPreviewBox";
import { useHistory } from "react-router";

const Wrapper = styled.div`
  font-size: 14px;
  padding-bottom: 10px;
`;

const NoticeCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e5eefe;
  margin: 0px 10px;
`;

const NoticeCard = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.fixStatus === 1 ? "#e5eefe" : "#fff")};
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
`;

const NoticeIndex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  min-width: 100px;
  width: 100px;
  height: 60px;
`;

const NoticeIndexText = styled.div``;

const NoticeFixTag = styled.div`
  background-color: ${(props) =>
    props.fixStatus === 1 ? "#dd4b39" : "#00A65A"};
  margin-top: 10px;
  padding: 3px 8px;
  border-radius: 8px;
  font-size: 12px;
  color: #ffffff;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10px;
  margin-right: 10px;
`;

const TitleEnText = styled.div`
  margin-bottom: 10px;
`;

const TitleKoText = styled.div``;

const CreatedDate = styled.div`
  min-width: 100px;
  width: 100px;
`;

const ShowMoreBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 150px;
  width: 150px;
  margin-right: 20px;
`;

const ShowMoreText = styled.div``;

const ShowMoreIconClosed = styled(IoIosArrowBack)`
  width: 16px;
  height: 16px;
  margin-left: 10px;
`;

const ShowMoreIconOpened = styled(IoIosArrowDown)`
  width: 16px;
  height: 16px;
  margin-left: 10px;
`;

const NoticeDetail = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #e5eefe;
`;

const NoticeDetailEn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 750px;
  max-width: 750px;
  width: 750px;
  padding: 15px;
  border-left: 1px solid #e5eefe;
`;

const NoticeDetailKo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 750px;
  max-width: 750px;
  width: 750px;
  padding: 15px;
  border-left: 1px solid #e5eefe;
  border-right: 1px solid #e5eefe;
`;

const NoticeDetailControl = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-top: 1px solid #e8e9ed;
`;

const HeaderTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid #e8e9ed;
`;

const HeaderTitleText = styled.div`
  width: 716px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  overflow-wrap: break-word;
`;

const HeaderBottomDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 30px;
`;

const HeaderTimeText = styled.div`
  font-family: RawlineMedium, sans-serif;
  font-size: 12px;
  color: #8f8f8f;
`;

const DetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const TextArea = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const NoticeText = styled.div`
  color: #0c0b24;
  line-height: 1.86;
`;

const MentionBottom = styled.div`
  display: flex;
  position: relative;
`;

const NoticeAttachArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const NoticeAttachBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
`;

const NoticeAttachIcon = styled(AiOutlinePaperClip)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 2px;
  background-color: #f7f9fc;
  color: #7b7b7b;
`;

const MentionAttachText = styled.div`
  color: #b3b3b3;
  margin-left: 4px;
`;

const ControlDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  letter-spacing: 2.4px;
  font-size: 12px;
`;

const BackListBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BackArrowIcon = styled(IoArrowBack)`
  color: #9fa3a7;
  margin-bottom: 1px;
`;

const BackListText = styled.div`
  color: #9fa3a7;
  margin-left: 8px;
`;

const MessageNoticeList = () => {
  const [noticeList, setNoticeList] = useState([]);
  const [reload, setReload] = useState(false);
  const [openedIndex, setOpenedIndex] = useState(-1);
  const [noticeData, setNoticeData] = useState(null);
  const [isAttachHover, setIsAttachHover] = useState(-1);

  const history = useHistory();

  useEffect(() => {
    getAllNoticeList();
  }, []);

  useEffect(() => {
    if (reload) getAllNoticeList();
  }, [reload]);

  useEffect(() => {
    if (openedIndex !== -1) {
      document.getElementsByClassName("wrapper")[0].scrollTo({
        top:
          document.getElementById(`notice_card_${openedIndex}`).offsetTop - 70,
        behavior: "smooth",
      });
    }
  }, [openedIndex]);

  const getAllNoticeList = async () => {
    try {
      await axios
        .get(`/new/webAdmin/v2/message-center/notice`, {
          headers: { token: sessionStorage.getItem("token") },
        })
        .then((res) => {
          setNoticeList(res.data.body);
          setReload(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getOneNotice = async (noticeId) => {
    try {
      await axios
        .get(`/new/webAdmin/v2/message-center/notice/${noticeId}`, {
          headers: { token: sessionStorage.getItem("token") },
        })
        .then((res) => {
          setNoticeData(res.data.body[0]);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteOneNotice = async (noticeId) => {
    try {
      await axios
        .delete(`/new/webAdmin/v2/message-center/notice/${noticeId}`, {
          headers: { token: sessionStorage.getItem("token") },
        })
        .then((res) => {
          setReload(true);
          setOpenedIndex(-1);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Wrapper>
      {noticeList &&
        noticeList.length !== 0 &&
        noticeList.map((nt, index) => {
          return (
            <NoticeCardWrapper id={`notice_card_${index}`} key={index}>
              <NoticeCard
                onClick={async () => {
                  if (openedIndex === index) {
                    setOpenedIndex(-1);
                  } else {
                    await getOneNotice(nt.noticeId);
                    setOpenedIndex(index);
                  }
                }}
                fixStatus={nt.fixStatus}
              >
                <NoticeIndex>
                  <NoticeIndexText>#{index + 1}</NoticeIndexText>
                  <NoticeFixTag fixStatus={nt.fixStatus}>
                    {nt.fixStatus === 1 ? "FIXED" : "DEFAULT"}
                  </NoticeFixTag>
                </NoticeIndex>
                <TitleBox>
                  <TitleEnText>{nt.noticeTitleEn}</TitleEnText>
                  <TitleKoText>{nt.noticeTitleKo}</TitleKoText>
                </TitleBox>
                <CreatedDate>{nt.subDate}</CreatedDate>
                <ShowMoreBox>
                  <ShowMoreText>Show Detail</ShowMoreText>
                  {openedIndex === index ? (
                    <ShowMoreIconOpened />
                  ) : (
                    <ShowMoreIconClosed />
                  )}
                </ShowMoreBox>
              </NoticeCard>
              {openedIndex === index && noticeData && (
                <Fragment>
                  <NoticeDetailControl>
                    <DefaultButton
                      theme={"default"}
                      btnText={"Edit"}
                      style={{ width: 80 }}
                      onClick={() => {
                        history.push({
                          pathname: "/Message/Notice/Edit",
                          state: { noticeData },
                        });
                      }}
                    />
                    <DefaultButton
                      theme={"red"}
                      style={{ width: 80 }}
                      btnText={"Delete"}
                      onClick={() => {
                        if (
                          window.confirm("Are you sure to delete this notice?")
                        ) {
                          deleteOneNotice(nt.noticeId);
                        }
                      }}
                    />
                  </NoticeDetailControl>
                  <NoticeDetail>
                    <NoticeDetailEn>
                      <HeaderTitleBox>
                        <HeaderTitleText>
                          {noticeData["noticeTitleEn"]}
                        </HeaderTitleText>
                        <HeaderBottomDiv>
                          <HeaderTimeText>
                            {noticeData["subDate"]}
                          </HeaderTimeText>
                        </HeaderBottomDiv>
                      </HeaderTitleBox>
                      <DetailDiv>
                        {noticeData.noticeContentsEn !== undefined &&
                          noticeData.noticeContentsEn !== null &&
                          noticeData.noticeContentsPlace === 0 && (
                            <TextArea>
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    target={decoratedText}
                                    href={decoratedHref}
                                    key={key}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                <NoticeText>
                                  {noticeData.noticeContentsEn
                                    .split("\n")
                                    .map((line, index) => {
                                      let resultLine = "";
                                      const splitLine1 = line.split("![");
                                      if (splitLine1.length > 1) {
                                        const splitLink1 =
                                          splitLine1[1].split("]");
                                        const splitLinkName = splitLink1[0];
                                        const splitLink2 = splitLink1[1]
                                          .split("(")[1]
                                          .split(")");
                                        const splitLinkPath = splitLink2[0];
                                        const splitLine2 = splitLink2[1];
                                        resultLine = (
                                          <span key={index}>
                                            {splitLine1[0]}
                                            <a
                                              style={{
                                                color: "#fe3a93",
                                                textDecoration: "underline",
                                              }}
                                              href={splitLinkPath}
                                              target={"_blank"}
                                            >
                                              {splitLinkName}
                                            </a>
                                            {splitLine2}
                                            <br />
                                          </span>
                                        );
                                      } else {
                                        resultLine = (
                                          <span key={index}>
                                            {splitLine1[0]}
                                            <br />
                                          </span>
                                        );
                                      }
                                      return resultLine;
                                    })}
                                </NoticeText>
                              </Linkify>
                            </TextArea>
                          )}
                        {noticeData.noticeImageEn !== undefined &&
                          noticeData.noticeImageEn !== null && (
                            <img
                              style={{ marginTop: 15, marginBottom: 15 }}
                              src={encodeURI(noticeData.noticeImageEn)}
                              alt={""}
                            />
                          )}
                        {noticeData.noticeContentsEn !== undefined &&
                          noticeData.noticeContentsEn !== null &&
                          noticeData.noticeContentsPlace === 1 && (
                            <TextArea>
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    target={decoratedText}
                                    href={decoratedHref}
                                    key={key}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                <NoticeText>
                                  {noticeData.noticeContentsEn
                                    .split("\n")
                                    .map((line, index) => {
                                      let resultLine = "";
                                      const splitLine1 = line.split("![");
                                      if (splitLine1.length > 1) {
                                        const splitLink1 =
                                          splitLine1[1].split("]");
                                        const splitLinkName = splitLink1[0];
                                        const splitLink2 = splitLink1[1]
                                          .split("(")[1]
                                          .split(")");
                                        const splitLinkPath = splitLink2[0];
                                        const splitLine2 = splitLink2[1];
                                        resultLine = (
                                          <span key={index}>
                                            {splitLine1[0]}
                                            <a
                                              style={{
                                                color: "#fe3a93",
                                                textDecoration: "underline",
                                              }}
                                              href={splitLinkPath}
                                              target={"_blank"}
                                            >
                                              {splitLinkName}
                                            </a>
                                            {splitLine2}
                                            <br />
                                          </span>
                                        );
                                      } else {
                                        resultLine = (
                                          <span key={index}>
                                            {splitLine1[0]}
                                            <br />
                                          </span>
                                        );
                                      }
                                      return resultLine;
                                    })}
                                </NoticeText>
                              </Linkify>
                            </TextArea>
                          )}
                        <MentionBottom>
                          <NoticeAttachArea>
                            {noticeData.attachPathEn1 !== undefined &&
                              noticeData.attachPathEn1 !== null && (
                                <NoticeAttachBox
                                  onClick={() => {
                                    window.open(
                                      encodeURI(noticeData.attachPathEn1),
                                      "_blank"
                                    );
                                  }}
                                >
                                  <NoticeAttachIcon
                                    onMouseEnter={() => {
                                      setIsAttachHover(0);
                                    }}
                                    onMouseLeave={() => {
                                      setIsAttachHover(-1);
                                    }}
                                  />
                                  <MentionAttachText>
                                    {noticeData.attachNameEn1}
                                  </MentionAttachText>
                                  {isAttachHover === 0 && (
                                    <AttachPreview
                                      url={noticeData.attachPathEn1}
                                    />
                                  )}
                                </NoticeAttachBox>
                              )}
                            {noticeData.attachPathEn2 !== undefined &&
                              noticeData.attachPathEn2 !== null && (
                                <NoticeAttachBox
                                  onClick={() => {
                                    window.open(
                                      encodeURI(noticeData.attachPathEn2),
                                      "_blank"
                                    );
                                  }}
                                >
                                  <NoticeAttachIcon
                                    onMouseEnter={() => {
                                      setIsAttachHover(1);
                                    }}
                                    onMouseLeave={() => {
                                      setIsAttachHover(-1);
                                    }}
                                  />
                                  <MentionAttachText>
                                    {noticeData.attachNameEn2}
                                  </MentionAttachText>
                                  {isAttachHover === 1 && (
                                    <AttachPreview
                                      url={noticeData.attachPathEn2}
                                    />
                                  )}
                                </NoticeAttachBox>
                              )}
                            {noticeData.attachPathEn3 !== undefined &&
                              noticeData.attachPathEn3 !== null && (
                                <NoticeAttachBox
                                  onClick={() => {
                                    window.open(
                                      encodeURI(noticeData.attachPathEn3),
                                      "_blank"
                                    );
                                  }}
                                >
                                  <NoticeAttachIcon
                                    onMouseEnter={() => {
                                      setIsAttachHover(2);
                                    }}
                                    onMouseLeave={() => {
                                      setIsAttachHover(-1);
                                    }}
                                  />
                                  <MentionAttachText>
                                    {noticeData.attachNameEn3}
                                  </MentionAttachText>
                                  {isAttachHover === 2 && (
                                    <AttachPreview
                                      url={noticeData.attachPathEn3}
                                    />
                                  )}
                                </NoticeAttachBox>
                              )}
                          </NoticeAttachArea>
                        </MentionBottom>
                      </DetailDiv>
                      <ControlDiv>
                        <BackListBox onClick={() => {}}>
                          <BackArrowIcon size={16} />
                          <BackListText>LIST</BackListText>
                        </BackListBox>
                      </ControlDiv>
                    </NoticeDetailEn>
                    <NoticeDetailKo>
                      <HeaderTitleBox>
                        <HeaderTitleText>
                          {noticeData.noticeTitleKo}
                        </HeaderTitleText>
                        <HeaderBottomDiv>
                          <HeaderTimeText>{noticeData.subDate}</HeaderTimeText>
                        </HeaderBottomDiv>
                      </HeaderTitleBox>
                      <DetailDiv>
                        {noticeData.noticeContentsKo !== undefined &&
                          noticeData.noticeContentsKo !== null &&
                          noticeData.noticeContentsPlace === 0 && (
                            <TextArea>
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    target={decoratedText}
                                    href={decoratedHref}
                                    key={key}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                <NoticeText>
                                  {noticeData.noticeContentsKo
                                    .split("\n")
                                    .map((line, index) => {
                                      let resultLine = "";
                                      const splitLine1 = line.split("![");
                                      if (splitLine1.length > 1) {
                                        const splitLink1 =
                                          splitLine1[1].split("]");
                                        const splitLinkName = splitLink1[0];
                                        const splitLink2 = splitLink1[1]
                                          .split("(")[1]
                                          .split(")");
                                        const splitLinkPath = splitLink2[0];
                                        const splitLine2 = splitLink2[1];
                                        resultLine = (
                                          <span key={index}>
                                            {splitLine1[0]}
                                            <a
                                              style={{
                                                color: "#fe3a93",
                                                textDecoration: "underline",
                                              }}
                                              href={splitLinkPath}
                                              target={"_blank"}
                                            >
                                              {splitLinkName}
                                            </a>
                                            {splitLine2}
                                            <br />
                                          </span>
                                        );
                                      } else {
                                        resultLine = (
                                          <span key={index}>
                                            {splitLine1[0]}
                                            <br />
                                          </span>
                                        );
                                      }
                                      return resultLine;
                                    })}
                                </NoticeText>
                              </Linkify>
                            </TextArea>
                          )}
                        {noticeData.noticeImageKo !== undefined &&
                          noticeData.noticeImageKo !== null && (
                            <img
                              style={{ marginTop: 15, marginBottom: 15 }}
                              src={encodeURI(noticeData.noticeImageKo)}
                              alt={""}
                            />
                          )}
                        {noticeData.noticeContentsKo !== undefined &&
                          noticeData.noticeContentsKo !== null &&
                          noticeData.noticeContentsPlace === 1 && (
                            <TextArea>
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    target={decoratedText}
                                    href={decoratedHref}
                                    key={key}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                <NoticeText>
                                  {noticeData.noticeContentsKo
                                    .split("\n")
                                    .map((line, index) => {
                                      let resultLine = "";
                                      const splitLine1 = line.split("![");
                                      if (splitLine1.length > 1) {
                                        const splitLink1 =
                                          splitLine1[1].split("]");
                                        const splitLinkName = splitLink1[0];
                                        const splitLink2 = splitLink1[1]
                                          .split("(")[1]
                                          .split(")");
                                        const splitLinkPath = splitLink2[0];
                                        const splitLine2 = splitLink2[1];
                                        resultLine = (
                                          <span key={index}>
                                            {splitLine1[0]}
                                            <a
                                              style={{
                                                color: "#fe3a93",
                                                textDecoration: "underline",
                                              }}
                                              href={splitLinkPath}
                                              target={"_blank"}
                                            >
                                              {splitLinkName}
                                            </a>
                                            {splitLine2}
                                            <br />
                                          </span>
                                        );
                                      } else {
                                        resultLine = (
                                          <span key={index}>
                                            {splitLine1[0]}
                                            <br />
                                          </span>
                                        );
                                      }
                                      return resultLine;
                                    })}
                                </NoticeText>
                              </Linkify>
                            </TextArea>
                          )}
                        <MentionBottom>
                          <NoticeAttachArea>
                            {noticeData.attachPathKo1 !== undefined &&
                              noticeData.attachPathKo1 !== null && (
                                <NoticeAttachBox
                                  onClick={() => {
                                    window.open(
                                      encodeURI(noticeData.attachPathKo1),
                                      "_blank"
                                    );
                                  }}
                                >
                                  <NoticeAttachIcon
                                    onMouseEnter={() => {
                                      setIsAttachHover(3);
                                    }}
                                    onMouseLeave={() => {
                                      setIsAttachHover(-1);
                                    }}
                                  />
                                  <MentionAttachText>
                                    {noticeData.attachNameKo1}
                                  </MentionAttachText>
                                  {isAttachHover === 3 && (
                                    <AttachPreview
                                      url={noticeData.attachPathKo1}
                                    />
                                  )}
                                </NoticeAttachBox>
                              )}
                            {noticeData.attachPathKo2 !== undefined &&
                              noticeData.attachPathKo2 !== null && (
                                <NoticeAttachBox
                                  onClick={() => {
                                    window.open(
                                      encodeURI(noticeData.attachPathKo2),
                                      "_blank"
                                    );
                                  }}
                                >
                                  <NoticeAttachIcon
                                    onMouseEnter={() => {
                                      setIsAttachHover(4);
                                    }}
                                    onMouseLeave={() => {
                                      setIsAttachHover(-1);
                                    }}
                                  />
                                  <MentionAttachText>
                                    {noticeData.attachNameKo2}
                                  </MentionAttachText>
                                  {isAttachHover === 4 && (
                                    <AttachPreview
                                      url={noticeData.attachPathKo2}
                                    />
                                  )}
                                </NoticeAttachBox>
                              )}
                            {noticeData.attachPathKo3 !== undefined &&
                              noticeData.attachPathKo3 !== null && (
                                <NoticeAttachBox
                                  onClick={() => {
                                    window.open(
                                      encodeURI(noticeData.attachPathKo3),
                                      "_blank"
                                    );
                                  }}
                                >
                                  <NoticeAttachIcon
                                    onMouseEnter={() => {
                                      setIsAttachHover(5);
                                    }}
                                    onMouseLeave={() => {
                                      setIsAttachHover(-1);
                                    }}
                                  />
                                  <MentionAttachText>
                                    {noticeData.attachNameKo3}
                                  </MentionAttachText>
                                  {isAttachHover === 5 && (
                                    <AttachPreview
                                      url={noticeData.attachPathKo3}
                                    />
                                  )}
                                </NoticeAttachBox>
                              )}
                          </NoticeAttachArea>
                        </MentionBottom>
                      </DetailDiv>
                      <ControlDiv>
                        <BackListBox onClick={() => {}}>
                          <BackArrowIcon size={16} />
                          <BackListText>목록으로 돌아가기</BackListText>
                        </BackListBox>
                      </ControlDiv>
                    </NoticeDetailKo>
                  </NoticeDetail>
                </Fragment>
              )}
            </NoticeCardWrapper>
          );
        })}
    </Wrapper>
  );
};

export default MessageNoticeList;
