import React from "react";
import DashBoard from "../../Components/Home/DashBoard";
// import LeftMenu from "../../Components/LeftMenu";

/*
Home : 새로 개발 중인 Home DashBoard
DashBoard : 기존에 사용 중인 DashBoard
*/
const DashBoardPage = ({ match, location }) => {
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <DashBoard />
    </React.Fragment>
  );
};

export default DashBoardPage;
