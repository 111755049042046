import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import Pagination from 'react-bootstrap/Pagination'

const AIUploadView = props => {
  const { pageNumber, limit, d, l, cnt } = props
  const [imageList, setImageList] = useState([])
  const [pageMap, setPageMap] = useState({})
  const [confidence, setConfidence] = useState({
    d: d ? d : '',
    l: l ? l : '',
    cnt: cnt ? cnt : '',
  })
  const [params, setParams] = useState({
    pageNumber: pageNumber ? pageNumber : 1,
    limit: limit ? limit : 20,
    d: d ? d : '',
    l: l ? l : '',
    cnt: cnt ? cnt : '',
  })
  const [rate, setRate] = useState({
    detected: 0,
    total: 0,
  })
  const [isLoading, setIsLoading] = useState(false)

  const pageName = 'AI/Upload'

  const getImage = () => {
    setIsLoading(true)

    const query =
      `?pageNumber=${params.pageNumber}` +
      `&limit=${params.limit}` +
      `&d=${params.d}` +
      `&l=${params.l}` +
      `&cnt=${params.cnt}`

    axios
      .get(`/new/webAdmin/v2/ai/init/image` + query, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        setImageList(data.result.imageList)
        setPageMap(data.result.pageMap)
        setRate({
          detected: data.result.detectedCnt,
          total: data.result.totalCnt,
        })
        setIsLoading(false)

        props.history.push(`/${pageName}` + query)
      })
  }

  useEffect(() => {
    getImage()
  }, [params])

  const renderPaging = (pageNumber, pageMap) => {
    let list = []
    let j = 0
    let i = 0

    let test = pageNumber !== 1 ? pageNumber - 1 : 1
    let last =
      pageNumber !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount

    list.push(
      <Pagination.Item
        key="first"
        onClick={() => {
          setParams({ ...params, pageNumber: test })
        }}
      >
        &laquo;
      </Pagination.Item>
    )

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
      let num = pageMap.startPage * 1 + j

      if (num === pageMap.pageNumber * 1) {
        list.push(
          <Pagination.Item key={i} className="active">
            {num}
          </Pagination.Item>
        )
      } else {
        list.push(
          <Pagination.Item
            key={i}
            onClick={() => {
              setParams({ ...params, pageNumber: num })
            }}
          >
            {num}
          </Pagination.Item>
        )
      }
      j++
    }

    list.push(
      <Pagination.Item
        key="last"
        onClick={() => {
          setParams({ ...params, pageNumber: last })
        }}
      >
        &raquo;
      </Pagination.Item>
    )
    return <Pagination>{list}</Pagination>
  }

  return !isLoading && (
    <Wrapper>
      <section className="content-header">
        <h1>AI Upload Image</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/ContentsList">
              <i className="fa fa-dashboard"></i>AI
            </a>
          </li>
          <li className="active">Upload</li>
        </ol>
      </section>
      <section
        className="content"
        style={{ minHeight: '20px', paddingBottom: 0 }}
      >
        <div className="row">
          <section
            className="col-lg-12 connectedSortable"
            style={{ minHeight: '20px' }}
          >
            <div className="box box-info">
              <div className="box-body">
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>Confidence : </div>
                    <div
                      style={{
                        marginLeft: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span>detection (0 ~ 1)</span>
                      <input
                        type="text"
                        value={confidence.d}
                        onChange={e =>
                          setConfidence({ ...confidence, d: e.target.value })
                        }
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span>landmarking (0 ~ 1)</span>
                      <input
                        type="text"
                        value={confidence.l}
                        onChange={e =>
                          setConfidence({ ...confidence, l: e.target.value })
                        }
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span>face</span>
                      <input
                        type="text"
                        value={confidence.cnt}
                        onChange={e =>
                          setConfidence({ ...confidence, cnt: e.target.value })
                        }
                      />
                    </div>
                    <button
                      className="btn btn-default"
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        setParams({
                          ...params,
                          pageNumber: 1,
                          d: confidence.d,
                          l: confidence.l,
                          cnt: confidence.cnt,
                        })
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    Face Detection :{' '}
                    {`${rate.detected} / ${rate.total} (${(
                      (rate.detected / rate.total) *
                      100
                    ).toFixed(2)}%)`}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section className="content">
        <div className="row">
          <section className="col-lg-12 connectedSortable">
            <div className="box box-info">
              <div className="box-header with-border">
                <ImageWrapper>
                  {imageList.map((item, i) => (
                    <ImageBox key={i}>
                      <img src={item.img} />
                    </ImageBox>
                  ))}
                </ImageWrapper>
              </div>
            </div>
            <div className="box-footer clearfix">
              <ul className="pagination pagination-sm no-margin pull-right">
                {renderPaging(pageMap.pageNumber, pageMap)}
              </ul>
            </div>
          </section>
        </div>
      </section>
    </Wrapper>
  )
}

export default withRouter(AIUploadView)

const Wrapper = styled.div`
  min-height: 100%;
`

const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
`

const ImageBox = styled.div`
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: 100%;
  }
`
