import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import LeftMenu from "../../Components/LeftMenu";
import Loading from "../../Components/Common/Loading/Loading";
import { withRouter } from "react-router-dom";
import TaggersTable from "../../Components/StickerTag/TaggersTable";

const StickerTagHomePage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [taggers, setTaggers] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      loadData();
    } else {
      props.history.push("/");
    }
  }, []);

  const loadData = async () => {
    await getTaggers();
    // await getTags();
    setIsLoading(false);
  };

  const getTaggers = async () => {
    return fetch("/new/webAdmin/v2/taggers", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
        setTaggers(responseJson.taggers);
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  // const getTags = async () => {
  //     return fetch('/new/webAdmin/v2/tags/frequent', {
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'Access-Control-Allow-Origin': '*',
  //             'token': sessionStorage.getItem("token")
  //         }
  //         , method: "GET"
  //     })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //         setTags(responseJson.tags);
  //     })
  //     .catch((error) => {console.error("123=" + error)})
  // }

  const getRandomImage = () => {
    switch (Date.now() % 26) {
      case 0:
        return (
          <img
            src="https://img.stipop.io/2020/8/19/1597886381490_11.gif"
            alt="https://img.stipop.io/2020/8/19/1597886381490_11.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 1:
        return (
          <img
            src="https://img.stipop.io/1543369323896_cheerboy_03.gif"
            alt="https://img.stipop.io/1543369323896_cheerboy_03.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 2:
        return (
          <img
            src="https://img.stipop.io/1543369323945_cheerboy_02.gif"
            alt="https://img.stipop.io/1543369323945_cheerboy_02.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 3:
        return (
          <img
            src="https://img.stipop.io/1543369323937_cheerboy_01.gif"
            alt="https://img.stipop.io/1543369323937_cheerboy_01.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 4:
        return (
          <img
            src="https://img.stipop.io/2020/9/4/1599502411043_beabi13.gif"
            alt="https://img.stipop.io/2020/9/4/1599502411043_beabi13.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 5:
        return (
          <img
            src="https://img.stipop.io/2020/5/22/1590144346993_aa.gif"
            alt="https://img.stipop.io/2020/5/22/1590144346993_aa.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 6:
        return (
          <img
            src="https://img.stipop.io/2020/6/5/1591461092052_20200321_233035.gif"
            alt="https://img.stipop.io/2020/6/5/1591461092052_20200321_233035.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 7:
        return (
          <img
            src="https://img.stipop.io/2020/6/5/1591461092531_20200321_182900.gif"
            alt="https://img.stipop.io/2020/6/5/1591461092531_20200321_182900.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 8:
        return (
          <img
            src="https://img.stipop.io/2020/11/24/1606397523696_20201031_221817.gif"
            alt="https://img.stipop.io/2020/11/24/1606397523696_20201031_221817.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 9:
        return (
          <img
            src="https://img.stipop.io/2020/11/24/1606397523992_20201114_222747.gif"
            alt="https://img.stipop.io/2020/11/24/1606397523992_20201114_222747.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 10:
        return (
          <img
            src="https://img.stipop.io/2020/11/18/1606109774894_DFDAA430-E079-4EDC-9DF3-C0661428D6C2.gif"
            alt="https://img.stipop.io/2020/11/18/1606109774894_DFDAA430-E079-4EDC-9DF3-C0661428D6C2.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 11:
        return (
          <img
            src="https://img.stipop.io/2020/11/18/1606109775808_3C88D4EF-9610-4F90-B6F3-7E2777B3902A.gif"
            alt="https://img.stipop.io/2020/11/18/1606109775808_3C88D4EF-9610-4F90-B6F3-7E2777B3902A.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 12:
        return (
          <img
            src="https://img.stipop.io/2020/11/12/1605154807738_%ED%95%98%ED%8A%B8.gif"
            alt="https://img.stipop.io/2020/11/12/1605154807738_%ED%95%98%ED%8A%B8.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 13:
        return (
          <img
            src="https://img.stipop.io/2020/11/23/1606128342575_23.gif"
            alt="https://img.stipop.io/2020/11/23/1606128342575_23.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 14:
        return (
          <img
            src="https://img.stipop.io/2020/10/26/1603801542537_9.gif"
            alt="https://img.stipop.io/2020/10/26/1603801542537_9.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 15:
        return (
          <img
            src="https://img.stipop.io/2020/10/26/1603801542376_3.gif"
            alt="https://img.stipop.io/2020/10/26/1603801542376_3.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 16:
        return (
          <img
            src="https://img.stipop.io/2020/10/26/1603801542472_7.gif"
            alt="https://img.stipop.io/2020/10/26/1603801542472_7.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 17:
        return (
          <img
            src="https://img.stipop.io/2020/10/26/1603801542436_6.gif"
            alt="https://img.stipop.io/2020/10/26/1603801542436_6.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 18:
        return (
          <img
            src="https://img.stipop.io/2020/10/8/1602919521497_2.gif"
            alt="https://img.stipop.io/2020/10/8/1602919521497_2.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 19:
        return (
          <img
            src="https://img.stipop.io/2020/10/8/1602919521372_1.gif"
            alt="https://img.stipop.io/2020/10/8/1602919521372_1.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 20:
        return (
          <img
            src="https://img.stipop.io/2020/10/8/1602197569852_6.gif"
            alt="https://img.stipop.io/2020/10/8/1602197569852_6.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 21:
        return (
          <img
            src="https://img.stipop.io/2020/10/8/1602197570003_11.gif"
            alt="https://img.stipop.io/2020/10/8/1602197570003_11.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 22:
        return (
          <img
            src="https://img.stipop.io/2020/10/8/1602197569772_3.gif"
            alt="https://img.stipop.io/2020/10/8/1602197569772_3.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 23:
        return (
          <img
            src="https://img.stipop.io/2020/9/15/1600478828145_4.gif"
            alt="https://img.stipop.io/2020/9/15/1600478828145_4.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 24:
        return (
          <img
            src="https://img.stipop.io/2020/9/15/1600478828510_22.gif"
            alt="https://img.stipop.io/2020/9/15/1600478828510_22.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      case 25:
        return (
          <img
            src="https://img.stipop.io/2020/9/15/1600478962480_6-r.gif"
            alt="https://img.stipop.io/2020/9/15/1600478962480_6-r.gif"
            style={{ width: "300px", height: "300px" }}
          />
        );
      default:
        return;
    }
  };
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <div
        //  className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header" style={{ marginBottom: "15px" }}>
          <h1>태그 현황</h1>
          <ol className="breadcrumb">
            <li>
              <a href="/Taggers/Home">
                <i className="fa fa-dashboard"></i> 태그
              </a>
            </li>
            <li className="active">태그 현황</li>
          </ol>
        </section>
        <section>
          {isLoading ? (
            <Loading />
          ) : (
            <Container fluid>
              <Row>
                <Col md={5}>
                  <TaggersTable taggers={taggers} />
                </Col>
                <Col md={{ offset: 2, span: 5 }}>
                  <strong>Let's Go Stipop!</strong>
                  <br />
                  {getRandomImage()}
                </Col>
              </Row>
            </Container>
          )}
        </section>
      </div>
    </React.Fragment>
  );
};

export default withRouter(StickerTagHomePage);
