import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import styled, { css } from 'styled-components'
import ReactJson from 'react-json-view'

const SDPromptView = props => {
  const { getPrompt, promptList, style, setStyle } = props
  const [checked, setChecked] = useState([])
  const [edit, setEdit] = useState(0)
  const checkRef = useRef([])
  const [params, setParams] = useState({
    status: '',
    style: '',
    params: {},
  })
  const [inputJson, setInputJson] = useState('')
  const [pType, setPType] = useState(true) //true: json, false: string

  const init = async () => {
    await getPrompt()
    await setChecked([])
    await setPType(true)
    await setInputJson('')
    await setParams({ status: '', style: '', params: {} })
    await setEdit(0)

    if (checkRef.current) {
      for (let i = 0; i < checkRef.current.length; i++) {
        if (checkRef.current[i] && checkRef.current[i].checked) {
          checkRef.current[i].checked = false
        }
      }
    }
  }

  useEffect(() => {
    if (edit > 0) {
      setParams(promptList.filter(prompt => prompt.seq === edit)[0])
    } else if (edit < 0) {
      setParams({ status: 'Y', style: '', params: {} })
    } else {
      init()
    }
  }, [edit])

  const handleCheck = e => {
    if (e.target.checked) {
      setChecked(checked.concat(e.target.value))
    } else {
      setChecked(checked.filter(id => id !== e.target.value))
    }
  }

  const clickSave = async str => {
    if (edit > 0) {
      // edit
      await axios.put(
        `/new/webAdmin/v2/ai/sd/prompt/${edit}`,
        {
          ...params,
          params: JSON.stringify(str ? str : params.params),
        },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
    } else if (edit < 0) {
      // new
      await axios.post(
        `/new/webAdmin/v2/ai/sd/prompt`,
        {
          ...params,
          params: JSON.stringify(str ? str : params.params),
        },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
    }

    await init()
  }

  const clickDelete = async () => {
    if (checked.length > 0) {
      await axios
        .delete(`/new/webAdmin/v2/ai/sd/prompt`, {
          data: {
            deleteList: checked,
          },
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(() => {
          init()
        })

      await setChecked([])
    }
  }

  const clickCopy = async id => {
    const params = promptList.filter(prompt => prompt.seq === id)[0]

    await axios
      .post(
        `/new/webAdmin/v2/ai/sd/prompt`,
        {
          ...params,
          status: 'N',
          style: `${params.style} copy`,
          params: JSON.stringify(params.params),
        },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        init()
      })
  }

  const updateStatus = status => {
    axios
      .put(
        `/new/webAdmin/v2/ai/sd/status`,
        { updateList: checked, status },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        init()
      })
  }

  const validJSON = str => {
    try {
      const josn = JSON.parse(str)
      return typeof josn === 'object'
    } catch (err) {
      return false
    }
  }

  const reorderPromise = (id, order) => {
    const params = {
      id,
      order,
    }

    return new Promise(async (resolve, reject) => {
      axios
        .put(`/new/webAdmin/v2/ai/sd/order`, params, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const reorder = async list => {
    const result = await Promise.all(
      list.map((item, index) =>
        reorderPromise(item.seq, style ? item.order : index + 1)
      )
    )

    if (result) {
      init()
    }
  }

  const moveOrder = async (idx, move) => {
    let updateData = [...promptList]
    const target = updateData.splice(move === 'up' ? idx - 1 : idx + 1, 1)[0]
    if (style) {
      updateData =
        move === 'up'
          ? [
              { ...promptList[idx], order: target.order },
              { ...target, order: promptList[idx].order },
            ]
          : [
              { ...target, order: promptList[idx].order },
              { ...promptList[idx], order: target.order },
            ]
    } else {
      updateData.splice(idx, 0, target)
    }

    await reorder(updateData)
  }

  const renderEdit = () => {
    return (
      <Table className="table no-margin">
        <thead>
          <tr>
            <th>Style</th>
            <th style={{ minWidth: '800px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                }}
              >
                <div style={{ marginRight: '20px' }}>Parameters</div>
                <div
                  onClick={async () => {
                    await setInputJson(JSON.stringify(params.params, null, 4))
                    await setPType(false)
                  }}
                  style={{
                    fontSize: '14px',
                    color: pType ? '#0000004d' : '#268bd2',
                    cursor: 'pointer',
                    marginRight: '10px',
                  }}
                >
                  String
                </div>
                <div
                  onClick={async () => {
                    if (validJSON(inputJson)) {
                      await setParams({
                        ...params,
                        params: JSON.parse(inputJson),
                      })
                      await setPType(true)
                    } else {
                      alert('This text is not in valid json format.')
                    }
                  }}
                  style={{
                    fontSize: '14px',
                    color: !pType ? '#0000004d' : '#268bd2',
                    cursor: 'pointer',
                  }}
                >
                  JSON
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                value={params.style}
                onChange={e => {
                  setParams({
                    ...params,
                    style: e.target.value,
                  })
                }}
              />
            </td>
            <td style={{ minWdith: '800px' }}>
              {pType ? (
                <ReactJson
                  src={params.params}
                  theme={'summerfruit:inverted'}
                  collapsed={false}
                  onAdd={e => {
                    setParams({ ...params, params: e.updated_src })
                  }}
                  onEdit={e => {
                    setParams({ ...params, params: e.updated_src })
                  }}
                  onDelete={e => {
                    setParams({ ...params, params: e.updated_src })
                  }}
                  enableClipboard={edit && false}
                  name={null}
                />
              ) : (
                <>
                  <textarea
                    value={inputJson}
                    onChange={e => {
                      setInputJson(e.target.value)
                    }}
                    style={{ minWidth: '800px', minHeight: '800px' }}
                  />
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#dc3545',
                    }}
                  >
                    {!validJSON(inputJson) &&
                      'This text is not in valid json format.'}
                  </div>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    <div style={{ minHeight: '100%' }}>
      <section className="content-header">
        <h1>AI SD Prompt 관리</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/ContentsList">
              <i className="fa fa-dashboard"></i>AI
            </a>
          </li>
          <li className="active">SD Prompt</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <section className="col-lg-12 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  width: '100%',
                  paddingBottom: '30px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    gap: '0 10px',
                    alignItems: 'center',
                  }}
                >
                  {!edit && (
                    <>
                      <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        Style
                      </div>
                      <input
                        type="text"
                        value={style}
                        onChange={e => {
                          setStyle(e.target.value)
                        }}
                      />
                    </>
                  )}
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                    gap: '0 10px',
                  }}
                >
                  {!edit && (
                    <>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          updateStatus('Y')
                        }}
                      >
                        Activate
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          updateStatus('N')
                        }}
                      >
                        DeActivate
                      </button>
                    </>
                  )}
                  <button
                    className="btn btn-default"
                    onClick={async () => {
                      if (pType) {
                        if (edit) {
                          clickSave()
                        } else {
                          setEdit(-1)
                        }
                      } else {
                        if (!validJSON(inputJson)) {
                          alert('This text is not in valid json format.')
                        } else {
                          clickSave(JSON.parse(inputJson))
                        }
                      }
                    }}
                  >
                    {edit ? '저장' : '추가'}
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      if (edit) {
                        init()
                      } else {
                        clickDelete()
                      }
                    }}
                  >
                    {edit ? '취소' : '삭제'}
                  </button>
                </div>
              </div>
              {promptList.length > 0 && (
                <div className="box-body">
                  <div className="table-responsive">
                    {edit ? (
                      renderEdit()
                    ) : (
                      <Table className="table no-margin">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th></th>
                            <th>Status</th>
                            <th>Style</th>
                            <th>Parameters</th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {promptList &&
                            promptList.map((item, index) => (
                              <tr key={index}>
                                <td>{item.order}</td>
                                <td>
                                  <input
                                    ref={el => (checkRef.current[index] = el)}
                                    type="checkbox"
                                    onChange={handleCheck}
                                    value={item.seq}
                                  />
                                </td>
                                <td>{item.status}</td>
                                <td>{item.style}</td>
                                <td style={{ minWidth: '500px' }}>
                                  <ReactJson
                                    src={item.params}
                                    collapsed={true}
                                    name={null}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    quotesOnKeys={false}
                                    displayArrayKey={false}
                                  />
                                </td>

                                <td>
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      setEdit(item.seq)
                                    }}
                                  >
                                    Edit
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      clickCopy(item.seq)
                                    }}
                                  >
                                    Copy
                                  </button>
                                </td>
                                <td>
                                  {index !== 0 && (
                                    <button
                                      className="btn btn-default"
                                      onClick={() => {
                                        moveOrder(index, 'up')
                                      }}
                                    >
                                      ▲
                                    </button>
                                  )}
                                  {index !== promptList.length - 1 && (
                                    <button
                                      className="btn btn-default"
                                      onClick={() => {
                                        moveOrder(index, 'down')
                                      }}
                                    >
                                      ▼
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default SDPromptView

const Table = styled.table`
  td {
    /* max-width: 300px; */
    white-space: break-spaces;
  }
`

const ToggleBtn = styled.button`
  width: 50px;
  height: 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background-color: ${props => (!props.toggle ? 'none' : '#007bff')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
`
const Circle = styled.div`
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  left: 2px;
  transition: all 0.5s ease-in-out;
  ${props =>
    props.toggle &&
    css`
      transform: translate(28px, 0);
      transition: all 0.5s ease-in-out;
    `}
`
