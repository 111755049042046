const getGoalOfTheDay = () => {
    const now = new Date();
    const goalPerDay = 40;
    if(now.getMonth() === 11) {
        switch(now.getDate()) {
            case 3 : return 0;
            case 4 : return 1 * goalPerDay;
            
            case 7 : return 2 * goalPerDay;
            case 8 : return 3 * goalPerDay;
            case 9 : return 4 * goalPerDay;
            case 10 : return 5 * goalPerDay;
            case 11 : return 6 * goalPerDay;

            case 14 : return 7 * goalPerDay;
            case 15 : return 8 * goalPerDay;
            case 16 : return 9 * goalPerDay;
            case 17 : return 10 * goalPerDay;
            case 18 : return 11 * goalPerDay;
            
            case 21 : return 12 * goalPerDay;
            case 22 : return 13 * goalPerDay;
            case 23 : return 14 * goalPerDay;
            case 24 : return 15 * goalPerDay;
            case 25 : return 16 * goalPerDay;
            
            case 28 : return 17 * goalPerDay;
            case 29 : return 18 * goalPerDay;
            case 30 : return 19 * goalPerDay;
            case 31 : return 20 * goalPerDay;
            default : return 99999;
        }
    }
    return 99999;
}

export default getGoalOfTheDay;