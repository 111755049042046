import React from "react";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

class ArtistQnaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      q_id: this.props.id,
      contents: "",
      an: 1,
      artistQna: "",
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      fetch("/new/webAdmin/v2/ArtistQnaDetail/" + this.state.q_id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
          this.setState({
            artistQna: responseJson.artistQna,
            isLoading: false,
            view: responseJson.artistQna.status === 1 ? "none" : "",
          });
        })
        .catch((error) => {
          console.error("123=" + error);
        });
    } else {
      this.props.history.push("/");
    }
  }

  _save = () => {
    const params = {
      q_id: this.state.q_id,
      contents: this.state.contents,
      // ,contents: encodeURIComponent(this.state.contents)
    };

    fetch("/new/webAdmin/v2/ArtistQnaInsert", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        alert("완료");
        window.location.reload();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _qnaRender = () => {
    let list = [];
    if (this.state.artistQna) {
      const data = this.state.artistQna;
      list.push(
        <React.Fragment key={data.seq}>
          <div className="form-group">
            <label>질문</label>
            <input
              type="text"
              className="form-control"
              value={data.title}
              disabled
            />
          </div>
          <div className="form-group">
            {/* <label>문의내용</label> */}
            <textarea
              className="form-control"
              rows={20}
              value={data.contents}
              disabled
            ></textarea>
          </div>
          {this._attachRender(data.attach)}
        </React.Fragment>
      );
      if (data.answer) {
        list.push(
          <React.Fragment key={data.seq}>
            <div className="form-group">
              <label>답변</label>
              <input
                type="text"
                className="form-control"
                value={"Admin"}
                disabled
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                rows={20}
                value={data.answer}
                disabled
              ></textarea>
            </div>
          </React.Fragment>
        );
      }

      /*
                    list.push(
                        <React.Fragment key={data.seq}>
                            <div className="form-group">
                                <label>답변</label>
                                <input type="text" className="form-control" value={"Admin"} disabled/>
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" rows="5" value={data.contents} disabled></textarea>
                            </div>
                        </React.Fragment>
                    );*/

      return list;
    } else {
      return <div></div>;
    }
  };

  _attachRender = (attach) => {
    let list = [];
    for (const tmp of attach) {
      list.push(
        <div className="form-group" key={tmp.seq}>
          <a key={tmp.path} href={tmp.path}>
            첨부파일
          </a>
        </div>
      );
    }

    return list;
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
        //  className="content-wrapper"
        >
          <section className="content-header">
            <h1>스티커 QNA 답변</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/StickerQnaList">
                  <i className="fa fa-dashboard"></i> 스티커 QNA 답변
                </a>
              </li>
              <li className="active">스티커 QNA 관리</li>
            </ol>
          </section>
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div className="box-header with-border">
                    <label>작가 : {this.state.artistQna.name}</label>
                  </div>
                  <div className="box-body">
                    {this._qnaRender()}

                    <div style={{ display: this.state.view }}>
                      <div className="form-group">
                        <label>답변</label>
                        <textarea
                          className="form-control"
                          rows="20"
                          placeholder="답변입력...."
                          onChange={(event) =>
                            this.setState({ contents: event.target.value })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div className="box-footer">
                      <button
                        type="submit"
                        onClick={() =>
                          this.props.history.push(
                            "/ArtistQnaList?pageNumber=" +
                              this.props.pageNumber +
                              "&search=" +
                              this.props.search
                          )
                        }
                        className="btn btn-secondary"
                      >
                        목록으로
                      </button>
                      <button
                        type="submit"
                        style={{ display: this.state.view }}
                        onClick={this._save}
                        className="btn btn-primary"
                      >
                        답변작성
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- /.box-header --> */}
              </section>
            </div>
          </section>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(ArtistQnaDetail);
