import React from "react";
import RegCount from "../../Components/Tracking/RegCount.js";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const RegCountPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <RegCount date={query.date} />
    </React.Fragment>
  );
};

export default RegCountPage;
