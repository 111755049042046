import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import getGoalOfTheDay from './GoalOfTheDay.js';

const TaggersTable = (props) => {
    const renderTaggers = () => {
        const goalOfTheDay = getGoalOfTheDay();
        return props.taggers.map((item, idx) => (
            <tr key={item.SEQ}>
                <td>{item.NAME}</td>
                <td><strong><font color={item.TOTAL_COUNT < goalOfTheDay ? "red" : "green"}>{item.TOTAL_COUNT}</font></strong></td>
                <td>{goalOfTheDay}</td>
                <td><Link to={"/Taggers/"+item.SEQ+"/InsertTag"}>Start Tag</Link></td>
            </tr>
        ))
    }

    return (
        <React.Fragment>
            <Table striped bordered hover>
                <thead style={{backgroundColor: "#ccc", color: "blue", border: "2px solid black", borderCollapse: "collapse", borderBottom: "double black"}}>
                    <tr>
                        <th>Name</th>
                        <th>Total Stickers</th>
                        <th>Goal Of The Day</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{border: "2px solid", borderCollapse: "collapse"}}>
                    {renderTaggers()}
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default TaggersTable;