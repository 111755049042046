import React from 'react'
import styled from 'styled-components'
import { SpaceResponsive } from '../../Pages/NewApp/NewAppBannerPage'

const ArtistItem = props => {
  const { item } = props

  return (
    <ArtistItemWrapper>
      <Title>{item.userName}</Title>
      <SubTitle>
        {item.followCnt} Followers • {item.packageCnt} sticker pack
      </SubTitle>
      <SpaceResponsive height={10} />
      <ImgWrapper>
        {item.stickers.length > 0 &&
          item.stickers
            .slice(0, 4)
            .map((img, i) => (
              <Img src={img.stickerImgWebp_618 || img.stickerImg_618} key={i} />
            ))}
      </ImgWrapper>
    </ArtistItemWrapper>
  )
}

const ArtistItemWrapper = styled.div`
  width: 100%;
  height: 132px;
  padding: 0 20px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  height: 18px;
`

const SubTitle = styled.div`
  font-size: 12px;
  height: 18px;
`

const ImgWrapper = styled.div`
  width: 100%;
  height: 74px;
  display: flex;
  gap: 13px;
`

const Img = styled.img`
  width: 74px;
  border-radius: 20px;
`

export default ArtistItem
