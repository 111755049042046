import React from 'react'
import {Line} from 'react-chartjs-2';

function chartData(labels, datasets) {
    return {
        labels: labels,
        datasets: [
            {
                label: 'view',
                yAxisID: "view",
                data: datasets[0],

                fill: false,
                backgroundColor: '#00cd00',
                borderColor: '#00cd00',
                borderCapStyle: 'round', // 선 끝 부분('butt', 'round', 'square')
                borderDash: [], // 점선([실선 부분, 공백 부분])
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter', // ('round', 'bevel', 'miter')
                
                pointBackgroundColor: '#8db600',
                pointBorderColor: '#000000',
                pointBorderWidth: 1,
                pointHoverRadius: 7,
                pointHoverBackgroundColor: '#008000',
                pointHoverBorderColor: '#000000',
                pointHoverBorderWidth: 3,
                pointRadius: 3,
                pointHitRadius: 3,
                
                hoverBackgroundColor: '#008000',
                hoverBorderColor:'#008000',

                order : 1,
            },
            {
                label: 'down',
                yAxisID: "down",
                data: datasets[1],

                fill: false,
                backgroundColor: '#007fff',
                borderColor: '#007fff',
                borderCapStyle: 'round', // 선 끝 부분('butt', 'round', 'square')
                borderDash: [], // 점선([실선 부분, 공백 부분])
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter', // ('round', 'bevel', 'miter')
                
                pointBackgroundColor: '#89cff0',
                pointBorderColor: '#000000',
                pointBorderWidth: 1,
                pointHoverRadius: 7,
                pointHoverBackgroundColor: '#318ce7',
                pointHoverBorderColor: '#000000',
                pointHoverBorderWidth: 3,
                pointRadius: 3,
                pointHitRadius: 3,

                hoverBackgroundColor: '#21abcd',
                hoverBorderColor: '#21abcd',
                
                order : 2,
            },
        ]
    }
}
    
const options = {
    scales: {
        xAxes: [{
            gridLines: {
                display : true,
                color : '#a9a9a9',
            },
        }],
        yAxes: [
            {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'view',
                labels:{
                    show: false,
                },
                gridLines: {
                    display : false,
                },
            },
            {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'down',
                labels:{
                    show: false,
                },
                gridLines: {
                    display : false,
                },
            },
        ],
        
    },
    title: {
        display: true,
        text: "Views and Downloads",
        fontSize : 20
    },
    hover: {
        mode : 'x', // 'nearest', 'average'
        intersect : false,
        axis: 'x',
        animationDuration : 100,
    },
    tooltips: {
        mode : 'index',
        intersect : false,
        backgroundColor: '#000000',
        titleFontSize : 20,
        bodyFontSize : 18,
    }
}

const legend = {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "black",
      fontSize: 15,
    }
  };
    
class LineGraph extends React.Component {
    render() {
        return (
            <Line data={chartData(this.props.labels, this.props.datasets)}
                    options={options}
                    legend={legend}
                    width={100} height={30}/>
        )   
    }
}

export default LineGraph;