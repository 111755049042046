import React, { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../Components/Common/Loading/Loading";
import BorderBox from "../../_Components/Common/BoxUI/BorderBox";
import PageTitle from "../../_Components/Common/PageTitle/PageTitle";
import MessageNoticeEditForm from "../../_Components/MessageCenter/MessageNotice/MessageNoticeEditForm";
import MessageNoticeEditPreview from "../../_Components/MessageCenter/MessageNotice/MessageNoticeEditPreview";

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 15px;
`;

const Inner = styled.div`
  display: flex;
  width: 100%;
`;

const InnerLeft = styled.div`
  display: flex;
  width: 100%;
`;

const InnerRight = styled.div`
  display: flex;
  min-width: 780px;
  max-width: 780px;
  width: 780px;
`;

const MessageNoticeEditPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [noticeData, setNoticeData] = useState(null);

  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      setIsLoading(false);
      try {
        setNoticeData(location.state.noticeData);
      } catch (error) {
        history.push("/Message/Notice");
      }
    } else {
      history.push("/");
    }
  }, []);

  const goTop = () => {
    document
      .getElementsByClassName("wrapper")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Wrapper>
      <PageTitle
        mainTitle={"Notice - Edit"}
        v1Link={""}
        navNames={["Management", "Message Center", "Notice", "Add"]}
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <Fragment>
          <Inner>
            <InnerLeft>
              <BorderBox
                boxTitle={"Notice Form"}
                component={
                  <MessageNoticeEditForm
                    noticeData={noticeData}
                    setNoticeData={(formData) => {
                      setNoticeData(formData);
                    }}
                    goTop={goTop}
                  />
                }
              />
            </InnerLeft>
            <InnerRight>
              <BorderBox
                boxTitle={"Notice Preview"}
                component={
                  <MessageNoticeEditPreview
                    noticeData={noticeData}
                    goTop={goTop}
                  />
                }
              />
            </InnerRight>
          </Inner>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default MessageNoticeEditPage;
