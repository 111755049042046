import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import Loading from '../Common/Loading/Loading'
import Header from '../Common/Header'
import List from '../Common/SearchListFixed'
import SearchText from '../Common/SearchTextNewFixed'
import { client } from '../../Typesense/client'
import { pageMapSetting } from '../../Common/Utils'
import { Pagination } from 'react-bootstrap'

const searchApiNewFixed = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [fieldList, setFieldList] = useState([])
  const [dataList, setDataList] = useState([])
  const [collection, setCollection] = useState('stickers')
  const [queryBy, setQueryBy] = useState('packageName')
  const [pageMap, setPageMap] = useState({})
  const [pageNumber, setPageNumber] = useState(
    props.pageNumber ? props.pageNumber : 1
  )
  const [q, setQ] = useState(props.search ? props.search : '')
  const [autoList, setAutoList] = useState([])
  const [sort, setSort] = useState('')
  const [qBy, setQby] = useState([])
  const [weight, setWeight] = useState([])
  const [filter, setFilter] = useState(props.lang ? props.lang : 'en')
  const defaults =
    '902,903,904,905,906,907,908,909,910,911,912,913,914,915,916,917,838,834,835,836,837,838,839,840,841,842,843,844,845,846,847,848,849,850,851,852,853,854,855,856,857,858,859,860,861,878,879,880,881,882,883,884,885,886,887,888,889,890,891,892,893,894,895,896,897,898,899,900,901'

  const search = async searchText => {
    setState(searchText)

    const searchParameters = {
      q: `${searchText}`,
      query_by: `stickerKeyword,artistName,packageCharacterName`,
      query_by_weights: `,`,
      filter_by: `packageLang:[${filter}]`,
      sort_by: `stickerSendMonthCnt:desc,packageDownMonthCnt:desc`,
      page: `${pageNumber}`,
      per_page: '100',
      highlight_start_tag: '<b>',
      highlight_end_tag: '</b>',
      hidden_hits: defaults,
    }

    const searchParametersAuto = {
      q: `${searchText}`,
      query_by: `stickerKeyword,artistName,packageCharacterName`,
      query_by_weights: `,`,
      filter_by: `packageLang:=[en]`,
      sort_by: `stickerSendMonthCnt:desc,packageDownMonthCnt:desc`,
      page: `${pageNumber}`,
      per_page: '100',
      highlight_start_tag: '<b>',
      highlight_end_tag: '</b>',
      hidden_hits: defaults,
    }

    console.log(searchParameters)

    const result = await client
      .collections(collection)
      .documents()
      .search(searchParameters)
    const auto = await client
      .collections(collection)
      .documents()
      .search(searchParametersAuto)

    if (result.hits) {
      if (fieldList.length === 0) {
        let fileds = []

        if (result.hits.length !== 0) {
          for (const tmp in result.hits[0].document) {
            fileds.push(tmp)
          }
          setFieldList(fileds)
        }
      }

      setPageMap(
        pageMapSetting(
          pageNumber,
          result.found,
          result.request_params.per_page,
          'searchApiNewFixed'
        )
      )
      setDataList(result.hits)
      setAutoList(auto.hits)
      setQ(searchText)
    } else {
      setDataList([])
    }
  }

  const [keyword, SearchInput, setState] = SearchText(
    q,
    search,
    collection,
    setCollection,
    queryBy,
    setQueryBy,
    setQ,
    autoList,
    sort,
    setSort,
    qBy,
    setQby,
    weight,
    setWeight,
    filter,
    setFilter,
    setPageNumber
  )

  const list = [
    'packageId',
    'stickerId',
    'stickerImg',
    'artistName',
    'packageCharacterName',
    'stickerKeyword',
    'stickerSendMonthCnt',
    'packageDownMonthCnt',
  ]

  const newFieldList = [...new Set([...list, ...fieldList])]

  const customComponent = useCallback(() => {
    return
  }, [dataList])

  useEffect(() => {
    // componentDidMount

    const fetchData = async () => {
      q && (await search(q))
      setIsLoading(false)
    }

    fetchData()

    return async () => {
      //componentWillUnmount
    }
  }, [])

  const paging = (pageNumber, pageMap, pageName, search) => {
    let list = []
    let j = 0
    let i = 0

    const first =
      '/' + pageName + '?search=' + search + '&lang=' + filter + '&pageNumber='
    let test = pageNumber !== 1 ? pageNumber - 1 : 1
    let last =
      pageNumber !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount

    list.push(
      <Pagination.Item key="first" href={first + test}>
        &laquo;
      </Pagination.Item>
    )

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
      let num = pageMap.startPage * 1 + j

      if (num === pageNumber * 1) {
        list.push(
          <Pagination.Item key={i} className="active">
            {num}
          </Pagination.Item>
        )
      } else {
        list.push(
          <Pagination.Item key={i} href={first + num}>
            {num}
          </Pagination.Item>
        )
      }
      j++
    }

    list.push(
      <Pagination.Item key="last" href={first + last}>
        &raquo;
      </Pagination.Item>
    )
    return <Pagination>{list}</Pagination>
  }

  if (!isLoading) {
    return (
      <>
        <Header title={'search Api (Chrome, App)'} />
        <Content>
          <Row>
            <Box>
              <BoxInfo>
                <BoxHeader width={'100%'}>
                  {SearchInput && SearchInput}
                </BoxHeader>
                <BoxBody>
                  <List fieldList={newFieldList} dataList={dataList} />
                </BoxBody>
              </BoxInfo>
              {pageMap &&
                paging(pageNumber, pageMap, pageMap.pageName, keyword)}
            </Box>
          </Row>
        </Content>
      </>
    )
  } else {
    return <Loading />
  }
}

export default withRouter(searchApiNewFixed)

const Content = styled.section`
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

const Box = styled.section`
  min-height: 100px;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: block;
`

const BoxInfo = styled.div`
  border-top-color: #00c0ef;
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  display: block;
`

const BoxHeader = styled.div`
  position: relative;
  display: table;
  border-collapse: separate;
  width: ${props => (props.width ? props.width : '100%')};
  margin: 10px;
`
// color: #444;
// display: block;
// padding: 10px;
// position: relative;
// float: left;
// width: 100%;

const BoxBody = styled.div`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
  min-height: 0.01%;
  overflow-x: auto;
`
