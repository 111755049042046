import React from 'react';

export default class Loading extends React.Component {
    render() {
        return (
            <div className="overlay" style={{position:"absolute", width:"100px", height:"100px", top:"50%", left:"50%", marginTop:"-50px", marginLeft:"-50px"}}>
                <i className="fa fa-refresh fa-spin" style={{fontSize:"30px", display: "flex", justifyContent: "center", alignItems: "center", width:"100%", height: "100%"}}></i>
            </div>
        )
    }
}