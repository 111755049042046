import React from "react";
// import LeftMenu from "../../Components/LeftMenu";
import StudioBannerUploadSection from "../../Components/StudioBanner/StudioBannerUploadSection";
import { withRouter } from "react-router-dom";
const StudioBannerInsertPage = (props) => {
  const redirect = () => {
    props.history.push({
      pathname: "/Banner/Studio",
    });
  };
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <div
        //  className="content-wrapper"
        style={{ minHeight: "100%" }}
      >
        <section className="content-header" style={{ marginBottom: "15px" }}>
          <h1>Studio 배너 추가</h1>
          <ol className="breadcrumb">
            <li>
              <a href="/Banner/Studio">
                <i className="fa fa-dashboard"></i> 배너 관리
              </a>
            </li>
            <li className="active">Studio 배너 추가</li>
          </ol>
        </section>
        <section>
          <StudioBannerUploadSection redirect={redirect} />
        </section>
      </div>
    </React.Fragment>
  );
};

export default withRouter(StudioBannerInsertPage);
