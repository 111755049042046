import React, { useState, useEffect, useRef, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Loading from '../Common/Loading/Loading'
import Header from '../Common/Header'
import BookmarkListTopBar from './BookmarkListTopBar'
import axios from 'axios'
import { useCallback } from 'react'

const BookmarkList = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [filter, setFilter] = useState('bookmark')
  const [data, setData] = useState([])
  const [click, setClick] = useState(null)
  const [tag, setTag] = useState('')
  const [keyword, setKeyword] = useState('')
  const [search, setSearch] = useState('')
  const [searchData, setSearchData] = useState([])
  const [pageMap, setPageMap] = useState({})

  const inputRef = useRef([])
  const contentRef = useRef([])

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getBookmarkList(1)
      setClick(null)
      setKeyword('')
    } else {
      props.history.push('/')
    }
  }, [filter])

  const scrollLock = useCallback(() => {
    if (contentRef.current) {
      contentRef.current.style.overflow = 'hidden'
    }
  })

  const scrollUnlock = useCallback(() => {
    if (contentRef.current) {
      contentRef.current.style.overflow = ''
    }
  })

  const handleScroll = async e => {
    if (
      e.target.scrollHeight - (e.target.offsetHeight + e.target.scrollTop) <
      1000
    ) {
      if (pageMap.pageNumber < pageMap.pageCount) {
        await scrollLock()
        await getBookmarkList(pageMap.pageNumber + 1)
        await scrollUnlock()
      }
    }
  }

  const getBookmarkList = async page => {
    // setIsLoading(true)
    const response = await fetch(
      `/new/webAdmin/v2/package/${filter}?pageNumber=${page}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem("token"),
        },
        method: 'get',
      }
    )

    const responseJson = await response.json()
    if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

    if (responseJson.status === 'success') {
      setIsLoading(false)
      if (page === 1) {
        if (contentRef.current) {
          contentRef.current.scrollTop = 0
        }
        await setData(responseJson.list)
      } else {
        await setData(data.concat(responseJson.list))
      }
      await setPageMap(responseJson.pageMap)
      await setClick(null)
    } else {
      await setData([])
    }
  }

  const savePtag = async (packageId, tag) => {
    const params = {
      packageId,
      tag,
    }

    axios
      .put(`/new/webAdmin/v2/bookmark/ptag`, params, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then(() => {
        setData(
          data.map(d =>
            d.p_id === packageId
              ? { ...d, p_tags: d.p_tags ? d.p_tags + `,${tag}` : tag }
              : d
          )
        )
        setTag('')
      })
  }

  const deletePtag = async (packageId, p_tags, tag) => {
    const tags = p_tags
      ? p_tags
          .split(',')
          .filter(item => item !== tag)
          .toString()
      : p_tags

    const params = {
      packageId,
      tag: tags,
    }

    axios
      .delete(`/new/webAdmin/v2/bookmark/ptag`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: params,
      })
      .then(() => {
        setData(
          data.map(d =>
            d.p_id === packageId
              ? {
                  ...d,
                  p_tags: tags,
                }
              : d
          )
        )
      })
  }

  const saveStag = async (pId, stickerId, keyword, index) => {
    // console.log(pId, stickerId, keyword)

    const params = {
      packageId: pId,
      stickerId,
      keyword,
    }

    axios
      .post(`/new/webAdmin/v2/bookmark/sticker/keyword`, params, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then(() => {
        setData(
          data.map(d =>
            d.p_id === pId
              ? {
                  ...d,
                  bookmarkStickerList: d.bookmarkStickerList.map(b =>
                    b.s_id === stickerId
                      ? {
                          ...b,
                          keyword: b.keyword
                            ? b.keyword + ',' + keyword
                            : keyword,
                        }
                      : b
                  ),
                }
              : d
          )
        )
        if (inputRef.current) {
          if (inputRef.current[index]) {
            inputRef.current[index].value = ''
          }
        }
        setKeyword('')
      })
  }

  const deleteStag = async (pId, stickerId, keyword) => {
    const params = {
      stickerId,
      keyword,
    }

    axios
      .delete(`/new/webAdmin/v2/bookmark/sticker/keyword`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: params,
      })
      .then(() => {
        setData(
          data.map(d =>
            d.p_id === pId
              ? {
                  ...d,
                  bookmarkStickerList: d.bookmarkStickerList.map(b =>
                    b.s_id === stickerId
                      ? {
                          ...b,
                          keyword: b.keyword
                            ? b.keyword
                                .split(',')
                                .filter(item => item !== keyword)
                                .toString()
                            : b.keyword,
                        }
                      : b
                  ),
                }
              : d
          )
        )
      })
  }

  const bookmarkUpdate = async (value, pid) => {
    let params

    if (value === 'bookmark') {
      params = {
        bookmark: 'N',
      }
    } else {
      params = {
        isUS: 'N',
      }
    }

    fetch(`/new/webAdmin/v2/package/${value}/${pid}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem("token"),
      },
      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(() => {
        axios
          .delete(`/new/webAdmin/v2/bookmark/package/keyword`, {
            headers: {
              token: sessionStorage.getItem("token"),
            },
            data: { packageId: pid },
          })
          .then(() => {
            getBookmarkList(1)
          })
      })
      .catch(error => {
        console.error('123=' + error)
      })
  }

  const renderTable = () => {
    return searchData.length > 0 ? (
      <Table>
        <tbody>
          <tr>
            <Field>s_id</Field>
            <Field>stickerImg</Field>
            <Field>keyword</Field>
          </tr>
          {searchData.map((d, i) => (
            <PackageWrapper key={i}>
              <Data>{d.s_id}</Data>
              <td>
                <img
                  src={d.stickerImg}
                  alt=""
                  style={{ width: 100, height: 100 }}
                />
              </td>
              <Data>{d.keyword}</Data>
            </PackageWrapper>
          ))}
        </tbody>
      </Table>
    ) : (
      <Table>
        <tbody>
          <tr>
            <Field>stickerImg</Field>
            <Field>p_id</Field>
            <Field>u_name</Field>
            <Field>p_name</Field>
            {/* <Field>p_tags</Field> */}
          </tr>
          {data.map((d, i) => (
            <Fragment key={i}>
              <PackageWrapper
                onClick={() => {
                  click === i ? setClick(null) : setClick(i)
                  setTag('')
                }}
              >
                <td>
                  <img
                    src={d.stickerImg}
                    alt=""
                    style={{ width: 100, height: 100 }}
                  />
                </td>
                <Data>{d.p_id}</Data>
                <Data>{d.u_name}</Data>
                <Data>{d.p_name}</Data>
                {/* <Data>{d.p_tags}</Data> */}
              </PackageWrapper>
              {i === click ? (
                <>
                  <tr style={{ height: '50px' }}>
                    <td>
                      <button
                        onClick={() => bookmarkUpdate(filter, data[click].p_id)}
                      >
                        북마크 삭제
                      </button>
                    </td>
                    {/* <td>
                      <input
                        type="text"
                        value={tag}
                        onChange={e => setTag(e.target.value)}
                        onKeyPress={async e => {
                          if (e.key === 'Enter') {
                            if (tag.length > 0) {
                              savePtag(data[click].p_id, tag)
                            }
                          }
                        }}
                      />
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (tag.length > 0) {
                            savePtag(data[click].p_id, tag)
                          }
                        }}
                      >
                        태그 추가
                      </button>
                    </td> */}
                    {/* <td>
                      {data[click].p_tags
                        ? data[click].p_tags.split(',').map((item, i) => (
                            <StickerKeyword
                              key={i}
                              onClick={() =>
                                deletePtag(
                                  data[click].p_id,
                                  data[click].p_tags,
                                  item
                                )
                              }
                            >
                              <span>{item}</span>
                            </StickerKeyword>
                          ))
                        : null}
                    </td> */}
                  </tr>
                  <tr>
                    <Field>stickerImg</Field>
                    <Field>s_id</Field>
                    <Field>keyword</Field>
                    <Field />
                  </tr>
                  {data[click].bookmarkStickerList.map((sticker, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={sticker.stickerImg}
                          alt=""
                          style={{ width: 100, height: 100 }}
                        />
                      </td>
                      <td>{sticker.s_id}</td>
                      <td>
                        <input
                          ref={el => (inputRef.current[index] = el)}
                          type="text"
                          onChange={e => setKeyword(e.target.value)}
                          onKeyPress={async e => {
                            if (e.key === 'Enter') {
                              if (keyword.length > 0) {
                                saveStag(
                                  data[click].p_id,
                                  sticker.s_id,
                                  keyword,
                                  index
                                )
                              }
                            }
                          }}
                        />
                        <button
                          onClick={() => {
                            if (keyword.length > 0) {
                              saveStag(
                                data[click].p_id,
                                sticker.s_id,
                                keyword,
                                index
                              )
                            }
                          }}
                        >
                          키워드 추가
                        </button>
                      </td>

                      <td
                        style={{
                          minHeight: 100,
                        }}
                      >
                        {sticker.keyword
                          ? sticker.keyword.split(',').map((item, i) => (
                              <StickerKeyword
                                key={i}
                                onClick={() =>
                                  deleteStag(
                                    data[click].p_id,
                                    sticker.s_id,
                                    item
                                  )
                                }
                              >
                                <span>{item}</span>
                              </StickerKeyword>
                            ))
                          : null}
                      </td>
                    </tr>
                  ))}
                </>
              ) : null}
            </Fragment>
          ))}
        </tbody>
      </Table>
    )
  }

  return (
    <>
      <Header title={'북마크 스티커 리스트'} />
      <BookmarkListTopBar
        setFilter={setFilter}
        search={search}
        setSearch={setSearch}
        setSearchData={setSearchData}
      />
      {!isLoading ? (
        <Content
          ref={contentRef}
          onScroll={async e => {
            await handleScroll(e)
          }}
        >
          <Row>
            <Box>
              <BoxInfo>
                <BoxHeader />
                <BoxBody>{renderTable()}</BoxBody>
              </BoxInfo>
            </Box>
          </Row>
        </Content>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default withRouter(BookmarkList)

const Content = styled.section`
  min-height: 250px;
  max-height: calc(100vh - 200px);
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
  overflow-y: auto;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

const Box = styled.section`
  min-height: 100px;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: block;
`

const BoxInfo = styled.div`
  border-top-color: #00c0ef;
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  display: block;
`

const BoxHeader = styled.div`
  position: relative;
  display: table;
  border-collapse: separate;
  width: 50%;
  margin: 10px;
`

const BoxBody = styled.div`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
  min-height: 0.01%;
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
`

const PackageWrapper = styled.tr`
  &:hover {
    cursor: pointer;
    background-color: #ededed;
  }
`

const Field = styled.th`
  border-top: 1px solid #f4f4f4;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  text-align: left;
`

const Data = styled.td`
  border-top: 1px solid #f4f4f4;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  display: table-cell;
`

const StickerKeyword = styled.button`
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #fff;
  border: 1px solid #ff4500;
  border-radius: 8px;
  text-align: center;
  position: relative;
  font-weight: bold;

  span {
    color: #ff3b94;
  }

  &:hover {
    background-color: #ff4298;
    color: #fff;
    span {
      opacity: 0;
    }
  }

  &:hover::before {
    content: 'X';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    outline: none;
  }
`
