import React from 'react';
import { Table } from 'react-bootstrap';

const FrequentTagsTable = (props) => {

    const renderTags = () => {
        const leftColumn = props.tags.filter((item, idx) => idx < props.tags.length/2);
        const rightColumn = props.tags.filter((item, idx) => idx >= props.tags.length/2);
        let result = [];
        for(let i=0; i<props.tags.length/2; i++){
            result = result.concat(
                <tr key={i}>
                    <td>{i+1}</td>
                    <td>{leftColumn[i].keyword}</td>
                    <td>{leftColumn[i].count}</td>
                    <td>{i+1+props.tags.length/2}</td>
                    <td>{rightColumn[i].keyword}</td>
                    <td>{rightColumn[i].count}</td>
                </tr>
            )
        }
        return result;
    }

    return (
        <React.Fragment>
            <Table striped bordered hover>
                <thead style={{backgroundColor: "#ccc", color: "blue", border: "2px solid black", borderCollapse: "collapse", borderBottom: "double black"}}>
                    <tr>
                        <th>#</th>
                        <th>Keyword</th>
                        <th>Count</th>
                        <th>#</th>
                        <th>Keyword</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody style={{border: "2px solid", borderCollapse: "collapse"}}>
                    {renderTags()}
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default FrequentTagsTable;