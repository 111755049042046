import React from "react";
import SearchGraphionica from "../../Components/B2B/searchGraphionica";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const SearchGraphionicaPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <SearchGraphionica date={query.date} />
    </React.Fragment>
  );
};

export default SearchGraphionicaPage;
