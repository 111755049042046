import React from "react";
import Download from "../../Components/Tracking/Download";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const DownloadPage = ({ match, location }) => {
  const query = queryString.parse(location.search);

  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <Download date={query.date} />
    </React.Fragment>
  );
};

export default DownloadPage;
