import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const Artists = props => {
  const { title, artistList } = props

  return (
    <>
      <Title>{title}</Title>
      <SpaceResponsive height={16} />
      <Wrapper>
        <ArtistWrapper>
          {artistList.map((sticker, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Artist key={index}>
                <img
                  src={
                    sticker.artistImg ||
                    sticker.artistProfileImg ||
                    'https://img.stipop.io/image/studio/stipop-dummy-avatar.png'
                  }
                  alt=""
                />
              </Artist>
              <ArtistName>{sticker.artistName || sticker.userName}</ArtistName>
            </div>
          ))}
        </ArtistWrapper>
      </Wrapper>
      <SpaceResponsive height={34} />
    </>
  )
}

export default Artists

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  position: relative;

  /* scrollbar-color: transparent transparent;
  scrollbar-width: 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  } */
`

const ArtistWrapper = styled.div`
  display: flex;
`

const Artist = styled.div`
  min-width: 62px;
  height: 63px;
  /* background-color: ${props => props.lightColor}; */
  border-radius: 36px;
  margin-right: 14px;
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`

const ArtistName = styled.div`
  max-width: 62px;
  height: 15px;
  font-size: 9px;
  font-family: Poppins;
  font-weight: bold;
  line-height: 15px;
  letter-spacing: 0.11px;
  text-align: center;
  margin-top: 3.5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
