import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import {
  STIPOP_API_HOST,
  STIPOP_API_KEY,
} from '../../_Components/Common/common'
import PageTitle from '../../_Components/Common/PageTitle/PageTitle'
import Dropdown from '../../Components/NewApp/Dropdown'
import VersionBanner from '../../Components/NewApp/VersionBanner'
import NewApp from '../../Components/NewApp/NewApp'
import EditTable from '../../Components/NewApp/EditTable'
import LoadingSpinner from '../../Components/Common/Loading/LoadingSpinner'
import Loading from '../../Components/Common/Loading/Loading'

const index = () => {
  const [mount, setMount] = useState(false)
  // current langauge
  const [lang, setLang] = useState('en')
  // current page
  const [page, setPage] = useState('home')
  // version
  const [allV, setAllV] = useState([])
  const [newV, setNewV] = useState([])
  const [liveV, setLiveV] = useState([])
  const [prevV, setPrevV] = useState([])
  // current selected version
  const [version, setVersion] = useState(null)
  // current lived version
  const [liveVersion, setLiveVersion] = useState(null)
  // stickers
  const [homeStickers, setHomeStickers] = useState([])
  const [newStickers, setNewStickers] = useState(null)
  const [searchStickers, setSearchStickers] = useState([])
  const [topStickers, setTopStickers] = useState([])
  // collection data
  const [collection, setCollection] = useState([])
  // collection package edit
  const [edit, setEdit] = useState('')
  // dropdown list
  const pageList = ['home', 'new', 'search']
  const langList = [
    { code: 'en', lang: 'English' },
    { code: 'ko', lang: 'Korean' },
    { code: 'es', lang: 'Spanish' },
    { code: 'pt', lang: 'Portuguese' },
  ]

  const [prevLoading, setPrevLoading] = useState(false)
  const [collectionLoading, setCollectionLoading] = useState(false)

  // popup
  const [popup, setPopup] = useState(null)
  const popupRef = useRef()

  // selected copy language
  const [copyLang, setCopyLang] = useState([lang])

  // platform
  const [platform, setPlatform] = useState('b2b')

  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (mount) {
      getVersion(lang)
    }
  }, [mount, page, lang])

  useEffect(() => {
    if (version) {
      getStickers(page, lang, version)
      getCollection(page, lang, version)
    }
  }, [version])

  // useEffect(() => {
  //   console.log(homeStickers, newStickers, searchStickers)
  // }, [homeStickers, newStickers, searchStickers])

  //change Page
  const changePage = async v => {
    if (page !== v) {
      await setPage(v)
    }
  }

  //change language
  const changeLang = async v => {
    if (lang !== v) {
      await setLang(v)
    }
  }

  useEffect(() => {
    // console.log(liveVersion)
  }, [liveVersion])

  // get version list
  const getVersion = async (lang, opt) => {
    await axios
      .get(
        `/new/webAdmin/v2/collection/version?lang=${lang}&platform=${platform}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      )
      .then(({ data }) => {
        const result = data.result
        if (result.length > 0) {
          setAllV(result)
          setNewV(result.filter(item => item.STATUS === 'W'))
          setLiveV(result.filter(item => item.STATUS === 'Y'))
          setPrevV(result.filter(item => item.STATUS === 'N'))

          if (opt !== 'copy') {
            if (result.filter(item => item.STATUS === 'Y').length > 0) {
              setVersion({
                id: result.filter(item => item.STATUS === 'Y')[0].SEQ,
                version: result.filter(item => item.STATUS === 'Y')[0].VERSION,
                status: 'Y',
              })
              setLiveVersion({
                id: result.filter(item => item.STATUS === 'Y')[0].SEQ,
                version: result.filter(item => item.STATUS === 'Y')[0].VERSION,
                status: 'Y',
              })
            } else {
              setVersion({
                id: result[0].SEQ,
                version: result[0].VERSION,
                status: result[0].STATUS,
              })
              setLiveVersion(null)
            }
          }
        } else {
          setAllV([])
          setNewV([])
          setLiveV([])
          setPrevV([])
          setVersion(null)
          setLiveVersion(null)
          setCollection([])
        }
      })
  }

  // get selected version collection stickers
  const getStickers = async (page, lang, version) => {
    // await setPrevLoading(true)
    if (version.status === 'Y') {
      if (page === 'home') {
        await axios
          .get(`${STIPOP_API_HOST}/v4/home?lang=${lang}&platform=${platform}`, {
            headers: {
              apikey: STIPOP_API_KEY,
            },
          })
          .then(({ data }) => {
            setHomeStickers(data.body.collectionList)
          })
      } else if (page === 'new') {
        await axios
          .get(`${STIPOP_API_HOST}/v4/new?lang=${lang}&platform=${platform}`, {
            headers: {
              apikey: STIPOP_API_KEY,
              token: '',
            },
          })
          .then(({ data }) => {
            setNewStickers({
              collectionList: data.body.collectionList.collectionList,
              newList: data.body.newList.newPackageList,
            })
          })
      } else {
        await axios
          .get(
            `${STIPOP_API_HOST}/v4/search/collection?lang=${lang}&platform=${platform}`,
            {
              headers: {
                apikey: STIPOP_API_KEY,
              },
            }
          )
          .then(({ data }) => {
            setSearchStickers(data.body.collectionList)
          })
      }
    } else {
      const params = `?page=${page}&type=&status=${version.status}&version=${version.id}&lang=${lang}&platform=${platform}`
      await axios
        .get(`/new/webAdmin/v2/collection` + params, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(({ data }) => {
          if (page === 'home') {
            setHomeStickers(data.result)
          } else if (page === 'new') {
            setNewStickers({
              collectionList: data.result.collectionList,
              newList: data.result.newList,
            })
          } else {
            setSearchStickers(data.result)
          }
        })
    }

    await axios
      .get(`${STIPOP_API_HOST}/v4/top/daily/${lang}`, {
        headers: {
          apikey: STIPOP_API_KEY,
          token: '',
        },
      })
      .then(({ data }) => {
        setTopStickers(data.body.topList)
      })
    // await setPrevLoading(false)
  }

  const getCollection = async (page, lang, version) => {
    //  await setCollectionLoading(true)
    if (version) {
      const params = `?page=${page}&type=&status=${version.status}&version=${version.id}&lang=${lang}`
      await axios
        .get(`/new/webAdmin/v2/collection` + params, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(({ data }) => {
          if (page === 'new') {
            setCollection(data.result.collectionList)
          } else {
            setCollection(data.result)
          }
          // setCollectionLoading(false)
        })
    }
  }

  const selectedCopyLang = lang => {
    if (copyLang.filter(l => l === lang).length > 0) {
      setCopyLang(copyLang.filter(l => l !== lang))
    } else {
      setCopyLang([...copyLang].concat(lang))
    }
  }

  const clickLive = async id => {
    if (liveVersion) {
      await axios
        .put(
          `/new/webAdmin/v2/collection/status`,
          { status: 'N', version: liveVersion.id },
          {
            headers: {
              token: sessionStorage.getItem("token"),
            },
          }
        )
        .then(async () => {
          const params = {
            status: 'Y',
            version: id,
          }

          await axios
            .put(`/new/webAdmin/v2/collection/status`, params, {
              headers: {
                token: sessionStorage.getItem("token"),
              },
            })
            .then(() => {
              getVersion(lang)
              setPopup(null)
            })
        })
    } else {
      const params = {
        status: 'Y',
        version: id,
      }

      await axios
        .put(`/new/webAdmin/v2/collection/status`, params, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then(() => {
          getVersion(lang)
          setPopup(null)
        })
    }
  }

  const clickDelete = id => {
    axios
      .delete(`/new/webAdmin/v2/collection/version/${id}`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then(() => {
        setEdit('')
        getVersion(lang)
        setPopup(null)
      })
  }

  const clickCopy = (id, version) => {
    const len = copyLang.length

    if (len === 1) {
      axios
        .post(
          `/new/webAdmin/v2/collection/version/${id}`,
          { lang: copyLang[0], platform: platform },
          {
            headers: {
              token: sessionStorage.getItem("token"),
            },
          }
        )
        .then(async ({ data }) => {
          if (copyLang[0] === lang) {
            await getVersion(lang, 'copy')
            await setVersion({
              id: data.result,
              version: `${version.version} copy`,
              status: 'W',
            })
          } else {
            await setLang(copyLang[0])
          }
          await setPopup(null)
        })
    } else {
      copyLang.map((l, i) => {
        axios
          .post(
            `/new/webAdmin/v2/collection/version/${id}`,
            { lang: l, platform: platform },
            {
              headers: {
                token: sessionStorage.getItem("token"),
              },
            }
          )
          .then(async ({ data }) => {
            if (l === lang) {
              await getVersion(lang, 'copy')
              await setVersion({
                id: data.result,
                version: `${version.version} copy`,
                status: 'W',
              })
            } else {
              if (i === len - 1) {
                await setLang(l)
              }
            }
            await setPopup(null)
          })
      })
    }
  }

  const renderPopup = (type, id) => {
    return (
      <PopupWrapper
        onClick={e => {
          if (popupRef.current && !popupRef.current.contains(e.target)) {
            setPopup(null)
            setCopyLang([lang])
          }
        }}
      >
        <PopupContent ref={popupRef}>
          {type === 'live' ? (
            <>
              <PopupHeader>라이브 출시</PopupHeader>
              <PopupBody>
                <div>라이브로 출시하시겠습니까?</div>
                <div>기존 라이브 버전은 이전 버전으로 이동됩니다.</div>
              </PopupBody>
              <PopupFooter>
                <button id="no" onClick={() => setPopup(null)}>
                  취소
                </button>
                <button id="yes" onClick={() => clickLive(id)}>
                  라이브 출시
                </button>
              </PopupFooter>
            </>
          ) : type === 'delete' ? (
            <>
              <PopupHeader>버전 삭제</PopupHeader>
              <PopupBody>
                <div>{version.version} 버전을 정말 삭제하시겠습니까?</div>
              </PopupBody>
              <PopupFooter>
                <button id="no" onClick={() => setPopup(null)}>
                  취소
                </button>
                <button id="yes" onClick={() => clickDelete(id)}>
                  삭제
                </button>
              </PopupFooter>
            </>
          ) : (
            type === 'copy' && (
              <>
                <PopupHeader>버전 복제</PopupHeader>
                <PopupBody>
                  <div>복제할 언어를 선택해 주세요.</div>
                  <div style={{ display: 'flex' }}>
                    {langList.map((item, i) => (
                      <LangBtn
                        key={i}
                        onClick={() => selectedCopyLang(item.code)}
                        selected={copyLang.indexOf(item.code) > -1}
                      >
                        {item.lang}
                      </LangBtn>
                    ))}
                  </div>
                  {copyLang.length > 0 && (
                    <>
                      <SpaceResponsive height={20} />
                      <div>{version.version} 버전을 복제하시겠습니까?</div>
                      <div>복제한 버전은 새로운 큐레이션에 등록됩니다.</div>
                    </>
                  )}
                </PopupBody>
                <PopupFooter deactive={copyLang.length === 0}>
                  <button
                    id="no"
                    onClick={() => {
                      setPopup(null)
                      setCopyLang([lang])
                    }}
                  >
                    취소
                  </button>
                  <button
                    id="yes"
                    onClick={() => {
                      if (copyLang.length > 0) {
                        clickCopy(id, version)
                      }
                    }}
                  >
                    복제
                  </button>
                </PopupFooter>
              </>
            )
          )}
        </PopupContent>
      </PopupWrapper>
    )
  }

  return (
    <>
      <PageTitle
        mainTitle={'New B2B'}
        v1Link={''}
        navNames={['Banners', 'New B2B']}
      />
      <Wrapper>
        {popup && renderPopup(popup.type, popup.id)}
        <BannerWrapper>
          <VersionBanner
            version={version}
            setVersion={setVersion}
            getVersion={getVersion}
            allV={allV}
            newV={newV}
            liveV={liveV}
            prevV={prevV}
            lang={lang}
            setPopup={setPopup}
            platform={platform}
          />
        </BannerWrapper>

        <EditWrapper>
          {version && (
            <Version>
              <div>버전 {version.version}</div>
              {!edit
                ? version.status !== 'Y' && (
                    <button
                      onClick={() => {
                        setPopup({ id: version.id, type: 'live' })
                      }}
                    >
                      라이브 출시
                    </button>
                  )
                : edit === 'new'
                ? `New Banner`
                : `Editing [${
                    collection
                      ? collection.filter(item => item.collectionId === edit)[0]
                          .collectionName
                      : ''
                  }] [Banner #${
                    Number(
                      collection.findIndex(item => item.collectionId === edit)
                    ) + 1
                  }]`}
            </Version>
          )}
          <div style={{ display: 'flex', height: 'calc(100% - 30px)' }}>
            <PreviewWrapper>
              <div
                style={{ height: '30px', margin: '15px 0', display: 'flex' }}
              >
                <Dropdown
                  list={pageList}
                  onChange={e => changePage(e.target.value)}
                  value={page}
                  type={'page'}
                />
                <div style={{ width: '10px' }} />
                <Dropdown
                  list={langList}
                  onChange={e => changeLang(e.target.value)}
                  value={lang}
                  type={'lang'}
                />
              </div>
              {version && (
                <NewApp
                  homeStickers={homeStickers}
                  newStickers={newStickers}
                  searchStickers={searchStickers}
                  topStickers={topStickers}
                  page={page}
                  isLoading={prevLoading}
                />
              )}
            </PreviewWrapper>
            {version && (
              <CurationWrapper>
                {collectionLoading ? (
                  <LoadingSpinner />
                ) : (
                  <EditTable
                    tableData={collection}
                    getCollection={getCollection}
                    getStickers={getStickers}
                    page={page}
                    version={version}
                    lang={lang}
                    edit={edit}
                    setEdit={setEdit}
                    platform={platform}
                  />
                )}
              </CurationWrapper>
            )}
          </div>
        </EditWrapper>
      </Wrapper>
    </>
  )
}

export default index

export const SpaceResponsive = styled.div`
  height: ${props => `${props.height}px`};
`

export const Title = styled.div`
  height: 22px;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  letter-spacing: 0.21px;
`

const Wrapper = styled.div`
  display: flex;
  padding: 0 10px;
  overflow-y: hidden;
  width: 100%;
  height: calc(100vh - 140px);
  overflow-x: auto;
  position: relative;
`

const BannerWrapper = styled.div`
  width: 240px;
  min-width: 240px;
  height: calc(100% - 25px);
  border: 1px solid #dbdbdb;
  margin-right: 10px;
  margin-top: 15px;
  box-sizing: border-box;
`

const Version = styled.div`
  width: 100%;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    height: 40px;
    padding: 0 15px;
    box-sizing: border-box;
    font-weight: bold;
    background-color: #ff4500;
    color: #fff;
    border-radius: 5px;
    border: none;
  }
`

const PreviewWrapper = styled.div`
  width: 375px;
`

const EditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: calc(100% - 255px);
  height: calc(100% - 25px);
`

const CurationWrapper = styled.div`
  width: 100%;
  min-width: 400px;
`

const PopupWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`

const PopupContent = styled.div`
  min-width: 400px;
  min-height: 250px;
  background-color: #fff;
`

const PopupHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ecf0f5;
  font-size: 20px;
  font-weight: bold;
  padding: 15px;
  box-sizing: border-box;
`

const PopupBody = styled.div`
  width: 100%;
  min-height: 130px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LangBtn = styled.button`
  height: 30px;
  border-radius: 5px;
  border: 1px solid ${props => (props.selected ? '#007ffb' : '#000')};
  background-color: ${props => (props.selected ? '#007ffb' : '#fff')};
  color: ${props => (props.selected ? '#fff' : '#000')};
  margin-right: 5px;
`

const PopupFooter = styled.div`
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 60px;
  border-top: 1px solid #ecf0f5;

  #yes {
    background-color: #007ffb;
    color: #fff;
    height: 35px;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    cursor: ${props => props.deactive && 'not-allowed'};
    opacity: ${props => props.deactive && 0.4};

    &:hover {
      opacity: 0.8;
      font-weight: bold;
    }
  }

  #no {
    background-color: #dc143c;
    color: #fff;
    margin-right: 10px;
    height: 35px;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;

    &:hover {
      opacity: 0.8;
      font-weight: bold;
    }
  }
`
