import React from "react";
import ContentsDashboard from "../../Components/Dashboard/ContentsDashboard";
// import LeftMenu from "../../Components/LeftMenu";

const ContentsDashboardPage = ({ match, location }) => {
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <ContentsDashboard />
    </React.Fragment>
  );
};

export default ContentsDashboardPage;
