import React, { useState, useEffect, useCallback, useMemo } from "react"
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import List from "../Common/List";
import { EditList2 } from "../Common/EditList";
import Lang from "../Common/InsertLang";

const Recommend = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState([]); 
    const [dataList, setDataList] = useState([]);
    const [langList, setLangList] = useState([]);
    const [lang, setLang] = useState("en");
    const [insertLang, setInsertLang] = useState("");

    const recommend = useCallback(async () => { 

        const response = await fetch(`/new/webAdmin/v2/recommend?lang=${lang}`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === 'success') {

            setLangList(responseJson.recommendLang);

            if (filedList.length === 0) {
                
                let fileds = []
    
                for (const tmp in responseJson.recommendList[0]) {
                    fileds.push(tmp);
                }
                
                setFiledList(fileds);
            }

            setDataList(responseJson.recommendList);

        } else if (responseJson.status === "fail" && responseJson.code === "1111") {
            props.history.push("/");
        } else {
            setDataList([]);
        }

    }, [lang]); 

    const save = useCallback(async (lang) => {

        if (window.confirm('Are You Sure?')) {

            const response = await fetch(`/new/webAdmin/v2/recommend/lang`, {
                headers : {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: sessionStorage.getItem("token")
                },
                method:'post',
                body: JSON.stringify({
                    lang
                })
            });

            const responseJson = await response.json();
    
            if (responseJson.status === 'success') {
                alert("저장 완료");
                setLangList([...langList, {lang:lang}]);
                setState('');
            } else if (responseJson.status === "fail" && responseJson.code === "1111") {
                props.history.push("/");
            } else {
                alert("저장 실패");
            }

        }
    }, [langList]);

    const setInputLang = (lang) => {
        setInsertLang(lang);
        setState(lang);
    }

    const setLanguage = (lang) => {
        setLang(lang);
    }

    const langDelete = useCallback(async () => {
        if (window.confirm('Are You Sure?')) {

            const response = await fetch(`/new/webAdmin/v2/recommend/lang/${lang}`, {
                headers : {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: sessionStorage.getItem("token")
                },
                method:'delete'
            });

            const responseJson = await response.json();
    
            if (responseJson.status === 'success') {
                alert("삭제 완료");
                setLangList(langList.filter(item => item.lang !== lang));
                setLang('en');
            } else if (responseJson.status === "fail" && responseJson.code === "1111") {
                props.history.push("/");
            } else {
                alert("삭제 실패");
            }

        }
    }, [langList]);

    const addKeywordForm = useCallback( async () => {
        setDataList([...dataList, {seq:'insert', keyword:'', lang, status:"N"}]);
    }, [dataList]);
    
    const [LangInput, setState] = Lang("", langList, setLanguage, save, setInputLang, langDelete, addKeywordForm);

    const callback = useCallback((value, key, index) => {
        dataList[index][key] = value;
        // setDataList([...dataList]);
    }, [dataList]);

    const editKeyword = async (data) => {

        const response = await fetch(`/new/webAdmin/v2/recommend`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            }
            , method:'PUT'
            , body: JSON.stringify(data)
        });

        const responseJson = await response.json();

        if (responseJson.status === 'success') {
            if (data.seq === 'insert') {
                setDataList(dataList.map(item => item.seq === 'insert' && item.keyword === data.keyword ? {...item, seq:responseJson.seq} : item));
            }
            alert("성공");
        } else if (responseJson.status === "fail" && responseJson.code === "1111") {
            props.history.push("/");
        } else {
            alert("실패");
        }

    };

    const deleteKeyword = async (seq) => {
        // alert(seq);
        // alert(index);
        if (window.confirm('Are You Sure?')) {
            
            const response = await fetch(`/new/webAdmin/v2/recommend/keyword/${seq}`, {
                headers : {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: sessionStorage.getItem("token")
                }
                , method:'DELETE'
            });

            setDataList(dataList.filter(item => item.seq !== seq));
            
            alert("삭제완료");
        }
    };

    const updateStatus = async (seq, status) => {
        
        fetch(`/new/webAdmin/v2/recommend/keyword/status`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token")
            }
            , method:'PUT'
            , body: JSON.stringify({seq, status})
        });

        setDataList(dataList.map(item => item.seq === seq ? {...item, status} : item));
    }

    const updateOrder = async (data, index, order) => {
        
        const tmp = data.order;

        if (order === "UP") {
            
            if (index === 0) {
                alert("첫번째 입니다.");
                return;
            }

            await fetch(`/new/webAdmin/v2/recommend/keyword/order`, {
                headers : {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: sessionStorage.getItem("token")
                }
                , method:'PUT'
                , body: JSON.stringify({...data, move:order})
            });
            
            dataList[index].order = dataList[index-1].order;
            dataList[index-1].order = tmp;

            [dataList[index], dataList[index-1]] = [dataList[index-1], dataList[index]];
            setDataList([...dataList]);
            
        } else if (order === "DOWN") {
                
            if (index === dataList.length-1) {
                alert("마지막 입니다.");
                return;
            }

            await fetch(`/new/webAdmin/v2/recommend/keyword/order`, {
                headers : {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: sessionStorage.getItem("token")
                }
                , method:'PUT'
                , body: JSON.stringify({...data, move:order})
            });

            dataList[index].order = dataList[index+1].order;
            dataList[index+1].order = tmp;
            
            [dataList[index], dataList[index+1]] = [dataList[index+1], dataList[index]];
            setDataList([...dataList]);
        }

    }

    const customComponent = useCallback(() => {
        return (
            <EditList2 
                fieldList={filedList} 
                dataList={dataList} 
                setDataList={callback} 
                editKeyword={(data) => editKeyword(data)} 
                deleteKeyword={(seq) => deleteKeyword(seq)} 
                updateStatus={(seq, status) => updateStatus(seq, status)}
                updateOrder={(seq, index, order) => updateOrder(seq, index, order)}
            />
            // <List fieldList={filedList} dataList={dataList} />
        )
    }, [dataList]);


    useEffect(() => { 
        // componentDidMount

        const getData = async () => { 
            await recommend();
            setIsLoading(false);
        };

        getData();

        return async () => {
          //componentWillUnmount
        };
    }, [lang]);

    if (!isLoading) {
        return (
            <>
                <Header title={"Recommend keyword"} />
                <Body HeaderComponent={LangInput} headerWidth={"30%"} DataComponet={customComponent}  />
            </>
        )
    } else {
        return <Loading />;
    }
}

export default withRouter(Recommend);