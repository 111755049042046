import React, { useEffect, useState } from 'react'
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import { AiOutlineRollback } from 'react-icons/ai'
import {
  countryDefaultLanguage,
  languages,
} from '../../../Data/CountryAndLanguage'

const HeaderBox = styled.div`
  margin-bottom: 15px;
  width: 100%;
`

const StyledButton = styled.button`
  border: none;
  background-color: skyblue;
  margin: 5px;
  border-radius: 10px;
  padding: 5px 10px;
  :focus {
    outline: none;
  }
`

const ClickedButton = styled.button`
  background-color: #fff;
  // border: 3px solid #ff4500;
  border: 3px solid #294e83;
  margin: 5px;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: bold;
  color: black;
`

const LangButton = styled.button`
  background-color: #294e83;
  margin: 5px;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: bold;
  color: #ffffff;

  &:hover {
    background-color: #3665a9;
  }
`

const ResetButton = styled.button`
  border: none;
  border-radius: 10px;
  background-color: crimson;
  margin: 5px;
  padding: 5px 10px;
  font-weight: bold;
  color: white;
`
const Header = props => {
  const [countries, setCountries] = useState([])
  useEffect(() => {
    let set = new Set([])
    let arr = []
    countryDefaultLanguage.filter(con => {
      if (!set.has(con.country)) {
        set.add(con.country)
        arr.push(con)
      }
    })
    setCountries(arr)
  }, [])

  const [selectedLang, setSelectedLang] = useState('en')
  const [selectedCountry, setSelectedCountry] = useState('us')

  const clickLang = (lang, country) => {
    props.setDefaultParams(lang, country)
    setSelectedLang(lang)
    setSelectedCountry(country)
  }

  const clickCountry = country => {
    props.setCountry(country)
    setSelectedCountry(country)
  }

  const clickReset = () => {
    setSelectedLang('')
    setSelectedCountry('')
    props.resetParams()
  }

  return (
    <HeaderBox>
      <section className="content-header" style={{ marginBottom: '15px' }}>
        <h1>CurationPreview</h1>
      </section>
      <section>
        <Container fluid>
          <Row>
            <Col md={'auto'}>
              {!selectedLang && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>언어를 선택하세요(Select a language).</span>
                  <div>
                    {languages.map(lang => (
                      //   <StyledButton key={lang} onClick={() => clickLang(lang)}>
                      <StyledButton
                        key={lang}
                        onClick={() =>
                          clickLang(
                            lang,
                            countryDefaultLanguage.filter(
                              item => item.language === lang
                            )[0].country
                          )
                        }
                      >
                        {lang}
                      </StyledButton>
                    ))}
                  </div>
                </div>
              )}
              {selectedLang && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>국가를 선택하세요(Select a country).</span>
                  <div style={{ display: 'flex' }}>
                    <OverlayTrigger
                      placement={'bottom'}
                      overlay={<Tooltip>언어 변경</Tooltip>}
                    >
                      <LangButton onClick={clickReset}>
                        {selectedLang}
                      </LangButton>
                    </OverlayTrigger>

                    {countries.map(country =>
                      selectedCountry === country.country ? (
                        <ClickedButton
                          key={country.country}
                          onClick={() => clickCountry(country.country)}
                        >
                          {country.country_kr}({country.country})
                        </ClickedButton>
                      ) : (
                        <StyledButton
                          key={country.country}
                          onClick={() => clickCountry(country.country)}
                        >
                          {country.country_kr}({country.country})
                        </StyledButton>
                      )
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </HeaderBox>
  )
}

export default Header
