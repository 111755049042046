import React from "react";
import After from "../../Components/Money/After";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const AfterPage = ({ match, location }) => {
  const query = queryString.parse(location.search);
  return (
    <React.Fragment>
      {/* <LeftMenu /> */}
      <After date={query.date} />
    </React.Fragment>
  );
};

export default AfterPage;
