import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import Header from "../Common/Header";
import Body from "../Common/Body";
import List from "../Common/List";
import { EditList3 } from "../Common/EditList";
import SearchTextAni from "../Common/SearchTextAni";

const searchColorApi = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [filedList, setFiledList] = useState([]); 
    const [dataList, setDataList] = useState([]);
    const [animated, setAnimated] = useState("N");
    
    const search = useCallback(async (searchText) => { 

        setState(searchText);
      
        const color = encodeURIComponent(searchText);

        const response = await fetch(`https://messenger.stipop.io/v1/search/color?color=${color}&userId=9937&animated=${animated}&limit=100`, {
            headers : {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                apikey: 'f1487146b30e92fc07ae42f44de3c5d7',
            },
            method:'get'
        });

        const responseJson = await response.json();

        if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");

        if (responseJson.body.stickerList) {

            if (filedList.length === 0) {
                
                let fileds = []
    
                for (const tmp in responseJson.body.stickerList[0]) {
                    fileds.push(tmp);
                }
                
                setFiledList(fileds);
            }
            
            setDataList(responseJson.body.stickerList);

        } else {
            setDataList([]);
        }

    }); 

    const [keyword, SearchInput, setState] = SearchTextAni("", search, setAnimated);

    const callback = useCallback((value, key, index) => {
        dataList[index][key] = value;
        // setDataList([...dataList]);
    }, [dataList]);

    const customComponent = useCallback(() => {
        return (
            <EditList3 
                fieldList={filedList} 
                dataList={dataList} 
                setDataList={callback} 
            />
        )
    }, [dataList]);


    useEffect(() => { 
        // componentDidMount
        setIsLoading(false);

        return async () => {
          //componentWillUnmount
        };
    }, [dataList]);

    if (!isLoading) {
        return (
            <>
                <Header title={"search Color Api"} />
                <Body HeaderComponent={SearchInput} headerWidth={"20%"} DataComponet={customComponent}  />
            </>
        )
    } else {
        return <Loading />;
    }
}

export default withRouter(searchColorApi);