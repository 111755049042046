import React from "react";
import { Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import App from "./App";
import HeaderBar from "./_Components/HeaderBar/HeaderBarContainer";
import LeftMenu from "./_Components/LeftMenu/LeftMenuContainer";

const Wrapper = styled.div`
  background-color: #ecf0f5;
  padding-left: 230px;
  padding-top: 50px;
  overflow: scroll;
  white-space: nowrap;
`;

const Root = () => (
  <Fragment>
    <BrowserRouter>
      <LeftMenu />
      <HeaderBar />
      <Wrapper>
        <App />
      </Wrapper>
    </BrowserRouter>
  </Fragment>
);

export default Root;
