export default (file, size, transformedSize, pixel) => {
  return new Promise((resolve, reject) => {
    const readAsDataURL = file => {
      //파일 사이즈 검증
      const fileSize = file.size
      //파일사이즈 검증이 필요없는 경우, size는 fileSize보다 크게, 경고메세지로 표시되는 transformedSize 는 0으로
      size = size || fileSize + 1
      transformedSize = transformedSize || 0

      if (fileSize > size) {
        reject(`File must be ${transformedSize} or less`)
      }
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        //이미지 픽셀 크기 검증
        if (pixel) {
          const image = new Image()
          image.addEventListener('load', () => {
            if (image.width !== pixel || image.height !== pixel) {
              reject(`Image size must be ${pixel}px`)
            } else {
              resolve(reader.result)
            }
          })
          image.src = reader.result
        } else {
          resolve(reader.result)
        }
      })
      reader.readAsDataURL(file)
    }
    readAsDataURL(file)
  })
}
