import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import LeftMenu from '../../Components/LeftMenu';
import Loading from '../../Components/Common/Loading/Loading';
import { withRouter, Link } from 'react-router-dom';
import InsertTags from '../../Components/StickerTag/InsertTags';
import InsertTagGuideline from '../../Components/StickerTag/InsertTagGuideline';

const StickerTagInsertPage = (props) => {
    const [ user, setUser ] = useState({})
    const [ isLoading, setIsLoading ] = useState(true);
    const [ sticker, setSticker ] = useState({});
    const [ tags, setTags ] = useState([]);
    const [ existingTags, setExistingTags ] = useState([]);
    const [ frequentTags, setFrequentTags ] = useState([]);
    
    useEffect(() => {
        if (sessionStorage.getItem("token")) {
            if(props.match.params.id > 0) loadData();
            else props.history.push('/Taggers/Home');
        }
        else {
            props.history.push('/');
        }
    }, []);

    const loadData = async () => {
        await getUser();
        await getUntaggedSticker();
        await getFrequentTags();
        setIsLoading(false);
    }

    const getUser = async () => {
        return fetch('/new/webAdmin/v2/taggers/'+props.match.params.id, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: "GET"
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
            setUser(responseJson.tagger);
        })
        .catch((error) => {console.error("123=" + error)})
    }

    const getUntaggedSticker = async () => {
        return fetch('/new/webAdmin/v2/tags/untagged-sticker', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: "GET"
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
            setSticker(responseJson.untaggedSticker);
            setExistingTags(responseJson.tags);
            setTags([]);
            setIsLoading(false);
        })
        .catch((error) => {console.error("123=" + error)})
    }

    const getFrequentTags = async () => {
        return fetch('/new/webAdmin/v2/tags/frequent', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: "GET"
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
            setFrequentTags(responseJson.tags);
        })
        .catch((error) => {console.error("123=" + error)})
    }

    const handleSubmit = () => {
        if(tags.length < 6) {
            alert("There should be at least 6 tags. \n태그는 6개 이상 있어야 합니다.");
            return;
        }
        setIsLoading(true);
        const params = {
            taggerId : user.SEQ,
            s_id : sticker.s_id,
            tags : tags
        }
        fetch('/new/webAdmin/v2/tags', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'token': sessionStorage.getItem("token")
            }
            , method: "POST"
            , body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === "fail" && responseJson.code === "1111") props.history.push("/");
            if(responseJson.status === "success"){
                setUser({...user, TOTAL_COUNT: user.TOTAL_COUNT + 1});
                getUntaggedSticker();
                getFrequentTags();
            } else {
                alert("Submit Fail. Try Again");
                setIsLoading(false);
            }
        })
        .catch((error) => {console.error("123=" + error)})
    }
    
    return (
        <React.Fragment>
            <LeftMenu />
            <div className="content-wrapper" style={{ minHeight: '100%' }}>
                <section className="content-header" style={{marginBottom : '15px'}}>
                    <h1>
                        태그 입력
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="/Taggers/Home"><i className="fa fa-dashboard"></i> 태그</a></li>
                        <li className="active">태그 입력</li>
                    </ol>
                </section>
                <section>
                    {isLoading || !sticker
                    ? <Loading />
                    : <Container fluid>
                        <Row><Col><InsertTags user={user} sticker={sticker} existingTags={existingTags} tags={tags} setTags={setTags} handleSubmit={handleSubmit} frequentTags={frequentTags}/></Col></Row>
                        <Row>
                            <Col md={{offset: 10, span: 2}}>
                                <Button variant="success" style={{marginRight: "2px"}} onClick={handleSubmit}>Submit(제출)</Button>
                                <Link to="/Taggers/Home"><Button variant="warning">Quit(종료)</Button></Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col><InsertTagGuideline /></Col>
                        </Row>
                    </Container>}
                </section>
            </div>
        </React.Fragment>
    )
}

export default withRouter(StickerTagInsertPage);