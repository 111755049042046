import React from 'react'
import Table from 'react-bootstrap/Table'

const ArtistListTable = (props) => {

    const renderCommitCounts = (count) => {
        return count[0] ?
        <strong><font color="blue">({count[0]}) : </font> <font color="limegreen">[승인({count[2]})]</font> <font color="gray">[검토 중({count[1]})]</font></strong>
        :
        <div style={{color:"gray"}}><font>({count[0]}) : </font> [<font>승인({count[2]})</font>] [<font>검토 중({count[1]})]</font></div>
        ;
    }

    const renderContents = () => {
        let list = props.contents && props.contents.map(data => (
            <tr key={data.u_id}>
                <td><input type="checkbox" onChange={(event) => props.handleClick(event)} value={data.u_id}/></td>
                <td>{data.u_id}</td>
                <td onClick= {() => props.handleShow(data)} >{data.u_name}</td>
                <td onClick= {() => props.handleShow(data)} >{data.u_email}</td>
                
                <td>{data.u_date}</td>
                <td>{data.u_loginType === 0 ? "Web" : "App"}</td>
                <td>{data.u_phModel ? data.u_phModel : "web"}</td>
                

                <td onClick= {() => props.handleShow(data)} >{renderCommitCounts(data.count)}</td> 
                
                <td>{data.c_name}</td>
                <td>{data.u_certMail === 0 ? <i className="fa fa-times"></i> : <i className="fa fa-check"></i>}</td>
            </tr>
        ));
        return list;
    }

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th><input type="checkbox" /></th>
                    <th>No.</th>
                    <th>이름</th>
                    <th>email</th>

                    <th>가입일</th>
                    <th>가입경로</th>
                    <th>os</th>
                    
                    <th>활동 현황</th>
                    <th>
                        <select id="countrySelectBox" style={{verticalAlign: "middle", fontSize : "12px"}} value={props.params ? props.params.countryCode : -1} onChange={(event) => props.setParams({...props.params, countryCode: event.target.value})}>
                            <option value={-1}>국적</option>
                            {props.artistCountryList && props.artistCountryList.map(data => 
                                <option value={data.code} key={data.c_id}>{data.c_name}</option>
                            )}        
                        </select>
                    </th>
                    <th>메일인증</th>
                </tr>
            </thead>
            <tbody>
                {renderContents()}
            </tbody>
        </Table>
    )
}

export default ArtistListTable;