import axios from "axios";
import React, { useState, Fragment } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { IoArrowBack } from "react-icons/io5";
import { AiOutlinePaperClip } from "react-icons/ai";
import AttachPreview from "../../Common/BoxUI/AttachPreviewBox";
import DefaultButton from "../../Common/ButtunUI/DefaultButton";
import Linkify from "react-linkify";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const HeaderTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid #e8e9ed;
`;

const HeaderTitleText = styled.div`
  width: 716px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  overflow-wrap: break-word;
`;

const HeaderBottomDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 30px;
`;

const HeaderTimeText = styled.div`
  font-family: RawlineMedium, sans-serif;
  font-size: 12px;
  color: #8f8f8f;
`;

const DetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const TextArea = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const NoticeText = styled.div`
  color: #0c0b24;
  line-height: 1.86;
`;

const MentionBottom = styled.div`
  display: flex;
  position: relative;
`;

const NoticeAttachArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const NoticeAttachBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
`;

const NoticeAttachIcon = styled(AiOutlinePaperClip)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 2px;
  background-color: #f7f9fc;
  color: #7b7b7b;
`;

const MentionAttachText = styled.div`
  color: #b3b3b3;
  margin-left: 4px;
`;

const ControlDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  letter-spacing: 2.4px;
  font-size: 12px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const BackListBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BackArrowIcon = styled(IoArrowBack)`
  color: #9fa3a7;
  margin-bottom: 1px;
`;

const BackListText = styled.div`
  color: #9fa3a7;
  margin-left: 8px;
`;

const MessageNoticeEditPreview = ({ noticeData, goTop }) => {
  const [previewLang, setPreviewLang] = useState("en");

  const [isAttachHover, setIsAttachHover] = useState(-1);

  const history = useHistory();

  const tempDate = new Date().toLocaleString().split(". ");

  const subDate = `${tempDate[0]}.${
    tempDate[1].length === 1 ? `0${tempDate[1]}` : `${tempDate[1]}`
  }.${tempDate[2].length === 1 ? `0${tempDate[2]}` : `${tempDate[2]}`}`;

  const addOneNotice = async () => {
    const {
      imageEn,
      imageKo,
      noticeImageEn,
      noticeImageKo,
      attachEn1,
      attachEn2,
      attachEn3,
      attachKo1,
      attachKo2,
      attachKo3,
      attachPathEn1,
      attachNameEn1,
      attachPathEn2,
      attachNameEn2,
      attachPathEn3,
      attachNameEn3,
      attachPathKo1,
      attachNameKo1,
      attachPathKo2,
      attachNameKo2,
      attachPathKo3,
      attachNameKo3,
      noticeTitleEn,
      noticeTitleKo,
      noticeContentsEn,
      noticeContentsKo,
      noticeContentsPlace,
      fixStatus,
      noticeId,
    } = noticeData;
    let formData = new FormData();
    if (imageEn !== undefined && imageEn !== null) {
      formData.append("imageEn", imageEn);
    } else {
      formData.append("imagePathEn", noticeImageEn);
    }
    if (imageKo !== undefined && imageKo !== null) {
      formData.append("imageKo", imageKo);
    } else {
      formData.append("imagePathKo", noticeImageKo);
    }
    if (attachEn1 !== undefined && attachEn1 !== null) {
      formData.append("attachEn1", attachEn1);
    } else {
      formData.append("attachPathEn1", attachPathEn1);
      formData.append("attachNameEn1", attachNameEn1);
    }
    if (attachEn2 !== undefined && attachEn2 !== null) {
      formData.append("attachEn2", attachEn2);
    } else {
      formData.append("attachPathEn2", attachPathEn2);
      formData.append("attachNameEn2", attachNameEn2);
    }
    if (attachEn3 !== undefined && attachEn3 !== null) {
      formData.append("attachEn3", attachEn3);
    } else {
      formData.append("attachPathEn3", attachPathEn3);
      formData.append("attachNameEn3", attachNameEn3);
    }
    if (attachKo1 !== undefined && attachKo1 !== null) {
      formData.append("attachKo1", attachKo1);
    } else {
      formData.append("attachPathKo1", attachPathKo1);
      formData.append("attachNameKo1", attachNameKo1);
    }
    if (attachKo2 !== undefined && attachKo2 !== null) {
      formData.append("attachKo2", attachKo2);
    } else {
      formData.append("attachPathKo2", attachPathKo2);
      formData.append("attachNameKo2", attachNameKo2);
    }
    if (attachKo3 !== undefined && attachKo3 !== null) {
      formData.append("attachKo3", attachKo3);
    } else {
      formData.append("attachPathKo3", attachPathKo3);
      formData.append("attachNameKo3", attachNameKo3);
    }
    formData.append("titleEn", noticeTitleEn);
    formData.append("titleKo", noticeTitleKo);
    formData.append("contentsEn", noticeContentsEn);
    formData.append("contentsKo", noticeContentsKo);
    formData.append("contentsPlace", noticeContentsPlace ? 1 : 0);
    formData.append("fixStatus", fixStatus ? 1 : 0);
    formData.append("noticeId", noticeId);

    try {
      await axios
        .put(`/new/webAdmin/v2/message-center/notice`, formData, {
          headers: { token: sessionStorage.getItem("token") },
        })
        .then((res) => {
          history.push("/Message/Notice");
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickApplyButton = () => {
    const { imageEn, imageKo, titleEn, titleKo, contentsEn, contentsKo } =
      noticeData;
    if (titleEn === "" || titleKo === "") {
      alert("Please input both EN and KO title.");
      return;
    }
    if (contentsEn === "" && contentsKo !== "") {
      alert("Please input both EN and KO contents.");
      return;
    }
    if (contentsKo === "" && contentsEn !== "") {
      alert("Please input both EN and KO contents.");
      return;
    }
    if (imageEn === undefined && imageKo !== undefined) {
      alert("Please input both EN and KO image.");
      return;
    }
    if (imageKo === undefined && imageEn !== undefined) {
      alert("Please input both EN and KO image.");
      return;
    }
    if (contentsEn === "" && imageEn === undefined) {
      alert("Please input contents or image.");
      return;
    }

    if (
      window.confirm(
        "Before applying changes, please make sure you click 'Show Preview' buttons. Do you want to continue?"
      )
    )
      addOneNotice();
  };

  return (
    <Wrapper>
      {!noticeData &&
        'Please input form left side, then click "Show Preview" button.'}
      {noticeData && (
        <Fragment>
          <HeaderTitleBox>
            <HeaderTitleText>
              {previewLang === "en"
                ? noticeData.noticeTitleEn
                : noticeData.noticeTitleKo}
            </HeaderTitleText>
            <HeaderBottomDiv>
              <HeaderTimeText>{subDate}</HeaderTimeText>
            </HeaderBottomDiv>
          </HeaderTitleBox>
          {previewLang === "en" ? (
            <DetailDiv>
              {noticeData.noticeContentsEn !== undefined &&
                noticeData.noticeContentsEn !== null &&
                noticeData.noticeContentsPlace === 0 && (
                  <TextArea>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <NoticeText>
                        {noticeData.noticeContentsEn
                          .split("\n")
                          .map((line, index) => {
                            let resultLine = "";
                            const splitLine1 = line.split("![");
                            if (splitLine1.length > 1) {
                              const splitLink1 = splitLine1[1].split("]");
                              const splitLinkName = splitLink1[0];
                              const splitLink2 = splitLink1[1]
                                .split("(")[1]
                                .split(")");
                              const splitLinkPath = splitLink2[0];
                              const splitLine2 = splitLink2[1];
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    href={splitLinkPath}
                                    target={"_blank"}
                                  >
                                    {splitLinkName}
                                  </a>
                                  {splitLine2}
                                  <br />
                                </span>
                              );
                            } else {
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <br />
                                </span>
                              );
                            }
                            return resultLine;
                          })}
                      </NoticeText>
                    </Linkify>
                  </TextArea>
                )}
              {noticeData.imageEn !== undefined &&
              noticeData.imageEn !== null ? (
                <img
                  style={{ marginTop: 15, marginBottom: 15 }}
                  src={URL.createObjectURL(noticeData.imageEn)}
                  alt={""}
                />
              ) : (
                <img
                  style={{ marginTop: 15, marginBottom: 15 }}
                  src={encodeURI(noticeData.noticeImageEn)}
                  alt={""}
                />
              )}
              {noticeData.noticeContentsEn !== undefined &&
                noticeData.noticeContentsEn !== null &&
                noticeData.noticeContentsPlace === 1 && (
                  <TextArea>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <NoticeText>
                        {noticeData.noticeContentsEn
                          .split("\n")
                          .map((line, index) => {
                            let resultLine = "";
                            const splitLine1 = line.split("![");
                            if (splitLine1.length > 1) {
                              const splitLink1 = splitLine1[1].split("]");
                              const splitLinkName = splitLink1[0];
                              const splitLink2 = splitLink1[1]
                                .split("(")[1]
                                .split(")");
                              const splitLinkPath = splitLink2[0];
                              const splitLine2 = splitLink2[1];
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    href={splitLinkPath}
                                    target={"_blank"}
                                  >
                                    {splitLinkName}
                                  </a>
                                  {splitLine2}
                                  <br />
                                </span>
                              );
                            } else {
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <br />
                                </span>
                              );
                            }
                            return resultLine;
                          })}
                      </NoticeText>
                    </Linkify>
                  </TextArea>
                )}
              <MentionBottom>
                <NoticeAttachArea>
                  {noticeData.attachEn1 !== undefined &&
                  noticeData.attachEn1 !== null ? (
                    <NoticeAttachBox
                      onClick={() => {
                        window.open(
                          URL.createObjectURL(noticeData.attachEn1),
                          "_blank"
                        );
                      }}
                    >
                      <NoticeAttachIcon
                        onMouseEnter={() => {
                          setIsAttachHover(0);
                        }}
                        onMouseLeave={() => {
                          setIsAttachHover(-1);
                        }}
                      />
                      <MentionAttachText>
                        {noticeData.attachEn1.name}
                      </MentionAttachText>
                      {isAttachHover === 0 && (
                        <AttachPreview
                          url={URL.createObjectURL(noticeData.attachEn1)}
                        />
                      )}
                    </NoticeAttachBox>
                  ) : (
                    noticeData.attachPathEn1 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            encodeURI(noticeData.attachPathEn1),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(0);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {noticeData.attachNameEn1}
                        </MentionAttachText>
                        {isAttachHover === 0 && (
                          <AttachPreview
                            url={encodeURI(noticeData.attachPathEn1)}
                          />
                        )}
                      </NoticeAttachBox>
                    )
                  )}
                  {noticeData.attachEn2 !== undefined &&
                  noticeData.attachEn2 !== null ? (
                    <NoticeAttachBox
                      onClick={() => {
                        window.open(
                          URL.createObjectURL(noticeData.attachEn2),
                          "_blank"
                        );
                      }}
                    >
                      <NoticeAttachIcon
                        onMouseEnter={() => {
                          setIsAttachHover(1);
                        }}
                        onMouseLeave={() => {
                          setIsAttachHover(-1);
                        }}
                      />
                      <MentionAttachText>
                        {noticeData.attachEn2.name}
                      </MentionAttachText>
                      {isAttachHover === 1 && (
                        <AttachPreview
                          url={URL.createObjectURL(noticeData.attachEn2)}
                        />
                      )}
                    </NoticeAttachBox>
                  ) : (
                    noticeData.attachPathEn2 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            encodeURI(noticeData.attachPathEn2),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(1);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {noticeData.attachNameEn2}
                        </MentionAttachText>
                        {isAttachHover === 1 && (
                          <AttachPreview
                            url={encodeURI(noticeData.attachPathEn2)}
                          />
                        )}
                      </NoticeAttachBox>
                    )
                  )}
                  {noticeData.attachEn3 !== undefined &&
                  noticeData.attachEn3 !== null ? (
                    <NoticeAttachBox
                      onClick={() => {
                        window.open(
                          URL.createObjectURL(noticeData.attachEn3),
                          "_blank"
                        );
                      }}
                    >
                      <NoticeAttachIcon
                        onMouseEnter={() => {
                          setIsAttachHover(2);
                        }}
                        onMouseLeave={() => {
                          setIsAttachHover(-1);
                        }}
                      />
                      <MentionAttachText>
                        {noticeData.attachEn3.name}
                      </MentionAttachText>
                      {isAttachHover === 2 && (
                        <AttachPreview
                          url={URL.createObjectURL(noticeData.attachEn3)}
                        />
                      )}
                    </NoticeAttachBox>
                  ) : (
                    noticeData.attachPathEn3 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            encodeURI(noticeData.attachPathEn3),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(2);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {noticeData.attachNameEn3}
                        </MentionAttachText>
                        {isAttachHover === 2 && (
                          <AttachPreview
                            url={encodeURI(noticeData.attachPathEn3)}
                          />
                        )}
                      </NoticeAttachBox>
                    )
                  )}
                </NoticeAttachArea>
              </MentionBottom>
            </DetailDiv>
          ) : (
            <DetailDiv>
              {noticeData.noticeContentsKo !== undefined &&
                noticeData.noticeContentsKo !== null &&
                noticeData.noticeContentsPlace === 0 && (
                  <TextArea>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <NoticeText>
                        {noticeData.noticeContentsKo
                          .split("\n")
                          .map((line, index) => {
                            let resultLine = "";
                            const splitLine1 = line.split("![");
                            if (splitLine1.length > 1) {
                              const splitLink1 = splitLine1[1].split("]");
                              const splitLinkName = splitLink1[0];
                              const splitLink2 = splitLink1[1]
                                .split("(")[1]
                                .split(")");
                              const splitLinkPath = splitLink2[0];
                              const splitLine2 = splitLink2[1];
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    href={splitLinkPath}
                                    target={"_blank"}
                                  >
                                    {splitLinkName}
                                  </a>
                                  {splitLine2}
                                  <br />
                                </span>
                              );
                            } else {
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <br />
                                </span>
                              );
                            }
                            return resultLine;
                          })}
                      </NoticeText>
                    </Linkify>
                  </TextArea>
                )}
              {noticeData.imageKo !== undefined &&
              noticeData.imageKo !== null ? (
                <img
                  style={{ marginTop: 15, marginBottom: 15 }}
                  src={URL.createObjectURL(noticeData.imageKo)}
                  alt={""}
                />
              ) : (
                <img
                  style={{ marginTop: 15, marginBottom: 15 }}
                  src={encodeURI(noticeData.noticeImageKo)}
                  alt={""}
                />
              )}
              {noticeData.noticeContentsKo !== undefined &&
                noticeData.noticeContentsKo !== null &&
                noticeData.noticeContentsPlace === 1 && (
                  <TextArea>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          style={{
                            color: "#fe3a93",
                            textDecoration: "underline",
                          }}
                          target={decoratedText}
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <NoticeText>
                        {noticeData.noticeContentsKo
                          .split("\n")
                          .map((line, index) => {
                            let resultLine = "";
                            const splitLine1 = line.split("![");
                            if (splitLine1.length > 1) {
                              const splitLink1 = splitLine1[1].split("]");
                              const splitLinkName = splitLink1[0];
                              const splitLink2 = splitLink1[1]
                                .split("(")[1]
                                .split(")");
                              const splitLinkPath = splitLink2[0];
                              const splitLine2 = splitLink2[1];
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <a
                                    style={{
                                      color: "#fe3a93",
                                      textDecoration: "underline",
                                    }}
                                    href={splitLinkPath}
                                    target={"_blank"}
                                  >
                                    {splitLinkName}
                                  </a>
                                  {splitLine2}
                                  <br />
                                </span>
                              );
                            } else {
                              resultLine = (
                                <span key={index}>
                                  {splitLine1[0]}
                                  <br />
                                </span>
                              );
                            }
                            return resultLine;
                          })}
                      </NoticeText>
                    </Linkify>
                  </TextArea>
                )}
              <MentionBottom>
                <NoticeAttachArea>
                  {noticeData.attachKo1 !== undefined &&
                  noticeData.attachKo1 !== null ? (
                    <NoticeAttachBox
                      onClick={() => {
                        window.open(
                          URL.createObjectURL(noticeData.attachKo1),
                          "_blank"
                        );
                      }}
                    >
                      <NoticeAttachIcon
                        onMouseEnter={() => {
                          setIsAttachHover(0);
                        }}
                        onMouseLeave={() => {
                          setIsAttachHover(-1);
                        }}
                      />
                      <MentionAttachText>
                        {noticeData.attachKo1.name}
                      </MentionAttachText>
                      {isAttachHover === 0 && (
                        <AttachPreview
                          url={URL.createObjectURL(noticeData.attachKo1)}
                        />
                      )}
                    </NoticeAttachBox>
                  ) : (
                    noticeData.attachPathKo1 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            encodeURI(noticeData.attachPathKo1),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(0);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {noticeData.attachNameKo1}
                        </MentionAttachText>
                        {isAttachHover === 0 && (
                          <AttachPreview
                            url={encodeURI(noticeData.attachPathKo1)}
                          />
                        )}
                      </NoticeAttachBox>
                    )
                  )}
                  {noticeData.attachKo2 !== undefined &&
                  noticeData.attachKo2 !== null ? (
                    <NoticeAttachBox
                      onClick={() => {
                        window.open(
                          URL.createObjectURL(noticeData.attachKo2),
                          "_blank"
                        );
                      }}
                    >
                      <NoticeAttachIcon
                        onMouseEnter={() => {
                          setIsAttachHover(1);
                        }}
                        onMouseLeave={() => {
                          setIsAttachHover(-1);
                        }}
                      />
                      <MentionAttachText>
                        {noticeData.attachKo2.name}
                      </MentionAttachText>
                      {isAttachHover === 1 && (
                        <AttachPreview
                          url={URL.createObjectURL(noticeData.attachKo2)}
                        />
                      )}
                    </NoticeAttachBox>
                  ) : (
                    noticeData.attachPathKo2 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            encodeURI(noticeData.attachPathKo2),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(1);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {noticeData.attachNameKo2}
                        </MentionAttachText>
                        {isAttachHover === 1 && (
                          <AttachPreview
                            url={encodeURI(noticeData.attachPathKo2)}
                          />
                        )}
                      </NoticeAttachBox>
                    )
                  )}
                  {noticeData.attachKo3 !== undefined &&
                  noticeData.attachKo3 !== null ? (
                    <NoticeAttachBox
                      onClick={() => {
                        window.open(
                          URL.createObjectURL(noticeData.attachKo3),
                          "_blank"
                        );
                      }}
                    >
                      <NoticeAttachIcon
                        onMouseEnter={() => {
                          setIsAttachHover(2);
                        }}
                        onMouseLeave={() => {
                          setIsAttachHover(-1);
                        }}
                      />
                      <MentionAttachText>
                        {noticeData.attachKo3.name}
                      </MentionAttachText>
                      {isAttachHover === 2 && (
                        <AttachPreview
                          url={URL.createObjectURL(noticeData.attachKo3)}
                        />
                      )}
                    </NoticeAttachBox>
                  ) : (
                    noticeData.attachPathKo3 !== null && (
                      <NoticeAttachBox
                        onClick={() => {
                          window.open(
                            encodeURI(noticeData.attachPathKo3),
                            "_blank"
                          );
                        }}
                      >
                        <NoticeAttachIcon
                          onMouseEnter={() => {
                            setIsAttachHover(2);
                          }}
                          onMouseLeave={() => {
                            setIsAttachHover(-1);
                          }}
                        />
                        <MentionAttachText>
                          {noticeData.attachNameKo3}
                        </MentionAttachText>
                        {isAttachHover === 2 && (
                          <AttachPreview
                            url={encodeURI(noticeData.attachPathKo3)}
                          />
                        )}
                      </NoticeAttachBox>
                    )
                  )}
                </NoticeAttachArea>
              </MentionBottom>
            </DetailDiv>
          )}
          <ControlDiv>
            <BackListBox onClick={() => {}}>
              <BackArrowIcon size={16} />
              <BackListText>
                {previewLang === "en" ? "LIST" : "목록으로 돌아가기"}
              </BackListText>
            </BackListBox>
          </ControlDiv>
          <ButtonDiv>
            <DefaultButton
              style={{ width: 120 }}
              theme={"blue"}
              btnText={"Apply Changes"}
              onClick={() => {
                onClickApplyButton();
              }}
            />
            <DefaultButton
              style={{ width: 120 }}
              theme={"default"}
              btnText={previewLang === "en" ? "Language : EN" : "Language : KO"}
              onClick={() => {
                setPreviewLang(previewLang === "en" ? "ko" : "en");
                goTop();
              }}
            />
          </ButtonDiv>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default MessageNoticeEditPreview;
