import React from "react";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

class StickerQnaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      p_id: this.props.p_id,
      contents: "",
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      fetch("/new/webAdmin/v2/StickerQnaDetail/" + this.state.p_id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "get",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
          this.setState({
            stickerQna: responseJson.stickerQna,
            isLoading: false,
          });
        })
        .catch((error) => {
          console.error("123=" + error);
        });
    } else {
      this.props.history.push("/");
    }
  }

  _save = () => {
    const params = {
      p_id: this.state.p_id,
      contents: this.state.contents,
      // ,contents: encodeURIComponent(this.state.contents)
    };

    fetch("/new/webAdmin/v2/StickerQnaInsert", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        alert("완료");
        window.location.reload();
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _qnaRender = () => {
    let list = [];
    if (this.state.stickerQna) {
      for (const data of this.state.stickerQna) {
        if (data.type === "Q") {
          list.push(
            <React.Fragment key={data.seq}>
              <div className="form-group">
                <label>질문</label>
                <input
                  type="text"
                  className="form-control"
                  value={data.u_name}
                  disabled
                />
              </div>
              <div className="form-group">
                {/* <label>문의내용</label> */}
                <textarea
                  className="form-control"
                  rows="5"
                  value={data.contents}
                  disabled
                ></textarea>
              </div>
              {this._attachRender(data.attach)}
            </React.Fragment>
          );
        } else if (data.type === "A") {
          list.push(
            <React.Fragment key={data.seq}>
              <div className="form-group">
                <label>답변</label>
                <input
                  type="text"
                  className="form-control"
                  value={"Admin"}
                  disabled
                />
              </div>
              <div className="form-group">
                {/* <label>문의내용</label> */}
                <textarea
                  className="form-control"
                  rows="5"
                  value={data.contents}
                  disabled
                ></textarea>
              </div>
            </React.Fragment>
          );
        }
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _attachRender = (attach) => {
    let list = [];
    for (const tmp of attach) {
      list.push(
        <div className="form-group" key={tmp.seq}>
          <a key={tmp.seq} href={tmp.path}>
            첨부파일
          </a>
        </div>
      );
    }
    return list;
  };

  render() {
    if (!this.state.isLoading) {
      return (
        <div
        // className="content-wrapper"
        >
          <section className="content-header">
            <h1>스티커 QNA 답변</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/StickerQnaList">
                  <i className="fa fa-dashboard"></i> 스티커 QNA 답변
                </a>
              </li>
              <li className="active">스티커 QNA 관리</li>
            </ol>
          </section>
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div className="box-header with-border"></div>
                  <div className="box-body">
                    {this._qnaRender()}
                    <div className="form-group">
                      <label>답변</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        placeholder="답변입력...."
                        onChange={(event) =>
                          this.setState({ contents: event.target.value })
                        }
                      ></textarea>
                    </div>
                    <div className="box-footer">
                      <button
                        type="submit"
                        onClick={() =>
                          this.props.history.push("/StickerQnaList")
                        }
                        className="btn btn-secondary"
                      >
                        목록으로
                      </button>
                      <button
                        type="submit"
                        onClick={this._save}
                        className="btn btn-primary"
                      >
                        답변작성
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- /.box-header --> */}
              </section>
            </div>
          </section>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(StickerQnaDetail);
