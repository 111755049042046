import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { STIPOP_API_HOST } from '../../_Components/Common/common'

/**
 * 작가(아티스트)에 관한 정보, 제출한 패키지, SNS 정보 팝업
 * @param {Object} 해당 아티스트 정보{u_id, u_name, u_email, u_date, facebook, instagram, youtube, homepage, packages}
 */
const ArtistInfoPopup = props => {
  useEffect(() => {
    setBannerImg(
      props.data.bannerImg
        ? props.data.bannerImg
        : 'https://img.stipop.io/image/studio/profile-default-banner.png'
    )
    setProfileImg(
      props.data.profileImg
        ? props.data.profileImg
        : 'https://img.stipop.io/image/studio/stipop-dummy-avatar.png'
    )
  }, [props])

  const [bannerImg, setBannerImg] = useState(
    props.data.bannerImg ||
      'https://img.stipop.io/image/studio/profile-default-banner.png'
  )

  const [profileImg, setProfileImg] = useState(
    props.data.profileImg ||
      'https://img.stipop.io/image/studio/stipop-dummy-avatar.png'
  )

  const handleClose = () => props.setShow(false)
  const renderPackageList = () => {
    let list =
      props.data.packages &&
      props.data.packages.map(data => (
        //data.commitDate !== null && count++;
        <tr key={data.p_id}>
          <td width="13%">
            {(() => {
              switch (data.commitType) {
                case 1:
                  return <font color="limegreen">승인</font>
                case 2:
                  return <font color="orange">수정 요청</font>
                case 3:
                  return <font color="red">거절</font>
                default:
                  return <font color="gray">검토 중</font>
              }
            })()}
          </td>
          <td width="12%">{data.p_id}</td>
          <td width="15%">{data.p_name}</td>
          <td width="20%">
            <img src={data.p_img} width="75px" height="75px" alt=""></img>
          </td>
          <td width="20%">{data.regDate}</td>
          <td width="20%">
            {data.commitDate === null ? '검토 중' : data.commitDate}
          </td>
        </tr>
      ))
    return list
  }

  return (
    <Modal show={props.show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{props.data.u_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="form-group">
            <font size={5}>
              <strong>
                <label>작가 정보</label>
              </strong>
            </font>
            <font size={3}>
              <p>ID : {props.data.u_id}</p>
              <p>Name : {props.data.u_name}</p>
              <p>Email : {props.data.u_email}</p>
              <p>Join : {props.data.u_date}</p>
              <p>
                가입경로 :{' '}
                {!props.data.u_sns
                  ? 'email'
                  : props.data.u_sns === 'gle'
                  ? 'google'
                  : props.data.u_sns === 'fb'
                  ? 'facebook'
                  : 'apple'}
              </p>
            </font>
          </div>
          <div className="form-group" style={{ marginRight: '40px' }}>
            <font size={5}>
              <strong>
                <label>작가 프로필 이미지</label>
              </strong>
            </font>
            <div
              style={{ position: 'relative', width: '328px', height: '328px' }}
            >
              <label
                style={{
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                  position: 'absolute',
                  objectFit: 'cover',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  onChange={e => {
                    if (e.target.files.length > 0) {
                      const reader = new FileReader()
                      reader.addEventListener('load', function () {
                        setBannerImg(reader.result)
                      })
                      reader.readAsDataURL(e.target.files[0])

                      const bannerData = new FormData()
                      bannerData.append('bImage', e.target.files[0])
                      bannerData.append('userId', props.data.u_id)
                      axios
                        .post(`${STIPOP_API_HOST}/v4/util/bimage`, bannerData)
                        .then(async ({ data }) => {
                          await props.setData({
                            ...props.data,
                            bannerImg: data.body.bImage,
                          })
                          await props.setArtistList(
                            props.artistList.map(item =>
                              item.u_id === props.data.u_id
                                ? { ...item, bannerImg: data.body.bImage }
                                : item
                            )
                          )
                        })
                    }
                  }}
                  style={{ display: 'none' }}
                />
                <img
                  src={bannerImg}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '20px',
                  }}
                />
              </label>
              <div
                style={{
                  position: 'absolute',
                  bottom: '13px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'cetner',
                  alignItems: 'center',
                }}
              >
                <label
                  style={{
                    cursor: 'pointer',
                    objectFit: 'cover',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '77px',
                    height: '77px',
                    borderRadius: '50%',
                    position: 'relative',
                    marginBottom: '3.5px',
                  }}
                >
                  <input
                    type="file"
                    onChange={e => {
                      if (e.target.files.length > 0) {
                        const reader = new FileReader()
                        reader.addEventListener('load', function () {
                          setProfileImg(reader.result)
                        })
                        reader.readAsDataURL(e.target.files[0])

                        const profileData = new FormData()
                        profileData.append('pImage', e.target.files[0])
                        profileData.append('userId', props.data.u_id)
                        axios
                          .put(`/new/webAdmin/v2/artist/pimage`, profileData, {
                            headers: {
                              token: sessionStorage.getItem("token"),
                            },
                          })
                          .then(async ({ data }) => {
                            await props.setData({
                              ...props.data,
                              profileImg: data.body.pImage,
                            })
                            await props.setArtistList(
                              props.artistList.map(item =>
                                item.u_id === props.data.u_id
                                  ? { ...item, profileImg: data.body.pImage }
                                  : item
                              )
                            )
                          })
                      }
                    }}
                    style={{ display: 'none' }}
                  />
                  <img
                    src={profileImg}
                    alt=""
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                </label>
                <span
                  style={{
                    height: '49.5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '21px',
                    fontWeight: 'bold',
                    color: '#fff',
                    lineHeight: '1.05',
                    letterSpacing: '0.25px',
                  }}
                >
                  {props.data.u_name}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <font size={5}>
            <strong>
              <label>업로드 현황</label>
            </strong>
          </font>
          <font size={3}>
            <p>
              {props.data.count && props.data.count[0] ? (
                <strong>
                  <font color="blue">({props.data.count[0]}) : </font>
                  <font color="limegreen">승인({props.data.count[2]})</font> /
                  <font color="orange">수정 요청({props.data.count[3]})</font> /
                  <font color="gray">검토 중({props.data.count[1]})</font> /
                  <font color="red">거절({props.data.count[4]})</font>
                </strong>
              ) : (
                ''
              )}
            </p>
          </font>
          <table className="table no-margin">
            <thead>
              <tr>
                <th width="13%">승인여부</th>
                <th width="12%">패키지ID</th>
                <th width="15%">패키지명</th>
                <th width="20%">대표이미지</th>
                <th width="20%">제출일자</th>
                <th width="20%">검토일자</th>
              </tr>
            </thead>
            <tbody>{renderPackageList()}</tbody>
          </table>
        </div>
        <div className="form-group">
          <font size={5}>
            <strong>
              <label>작가 SNS</label>
            </strong>
            <div>
              <img src="" alt="" />
            </div>
          </font>

          <font size={3}>
            {props.data.facebook && (
              <p>
                <i className="fa fa-facebook"></i>&nbsp;
                <a
                  href={props.data.facebook}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {props.data.facebook}
                </a>
              </p>
            )}
            {props.data.instagram && (
              <p>
                <i className="fa fa-instagram"></i>&nbsp;
                <a
                  href={props.data.instagram}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {props.data.instagram}
                </a>
              </p>
            )}
            {props.data.youtube && (
              <p>
                <i className="fa fa-youtube"></i>&nbsp;
                <a
                  href={props.data.youtube}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {props.data.youtube}
                </a>
              </p>
            )}
            {props.data.homepage && (
              <p>
                <i className="fa fa-home"></i>&nbsp;
                <a
                  href={props.data.homepage}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {props.data.homepage}
                </a>
              </p>
            )}
          </font>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>닫기</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ArtistInfoPopup
