import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal, Button, Row, Col } from 'react-bootstrap';

const ModalWrapper = styled.div`
    ${({open})=>css`
        display: ${open?'block':'none'}
    `}

    position: fixed;

`

const StickerWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: auto;
`

const StickerBox = styled.div`
    width: 100px;
    height: 150px;
    padding: 10px;
    margin: 5px;
`

const StickerImage = styled.img`
    width: 100px;
    height: 100px;
    border: none;
    border-radius: 10px;
    &:hover {
        cursor: pointer;
    }

    ${(props)=>css`
        background-color: ${props.light};
        &:hover {
            background-color: ${props.dark}
        }
    `}
`

const TrendingModal = (props) => {
    const [stickers, setStickers] = useState(null);
    const [mainSticker, setMainSticker] = useState(null);

    useEffect(()=>{
        if(props.open && props.info.p_id){
            getStickerList(props.info.p_id);
        }
    }, [props])

    useEffect(()=>{
        if(stickers){
            // console.log(stickers)
            stickers.forEach((item,index)=>{
                if(item.s_id === props.info.s_id){
                    setMainSticker(item.s_img);
                    return;
                }
            })
        }
    },[stickers])

    const getStickerList = async (p_id) => {
        try{
            await axios
                .get(
                    `/new/webAdmin/v2/package/sticker/${p_id}`,{
                        headers: {
                            token: window.sessionStorage.getItem("token")
                        },
                    }
                )
                .then(res=>{
                    if(res.data.status === 'success'){
                        // console.log(res.data)
                        setStickers(res.data.body.package);
                    }
                })
        } catch (err) {
            console.log(err)
        }
    }

    const handleClose = () => {
        setStickers(null);
        setMainSticker(null);
        props.closeModal();
    }

    return(
        // <ModalWrapper open={props.open}>
        //     modal
        //     <button onClick={props.closeModal}>close</button>
        // </ModalWrapper>
        <Modal show={props.open} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Package ID: {props.info && (props.info.p_id)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {stickers && (
                    <>
                        <StickerWrapper>
                            <StickerBox>
                                <StickerImage 
                                    src={mainSticker} 
                                    light={props.info.lightBackgroundCode}
                                    dark={props.info.darkBackgroundCode}
                                />
                            </StickerBox>
                        </StickerWrapper>
                        {/* <Row>
                            {stickers.map((item)=>{
                                return(
                                    <Col>
                                        {item.s_id}
                                    </Col>
                                )
                            })}
                        </Row> */}

                        <StickerWrapper>
                            {stickers.map((sticker, index)=>{
                                if(sticker.s_id !== props.info.s_id){
                                    return(
                                        <StickerBox key={sticker.s_id+index} >
                                            <StickerImage 
                                                src={sticker.s_img} 
                                                light={props.info.lightBackgroundCode}
                                                dark={props.info.darkBackgroundCode}
                                            />
                                        </StickerBox>
                                    )
                                }
                            })}
                        </StickerWrapper>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {/* <Button variant="primary" >
                    Go Sticker Edit Page
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default TrendingModal;