import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import DefaultButton from '../../Common/ButtunUI/DefaultButton'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 10px;
  input:focus {
    outline: none;
  }
`

const ControlWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const ContentWrapper = styled.div`
  display: flex;
`

const BoxBoldTitle = styled.div`
  font-weight: bold;
`

const StickerDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 220px;
  min-width: 220px;
  border: 1px solid #e5eefe;
  padding: 10px;
  margin-right: 10px;
`

const StickerImage = styled.img`
  position: absolute;
  top: 40px;
  z-index: 1;
  border: 1px solid #e5eefe;
  width: 200px;
  background-color: white;
  :hover {
    width: 500px;
  }
  transition: width 0.3s;
`

const StickerInfoBox = styled.div`
  margin-top: 220px;
  margin-bottom: 10px;
`

const StickerInfoTextBox = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5eefe;
`

const StickerInfoTag = styled.div`
  font-weight: bold;
`

const StickerInfoText = styled.div`
  margin-bottom: 5px;
  word-break: break-all;
  min-height: 22px;
`

const KeywordListDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 60%;
  border: 1px solid #e5eefe;
  margin-right: 10px;
`

const KeywordListBoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #e5eefe;
  margin-top: 10px;
  height: 100%;
`

const KeywordListBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e5eefe;
  border-top: 1px solid #e5eefe;
  border-left: 1px solid #e5eefe;
  border-right: 1px solid rgba(231, 231, 231, 0.5);
  border-bottom: 1px solid rgba(231, 231, 231, 0.5);
  box-shadow: 0 4px 4px 0 rgba(231, 231, 231, 0.5);
  padding: 0px 10px 10px 10px;
  margin-bottom: 5px;
`

const KeywordListBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e5eefe;
`

const KeywordCardName = styled.div`
  font-weight: bold;
  font-size: 18px;
`

const KeywordListButtonBox = styled.div`
  display: flex;
`

const KeywordTableRow = styled.div`
  display: flex;
`

const KeywordTableBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border: 1px solid #e5eefe;
  background-color: ${props => (props.$isEmpty ? '#ccc' : '#fff')};
  color: ${props => (props.$isEmpty ? '#fff' : '#000')};
  transition: background-color 0.3s, color 0.3s;
`

const KeywordTableHead = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const KeywordTableText = styled.div`
  height: 24px;
`

const KeywordInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`

const KeywordInputTopDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  border: 1px solid #e5eefe;
  margin-bottom: 10px;
`

const KeywordInputBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 0px;
  margin-top: 10px;
`

const KeywordInputText = styled.input`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  padding: 10px;
  width: 280px;
`

const KeywordInputBottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  border: 1px solid #e5eefe;
`

const KeywordRecommendList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

const KeywordRecommendBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  padding: 5px 10px;
  border: 1px solid #e5eefe;
  :hover {
    background-color: #e5eefe;
  }
`

const KeywordRecommendTextBox = styled.div`
  display: flex;
`

const KeywordRecommendName = styled.div`
  font-weight: bold;
`

const KeywordRecommendScore = styled.div`
  margin-left: 5px;
`

const KeywordEditInput = styled.input`
  border: 1px solid #d2d6de;
  border-radius: 4px;
  height: 24px;
`

const KeywordAddForm = () => {
  const [currentSticker, setCurrentSticker] = useState()
  const [recommendList, setRecommendList] = useState([])
  const [currentKeywordList, setCurrentKeywordList] = useState([])
  const [deleteKeywordList, setDeleteKeywordList] = useState([])
  const [updateKeywordList, setUpdateKeywordList] = useState([])

  const [customKeywordInput, setCustomKeywordInput] = useState('')

  const [onEdit, setOnEdit] = useState(-1)
  const [tempEditKeyword, setTempEditKeyword] = useState({
    en: '',
    it: '',
    pt: '',
    ru: '',
    es: '',
    ko: '',
    fr: '',
    zh_CN: '',
    zh_TW: '',
    vi: '',
    uk: '',
    ar: '',
    id: '',
    de: '',
    th: '',
    hi: '',
    ta: '',
    te: '',
    mt: '',
    ml: '',
    gu: '',
    kn: '',
    pa: '',
    bn: '',
    or: '',
    bh: '',
    ja: '',
  })

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    try {
      getStickerKeywordWithVision()
    } catch (error) {
      history.push('/Message/Notice')
    }
  }, [])

  const getStickerKeywordWithVision = async () => {
    try {
      await axios
        .get(
          `/new/webAdmin/v2/keyword/sticker/vision/${location.pathname
            .split('/')
            .pop()}`,
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        .then(res => {
          console.log(res)
          if (res.data.sticker) {
            setRecommendList(res.data.sticker.recommendResult)
            setCurrentKeywordList(res.data.sticker.keywordResult)
            setCurrentSticker({
              ...res.data.sticker.stickerResult[0],
              ...res.data.sticker.packageResult[0],
            })
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  const getKeywordTranslation = async (keyword, index) => {
    try {
      await axios
        .get(`/new/webAdmin/v2/keyword/sticker/translate/${keyword}`, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then(res => {
          const temp = currentKeywordList.map((keyword, i) => {
            if (index === i) {
              return { ...keyword, ...res.data.sticker.translateResult }
            } else {
              return keyword
            }
          })
          setCurrentKeywordList(temp)
          if (temp[index]['keywordId']) {
            setUpdateKeywordList([...updateKeywordList, temp[index]])
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  // 1 insert, 2 delete, 3 update

  const onClickAddKeyword = keyword => {
    if (customKeywordInput !== '') {
      setCurrentKeywordList([{ en: keyword }, ...currentKeywordList])
    }
  }

  const onClickRemoveKeyword = index => {
    const keywordId = currentKeywordList[index].keywordId

    if (keywordId !== undefined) {
      setDeleteKeywordList([
        ...deleteKeywordList,
        currentKeywordList[index].keywordId,
      ])
    }
    setCurrentKeywordList(currentKeywordList.filter((_, i) => i !== index))
  }

  const onClickApplyChanges = async () => {
    const insertKeywordList = currentKeywordList.filter(
      x => x.keywordId === undefined
    )

    try {
      await axios
        .post(
          `/new/webAdmin/v2/keyword/sticker/untagged/${location.pathname
            .split('/')
            .pop()}`,
          { insertKeywordList, deleteKeywordList, updateKeywordList },
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        .then(res => {
          window.alert('Success applying keyword changes.')
          getStickerKeywordWithVision()
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Wrapper>
      {currentSticker && (
        <Fragment>
          <ControlWrapper>
            <DefaultButton
              theme={'default'}
              btnText={'Reset Changes'}
              onClick={() => {
                getStickerKeywordWithVision()
              }}
            />
            <DefaultButton
              style={{ marginLeft: 10 }}
              theme={'blue'}
              btnText={'Apply Changes'}
              onClick={() => {
                onClickApplyChanges()
              }}
            />
          </ControlWrapper>
          <ContentWrapper>
            <StickerDiv>
              <BoxBoldTitle>Sticker Information</BoxBoldTitle>
              <StickerImage src={currentSticker.stickerImage} />
              <StickerInfoBox>
                <StickerInfoTextBox>
                  <StickerInfoTag>Sticker ID</StickerInfoTag>
                  <StickerInfoText>{currentSticker.stickerId}</StickerInfoText>
                </StickerInfoTextBox>
                <StickerInfoTextBox>
                  <StickerInfoTag>Package ID</StickerInfoTag>
                  <StickerInfoText>{currentSticker.packageId}</StickerInfoText>
                  <StickerInfoTag>Package Name</StickerInfoTag>
                  <StickerInfoText>
                    {currentSticker.packageName}
                  </StickerInfoText>
                  <StickerInfoTag>Package Category</StickerInfoTag>
                  <StickerInfoText>
                    {currentSticker.packageCategory}
                  </StickerInfoText>
                  <StickerInfoTag>Package Tags</StickerInfoTag>
                  <StickerInfoText>
                    {currentSticker.packageTags}
                  </StickerInfoText>
                  <StickerInfoTag>Package Upload Date</StickerInfoTag>
                  <StickerInfoText>
                    {currentSticker.packageUploadDate}
                  </StickerInfoText>
                  <StickerInfoTag>Package Approval Date</StickerInfoTag>
                  <StickerInfoText>
                    {currentSticker.packageApprovalDate}
                  </StickerInfoText>
                </StickerInfoTextBox>
                <StickerInfoTextBox>
                  <StickerInfoTag>Artist ID</StickerInfoTag>
                  <StickerInfoText>{currentSticker.userId}</StickerInfoText>
                  <StickerInfoTag>Artist Name</StickerInfoTag>
                  <StickerInfoText>{currentSticker.userName}</StickerInfoText>
                  <StickerInfoTag>Artist Email</StickerInfoTag>
                  <StickerInfoText>{currentSticker.userEmail}</StickerInfoText>
                  <StickerInfoTag>Artist Join Date</StickerInfoTag>
                  <StickerInfoText>
                    {currentSticker.userJoinDate}
                  </StickerInfoText>
                </StickerInfoTextBox>
              </StickerInfoBox>
            </StickerDiv>
            <KeywordListDiv>
              <BoxBoldTitle>Keyword List</BoxBoldTitle>
              <KeywordListBoxDiv>
                {currentKeywordList &&
                  (currentKeywordList.length === 0 ||
                    currentKeywordList.filter(x => x.status !== 2).length ===
                      0) && (
                    <KeywordListBox style={{ paddingTop: 10 }}>
                      This sticker has no keyword.
                    </KeywordListBox>
                  )}
                {currentKeywordList &&
                  currentKeywordList.length !== 0 &&
                  currentKeywordList.map((keyword, index) => {
                    return (
                      <KeywordListBox key={index}>
                        <KeywordListBoxHeader>
                          <KeywordCardName>{keyword.en}</KeywordCardName>
                          <KeywordListButtonBox>
                            {onEdit !== index && (
                              <DefaultButton
                                btnText={'Translate'}
                                theme={'blue'}
                                onClick={() => {
                                  getKeywordTranslation(keyword.en, index)
                                }}
                              />
                            )}
                            <DefaultButton
                              style={{ marginLeft: 10, width: 80 }}
                              btnText={onEdit === index ? 'Cancel' : 'Edit'}
                              theme={onEdit === index ? 'default' : 'green'}
                              onClick={() => {
                                if (onEdit === index) {
                                  setOnEdit(-1)
                                } else {
                                  setOnEdit(index)
                                  setTempEditKeyword(keyword)
                                }
                              }}
                            />
                            {onEdit === index && (
                              <DefaultButton
                                style={{ marginLeft: 10, width: 80 }}
                                btnText={'Save'}
                                theme={'green'}
                                onClick={() => {
                                  const temp = currentKeywordList.map(
                                    (keyword, i) => {
                                      if (index === i) {
                                        return {
                                          ...keyword,
                                          ...tempEditKeyword,
                                        }
                                      } else {
                                        return keyword
                                      }
                                    }
                                  )
                                  setCurrentKeywordList(temp)
                                  if (tempEditKeyword['keywordId']) {
                                    setUpdateKeywordList([
                                      ...updateKeywordList,
                                      tempEditKeyword,
                                    ])
                                  }
                                  setOnEdit(-1)
                                }}
                              />
                            )}
                            {onEdit !== index && (
                              <DefaultButton
                                style={{ marginLeft: 10, width: 80 }}
                                theme={'red'}
                                btnText={'Remove'}
                                onClick={() => {
                                  onClickRemoveKeyword(index)
                                }}
                              />
                            )}
                          </KeywordListButtonBox>
                        </KeywordListBoxHeader>
                        <KeywordTableRow>
                          <KeywordTableBox>
                            <KeywordTableHead>English</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.en}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.en}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    en: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['ko']}>
                            <KeywordTableHead>Korean</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.ko}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.ko}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    ko: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['it']}>
                            <KeywordTableHead>Italian</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.it}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.it}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    it: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                        </KeywordTableRow>
                        <KeywordTableRow>
                          <KeywordTableBox $isEmpty={!keyword['pt']}>
                            <KeywordTableHead>Portuguese</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.pt}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.pt}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    pt: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['es']}>
                            <KeywordTableHead>Spanish</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.es}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.es}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    es: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['ru']}>
                            <KeywordTableHead>Russian</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.ru}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.ru}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    ru: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                        </KeywordTableRow>
                        <KeywordTableRow>
                          <KeywordTableBox $isEmpty={!keyword['fr']}>
                            <KeywordTableHead>French</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.fr}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.fr}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    fr: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['zh_CN']}>
                            <KeywordTableHead>
                              Simplified Chinese
                            </KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>
                                {keyword.zh_CN}
                              </KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.zh_CN}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    zh_CN: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['zh_TW']}>
                            <KeywordTableHead>
                              Traditional Chinese
                            </KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>
                                {keyword.zh_TW}
                              </KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.zh_TW}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    zh_TW: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                        </KeywordTableRow>
                        <KeywordTableRow>
                          <KeywordTableBox $isEmpty={!keyword['vi']}>
                            <KeywordTableHead>Vietnamese</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.vi}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.vi}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    vi: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['uk']}>
                            <KeywordTableHead>Ukrainian</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.uk}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.uk}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    uk: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['ar']}>
                            <KeywordTableHead>Arabic</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.ar}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.ar}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    ar: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                        </KeywordTableRow>
                        <KeywordTableRow>
                          <KeywordTableBox $isEmpty={!keyword['id']}>
                            <KeywordTableHead>Indonessian</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.id}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.id}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    id: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['de']}>
                            <KeywordTableHead>German</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.de}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.de}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    de: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['th']}>
                            <KeywordTableHead>Thai</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.th}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.th}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    th: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                        </KeywordTableRow>
                        <KeywordTableRow>
                          <KeywordTableBox $isEmpty={!keyword['hi']}>
                            <KeywordTableHead>Hindi</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.hi}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.hi}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    hi: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['ta']}>
                            <KeywordTableHead>Tamil</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.ta}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.ta}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    ta: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['te']}>
                            <KeywordTableHead>Telugu</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.te}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.te}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    te: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                        </KeywordTableRow>
                        <KeywordTableRow>
                          <KeywordTableBox $isEmpty={!keyword['mt']}>
                            <KeywordTableHead>Marathi</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.mt}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.mt}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    mt: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['ml']}>
                            <KeywordTableHead>Malayalam</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.ml}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.ml}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    ml: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['gu']}>
                            <KeywordTableHead>Gujarati</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.gu}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.gu}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    gu: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                        </KeywordTableRow>
                        <KeywordTableRow>
                          <KeywordTableBox $isEmpty={!keyword['kn']}>
                            <KeywordTableHead>Kannada</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.kn}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.kn}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    kn: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                          <KeywordTableBox $isEmpty={!keyword['pa']}>
                            <KeywordTableHead>Punjabi</KeywordTableHead>
                            {onEdit !== index ? (
                              <KeywordTableText>{keyword.pa}</KeywordTableText>
                            ) : (
                              <KeywordEditInput
                                value={tempEditKeyword.pa}
                                onChange={e => {
                                  setTempEditKeyword({
                                    ...tempEditKeyword,
                                    pa: e.target.value,
                                  })
                                }}
                              />
                            )}
                          </KeywordTableBox>
                        </KeywordTableRow>
                      </KeywordListBox>
                    )
                  })}
              </KeywordListBoxDiv>
            </KeywordListDiv>
            <KeywordInputDiv>
              <KeywordInputTopDiv>
                <BoxBoldTitle>Custom Keyword</BoxBoldTitle>
                <KeywordInputBox>
                  <KeywordInputText
                    placeholder={'Please input custom keyword in English.'}
                    value={customKeywordInput}
                    onChange={e => {
                      setCustomKeywordInput(e.target.value)
                    }}
                    onKeyPress={e => {
                      if (
                        window.event.keyCode === 13 &&
                        customKeywordInput !== ''
                      ) {
                        onClickAddKeyword(customKeywordInput)
                      }
                    }}
                  />
                  <DefaultButton
                    btnText={'Add Keyword'}
                    theme={'green'}
                    onClick={() => {
                      onClickAddKeyword(customKeywordInput)
                    }}
                  />
                </KeywordInputBox>
              </KeywordInputTopDiv>
              <KeywordInputBottomDiv>
                <BoxBoldTitle>Recommend Keyword</BoxBoldTitle>
                <KeywordRecommendList>
                  {recommendList &&
                    recommendList.map((keyword, index) => {
                      return (
                        <KeywordRecommendBox key={index}>
                          <KeywordRecommendTextBox>
                            <KeywordRecommendName>
                              {keyword.name}
                            </KeywordRecommendName>
                            <KeywordRecommendScore>
                              ({keyword.score}%)
                            </KeywordRecommendScore>
                          </KeywordRecommendTextBox>
                          <DefaultButton
                            style={{ alignSelf: 'flex-end' }}
                            btnText={'Add Keyword'}
                            theme={'green'}
                            onClick={() => {
                              onClickAddKeyword(keyword.name)
                            }}
                          />
                        </KeywordRecommendBox>
                      )
                    })}
                </KeywordRecommendList>
              </KeywordInputBottomDiv>
            </KeywordInputDiv>
          </ContentWrapper>
        </Fragment>
      )}
    </Wrapper>
  )
}

export default KeywordAddForm
