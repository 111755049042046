import React from 'react'
import { Row, Col, Navbar } from 'react-bootstrap'
import { paging2 } from '../../Common/Page';
import {ExportReactCSV} from '../../Common/ExportReactCSV';

const ContentsListBottomBar = (props) => {
    return (
        <Navbar bg="light" expand="lg" >
            <Col>
                <ExportReactCSV csvData={props.selectedPackages} fileName='stickerInfo.csv' title='선택 스티커팩 정보'/>
            </Col>
            <Row >
                <Col>
                    {paging2(props.pageNumber, props.pageMap, props.pageName, props.search, props.type, props.order, props.language)}
                </Col>
            </Row>
        </Navbar>
    )
}

export default ContentsListBottomBar;